@if (getIsPhoneScreen()) {
    <div class="malou-card !m-0 flex !h-20 !flex-row justify-start !p-2" (click)="openAnswerModal()">
        <a
            class="flex h-16 w-12 overflow-hidden"
            target="_blank"
            [ngClass]="{ 'cursor-pointer': post.socialLink, 'cursor-default': !post.socialLink }"
            [href]="post.socialLink">
            <app-social-post-media
                customMediaClass="!rounded-md h-[4.5rem] w-[5.5rem]"
                [postType]="post.postType"
                [thumbnailUrl]="post | applySelfPure: 'getSocialMediaThumbnail'"
                [workingMedia]="(post | applySelfPure: 'getSocialMediaUrl') || (post | applySelfPure: 'getMalouMediaUrl')"
                [firstAttachmentType]="post | applySelfPure: 'getFirstMediumType'"
                [tooltipText]="post.text"
                [showVideoControls]="false"
                (refreshMedia)="refreshPost(post)">
            </app-social-post-media>
        </a>
        <div class="ml-5 flex flex-1 flex-col py-1">
            <div class="flex h-8 flex-row items-center">
                <div class="platform-user-logo">
                    <img height="20" width="20" [lazyLoad]="post.key | platformLogoPathResolver" />
                </div>
                <div class="ml-3.5 flex flex-1 flex-col justify-between truncate">
                    <span class="malou-break-word malou-color-text-1 malou-text-12--bold">
                        {{ post | applySelfPure: 'getPostDate' | formatDate: 'shortDate' }}
                    </span>
                    <i class="malou-text-10--regular italic">{{ post | applySelfPure: 'getPostDate' | formatDate: 'shortTime' }}</i>
                </div>
            </div>
            <div class="flex pt-2">
                <span class="malou-color-primary malou-text-10--semibold">
                    @if (post.unansweredComments.length) {
                        {{ 'moderation.unanswered_comments' | pluralTranslate: post.unansweredComments.length }}
                    } @else {
                        {{ 'moderation.unanswered_mentions' | pluralTranslate: post.unansweredMentions.length }}
                    }
                </span>
            </div>
        </div>
        <button
            class="malou-btn-icon--secondary btn-xl"
            [ngClass]="{ 'btn-active': post.areAllCommentsAndMentionsArchived }"
            [matTooltip]="
                post.areAllCommentsAndMentionsArchived
                    ? ('moderation.unarchive_post_comments' | translate)
                    : ('moderation.archive_post_comments' | translate)
            "
            (click)="$event.stopPropagation(); toggleArchived(post)">
            <mat-icon class="icon-btn malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.BOX"></mat-icon>
        </button>
    </div>
} @else {
    <div class="malou-card !m-0 !p-5 shadow-none">
        <div class="h-full" data-cy="postCommentDiv">
            <div class="flex h-full flex-col">
                <div class="mb-4 ml-0 flex items-center">
                    <img class="malou-logo--medium" data-cy="postLogoImg" alt="logo" [lazyLoad]="post.key | platformLogoPathResolver" />
                    <div
                        class="malou-color-text-2 mx-2 flex w-full flex-col"
                        [matTooltip]="post | applySelfPure: 'getPostDate' | formatDate: 'full'"
                        [matTooltipClass]="'multiline-tooltip'">
                        <span class="malou-text-10--medium">{{ post | applySelfPure: 'getPostDate' | formatDate: 'day' }}</span>
                        <span class="malou-text-10--regular">{{ post | applySelfPure: 'getPostDate' | formatDate: 'shortTime' }}</span>
                    </div>
                    <button
                        class="malou-btn-icon--secondary btn-xl"
                        mat-icon-button
                        [ngClass]="{ 'btn-active': post.areAllCommentsAndMentionsArchived }"
                        [matTooltip]="
                            post.areAllCommentsAndMentionsArchived
                                ? ('moderation.unarchive_post_comments' | translate)
                                : ('moderation.archive_post_comments' | translate)
                        "
                        (click)="$event.stopPropagation(); toggleArchived(post)">
                        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.BOX"></mat-icon>
                    </button>
                </div>
                <div
                    class="flex h-36 overflow-hidden rounded-xl"
                    [ngStyle]="{ cursor: post.socialLink ? 'pointer' : 'initial' }"
                    (click)="openSocialLink(post)">
                    <app-social-post-media
                        customMediaClass="!rounded-[10px]"
                        [thumbnailUrl]="post | applySelfPure: 'getSocialMediaThumbnail'"
                        [postType]="post.postType"
                        [workingMedia]="(post | applySelfPure: 'getSocialMediaUrl') || (post | applySelfPure: 'getMalouMediaUrl')"
                        [firstAttachmentType]="post | applySelfPure: 'getFirstMediumType'"
                        [tooltipText]="post.text"
                        (refreshMedia)="refreshPost(post)">
                    </app-social-post-media>
                </div>
                <div class="post-comment flex w-full grow-2 justify-center">
                    <span class="malou-color-primary malou-text-10--semibold mt-4 cursor-pointer" (click)="openAnswerModal()">
                        @if (post.unansweredComments.length) {
                            {{ 'moderation.unanswered_comments' | pluralTranslate: post.unansweredComments.length }}
                        } @else {
                            {{ 'moderation.unanswered_mentions' | pluralTranslate: post.unansweredMentions.length }}
                        }
                    </span>
                </div>
            </div>
        </div>
    </div>
}
