import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, computed, Input, OnInit, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, forkJoin, map, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

import {
    errorReplacer,
    isNotNil,
    ListingStatus,
    PlatformDefinition,
    PlatformDefinitions,
    PlatformKey,
    YextPublisherId,
    yextPublishers,
} from '@malou-io/package-utils';

import * as FooterManagerActions from ':core/components/restaurant/footer-manager/store/footer-manager.actions';
import { AvailableFooterType } from ':core/components/restaurant/footer-manager/store/footer-manager.interface';
import { ExperimentationService } from ':core/services/experimentation.service';
import { InformationUpdatesService } from ':core/services/information-update.service';
import { PlatformsService } from ':core/services/platforms.service';
import { PublishersService } from ':core/services/publishers.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import * as InformationsActions from ':modules/informations/store/informations.actions';
import { InformationsUpdateStatus } from ':modules/informations/store/informations.interface';
import { selectCurrentInformations } from ':modules/informations/store/informations.reducer';
import * as PlatformActions from ':modules/platforms/store/platforms.actions';
import { selectCurrentPlatforms, selectOauthPlatforms } from ':modules/platforms/store/platforms.reducer';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces/kill-subscriptions.interface';
import { Platform, PlatformComparisonWithStatus, Restaurant } from ':shared/models';
import { OauthUpdateStatusFactory, UpdateStatus } from ':shared/models/oauth-update-status';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

@Component({
    selector: 'app-information-update-footer',
    templateUrl: './information-update-footer.component.html',
    styleUrls: ['./information-update-footer.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule,
        AsyncPipe,
        IncludesPipe,
        PlatformLogoPathResolverPipe,
        ApplySelfPurePipe,
        ApplyPurePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class InformationUpdateFooterComponent implements OnInit, KillSubscriptions {
    @Input() isAggregatedView: Signal<boolean>;

    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    yextUpdateStatus: InformationsUpdateStatus | null = null;
    readonly restaurant$ = this._restaurantsService.restaurantSelected$;
    readonly restaurant = toSignal(this.restaurant$, { initialValue: null });
    readonly referencePlatformForRestaurant = computed(() =>
        this.restaurant()?.isBrandBusiness() ? PlatformKey.FACEBOOK : PlatformKey.GMB
    );
    readonly isYextEnabled = computed(() => !!this.restaurant()?.isYextActivated);

    readonly listings = toSignal(
        toObservable(this.isYextEnabled).pipe(
            switchMap((isYextEnabled) => {
                if (isYextEnabled) {
                    return this._publishersService.getYextListingsForRestaurant$(this.restaurant()!._id).pipe(map((res) => res.data));
                } else {
                    return of([]);
                }
            })
        )
    );

    readonly availablePublishers = computed(() => {
        const listings = this.listings();
        if (!listings) {
            return [];
        }
        const availablePublishers = listings.filter((listing) => {
            const isAvailable = listing.status !== ListingStatus.UNAVAILABLE;
            const yextPublishersAlreadyManagedByMalou = PlatformDefinitions.getPlatformKeysWithYextPublisherIds();
            const isManagedByMalou = yextPublishersAlreadyManagedByMalou.includes(listing.id as YextPublisherId);
            return isAvailable && !isManagedByMalou;
        });
        const connectedPublishers = availablePublishers
            .map((listing) => {
                const yextPublisherConfig = yextPublishers[listing.id as YextPublisherId];
                if (!yextPublisherConfig) {
                    return null;
                }
                return {
                    id: listing.id as YextPublisherId,
                    url: listing.url,
                    displayPriority: yextPublisherConfig.displayPriority,
                };
            })
            .filter(isNotNil)
            .sort((a, b) => {
                const aValue = a.displayPriority;
                const bValue = b.displayPriority;
                return bValue - aValue;
            })
            .map((listing) => ({
                id: listing.id as YextPublisherId,
                url: listing.url,
            }));

        return connectedPublishers;
    });

    readonly readablePublishersNames = computed(() => {
        const availablePublishers = this.availablePublishers();
        return availablePublishers.map((publisher) => yextPublishers[publisher.id].name).join(', ');
    });

    readonly isFooterVisible$: Observable<boolean> = combineLatest([this.restaurant$, this._store.select(selectCurrentInformations)]).pipe(
        filter(([restaurant]) => isNotNil(restaurant) && this._isReferencePlatformConnected(restaurant)),
        switchMap(([_, state]) =>
            combineLatest([of(state), this._store.select(selectCurrentPlatforms).pipe(filter((platforms) => !!platforms))])
        ),
        map(([state, platforms]) => ({
            state,
            referencePlatform: platforms.find((p) => p.key === this.referencePlatformForRestaurant()),
        })),
        tap(({ state, referencePlatform }) => {
            if (state) {
                this.loadingStatus =
                    state.updateLoadingState === InformationsUpdateStatus.NOT_STARTED ? null : (state.updateLoadingState ?? null);
                this.referencePlatform = referencePlatform ?? null;
            }
        }),
        map(
            ({ state, referencePlatform }) =>
                (state?.isFooterVisible &&
                    ((referencePlatform?.platformPropertiesToUpdate?.length ?? 0) > 0 ||
                        state.updateLoadingState === InformationsUpdateStatus.LOADED)) ??
                false
        ),
        tap((isFooterVisible) => {
            if (isFooterVisible) {
                document.getElementById('footer-update')?.classList.remove('close-animation');
            }
            this._store.dispatch({
                type: FooterManagerActions.setFooterVisibility.type,
                footerType: AvailableFooterType.INFORMATION_UPDATE,
                isFooterVisible,
            });
        })
    );

    readonly InformationsUpdateStatus = InformationsUpdateStatus;

    referencePlatform: Platform | null;

    isClosed = false;

    platformsWithStatus: PlatformComparisonWithStatus[];
    loadingStatus: InformationsUpdateStatus | null = null;

    platformConnectedToApi = {};
    oauthPlatforms: string[];

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly _UPDATE_MODAL_TRANSLATE = this._translate.instant('information.update_modal');
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly _TOOLTIP_TEXT = {
        gmb: this._UPDATE_MODAL_TRANSLATE.update_failed,
        facebook: this._UPDATE_MODAL_TRANSLATE.update_failed,
    };

    private readonly _platforms$: Observable<Platform[]> = this._store.select(selectCurrentPlatforms).pipe(
        map((platforms) => {
            if (platforms?.length) {
                return platforms.map((platform) => new Platform(platform));
            }
            return platforms;
        })
    );

    constructor(
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translate: TranslateService,
        private readonly _informationUpdatesService: InformationUpdatesService,
        private readonly _platformService: PlatformsService,
        private readonly _experimentationService: ExperimentationService,
        private readonly _publishersService: PublishersService
    ) {}

    ngOnInit(): void {
        this._initPlatformsWithStatus();

        this._store
            .select(selectCurrentInformations)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((infos) => {
                if (infos?.shouldStartUpdate) {
                    this.startUpdate();
                }
            });

        this.isFooterVisible$.pipe(take(1)).subscribe(() =>
            this._store.dispatch({
                type: InformationsActions.editInformation.type,
                information: {
                    platformPropertiesToUpdate: this.referencePlatform?.platformPropertiesToUpdate,
                },
            })
        );

        this._store
            .select(selectOauthPlatforms)
            .pipe(map((res) => Object.values(res).map((p) => p.key)))
            .subscribe((oauthPlatformsKeys) => {
                this.oauthPlatforms = oauthPlatformsKeys;
            });
    }

    close(): void {
        document.getElementById('footer-update')?.classList.add('close-animation');
        setTimeout(() => {
            this._dispatchEditIsVisible(false, InformationsUpdateStatus.NOT_STARTED);
        }, 500);
    }

    startUpdate(): void {
        const restaurant = this.restaurant();
        if (!restaurant) {
            return;
        }
        this.loadingStatus = InformationsUpdateStatus.LOADING;
        this.yextUpdateStatus = InformationsUpdateStatus.LOADING;
        this._informationUpdatesService.validateInformationUpdate({ restaurantId: restaurant._id }).subscribe({
            error: (err) => {
                console.error(JSON.stringify(err, errorReplacer));
            },
        });
        this._informationUpdatesService
            .startUpdate(restaurant._id)
            .pipe(
                tap((res) => {
                    const { yext, ...responseByPlatformKey } = res.data;
                    this.yextUpdateStatus = yext.success ? InformationsUpdateStatus.LOADED : InformationsUpdateStatus.ERROR;
                    this._setPlatformsInfosStatus(responseByPlatformKey);
                    this._dispatchEditIsVisible(true, InformationsUpdateStatus.LOADED);
                    this._dispatchDirtyState();
                }),
                switchMap((res) => {
                    const { yext: _, ...responseByPlatformKey } = res.data;
                    const succeededPlatforms = Object.entries(responseByPlatformKey)
                        .map(([key, value]) => ({ ...value, key }))
                        .filter((platform) => platform.success);
                    const requests = succeededPlatforms.map(({ key }) => this._platformService.pullOverview(restaurant._id, key));
                    return forkJoin(requests);
                })
            )
            .subscribe({
                next: () => {
                    this._store.dispatch({
                        type: PlatformActions.loadPlatformsData.type,
                        restaurantId: restaurant._id,
                    });
                    this._store.dispatch({
                        type: InformationsActions.editInformation.type,
                        information: {
                            platformPropertiesToUpdate: [],
                        },
                    });
                    this._restaurantsService.reloadSelectedRestaurant();
                },
                error: (err) => this._onSendUpdateInformationError(err),
            });
    }

    isOauthPlatform = (platformKey: string): boolean => this.oauthPlatforms.includes(platformKey);

    private _isReferencePlatformConnected(restaurant: Restaurant): boolean {
        return !!restaurant.access.find((a) => a.platformKey === this.referencePlatformForRestaurant());
    }

    private _onSendUpdateInformationError(err: any): void {
        this._dispatchDirtyState();
        this._dispatchEditIsVisible(true, InformationsUpdateStatus.ERROR);
        this.loadingStatus = InformationsUpdateStatus.ERROR;

        if (err.error && err.error.status === 400) {
            if (!!err?.error?.message?.match(/currently not allowed/)) {
                this._TOOLTIP_TEXT.gmb = this._UPDATE_MODAL_TRANSLATE.gmb_currently_not_allowed;
            }
        }
    }

    private _dispatchDirtyState(): void {
        const restaurant = this.restaurant();
        if (!restaurant) {
            return;
        }
        this._store.dispatch({
            type: InformationsActions.editInformation.type,
            information: {
                isDirty: false,
                restaurantId: restaurant._id,
            },
        });
    }

    private _dispatchEditIsVisible(isFooterVisible: boolean, updateLoadingState?: InformationsUpdateStatus): void {
        this._store.dispatch({
            type: InformationsActions.editInformation.type,
            information: {
                isFooterVisible: isFooterVisible,
                shouldStartUpdate: false,
                updateLoadingState: updateLoadingState,
            },
        });
    }

    private _setPlatformsInfosStatus(responseByPlatformKey: Record<PlatformKey, UpdateStatus>): void {
        for (const [key, value] of Object.entries(responseByPlatformKey)) {
            const statusInfos = OauthUpdateStatusFactory.create(key, value)?.getInfos();
            let info: string = statusInfos.info
                .filter(Boolean)
                .map((v) => this._translate.instant(v))
                .join(', ');
            if (statusInfos.failedToUpdateProperties?.length) {
                const prefix = this._translate.instant('information.update_modal.failed_to_update_properties');
                info = info ? info + '. ' : '';
                info += `${prefix}: ${statusInfos.failedToUpdateProperties}`;
            }
            this.platformConnectedToApi[key] = {
                status: statusInfos.status,
                info: info,
            };
        }
    }

    private _buildPlatformsComparisonWithStatus(
        platformList: PlatformDefinition[],
        restaurantPlatforms: Platform[],
        restaurant: Restaurant
    ): PlatformComparisonWithStatus[] {
        const filterPlatformsCantUpdateInformations = (pl: PlatformDefinition): boolean =>
            PlatformDefinitions.shouldCompareInformation(pl.key as PlatformKey);

        return platformList
            .filter((pl) => filterPlatformsCantUpdateInformations(pl))
            .map((p) => {
                const restaurantPlatform = restaurantPlatforms.find((rp) => rp.key === p.key) ?? null; // can be null
                return new PlatformComparisonWithStatus(restaurant, restaurantPlatform, p);
            });
    }

    private _initPlatformsWithStatus(): void {
        combineLatest([this.restaurant$, this._platforms$])
            .pipe(
                filter(() => !this.loadingStatus || this.loadingStatus === InformationsUpdateStatus.NOT_STARTED),
                filter(([restaurant, platforms]) => !!(restaurant && platforms)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([restaurant, platforms]: [Restaurant, Platform[]]) => {
                    this.platformsWithStatus = this._buildPlatformsComparisonWithStatus(
                        PlatformDefinitions.getNonPrivatePlatforms(),
                        platforms,
                        restaurant
                    ).filter((p) => p.isConnected());
                },
            });
    }
}
