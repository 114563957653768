<div class="flex h-full flex-col justify-between">
    <div>
        @for (interval of restaurantSpecialHoursIntervals; track interval) {
            <div class="malou-row flex flex-col">
                <div class="flex flex-row">
                    <ng-container [ngTemplateOutlet]="leftSideTemplate" [ngTemplateOutletContext]="{ interval }"></ng-container>
                    <ng-container [ngTemplateOutlet]="rightSideTemplate" [ngTemplateOutletContext]="{ interval }"></ng-container>
                </div>
            </div>
        }
    </div>

    @if (displayedHours && !displayedHours.length) {
        <div class="min-h-[200px]">
            <i> -- </i>
        </div>
    }
    <ng-container [ngTemplateOutlet]="previousHourButtonTemplate"></ng-container>
</div>

<ng-template let-interval="interval" #leftSideTemplate>
    <div class="malou-color-text-2 malou-text-13--medium min-w-[140px]">
        <span>{{ interval.start | malouDate | formatDate: 'shortDate' }}</span>
        @if (isIntervalDates | applyPure: interval) {
            <span>
                -
                <span>
                    {{ interval.end | malouDate | formatDate: 'shortDate' }}
                </span>
            </span>
        }
    </div>
</ng-template>

<ng-template let-interval="interval" #rightSideTemplate>
    <div class="flex">
        <div class="flex flex-wrap">
            @for (period of interval.hours; track period) {
                <span class="ml-1.5">
                    <ng-container
                        [ngTemplateOutlet]="!period ? noHourTemplate : hourTemplate"
                        [ngTemplateOutletContext]="{ period }"></ng-container>
                </span>
            }
        </div>
    </div>
</ng-template>

<ng-template #noHourTemplate>
    {{ 'information.business_hours.not_specified' | translate }}
</ng-template>

<ng-template let-period="period" #hourTemplate>
    <span class="malou-text-12--regular whitespace-pre break-keep">
        {{ period | specialTimePeriodText }}
    </span>
</ng-template>

<ng-template #closePeriodTemplate>
    {{ 'information.business_hours.closed' | translate }}
</ng-template>

<ng-template let-period="period" #openPeriodTemplate>
    <ng-container
        [ngTemplateOutlet]="period.openTime === '00:00' && period.closeTime === '24:00' ? openAllDayPeriodTemplate : openWithHoursTemplate"
        [ngTemplateOutletContext]="{ period }">
    </ng-container>
</ng-template>

<ng-template #openAllDayPeriodTemplate>24h/24</ng-template>

<ng-template let-period="period" #openWithHoursTemplate>
    {{ (period.openTime === '24:00' ? '00:00' : period.openTime) + ' - ' + (period.closeTime === '24:00' ? '00:00' : period.closeTime) }}
</ng-template>

<ng-template #previousHourButtonTemplate>
    <div class="flex flex-col items-center">
        <button class="malou-btn-flat !mb-0" mat-button (click)="showAnteriorHours = !showAnteriorHours; onShowAnteriorDates()">
            @if (!showAnteriorHours) {
                <span>
                    {{ 'information.special_hours.display_ant_hours' | translate }}
                </span>
            }
            @if (showAnteriorHours) {
                <span>
                    {{ 'information.special_hours.hide_ant_hours' | translate }}
                </span>
            }
        </button>
    </div>
</ng-template>
