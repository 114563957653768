import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { round } from 'lodash';
import { combineLatest, filter, forkJoin, map, switchMap, tap } from 'rxjs';

import { RoiAdditionalCustomersDto } from '@malou-io/package-dto';
import { HeapEventName, isNotNil, PlatformKey } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { EstimatedCustomersDetailsModalComponent } from ':modules/roi/estimated-customers-details-modal/estimated-customers-details-modal.component';
import { RoiMissingInformationBadgeComponent } from ':modules/roi/roi-missing-information-badge/roi-missing-information-badge.component';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiService } from ':modules/roi/roi.service';
import * as statisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-estimated-customers',
    templateUrl: './estimated-customers.component.html',
    styleUrls: ['./estimated-customers.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        EmojiPathResolverPipe,
        NumberEvolutionComponent,
        ShortNumberPipe,
        TranslateModule,
        MatIconModule,
        MatTooltipModule,
        RoiMissingInformationBadgeComponent,
        AsyncPipe,
        NgTemplateOutlet,
        SkeletonComponent,
    ],
})
export class EstimatedCustomersComponent implements OnInit {
    readonly isParentLoading = input.required<boolean>();
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _platformService = inject(PlatformsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _roiService = inject(RoiService);
    private readonly _store = inject(Store);
    private readonly _heapService = inject(HeapService);
    public readonly roiContext = inject(RoiContext);

    readonly SvgIcon = SvgIcon;
    readonly PlatformKey = PlatformKey;

    readonly isGmbConnected$ = this._platformService.isPlatformConnected$(PlatformKey.GMB);
    readonly selectedTimeScaleFilter$ = this._store.select(statisticsSelectors.selectTimeScaleFilter);
    readonly selectedMonths$ = this.selectedTimeScaleFilter$.pipe(
        map((timeScaleFilter) => getSelectedMonthsNumberFromTimeScaleFilter(timeScaleFilter))
    );
    readonly selectedMonths: Signal<number> = toSignal(this.selectedMonths$, {
        initialValue: getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS),
    });

    readonly isLoading: WritableSignal<boolean> = signal(false);
    readonly isMissingData: Signal<boolean> = computed(() => this.estimatedCustomersDetails()?.isMissingData ?? false);
    readonly hasValidData: Signal<boolean> = computed(() => this.totalCustomers() > 0 ?? true);

    readonly hasSimilarLocationsData: WritableSignal<boolean> = signal(false);
    readonly estimatedCustomersDetails: WritableSignal<RoiAdditionalCustomersDto | null> = signal(null);
    readonly similarRestaurantsEstimatedCustomersDetails: WritableSignal<RoiAdditionalCustomersDto | null> = signal(null);

    readonly minCustomers: Signal<number> = computed(() => this._roundToTens(this.estimatedCustomersDetails()?.minCustomers ?? 0));
    readonly maxCustomers: Signal<number> = computed(() => this._roundToTens(this.estimatedCustomersDetails()?.maxCustomers ?? 0));
    readonly minEarnings: Signal<number | null> = computed(() =>
        this._getEarnings(this.minCustomers(), this.roiContext.roiSettings()?.averageTicket)
    );
    readonly maxEarnings: Signal<number | null> = computed(() =>
        this._getEarnings(this.maxCustomers(), this.roiContext.roiSettings()?.averageTicket)
    );
    readonly totalCustomers: Signal<number> = computed(() => this.estimatedCustomersDetails()?.totalCustomers ?? 0);
    readonly previousPeriodTotalCustomers: Signal<number> = computed(
        () => this.estimatedCustomersDetails()?.previousPeriodTotalCustomers ?? 0
    );
    readonly minSimilarCustomers: Signal<number> = computed(() =>
        this._roundToTens(this.similarRestaurantsEstimatedCustomersDetails()?.minCustomers ?? 0)
    );
    readonly maxSimilarCustomers: Signal<number> = computed(() =>
        this._roundToTens(this.similarRestaurantsEstimatedCustomersDetails()?.maxCustomers ?? 0)
    );
    readonly minSimilarEarnings: Signal<number | null> = computed(() =>
        this._getEarnings(this.minSimilarCustomers(), this.roiContext.roiSettings()?.averageTicket)
    );
    readonly maxSimilarEarnings: Signal<number | null> = computed(() =>
        this._getEarnings(this.maxSimilarCustomers(), this.roiContext.roiSettings()?.averageTicket)
    );
    readonly totalEarningsEvolution: Signal<number> = computed(() => {
        const current = this.totalCustomers();
        const previous = this.previousPeriodTotalCustomers();
        return previous ? round(((current - previous) / previous) * 100, 0) : 0;
    });

    readonly isTotalEarningsEvolutionTooHigh: Signal<boolean> = computed(
        () => Math.abs(this.totalEarningsEvolution() ?? 0) > this._MAX_DISPLAY_EVOLUTION
    );
    private readonly _MAX_DISPLAY_EVOLUTION = 200;

    ngOnInit(): void {
        this._initAdditionalCustomers();
    }

    openDetails(): void {
        this._heapService.track(HeapEventName.TRACKING_CHECK_EARNINGS_DETAILS_SINGLE_RESTAURANT);
        this._customDialogService.open(
            EstimatedCustomersDetailsModalComponent,
            {
                height: 'unset',
                maxHeight: '90vh',
                data: {
                    additionalCustomers: this.estimatedCustomersDetails(),
                },
            },
            { closeOnOutsideClick: true }
        );
    }

    private _initAdditionalCustomers(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this.selectedTimeScaleFilter$])
            .pipe(
                tap(() => this.isLoading.set(true)),
                filter(([restaurant, _]: [Restaurant, MalouTimeScalePeriod | undefined]) => isNotNil(restaurant)),
                switchMap(([restaurant, _]: [Restaurant, MalouTimeScalePeriod | undefined]) =>
                    forkJoin([
                        this._roiService
                            .getEstimatedCustomersForNLastMonths(restaurant?._id, this.selectedMonths())
                            .pipe(map((res) => res.data)),
                        this._roiService
                            .getEstimatedCustomersForNLastMonths(restaurant?._id, this.selectedMonths(), { similarLocations: true })
                            .pipe(map((res) => res.data)),
                    ])
                )
            )
            .subscribe({
                next: ([estimatedCustomersDetails, similarLocationsEstimatedCustomersDetails]) => {
                    this.hasSimilarLocationsData.set(!!similarLocationsEstimatedCustomersDetails);
                    this.estimatedCustomersDetails.set(estimatedCustomersDetails);
                    this.similarRestaurantsEstimatedCustomersDetails.set(similarLocationsEstimatedCustomersDetails);
                    this.isLoading.set(false);
                },
                error: (err) => {
                    this.isLoading.set(false);
                    console.warn(err);
                },
            });
    }

    private _roundToTens(num: number): number {
        return num >= 10 ? round(num / 10) * 10 : num;
    }

    private _getEarnings(value: number, ticket?: number): number | null {
        return value && ticket ? this._roundToTens(value * ticket) : null;
    }
}
