<div class="flex items-center justify-between rounded-[10px] bg-malou-color-background-dark p-6">
    <div class="flex flex-col">
        <span class="malou-text-14--bold malou-color-text-1">
            {{ 'informations.you_have_see_inconsistencies' | translate: { nbInconsistencies: totalDifferencesCount() } }}</span
        >
        <span class="malou-text-10--regular malou-color-text-2">
            {{ 'informations.you_have_see_inconsistencies_subtitle' | translate }}</span
        >
    </div>
    <div class="flex gap-x-2">
        <button class="malou-btn-raised--primary btn-xl !h-12.5 !min-w-fit" mat-raised-button (click)="openPlatformsComparisons.emit()">
            {{ 'informations.see_inconsistencies' | translate }}
        </button>
    </div>
</div>
