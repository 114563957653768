import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Input, OnInit, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { ExperimentationService } from ':core/services/experimentation.service';
import { PostPublicationStatusFooterComponent } from ':shared/components-v3/post-publication-status-footer/post-publication-status-footer.component';
import { ReviewSynchronizationFooterComponent } from ':shared/components-v3/review-synchronization-footer/review-synchronization-footer.component';
import { CampaignFooterComponent } from ':shared/components/campaign-footer/campaign-footer.component';
import { InformationUpdateFooterComponent } from ':shared/components/information-update-footer/information-update-footer.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

import { AvailableFooterType } from './store/footer-manager.interface';
import { selectOpenedFootersOrder } from './store/footer-manager.reducer';

@Component({
    selector: 'app-footer-manager',
    templateUrl: './footer-manager.component.html',
    styleUrls: ['./footer-manager.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        CampaignFooterComponent,
        InformationUpdateFooterComponent,
        PostPublicationStatusFooterComponent,
        ReviewSynchronizationFooterComponent,
        ApplySelfPurePipe,
        AsyncPipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class FooterManagerComponent implements OnInit, KillSubscriptions {
    @Input() isAggregatedView: Signal<boolean>;
    AvailableFooterType = AvailableFooterType;

    openedFootersOrder: AvailableFooterType[] = [];
    readonly killSubscriptions$: Subject<void> = new Subject();

    readonly isFeaturePlatformsUpdatesV2Enabled$ = this._experimentationService.isFeatureEnabled$('release-platforms-updates');

    constructor(
        private readonly _store: Store,
        private readonly _experimentationService: ExperimentationService
    ) {}

    ngOnInit(): void {
        this._store
            .select(selectOpenedFootersOrder)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((openedFootersOrder) => {
                this.openedFootersOrder = openedFootersOrder;
            });
    }
}
