import { NgClass } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { APP_DEFAULT_LANGUAGE, ApplicationLanguage } from '@malou-io/package-utils';

import { eventTitleTextLimit } from ':core/constants';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { EmojiPickerComponent } from ':shared/components/emoji-picker/emoji-picker.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { KillSubscriptions } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

@Component({
    selector: 'app-create-calendar-event-modal',
    templateUrl: './create-calendar-event-modal.component.html',
    styleUrls: ['./create-calendar-event-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        EmojiPickerComponent,
        InputTextComponent,
        NgClass,
        TranslateModule,
        InputDatePickerComponent,
    ],
})
@AutoUnsubscribeOnDestroy()
export class CreateCalendarEventModalComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    flagImg = {};
    currentLang = localStorage.getItem(LocalStorageKey.LANG) || APP_DEFAULT_LANGUAGE;
    otherLangs: string[];
    translateOtherLanguages = false;
    applicationLanguages: string[] = Object.values(ApplicationLanguage);
    calendarEventForm: FormGroup;
    isSmallScreen = false;
    EVENT_TITLE_TEXT_LIMIT = eventTitleTextLimit;

    langText = {
        fr: this._translate.instant('events.language.french'),
        en: this._translate.instant('events.language.english'),
        es: this._translate.instant('events.language.spanish'),
        it: this._translate.instant('events.language.italian'),
    };

    constructor(
        public dialogRef: MatDialogRef<CreateCalendarEventModalComponent>,
        private readonly _translate: TranslateService,
        private readonly _fb: FormBuilder,
        private readonly _screenSizeService: ScreenSizeService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            startDate: Date;
        }
    ) {
        this._initCalendarEventForm();
    }

    ngOnInit(): void {
        this._screenSizeService.resize$.subscribe((elt) => {
            this.isSmallScreen = elt.size === ScreenSize.IsSmallScreen;
        });

        this._translate.onLangChange.pipe(takeUntil(this.killSubscriptions$)).subscribe((res) => {
            this.currentLang = res.lang;
        });

        const flagPathResolverPipe = new FlagPathResolverPipe();
        this.applicationLanguages.forEach((l) => {
            this.flagImg[l] = flagPathResolverPipe.transform(l);
        });
        this.otherLangs = this.applicationLanguages.filter((l) => l !== this.currentLang);
    }

    changeEmoji(event: EmojiEvent): void {
        this.calendarEventForm.get('emoji')?.patchValue(event.emoji.native);
    }

    save(): void {
        const nameWithLang = {};
        this.applicationLanguages.forEach((l) => {
            nameWithLang[l] = this.calendarEventForm.get('name')?.get(l)?.value;
        });

        return this.close({
            startDate: this.calendarEventForm.get('startDate')?.value,
            name: nameWithLang,
            key: this.calendarEventForm.get('name')?.get(this.currentLang)?.value,
            emoji: this.calendarEventForm.get('emoji')?.value,
        });
    }

    close(data: any = null): void {
        this.dialogRef.close(data);
    }

    expandModalHeight(): void {
        if (this.isSmallScreen) {
            return;
        }
        const modal = document.querySelector('.malou-dialog-panel') as HTMLElement;
        modal.style.height = '505px';
    }

    getLangText(lang: string): string {
        return this.langText[lang];
    }

    getFlagImg(lang: string): string {
        return this.flagImg[lang];
    }

    getOtherLangsText(): string {
        return this.otherLangs.map((lang) => this.getLangText(lang)).join(', ');
    }

    private _initCalendarEventForm(): void {
        const nameFormGroup = {};
        this.applicationLanguages.forEach((lang) => {
            nameFormGroup[lang] = [null, this.currentLang === lang ? Validators.required : null];
        });
        this.calendarEventForm = this._fb.group({
            emoji: ['🎉', Validators.required],
            name: this._fb.group(nameFormGroup),
            startDate: [this.data.startDate, Validators.required],
        });
    }
}
