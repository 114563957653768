<a
    class="navigation-item flex cursor-pointer items-center gap-x-4 rounded-md px-4 py-2 hover:bg-malou-color-background-dark"
    routerLinkActive="is-active"
    [routerLink]="routerLink()"
    [ngClass]="{ 'justify-center !px-0': !sidenavState.isOpened() }"
    [routerLinkActiveOptions]="{ queryParams: 'ignored', matrixParams: 'exact', paths: 'subset', fragment: 'ignored' }">
    <div class="relative h-4 shrink-0">
        <mat-icon class="malou-color-primary !h-4 !w-4 opacity-30" mat-list-icon [svgIcon]="svgIcon()"></mat-icon>
        @if (this.notificationCount() > 0) {
            <div class="absolute -right-0.5 -top-0.5 h-[9px] w-[9px] rounded-full bg-malou-color-primary"></div>
        }
    </div>

    @if (sidenavState.isOpened()) {
        <div class="shrink grow-0 overflow-hidden">
            <div class="malou-color-text-2 malou-text-12--medium truncate">
                {{ text() }}
            </div>
        </div>

        @if (showCrown()) {
            <mat-icon class="ml-2 !h-4 !w-4 shrink-0 gap-x-1" svgIcon="crown"></mat-icon>
        }

        @if (notificationCount() > 0) {
            <div class="malou-status--count malou-text-10--medium shrink-0">
                {{ notificationCount() || 0 | oneDigitNumber }}
            </div>
        }
    }
</a>
