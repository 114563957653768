<ng-container *ngTemplateOutlet="isLoading() ? loading : page"></ng-container>

<ng-template #page>
    @if (restaurantHasAtLeastOneTemplate()) {
        <div class="flex h-full flex-col">
            <ng-container *ngTemplateOutlet="actionHeader"></ng-container>
            @if (atLeastOneTemplateToDisplay()) {
                <div class="min-h-0 grow overflow-y-auto px-7.5" [id]="SCROLL_CONTAINER_ID">
                    <ng-container *ngTemplateOutlet="screenSizeService.isPhoneScreen ? mobileList : desktopTable"></ng-container>
                </div>
            } @else {
                <div class="grid h-full w-full place-items-center">
                    <div class="flex w-full flex-col items-center gap-y-6">
                        <img
                            height="100"
                            width="100"
                            [lazyLoad]="
                                (dataSource().filter.length ? Illustration.Cook : Illustration.Goggles) | illustrationPathResolver
                            " />
                        <div class="malou-text-14--bold malou-color-text-1 max-w-[70%] text-center">
                            {{
                                dataSource().filter.length
                                    ? ('templates.review.no_templates_with_filters.title' | translate)
                                    : ('templates.review.no_templates.title' | translate)
                            }}
                            <div class="malou-text-10--regular mt-3 text-center">
                                {{ dataSource().filter.length ? '' : ('templates.review.no_templates.subtitle' | translate) }}
                            </div>
                        </div>
                        @if (!dataSource().filter.length) {
                            <button class="malou-btn-raised--primary" mat-raised-button (click)="openEditTemplateModal()">
                                {{ 'templates.review.no_templates.add_template' | translate }}
                            </button>
                        }
                    </div>
                </div>
            }
            <app-scroll-to-top
                class="fixed right-7.5"
                [ngClass]="{
                    'bottom-8': footersVisibilityCount === 0,
                    'bottom-24': footersVisibilityCount >= 1,
                }"
                [container]="SCROLL_CONTAINER_SELECTOR">
            </app-scroll-to-top>
        </div>
    } @else {
        <app-generate-review-templates (generate)="generateTemplates($event)"></app-generate-review-templates>
    }
</ng-template>

<ng-template #actionHeader>
    <div class="px-7.5">
        <app-review-template-actions-header
            [hasSelectedTemplates]="selection.selected.length > 0"
            (searchChange)="onSearchChange($event)"
            (duplicateSelected)="onDuplicateSelected($event)"
            (deleteSelected)="onDeleteSelected()"
            (createTemplate)="openEditTemplateModal()"></app-review-template-actions-header>
    </div>
</ng-template>

<ng-template #mobileList>
    <div class="my-5 flex flex-col gap-y-2">
        @for (template of dataSource().filteredData; track trackByIdFn(i, template); let i = $index) {
            <div
                class="malou-card !m-0 !flex-none cursor-pointer gap-y-5 !p-5"
                [class.selected]="selection.isSelected(template)"
                [class.duplicated]="duplicatedTemplateIds | includes: template._id"
                [id]="template._id"
                (click)="$event.stopPropagation(); selectMultiple($event, i, template)">
                <div class="flex gap-x-4">
                    <app-noop-mat-checkbox color="primary" [checked]="selection.isSelected(template)"></app-noop-mat-checkbox>
                    <ng-container [ngTemplateOutlet]="templateNameTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
                </div>
                <div>
                    <ng-container [ngTemplateOutlet]="templateTextTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
                </div>
                <div class="flex flex-wrap gap-4">
                    <ng-container
                        [ngTemplateOutlet]="withCommentTemplate"
                        [ngTemplateOutletContext]="{ template, size: 'long' }"></ng-container>
                    <ng-container [ngTemplateOutlet]="ratingTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
                </div>
                <div class="flex flex-wrap gap-4">
                    <span>{{ 'templates.review.auto_reply' | translate }}</span>
                    @if (template.automationConfig.automated) {
                        <div [matTooltip]="getAutomatedColumnToolip | applyPure: template">
                            <mat-icon class="!h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.FLASH"></mat-icon>
                        </div>
                    }
                </div>
                <div class="absolute right-3 top-3" (click)="$event.stopPropagation()">
                    <button class="malou-btn-icon--secondary btn-xl" mat-icon-button [matMenuTriggerFor]="actionsMenu">
                        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                    </button>
                    <mat-menu class="malou-mat-menu malou-box-shadow" #actionsMenu="matMenu">
                        <ng-container [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
                    </mat-menu>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #desktopTable>
    <mat-table
        class="malou-mat-table my-5"
        matSort
        matSortDisableClear
        matSortDirection="asc"
        matSortActive="name"
        aria-label="Templates table"
        [dataSource]="dataSource()"
        #table="matTable">
        <!-- Checkbox Column -->
        <ng-container [matColumnDef]="ReviewTemplateColumns.SELECT">
            <mat-header-cell *matHeaderCellDef>
                <app-noop-mat-checkbox
                    color="primary"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    (click)="toggleAllFiltered()">
                </app-noop-mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let template; table: table; let i = index">
                <app-noop-mat-checkbox
                    color="primary"
                    [checked]="selection.isSelected(template)"
                    (click)="$event.stopPropagation(); selectMultiple($event, i, template)">
                </app-noop-mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="ReviewTemplateColumns.NAME">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'templates.wording' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let template; table: table" class="malou-text-13--semi-bold !text-malou-color-text-1">
                <ng-container [ngTemplateOutlet]="templateNameTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="ReviewTemplateColumns.TEXT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'templates.text' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let template; table: table" class="malou-text-12--regular">
                <ng-container [ngTemplateOutlet]="templateTextTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="ReviewTemplateColumns.WITH_COMMENT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'templates.comments' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let template; table: table">
                <ng-container
                    [ngTemplateOutlet]="withCommentTemplate"
                    [ngTemplateOutletContext]="{ template, size: 'short' }"></ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="ReviewTemplateColumns.RATING">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'templates.rating' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let template; table: table">
                <ng-container [ngTemplateOutlet]="ratingTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="ReviewTemplateColumns.AUTOMATED">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'templates.review.auto_replies' | translate }}
                <i class="malou-text-12--regular">({{ automaticReplyCount }})</i>
            </mat-header-cell>
            <mat-cell *matCellDef="let template; table: table">
                @if (template.automationConfig.automated) {
                    <div [matTooltip]="getAutomatedColumnToolip | applyPure: template">
                        <mat-icon class="!h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.FLASH"></mat-icon>
                    </div>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="ReviewTemplateColumns.ACTIONS">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let template; table: table" (click)="$event.stopPropagation()">
                <div class="flex">
                    <div class="flex h-full w-full justify-end" [matMenuTriggerFor]="actionsMenu">
                        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                    </div>
                    <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                        <ng-container [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
                    </mat-menu>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
        <mat-row
            *matRowDef="let template; columns: DISPLAYED_COLUMNS; table: table; let i = index"
            class="row"
            [id]="template._id"
            [class.selected]="selection.isSelected(template) || (duplicatedTemplateIds | includes: template._id)"
            [class.duplicated]="duplicatedTemplateIds | includes: template._id"
            [ngClass]="{
                pending: template.status === 'pending',
            }"
            (click)="$event.stopPropagation(); selectMultiple($event, i, template)"></mat-row>
    </mat-table>
</ng-template>

<ng-template let-template="template" #templateNameTemplate>
    <div class="flex items-center gap-x-1 truncate md:!mr-12.5">
        @if (template.status === 'pending') {
            <div>
                <mat-icon
                    class="mt-1 !h-[16px] !w-[16px] cursor-pointer"
                    color="primary"
                    [svgIcon]="SvgIcon.DRAFT"
                    [matTooltip]="
                        (template.duplicatedFromRestaurant ?? template.duplicatedByUser)
                            ? (template | duplicateTooltip)
                            : ('templates.draft' | translate)
                    "
                    (click)="openEditTemplateModal(template)">
                </mat-icon>
            </div>
        }
        <div class="truncate" [matTooltip]="template.name ?? ''">
            {{ template.name ?? '-' }}
        </div>
    </div>
</ng-template>

<ng-template let-template="template" #templateTextTemplate>
    <div
        class="truncate md:!mr-12.5"
        [ngClass]="{
            truncate: !screenSizeService.isPhoneScreen,
            'line-clamp-2': screenSizeService.isPhoneScreen,
        }">
        <div
            [ngClass]="{ truncate: !screenSizeService.isPhoneScreen }"
            [matTooltip]="replaceVariableTextToChipText | applyPure: template.text">
            {{ replaceVariableTextToChipText | applyPure: template.text }}
        </div>
    </div>
</ng-template>

<ng-template let-template="template" let-size="size" #withCommentTemplate>
    <div
        class="malou-chip--rounded-20 flex items-center"
        [ngClass]="{
            'malou-chip--purple-light': template?.withComment === CommentOptionValue.WITH,
            'malou-chip--warn': template?.withComment === CommentOptionValue.WITHOUT,
            'malou-chip--pink-light': template?.withComment === CommentOptionValue.WITH_OR_WITHOUT,
        }">
        {{ template?.withComment | withComment: size }}
    </div>
</ng-template>

<ng-template let-template="template" #ratingTemplate>
    <div class="flex gap-1">
        @for (rating of template.rating; track rating) {
            <app-star-with-text-chip [value]="rating === 0 ? null : rating"></app-star-with-text-chip>
        }
    </div>
</ng-template>

<ng-template let-template="template" #actionsTemplate>
    <button class="custom-menu-item" data-cy="editTemplateBtn" mat-menu-item (click)="openEditTemplateModal(template)">
        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
        {{ 'common.edit' | translate }}
    </button>
    <button
        class="malou-menu-item malou-menu-submenu-icon"
        data-cy="duplicateTemplateBtn"
        mat-menu-item
        [matMenuTriggerFor]="duplicateActions">
        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
        {{ 'common.duplicate' | translate }}
    </button>

    <mat-menu class="malou-mat-menu" #duplicateActions="matMenu">
        <button class="malou-menu-item" mat-menu-item (click)="duplicateTemplates(DuplicationDestination.HERE, template)">
            {{ 'templates.review.actions.duplicate_here' | translate }}
        </button>
        <button class="malou-menu-item" mat-menu-item (click)="duplicateTemplates(DuplicationDestination.OUT, template)">
            {{ 'common.to_other_venues' | translate }}
        </button>
    </mat-menu>
    <button class="malou-menu-item" data-cy="deleteTemplateBtn" mat-menu-item (click)="deleteTemplatesByIds([template._id])">
        <mat-icon class="!h-4 !w-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
        {{ 'common.delete' | translate }}
    </button>
</ng-template>

<ng-template #loading>
    <div class="h-full overflow-y-auto px-7.5">
        <div class="w-full">
            <app-skeleton skeletonClass="secondary-bg h-[50px] w-full"></app-skeleton>
        </div>

        <div class="mt-7 flex w-full justify-between px-7 md:hidden">
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[8vw] mr-3"></app-skeleton>
            <div class="w-[10vh]"></div>
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[8vw] mr-3"></app-skeleton>
            <div class="w-[10vh]"></div>
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[8vw] mr-3" [count]="2"></app-skeleton>
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[10vw] mr-3"></app-skeleton>
        </div>

        <div class="mt-3 w-full">
            <app-skeleton skeletonClass="secondary-bg h-[50px] md:h-[100px] w-full" [count]="9"></app-skeleton>
        </div>
    </div>
</ng-template>
