<div class="flex w-full flex-col">
    @if (title() || subtitle() || required() || showRequiredStar()) {
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            @if (title(); as title) {
                <span>{{ title }}</span>
            }
            @if (subtitle(); as subtitle) {
                <span class="malou-text-10 italic">{{ subtitle }}</span>
            }
            @if (required() || showRequiredStar()) {
                <span>*</span>
            }
            <ng-content select="[afterTitleContent]"></ng-content>
        </div>
    }

    <div
        class="malou-border-primary flex items-center self-stretch rounded-lg bg-white"
        [class.opacity-50]="control.disabled"
        [class.malou-border-secondary]="!errorMessage() && isFocused()"
        [class.malou-border-error]="!!errorMessage() || (control && control.invalid && (control.dirty || control.touched))">
        @if (svgImg(); as svgImg) {
            <div class="flex items-center pl-5">
                <img class="!h-4 !w-4 !max-w-none" [src]="svgImg" />
            </div>
        }

        <div class="h-12 w-full">
            <input
                class="malou-text-12--medium box-border h-full w-full rounded-lg border-0 bg-white px-5 !text-malou-color-text-1 outline-none placeholder:italic"
                [type]="inputType()"
                [id]="inputId()"
                [attr.data-testid]="testId()"
                [class.malou-text-12]="isEmptyValue()"
                [class.bg-white]="control.disabled"
                [placeholder]="placeholder()"
                [formControl]="control"
                [autocapitalize]="autocapitalize()"
                [autocomplete]="autocomplete()"
                [defaultValue]="defaultValue()"
                [min]="min()"
                [max]="max()"
                [maxlength]="maxLength()"
                [disabled]="control.disabled || disabled()"
                (focus)="isFocused.set(true)"
                (blur)="isFocused.set(false)" />
        </div>

        @if (showMaxLength()) {
            <div class="flex h-10 items-center rounded border-l border-malou-color-border-primary px-1">
                <div class="malou-color-text-2 malou-text-12--regular whitespace-nowrap pr-1 italic">
                    {{ control.value?.length || 0 }}/{{ maxLength() }}
                </div>
            </div>
        }
        @if (isEmojiPickerEnabled()) {
            <div class="malou-color-text-2 pr-2 sm:hidden">
                <app-emoji-picker
                    [buttonColorClass]="emojiButtonColor()"
                    [disabled]="maxLength() && control.value ? maxLength() === control.value.length : false"
                    [closeOnSelect]="true"
                    (emitEmoji)="emojiSelected($event)"></app-emoji-picker>
            </div>
        }

        @if (shouldShowSvgIcon()) {
            <div class="flex items-center px-3">
                @if (svgIcon(); as svgIcon) {
                    <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="svgIcon"></mat-icon>
                }
            </div>
        }

        @if (showEyeIcon()) {
            <div class="flex items-center px-3">
                <mat-icon color="primary" [svgIcon]="password() ? 'eye' : 'eye-closed'" (click)="toggleEyeIcon()"></mat-icon>
            </div>
        }
        @if (showClearIcon() && control.value?.length > 0) {
            <div class="flex items-center pr-5">
                <mat-icon class="!h-3 !w-3" matRipple color="primary" [svgIcon]="SvgIcon.CROSS" (click)="clearControl()"></mat-icon>
            </div>
        }

        @if (customActionBtn(); as customActionBtn) {
            <div>
                <span class="malou-color-primary malou-text-10--bold cursor-pointer pr-4" (click)="emitCustomAction()">
                    {{ customActionBtn }}
                </span>
            </div>
        }

        @if (showConfirmIcon() && control.value?.length > 0) {
            <div class="flex cursor-pointer items-center pr-5">
                <mat-icon
                    class="!h-3 !w-3"
                    color="primary"
                    [svgIcon]="SvgIcon.ARROW_RIGHT"
                    [id]="confirmationIconId()"
                    (click)="emitControlValue()"></mat-icon>
            </div>
        }
    </div>

    @if (showMaxLengthHelper() && maxLength() !== null) {
        <div class="malou-text-8--regular malou-color-text-2 w-full pt-1 text-right italic">
            {{ 'common.max_length_helper' | translate: { count: maxLength() } }}
        </div>
    }

    @if (errorMessage()) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage() }}</div>
    }
    @if (!errorMessage() && control && control.invalid && (control.dirty || control.touched)) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">
            {{ 'common.invalid_field' | translate }}
        </div>
    }
</div>
