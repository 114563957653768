<div class="mat-expansion-panel-body flex flex-col">
    <div class="flex h-12.5 items-center bg-malou-color-background-dark px-10">
        <p class="malou-text-12--bold malou-color-text-1">{{ 'admin.updates' | translate }}</p>
    </div>
    <ng-container [ngTemplateOutlet]="updatesData.length > 0 ? updateTableTemplate : noUpdateTemplate"></ng-container>
</div>

<ng-template #updateTableTemplate>
    <mat-table class="malou-mat-table" matSort [dataSource]="dataSource" #table="matTable">
        <ng-container matColumnDef="platformKey">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ isBusinessView ? ('admin.platform' | translate) : ('admin.business' | translate) }}
            </mat-header-cell>
            <mat-cell *matCellDef="let tabData; table: table">
                @if (isBusinessView) {
                    <div class="flex items-center" (click)="openPlatformTab(tabData)">
                        <img
                            class="mr-4 rounded-[5px]"
                            height="24"
                            width="24"
                            [src]="tabData.platformKey | platformLogoPathResolver: { folder: 'platforms' }" />
                        <span class="malou-text-13--semibold malou-color-text-1">
                            {{ tabData.platformKey | enumTranslate: 'platform_key' }}
                        </span>
                    </div>
                } @else {
                    <div class="flex items-center gap-x-2">
                        <img
                            class="h-10 w-12 rounded-md object-cover"
                            loading="lazy"
                            [src]="tabData.originalData.restaurant.logo?.urls?.original ?? ('default_logo' | imagePathResolver)" />
                        <span class="malou-text-13--medium malou-color-text-2 truncate" [matTooltip]="tabData.originalData.restaurant.name">
                            {{ tabData.originalData.restaurant.name }}
                        </span>
                    </div>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="accessType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'admin.access.type' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let tabData; table: table">
                <div
                    class="malou-chip--rounded-20"
                    [ngClass]="{
                        'malou-chip--purple-light': tabData.accessType === AccessType.MANAGER,
                        'malou-chip--warn': tabData.accessType === AccessType.CREDENTIALS,
                    }">
                    {{ tabData.accessType | titlecase }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastVerified">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.update.checked_access' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let tabData; table: table">
                <div class="malou-chip--rounded-20" [ngClass]="tabData.lastVerified ? 'malou-chip--green' : 'malou-chip--red'">
                    {{ tabData.lastVerified ? ('common.yes' | translate) : ('common.no' | translate) }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastUpdated">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'admin.client_update' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let tabData; table: table">{{ tabData.lastUpdated | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="login">
            <mat-header-cell *matHeaderCellDef>{{ 'admin.update.login' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let tabData; table: table">
                <span class="malou-text-13--medium malou-color-text-2 truncate" [matTooltip]="tabData.login ?? ''">
                    {{ tabData.login }}
                </span>
                @if (tabData.login) {
                    <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.COPY" [cdkCopyToClipboard]="tabData.login" (click)="copied()">
                    </mat-icon>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="password">
            <mat-header-cell *matHeaderCellDef>{{ 'admin.update.password' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let tabData; table: table">
                @if (tabData.password) {
                    <div>
                        <span class="malou-text-18--bold malou-color-text-1">&bull;&bull;&bull;&bull;&bull;&bull;</span>
                        @if (tabData.password) {
                            <mat-icon
                                class="!h-4"
                                color="primary"
                                [svgIcon]="SvgIcon.COPY"
                                (click)="copyPassword(tabData.platformKey, tabData.originalData.restaurant._id)">
                            </mat-icon>
                        }
                    </div>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>{{ 'admin.update.update' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let tabData; table: table">
                <div class="flex items-center">
                    <div
                        class="h-2 w-2 rounded-full"
                        [ngClass]="{
                            'malou-color-bg-fail': tabData.status === InformationUpdatePlatformStatusStatus.ERROR,
                            'malou-color-bg-warning': tabData.status === InformationUpdatePlatformStatusStatus.PENDING,
                            'malou-color-bg-success': tabData.status === InformationUpdatePlatformStatusStatus.DONE,
                        }"></div>
                    <select
                        required
                        [disabled]="tabData.status === InformationUpdatePlatformStatusStatus.DONE"
                        (change)="changeStatus($event, tabData)">
                        @for (status of INFORMATION_UPDATE_PLATFORM_STATUS_STATUS; track status) {
                            <option [value]="status" [selected]="tabData.status === status">
                                {{ status | enumTranslate: 'information_update_platform_status_status' }}
                            </option>
                        }
                    </select>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mergedInformationUpdate">
            <mat-header-cell *matHeaderCellDef>{{ 'admin.update.information' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let tabData; table: table" class="flex items-center">
                <mat-icon color="primary" [svgIcon]="SvgIcon.EYE"></mat-icon>
                <button class="malou-btn-flat !pl-2 !pr-0" mat-button (click)="openCompareUpdatesModal(tabData)">
                    {{ 'admin.update.display' | translate }}
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS_VALUES"></mat-header-row>
        <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS_VALUES; table: table"></mat-row>
    </mat-table>
</ng-template>

<ng-template #noUpdateTemplate>
    <div class="malou-text-12--medium flex h-12.5 items-center px-10">
        <p>{{ 'admin.update.no_update' | translate }}</p>
    </div>
</ng-template>
