import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-reviews-header-title',
    templateUrl: './reviews-header-title.component.html',
    styleUrls: ['./reviews-header-title.component.scss'],
    standalone: true,
    imports: [NgClass, ApplyPurePipe, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsHeaderTitleComponent {
    readonly reviewsCount = input<number | undefined>();

    readonly reviewCountClass = computed(() => {
        const reviewCount = this.reviewsCount();
        let fontSize = 14;
        if (!reviewCount || reviewCount < 1000) {
            fontSize = 18;
        } else if (reviewCount >= 1000 && reviewCount < 10000) {
            fontSize = 16;
        } else if (reviewCount >= 10000) {
            fontSize = 14;
        }
        return `malou-text-${fontSize}--regular`;
    });
}
