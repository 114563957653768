import { Component, Input, OnInit, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { SearchComponent } from ':shared/components/search/search.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { NfcSelectors } from '../store/nfc.selectors';

@Component({
    selector: 'app-nfc-actions-header',
    templateUrl: './nfc-actions-header.component.html',
    styleUrls: ['./nfc-actions-header.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatMenuModule, TranslateModule, SearchComponent],
})
export class NfcActionsHeaderComponent implements OnInit {
    @Input() hasSelectedNfcs = false;
    @Input() showActions = false;
    readonly searchChange = output<string>();
    readonly delete = output<void>();
    readonly createNfc = output<boolean>();
    readonly loadNfcsFromSheet = output<void>();

    readonly SvgIcon = SvgIcon;

    nfcCount = 0;

    constructor(private readonly _store: Store) {}

    ngOnInit(): void {
        this._store.select(NfcSelectors.selectNfcCount).subscribe((nfcCount) => (this.nfcCount = nfcCount));
    }

    onSearchChange(search: string): void {
        this.searchChange.emit(search);
    }

    onDeleteClick(): void {
        this.delete.emit();
    }

    onCreateNfcClick({ isTotem }: { isTotem: boolean }): void {
        this.createNfc.emit(isTotem);
    }

    onLoadNfcsFromSheet(): void {
        this.loadNfcsFromSheet.emit();
    }
}
