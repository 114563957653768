<div
    class="absolute right-0 top-0 z-10 h-full w-full translate-x-[110%] overflow-hidden bg-malou-color-background-light p-5 transition-all duration-300 ease-in-out md:z-30 md:h-[80vh] md:w-[100vw] md:overflow-y-auto md:bg-white"
    [class.!translate-x-0]="isOpen()"
    (click)="$event.stopPropagation()">
    <div class="flex items-center gap-x-2">
        <mat-icon class="!w-4 rotate-180 cursor-pointer" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT" (click)="close()"></mat-icon>
        <span class="malou-text-14--bold text-malou-color-text-2">{{ 'social_posts.new_social_post.write_with_ai' | translate }}</span>
    </div>
    <div class="flex flex-col gap-y-2">
        <div class="mt-7.5 flex items-center gap-x-2 md:mt-3">
            <mat-icon class="mb-1 h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
            <span class="malou-text-12--semibold text-malou-color-text-2">{{
                'social_posts.new_social_post.describe_your_caption' | translate
            }}</span>
        </div>
        <div class="flex rounded-lg border border-malou-color-border-primary bg-white p-4">
            <textarea
                class="malou-text-12--regular w-full resize-none border-none focus:outline-none"
                id="openaiPromptTextarea"
                maxlength="300"
                [formControl]="promptFormControl()"
                [placeholder]="'social_posts.new_social_post.ai_placeholder' | translate"
                [cdkTextareaAutosize]="true"
                [cdkAutosizeMaxRows]="4"
                [cdkAutosizeMinRows]="3"></textarea>
        </div>
        <div class="flex w-full justify-end md:hidden">
            <button
                class="malou-btn-raised--primary"
                data-testid="post-generate-ai-btn"
                mat-raised-button
                [id]="
                    isSeo()
                        ? 'tracking_new_post_modal_generate_post_with_ai_button'
                        : 'tracking_new_social_post_modal_generate_post_with_ai_button'
                "
                [disabled]="promptFormControl().invalid || isGenerating()"
                (click)="generatePrompt()">
                <div>
                    <div class="flex items-center gap-x-2">
                        <span>
                            {{ 'social_posts.new_social_post.generate' | translate }}
                        </span>
                        @if (isGenerating()) {
                            <app-malou-spinner class="mb-1" [size]="'xs'" [color]="'white'"></app-malou-spinner>
                        }
                    </div>
                </div>
            </button>
        </div>
    </div>
    <div class="flex flex-col gap-y-2 md:mt-2">
        <span class="malou-text-12--semibold mb-2 text-malou-color-text-2">{{ 'social_posts.new_social_post.ideas' | translate }}</span>
        @for (promptIdeaKey of getObjectKeys | applyPure: PROMPT_IDEAS; track $index) {
            <div
                class="flex cursor-pointer gap-x-2 rounded-lg border border-malou-color-border-primary bg-white p-4 transition-all ease-in-out hover:border-malou-color-border-secondary"
                [class.!bg-malou-color-background-dark]="selectedPromptIndex() === $index"
                [class.!border-malou-chip-background-primary]="selectedPromptIndex() === $index"
                (click)="onPromptIdeaClick(promptIdeaKey)">
                <span class="malou-text-12--regular text-malou-color-text-1">
                    {{ PROMPT_IDEAS[promptIdeaKey] }}
                </span>
            </div>
        }
    </div>
    <button
        class="malou-btn-raised--primary mt-2 !hidden !h-auto w-full items-center md:!flex md:items-center md:justify-center"
        mat-raised-button
        [disabled]="promptFormControl().invalid || isGenerating()"
        (click)="generatePrompt()">
        <div>
            <div class="flex items-center justify-center gap-x-2">
                <span>
                    {{ 'social_posts.new_social_post.generate' | translate }}
                </span>
                @if (isGenerating()) {
                    <app-malou-spinner class="mb-1" [size]="'xs'" [color]="'white'"></app-malou-spinner>
                }
            </div>
        </div>
    </button>
</div>
