import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { KeywordScoreTextType, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { ExtendedPostPublicationStatus } from ':core/constants';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import * as fromPlatformsStore from ':modules/platforms/store/platforms.reducer';
import { PlatformState } from ':modules/platforms/store/platforms.reducer';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { showFeedbackNotification } from ':shared/helpers/show-feedback-notification';
import { Keyword, Post, PostWithJob, Restaurant, SeoPost } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { KeywordsScoreGaugeComponent } from '../keywords-score-gauge/keywords-score-gauge.component';
import { NoopMatCheckboxComponent } from '../noop-mat-checkbox/noop-mat-checkbox.component';
import { SinglePostLegendComponent } from '../single-post-legend/single-post-legend.component';

interface AppState {
    platforms: PlatformState;
}

@Component({
    selector: 'app-single-post',
    templateUrl: './single-post.component.html',
    styleUrls: ['./single-post.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTooltipModule,
        TranslateModule,
        KeywordsScoreGaugeComponent,
        SinglePostLegendComponent,
        NoopMatCheckboxComponent,
        AsyncPipe,
        ImagePathResolverPipe,
        ApplySelfPurePipe,
    ],
})
export class SinglePostComponent implements OnInit {
    @Input() post: PostWithJob;
    @Input() isChecked: boolean;
    @Input() restaurantKeywords: Keyword[] = [];
    @Output() duplicate = new EventEmitter<{ to: string; post: PostWithJob; platformsKeys: PlatformKey[]; inBackground: boolean }>();
    @Output() reload = new EventEmitter();
    @Output() emptySelectedPosts = new EventEmitter();
    @Output() delete = new EventEmitter<string>();
    @Output() open = new EventEmitter<string>();

    readonly SvgIcon = SvgIcon;

    restaurantKeywords$: Observable<Keyword[]>;
    isGmbConnected$ = this._store.select(fromPlatformsStore.selectCurrentPlatform({ platformKey: PlatformKey.GMB })).pipe(
        take(1),
        map((platform) => (platform?.credentials?.length ?? 0) > 0)
    );
    isForLater: boolean;
    userInfos: User | null = null;
    ExtendedPostPublicationStatus = ExtendedPostPublicationStatus;
    postText$: Observable<string>;
    restaurant$: BehaviorSubject<Restaurant | null> = this._restaurantsService.restaurantSelected$;
    gaugeTextType$: BehaviorSubject<KeywordScoreTextType> = new BehaviorSubject<KeywordScoreTextType>(KeywordScoreTextType.POST);
    DuplicationDestination = DuplicationDestination;
    errorMessage: string | null;
    PlatformKey = PlatformKey;
    postLang$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    readonly platformsKeysWithSeoPost = PlatformDefinitions.getSeoPlatformKeysWithDuplicablePosts();
    readonly platformsKeysWithSocialPost = PlatformDefinitions.getSocialPlatformKeysWithDuplicablePosts();

    constructor(
        private readonly _store: Store<AppState>,
        private readonly _postsService: PostsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translateService: TranslateService,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    get showFeedbackBadge(): boolean {
        if (!showFeedbackNotification(this.post?.feedback ?? null, this.userInfos?.role)) {
            return false;
        }
        return this.post.feedback?.isOpen ?? false;
    }

    ngOnInit(): void {
        this.isForLater = !!this.post.job && !this.post.job?.lastRunAt;
        this._store.select(selectUserInfos).subscribe((infos) => {
            this.userInfos = infos;
        });
        this.postText$ = of(this.post?.text || '');
        this.errorMessage = new SeoPost(this.post)?.clarifyError(this.post.errorData, this._translateService, this.post.published);
        this.restaurantKeywords$ = of(this.restaurantKeywords);
        this.postLang$.next(this.post?.language || null);
    }

    deletePost(): void {
        this.delete.emit(this.post._id);
    }

    openPost(): void {
        this.open.emit(this.post._id);
    }

    duplicatePost(to: string, platformsKeys: PlatformKey[] = [], inBackground = false): void {
        this.duplicate.emit({ to, post: this.post, platformsKeys, inBackground });
    }

    openSocialLink(post: Post): void {
        if (post?.socialLink) {
            window.open(post.socialLink, '_blank');
        }
    }

    refreshPostIfFailed(event: StateChange): void {
        if (event.reason === 'loading-failed') {
            this._refreshPost();
        }
    }

    emitEmptySelectedPosts(): void {
        this.emptySelectedPosts.emit();
    }

    private _refreshPost(): void {
        if (!this._shouldRefresh(this.post)) {
            return;
        }
        this._postsService
            .refresh(this.post?._id)
            .pipe(map((res) => res.data))
            .subscribe({
                next: (p) => {
                    this.post.refreshData({ ...p });
                },
                error: (err) => console.error('fail silently :>> ', err),
            });
    }

    private _shouldRefresh(post: PostWithJob): boolean {
        return !post.isStory;
    }
}
