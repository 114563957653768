@if (sticker()) {
    <div class="flex h-36 w-36 flex-col items-center justify-center rounded-full bg-malou-color-background-white" #stickerContainer>
        <ng-container [ngTemplateOutlet]="stickerTemplate"> </ng-container>
    </div>
}

<ng-template #stickerTemplate>
    <div class="z-50 flex h-36 flex-col items-center bg-contain bg-no-repeat">
        <mat-icon class="mt-2 !h-2" [svgIcon]="SvgIcon.LOCALISATION"></mat-icon>
        <div
            class="mt-1 w-[75px] text-center"
            [ngClass]="{
                'mt-1.5': stickerRestaurantName().length <= 23,
                'malou-text-7--semibold': stickerRestaurantName().length <= 23,
                'malou-text-5--semibold': stickerRestaurantName().length > 23 && stickerRestaurantName().length <= 50,
                'malou-text-3--semibold': stickerRestaurantName().length > 50,
            }">
            {{ stickerRestaurantName() }}
        </div>
        <div class="mt-1 flex justify-center">
            <img class="h-[77px] w-[77px] rounded-[10px]" alt="qr-code" [src]="qrCodeImage()" />
        </div>
        <img class="w-full" [src]="'five_stars' | imagePathResolver: { folder: 'stickers', extensionFormat: 'svg' }" />
        <div class="malou-text-3--semibold absolute bottom-1 flex h-1.5 w-20 justify-center">
            <span class="h-[4px]">{{ 'admin.nfcs.stickers.powered_by' | translate }}</span>
            <img class="relative bottom-[1px] ml-0.5 h-[3px]" [src]="'malou_logo_black' | imagePathResolver: { extensionFormat: 'svg' }" />
        </div>
    </div>
</ng-template>
