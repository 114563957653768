import { Component, OnInit, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { NfcWithRestaurantDto } from '@malou-io/package-dto';
import { Role } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { NfcService } from ':core/services/nfc.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { HeaderComponent } from ':shared/components/header/header.component';
import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { Restaurant } from ':shared/models';

import { selectOwnRestaurants } from '../restaurant-list/restaurant-list.reducer';
import { AggregatedStatisticsFiltersContext } from './filters/filters.context';
import * as AggregatedStatisticsActions from './store/aggregated-statistics.actions';

export const AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT = 20;
export const AGGREGATED_STATISTICS_RESTAURANTS_SELECTION_LIMIT = 100;

@Component({
    selector: 'app-aggregated-statistics',
    templateUrl: './aggregated-statistics.component.html',
    styleUrls: ['./aggregated-statistics.component.scss'],
    standalone: true,
    imports: [HeaderComponent, NavigationHeaderComponent, RouterOutlet, ScrollToTopComponent, MalouSpinnerComponent],
})
@AutoUnsubscribeOnDestroy()
export class AggregatedStatisticsComponent implements OnInit, KillSubscriptions {
    readonly SEO_TAB: NavigationTab = {
        label: this._translate.instant('aggregated_statistics.navigation_header.seo'),
        link: './seo',
    };
    readonly E_REPUTATION_TAB: NavigationTab = {
        label: this._translate.instant('aggregated_statistics.navigation_header.e_reputation'),
        link: './e-reputation',
    };
    readonly SOCIAL_NETWORK_TAB: NavigationTab = {
        label: this._translate.instant('aggregated_statistics.navigation_header.social_network'),
        link: './social-networks',
    };
    readonly BOOSTERS_TAB: NavigationTab = {
        label: this._translate.instant('aggregated_statistics.navigation_header.boosters'),
        link: './boosters',
    };
    readonly ROI_TAB: NavigationTab = {
        label: this._translate.instant('aggregated_statistics.navigation_header.roi'),
        link: './roi',
    };

    readonly MIN_RESTAURANT_COUNT_TO_SHOW_ROI_TAB = 2;

    tabs: NavigationTab[] = [];

    restaurants$: Observable<Restaurant[]> = this._store.select(selectOwnRestaurants);

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    isLoading = signal(true);

    constructor(
        private readonly _translate: TranslateService,
        private readonly _store: Store,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        private readonly _nfcService: NfcService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {}

    ngOnInit(): void {
        console.log('[LOG-TO-DELETE][StatsAggregated] Component initializing');
        this._initTabs();
        this._initFilterSelection();
    }

    private _initTabs(): void {
        this._store
            .select(selectOwnRestaurants)
            .pipe(
                tap(() => console.log('[LOG-TO-DELETE][StatsAggregated] Selected own restaurants change triggered')),
                filter((restaurants) => restaurants.length > 0),
                tap(() => this.isLoading.set(true)),
                switchMap((restaurants) => {
                    if (!restaurants.length) {
                        return forkJoin([of(null), of([])]);
                    }
                    const restaurantIds = restaurants.map(({ _id }) => _id.toString());
                    return forkJoin([
                        this._wheelsOfFortuneService.haveAtLeastOneWheelOfFortune(restaurantIds).pipe(map((res) => res?.data)),
                        this._nfcService.search({ limit: 1 }, restaurantIds).pipe(map((res) => res?.data)),
                        of(restaurants.filter((restaurant) => restaurant.roiActivated).length >= this.MIN_RESTAURANT_COUNT_TO_SHOW_ROI_TAB),
                        this._store.select(selectUserInfos).pipe(
                            filter((storageUser) => !!storageUser),
                            take(1)
                        ),
                    ]);
                })
            )
            .subscribe({
                next: ([haveAtLeastOneWheelOfFortune, totems, isRoiActivatedForAtLeast2Restaurants, user]: [
                    boolean,
                    NfcWithRestaurantDto[],
                    boolean,
                    User,
                ]) => {
                    console.log('[LOG-TO-DELETE][StatsAggregated] Received data for tab settings', {
                        haveAtLeastOneWheelOfFortune,
                        user,
                        isRoiActivatedForAtLeast2Restaurants,
                    });
                    const shouldShowRoiTab = user?.role === Role.ADMIN || isRoiActivatedForAtLeast2Restaurants;
                    console.log('[LOG-TO-DELETE][StatsAggregated] shouldShowRoiTab', {
                        shouldShowRoiTab,
                        role: user?.role,
                        isRoiActivatedForAtLeast2Restaurants,
                    });
                    if (shouldShowRoiTab && !this.tabs.includes(this.ROI_TAB)) {
                        this.tabs.push(this.ROI_TAB);
                    }
                    this.tabs.push(this.SEO_TAB, this.E_REPUTATION_TAB, this.SOCIAL_NETWORK_TAB);
                    if ((haveAtLeastOneWheelOfFortune || totems.length) && !this.tabs.includes(this.BOOSTERS_TAB)) {
                        this.tabs.push(this.BOOSTERS_TAB);
                    }
                    console.log('[LOG-TO-DELETE][StatsAggregated] Final tabs', this.tabs);
                    this.isLoading.set(false);
                },
            });
    }

    private _initFilterSelection(): void {
        this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)).subscribe((restaurantsSelected: Restaurant[]) => {
            if (restaurantsSelected.length === 0) {
                this.restaurants$
                    .pipe(
                        filter((restaurants) => restaurants.length > 0),
                        take(1)
                    )
                    .subscribe((restaurants: Restaurant[]) => {
                        this._store.dispatch(
                            AggregatedStatisticsActions.editRestaurants({
                                restaurants: restaurants
                                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                                    .slice(0, AGGREGATED_STATISTICS_RESTAURANTS_SELECTION_LIMIT),
                            })
                        );
                    });
            }
        });
    }
}
