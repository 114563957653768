<div class="flex flex-col gap-4">
    <div>
        <div class="malou-text-14--bold text-malou-color-text-1">
            {{ 'informations.infos_updates_state' | translate }}
        </div>
        <span class="malou-text-10--regular italic text-malou-color-text-2">{{
            'informations.infos_updates_state_last_time' | translate: { date: date | formatDate: 'long' }
        }}</span>
    </div>

    <div class="flex gap-6">
        <span class="malou-text-12--regular flex items-center">
            <mat-icon
                class="mr-2 !h-6 !w-6 rounded bg-malou-color-chart-green bg-opacity-30 p-1 text-malou-color-state-success"
                [svgIcon]="SvgIcon.CHECK"></mat-icon>
            {{ 'informations.nb_succeed_updates' | translate: { nbUpdates: 33 } }}
        </span>

        <span class="malou-text-12--regular flex items-center">
            <mat-icon
                class="mr-2 !h-6 !w-6 rounded bg-malou-color-state-error--light bg-opacity-30 p-1 text-malou-color-state-error"
                [svgIcon]="SvgIcon.CROSS"></mat-icon>
            {{ 'informations.nb_failed_updates' | translate: { nbUpdates: 12 } }}
        </span>

        <button class="malou-btn-flat !malou-text-10--semibold sm:mt-2 sm:self-center" (click)="openUpdateStateModal()">
            {{ 'informations.see_details' | translate }}
        </button>
    </div>
</div>
