import { NgClass, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { differenceBy } from 'lodash';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CreateOrganizationRequestBodyDto, CreateOrganizationResponseBodyDto } from '@malou-io/package-dto';

import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { OrganizationsService } from ':core/services/organizations.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { AdminService } from ':modules/admin/admin.service';
import { RestaurantsListModalComponent } from ':modules/admin/organizations/restaurants-list-modal/restaurants-list-modal.component';
import {
    OrganizationUpsertForm,
    UpsertOrganizationModalComponent,
} from ':modules/admin/organizations/upsert-organization-modal/upsert-organization-modal.component';
import { UsersListModalComponent } from ':modules/admin/organizations/users-list-modal/users-list-modal.component';
import * as UserActions from ':modules/user/store/user.actions';
import { UserState } from ':modules/user/store/user.reducer';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.services';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { UpsertKind } from ':shared/enums/upsert-kind.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { Organization } from ':shared/models/organization';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

interface OrganizationWithData extends Organization {
    restaurants: Restaurant[];
    users: User[];
}

@Component({
    selector: 'app-organizations-manager',
    templateUrl: './organizations.component.html',
    styleUrls: ['./organizations.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatMenuModule,
        MatSortModule,
        MatIconModule,
        ReactiveFormsModule,
        TranslateModule,
        PaginatorComponent,
        SearchComponent,
        FormatDatePipe,
        SlicePipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    dataSource: MatTableDataSource<OrganizationWithData> = new MatTableDataSource<OrganizationWithData>([]);
    displayedColumns: string[] = ['name', 'createdAt', 'users', 'restaurants', 'actions'];
    defaultSort: Sort = {
        active: 'createdAt',
        direction: 'desc',
    };
    refresh$: Subject<void> = new Subject<void>();

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    UpsertKind = UpsertKind;

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    constructor(
        private readonly _adminService: AdminService,
        private readonly _organizationsService: OrganizationsService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _dialogService: DialogService,
        public readonly translate: TranslateService,
        private readonly _spinnerService: SpinnerService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _usersService: UsersService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store<{ user: UserState }>
    ) {}

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    @ViewChild(PaginatorComponent) set paginator(paginatorComponent: PaginatorComponent) {
        if (paginatorComponent) {
            this.dataSource.paginator = paginatorComponent.matPaginator;
        }
    }

    ngOnInit(): void {
        this.refresh$
            .pipe(
                tap(() => this._spinnerService.show()),
                switchMap(() =>
                    forkJoin([
                        this._restaurantsService.all({ active: true }, ['organizationId', 'name']).pipe(map((res) => res.data)),
                        this._usersService.index(),
                    ])
                ),
                switchMap(([restaurants, users]: [Restaurant[], User[]]) =>
                    forkJoin([of(restaurants), of(users), this._organizationsService.index().pipe(map((res) => res.data))])
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(([restaurants, users, organizations]: [Restaurant[], User[], Organization[]]) => {
                this.dataSource.data = organizations.map((organization) =>
                    Object.assign(organization, {
                        restaurants: restaurants.filter((r) => r.organizationId === organization._id),
                        users: users.filter((u) => u.organizationIds.includes(organization._id)),
                    })
                );
                this._spinnerService.hide();
            });

        this.refresh$.next();
    }

    onSearchChange(searchValue: string): void {
        this.dataSource.filter = searchValue.trim().toLowerCase();
    }

    displayUsersListModal(users: User[]): void {
        this._customDialogService.open(UsersListModalComponent, {
            disableClose: false,
            data: {
                users,
            },
        });
    }

    displayRestaurantsListModal(organization: OrganizationWithData): void {
        this._customDialogService.open(RestaurantsListModalComponent, {
            disableClose: false,
            data: {
                restaurants: organization.restaurants,
                maxRestaurants: organization.limit,
            },
        });
    }

    delete(organizationId: string): void {
        this._dialogService.open({
            title: this.translate.instant('common.are_you_sure'),
            message: this.translate.instant('admin.organizations.delete_organization_confirmation'),
            variant: DialogVariant.ALERT,
            primaryButton: {
                label: this.translate.instant('common.yes'),
                action: () => {
                    this._spinnerService.show();
                    this._organizationsService.delete({ organizationId }).subscribe({
                        next: () => {
                            this._store.dispatch({ type: UserActions.loadUser.type });
                            this.refresh$.next();
                        },
                        error: (err) => {
                            if (err.status === 400) {
                                this._toastService.openErrorToast(
                                    this.translate.instant('admin.organizations.organization_has_restaurants')
                                );
                            } else {
                                console.error('err :>> ', err);
                                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                            }
                            this._spinnerService.hide();
                        },
                    });
                },
            },
            secondaryButton: {
                label: this.translate.instant('common.no'),
            },
        });
    }

    upsert(upsertKind: UpsertKind, organizationWithData?: OrganizationWithData): void {
        this._customDialogService
            .open(UpsertOrganizationModalComponent, {
                height: 'auto',
                data: {
                    upsertKind,
                    organization: organizationWithData,
                    users: organizationWithData?.users ?? [],
                },
            })
            .afterClosed()
            .subscribe((result: OrganizationUpsertForm) => {
                if (!result) {
                    return;
                }
                this._spinnerService.show();

                const body = {
                    name: result.name,
                    limit: result.limit,
                    verifiedEmailsForCampaigns: [],
                };

                const organizationId$ =
                    upsertKind === UpsertKind.INSERT
                        ? this._getCreateOrganization$(body).pipe(map((res) => res.id))
                        : this._getUpdateOrganization$(organizationWithData?._id, body).pipe(map((res) => res._id));
                organizationId$
                    .pipe(
                        switchMap((organizationId) =>
                            forkJoin(this._getUpdateUsers$(organizationId, organizationWithData?.users ?? [], result.users))
                        )
                    )
                    .subscribe({
                        complete: () => {
                            this._store.dispatch({ type: UserActions.loadUser.type });
                            this.refresh$.next();
                        },
                        error: (err) => {
                            console.error('err :>> ', err);
                            this._spinnerService.hide();
                            this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                        },
                    });
            });
    }

    private _getCreateOrganization$(body: CreateOrganizationRequestBodyDto): Observable<CreateOrganizationResponseBodyDto> {
        return this._organizationsService.create(body).pipe(map((res) => res.data));
    }

    private _getUpdateOrganization$(organizationId: string | undefined, partial: Partial<Organization>): Observable<Organization> {
        if (organizationId) {
            return this._organizationsService.update(organizationId, partial).pipe(map((res) => res.data));
        }
        return throwError(() => new Error('Organization id is missing'));
    }

    private _getUpdateUsers$(organizationId: string, currentUsers: User[], newUsers: User[]): Observable<any>[] {
        const usersToAdd = differenceBy(newUsers, currentUsers, '_id');
        const usersToRemove = differenceBy(currentUsers, newUsers, '_id');

        return [
            ...usersToAdd.map((user) =>
                this._adminService.updateAccount(user._id, {
                    organizationIds: [...user.organizationIds, organizationId],
                })
            ),
            ...usersToRemove.map((user) =>
                this._usersService.updateUserOrganizations(user._id, {
                    organizationIds: user.organizationIds.filter((id) => id !== organizationId),
                })
            ),
        ];
    }
}
