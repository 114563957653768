import { NgClass, NgStyle } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { map } from 'rxjs';

import { PostsService } from ':core/services/posts.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { CommentOrigin, CommentsFilters, Platform } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

import { PostWithComments, PostWithCommentsAndMentions } from '../comments.interface';
import { CommentsService } from '../comments.service';

@Component({
    selector: 'app-post-comment-preview',
    templateUrl: './post-comment-preview.component.html',
    styleUrls: ['./post-comment-preview.component.scss'],
    standalone: true,
    imports: [
        MatTooltipModule,
        MatButtonModule,
        NgClass,
        MatIconModule,
        NgStyle,
        SocialPostMediaComponent,
        PlatformLogoPathResolverPipe,
        PluralTranslatePipe,
        TranslateModule,
        FormatDatePipe,
        LazyLoadImageModule,
        ApplySelfPurePipe,
    ],
})
export class PostCommentPreviewComponent implements OnInit {
    @Input() post: PostWithCommentsAndMentions;
    @Input() filters: CommentsFilters;
    @Input() platforms: Platform[];
    @Output() archived = new EventEmitter<{ post: PostWithCommentsAndMentions; archived: boolean }>();

    readonly SvgIcon = SvgIcon;

    constructor(
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _commentsService: CommentsService,
        private readonly _translate: TranslateService,
        private readonly _postsService: PostsService,
        private readonly _http: HttpClient,
        private readonly _router: Router,
        private readonly _activeRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.post.http = this._http;
        this.post.initWorkingPic('original');
    }

    getArchiveTooltipText(post: PostWithCommentsAndMentions): string {
        return post.areAllCommentsAndMentionsArchived
            ? this._translate.instant('moderation.unarchive_post_comments')
            : this._translate.instant('moderation.archive_post_comments');
    }

    getIsPhoneScreen(): boolean {
        return this._screenSizeService.isPhoneScreen;
    }

    toggleArchived(post: PostWithCommentsAndMentions): void {
        post.areAllCommentsAndMentionsArchived = !post.areAllCommentsAndMentionsArchived;
        this._commentsService.archiveCommentsForPost(post.socialId, !post.areAllCommentsAndMentionsArchived).subscribe({
            error: (err) => {
                console.warn(err);
                post.areAllCommentsAndMentionsArchived = !post.areAllCommentsAndMentionsArchived;
            },
        });

        if (post.areAllCommentsAndMentionsArchived) {
            this.archived.emit({ post, archived: true });
        }
    }

    openSocialLink(post: PostWithCommentsAndMentions): void {
        if (post.socialLink) {
            window.open(post.socialLink, '_blank');
        }
    }

    isAVideo(post: PostWithCommentsAndMentions): boolean {
        return (
            post.socialAttachments[0]?.type === 'video' ||
            post.socialAttachments[0]?.urls?.original.includes('video-') ||
            post.socialAttachments[0]?.urls?.original.includes('.mp4')
        );
    }

    refreshPost(post: PostWithCommentsAndMentions): void {
        this._postsService
            .refresh(post?._id)
            .pipe(map((res) => res.data))
            .subscribe({
                next: (updatedPost) => {
                    if (updatedPost?._id) {
                        const partialPost = new PostWithComments(updatedPost) as unknown as PostWithCommentsAndMentions;
                        partialPost.areAllCommentsAndMentionsArchived = this.post.areAllCommentsAndMentionsArchived;
                        partialPost.unansweredComments = this.post.unansweredComments;
                        partialPost.unansweredMentions = this.post.unansweredMentions;
                        this.post = partialPost;
                    }
                },
                error: (err) => console.error('fail silently :>> ', err),
            });
    }

    openAnswerModal(): void {
        // navigate to currentRoute with queryParam comment_id
        const comment = this.post.getFirstUnAnsweredCommentOrMention();
        let commentId: string | undefined;
        let mentionId: string | undefined;
        if (comment?.type === CommentOrigin.COMMENT) {
            commentId = comment?._id;
        } else {
            mentionId = comment?._id;
        }

        this._router.navigate([], {
            relativeTo: this._activeRoute,
            queryParams: {
                comment_id: commentId,
                mention_id: mentionId,
                from_posts: true,
                post_social_id: this.post.socialId,
            },
            queryParamsHandling: 'merge',
        });
    }
}
