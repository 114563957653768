import { EntityConstructor, LaunchState } from '@malou-io/package-utils';

export class GoogleCommunicationsAgent {
    name: string;
    businessMessagesAgent: {
        conversationalSettings: ConversationalSettings;
    };
    launchState: LaunchState;

    public constructor(init: EntityConstructor<GoogleCommunicationsAgent>) {
        this.name = init.name;
        this.businessMessagesAgent = init.businessMessagesAgent;
        this.launchState = init.launchState;
    }

    setWelcomeMessage(text: string, locale: string): void {
        this.businessMessagesAgent.conversationalSettings[locale].welcomeMessage.text = text;
    }

    toDto() {
        return {
            name: this.name,
            businessMessagesAgent: this.businessMessagesAgent,
            launchState: this.launchState,
        };
    }

    copyWith(agent: Partial<GoogleCommunicationsAgent>): GoogleCommunicationsAgent {
        return new GoogleCommunicationsAgent({
            ...this,
            ...agent,
        });
    }
}

export interface ConversationalSettings {
    [key: string]: {
        welcomeMessage: {
            text: string;
        };
    };
}

export class GoogleCommunicationsLocation {
    name: string;
    placeId: string;
    locationEntryPointConfigs: [
        {
            allowedEntryPoint: string;
        },
    ];
    agent: string;
    locationTestUrl: string;
    testUrls: [
        {
            surface: string;
            url: string;
        },
    ];
    conversationalSettings?: ConversationalSettings;
    defaultLocale: string;
    listingId: string;
}
