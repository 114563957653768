import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

import { TimeInMilliseconds } from '@malou-io/package-utils';

import { times } from ':core/constants';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { MyDate, Restaurant, SpecialTimePeriod } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';

import { SpecialHourInterval, SpecialHoursService } from './special-hours-interval.service';

interface ISpecialTimePeriod<T extends Date | DateTime> {
    startDate: T;
    openTime: string;
    endDate: T;
    closeTime: string;
    isClosed: boolean;
}

@Component({
    selector: 'app-special-hours-form',
    templateUrl: './special-hours-form.component.html',
    styleUrls: ['./special-hours-form.component.scss'],
    standalone: true,
    imports: [
        InputDatePickerComponent,
        NgTemplateOutlet,
        FormsModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        TranslateModule,
        SlideToggleComponent,
        FormatTimePipe,
    ],
})
export class SpecialHoursFormComponent implements OnInit {
    @Input() prefilledStartDate: Date | undefined;
    @Input() showAnteriorHours: boolean;
    @Output() updateSpecialHours = new EventEmitter<{ specialHours: SpecialTimePeriod[] }>();
    @Output() hasError = new EventEmitter<boolean>();
    restaurant: Restaurant;
    hours: SpecialTimePeriod[] = [];
    times: string[] = times;
    isRestaurantClosed: boolean;

    specialHoursForm: UntypedFormGroup;

    constructor(
        public readonly screenSizeService: ScreenSizeService,
        private readonly _dialogRef: MatDialogRef<SpecialHoursFormComponent>,
        private readonly _fb: UntypedFormBuilder,
        private readonly _specialHoursService: SpecialHoursService,
        private readonly _translateService: TranslateService
    ) {
        this.specialHoursForm = this._fb.group({
            formHours: this._fb.array([]),
        });
    }

    // getter for form "formHours" FormArray value
    get formHours(): UntypedFormArray {
        return this.specialHoursForm.get('formHours') as UntypedFormArray;
    }

    @Input() set rest(value: Restaurant) {
        this.restaurant = value;
    }

    @Input() set specialHours(value: SpecialTimePeriod[]) {
        this.hours = value;
    }

    @Input() set isClosedTemporarily(value: boolean) {
        this.isRestaurantClosed = value;
        if (value) {
            this.specialHoursForm.get('formHours')?.disable({ emitEvent: false });
        } else {
            this.specialHoursForm.get('formHours')?.enable({ emitEvent: false });
            this.specialHoursForm.value.formHours.forEach((hour, i) => {
                if (hour.isClosed) {
                    const openTimeCtrl = this.specialHoursForm.get(['formHours', i, 'openTime']);
                    const closeTimeCtrl = this.specialHoursForm.get(['formHours', i, 'closeTime']);
                    openTimeCtrl?.setValue('');
                    openTimeCtrl?.disable();
                    closeTimeCtrl?.setValue('');
                    closeTimeCtrl?.disable();
                }
            });
        }
    }

    readonly SvgIcon = SvgIcon;

    ngOnInit(): void {
        this.prefilledStartDate = this.prefilledStartDate ? new Date(this.prefilledStartDate) : undefined;
        this.specialHoursForm.setControl('formHours', this.setExistingHours(this.hours));
        this.specialHoursForm.setValidators(this.closeTimeValidator());
        this.specialHoursForm.valueChanges.subscribe((formValue) => {
            this.hasError.emit(this.specialHoursForm.invalid);
            this.save(formValue.formHours);
        });
    }

    /**
     * Transform restaurant regular hours into FormArray format
     * @param hours
     */
    setExistingHours(hours: SpecialTimePeriod[]): UntypedFormArray {
        const hoursFormArray = new UntypedFormArray([]);
        const formattedAsIntervals = this._specialHoursService.createIntervalsFromDates(hours);
        const formattedAsSpecialTimePeriods = this.formatIntervalToSpecialTimePeriod(formattedAsIntervals);
        formattedAsSpecialTimePeriods.forEach((h) => {
            hoursFormArray.push(
                this._fb.group({
                    openTime: new UntypedFormControl({ value: h.openTime === '24:00' ? '00:00' : h.openTime, disabled: h.isClosed }),
                    closeTime: new UntypedFormControl({ value: h.closeTime, disabled: h.isClosed }),
                    isClosed: new UntypedFormControl({ value: h.isClosed, disabled: false }),
                    startDate: h.startDate.getDate(),
                    endDate: h.endDate.getDate(),
                    isFutureDate: this.isFutureDate(h.startDate.getDate()),
                })
            );
        });
        if (this.prefilledStartDate) {
            hoursFormArray.push(
                this._fb.group({
                    startDate: this.prefilledStartDate,
                    endDate: null,
                    isClosed: new UntypedFormControl({ value: true, disabled: false }),
                    openTime: new UntypedFormControl({ value: null, disabled: true }),
                    closeTime: new UntypedFormControl({ value: null, disabled: true }),
                    isFutureDate: this.isFutureDate(this.prefilledStartDate),
                })
            );
        }
        return hoursFormArray;
    }

    isClosed(index: number): boolean {
        return this.specialHoursForm.get(['formHours', index, 'isClosed'])?.value;
    }

    formatIntervalToSpecialTimePeriod(intervals: SpecialHourInterval[]): SpecialTimePeriod[] {
        return intervals.flatMap((interval) =>
            interval.hours.map(
                (hourRange) =>
                    new SpecialTimePeriod({
                        startDate: interval.start,
                        endDate: interval.end,
                        openTime: hourRange.openTime,
                        closeTime: hourRange.closeTime,
                        isClosed: hourRange.isClosed,
                    })
            )
        );
    }

    validatorEndDate(hour): boolean {
        const startDate = hour.value.startDate;
        const endDate = hour.value.endDate;
        return endDate ? startDate <= endDate : true;
    }

    /**
     * Validate Close Time and OpenTime  // todo complete for more sophisticated conditions
     */
    closeTimeValidator(): ValidatorFn {
        return (): ValidationErrors | null => {
            this.formHours.controls.forEach((c) => {
                if (c.value.closeTime === c.value.openTime && !c.value.isClosed && c.touched) {
                    c.setErrors({ error: this._translateService.instant('information.special_hours.hours_should_be_different') });
                } else if (!this.validatorEndDate(c)) {
                    c.setErrors({ error: this._translateService.instant('information.special_hours.invalid_end_date') });
                } else if (this._isControlNotComplete(c)) {
                    c.setErrors({ error: this._translateService.instant('information.special_hours.should_not_be_empty') });
                } else {
                    c.setErrors(null);
                }
            });
            return null;
        };
    }

    close(event: boolean, i: number): void {
        this.specialHoursForm.get(['formHours', i, 'isClosed'])?.setValue(event);
        this.specialHoursForm.get(['formHours', i, 'isClosed'])?.markAsDirty();
        const openTimeCtrl = this.specialHoursForm.get(['formHours', i, 'openTime']);
        const closeTimeCtrl = this.specialHoursForm.get(['formHours', i, 'closeTime']);
        if (event) {
            openTimeCtrl?.setValue('');
            openTimeCtrl?.disable();
            closeTimeCtrl?.setValue('');
            closeTimeCtrl?.disable();
        } else {
            openTimeCtrl?.enable();
            closeTimeCtrl?.enable();
            this.updateFullDay(i);
        }
    }

    /**
     * delete a specific hour control
     * @param index
     */
    deleteHourInput(index: number): void {
        this.formHours.removeAt(index);
    }

    /**
     *
     * @param index
     */
    updateFullDay(index: number): void {
        const openTimeCtrl = this.specialHoursForm.get(['formHours', index, 'openTime']);
        const closeTimeCtrl = this.specialHoursForm.get(['formHours', index, 'closeTime']);

        if (this.specialHoursForm.value.formHours[index].openTime === '24-24') {
            closeTimeCtrl?.setValue('');
            closeTimeCtrl?.disable();
        } else {
            openTimeCtrl?.enable();
            closeTimeCtrl?.enable();
        }
    }

    updateToDateFormat(index: number): void {
        this.specialHoursForm.value.formHours[index].startDate = this.specialHoursForm.value.formHours
            ? this.specialHoursForm.value.formHours[index]?.startDate && new Date(this.specialHoursForm.value.formHours[index].startDate)
            : null;

        this.specialHoursForm.value.formHours[index].endDate = this.specialHoursForm.value.formHours
            ? this.specialHoursForm.value.formHours[index]?.endDate && new Date(this.specialHoursForm.value.formHours[index].endDate)
            : null;
    }

    isFutureDate(date: Date): boolean {
        return date.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    }

    public add(): void {
        const hour = this._fb.group({
            openTime: new UntypedFormControl({ value: '', disabled: true }),
            closeTime: new UntypedFormControl({ value: '', disabled: true }),
            isClosed: new UntypedFormControl({ value: true, disabled: false }),
            startDate: new Date(),
            endDate: new Date(),
            isFutureDate: true,
        });
        this.formHours.push(hour);
    }

    getNextDay(date: Date): Date {
        return this.getDayAfter(date, 1);
    }

    getDayAfter(date: Date, i: number): Date {
        const tomorrow = new Date(date);
        tomorrow.setDate(tomorrow.getDate() + i);
        return tomorrow;
    }

    getMyDateFromDate(date: Date | DateTime): MyDate | undefined {
        if (!date) {
            return undefined;
        }
        date = this._toDate(date);
        return new MyDate({
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
        });
    }

    save(hours: ISpecialTimePeriod<Date | DateTime>[]): void {
        const dayHours = this._createAllDaysHours(hours);
        const gmbHours = this._mapToGmbDates(dayHours);
        const filteredHours = this._filterFullHoursDays(gmbHours);
        const cleanHours = this._cleanEndDates(filteredHours);
        const updateData = this._removeOpenIfClose(cleanHours);
        this.updateSpecialHours.emit({ specialHours: updateData });
    }

    cancel(): void {
        this._dialogRef.close();
    }

    private _filterFullHoursDays(hours: SpecialTimePeriod[]): SpecialTimePeriod[] {
        return hours.map((hour) => {
            if (hour.openTime === '24-24') {
                hour.openTime = '00:00';
                hour.closeTime = '24:00';
            }
            return hour;
        });
    }

    private _createAllDaysHours(hours: ISpecialTimePeriod<Date | DateTime>[]): ISpecialTimePeriod<Date | DateTime>[] {
        return hours.reduce((acc: ISpecialTimePeriod<Date | DateTime>[], hour) => {
            if (!hour.endDate) {
                hour.endDate = hour.startDate;
            }
            const startDate: Date = this._getStartDateFromHour(hour);
            const endDate: Date = this._getEndDateFromHour(hour);
            const isInterval = startDate.getDate() !== endDate.getDate();
            if (isInterval) {
                const dates = this._generateIntervalDates(hour, startDate, endDate);
                acc.push(...dates);
            } else {
                acc.push(hour);
            }
            return acc;
        }, []);
    }
    private _getStartDateFromHour(hour: ISpecialTimePeriod<Date | DateTime>): Date {
        return this._toDate(hour.startDate);
    }

    private _getEndDateFromHour(hour: ISpecialTimePeriod<Date | DateTime>): Date {
        const date = this._toDate(hour.endDate);
        if (!date) {
            return this._getStartDateFromHour(hour);
        }
        return date;
    }

    private _toDate(date: Date | DateTime): Date {
        if (date?.hasOwnProperty('isLuxonDateTime')) {
            return (<DateTime>date)?.toJSDate();
        }
        return <Date>date;
    }

    private _generateIntervalDates(hour: ISpecialTimePeriod<Date | DateTime>, startDate: Date, endDate: Date): ISpecialTimePeriod<Date>[] {
        const start = startDate.getTime();
        const end = endDate.getTime();
        const diffDays = Math.ceil(Math.abs(end - start) / TimeInMilliseconds.DAY);
        const dates: ISpecialTimePeriod<Date>[] = [];
        for (let i = 0; i <= diffDays; i++) {
            const singleDate = this.getDayAfter(startDate, i);
            dates.push({
                startDate: singleDate,
                openTime: hour.openTime,
                endDate: singleDate,
                closeTime: hour.closeTime,
                isClosed: hour.isClosed,
            });
        }
        return dates;
    }

    private _mapToGmbDates(hours: ISpecialTimePeriod<Date | DateTime>[]): SpecialTimePeriod[] {
        return hours.map(
            (el) =>
                new SpecialTimePeriod({
                    startDate: this.getMyDateFromDate(el.startDate),
                    openTime: el.openTime,
                    endDate: this.getMyDateFromDate(el.endDate),
                    closeTime: el.closeTime,
                    isClosed: el.isClosed,
                })
        );
    }

    /**
     *
     * @param hours
     * Set end dates equal if openTime < closeTime, next day after start date if openTime > closeTime
     */
    private _cleanEndDates(hours: SpecialTimePeriod[]): SpecialTimePeriod[] {
        return hours.map((hour) => {
            const cleanHour = Object.assign({}, hour);
            if (hour.openTime && hour.closeTime && hour.openTime > hour.closeTime) {
                const endDate = this.getMyDateFromDate(this.getNextDay(hour.startDate.getDate()));
                if (endDate) {
                    cleanHour.endDate = endDate;
                }
            }
            return new SpecialTimePeriod(cleanHour);
        });
    }

    /**
     *
     * @param hours
     * Remove open periods if one period is found closed
     */
    private _removeOpenIfClose(hours: SpecialTimePeriod[]): SpecialTimePeriod[] {
        return hours.filter((hour) => {
            const hasClosedHourForSameDay = hours.find(
                (h) => h.isClosed && h.startDate.getDate().toString() === hour.startDate.getDate().toString()
            );
            return hour.isClosed || !hasClosedHourForSameDay;
        });
    }

    private _isControlNotComplete(control: AbstractControl): boolean {
        const timePeriod = this._filterFullHoursDays([cloneDeep(control.value)])[0];
        return (
            !control.pristine &&
            !control.value.isClosed &&
            [timePeriod.closeTime, timePeriod.openTime].some((time) => [null, '', undefined].includes(time))
        );
    }
}
