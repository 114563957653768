<div class="malou-dialog">
    <div class="malou-dialog__header">
        <p>
            {{ 'campaigns.report.campaign_report' | translate: { name: campaign.name, date: campaign.startDate | date } }}
        </p>
        <button class="malou-btn-icon" mat-icon-button color="primary" (click)="cancel()">
            <mat-icon class="icon-btn" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <div class="flex flex-col gap-4">
            <div class="flex flex-col rounded-[10px] border border-malou-color-border-primary bg-malou-color-background-light p-5">
                <div class="malou-text-12--bold text-malou-color-text-1">{{ 'campaigns.report.results' | translate }}</div>
                <div class="malou-text-10--regular italic text-malou-color-text-2">
                    {{ 'campaigns.report.results_description' | translate }}
                </div>
                <div class="flex flex-wrap md:gap-2">
                    <div class="ml-3 flex flex-1 flex-col-reverse justify-center gap-3 md:my-7 md:ml-1">
                        @for (rating of RATING_LIST; track rating) {
                            <div class="flex items-center gap-7">
                                <div class="malou-text-12--bold mt-1 text-malou-color-text-1">{{ ALL_STARS_CLICKS[rating] }}</div>
                                <app-star-gauge size="medium" [stars]="rating"></app-star-gauge>
                            </div>
                        }
                    </div>
                    <div class="flex flex-1 flex-col gap-2">
                        <div class="flex gap-2 md:flex-wrap">
                            <div
                                class="flex flex-1 flex-col rounded-[10px] border border-malou-color-border-primary bg-white px-6 py-2 md:min-w-[150px] md:px-3">
                                <div class="malou-text-40--bold text-malou-color-text-1">{{ reviewsCount }}</div>
                                <div class="malou-text-12--medium whitespace-nowrap text-malou-color-text-2">
                                    {{ 'campaigns.report.received_reviews' | translate }}
                                </div>
                            </div>
                            <div
                                class="flex flex-1 flex-col rounded-[10px] border border-malou-color-border-primary bg-white px-6 py-2 md:min-w-[150px] md:px-3">
                                <div class="malou-text-40--bold text-malou-color-text-1" [matTooltip]="positiveReviewsTooltip">
                                    {{ positiveReviews }}
                                </div>
                                <div class="malou-text-12--medium whitespace-nowrap text-malou-color-text-2">
                                    {{ 'campaigns.report.positive_reviews' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col rounded-[10px] border border-malou-color-border-primary bg-white px-6 py-2">
                            <div class="malou-text-40--bold text-malou-color-text-1">{{ negativeReviews }}</div>
                            <div class="malou-text-12--medium whitespace-nowrap text-malou-color-text-2">
                                {{ 'campaigns.report.negative_reviews_avoided' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rounded-[10px] border border-malou-color-border-primary bg-malou-color-background-light p-5">
                <div class="malou-text-12--bold mb-5 text-malou-color-text-1">{{ 'campaigns.report.campaign' | translate }}</div>
                <div class="grid grid-cols-3 gap-4 sm:grid-cols-1">
                    <div class="sm:col-span-1">
                        <div
                            class="flex h-full flex-col justify-end gap-5 rounded-[10px] border border-malou-color-border-primary bg-white px-6 pb-2 pt-4">
                            <div class="flex items-center gap-1">
                                @for (source of campaign.audience.sources; track source) {
                                    <div>
                                        @switch (source) {
                                            @case ('malou') {
                                                <img width="20" [src]="'malou' | platformLogoPathResolver" />
                                            }
                                            @case (PlatformKey.LAFOURCHETTE) {
                                                <img width="20" [src]="'lafourchette' | platformLogoPathResolver" />
                                            }
                                            @case (PlatformKey.ZENCHEF) {
                                                <img width="20" [src]="'zenchef' | platformLogoPathResolver" />
                                            }
                                            @case ('wheel_of_fortune') {
                                                <img width="20" [src]="'wheel_of_fortune' | platformLogoPathResolver" />
                                            }
                                            @default {
                                                <img width="20" [src]="'manual' | platformLogoPathResolver" />
                                            }
                                        }
                                    </div>
                                }
                            </div>
                            <div class="malou-text-12--medium whitespace-nowrap text-malou-color-text-2">
                                {{ 'campaigns.report.contacted_clients' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-1">
                        <div
                            class="flex h-full flex-col justify-end gap-5 rounded-[10px] border border-malou-color-border-primary bg-white px-6 pb-2 pt-4">
                            <div class="malou-text-40--bold text-malou-color-text-1">
                                <img class="malou-avatar--medium" [src]="campaign.platformKey | platformLogoPathResolver" />
                            </div>
                            <div class="malou-text-12--medium whitespace-nowrap text-malou-color-text-2">
                                {{ 'campaigns.report.redirection_platform' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-1">
                        <div
                            class="flex h-full flex-col justify-end gap-5 rounded-[10px] border border-malou-color-border-primary bg-white px-6 pb-2 pt-4">
                            <div class="malou-text-40--bold h-8 leading-none text-malou-color-text-1" [matTooltip]="sentEmailsTooltipText">
                                {{ getDisplayedValueOrNone(sentEmails, { isPercentage: false }) }}
                            </div>
                            <div class="malou-text-12--medium whitespace-nowrap text-malou-color-text-2">
                                {{ 'campaigns.report.sent_mails' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-1">
                        <div
                            class="flex h-full flex-col justify-end gap-5 rounded-[10px] border border-malou-color-border-primary bg-white px-6 pb-2 pt-4">
                            @if (deliveredEmails || hasOpeningStats) {
                                <div class="malou-text-40--bold h-8 leading-none text-malou-color-text-1">
                                    {{ getDisplayedValueOrNone(deliveredEmails, { isPercentage: false }) }}
                                </div>
                            } @else {
                                <div
                                    class="malou-text-40--bold h-8 leading-none text-malou-color-text-1"
                                    matTooltip="{{ 'campaigns.no_stats' | translate }}">
                                    -
                                </div>
                            }
                            <div class="malou-text-12--medium whitespace-nowrap text-malou-color-text-2">
                                {{ 'campaigns.report.delivered_emails' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-1">
                        <div
                            class="flex h-full flex-col justify-end gap-5 rounded-[10px] border border-malou-color-border-primary bg-white px-6 pb-2 pt-4">
                            <div class="malou-text-40--bold h-8 leading-none text-malou-color-text-1" [matTooltip]="responseRateTooltip">
                                {{ getDisplayedValueOrNone(responseRate, { isPercentage: true }) }}
                            </div>
                            <div class="malou-text-12--medium whitespace-nowrap text-malou-color-text-2">
                                {{ 'campaigns.report.response_rate' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-1">
                        <div
                            class="flex h-full flex-col justify-end gap-5 rounded-[10px] border border-malou-color-border-primary bg-white px-6 pb-2 pt-4">
                            <div class="malou-text-40--bold h-8 leading-none text-malou-color-text-1">
                                {{ getDisplayedValueOrNone(unsubscribedRate, { isPercentage: true }) }}
                            </div>
                            <div class="malou-text-12--medium whitespace-nowrap text-malou-color-text-2">
                                {{ 'campaigns.report.unsubscribed_rate' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                        <div
                            class="flex h-full flex-col justify-end gap-5 rounded-[10px] border border-malou-color-border-primary bg-white px-6 pb-2 pt-4">
                            <div class="malou-text-12--bold text-malou-color-text-1">{{ 'campaigns.your_email' | translate }}</div>
                            <div class="flex flex-col gap-4">
                                <p class="malou-text-12--regular text-malou-color-text-1">
                                    <span
                                        ><strong>{{ 'campaigns.report.object' | translate }}</strong></span
                                    >
                                    <span [innerHTML]="transformText(campaign.content.object)"></span>
                                </p>
                                <p class="malou-text-12--regular text-malou-color-text-1">
                                    <span
                                        ><strong>{{ 'campaigns.report.text' | translate }}</strong></span
                                    >
                                    <br />
                                    <span [innerHTML]="transformText(campaign.content.messageHTML)"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="malou-dialog__footer"></div>
</div>
