import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { HttpResponseBase } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter as _filter, intersection, reject, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { GetRestaurantsRankingsResponseDto } from '@malou-io/package-dto';
import { BusinessCategory, KeywordRanking } from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { compareWeekYear, getStatisticsError, isDateSetOrGenericPeriod } from ':shared/helpers';
import { GeoSample, Restaurant } from ':shared/models';
import { ValueError } from ':shared/models/value-error';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import * as AggregatedStatisticsSelector from '../../store/aggregated-statistics.selectors';

enum ToggleMode {
    ALL_KEYWORDS = 'ALL_KEYWORDS',
    COMMON_KEYWORDS = 'COMMON_KEYWORDS',
}

class RestaurantWithSamples {
    _id: string;
    placeId: string;
    name: string;
    internalName?: string;
    samples: GeoSample[];
    error?: {
        message: string;
    };
    displayedName: string;

    constructor(init: GetRestaurantsRankingsResponseDto) {
        Object.assign(this, init);
        this.displayedName = this.internalName ?? this.name;
        this.samples = init.samples?.map((sample) => GeoSample.fromGeoSampleDto(sample)) ?? [];
    }
}

class RestaurantWithKeywordsAndTop {
    restaurant: { _id: string; name: string };
    keywords: string[];
    keywordsTop20: string[];
    keywordsTop3: string[];
    top20: number;
    top3: number;

    constructor(init?: Partial<RestaurantWithKeywordsAndTop>) {
        Object.assign(this, init);
        this.keywords = [];
        this.keywordsTop20 = [];
        this.keywordsTop3 = [];
        this.top20 = 0;
        this.top3 = 0;
    }

    incrementTop20(): void {
        this.top20++;
    }

    incrementTop3(): void {
        this.top3++;
    }

    addKeyword(keyword: string): void {
        this.keywords.push(keyword);
    }

    addKeywordTop20(keyword: string): void {
        this.keywordsTop20.push(keyword);
    }

    addKeywordTop3(keyword: string): void {
        this.keywordsTop3.push(keyword);
    }
}

class KeywordTableDataRow {
    chartAxisName: string;
    restaurantId: string;
    restaurantName: string;
    keywords: string[];
    keywordsTop20: string[];
    keywordsTop3: string[];
    currentTop20: number;
    currentTop3: number;
    previousTop20: number;
    previousTop3: number;
    type: string;
    error?: string;

    constructor(
        init?: Partial<KeywordTableDataRow>,
        private _translationService?: TranslateService
    ) {
        if (init) {
            this.chartAxisName = init.restaurantName + ':ID:' + init.restaurantId;
            this.restaurantId = init.restaurantId ?? '';
            this.restaurantName = init.restaurantName ?? '';
            this.keywords = init.keywords ?? [];
            this.keywordsTop20 = init.keywordsTop20 ?? [];
            this.keywordsTop3 = init.keywordsTop3 ?? [];
            this.currentTop20 = init.currentTop20 ?? 0;
            this.currentTop3 = init.currentTop3 ?? 0;
            this.previousTop20 = init.previousTop20 ?? 0;
            this.previousTop3 = init.previousTop3 ?? 0;
            this.type = init.type ?? '';
        }
        this.setError(this.translateError());
    }

    getDiffTop20(): number {
        return this.currentTop20 - this.previousTop20;
    }

    getDiffTop3(): number {
        return this.currentTop3 - this.previousTop3;
    }

    getKeywordsToString(): string {
        return this.keywords?.map((k) => `${k}`).join(' - ');
    }

    getKeywordsTop20ToString(): string {
        return this.keywordsTop20?.map((k) => `- ${k}`).join('\n');
    }

    getKeywordsTop3ToString(): string {
        return this.keywordsTop3?.map((k) => `- ${k}`).join('\n');
    }

    hasError(): boolean {
        return !!this.error;
    }

    setError(error?: string): void {
        this.error = error;
    }

    getError(): string | undefined {
        return this.error;
    }

    isBrandBusiness(): boolean {
        return this.type === BusinessCategory.BRAND;
    }

    translateError(): string | undefined {
        if (this.error?.match(/The provided Place ID is no longer valid/)) {
            return this._translationService?.instant('aggregated_statistics.seo.keywords.cant_get_data');
        }
        return;
    }
}

enum Column {
    RESTAURANT_NAME = 'restaurantName',
    CURRENT_TOP_20 = 'currentTop20',
    CURRENT_TOP_3 = 'currentTop3',
    VIEW_KEYWORD_LIST = 'view_keywords_list',
}

@Component({
    selector: 'app-keywords',
    templateUrl: './keywords.component.html',
    styleUrls: ['./keywords.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTooltipModule,
        MatIconModule,
        MatButtonToggleModule,
        SortByFiltersComponent,
        MatTableModule,
        MatSortModule,
        SkeletonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        ApplySelfPurePipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
})
export class KeywordsComponent implements OnInit {
    @Input() shouldHideTableClickableElements = false;
    @Input() tableSortOptions: Sort | undefined = undefined;
    @Output() tableSortOptionsChange = new EventEmitter<Sort>();
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;
    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'restaurantName', label: this._translate.instant('aggregated_statistics.seo.keywords.restaurant') },
        { key: 'top20', label: this._translate.instant('aggregated_statistics.seo.keywords.top20') },
        { key: 'top3', label: this._translate.instant('aggregated_statistics.seo.keywords.top3') },
    ];

    defaultSort: Sort = { active: 'restaurantName', direction: 'asc' };

    dataSource = new MatTableDataSource<KeywordTableDataRow>();

    readonly Column = Column;
    DISPLAYED_COLUMNS: Column[];

    restaurantsWithSamples: RestaurantWithSamples[];

    readonly dates$ = this._store.select(AggregatedStatisticsSelector.selectDatesFilter);
    readonly selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;

    keywords$: Observable<ValueError<RestaurantWithSamples[], string>>;

    readonly isLoading$ = new BehaviorSubject(true);

    readonly ToggleMode = ToggleMode;

    warningTooltip?: string;

    constructor(
        private readonly _store: Store,
        private readonly _keywordsService: KeywordsService,
        private readonly _router: Router,
        private readonly _translate: TranslateService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        public screenSizeService: ScreenSizeService
    ) {
        this.isLoading$.subscribe((isLoading) => this.isLoadingEvent.emit(isLoading));
    }

    @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    ngOnInit(): void {
        this.keywords$ = this.loadKeywordsWithSamples$();
        if (this.tableSortOptions) {
            this.defaultSort = this.tableSortOptions;
        }
    }

    onSortChange(sort: Sort): void {
        if (sort.direction?.length) {
            this.tableSortOptionsChange.emit(sort);
        }
    }

    loadKeywordsWithSamples$(): Observable<ValueError<RestaurantWithSamples[], string>> {
        this.DISPLAYED_COLUMNS = this._getDisplayedColumns();
        return combineLatest([this.dates$, this.selectedRestaurants$]).pipe(
            filter(([dates]) => isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isLoading$.next(true);
            }),
            switchMap((values) => {
                const [_dates, selectedRestaurants] = values;
                if (selectedRestaurants.length === 0) {
                    return EMPTY;
                }
                return of(values);
            }),
            filter(([dates, _restaurants]) => {
                const { startDate, endDate } = dates;
                return !!startDate && !!endDate;
            }),
            switchMap(([dates, restaurants]) => {
                const endDate = dates.endDate as Date;
                const startDate = DateTime.fromJSDate(endDate).minus({ weeks: 2 }).toJSDate();
                const businessRestaurants = restaurants.filter((r) => r.isBrandBusiness());
                if (businessRestaurants.length) {
                    this.warningTooltip = this._computeWarningTooltip(businessRestaurants);
                }
                const restaurantIds = restaurants.filter((r) => !r.isBrandBusiness()).map((r) => r._id);
                return this._keywordsService
                    .getRankingsForUserRestaurants(startDate, endDate, restaurantIds)
                    .pipe(
                        map(({ data: values }) => values.map((restaurantWithSamples) => new RestaurantWithSamples(restaurantWithSamples)))
                    )
                    .pipe(catchError(() => of(null)));
            }),
            tap((restaurantsWithSamples: RestaurantWithSamples[]) => {
                this.restaurantsWithSamples = restaurantsWithSamples ?? [];
                this.isLoading$.next(false);
            }),
            map((values) => ({ value: values })),
            catchError((error) =>
                of({
                    error: error instanceof HttpResponseBase ? getStatisticsError(error) : error.message,
                })
            ),
            tap(() => {
                this._setDatasource(ToggleMode.ALL_KEYWORDS);
                this.isLoading$.next(false);
            })
        );
    }

    getRestaurantsWithKeywordsAndTop(restaurantsWithSamples: RestaurantWithSamples[]): RestaurantWithKeywordsAndTop[] {
        const result: RestaurantWithKeywordsAndTop[] = [];
        restaurantsWithSamples.forEach((restaurant) => {
            if (restaurant.samples?.length > 0) {
                // sort by year Number and week Number
                const samples = [...restaurant.samples];
                let sortedSamples = samples.sort(compareWeekYear);
                // Take only samples of last week
                const { week: largestWeek, year: largestYear } = sortedSamples[sortedSamples.length - 1];
                sortedSamples = sortedSamples.filter((sample) => sample.week === largestWeek && sample.year === largestYear);
                const restaurantWithKeywordsAndTop = new RestaurantWithKeywordsAndTop({ restaurant });
                while (sortedSamples.length > 0) {
                    const { keyword } = sortedSamples[0];
                    restaurantWithKeywordsAndTop.addKeyword(keyword);
                    // for each keyword ...
                    let samplesFilteredByKeyword = _filter(sortedSamples, { keyword });
                    while (samplesFilteredByKeyword.length > 0) {
                        const { week, year } = samplesFilteredByKeyword[0];
                        // we take his samples (usually 4 samples)
                        const samplesFilteredByKeywordWeekAndYear = _filter(samplesFilteredByKeyword, { week, year });
                        const keywordRanking = new KeywordRanking(samplesFilteredByKeywordWeekAndYear);
                        // get average position of his samples
                        const position = keywordRanking.getPositionByPlaceId(restaurant.placeId);
                        if (position && position.rank <= 20) {
                            restaurantWithKeywordsAndTop.incrementTop20();
                            restaurantWithKeywordsAndTop.addKeywordTop20(keyword);
                            if (position.rank <= 3) {
                                restaurantWithKeywordsAndTop.incrementTop3();
                                restaurantWithKeywordsAndTop.addKeywordTop3(keyword);
                            }
                        }
                        samplesFilteredByKeyword = reject(samplesFilteredByKeyword, { keyword, week, year });
                    }
                    // remove all samples by current keyword
                    sortedSamples = reject(sortedSamples, { keyword });
                }
                result.push(restaurantWithKeywordsAndTop);
            }
        });
        return result;
    }

    goToKeywordsStats(keywordTableDataRow: KeywordTableDataRow): void {
        void this._router.navigate([`/restaurants/${keywordTableDataRow.restaurantId}/statistics/seo`]);
    }

    onToggleChange(event: MatButtonToggleChange): void {
        this._setDatasource(event.value);
    }

    onSortByChange(sortBy: string): void {
        this.dataSource.sort?.sort({ id: sortBy, start: this.dataSource.sort.direction || 'asc', disableClear: true });
    }

    onSortOrderChange(): void {
        const direction = this.dataSource.sort?.direction === 'asc' ? 'desc' : 'asc';
        this.dataSource.sort?.sort({ id: this.dataSource.sort.active, start: direction, disableClear: true });
    }

    private _setDatasource(toggleMode: ToggleMode): void {
        let restaurantsWithKeywordsAndTop: RestaurantWithKeywordsAndTop[] = [];
        switch (toggleMode) {
            case ToggleMode.ALL_KEYWORDS:
                restaurantsWithKeywordsAndTop = this.getRestaurantsWithKeywordsAndTop(this.restaurantsWithSamples);
                break;
            case ToggleMode.COMMON_KEYWORDS:
                const keywordsOfKeywords: string[][] = this.restaurantsWithSamples.map((elem) =>
                    uniq(elem.samples.map((sample) => sample.keyword))
                );
                const commonKeywords = intersection(...keywordsOfKeywords);
                if (commonKeywords.length) {
                    const restaurantsWithSamplesFilteredByCommonKeywords = this.restaurantsWithSamples.map((r) => {
                        // assignment statement, need to clone this variable to keep original object
                        const restaurantWithSamples = { ...r };
                        restaurantWithSamples.samples = r.samples.filter((s) => commonKeywords.includes(s.keyword));
                        return restaurantWithSamples;
                    });

                    restaurantsWithKeywordsAndTop = this.getRestaurantsWithKeywordsAndTop(restaurantsWithSamplesFilteredByCommonKeywords);
                }
                break;
        }
        const keywordTableDataRow: KeywordTableDataRow[] = this.restaurantsWithSamples.map((r) => {
            const { _id } = r;
            const keywords = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === _id)?.keywords || [];
            const keywordsTop20 = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === _id)?.keywordsTop20 || [];
            const keywordsTop3 = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === _id)?.keywordsTop3 || [];
            const currentTop20 = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === _id)?.top20 || 0;
            const currentTop3 = restaurantsWithKeywordsAndTop?.find((rest) => rest.restaurant._id === _id)?.top3 || 0;

            return new KeywordTableDataRow(
                {
                    restaurantId: r._id,
                    restaurantName: r.displayedName,
                    keywords,
                    keywordsTop20,
                    keywordsTop3,
                    currentTop20,
                    currentTop3,
                    error: r.error?.message,
                },
                this._translate
            );
        });
        this.dataSource = new MatTableDataSource<KeywordTableDataRow>(keywordTableDataRow);
        if (!keywordTableDataRow.length) {
            this.hasDataChange.emit();
        }
    }

    private _computeWarningTooltip(restaurants: Restaurant[] | undefined): string | undefined {
        if (restaurants) {
            const restaurantsLabel = restaurants.map((e) => e.name).join(', ');
            return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
                restaurants: restaurantsLabel,
            });
        }
    }

    private _getDisplayedColumns(): Column[] {
        return this.shouldHideTableClickableElements
            ? [Column.RESTAURANT_NAME, Column.CURRENT_TOP_20, Column.CURRENT_TOP_3]
            : Object.values(Column);
    }
}
