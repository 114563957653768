export enum LocationType {
    INDEPENDENT_RESTAURANT = 'INDEPENDENT_RESTAURANT',
    GROUPE_OF_RESTAURANTS = 'GROUPE_OF_RESTAURANTS',
    HOTEL_RESTAURANT = 'HOTEL_RESTAURANT',
    HOTEL = 'HOTEL',
    BAKERY_PASTRY = 'BAKERY_PASTRY',
    COCKTAIL_BAR = 'COCKTAIL_BAR',
    CATERER = 'CATERER',
    AGENCY_OR_COMMUNITY_MANAGER = 'AGENCY_OR_COMMUNITY_MANAGER',
    TECH_PARTNER = 'TECH_PARTNER',
    INFLUENCER = 'INFLUENCER',
    OTHER = 'OTHER',
}

export enum NumberOfLocations {
    ONE = 'ONE',
    TWO_TO_FIVE = 'TWO_TO_FIVE',
    SIX_TO_TEN = 'SIX_TO_TEN',
    ELEVEN_TO_TWENTY = 'ELEVEN_TO_TWENTY',
    TWENTY_ONE_TO_FIFTY = 'TWENTY_ONE_TO_FIFTY',
    FIFTY_TO_ONE_HUNDRED = 'FIFTY_TO_ONE_HUNDRED',
    MORE_THAN_ONE_HUNDRED = 'MORE_THAN_ONE_HUNDRED',
}
