import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { notionLinks } from '../../constants';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    standalone: true,
    imports: [StarGaugeComponent, MatButtonModule, IllustrationPathResolverPipe, ImagePathResolverPipe, TranslateModule],
})
export class PageNotFoundComponent {
    faqLink = notionLinks.GENERAL;
    constructor(private readonly _router: Router) {}

    redirectToHome(): void {
        this._router.navigate(['/']);
    }
}
