<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false"> </app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <span>
                {{ 'information.attributes.modal.title' | translate }}
                <span class="font-normal italic md:hidden">{{
                    'information.attributes.modal.number_of_attributes'
                        | translate: { populated: restaurantAttributes.length, attributes_length: allAttributes.length }
                }}</span>
            </span>
            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>
        <div class="malou-dialog__body">
            <form class="flex flex-col" [formGroup]="attributesForm">
                <div class="sticky top-0 z-10 mb-4 bg-white pb-5">
                    <app-search
                        [debounceTime]="0"
                        [placeholder]="'common.search' | translate"
                        (searchChange)="onSearchChange($event)"></app-search>
                    <div class="mt-4 flex w-full justify-end">
                        <div class="malou-text-12--bold malou-color-text-1 flex w-[200px] justify-between leading-5 md:w-[165px]">
                            <span class="w-[65px] text-center">{{ 'common.yes' | translate }}</span>
                            <span class="w-[65px] text-center">{{ 'common.no' | translate }}</span>
                            <span class="w-[65px] text-center">{{ 'information.attributes.not_informed' | translate }}</span>
                        </div>
                    </div>
                </div>
                <!-- Form radios -->
                <div class="overflow-x-hidden md:mt-4 md:overflow-visible" formArrayName="formAttributeValues">
                    <div class="flex flex-col">
                        @for (attribute of attributesFiltered; track attribute; let i = $index) {
                            <div class="mb-5 flex items-center justify-between" [formGroupName]="i">
                                <span class="malou-text-14--regular malou-color-text-2">
                                    {{ attribute | applySelfPure: 'getAttributeNameForLang' : currentLang }}
                                </span>
                                <div>
                                    <mat-radio-group
                                        class="flex w-[200px] flex-wrap justify-between md:w-[165px]"
                                        color="primary"
                                        formControlName="attributeValue">
                                        <mat-radio-button class="w-[55px]" [value]="RestaurantAttributeValue.YES"></mat-radio-button>
                                        <mat-radio-button class="w-[55px]" [value]="RestaurantAttributeValue.NO"></mat-radio-button>
                                        <mat-radio-button class="w-[55px]" selected [value]="RestaurantAttributeValue.NOT_CONCERNED">
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
        <ng-container *ngTemplateOutlet="footerButtonsTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #footerButtonsTemplate>
    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        @if (isPlatformsUpdatesReleaseEnabled$ | async) {
            <button
                class="malou-btn-raised--primary !h-11 md:grow"
                mat-raised-button
                [disabled]="!attributesForm.valid || attributesForm.pristine"
                (click)="save()">
                {{ 'common.update' | translate }}
            </button>
        } @else {
            <button
                class="malou-btn-raised--primary !h-11 md:grow"
                mat-raised-button
                [disabled]="!attributesForm.valid || attributesForm.pristine"
                (click)="save()">
                {{ 'common.save' | translate }}
            </button>
        }
    </div>
</ng-template>

<ng-template #loadingModal>
    <!-- Loading modal with shimmer -->
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <div class="w-full">
                <div class="mb-4 flex justify-between">
                    <div class="w-full">
                        <app-skeleton skeletonClass="!w-[60%] !h-12.5"></app-skeleton>
                    </div>
                    <button class="malou-btn-icon" mat-icon-button (click)="close()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                    </button>
                </div>

                <app-skeleton skeletonClass="w-full !h-12.5"></app-skeleton>
            </div>
        </div>
        <div class="malou-dialog__body w-full">
            @for (item of [1, 2, 3, 4]; track item) {
                <div class="mb-3 flex w-full justify-between">
                    <div class="w-full">
                        <app-skeleton skeletonClass="!w-[60%] !h-[40px]"></app-skeleton>
                    </div>
                    <app-skeleton
                        flexDirection="flex-row"
                        gapClass="gap-x-2"
                        skeletonClass="!h-[40px] !w-[40px]"
                        [count]="3"></app-skeleton>
                </div>
            }
        </div>
        <div class="malou-dialog__footer">
            <div class="w-[30%]">
                <app-skeleton skeletonClass="!h-12.5 !w-full"></app-skeleton>
            </div>
        </div>
    </div>
</ng-template>
