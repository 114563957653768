<span class="malou-text-10--semibold flex items-center text-malou-color-text-2">
    <mat-icon
        class="mr-4 !h-6 !w-6 rounded bg-malou-color-state-error--light bg-opacity-30 p-1 text-malou-color-state-error"
        [svgIcon]="SvgIcon.CROSS"></mat-icon>
    @switch (platformAccessStatus()) {
        @case (PlatformAccessStatus.MISSING_PERMISSIONS) {
            {{ 'informations.missing_permissions_state' | translate: { platformName: platformKey() | platformName } }}
        }
        @case (PlatformAccessStatus.BAD_ACCESS) {
            @if ([PlatformKey.PAGESJAUNES, PlatformKey.DELIVEROO, PlatformKey.LAFOURCHETTE] | includes: platformKey()) {
                {{ 'informations.invalid_login_password' | translate }}
            } @else if ([PlatformKey.TRIPADVISOR, PlatformKey.YELP, PlatformKey.FOURSQUARE, PlatformKey.RESY] | includes: platformKey()) {
                {{ 'informations.missing_access' | translate }}
            } @else {
                {{ 'informations.invalid_login_password' | translate }}
            }
        }
        @case (PlatformAccessStatus.UNCLAIMED_PAGE) {
            {{ 'informations.unclaimed_page' | translate }}
        }
        @case (PlatformAccessStatus.NOT_FOUND) {
            {{ 'informations.not_found' | translate }}
        }
        @case (PlatformAccessStatus.INVALID_PAGE) {
            {{ 'informations.platform_validation_process' | translate }}
        }
        <!-- not sure that this status still exists. -->
        @case (PlatformAccessStatus.UNVERIFIED) {
            {{ 'platforms.connection.unverified' | translate }}
        }
        <!-- not sure that this status still exists. -->
        @case (PlatformAccessStatus.FAILED) {
            {{ 'platforms.connection.failed' | translate }}
        }
        @default {}
    }
</span>
