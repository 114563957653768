@if (isLoading()) {
    <div class="flex h-full items-center justify-center">
        <app-malou-spinner></app-malou-spinner>
    </div>
} @else {
    <div class="flex h-full flex-col">
        <app-navigation-header containerClass="mx-8.5 my-5 md:my-1" [tabs]="tabs"></app-navigation-header>
        <div class="hide-scrollbar h-full overflow-y-auto" id="aggregated-statistics">
            <router-outlet></router-outlet>
        </div>
        <app-scroll-to-top class="fixed bottom-7.5 right-7.5" [container]="'#aggregated-statistics'"> </app-scroll-to-top>
    </div>
}
