import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';

@Component({
    selector: 'app-infinite-text-slide',
    templateUrl: './infinite-text-slide.component.html',
    styleUrls: ['./infinite-text-slide.component.scss'],
    animations: [
        trigger('textAnimation', [
            transition('* => *', [
                query(
                    ':enter',
                    [
                        stagger(1500, [
                            style({ transform: 'translateY(-100px)', opacity: '0' }),
                            animate(
                                '2.5s',
                                keyframes([
                                    style({ transform: 'translateY(-100px)', opacity: '1' }),
                                    style({ transform: 'translateY(0)', opacity: '1' }),
                                    style({ transform: 'translateY(0)', opacity: '1' }),
                                    style({ transform: 'translateY(100px)', opacity: '0' }),
                                ])
                            ),
                        ]),
                    ],
                    { optional: true }
                ),
            ]),
        ]),
    ],
    standalone: true,
    imports: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteTextSlideComponent {
    readonly defaultText = input.required<string>();
    readonly slideTextList = input.required<string[]>();

    readonly displayAnimation = signal(true);

    onAnimationDone(): void {
        this.displayAnimation.set(false);
        setTimeout(() => {
            this.displayAnimation.set(true);
        }, 0);
    }
}
