<div class="grid h-full w-full place-items-center md:min-h-[60vh]">
    <div class="flex flex-col items-center text-center">
        @if (error) {
            <img class="mb-10" height="127" width="127" [src]="'Cape' | illustrationPathResolver" />
            <div class="malou-text-14--bold mb-2">{{ 'moderation.error_occured' | translate }}</div>
            <div class="malou-text-10--regular" [innerHTML]="error | httpError"></div>
        } @else {
            <img class="mb-10" height="127" width="127" [src]="'Goggles' | illustrationPathResolver" />
            <div class="malou-text-14--bold malou-color-text-1 mb-2">{{ 'moderation.comments.no_comments' | translate }}</div>
            @if (hasSearch) {
                <div class="malou-text-10--regular" [innerHTML]="'moderation.comments.no_comment_found_hint' | translate"></div>
            } @else {
                <div class="malou-text-10--regular" [innerHTML]="'moderation.comments.no_comment_hint' | translate"></div>
            }
        }
    </div>
</div>
