import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { IStoryVideoCheck } from ':core/constants';

enum VideoError {
    DURATION = 'duration',
    WIDTH = 'width',
    EXTENSION = 'extension',
    RATIO = 'ratio',
}

@Component({
    selector: 'app-story-video-error',
    templateUrl: './story-video-error.component.html',
    styleUrls: ['./story-video-error.component.scss'],
    standalone: true,
    imports: [TranslateModule],
    providers: [TranslateModule],
})
export class StoryVideoErrorComponent implements OnInit {
    @Input() videoValidity: IStoryVideoCheck;
    errors: VideoError[] = [];
    readableErrorMapping: Record<VideoError, string>;

    constructor(private readonly _translate: TranslateService) {}

    ngOnInit(): void {
        if (!this.videoValidity) {
            return;
        }
        if (!this.videoValidity.isDurationValid) {
            this.errors.push(VideoError.DURATION);
        }
        if (!this.videoValidity.isWidthValid) {
            this.errors.push(VideoError.WIDTH);
        }
        if (!this.videoValidity.isExtensionValid) {
            this.errors.push(VideoError.EXTENSION);
        }
        if (!this.videoValidity.isRatioValid) {
            this.errors.push(VideoError.RATIO);
        }

        this.readableErrorMapping = {
            [VideoError.DURATION]: this._translate.instant('story.video.error.duration'),
            [VideoError.WIDTH]: this._translate.instant('story.video.error.width'),
            [VideoError.EXTENSION]: this._translate.instant('story.video.error.extension'),
            [VideoError.RATIO]: this._translate.instant('story.video.error.ratio'),
        };
    }
}
