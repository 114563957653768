import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ToastService } from ':core/services/toast.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

import * as UserActions from '../store/user.actions';
import { User } from '../user';
import { UsersService } from '../users.services';

interface AppState {
    user: any;
}

@Component({
    selector: 'app-notifications',
    standalone: true,
    templateUrl: './notifications-settings.component.html',
    styleUrls: ['./notifications-settings.component.scss'],
    imports: [SlideToggleComponent, TranslateModule, NgTemplateOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsSettingsComponent implements OnInit {
    wantsFeedbackNotifications = false;
    readonly wantsReviewReplyReminderNotification: WritableSignal<boolean> = signal(true);

    user: User | null = null;

    shouldRedirect = false;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _store: Store<AppState>,
        private readonly _userService: UsersService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _translateService: TranslateService,
        private readonly _changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.shouldRedirect) {
            return;
        }

        if (this._route.snapshot.queryParams.unsubscribed) {
            const isUnsubscribed = this._route.snapshot.queryParams.unsubscribed === 'true';
            const field = this._route.snapshot.queryParams.field;
            this._showUnsubscribedToast(isUnsubscribed, field);
        }

        this._store.select(selectUserInfos).subscribe((user) => {
            this.user = user;
            this.wantsFeedbackNotifications = this.user?.settings?.receiveFeedbacks ?? false;
            this.wantsReviewReplyReminderNotification.set(this.user?.settings?.notifications.email.reviewReplyReminder.active ?? false);
        });
    }

    toggleReceiveFeedback(): void {
        if (!this.user?.settings) {
            return;
        }
        this.wantsFeedbackNotifications = !this.wantsFeedbackNotifications;
        const settingsUpdate = {
            ...this.user.settings,
            receiveFeedbacks: this.wantsFeedbackNotifications,
        };
        this._updateUserSettings(settingsUpdate);
        this._changeDetector.markForCheck();
    }

    toggleReceiveReviewReplyReminderNotifications(): void {
        if (!this.user?.settings) {
            return;
        }
        const updatedValue = !this.wantsReviewReplyReminderNotification();
        const updatedSettings = {
            ...this.user.settings,
            notifications: {
                ...this.user.settings.notifications,
                email: {
                    ...this.user.settings.notifications.email,
                    reviewReplyReminder: {
                        active: updatedValue,
                    },
                },
            },
        };
        this._updateUserSettings(updatedSettings);
        this._changeDetector.markForCheck();
    }

    private _updateUserSettings(update: User['settings']): void {
        if (!this.user) {
            return;
        }
        this._userService.update(this.user._id, { settings: update }).subscribe({
            next: (res) => {
                this.user = new User(res.data);
                this._store.dispatch(UserActions.editUserInfos({ infos: new User(res.data) }));
            },
            error: (err) => {
                console.warn('err :>>', err);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    private _showUnsubscribedToast(isUnsubscribed: boolean, field: string): void {
        if (!isUnsubscribed) {
            this._toastService.openErrorToast(this._translateService.instant('admin.profile.error_update_user_settings'));
            return;
        }
        let prettyField;
        switch (field) {
            case 'receiveFeedbacks':
                prettyField = this._translateService.instant('admin.profile.unsubscribed_fields.feedbacks');
                break;
            case 'receiveMessagesNotifications':
                prettyField = this._translateService.instant('admin.profile.unsubscribed_fields.messages');
                break;
            default:
                prettyField = null;
        }

        const fullText = prettyField
            ? this._translateService.instant('admin.profile.unsubscribed_successfull_text') +
              this._translateService.instant('admin.profile.about') +
              prettyField
            : this._translateService.instant('admin.profile.unsubscribed_successfull_text');
        this._toastService.openSuccessToast(fullText);
    }
}
