<div class="mat-gradient flex h-full w-full">
    <div class="malou-color-bg-white flex w-3/5 flex-col rounded-r-[50px] md:w-full md:rounded-none">
        <div class="malou-sticky-logo">
            <img alt="malou logo" [src]="'malou_logo' | imagePathResolver" />
        </div>
        <div class="hide-scrollbar grid h-full place-items-center overflow-y-auto overflow-x-hidden sm:mt-3 md:px-5">
            <div class="w-8/12 md:w-full">
                <div class="icons mb-3">
                    <img alt="Burger illustration" [src]="'Burger' | illustrationPathResolver" />
                    <img alt="Karl illustration" [src]="'Karl' | illustrationPathResolver" />
                    <img alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <img alt="Goggles illustration" [src]="'Goggles' | illustrationPathResolver" />
                </div>
                <h1 class="md:!malou-text-35--semibold malou-text-heading malou-color-text-1">{{ 'login.welcome' | translate }}</h1>
                <h2 class="md:!malou-text-13--regular malou-text-15--regular mb-11">{{ 'login.connect_yourself' | translate }}</h2>
                <form class="malou-form-flex" [formGroup]="loginForm" (ngSubmit)="login()">
                    <app-input-text
                        class="mb-5"
                        formControlName="email"
                        [testId]="'login-email-input'"
                        [title]="'login.email' | translate"
                        [placeholder]="'email@address.com'"
                        [autocapitalize]="'none'"
                        [errorMessage]="!email?.valid && email?.dirty ? ('login.invalid_email' | translate) : ''">
                    </app-input-text>
                    <app-input-text
                        class="mb-3"
                        formControlName="password"
                        [testId]="'login-password-input'"
                        [title]="'login.password' | translate"
                        [password]="true"
                        [showEyeIcon]="true"
                        [errorMessage]="passwordError">
                    </app-input-text>
                    <div class="flex justify-end">
                        <div class="malou-text-10--regular malou-color-text-2 mb-5 cursor-pointer" (click)="goToForgotPassword()">
                            {{ 'login.forgot_password_link' | translate }}
                        </div>
                    </div>
                    <button
                        class="malou-btn-raised--primary !h-11"
                        data-testid="login-btn"
                        type="submit"
                        mat-raised-button
                        [disabled]="!loginForm.valid">
                        {{ 'login.connect' | translate }}
                    </button>
                </form>
                <div class="mt-[5vh] flex flex-col justify-center text-center md:!mt-4">
                    <p class="malou-text-10--regular">{{ 'login.no_account' | translate }}</p>
                    <button class="malou-btn-flat" mat-button>
                        <a class="malou-text-10--semibold" href="https://malou.io/contactez-nous" target="_blank">{{
                            'login.ask_for_demo' | translate
                        }}</a>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="w-2/5 items-center justify-center md:hidden md:w-0">
        <div class="flex h-full flex-col items-center justify-around">
            <p class="malou-text-title md:malou-text-section-title malou-color-white mt-4 px-14 text-center">
                {{ 'login.digital_for_growth' | translate }}
            </p>
            <img class="px-2" alt="Screen illustration" [src]="'Screen' | illustrationPathResolver" />
            <div class="malou-color-white malou-text-20--regular mb-4">
                <div>
                    {{ 'login.more' | translate }} <strong>{{ 'login.time' | translate }}</strong
                    >,
                </div>
                <div>
                    {{ 'login.more' | translate }} <strong>{{ 'login.visibility' | translate }}</strong
                    >,
                </div>
                <div>
                    {{ 'login.more' | translate }} <strong>{{ 'login.clients' | translate }}</strong>
                </div>
            </div>
        </div>
    </div>
</div>
