<div
    class="parent-container flex h-full flex-col gap-y-4 py-4 md:w-screen md:pt-0"
    [ngClass]="{ 'w-[250px]': !isSmallScreen() && isSidenavOpened(), 'w-[80px]': !isSmallScreen() && !isSidenavOpened() }">
    @if (!isSmallScreen()) {
        <ng-container [ngTemplateOutlet]="headerDesktopTemplate"></ng-container>
    }
    @if (isSmallScreen()) {
        <ng-container [ngTemplateOutlet]="headerMobileTemplate"></ng-container>
    }
    @if (!isSmallScreen()) {
        <ng-container [ngTemplateOutlet]="selectRestaurantDesktopTemplate"></ng-container>
    }
    @if (isSmallScreen()) {
        <ng-container [ngTemplateOutlet]="selectRestaurantDesktopTemplate"></ng-container>
    }
    @if (!isAllRestaurantsSelected()) {
        <ng-container [ngTemplateOutlet]="routesTemplate"></ng-container>
    }
    @if (isAllRestaurantsSelected()) {
        <ng-container [ngTemplateOutlet]="groupRoutesTemplate"></ng-container>
    }
</div>

<ng-template #headerDesktopTemplate>
    <div class="flex items-center gap-x-6 px-5">
        <app-sidenav-toggle-button></app-sidenav-toggle-button>
        @if (isSidenavOpened()) {
            <div class="shrink-0 cursor-pointer" routerLink="/">
                <img class="h-6" [src]="'malou_logo' | imagePathResolver" />
            </div>
        }
    </div>
</ng-template>

<ng-template #headerMobileTemplate>
    <app-header
        [showLogo]="true"
        [showRouting]="true"
        [showLangSelection]="true"
        [showProfile]="true"
        [showSidenavToggle]="true"
        [background]="'white'"></app-header>
</ng-template>

<ng-template #selectRestaurantDesktopTemplate>
    <div
        class="mx-8 flex cursor-pointer flex-col items-center"
        [ngClass]="{ '!mx-2': !isSidenavOpened() }"
        [matMenuTriggerFor]="restaurantsMenu">
        @if (isAllRestaurantsSelected()) {
            <ng-container
                [ngTemplateOutlet]="restaurantsLogosTemplate"
                [ngTemplateOutletContext]="{ restaurants: restaurants() }"></ng-container>
            @if (isSidenavOpened()) {
                <div class="mt-2 flex items-center justify-center gap-x-2">
                    <span class="malou-text-13--semibold malou-color-text-1 max-w-[200px] shrink-0">
                        {{ 'sidenav_content.all_businesses' | translate }}
                    </span>
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
                </div>
            }
        }
        @if (!isAllRestaurantsSelected()) {
            <img
                class="malou-avatar--small"
                [src]="(selectedRestaurant()?.logo | applySelfPure: 'getMediaUrl' : 'small') ?? ('default_logo' | imagePathResolver)" />
            @if (isSidenavOpened()) {
                <div class="mt-2 flex items-center justify-center gap-x-2">
                    <span class="malou-text-13--semibold malou-color-text-1 max-w-[200px] truncate">
                        {{ selectedRestaurant()?.internalName }}
                    </span>
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
                </div>
            }
            @if (isSidenavOpened()) {
                <div
                    class="malou-text-10--regular malou-color-text-2 max-w-full truncate italic"
                    [matTooltip]="
                        (selectedRestaurant() | applySelfPure: 'isBrandBusiness')
                            ? ''
                            : ((selectedRestaurant() | applySelfPure: 'getFullFormattedAddress') ?? '')
                    ">
                    {{
                        (selectedRestaurant() | applySelfPure: 'isBrandBusiness')
                            ? ('common.brand_account' | translate)
                            : (selectedRestaurant() | applySelfPure: 'getFullFormattedAddress')
                    }}
                </div>
            }
        }
    </div>
</ng-template>

<mat-menu #restaurantsMenu="matMenu">
    <div class="flex h-[450px] w-[330px] max-w-full flex-col">
        @if (restaurants().length > 1) {
            <div
                class="flex cursor-pointer items-center p-4 hover:bg-malou-color-background-dark"
                id="tracking_sidenav_content_select_all_businesses"
                [ngClass]="{ '!bg-malou-color-background-dark': isAllRestaurantsSelected() }"
                (click)="onAllRestaurantsSelected($event)">
                <ng-container
                    [ngTemplateOutlet]="restaurantsLogosTemplate"
                    [ngTemplateOutletContext]="{ restaurants: restaurants() }"></ng-container>
                <div class="malou-text-12--regular ml-3 truncate text-malou-color-text-1">
                    {{ 'sidenav_content.all_businesses' | translate }}
                </div>
            </div>
        }
        <div class="h-20 p-4" (click)="$event.stopPropagation()">
            <app-input-text
                class="mb-12"
                [svgIcon]="SvgIcon.SEARCH"
                [placeholder]="'common.search' | translate"
                [showClearIcon]="true"
                [formControl]="restaurantFilterCtrl"
                [showSvgIconWhenUserIsTyping]="false">
            </app-input-text>
        </div>

        @if (restaurants().length === 0) {
            <div class="flex h-[70%] items-center justify-center" (click)="$event.stopPropagation()">
                <app-malou-spinner size="medium"></app-malou-spinner>
            </div>
        }

        <div
            class="hide-scrollbar min-h-0 grow overflow-y-auto"
            id="restaurants-list-infinite-scroll-container"
            infinite-scroll
            [infiniteScrollDistance]="10"
            [scrollWindow]="false"
            (scrolled)="onScrollDown()">
            @for (restaurant of filterRestaurants(); track trackByIdFn($index, restaurant)) {
                <button
                    mat-menu-item
                    [ngClass]="{ '!bg-malou-color-background-dark': restaurant._id === selectedRestaurant()?._id }"
                    (click)="onRestaurantSelected(restaurant, $event)">
                    <div class="malou-text-12--regular flex items-center">
                        <!-- without this span element, user will lose focus on the text field on some occasions -->
                        <span class="opacity-0">l</span>
                        <img class="malou-avatar--small" [lazyLoad]="restaurant?.logo?.getMediaUrl('small') ?? ''" />
                        <div class="ml-3 flex min-w-0 grow flex-col leading-4">
                            <span class="text-malou-color-text-1">{{ restaurant?.internalName }}</span>
                            <span class="malou-text-10--regular truncate font-normal italic text-malou-color-text-2">{{
                                getSubtext | applyPure: restaurant
                            }}</span>
                        </div>
                    </div>
                </button>
            }
        </div>
    </div>
</mat-menu>

<ng-template #routesTemplate>
    @if (selectedRestaurant(); as restaurant) {
        <div
            class="hide-scrollbar mx-8 flex flex-col items-stretch gap-y-2.5 overflow-y-auto md:mx-4"
            [ngClass]="{ '!mx-3': !isSidenavOpened() }">
            @let base = ['/restaurants', restaurant._id];

            <app-sidenav-route svgIcon="calendar" [routerLink]="base.concat(['dashboard'])" [text]="'app_sidebar.calendar' | translate" />

            @if (restaurant.isBrandBusiness()) {
                <app-sidenav-route
                    svgIcon="edit"
                    [routerLink]="base.concat(['seo', 'informations'])"
                    [text]="'app_sidebar.seo.informations' | translate" />
            } @else {
                <app-sidenav-route svgIcon="search" [routerLink]="base.concat(['seo'])" [text]="'app_sidebar.seo.seo' | translate" />
            }

            <app-sidenav-route
                svgIcon="rocket"
                [routerLink]="base.concat(['boosters'])"
                [text]="'app_sidebar.boosters.boosters' | translate"
                [showCrown]="!restaurant.boosterPack.activated" />

            @if (!restaurant.isBrandBusiness()) {
                <app-sidenav-route
                    svgIcon="star-border"
                    [routerLink]="base.concat(['reputation'])"
                    [text]="'app_sidebar.reputation' | translate" />
            }

            <app-sidenav-route
                svgIcon="thumb-up"
                [routerLink]="base.concat(['social'])"
                [text]="'app_sidebar.social_networks.social_network' | translate" />

            <app-sidenav-route
                svgIcon="conversation"
                [routerLink]="base.concat(['interactions'])"
                [text]="'app_sidebar.interactions.interactions' | translate"
                [notificationCount]="interactionNotificationCount() ?? 0" />

            <app-sidenav-route
                svgIcon="stat-arrow-up"
                [routerLink]="base.concat(['statistics'])"
                [text]="'app_sidebar.statistics' | translate" />

            <div class="h-2"></div>

            <app-sidenav-route
                svgIcon="folder-check"
                [routerLink]="base.concat(['resources'])"
                [text]="'app_sidebar.library.library' | translate" />

            <app-sidenav-route
                svgIcon="settings"
                [routerLink]="base.concat(['settings'])"
                [text]="'app_sidebar.settings.settings' | translate" />
        </div>
    }
</ng-template>

<ng-template #groupRoutesTemplate>
    <div
        class="hide-scrollbar mx-8 flex flex-col items-stretch gap-y-2.5 overflow-y-auto md:mx-4"
        [ngClass]="{ '!mx-3': !isSidenavOpened() }">
        <app-sidenav-route svgIcon="star-border" [routerLink]="['/groups', 'reputation']" [text]="'app_sidebar.reputation' | translate" />

        <app-sidenav-route svgIcon="stat-arrow-up" [routerLink]="['/groups', 'statistics']" [text]="'app_sidebar.statistics' | translate" />

        @if (!this.isSmallScreen()) {
            <app-sidenav-route
                svgIcon="rocket"
                [routerLink]="['/groups', 'boosters']"
                [text]="'app_sidebar.boosters.boosters' | translate"
                [showCrown]="!hasOneRestaurantWithBoosterPackActivated()" />
        }
    </div>
</ng-template>

<ng-template let-restaurants="restaurants" #restaurantsLogosTemplate>
    <div class="flex shrink-0">
        @for (restaurant of restaurants | slice: 0 : 3; track trackByIdFn($index, restaurant); let first = $first; let last = $last) {
            <div class="relative shrink-0" [ngClass]="{ '-ml-4': !first }">
                <img
                    class="malou-avatar--small"
                    [ngClass]="{ 'brightness-75': last }"
                    [src]="(restaurant | asType: Restaurant)?.logo | applySelfPure: 'getMediaUrl' : 'small'" />
                @if (last) {
                    <div class="malou-color-white malou-text-12--semibold" appAbsoluteCentering>+{{ restaurants.length - 2 }}</div>
                }
            </div>
        }
    </div>
</ng-template>
