import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
    DEFAULT_MAX_IMAGE_SIZE,
    DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR,
    DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR,
    FileSize,
    InputFileType,
    MediaCategory,
    ONE_OCTET,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ToastService } from ':core/services/toast.service';
import { MediaService } from ':modules/media/media.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { InputColorComponent } from ':shared/components/input-color/input-color.component';
import { MediaFileUploaderComponent } from ':shared/components/media-file-uploader/media-file-uploader.component';
import { DndDirective } from ':shared/directives/dnd.directive';
import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-appearance-settings-wheel-of-fortune',
    templateUrl: './appearance-settings-wheel-of-fortune.component.html',
    styleUrls: ['./appearance-settings-wheel-of-fortune.component.scss'],
    standalone: true,
    imports: [
        CdkDrag,
        CdkDropList,
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        DndDirective,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        TranslateModule,
        InputColorComponent,
        MalouSpinnerComponent,
        MediaFileUploaderComponent,
        ApplySelfPurePipe,
        IllustrationPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppearanceSettingsWheelOfFortuneComponent implements OnInit {
    @Input() primaryColor: WritableSignal<string>;
    @Input() secondaryColor: WritableSignal<string>;
    @Input() media: WritableSignal<Media | null>;
    @Input() isAggregatedView: boolean;
    @Input() isDisabled = false;

    readonly SvgIcon = SvgIcon;

    defaultColors: string[] = [DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR, DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR];
    DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR = DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR;
    DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR = DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR;

    readonly userId: WritableSignal<string | null> = signal(null);
    readonly errorMessage: WritableSignal<string> = signal('');
    readonly uploadedFile: WritableSignal<File | null> = signal(null);
    readonly uploadedFileSize: Signal<string> = computed(() => {
        const fileSize = this.uploadedFile()?.size;
        if (!fileSize) {
            return '';
        }
        const units = [FileSize.OCTET, FileSize.KILOOCTET, FileSize.MEGAOCTET];
        let index = 0;
        let size = fileSize;
        while (size >= ONE_OCTET && index < units.length - 1) {
            size /= ONE_OCTET;
            index++;
        }

        return size.toFixed(2) + ' ' + this._enumTranslate.transform(units[index], 'file_size');
    });

    readonly isDraggingOver: WritableSignal<boolean> = signal(false);
    readonly isImporting: WritableSignal<boolean> = signal(false);
    readonly acceptedInputFileTypes = [InputFileType.PNG, InputFileType.JPEG, InputFileType.SVG].join(',');

    constructor(
        private readonly _mediaService: MediaService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe,
        private readonly _store: Store
    ) {}

    ngOnInit(): void {
        this._store.select(selectUserInfos).subscribe({
            next: (user) => this.userId.set(user?._id ?? null),
        });
    }

    onFileChange(event: Event): void {
        this.isDraggingOver.set(false);
        this.errorMessage.set('');
        const eventTarget = event.target as HTMLInputElement;
        const file: File = event[0] || eventTarget.files?.[0];
        if (eventTarget) {
            eventTarget.value = '';
        }
        if (!file) {
            this.errorMessage.set(
                this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.appearance.errors.error_uploading_file')
            );
            return;
        }
        if (!this.acceptedInputFileTypes.includes(file.type)) {
            this.errorMessage.set(
                this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.appearance.errors.format_not_accepted')
            );
            return;
        }
        if (file.size > DEFAULT_MAX_IMAGE_SIZE) {
            this.errorMessage.set(this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.appearance.errors.file_too_large'));
            return;
        }
        this.uploadedFile.set(file);

        this.isImporting.set(true);
        this._mediaService
            .uploadAndCreateMedia([
                {
                    data: file,
                    metadata: {
                        userId: this.userId() ?? '',
                        category: MediaCategory.ADDITIONAL,
                    },
                },
            ])
            .subscribe({
                next: (res) => {
                    this.isImporting.set(false);
                    if (res.data) {
                        this.media.set(res.data[0]);
                    } else {
                        this._toastService.openErrorToast(
                            this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.appearance.errors.error_uploading_file')
                        );
                    }
                },
                error: () => {
                    this.isImporting.set(false);
                    this._toastService.openErrorToast(
                        this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.appearance.errors.error_uploading_file')
                    );
                },
            });
    }

    onDragEnter(): void {
        this.isDraggingOver.set(true);
    }

    onDragLeave(event: DragEvent): void {
        const dragArea = document.getElementById('dragarea');
        if (dragArea && dragArea.contains(event.relatedTarget as Node)) {
            return;
        }
        this.isDraggingOver.set(false);
    }
}
