import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { APP_DEFAULT_LANGUAGE, RestaurantAttributeValue } from '@malou-io/package-utils';

import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import { RestaurantAttribute } from ':shared/models/';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

const MAX_ATTRIBUTES = 15;
@Component({
    selector: 'app-restaurant-attributes',
    templateUrl: './restaurant-attributes.component.html',
    styleUrls: ['./restaurant-attributes.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatButtonModule, ApplySelfPurePipe],
})
@AutoUnsubscribeOnDestroy()
export class RestaurantAttributesComponent implements OnInit, OnChanges, KillSubscriptions {
    @Input() restaurantAttributes: RestaurantAttribute[] = [];
    @Output() emitOpenAttributesDialog = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    public filteredRestaurantAttributes: RestaurantAttribute[];
    public hasTooManyAttr: boolean;
    public btnText: string = this._translate.instant('information.attributes.show_more');

    currentLang = localStorage.getItem(LocalStorageKey.LANG) || APP_DEFAULT_LANGUAGE;
    readonly RestaurantAttributeValue = RestaurantAttributeValue;

    constructor(private readonly _translate: TranslateService) {}

    ngOnInit(): void {
        this._translate.onLangChange.pipe(takeUntil(this.killSubscriptions$)).subscribe((res) => {
            this.currentLang = res.lang;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'restaurantAttributes')) {
            this.restaurantAttributes.sort((a, b) =>
                a.attribute?.attributeName[this.currentLang] >= b.attribute?.attributeName[this.currentLang] ? 1 : -1
            );
            this.hasTooManyAttr = this.restaurantAttributes.length > MAX_ATTRIBUTES;
            this.filteredRestaurantAttributes = this.hasTooManyAttr
                ? this.restaurantAttributes.slice(0, MAX_ATTRIBUTES)
                : this.restaurantAttributes;
            this.btnText = this._translate.instant('information.attributes.show_more', {
                attrCount: this.restaurantAttributes.length - MAX_ATTRIBUTES,
            });
        }
    }

    openAttributesModal(): void {
        this.emitOpenAttributesDialog.emit();
    }
}
