import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
    Component,
    computed,
    effect,
    Injector,
    input,
    OnChanges,
    OnInit,
    runInInjectionContext,
    Signal,
    SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { asapScheduler, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import * as PlatformsReducer from ':modules/platforms/store/platforms.reducer';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectPlatformsComponent } from ':shared/components/select-platforms/select-platforms.component';
import { SelectTimeScaleFilterComponent } from ':shared/components/select-time-scale-filter/select-time-scale-filter.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { getSortedPlatformKeys } from ':shared/helpers/get-sorted-platform-keys';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, MalouPeriod, MalouTimeScalePeriod, Nfc } from ':shared/models';

@Component({
    selector: 'app-statistics-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        GroupedDateFiltersComponent,
        SelectChipListComponent,
        SelectPlatformsComponent,
        AsyncPipe,
        NgTemplateOutlet,
        SelectTimeScaleFilterComponent,
    ],
})
@AutoUnsubscribeOnDestroy()
export class FiltersComponent implements OnInit, KillSubscriptions, OnChanges {
    readonly showPlatformsFilter = input<boolean>(false);
    readonly platformFilterPage = input<PlatformFilterPage>();
    readonly restaurantTotems = input<Nfc[]>([]);
    readonly showTimeScaleFilter = input<boolean>(false);
    readonly isRecentRestaurant = input<boolean>(false);
    readonly timeScaleMinAcceptedDate = input<Date | null>();

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly MalouPeriod = MalouPeriod;

    period$: Observable<MalouPeriod>;
    startDate$: Observable<Date | null>;
    endDate$: Observable<Date | null>;

    connectedPlatforms: PlatformKey[];
    readonly platformsFilterControl: FormControl<string[]> = new FormControl<string[]>([]) as FormControl<string[]>;

    readonly showTotemsFilter: Signal<boolean> = computed(() => this.restaurantTotems && this.restaurantTotems().length > 0);
    readonly totemsFilterControl: FormControl<Nfc[]> = new FormControl<Nfc[]>([]) as FormControl<Nfc[]>;
    readonly timeScaleFilterControl: FormControl<MalouTimeScalePeriod> = new FormControl<MalouTimeScalePeriod>(
        MalouTimeScalePeriod.LAST_SIX_MONTHS
    ) as FormControl<MalouTimeScalePeriod>;

    readonly DEFAULT_PERIODS = [
        MalouPeriod.LAST_SEVEN_DAYS,
        MalouPeriod.LAST_THIRTY_DAYS,
        MalouPeriod.LAST_THREE_MONTHS,
        MalouPeriod.LAST_TWELVE_MONTHS,
    ];

    constructor(
        private readonly _store: Store,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _translateService: TranslateService,
        private readonly _injector: Injector
    ) {}

    ngOnInit(): void {
        const dates$ = this._store.select(StatisticsSelector.selectDatesFilter);
        this.period$ = dates$.pipe(map((groupDateFilter) => groupDateFilter.period));
        this.startDate$ = dates$.pipe(map((groupDateFilter) => groupDateFilter.startDate));
        this.endDate$ = dates$.pipe(map((groupDateFilter) => groupDateFilter.endDate));

        this.initTimeScaleFilter();

        if (this.showPlatformsFilter()) {
            this.initPlatformsFilter();
        }

        runInInjectionContext(this._injector, () => {
            effect(() => {
                if (this.showTotemsFilter()) {
                    this.initTotemsFilter();
                }
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.showTotemsFilter?.currentValue) {
            this.initTotemsFilter();
        }
    }

    initPlatformsFilter(): void {
        this._store
            .select(PlatformsReducer.selectCurrentPlatformKeys)
            .pipe(
                map((platforms: PlatformKey[]) =>
                    platforms.filter((platform: PlatformKey) => {
                        if (this.platformFilterPage() === PlatformFilterPage.E_REPUTATION) {
                            const keys: string[] = PlatformDefinitions.getPlatformKeysWithReview();
                            return keys.includes(platform);
                        }
                        if (this.platformFilterPage() === PlatformFilterPage.SOCIAL_NETWORKS) {
                            const keys: string[] = PlatformDefinitions.getPlatformKeysWithRSStats();
                            return keys.includes(platform);
                        }
                        return true;
                    })
                ),
                map((e) => getSortedPlatformKeys(e))
            )
            .subscribe((platforms) => {
                this.connectedPlatforms = platforms;
                this.onPlatformsChange(platforms);
            });

        const platformFilterPageValue = this.platformFilterPage();
        if (!platformFilterPageValue) {
            return;
        }
        this._store
            .select(StatisticsSelector.selectPlatformsFilter({ page: platformFilterPageValue }))
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((platforms: PlatformKey[]) => {
                this.platformsFilterControl.setValue(getSortedPlatformKeys([...platforms]));
            });
    }

    initTimeScaleFilter(): void {
        this._store.select(StatisticsSelector.selectTimeScaleFilter).subscribe((timeScale: MalouTimeScalePeriod) => {
            if (!timeScale) {
                const timeScaleInit = this.isRecentRestaurant()
                    ? MalouTimeScalePeriod.LAST_THREE_MONTHS
                    : MalouTimeScalePeriod.LAST_SIX_MONTHS;
                this.timeScaleFilterControl.setValue(timeScaleInit);
                this.onTimeScaleChange(timeScaleInit);
            } else {
                this.timeScaleFilterControl.setValue(timeScale);
            }
        });
    }

    initTotemsFilter(): void {
        this.onTotemsChange(this.restaurantTotems());

        this._store
            .select(StatisticsSelector.selectTotemsFilter)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((totems: Nfc[]) => {
                this.totemsFilterControl.setValue(totems);
            });
    }

    chooseBoundaryDate(dates: DatesAndPeriod): void {
        this._store.dispatch(StatisticsActions.editDates({ dates: dates }));
    }

    onPlatformsChange(platforms: PlatformKey[]): void {
        const platformFilterPageValue = this.platformFilterPage();
        if (!platformFilterPageValue) {
            return;
        }
        this._store.dispatch(StatisticsActions.editPlatforms({ page: platformFilterPageValue, platforms }));
    }

    onTotemsChange(totems: Nfc[]): void {
        asapScheduler.schedule(() => {
            this._store.dispatch(StatisticsActions.editTotems({ totems }));
        });
    }
    onTimeScaleChange(timeScale: MalouTimeScalePeriod): void {
        this._store.dispatch(StatisticsActions.editTimeScale({ data: timeScale }));
    }

    totemsDisplayWith = (totem: Nfc): string =>
        totem.isTotem() && (totem.name ?? totem.chipName)
            ? `${this._translateService.instant('statistics.totems.totem')} ${totem.name ?? totem.chipName}`
            : this._translateService.instant('enums.nfc_type.sticker');

    getTotemHash(totem: Nfc): string {
        return totem.id;
    }
}
