import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'oneDigitNumber',
    standalone: true,
})
export class OneDigitNumberPipe implements PipeTransform {
    transform(number: number): string {
        if (number < 0) {
            console.warn('Tried to use negative numbers with OneDigitNumber Pipe. Use positive integers only.');
            return '-';
        }
        if (number >= 10) {
            return '9+';
        }
        return `${number.toFixed(0)}`;
    }
}
