import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

export enum SlideToggleSize {
    SMALL = 'small',
    MEDIUM = 'medium',
}

@Component({
    selector: 'app-slide-toggle',
    templateUrl: './slide-toggle.component.html',
    styleUrls: ['./slide-toggle.component.scss'],
    standalone: true,
    imports: [NgStyle, NgClass, MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideToggleComponent {
    readonly checked = input.required<boolean>();
    readonly activeLabel = input.required<string>();
    readonly inactiveLabel = input.required<string>();
    readonly disabled = input<boolean>(false);
    readonly theme = input<'primary' | 'white'>('primary');
    readonly switchWidth = input<number>();
    readonly size = input<SlideToggleSize>(SlideToggleSize.MEDIUM);
    readonly onToggle = output<boolean>();

    readonly SvgIcon = SvgIcon;

    readonly id = Math.random().toString(36).substring(7);

    readonly switchWidthInPx = computed(() => {
        const switchWidth = this.switchWidth();
        const size = this.size();
        return switchWidth || size === SlideToggleSize.SMALL ? (size === SlideToggleSize.SMALL ? '40px' : `${switchWidth}px`) : '';
    });

    readonly circleStyle = computed(() => {
        const switchWidth = this.switchWidth();
        const size = this.size();
        if (!switchWidth) {
            return '';
        }
        return size === SlideToggleSize.SMALL ? 'translateX(17px)' : `translateX(${switchWidth - 35}px)`;
    });

    readonly SlideToggleSize = SlideToggleSize;

    readonly testId = input<string>();

    toggle(): void {
        this.onToggle.emit(this.checked());
    }
}
