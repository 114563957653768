<ng-container *ngTemplateOutlet="hasFetchedUsers ? page : loading"></ng-container>

<ng-template #loading>
    <div class="flex flex-col gap-4 px-7.5 py-5">
        <app-skeleton skeletonClass="secondary-bg h-[50px] w-full"></app-skeleton>

        <app-skeleton
            flexDirection="flex-row"
            skeletonClass="secondary-bg h-[15px] w-[7vw]"
            justifyContent="justify-around"
            [count]="4"></app-skeleton>

        <app-skeleton skeletonClass="secondary-bg h-[50px] w-full" gapClass="gap-[5px]" [count]="10"></app-skeleton>
    </div>
</ng-template>

<ng-template #page>
    <div class="flex h-full flex-col py-5">
        <ng-container [ngTemplateOutlet]="header"></ng-container>

        <div class="flex min-h-0 grow flex-col overflow-y-auto px-7.5">
            <div class="hidden md:mb-2 md:block">
                <app-sort-by-filters
                    [sortOptions]="SORT_OPTIONS"
                    [sortBy]="TABLE_COLUMNS.NAME"
                    [sortOrder]="1"
                    (changeSortBy)="onSortByChange($event)"
                    (toggleSortOrder)="onSortOrderChange()">
                </app-sort-by-filters>
            </div>

            @if (dataSource.filteredData.length) {
                <mat-table class="malou-mat-table" matSort [dataSource]="dataSource" #table="matTable">
                    <ng-container matColumnDef="avatar">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element; table: table" mat-cell>
                            <img
                                class="malou-avatar--medium my-2.5 ml-1.5 !rounded-[5px]"
                                [lazyLoad]="element.avatar || ('Karl' | illustrationPathResolver)" />
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lastname">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div>
                                {{ 'roles.manager.lastname' | translate }}
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element; table: table" class="malou-color-text-1 malou-text-weight-semibold" mat-cell>
                            {{ element.lastname }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div>
                                {{ 'roles.manager.name_header' | translate }}
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element; table: table" mat-cell>
                            {{ element.name }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div>
                                {{ 'roles.manager.email_header' | translate }}
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element; table: table" mat-cell>
                            <span class="malou-color-text-1 malou-text-weight-semibold mr-1.5 hidden md:inline">
                                {{ 'roles.manager.email_header' | translate }} :
                            </span>
                            {{ element.email }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="urRole">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div>
                                {{ 'roles.manager.casl_role' | translate }}
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element; table: table" mat-cell>
                            <mat-form-field class="custom-malou-filled-form-field !mb-[-10px] h-[36px] w-9/12" subscriptSizing="dynamic">
                                <mat-select
                                    panelClass="malou-select-panel"
                                    [(value)]="element.urRole"
                                    [hideSingleSelectionIndicator]="true"
                                    (openedChange)="selectRoleOpen(element.urRole, $event)"
                                    (selectionChange)="changeRole($event, element)">
                                    @for (role of CASL_ROLES; track role) {
                                        <mat-option [value]="role.key">{{ role.text }}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element; table: table" mat-cell>
                            <button
                                class="malou-btn-icon"
                                mat-icon-button
                                matTooltip="{{ 'roles.manager.pull_out' | translate }}"
                                (click)="pullOut(element)">
                                <mat-icon class="!w-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS; table: table"></mat-row>
                </mat-table>
            } @else {
                <div class="flex h-full flex-col items-center justify-center">
                    <img class="h-32" [src]="'Goggles' | illustrationPathResolver" />
                    <p class="malou-text-14--bold mb-4 mt-9 text-center">
                        {{ 'roles.manager.no_users' | translate }}
                    </p>
                </div>
            }

            <app-scroll-to-top
                class="fixed right-7.5"
                [container]="SCROLL_CONTAINER_SELECTOR"
                [ngClass]="{ 'bottom-8': footersVisibilityCount === 0, 'bottom-24': footersVisibilityCount >= 1 }">
            </app-scroll-to-top>
        </div>
    </div>
</ng-template>

<ng-template #header>
    <div class="mb-4 flex justify-between px-7.5">
        <div class="malou-text-18--bold malou-color-text-1 flex items-center italic lg:hidden">
            {{ 'roles.users' | translate }}
            <span class="malou-text-18--regular">({{ dataSource.filteredData.length }})</span>
        </div>
        <app-search
            class="mx-2.5 grow sm:basis-full md:ml-0"
            [placeholder]="'common.search' | translate"
            [debounceTime]="500"
            (searchChange)="onSearchChange($event)"></app-search>
        <div class="flex lg:hidden" [matTooltip]="addUserBtnTooltip" [matTooltipDisabled]="!shouldDisableAddUserBtn">
            <button
                class="malou-btn-raised--primary !h-12.5"
                mat-raised-button
                [disabled]="shouldDisableAddUserBtn"
                [class.opacity-50]="shouldDisableAddUserBtn"
                (click)="openAddUser()">
                {{ 'roles.add_user_btn' | translate }}
            </button>
        </div>
        <div class="hidden lg:flex" [matTooltip]="addUserBtnTooltip" [matTooltipDisabled]="!shouldDisableAddUserBtn">
            <button
                class="malou-btn-icon--primary btn-xl"
                mat-icon-button
                [disabled]="shouldDisableAddUserBtn"
                [class.opacity-50]="shouldDisableAddUserBtn"
                (click)="openAddUser()">
                <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
            </button>
        </div>
    </div>
</ng-template>
