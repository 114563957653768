import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { capitalize, round, sum } from 'lodash';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { CustomRoiChartLabelComponent } from ':modules/roi/custom-roi-chart-label/custom-roi-chart-label.component';
import { ChartDataArray, malouChartColorLighterBlue, malouChartColorPurple, malouChartColorPurpleLight30Percent } from ':shared/helpers';
import { LARGE_TOOLTIP_TAB, SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';

import { AggregatedEstimatedCustomersData } from '../../roi.interface';

type BarChartType = Extract<ChartType, 'bar'>;
export interface ChartMetaData {
    metadata: {
        dates: Date[][];
        dataByMonth: ChartDataArray[];
    };
}

@Component({
    selector: 'app-aggregated-monthly-estimated-customers-chart',
    standalone: true,
    imports: [NgChartsModule, TranslateModule, CustomRoiChartLabelComponent],
    templateUrl: './aggregated-monthly-estimated-customers-chart.component.html',
    styleUrl: './aggregated-monthly-estimated-customers-chart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedMonthlyEstimatedCustomersChartComponent {
    estimatedCustomersData = input.required<AggregatedEstimatedCustomersData>();
    showCustomChartLabel = input.required<boolean>();

    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() =>
        this._computeChartData(this.estimatedCustomersData())
    );
    chartLabels: Signal<string[]> = computed(() => this._computeChartLabels(this.estimatedCustomersData()));
    chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions());

    private readonly _translateService = inject(TranslateService);

    private _computeChartData(data: AggregatedEstimatedCustomersData): (ChartDataset<BarChartType, ChartDataArray> & ChartMetaData)[] {
        if (!data?.length) {
            return [];
        }
        return [
            {
                label: this._translateService.instant('roi.estimated_customers_and_performance_chart.estimated_customers'),
                borderColor: malouChartColorPurple,
                backgroundColor: malouChartColorPurple,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 5,
                data: data.map((d) => sum(d.estimatedCustomersByMonth) ?? 0),
                metadata: {
                    dates: data.map(({ dates }) => [...dates]),
                    dataByMonth: data.map((d) => d.estimatedCustomersByMonth),
                },
            },
            {
                label: this._translateService.instant(
                    'roi.estimated_customers_and_performance_chart.estimated_customers_similar_locations'
                ),
                borderColor: malouChartColorPurpleLight30Percent,
                backgroundColor: malouChartColorPurpleLight30Percent,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 5,
                data: data.map((d) => round(sum(d.estimatedCustomersSimilarLocationsByMonth), 0) ?? 0),
                metadata: {
                    dates: data.map(({ dates }) => [...dates]),
                    dataByMonth: data.map((d) => d.estimatedCustomersSimilarLocationsByMonth.map((v) => round(v ?? 0, 0))),
                },
            },
        ];
    }

    private _computeChartLabels(data?: AggregatedEstimatedCustomersData): string[] {
        if (!data) {
            return [];
        }
        return data.map(({ restaurant }) => capitalize(restaurant.internalName ?? restaurant.name));
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    filter: (tooltipItem: TooltipItem<any>): boolean => tooltipItem.formattedValue !== '0',
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems),
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                        afterLabel: (tooltipItem: TooltipItem<any>) => this._computeTooltipAfterLabel(tooltipItem),
                    },
                },
                legend: {
                    display: true,
                    align: 'end',
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    display: !this.showCustomChartLabel(),
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    beginAtZero: true,
                    grid: {
                        display: true,
                        color: (context): string | undefined => {
                            if (context.tick.value === 0) {
                                return malouChartColorLighterBlue;
                            }
                        },
                    },
                    position: 'left',
                },
            },
        };
    }

    private _computeTooltipTitle(tooltipItems: TooltipItem<any>[]): string {
        return `${tooltipItems[0].label}`;
    }

    private _computeTooltipLabel(tooltipItem: TooltipItem<any>): string {
        const datasetLabel = tooltipItem.dataset.label;
        return `${SMALL_TOOLTIP_TAB}${datasetLabel}: ${tooltipItem.formattedValue}`;
    }

    private _computeTooltipAfterLabel(tooltipItem: TooltipItem<any>): string[] {
        const dataByMonth = tooltipItem.dataset.metadata.dataByMonth[tooltipItem.dataIndex];
        const dates = tooltipItem.dataset.metadata.dates[tooltipItem.dataIndex].map((date) => new Date(date));
        const isSameYear = dates[0].getFullYear() === dates[dates.length - 1].getFullYear();
        const tooltipDetails = dates.map((date, index) =>
            isSameYear
                ? `${LARGE_TOOLTIP_TAB}${capitalize(DateTime.fromJSDate(date).toFormat('LLLL'))}: ${dataByMonth[index]}`
                : `${LARGE_TOOLTIP_TAB}${capitalize(DateTime.fromJSDate(date).toFormat('LLLL yyyy'))}: ${dataByMonth[index]}`
        );
        return tooltipItem.datasetIndex === 1 ? tooltipDetails : tooltipDetails.concat('');
    }
}
