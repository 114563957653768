import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';

import { NotificationCenterContext } from '../../context/notification-center.context';
import { Notification } from '../../models/notification.model';

@Component({
    selector: 'app-notification-item-actions',
    templateUrl: './notification-item-actions.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, NoopMatCheckboxComponent, MatTooltipModule, TranslateModule],
})
export class NotificationItemActionsComponent {
    readonly notification = input.required<Notification>();

    readonly notificationCenterContext = inject(NotificationCenterContext);

    readonly isRead = computed(() => !!this.notification().readAt);

    toggleRead(): void {
        this.notificationCenterContext.notifications.update((notifications) =>
            notifications.map((notification) => {
                if (notification.id === this.notification().id) {
                    return notification.copyWith({ readAt: notification.readAt ? null : new Date() });
                }

                return notification;
            })
        );
    }
}
