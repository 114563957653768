import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { ToastService } from ':core/services/toast.service';
import { DeliverooConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-deliveroo-modal/deliveroo-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { CreateCredentialService } from ':modules/platforms/platforms-connection-modals/shared/services/create-credential.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { isSafari } from ':shared/helpers/is-safari';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-deliveroo-connection-step-0',
    templateUrl: './deliveroo-connection-step-0.component.html',
    styleUrls: ['./deliveroo-connection-step-0.component.scss'],
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        MalouSpinnerComponent,
        ImagePathResolverPipe,
        InputTextComponent,
        ReactiveFormsModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliverooConnectionStep0Component extends BaseStepComponent<undefined, DeliverooConnectionModalResult> {
    private readonly _createCredentialService = inject(CreateCredentialService);
    private readonly _spinnerService = inject(SpinnerService);
    private readonly _toastService = inject(ToastService);
    private readonly _router = inject(Router);
    private readonly _translateService = inject(TranslateService);

    readonly ButtonStyle = ButtonStyle;

    readonly passwordControl = new FormControl<string>('', Validators.required);
    readonly loginControl = new FormControl<string>('', [Validators.required, Validators.email]);

    readonly isEmailInError$ = this.loginControl.statusChanges.pipe(map(() => this.loginControl.errors?.email ?? false));
    readonly isEmailInError = toSignal<boolean, boolean>(this.isEmailInError$, {
        initialValue: false,
    });
    readonly isSafari = isSafari;

    onPrimaryClick(): void {
        this._spinnerService.show();
        const login = this.loginControl.value;
        const password = this.passwordControl.value;
        if (!login || !password) {
            return;
        }
        this._createCredentialService.execute(PlatformKey.DELIVEROO, { type: 'password', login, password }).subscribe({
            next: (redirect) => {
                this._spinnerService.hide();
                this.close.emit({ hasDataChanged: true });
                this._router.navigateByUrl(`${redirect}&redirect=${this._router.url}`);
            },
            error: (err) => {
                console.error(err);
                this._spinnerService.hide();
                this._toastService.openErrorToast(this._translateService.instant('common.error'));
            },
        });
    }
}
