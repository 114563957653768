import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { APP_DEFAULT_LANGUAGE, RestaurantAttributeValue } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Attribute, Restaurant, RestaurantAttribute } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

export type AttributesUpdateData = Pick<Restaurant, 'attributeList'>;

@Component({
    selector: 'app-attributes-modal',
    templateUrl: './attributes-modal.component.html',
    styleUrls: ['./attributes-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        CloseWithoutSavingModalComponent,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        SearchComponent,
        MatLegacyRadioModule,
        SkeletonComponent,
        TranslateModule,
        ApplySelfPurePipe,
        AsyncPipe,
    ],
})
export class AttributesModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    attributesForm: UntypedFormGroup;
    searchText = '';

    allAttributes: Attribute[] = [];
    restaurantAttributes: RestaurantAttribute[] = [];
    currentLang = localStorage.getItem(LocalStorageKey.LANG) || APP_DEFAULT_LANGUAGE;
    displayCloseModal = false;
    readonly RestaurantAttributeValue = RestaurantAttributeValue;

    readonly isPlatformsUpdatesReleaseEnabled$ = inject(ExperimentationService).isFeatureEnabled$('release-platforms-updates');

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            restaurant: Restaurant;
            allAttributes: Attribute[];
            restaurantAttributes: RestaurantAttribute[];
        },
        private readonly _dialogRef: MatDialogRef<AttributesModalComponent>,
        private readonly _fb: UntypedFormBuilder,
        private readonly _translate: TranslateService,
        public screenSizeService: ScreenSizeService
    ) {
        this.attributesForm = this._fb.group({
            formAttributeValues: this._fb.array([]),
        });
    }

    get attributesFiltered(): Attribute[] {
        return this.searchText === ''
            ? this.allAttributes
            : this.allAttributes?.filter((attr) =>
                  attr.getAttributeNameForLang(this.currentLang).trim().toLowerCase().includes(this.searchText.trim().toLowerCase())
              );
    }

    ngOnInit(): void {
        this.currentLang = this._translate.currentLang;

        this.allAttributes = this.data.allAttributes.sort((a, b) => {
            const aName = a?.getAttributeNameForLang(this.currentLang);
            const bName = b?.getAttributeNameForLang(this.currentLang);
            return aName.localeCompare(bName);
        });
        this.restaurantAttributes = this.data.restaurantAttributes;
        this.attributesForm.setControl('formAttributeValues', this.setDefaultAttributes());
        this.updateValueForExistingAttributes();
    }

    onSearchChange(searchValue: string): void {
        this.searchText = searchValue;
    }

    setDefaultAttributes(): UntypedFormArray {
        const attributesFormArray = new UntypedFormArray([]);
        this.allAttributes?.forEach((attr) => {
            const associatedRestaurantAttribute = this.restaurantAttributes.find((ra) => ra.attributeId === attr._id);
            attributesFormArray.push(
                this._fb.group({
                    _id: attr._id,
                    restaurantId: this.data.restaurant._id,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __v: 0,
                    attributeId: attr.attributeId,
                    attributeValue: associatedRestaurantAttribute?.attributeValue ?? [RestaurantAttributeValue.NOT_CONCERNED],
                    attribute: { ...attr },
                })
            );
        });
        return attributesFormArray;
    }

    updateValueForExistingAttributes(): void {
        if (this.allAttributes) {
            this.restaurantAttributes.forEach((restaurantAttribute) => {
                const index = this.allAttributes.findIndex((attr) => attr._id === restaurantAttribute.attributeId);
                if (index >= 0) {
                    const attributeValues = this.attributesForm.get('formAttributeValues');
                    if (attributeValues) {
                        attributeValues.value[index] = restaurantAttribute;
                    }
                }
            });
        }
        this.attributesForm.get('formAttributeValues')?.setValue(this.attributesForm.get('formAttributeValues')?.value);
    }

    save(): void {
        if (!this.attributesForm.valid) {
            return;
        }
        const updateData: RestaurantAttribute[] = this.attributesForm.value.formAttributeValues.filter(
            (attr) => attr.attributeValue !== RestaurantAttributeValue.NOT_CONCERNED
        );
        this.confirmClose({ attributeList: updateData });
    }

    close(): void {
        if (this.attributesForm.dirty) {
            this.displayCloseModal = true;
        } else {
            this.confirmClose();
        }
    }

    confirmClose(data?: AttributesUpdateData): void {
        this._dialogRef.close(data);
    }
}
