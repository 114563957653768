import { PlatformKey, PostType, SizeInBytes } from '@malou-io/package-utils';

import { parseAspectRatio } from ':shared/helpers/dimensions';

export interface MediaProperties {
    height: number;
    width: number;
    duration?: number;
    bytes: number;
    type: PostType;
}

export class MediaErrors {
    constructor(
        protected properties: MediaProperties,
        protected platformsKeys: string[]
    ) {}

    getErrors(): string[] {
        const errors: string[] = [];
        this.platformsKeys.forEach((key) => {
            const platformErrors = this._buildMediaPlatformErrors(key);
            errors.push(...platformErrors.getErrors());
        });
        return errors;
    }

    protected getVideoErrors(): string[] {
        throw new Error('Not implemented');
    }

    protected getImageErrors(): string[] {
        throw new Error('Not implemented');
    }

    protected isImage(): boolean {
        return this.properties.type === PostType.IMAGE;
    }

    protected isVideo(): boolean {
        return this.properties.type === PostType.VIDEO;
    }

    protected isReel(): boolean {
        return this.properties.type === PostType.REEL;
    }

    private _buildMediaPlatformErrors(key: string): MediaErrors {
        switch (key) {
            case PlatformKey.FACEBOOK:
                return new FacebookMediaErrors(this.properties);
            case PlatformKey.INSTAGRAM:
                return new InstagramMediaErrors(this.properties);
            case PlatformKey.MAPSTR:
                return new MapstrMediaErrors(this.properties);
            default:
                throw new Error(`Platform ${key} not implemented`);
        }
    }
}

class FacebookMediaErrors extends MediaErrors {
    constructor(protected properties: MediaProperties) {
        super(properties, [PlatformKey.FACEBOOK]);
    }

    getErrors(): string[] {
        return this.getImageErrors().concat(this.getVideoErrors());
    }

    protected getImageErrors(): string[] {
        return [];
    }

    protected getVideoErrors(): string[] {
        return [];
    }
}

class MapstrMediaErrors extends MediaErrors {
    MAX_SIZE_IMAGE = 4 * SizeInBytes.MEGA_BYTES;

    constructor(protected properties: MediaProperties) {
        super(properties, [PlatformKey.MAPSTR]);
    }

    getErrors(): string[] {
        return this.getImageErrors().concat(this.getVideoErrors());
    }

    protected getImageErrors(): string[] {
        const errors: string[] = [];
        if (this.isImage()) {
            if (this.properties.bytes > this.MAX_SIZE_IMAGE) {
                errors.push('social_posts.new_social_post.mapstr_max_size_img');
            }
        }
        return errors;
    }

    protected getVideoErrors(): string[] {
        return [];
    }
}

export class InstagramMediaErrors extends MediaErrors {
    MAX_DURATION_REEL = 900; // 15 minutes
    MIN_DURATION_REEL = 3;
    MAX_SIZE_REEL = 1_000_000_000;
    MIN_RATIO_REEL = 0.01;
    MAX_RATIO_REEL = 10;
    MAX_WIDTH_REEL = 1920;

    MAX_DURATION_VIDEO = 60;
    MIN_DURATION_VIDEO = 3;
    MAX_SIZE_VIDEO = 100_000_000;
    MIN_RATIO_VIDEO = 9 / 16;
    MAX_RATIO_VIDEO = 16 / 9;
    MAX_WIDTH_VIDEO = 1920;

    MAX_SIZE_IMAGE = 8_000_000;
    MIN_RATIO_IMAGE = 9 / 16;
    MAX_RATIO_IMAGE = 1.91;

    constructor(protected properties: MediaProperties) {
        super(properties, [PlatformKey.INSTAGRAM]);
    }

    getErrors(): string[] {
        return [...this.getImageErrors(), ...this.getVideoErrors(), ...this.getReelErrors()];
    }

    areReelDimensionsValid(): boolean {
        const ratio = this.properties.width / this.properties.height;
        return this.properties.width <= this.MAX_WIDTH_VIDEO && ratio <= this.MAX_RATIO_REEL && ratio >= this.MIN_RATIO_REEL;
    }

    getImageErrors(): string[] {
        const errors: string[] = [];
        if (this.isImage()) {
            const ratio = parseAspectRatio(this.properties.width / this.properties.height);
            if (ratio < parseAspectRatio(this.MIN_RATIO_IMAGE)) {
                errors.push('social_posts.new_social_post.instagram.min_ratio_img');
            }
            if (ratio > parseAspectRatio(this.MAX_RATIO_IMAGE)) {
                errors.push('social_posts.new_social_post.instagram.max_ratio_img');
            }
            if (this.properties.bytes > this.MAX_SIZE_IMAGE) {
                errors.push('social_posts.new_social_post.instagram.max_size_img');
            }
        }
        return errors;
    }

    getVideoErrors(): string[] {
        const errors: string[] = [];
        if (this.isVideo()) {
            const ratio = this.properties.width / this.properties.height;
            if (this.properties.width > this.MAX_WIDTH_VIDEO) {
                errors.push('social_posts.new_social_post.instagram.max_width_video');
            }
            if (ratio < this.MIN_RATIO_VIDEO) {
                errors.push('social_posts.new_social_post.instagram.min_ratio_video');
            }
            if (ratio > this.MAX_RATIO_VIDEO) {
                errors.push('social_posts.new_social_post.instagram.max_ratio_video');
            }
            if (this.properties.duration && this.properties.duration > this.MAX_DURATION_VIDEO) {
                errors.push('social_posts.new_social_post.instagram.max_duration');
            }
            if (this.properties.duration && this.properties.duration < this.MIN_DURATION_VIDEO) {
                errors.push('social_posts.new_social_post.instagram.min_duration');
            }
            if (this.properties.bytes > this.MAX_SIZE_VIDEO) {
                errors.push('social_posts.new_social_post.instagram.max_size_video');
            }
        }
        return errors;
    }

    protected getReelErrors(): string[] {
        const errors: string[] = [];
        if (this.isReel()) {
            if (this.properties.duration && this.properties.duration > this.MAX_DURATION_REEL) {
                errors.push('social_posts.new_social_post.instagram.max_duration');
            }
            if (this.properties.duration && this.properties.duration < this.MIN_DURATION_REEL) {
                errors.push('social_posts.new_social_post.instagram.min_duration');
            }
            if (this.properties.bytes > this.MAX_SIZE_REEL) {
                errors.push('social_posts.new_social_post.instagram.max_size_video');
            }
        }
        return errors;
    }
}
