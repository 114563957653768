<ng-container *ngTemplateOutlet="loading() ? loadingShimmer : posts"></ng-container>

<ng-template #loadingShimmer>
    <div class="h-full w-full overflow-hidden">
        <app-skeleton skeletonClass="h-full w-full secondary-bg p-6.5" [useContainer]="false">
            <app-skeleton skeletonClass="w-full h-[50px]" [useContainer]="false"></app-skeleton>
            <div class="mt-5">
                <app-skeleton skeletonClass="h-[90px] w-[90px]" [gapClass]="'gap-8'" [count]="16"> </app-skeleton>
            </div>
        </app-skeleton>
    </div>
</ng-template>

<ng-template #posts>
    <div class="h-full w-full overflow-hidden md:rounded-[30px] md:border md:border-malou-color-background-dark md:bg-white">
        <ng-container *ngTemplateOutlet="feedHeader"></ng-container>
        @if (isIgConnected()) {
            @if (filteredPosts().length) {
                <div
                    class="hide-scrollbar h-full max-h-[77%] w-full overflow-y-auto md:max-h-[73%]"
                    cdkDropListGroup
                    infinite-scroll
                    [infiniteScrollDistance]="2"
                    [scrollWindow]="false"
                    [infiniteScrollContainer]="scrollContainerSelector"
                    (scrolled)="onScroll()"
                    #scrollContainerSelector>
                    <div class="relative flex w-full flex-wrap gap-x-[1px] gap-y-0.5 transition-all">
                        <div
                            cdkDropList
                            (cdkDropListEntered)="onDropListEntered($event)"
                            (cdkDropListDropped)="onDropListDropped($event)"></div>
                        @for (post of filteredPosts(); track trackByIdFn($index, post)) {
                            <div
                                cdkDropList
                                [cdkDropListData]="post"
                                [cdkDropListEnterPredicate]="cdkDropListEnterPredicate"
                                (click)="editPost(post._id)"
                                (cdkDropListEntered)="onDropListEntered($event)"
                                (cdkDropListDropped)="onDropListDropped($event)">
                                <div
                                    class="media-box"
                                    cdkDrag
                                    [cdkDragStartDelay]="{ touch: 200, mouse: 0 }"
                                    [cdkDragData]="post"
                                    [cdkDragDisabled]="
                                        [ExtendedPostPublicationStatus.PUBLISHED, ExtendedPostPublicationStatus.ERROR].includes(
                                            post.postStatus.type
                                        )
                                    "
                                    [ngClass]="{
                                        'border border-malou-color-state-error':
                                            post.postStatus.type === ExtendedPostPublicationStatus.ERROR,
                                    }"
                                    #postRef>
                                    <app-social-post-media
                                        [ngClass]="
                                            [ExtendedPostPublicationStatus.PUBLISHED, ExtendedPostPublicationStatus.ERROR].includes(
                                                post.postStatus.type
                                            )
                                                ? 'cursor-default'
                                                : 'cursor-grab'
                                        "
                                        [customMediaClass]="'!rounded-[0] min-w-[105px] min-h-[105px]'"
                                        [postType]="post.postType"
                                        [workingMedia]="post.getSocialMediaUrl() || post.getMalouMediaUrl()"
                                        [firstAttachmentType]="post.getFirstMediumType()"
                                        [showVideoControls]="false"
                                        [postStatus]="post.postStatus"
                                        [thumbnailUrl]="post | applySelfPure: 'getThumbnailUrl$' | async"
                                        (refreshMedia)="refreshPost(post)"></app-social-post-media>
                                </div>
                            </div>
                        }
                        @if (hideInstagramFeed()) {
                            <div
                                class="malou-text-10--regular absolute left-[50%] top-[28%] w-full translate-x-[-50%] text-center text-malou-color-text-2">
                                {{ 'social_posts.feed_hidden' | translate }}
                            </div>
                        }
                    </div>
                </div>
            } @else {
                <div class="grid h-full w-full place-items-center px-7.5">
                    <div class="flex flex-col items-center gap-y-6">
                        <img height="100" width="100" [src]="'Goggles' | illustrationPathResolver" />
                        <div class="malou-text-14--bold text-center">
                            {{ 'social_posts.feed_empty' | translate }}
                            <div class="malou-text-10--regular mt-3 text-center">
                                {{ 'social_posts.not_connected_subtext' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            }
        } @else {
            <div class="grid h-full w-full place-items-center px-7.5">
                <div class="flex flex-col items-center gap-y-6">
                    <img height="100" width="100" [src]="'Goggles' | illustrationPathResolver" />
                    <div class="malou-text-14--bold text-center">
                        {{ 'social_posts.not_connected' | translate }}
                        <div class="malou-text-10--regular mt-3 text-center">
                            {{ 'social_posts.not_connected_subtext' | translate }}
                        </div>
                    </div>
                    <button class="malou-btn-raised--primary" mat-raised-button (click)="navigateToPlatforms()">
                        {{ 'common.connect_platforms' | translate }}
                    </button>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #feedHeader>
    @if (isIgConnected() && filteredPosts().length > 0) {
        <div class="p-6.5 pb-3">
            <div class="flex items-center gap-x-3 rounded-[10px] border border-malou-color-background-dark px-3.5 py-2.5">
                <img class="rounded-full" alt="instagram" height="34" width="34" [src]="PlatformKey.INSTAGRAM | platformLogoPathResolver" />
                <span class="malou-text-12--bold text-malou-color-text-1"> &#64;{{ igUsername() }} </span>
            </div>
            <div class="mt-3 flex items-center gap-x-2">
                <mat-checkbox
                    color="primary"
                    [matTooltip]="showOldDrafts() ? null : ('social_posts.show_tooltip' | translate)"
                    [checked]="showOldDrafts()"
                    (change)="toggleShouldShowDrafts()">
                </mat-checkbox>
                <span class="malou-text-10--regular text-malou-color-text-1">{{ 'social_posts.show_old_drafts' | translate }}</span>
            </div>
        </div>
    }
</ng-template>
