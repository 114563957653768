<app-text-area
    id="text"
    [testId]="'seo-post-caption-input'"
    [textAreaId]="textAreaId()"
    [shouldDisplayAiButton]="true"
    [title]="
        newPostModalContext.isSmallScreen()
            ? ('social_posts.new_social_post.add_caption_mobile' | translate)
            : ('social_posts.new_social_post.add_caption' | translate)
    "
    [required]="true"
    [formControl]="postDescriptionFormControl()"
    [placeholder]="'social_posts.new_social_post.write_here_or' | translate"
    [autoResizeOnContentOverflow]="true"
    [resizable]="false"
    [maxLength]="POST_CAPTION_TEXT_LIMIT"
    [showMaxLength]="false"
    [isLoadingAnimationEnabled]="newPostModalAiContext.isTextAreaLoadingAnimationEnabled()"
    [errorMessage]="
        (getPostCaptionErrorMessages
            | applyPure
                : this.postDescriptionFormControl().value.length
                : POST_CAPTION_TEXT_LIMIT
                : containPhoneNumberError
                : postDescriptionFormControl().invalid && this.postDescriptionFormControl().touched) ?? ''
            | translate: { captionTextLimit: POST_CAPTION_TEXT_LIMIT }
    "
    (appClickOutside)="newPostModalAiContext.closePostCaptionAiGeneration()"
    (click)="!newPostModalContext.isSmallScreen() && newPostModalAiContext.openPostCaptionAiGeneration()"
    (input)="onDescriptionChange($event)"
    (selectionChange)="onSelectionChange($event)">
    <ng-template #loadingAnimationTemplate>
        <app-infinite-text-slide [defaultText]="loadingAnimationDefaultText()" [slideTextList]="loadingAnimationSlideTextList()">
        </app-infinite-text-slide>
    </ng-template>
    @if (
        !newPostModalAiContext.isTextAreaLoadingAnimationEnabled() &&
        newPostModalAiContext.postCaptionAiGenerationDisplayState() === newPostModalAiContext.PostCaptionAiGenerationDisplayState.CLOSED
    ) {
        <div class="mt-3 h-full" afterTextAreaAiButtonTemplate>
            <button class="malou-btn-flat !pr-0" mat-button (click)="onWriteWithAiClick()">
                <mat-icon
                    class="h-4 !w-4 text-malou-color-chart-purple--accent"
                    [svgIcon]="SvgIcon.MAGIC_WAND"
                    [ngClass]="{ 'mr-1': !newPostModalContext.isSmallScreen() }"></mat-icon>

                <span class="!malou-text-10--semibold text-malou-color-chart-purple--accent">{{
                    'social_posts.new_social_post.write_with_ai' | translate
                }}</span>
            </button>
        </div>
    }
    <div class="flex flex-col" footerTemplate>
        <app-ai-generation-actions
            optimizeButtonId="tracking_new_post_modal_optimize_post_with_ai_button"
            translateButtonIdPrefix="tracking_new_post_modal_translate_post_to_"
            [trackerId]="'tracking_new_seo_post_modal_translate_post_to_custom_language_with_ai_button'"
            [disabled]="newPostModalAiContext.isGeneratingPostTextFromAI()"
            [interactions]="newPostModalAiContext.interactions()"
            [onReset]="newPostModalAiContext.resetBrowser()"
            (onWandIconClick)="onWriteWithAiClick()"
            (onTranslate)="newPostModalAiContext.translateText($event)"
            (onOptimize)="newPostModalAiContext.onPromptChange(postDescriptionFormControl().value)"
            (interactionChanged)="newPostModalAiContext.onInteractionChanged($event)"></app-ai-generation-actions>
    </div>
</app-text-area>
