<div class="malou-card__header relative mb-6 flex flex-col !pb-0 pl-0">
    <div class="flex w-full">
        <div class="flex w-[30%] grow flex-col justify-center">
            <div class="malou-text-16--bold truncate">{{ restaurant()?.internalName }}</div>
            <div class="malou-text__card-subtitle">{{ restaurant()?.fullFormattedAddress }}</div>
        </div>

        <div class="malou-card__image-container !mr-0 ml-2">
            <img
                class="h-11 w-20 rounded-lg object-cover"
                [lazyLoad]="restaurant()?.logo?.urls?.small || ('Grey-burger' | illustrationPathResolver)" />
        </div>
    </div>

    <div
        class="absolute bottom-[-1.5rem] left-[calc(-1.625rem-1px)] right-[calc(-1.625rem-1px)] my-3"
        [ngClass]="{
            'h-[2px] bg-malou-color-background-light': review() | hasReply,
            'h-[1px] bg-malou-color-background-dark': !(review() | hasReply),
            '!left-[calc(-1.625rem)] !right-[calc(-1.625rem)]': !isPhoneScreen() && isSelected(),
            '!left-[calc(-1.25rem-1px)] !right-[calc(-1.25rem-1px)]': isPhoneScreen(),
        }"></div>
</div>
