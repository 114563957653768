export enum DiagnosticRating {
    GOOD = 'good',
    AVERAGE = 'average',
    BAD = 'bad',
    VERY_BAD = 'very_bad',
}

export const SCORE_RATING = {
    MINIMUM_AVERAGE_THRESHOLD: 60,
    MINIMUM_GOOD_THRESHOLD: 80,
};

export const GOOGLE_SCORE_RATING = {
    MINIMUM_AVERAGE_THRESHOLD: 0.85,
    MINIMUM_GOOD_THRESHOLD: 1,
};

export const BETTER_LOCAL_COMPETITORS_RATING = {
    MAXIMUM_AVERAGE_THRESHOLD: 10,
    MAXIMUM_GOOD_THRESHOLD: 5,
};

export const INSTAGRAM_VISIBILITY_RATING = {
    MINIMUM_AVERAGE_THRESHOLD: 10,
    MINIMUM_GOOD_THRESHOLD: 30,
};

export const MAX_INCONSISTENCIES_BY_PLATFORM = 3;

export const INCONSISTENCIES_RATING_THRESHOLD = {
    GOOD: 0.15, // Less than 15% of the platforms are inconsistent
    AVERAGE: 0.5, // Between 50% and 15% of the platforms are inconsistent
};

export const KEYWORDS_RATING_THRESHOLD = {
    MAXIMUM_GOOD_THRESHOLD: 5,
    MAXIMUM_AVERAGE_THRESHOLD: 15,
};

export const PHOTOS_RATING_THRESHOLD = {
    MINIMUM_GOOD_THRESHOLD: 10,
    MINIMUM_AVERAGE_THRESHOLD: 5,
};

export const SERVICES_RATING_THRESHOLD = {
    MINIMUM_GOOD_THRESHOLD: 80,
    MINIMUM_AVERAGE_THRESHOLD: 50,
};

export const INSTAGRAM_SCORE_THRESHOLD = {
    MINIMUM_AVERAGE_THRESHOLD: 0.6,
    MINIMUM_GOOD_THRESHOLD: 0.8,
};
