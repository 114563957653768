import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { isNotNil } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { InformationsContext } from ':modules/informations/informations.context';
import { LoaderProgressColorClass, LoaderProgressComponent } from ':shared/components/loader-progress/loader-progress.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { DescriptionSize } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

enum GaugeCriteriaLabel {
    NAME = 'NAME',
    ADDRESS = 'ADDRESS',
    CATEGORY = 'CATEGORY',
    REGULAR_HOURS = 'REGULAR_HOURS',
    PHONE = 'PHONE',
    DESCRIPTIONS = 'DESCRIPTIONS',
    SECONDARY_CATEGORIES = 'SECONDARY_CATEGORIES',
    ATTRIBUTES = 'ATTRIBUTES',
    WEBSITE = 'WEBSITE',
    LOGO = 'LOGO',
}

interface GaugeInformation {
    label: GaugeCriteriaLabel;
    priority: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
    completed: boolean;
    points: number;
}

@Component({
    selector: 'app-informations-gauge',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        SkeletonComponent,
        EnumTranslatePipe,
        FlagPathResolverPipe,
        LoaderProgressComponent,
    ],
    templateUrl: './informations-gauge.component.html',
    styleUrl: './informations-gauge.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationsGaugeComponent {
    private readonly _DESCRIPTION_SIZE_MIN = 40;
    private readonly _SECONDARY_CATEGORIES_MIN = 2;

    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _informationsContext = inject(InformationsContext);
    private readonly _translateService = inject(TranslateService);

    private readonly _restaurant = toSignal(this._restaurantsService.restaurantSelected$);

    readonly gaugeInformations = computed((): GaugeInformation[] => {
        const informations: (GaugeInformation | undefined)[] = [];

        const restaurant = this._restaurant();
        if (!restaurant) {
            return [];
        }

        informations.push(this._createGaugeInformationForName());
        informations.push(this._createGaugeInformationForAddress());
        informations.push(this._createGaugeInformationForCategory());
        informations.push(this._createGaugeInformationForRegularHours());
        informations.push(this._createGaugeInformationForPhone());
        informations.push(this._createGaugeInformationForDescriptions());
        informations.push(this._createGaugeInformationForSecondaryCategories());
        informations.push(this._createGaugeInformationForAttributes());
        informations.push(this._createGaugeInformationForUrls());
        informations.push(this._createGaugeInformationForLogo());

        return informations.filter(isNotNil);
    });

    readonly scoreGauge = computed((): number => {
        const gaugeInformations = this.gaugeInformations();
        return gaugeInformations
            .filter((info) => info.completed)
            .map((info) => info.points)
            .reduce((a, b) => a + b, 0);
    });

    readonly progressBarColorClass = computed((): LoaderProgressColorClass => {
        if (this.scoreGauge() === 100) {
            return LoaderProgressColorClass.SUCCESS;
        }

        if (this.scoreGauge() >= 50) {
            return LoaderProgressColorClass.WARNING;
        }

        return LoaderProgressColorClass.ERROR;
    });

    readonly highestPriorityLabel = computed((): GaugeCriteriaLabel => {
        const gaugeInformations = this.gaugeInformations();

        return gaugeInformations.filter((info) => !info.completed).sort((a, b) => a.priority - b.priority)[0]?.label;
    });

    readonly ctaText = computed((): string => {
        const label = this.highestPriorityLabel();

        return (
            {
                [GaugeCriteriaLabel.NAME]: this._translateService.instant('informations.gauge.cta.name'),
                [GaugeCriteriaLabel.ADDRESS]: this._translateService.instant('informations.gauge.cta.address'),
                [GaugeCriteriaLabel.CATEGORY]: this._translateService.instant('informations.gauge.cta.category'),
                [GaugeCriteriaLabel.REGULAR_HOURS]: this._translateService.instant('informations.gauge.cta.regularHours'),
                [GaugeCriteriaLabel.PHONE]: this._translateService.instant('informations.gauge.cta.phone'),
                [GaugeCriteriaLabel.DESCRIPTIONS]: this._translateService.instant('informations.gauge.cta.descriptions'),
                [GaugeCriteriaLabel.SECONDARY_CATEGORIES]: this._translateService.instant('informations.gauge.cta.secondaryCategories'),
                [GaugeCriteriaLabel.ATTRIBUTES]: this._translateService.instant('informations.gauge.cta.attributes'),
                [GaugeCriteriaLabel.WEBSITE]: this._translateService.instant('informations.gauge.cta.website'),
                [GaugeCriteriaLabel.LOGO]: this._translateService.instant('informations.gauge.cta.logo'),
            }[label] ?? this._translateService.instant('informations.gauge.cta.default')
        );
    });

    openInformationsModal(): void {
        const label = this.highestPriorityLabel();

        if (label === GaugeCriteriaLabel.DESCRIPTIONS) {
            this._informationsContext.openRestaurantDescriptionsModal$.next();
        } else if (label === GaugeCriteriaLabel.REGULAR_HOURS) {
            this._informationsContext.openRestaurantHoursModal$.next();
        } else if (label === GaugeCriteriaLabel.ATTRIBUTES) {
            this._informationsContext.openRestaurantAttributesModal$.next();
        } else {
            this._informationsContext.openRestaurantInformationsModal$.next();
        }
    }

    private _createGaugeInformationForName(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant) {
            return undefined;
        }

        return {
            label: GaugeCriteriaLabel.NAME,
            priority: 1,
            completed: !!restaurant.name,
            points: restaurant.isBrandBusiness() ? 20 : 10,
        };
    }

    private _createGaugeInformationForAddress(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant || restaurant.isBrandBusiness()) {
            return undefined;
        }

        const hasAddress =
            !!restaurant.address?.locality &&
            !!restaurant.address?.country &&
            !!restaurant.address?.postalCode &&
            !!restaurant.address?.regionCode;

        return {
            label: GaugeCriteriaLabel.ADDRESS,
            priority: 2,
            completed: hasAddress,
            points: 10,
        };
    }

    private _createGaugeInformationForCategory(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant) {
            return undefined;
        }

        return {
            label: GaugeCriteriaLabel.CATEGORY,
            priority: 3,
            completed: isNotNil(restaurant.category),
            points: restaurant.isBrandBusiness() ? 20 : 10,
        };
    }

    private _createGaugeInformationForRegularHours(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant || restaurant.isBrandBusiness()) {
            return undefined;
        }

        return {
            label: GaugeCriteriaLabel.REGULAR_HOURS,
            priority: 4,
            completed: restaurant.regularHours.length > 0,
            points: 10,
        };
    }

    private _createGaugeInformationForPhone(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant || restaurant.isBrandBusiness()) {
            return undefined;
        }

        const hasPhone = !!restaurant?.phone?.digits && !!restaurant?.phone?.prefix;

        return {
            label: GaugeCriteriaLabel.PHONE,
            priority: 5,
            completed: hasPhone,
            points: 10,
        };
    }

    private _createGaugeInformationForDescriptions(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant) {
            return undefined;
        }

        const hasShortDescription = restaurant.descriptions.some(
            (desc) => desc.size === DescriptionSize.SHORT && !!desc.text && desc.text?.length > this._DESCRIPTION_SIZE_MIN
        );
        const hasLongDescription = restaurant.descriptions.some(
            (desc) => desc.size === DescriptionSize.LONG && !!desc.text && desc.text?.length > this._DESCRIPTION_SIZE_MIN
        );

        return {
            label: GaugeCriteriaLabel.DESCRIPTIONS,
            priority: 6,
            completed: hasShortDescription && (restaurant.isBrandBusiness() || hasLongDescription),
            points: restaurant.isBrandBusiness() ? 20 : 10,
        };
    }

    private _createGaugeInformationForSecondaryCategories(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant) {
            return undefined;
        }

        return {
            label: GaugeCriteriaLabel.SECONDARY_CATEGORIES,
            priority: 7,
            completed: restaurant.categoryList.length >= this._SECONDARY_CATEGORIES_MIN,
            points: 10,
        };
    }

    private _createGaugeInformationForAttributes(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant || restaurant.isBrandBusiness()) {
            return undefined;
        }

        const allPossibleAttributesLength = this._informationsContext.allCategoryAttributes().length;
        const restaurantAttributesLength = this._informationsContext.restaurantAttributes().length;

        const hasAllAttributesSet =
            allPossibleAttributesLength > 0 && restaurantAttributesLength > 0 && allPossibleAttributesLength === restaurantAttributesLength;

        return {
            label: GaugeCriteriaLabel.ATTRIBUTES,
            priority: 8,
            completed: hasAllAttributesSet,
            points: 10,
        };
    }

    private _createGaugeInformationForUrls(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant) {
            return undefined;
        }

        // TODO add social media links, reservation link and delivery link
        const hasAtLeastOneLink = !!restaurant.website;

        return {
            label: GaugeCriteriaLabel.WEBSITE,
            priority: 9,
            completed: hasAtLeastOneLink,
            points: restaurant.isBrandBusiness() ? 20 : 10,
        };
    }

    private _createGaugeInformationForLogo(): GaugeInformation | undefined {
        const restaurant = this._restaurant();

        if (!restaurant) {
            return undefined;
        }

        return {
            label: GaugeCriteriaLabel.LOGO,
            priority: 10,
            completed: isNotNil(restaurant.logo),
            points: 10,
        };
    }
}
