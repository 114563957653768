import { DateTime } from 'luxon';

import { PlatformCategory, StoredInDBInsightsMetric, TimeInMilliseconds } from '../../constants';
import { AdditionalCustomerKey, PerformanceScoreCriteriaDetails, PerformanceScoreKey, RoiSavedTime, SavedTimeKey } from './roi.interface';

export const RoiRevenueOptions = [
    {
        min: 5_000,
        max: 10_000,
    },
    {
        min: 10_000,
        max: 15_000,
    },
    {
        min: 15_000,
        max: 20_000,
    },
    {
        min: 20_000,
        max: 25_000,
    },
    {
        min: 25_000,
        max: 30_000,
    },
    {
        min: 30_000,
        max: 40_000,
    },
    {
        min: 40_000,
        max: 50_000,
    },
    {
        min: 50_000,
        max: 60_000,
    },
    {
        min: 60_000,
        max: 70_000,
    },
    {
        min: 70_000,
        max: 80_000,
    },
    {
        min: 80_000,
        max: 90_000,
    },
    {
        min: 90_000,
        max: 100_000,
    },
    {
        min: 100_000,
        max: 120_000,
    },
    {
        min: 120_000,
        max: 140_000,
    },
    {
        min: 140_000,
        max: 160_000,
    },
    {
        min: 160_000,
        max: 180_000,
    },
    {
        min: 180_000,
        max: 200_000,
    },
    {
        min: 200_000,
        max: 230_000,
    },
    {
        min: 230_000,
        max: 260_000,
    },
    {
        min: 260_000,
        max: 300_000,
    },
    {
        min: 300_000,
        max: 350_000,
    },
    {
        min: 350_000,
        max: 400_000,
    },
    {
        min: 400_000,
        max: 450_000,
    },
    {
        min: 450_000,
        max: 500_000,
    },
    {
        min: 500_000,
        max: 600_000,
    },
    {
        min: 600_000,
        max: 700_000,
    },
    {
        min: 700_000,
        max: 800_000,
    },
    {
        min: 800_000,
        max: 900_000,
    },
    {
        min: 900_000,
        max: 1_000_000,
    },
    {
        min: 1_000_000,
        max: 1_500_000,
    },
    {
        min: 1_500_000,
        max: 2_000_000,
    },
    {
        min: 2_000_000,
        max: 2_000_000,
    },
];

export const savedTimeDurations: Record<keyof Omit<RoiSavedTime, 'totalTime'>, number> = {
    // Reviews
    [SavedTimeKey.reviewsAnsweredAutomatically]: 6 * TimeInMilliseconds.MINUTE, // 6mins
    [SavedTimeKey.reviewsAnsweredWithTemplate]: 4 * TimeInMilliseconds.MINUTE, // 4mins
    [SavedTimeKey.reviewsAnsweredManually]: 2 * TimeInMilliseconds.MINUTE, // 2mins
    [SavedTimeKey.reviewsAnsweredManuallyWithAi]: 2.5 * TimeInMilliseconds.MINUTE, // 2mins30secs
    [SavedTimeKey.reviewsAnsweredManuallyWithAiOnAggregatedView]: 3.25 * TimeInMilliseconds.MINUTE, // 2mins30secs * 1.3
    [SavedTimeKey.reviewsAnsweredManuallyOnAggregatedView]: 2.6 * TimeInMilliseconds.MINUTE, // 2mins * 1.3,
    [SavedTimeKey.reviewsAnsweredWithTemplateOnAggregatedView]: 5.2 * TimeInMilliseconds.MINUTE, // 4mins * 1.3

    // Reports
    [SavedTimeKey.reviewsReports]: 4 * TimeInMilliseconds.MINUTE, // 4mins
    [SavedTimeKey.performanceReports]: 6 * TimeInMilliseconds.MINUTE, //6mins
    [SavedTimeKey.aggregatedReviewsReports]: 5.2 * TimeInMilliseconds.MINUTE, // 4mins * 1.3
    [SavedTimeKey.aggregatedPerformanceReports]: 7.8 * TimeInMilliseconds.MINUTE, // 6mins * 1.3
    [SavedTimeKey.activatedReports]: 0, // used to display in front

    // Seo posts
    [SavedTimeKey.publishedSeoPosts]: 6 * TimeInMilliseconds.MINUTE, // 6mins
    [SavedTimeKey.aiGeneratedLegendSeoPosts]: 10 * TimeInMilliseconds.MINUTE, // 10mins
    [SavedTimeKey.aiGeneratedHashtags]: 2 * TimeInMilliseconds.MINUTE, // 2mins

    // Social posts
    [SavedTimeKey.publishedSocialPosts]: 4 * TimeInMilliseconds.MINUTE, // 4mins
    [SavedTimeKey.publishedStories]: 30 * TimeInMilliseconds.SECOND, // 30secs
    [SavedTimeKey.aiGeneratedLegendSocialPosts]: 10 * TimeInMilliseconds.MINUTE, // 10mins

    // Interactions
    [SavedTimeKey.answeredMentions]: 1.3 * TimeInMilliseconds.MINUTE, // 1min20secs
    [SavedTimeKey.answeredComments]: 1.3 * TimeInMilliseconds.MINUTE, // 1min20secs
    [SavedTimeKey.sentMessages]: 30 * TimeInMilliseconds.SECOND, // 30secs
    [SavedTimeKey.usedMessageTemplates]: 45 * TimeInMilliseconds.SECOND, // 45secs

    // Duplication
    [SavedTimeKey.duplicatedSeoPosts]: 7 * TimeInMilliseconds.MINUTE, // 7mins for targeted restaurant
    [SavedTimeKey.duplicatedSocialPosts]: 9 * TimeInMilliseconds.MINUTE, // 9mins for targeted restaurant
    [SavedTimeKey.duplicatedStories]: 3 * TimeInMilliseconds.MINUTE, // 3mins for targeted restaurant

    [SavedTimeKey.duplicatedDescriptions]: 3 * TimeInMilliseconds.MINUTE, // 3mins for targeted restaurant
    [SavedTimeKey.duplicatedAttributes]: 7 * TimeInMilliseconds.MINUTE, // 7mins for targeted restaurant
    [SavedTimeKey.duplicatedKeywords]: 8 * TimeInMilliseconds.MINUTE, // 8mins for targeted restaurant
    [SavedTimeKey.duplicatedMedia]: 20 * TimeInMilliseconds.SECOND, // 20secs for targeted restaurant
    [SavedTimeKey.duplicatedMessageTemplates]: 2 * TimeInMilliseconds.MINUTE, // 2mins for targeted restaurant
    [SavedTimeKey.duplicatedReviewTemplates]: 4 * TimeInMilliseconds.MINUTE, // 4mins for targeted restaurant
    [SavedTimeKey.duplicatedClients]: 2 * TimeInMilliseconds.MINUTE, // 2mins for targeted restaurant

    // Information update
    [SavedTimeKey.informationUpdateClicks]: 60 * TimeInMilliseconds.MINUTE, // 60mins for each update

    // Campaigns
    [SavedTimeKey.launchedCampaigns]: 0, // used to display in front
    [SavedTimeKey.launchedCampaignsClients]: 2 * TimeInMilliseconds.MINUTE, // 2mins for each client of each campaign
};

export const SEVENTY_TWO_HOURS = 72 * TimeInMilliseconds.HOUR;

export const fullPlanGoalAndRatioByCriteria: PerformanceScoreCriteriaDetails = {
    [PerformanceScoreKey.receivedReviewsCount]: {
        weight: 6,
    },
    [PerformanceScoreKey.answeredReviewsRatio]: {
        goal: 100,
        weight: 14,
    },
    [PerformanceScoreKey.gmbRating]: {
        goal: 5,
        weight: 6,
    },
    [PerformanceScoreKey.positiveReviewsRatio]: {
        goal: 100,
        weight: 8,
    },
    [PerformanceScoreKey.averageAnswerTime]: {
        goal: SEVENTY_TWO_HOURS,
        weight: 14,
    },
    [PerformanceScoreKey.keywordScore]: {
        goal: 5,
        weight: 12,
    },
    [PerformanceScoreKey.keywordsInTopTen]: {
        goal: 10,
        weight: 8,
    },
    [PerformanceScoreKey.gmbPostCount]: {
        goal: 4,
        weight: 10,
    },
    [PerformanceScoreKey.facebookPostCount]: {
        goal: 8,
        weight: 8,
    },
    [PerformanceScoreKey.instagramPostCount]: {
        goal: 8,
        weight: 8,
    },
    [PerformanceScoreKey.socialImpressionsCount]: {
        weight: 6,
    },
};

export const refOnlyGoalAndRatioByCriteria: Partial<PerformanceScoreCriteriaDetails> = {
    [PerformanceScoreKey.receivedReviewsCount]: {
        weight: 8,
    },
    [PerformanceScoreKey.answeredReviewsRatio]: {
        goal: 100,
        weight: 25,
    },
    [PerformanceScoreKey.gmbRating]: {
        goal: 5,
        weight: 10,
    },
    [PerformanceScoreKey.positiveReviewsRatio]: {
        goal: 100,
        weight: 8,
    },
    [PerformanceScoreKey.averageAnswerTime]: {
        goal: SEVENTY_TWO_HOURS,
        weight: 12,
    },
    [PerformanceScoreKey.keywordScore]: {
        goal: 5,
        weight: 10,
    },
    [PerformanceScoreKey.keywordsInTopTen]: {
        goal: 10,
        weight: 12,
    },
    [PerformanceScoreKey.gmbPostCount]: {
        goal: 4,
        weight: 15,
    },
};

export const roiTipsConfig = {
    // Global
    MAX_TIP_COUNT: 5,
    MAX_DURATION_IN_MONTHS: 3,

    // Social Media
    MIN_TOTAL_POST_COUNT: 5,
    MIN_SOCIAL_POST_COUNT: 8,
    MIN_STORY_COUNT: 3,
    MIN_HASHTAG_COUNT: 3,
    MAX_HASHTAG_COUNT: 8,

    // Reviews
    MIN_REVIEW_COUNT: 5,
    MIN_POSITIVE_REVIEW_RATING: 4,

    MAX_TAG_APPEARANCE_PERCENTAGE_IN_NEGATIVE_REVIEWS: 30,

    MAX_RESPONSE_TIME_IN_DAYS: 4,

    // Interaction
    MAX_TOLERATED_UNANSWERED_MESSAGES_PERCENTAGE: 0.8,

    MAX_TOLERATED_UNANSWERED_COMMENTS_AND_MENTIONS_PERCENTAGE: 0.3,

    // Information
    MIN_ATTRIBUTE_COUNT: 20,
    MAX_ATTRIBUTE_COUNT: 50,

    MIN_INFORMATION_DATA_SCORE: 7,
    MAX_INFORMATION_DATA_SCORE: 10,

    // Google
    MIN_ACTION_BUTTON_COUNT: 3,
    MIN_AVERAGE_KEYWORD_SCORE: 4,
    MIN_POST_COUNT: 4,
};

export const MAX_TIPS_TO_SHOW_CONGRATS = 3;
export const MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS = 50;

export const actionsConversionRate: Record<AdditionalCustomerKey, number> = {
    [AdditionalCustomerKey.gmbDirectionRequests]: 0.45,
    [AdditionalCustomerKey.gmbBooking]: 0.98,
    [AdditionalCustomerKey.gmbPhoneCallClicks]: 0.5,
    [AdditionalCustomerKey.gmbFoodMenuClicks]: 0.2,
    [AdditionalCustomerKey.gmbWebsiteClicks]: 0.02,
    [AdditionalCustomerKey.gmbFoodOrders]: 0.98,
    [AdditionalCustomerKey.socialDirectionRequests]: 0.15,
    [AdditionalCustomerKey.socialPhoneCallClicks]: 0.35,
    [AdditionalCustomerKey.socialWebsiteClicks]: 0.015,
    [AdditionalCustomerKey.socialEmailContacts]: 0.04,
    [AdditionalCustomerKey.socialMessageSent]: 0.02,
    [AdditionalCustomerKey.socialImpressions]: 0.0001,
    [AdditionalCustomerKey.socialShares]: 0.05,
    [AdditionalCustomerKey.socialSaves]: 0.05,
};

export const additionalCustomersMetricsByPlatformCategory: Partial<Record<PlatformCategory, StoredInDBInsightsMetric[]>> = {
    [PlatformCategory.SEO]: [
        StoredInDBInsightsMetric.BUSINESS_BOOKINGS,
        StoredInDBInsightsMetric.DIRECTION_REQUESTS,
        StoredInDBInsightsMetric.BUSINESS_FOOD_MENU_CLICKS,
        StoredInDBInsightsMetric.BUSINESS_FOOD_ORDERS,
        StoredInDBInsightsMetric.PHONE_CALL_CLICKS,
        StoredInDBInsightsMetric.WEBSITE_CLICKS,
    ],
    [PlatformCategory.SOCIAL]: [
        StoredInDBInsightsMetric.DIRECTION_REQUESTS,
        StoredInDBInsightsMetric.IMPRESSIONS,
        StoredInDBInsightsMetric.PHONE_CALL_CLICKS,
        StoredInDBInsightsMetric.WEBSITE_CLICKS,
        StoredInDBInsightsMetric.TEXT_MESSAGE_CLICKS,
        StoredInDBInsightsMetric.EMAIL_CONTACTS,
        StoredInDBInsightsMetric.SAVES,
        StoredInDBInsightsMetric.SHARES,
    ],
};

export const additionalCustomersSeoKeys = [
    AdditionalCustomerKey.gmbPhoneCallClicks,
    AdditionalCustomerKey.gmbDirectionRequests,
    AdditionalCustomerKey.gmbFoodMenuClicks,
    AdditionalCustomerKey.gmbBooking,
    AdditionalCustomerKey.gmbFoodOrders,
    AdditionalCustomerKey.gmbWebsiteClicks,
];

export const additionalCustomersSocialKeys = [
    AdditionalCustomerKey.socialPhoneCallClicks,
    AdditionalCustomerKey.socialDirectionRequests,
    AdditionalCustomerKey.socialWebsiteClicks,
    AdditionalCustomerKey.socialMessageSent,
    AdditionalCustomerKey.socialEmailContacts,
    AdditionalCustomerKey.socialImpressions,
    AdditionalCustomerKey.socialShares,
    AdditionalCustomerKey.socialSaves,
];

export const RoiAdditionalCustomerRangePercentage = 0.05;

// TODO ROI: Remove this constant when the legacy restaurants fill up their roi settings
export const RoiSettingsDataGatheringStartDateForLegacyRestaurants = DateTime.fromISO('2024-07-10T16:00:00.000Z');

export const ROI_MINIMUM_KEYWORD_SCORE = 2;

export const ROI_MINIMUM_GMB_RATING_SCORE = 3.5;

export const ROI_HIDDEN_FIRST_MONTHS_NUMBER = 3;
