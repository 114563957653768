import { Component } from '@angular/core';

import { SocialPostsListComponent } from './social-posts-list/social-posts-list.component';

@Component({
    selector: 'app-social-posts-root',
    templateUrl: './social-posts-root.component.html',
    styleUrls: ['./social-posts-root.component.scss'],
    standalone: true,
    imports: [SocialPostsListComponent],
})
export class SocialPostsRootComponent {
    constructor() {}
}
