import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, ElementRef, input, output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { ImageCroppedEvent, ImageCropperModule, LoadedImage, OutputFormat } from 'ngx-image-cropper';

import { PictureSize } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { IGAccount } from ':modules/inspirations/inspirations/edit-inspiring-accounts-dialog/edit-inspiring-accounts-dialog.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { CropOption } from ':shared/enums/crop-options';
import { parseAspectRatio } from ':shared/helpers/dimensions';
import { Media, UserTag } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import { TagAccountComponent } from '../tag-account/tag-account.component';

@Component({
    selector: 'app-media-view',
    templateUrl: './media-view.component.html',
    styleUrls: ['./media-view.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatIconModule,
        NgClass,
        ImageCropperModule,
        SkeletonComponent,
        NgStyle,
        ApplyPurePipe,
        TagAccountComponent,
        MalouSpinnerComponent,
    ],
})
export class MediaViewComponent {
    readonly media = input.required<Media>();
    readonly originalMedia = input.required<Media>();
    readonly isCropping = input.required<boolean>();
    readonly imageCropperMediaLoading = input.required<boolean>();

    readonly state = input<string>();
    readonly angle = input<number>();
    readonly ratio = input<number>();
    readonly format = input<OutputFormat>();

    readonly cropSize = input.required<CropOption>();
    readonly maxMedia = input.required<number>();
    readonly isMediaDeletable = input.required<boolean>();
    readonly disabled = input.required<boolean>();
    readonly tagButtonToggled = input.required<boolean>();
    readonly showTagContainer = input.required<boolean>();
    readonly userTags = input.required<UserTag[]>();
    readonly tagControl = input.required<FormControl<string | null>>();
    readonly foundAccount = input.required<IGAccount | null>();
    readonly searching = input.required<boolean>();

    readonly onAccountSelected = output<MatAutocompleteSelectedEvent>();
    readonly imageCroppedEvent = output<ImageCroppedEvent>();
    readonly imageLoadedEvent = output<LoadedImage>();
    readonly removeMedia = output<Media>();
    readonly toggleShowTagContainer = output<MouseEvent>();
    readonly removeAccount = output<string>();

    readonly mediaUrl = computed(() => this.media().getMediaUrl(PictureSize.IG_FIT));

    readonly isMediaVideo = computed(() => this.media().isVideo());

    readonly aspectRatioClass = computed(() => {
        if (this.cropSize() === CropOption.SQUARE) {
            return 'aspect-square';
        }

        if (this.cropSize() === CropOption.LANDSCAPE) {
            return 'aspect-video';
        }

        return 'aspect-[4/5]';
    });

    readonly mediaSizeClass = computed(() => {
        if (this.cropSize() === CropOption.SQUARE) {
            return 'w-full h-full';
        }

        if (this.cropSize() === CropOption.LANDSCAPE) {
            return 'w-full';
        }

        return 'h-full';
    });

    readonly objectFitClass = computed(() => {
        const mediaAspectRatio = parseAspectRatio(this.media().getAspectRatio());
        if (this.cropSize() === mediaAspectRatio) {
            return 'object-contain';
        }

        return 'object-cover';
    });

    videoPlayerElement: HTMLVideoElement;

    readonly SvgIcon = SvgIcon;

    @ViewChild('videoPlayer', { static: false }) set videoPlayerRef(videoPlayerRef: ElementRef<HTMLVideoElement>) {
        if (videoPlayerRef) {
            this.videoPlayerElement = videoPlayerRef.nativeElement;
        }
    }

    playVideo(): void {
        if (!this.videoPlayerElement) {
            return;
        }
        if (this.videoPlayerElement?.paused) {
            this.videoPlayerElement.play();
        } else {
            this.videoPlayerElement.pause();
        }
    }

    muteUnmuteVideo(): void {
        if (!this.videoPlayerElement) {
            return;
        }
        this.videoPlayerElement.muted = !this.videoPlayerElement.muted;
    }

    isVideoMuted(): boolean {
        return !!this.videoPlayerElement?.muted;
    }

    isVideoPlaying(): boolean {
        return !this.videoPlayerElement?.paused;
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.imageCroppedEvent.emit(event);
    }

    imageLoaded(event: LoadedImage): void {
        this.imageLoadedEvent.emit(event);
    }

    removeMediaClick(): void {
        this.removeMedia.emit(this.media());
    }

    getCurrentTagPositionInMedia(x: number, y: number): { left: string; top: string } {
        const image = document.getElementById('imageContainer') as HTMLImageElement;
        const width = image.offsetWidth;
        const height = image.offsetHeight;

        return {
            left: x * width + 'px',
            top: y * height + 'px',
        };
    }

    toggleShowTagContainerClick(event: MouseEvent): void {
        this.toggleShowTagContainer.emit(event);
    }

    removeAccountClick(username: string): void {
        this.removeAccount.emit(username);
    }
}
