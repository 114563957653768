<div class="flex-between malou-box-shadow malou-color-bg-white flex w-[500px] max-w-[88vw] items-start rounded-[10px] p-4">
    <div class="mr-1.5 pt-[3px]">
        <img class="h-4.5 w-4.5" alt="openai logo" [src]="'openai' | platformLogoPathResolver" />
    </div>
    <div class="mx-1.5 grow" [formGroup]="promptForm">
        <textarea
            class="malou-text-13--regular w-full"
            id="openaiPromptTextarea"
            formControlName="prompt"
            maxlength="300"
            [placeholder]="placeholder"
            [cdkTextareaAutosize]="true"
            [cdkAutosizeMaxRows]="3"
            [cdkAutosizeMinRows]="2"
            (focus)="setFirstDefaultPrompt()"
            (ngModelChange)="onValueChange($event)"></textarea>

        <div class="flex w-full justify-end">
            <span class="malou-text-9--regular malou-color-text-2 italic">
                {{ 'openai.max_chars' | translate: { maxChars: promptLength } }}
            </span>
        </div>
    </div>

    <div class="flex items-start">
        <mat-icon
            class="mx-1.5 !w-4 cursor-pointer"
            color="primary"
            [svgIcon]="SvgIcon.ARROW_RIGHT"
            [id]="validateButtonId"
            (click)="emitPrompt()"></mat-icon>
        <span class="mx-1.5 opacity-50">|</span>
        <div class="relative">
            <mat-icon
                class="mx-1.5 !w-4 cursor-pointer"
                color="primary"
                cdkOverlayOrigin
                [svgIcon]="shouldDisplayDefaultPromptList ? 'interdiction' : 'add'"
                (click)="shouldDisplayDefaultPromptList = !shouldDisplayDefaultPromptList"
                #trigger="cdkOverlayOrigin"></mat-icon>

            <ng-template
                cdkConnectedOverlay
                [cdkConnectedOverlayOrigin]="trigger"
                [cdkConnectedOverlayOpen]="shouldDisplayDefaultPromptList"
                [cdkConnectedOverlayPositions]="positionPairs">
                <div class="malou-box-shadow malou-color-bg-white rounded-[10px]">
                    @for (promptOption of defaultPromptList; track promptOption) {
                        <button class="flex gap-x-3" mat-menu-item (click)="choosePromptOption(promptOption)">
                            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.OPENAI"></mat-icon>
                            <span class="malou-text-14--regular">{{ getDefaultPromptOptionText | applyPure: promptOption }}</span>
                        </button>
                    }
                </div>
            </ng-template>
        </div>
    </div>
</div>
