import { AsyncPipe } from '@angular/common';
import { Component, computed, EventEmitter, Input, OnInit, Output, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { ConversationStatus, LaunchState, PlatformKey } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { SearchComponent } from ':shared/components/search/search.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { AvailablePlatform, GoogleCommunicationsAgent } from ':shared/models';
import { ConversationWithMessages } from ':shared/models/conversation';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

@Component({
    selector: 'app-messages-filters',
    templateUrl: './messages-filters.component.html',
    styleUrls: ['./messages-filters.component.scss'],
    standalone: true,
    imports: [
        SearchComponent,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatMenuModule,
        MatRippleModule,
        AsyncPipe,
        PlatformLogoPathResolverPipe,
        TranslateModule,
        ApplyPurePipe,
        IncludesPipe,
        LazyLoadImageModule,
    ],
})
export class MessagesFiltersComponent implements OnInit {
    @Input() areAllSocialPlatformsDisconnected: boolean;
    @Input() availablePlatforms$: BehaviorSubject<AvailablePlatform[]>;
    @Input() conversationStatusFilters: ConversationStatus[];
    @Input() currentGoogleAgent: GoogleCommunicationsAgent | undefined;
    @Input() hasUpdateFinished$: BehaviorSubject<boolean>;
    @Input() checkedConversationsWithMessages: Signal<ConversationWithMessages[]>;
    @Output() onSynchronizeMessages: EventEmitter<void> = new EventEmitter();
    @Output() onOpenSettingsModal: EventEmitter<void> = new EventEmitter();
    @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
    @Output() onChangeSelectedPlatforms: EventEmitter<{ checked: boolean; platform: AvailablePlatform }> = new EventEmitter();
    @Output() onConversationStatusChange: EventEmitter<ConversationStatus[]> = new EventEmitter();
    @Output() onMarkConversationsAsUnread: EventEmitter<void> = new EventEmitter();
    @Output() onMarkConversationsAsRead: EventEmitter<void> = new EventEmitter();
    @Output() onAddConversationsToFavorites: EventEmitter<void> = new EventEmitter();
    @Output() onRemoveConversationsFromFavorites: EventEmitter<void> = new EventEmitter();
    @Output() onUnarchiveConversations: EventEmitter<void> = new EventEmitter();
    @Output() onArchiveConversations: EventEmitter<void> = new EventEmitter();

    readonly SvgIcon = SvgIcon;

    ConversationStatus = ConversationStatus;

    filteredAvailablePlatforms$: Observable<AvailablePlatform[]>;
    readonly trackByKeyFn = TrackByFunctionFactory.get('key');

    readonly atLeastOneMessageSelected = computed(() => this.checkedConversationsWithMessages().length > 0);
    readonly allSelectedConversationsAreRead = computed(() =>
        this.checkedConversationsWithMessages().every(
            (conversationWithMessages) => conversationWithMessages.conversation.status === ConversationStatus.READ
        )
    );
    readonly atLeastOneSelectedConversationIsUnread = computed(() =>
        this.checkedConversationsWithMessages().some(
            (conversationWithMessages) => conversationWithMessages.conversation.status === ConversationStatus.UNREAD
        )
    );
    readonly allSelectedConversationsAreArchived = computed(() =>
        this.checkedConversationsWithMessages().every((conversationWithMessages) => conversationWithMessages.conversation.archived)
    );
    readonly noSelectedConversationIsArchived = computed(() =>
        this.checkedConversationsWithMessages().every((conversationWithMessages) => !conversationWithMessages.conversation.archived)
    );
    readonly noSelectedConversationIsInFavorites = computed(() =>
        this.checkedConversationsWithMessages().every((conversationWithMessages) => !conversationWithMessages.conversation.favorite)
    );
    readonly atLeastOneSelectedConversationIsInFavorites = computed(() =>
        this.checkedConversationsWithMessages().some((conversationWithMessages) => conversationWithMessages.conversation.favorite)
    );
    readonly unreadConversationCount = computed(
        () =>
            this.checkedConversationsWithMessages().filter(
                (conversationWithMessages) => conversationWithMessages.conversation.status === ConversationStatus.UNREAD
            ).length
    );
    readonly readConversationCount = computed(
        () =>
            this.checkedConversationsWithMessages().filter(
                (conversationWithMessages) => conversationWithMessages.conversation.status === ConversationStatus.READ
            ).length
    );

    readonly isGoogleMessagingFeatureEnabled = toSignal(this._experimentationService.isFeatureEnabled$('experiment-google-messaging'), {
        initialValue: this._experimentationService.isFeatureEnabled('experiment-google-messaging'),
    });

    constructor(
        public screenSizeService: ScreenSizeService,
        private readonly _experimentationService: ExperimentationService
    ) {}

    ngOnInit(): void {
        this.filteredAvailablePlatforms$ = this.getFilteredAvailablePlatforms$();
    }

    startSynchronizeMessages(): void {
        this.onSynchronizeMessages.emit();
    }

    openSettingsModal(): void {
        this.onOpenSettingsModal.emit();
    }

    searchValueChange(searchValue: string): void {
        this.onSearchValueChange.emit(searchValue);
    }

    changeSelectedPlatforms(platform: AvailablePlatform): void {
        if (platform.connected) {
            this.onChangeSelectedPlatforms.emit({ checked: !platform.checked, platform });
        }
    }

    isPlatformDisabled = (platform: AvailablePlatform): boolean => {
        if (platform.key !== PlatformKey.GMB) {
            return !platform.connected;
        }
        return !platform.connected || !this.isGoogleMessagingEnabled(this.currentGoogleAgent?.launchState);
    };

    isGoogleMessagingEnabled(launchStateStr?: string): boolean {
        return launchStateStr === LaunchState.LAUNCH_STATE_LAUNCHED;
    }

    changeConversationsStatus(statusClicked: ConversationStatus): void {
        const statuses = this.conversationStatusFilters.includes(statusClicked)
            ? this.conversationStatusFilters.filter((status) => status !== statusClicked)
            : [...this.conversationStatusFilters, statusClicked];

        this.onConversationStatusChange.emit(statuses);
    }

    clearFilters(): void {
        this.onConversationStatusChange.emit([ConversationStatus.READ, ConversationStatus.UNREAD]);
        this.availablePlatforms$.value
            .filter((platform) => platform.connected)
            .forEach((platform) => this.onChangeSelectedPlatforms.emit({ checked: true, platform: platform }));
    }

    getFilteredAvailablePlatforms$(): Observable<AvailablePlatform[]> {
        return this.availablePlatforms$.pipe(map((availablePlatforms) => availablePlatforms.filter((platform) => platform.connected)));
    }

    markSelectedConversationsAsUnread(): void {
        this.onMarkConversationsAsUnread.emit();
    }

    markSelectedConversationsAsRead(): void {
        this.onMarkConversationsAsRead.emit();
    }

    addSelectedConversationsToFavorites(): void {
        this.onAddConversationsToFavorites.emit();
    }

    removeSelectedConversationsFromFavorites(): void {
        this.onRemoveConversationsFromFavorites.emit();
    }

    unarchiveSelectedConversations(): void {
        this.onUnarchiveConversations.emit();
    }

    archiveSelectedConversations(): void {
        this.onArchiveConversations.emit();
    }
}
