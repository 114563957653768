import { IStoryVideoCheck } from ':core/constants';

export const getStoryVideoValidity = (video: HTMLVideoElement): IStoryVideoCheck => {
    const duration = Math.floor(video.duration);
    const maxDuration = 59;
    const minDuration = 3;
    const isDurationValid = duration <= maxDuration && duration >= minDuration;

    const width = video.videoWidth;
    const maxWidth = 1920;
    const isWidthValid = width <= maxWidth;

    const height = video.videoHeight;

    const extension = video.getElementsByTagName('source')?.[0]?.type?.split('/')?.pop() ?? '';
    const isExtensionValid = ['mp4', 'mov'].includes(extension);

    const ratio = width / height;
    const maxRatio = 10;
    const minRatio = 0.1;
    const isRatioValid = ratio <= maxRatio && ratio >= minRatio;

    return {
        duration,
        maxDuration,
        minDuration,
        isDurationValid,
        width,
        maxWidth,
        isWidthValid,
        height,
        extension,
        isExtensionValid,
        ratio,
        maxRatio,
        minRatio,
        isRatioValid,
        error: !isDurationValid || !isWidthValid || !isExtensionValid || !isRatioValid,
    };
};
