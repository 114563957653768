@let currentRestaurant = restaurant();

@if (!isLoading()) {
    @if (currentRestaurant) {
        <ng-container [ngTemplateOutlet]="isFeaturePlatformsUpdatesEnabled() ? platformsUpdatesV2 : platformsUpdatesV1"></ng-container>
    }
}

<ng-template #platformsUpdatesV2>
    <div class="hide-scrollbar h-full overflow-y-auto">
        <div class="m-5 flex flex-col gap-6">
            <div class="flex flex-wrap justify-between rounded-[10px] bg-malou-color-background-dark p-6 sm:gap-5">
                <app-informations-gauge class="flex-2"></app-informations-gauge>
                <app-informations-updates-state class="flex flex-3 justify-center"></app-informations-updates-state>
            </div>

            @if (suggestionsCount() > 0) {
                <app-information-suggestions (openSuggestions)="openSuggestions($event)"></app-information-suggestions>
            }

            @if (updateState() === UpdateState.DIFFERENT && !currentRestaurant?.isBrandBusiness() && showDifferencesButton()) {
                <app-detected-inconsistencies
                    [totalDifferencesCount]="totalDifferencesCount()"
                    (openPlatformsComparisons)="openPlatformsComparisons()"></app-detected-inconsistencies>
            }

            <ng-container
                [ngTemplateOutlet]="currentRestaurant?.isBrandBusiness() ? brandBusinessTemplate : locationBusinessTemplate"
                [ngTemplateOutletContext]="{ restaurant: currentRestaurant }">
            </ng-container>
        </div>
    </div>
    <ng-template #customTooltipTemplate>
        @if (updateState() !== UpdateState.UPDATED && updateState() !== UpdateState.DIFFERENT) {
            <div class="platforms-update-tooltip flex min-w-max flex-col gap-y-2">
                @for (platform of platformsUpdateState(); track trackByKeyFn($index, platform)) {
                    <div class="platform-update-state flex items-center overflow-x-visible">
                        <div
                            class="rounded-full grid place-items-center mr-2 h-[15px] w-[15px] malou-bg-state-{{
                                (getIconNameAndBackgroundColorFromPlatformUpdateState | applyPure: platform).color
                            }}">
                            <mat-icon
                                class="!h-[9px] !w-[9px] !text-white"
                                [svgIcon]="(getIconNameAndBackgroundColorFromPlatformUpdateState | applyPure: platform).icon"></mat-icon>
                        </div>
                        <div class="platform-logo mr-2">
                            <img
                                class="rounded-full"
                                width="15"
                                [alt]="platform.key + ' logo'"
                                [src]="platform.key | platformLogoPathResolver: { folder: 'platforms' }" />
                        </div>
                        <div>
                            @if (platform.status === InformationUpdatePlatformStateStatus.ERROR) {
                                <span class="malou-text-9--medium text-white">
                                    {{
                                        'informations.tooltip_error_platform'
                                            | translate: { platformName: (getPlatformFullName | applyPure: platform.key) }
                                    }}
                                </span>
                            } @else {
                                <div class="malou-text-9--medium text-white">
                                    {{
                                        'informations.tooltip_updating_platform'
                                            | translate: { delay: (getDelayText | applyPure: platform.key) }
                                    }}
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        }
    </ng-template>

    <ng-template let-restaurant="restaurant" #brandBusinessTemplate>
        <div class="flex flex-wrap gap-6">
            <app-infos-restaurant class="flex-1" [restaurant]="restaurant" (updated)="updateRestaurantInformation($event)">
            </app-infos-restaurant>
            <app-description
                class="flex-2"
                [restaurant]="restaurant"
                (descriptionsChange)="updateRestaurantDescription($event)"
                (prepareDescriptionsDuplication)="onPrepareDescriptionsDuplication($event)">
            </app-description>
        </div>
    </ng-template>

    <ng-template let-restaurant="restaurant" #locationBusinessTemplate>
        <div class="flex flex-wrap gap-6">
            <app-infos-restaurant class="flex-3" [restaurant]="restaurant" (updated)="updateRestaurantInformation($event)">
            </app-infos-restaurant>
            <app-hours
                class="flex-5"
                [restaurant]="restaurant"
                (prepareHoursDuplication)="onPrepareHoursDuplication($event)"
                (hoursChange)="updateRestaurantHours($event)">
            </app-hours>
        </div>
        <div class="flex flex-wrap gap-6">
            <app-description
                class="flex-1"
                [restaurant]="restaurant"
                (descriptionsChange)="updateRestaurantDescription($event)"
                (prepareDescriptionsDuplication)="onPrepareDescriptionsDuplication($event)">
            </app-description>
            @if (restaurantHasAccessToGmb()) {
                <app-attributes
                    class="flex-1"
                    [restaurant]="restaurant"
                    (attributesChange)="updateRestaurantAttributes($event)"
                    (prepareAttributesDuplication)="onPrepareAttributesDuplication()">
                </app-attributes>
            }
        </div>
    </ng-template>
</ng-template>

<ng-template #platformsUpdatesV1>
    <div class="hide-scrollbar h-full overflow-y-auto">
        <div class="mx-5 flex justify-end gap-2 md:mx-5 min-xl:mx-10">
            <div class="wrapper relative grid place-items-center">
                <ng-container [ngTemplateOutlet]="customTooltipTemplate"></ng-container>
                @if (updateState() === UpdateState.DIFFERENT && !currentRestaurant?.isBrandBusiness() && showDifferencesButton()) {
                    <div class="flex cursor-pointer items-center" (click)="openPlatformsComparisons()">
                        <mat-icon
                            class="malou-color-background-chart-pink--accent malou-color-white cross-icon-border !h-3 !w-3 rounded-full"
                            [svgIcon]="SvgIcon.CROSS"></mat-icon>
                        <span class="malou-color-chart-pink--accent malou-text-12--bold ml-2">
                            {{ 'information.differences' | translate: { totalDifferencesCount: totalDifferencesCount() } }}
                        </span>
                        <button class="malou-btn-flat ml-2 !p-0" mat-button>
                            {{ 'common.see' | translate }}
                        </button>
                    </div>
                }
                @if (!currentRestaurant?.isBrandBusiness() && updateState() === UpdateState.WAITING) {
                    <div class="flex items-center">
                        <div class="malou-bg-state-warn mr-2 grid h-[15px] w-[15px] place-items-center rounded-full">
                            <mat-icon class="!h-[9px] !w-[9px] !text-white" color="primary" [svgIcon]="SvgIcon.ALARM"></mat-icon>
                        </div>
                        <span class="malou-text-12--bold">
                            {{ 'informations_update_footer.updating' | translate }}
                        </span>
                    </div>
                }
                @if (updateState() === UpdateState.UPDATED) {
                    <div class="flex items-center">
                        <div class="malou-bg-state-success mr-2 grid h-[15px] w-[15px] place-items-center rounded-full">
                            <mat-icon
                                class="!h-[9px] !w-[9px] text-white"
                                matTooltip="{{ 'information.update_modal.updated' | translate }}"
                                [svgIcon]="SvgIcon.CHECK">
                            </mat-icon>
                        </div>
                        <span class="malou-text-12--bold">
                            {{ 'information.update_modal.updated' | translate }}
                        </span>
                    </div>
                }
                @if (updateState() === UpdateState.ERROR) {
                    <div class="flex items-center">
                        <div class="malou-bg-state-error mr-2 grid h-[15px] w-[15px] place-items-center rounded-full">
                            <mat-icon
                                class="!h-[9px] !w-[9px] text-white"
                                matTooltip="{{ 'information.update_modal.failed' | translate }}"
                                [svgIcon]="SvgIcon.CROSS">
                            </mat-icon>
                        </div>
                        <span class="malou-text-12--bold">
                            {{ 'information.update_modal.failed' | translate }}
                        </span>
                    </div>
                }
            </div>
            <div
                [matTooltip]="'information.update_info_properties' | translate"
                [matTooltipDisabled]="!!platformPropertiesToUpdate().length">
                <button
                    class="malou-btn-raised--primary btn-xl !h-12.5"
                    mat-raised-button
                    [disabled]="
                        !(
                            currentRestaurant?.logoChanged ||
                            currentRestaurant?.coverChanged ||
                            (platformPropertiesToUpdate() && platformPropertiesToUpdate().length > 0)
                        ) || hasStartedUpdate()
                    "
                    (click)="startUpdate()">
                    {{ 'information.update_info' | translate }}
                </button>
            </div>
        </div>
        <ng-container
            [ngTemplateOutlet]="currentRestaurant?.isBrandBusiness() ? brandBusinessTemplate : locationBusinessTemplate"
            [ngTemplateOutletContext]="{ restaurant: currentRestaurant }">
        </ng-container>
    </div>

    <ng-template #customTooltipTemplate>
        @if (updateState() !== UpdateState.UPDATED && updateState() !== UpdateState.DIFFERENT) {
            <div class="platforms-update-tooltip flex min-w-max flex-col gap-y-2">
                @for (platform of platformsUpdateState(); track trackByKeyFn($index, platform)) {
                    <div class="platform-update-state flex items-center overflow-x-visible">
                        <div
                            class="rounded-full grid place-items-center mr-2 h-[15px] w-[15px] malou-bg-state-{{
                                (getIconNameAndBackgroundColorFromPlatformUpdateState | applyPure: platform).color
                            }}">
                            <mat-icon
                                class="!h-[9px] !w-[9px] !text-white"
                                [svgIcon]="(getIconNameAndBackgroundColorFromPlatformUpdateState | applyPure: platform).icon"></mat-icon>
                        </div>
                        <div class="platform-logo mr-2">
                            <img
                                class="rounded-full"
                                width="15"
                                [alt]="platform.key + ' logo'"
                                [src]="platform.key | platformLogoPathResolver: { folder: 'platforms' }" />
                        </div>
                        <div>
                            @if (platform.status === InformationUpdatePlatformStateStatus.ERROR) {
                                <span class="malou-text-9--medium text-white">
                                    {{
                                        'informations.tooltip_error_platform'
                                            | translate: { platformName: (getPlatformFullName | applyPure: platform.key) }
                                    }}
                                </span>
                            } @else {
                                <div class="malou-text-9--medium text-white">
                                    {{
                                        'informations.tooltip_updating_platform'
                                            | translate: { delay: (getDelayText | applyPure: platform.key) }
                                    }}
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        }
    </ng-template>

    <ng-template let-restaurant="restaurant" #brandBusinessTemplate>
        <div class="m-5 flex gap-6 sm:m-0" id="container-brand">
            <app-infos-restaurant [restaurant]="restaurant" (updated)="updateRestaurantInformation($event)"> </app-infos-restaurant>
            <app-description
                [restaurant]="restaurant"
                (descriptionsChange)="updateRestaurantDescription($event)"
                (prepareDescriptionsDuplication)="onPrepareDescriptionsDuplication($event)">
            </app-description>
        </div>
    </ng-template>

    <ng-template let-restaurant="restaurant" #locationBusinessTemplate>
        <div class="m-5 min-xl:mx-10" id="container">
            <app-infos-restaurant [restaurant]="restaurant" (updated)="updateRestaurantInformation($event)"> </app-infos-restaurant>
            <app-hours [restaurant]="restaurant" (hoursChange)="updateRestaurantHours($event)"> </app-hours>
            <app-description
                [restaurant]="restaurant"
                (descriptionsChange)="updateRestaurantDescription($event)"
                (prepareDescriptionsDuplication)="onPrepareDescriptionsDuplication($event)">
            </app-description>
            @if (restaurantHasAccessToGmb()) {
                <app-attributes
                    style="align-self: end"
                    [restaurant]="restaurant"
                    (attributesChange)="updateRestaurantAttributes($event)"
                    (prepareAttributesDuplication)="onPrepareAttributesDuplication()">
                </app-attributes>
            }
        </div>
    </ng-template>
</ng-template>
