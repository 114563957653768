import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { LoaderProgressComponent } from ':shared/components/loader-progress/loader-progress.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { InformationUpdatesStateModalComponent } from './information-updates-state-modal/information-updates-state-modal.component';

@Component({
    selector: 'app-informations-updates-state',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        SkeletonComponent,
        EnumTranslatePipe,
        FlagPathResolverPipe,
        LoaderProgressComponent,
        FormatDatePipe,
    ],
    templateUrl: './informations-updates-state.component.html',
    styleUrl: './informations-updates-state.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationsUpdatesStateComponent {
    readonly date = new Date();
    readonly SvgIcon = SvgIcon;

    private readonly _customDialogService = inject(CustomDialogService);

    openUpdateStateModal(): void {
        this._customDialogService
            .open(InformationUpdatesStateModalComponent, {
                width: '100%',
                panelClass: 'malou-dialog-panel--full',
                height: undefined,
                data: {},
            })
            .afterClosed()
            .subscribe({
                next: () => {},
            });
    }
}
