<div
    class="malou-box-shadow fixed right-0 top-0 z-10 h-full w-[25vw] max-w-[400px] bg-white transition-all duration-300 ease-in-out"
    [ngClass]="{ 'translate-x-[100%]': !notificationCenterContext.isOpen() }"
    (click)="$event.stopPropagation()">
    <div class="flex h-full flex-col">
        <div class="flex flex-1 items-center justify-between px-5 pt-3">
            <h3 class="malou-text-16--bold">Notifications ({{ notificationCenterContext.notifications().length }})</h3>
            <div class="flex items-center">
                <button class="malou-btn-icon" mat-icon-button></button>
                <button class="malou-btn-icon" mat-icon-button>
                    <mat-icon color="primary" [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
                </button>
                <button class="malou-btn-icon" mat-icon-button (click)="notificationCenterContext.close()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </button>
            </div>
        </div>
        <div class="flex flex-[12] flex-col overflow-y-auto">
            @for (notification of notificationCenterContext.notifications(); track notification.id) {
                <div class="notification-item relative w-full transition-all hover:cursor-pointer hover:bg-malou-color-background-light">
                    <app-notification-item-actions
                        class="absolute right-5 top-5"
                        [notification]="notification"></app-notification-item-actions>
                    @switch (notification.type) {
                        @case (NotificationType.REVIEW_REPLY_REMINDER) {
                            <app-negative-review-reminder-notification-item
                                [initialNotification]="notification"></app-negative-review-reminder-notification-item>
                        }
                        @case (NotificationType.REVIEW) {
                            <app-review-notification-item [initialNotification]="notification"></app-review-notification-item>
                        }
                        @default {
                            <app-special-hour-notification-item [initialNotification]="notification"></app-special-hour-notification-item>
                        }
                    }
                </div>
            }
        </div>
    </div>
</div>
