<form class="malou-expansion-panel mt-4" id="otherHoursForm" [formGroup]="otherHoursForm">
    @if (formHours.length) {
        <mat-accordion>
            @for (hour of formHours.controls; track hour; let i = $index) {
                <mat-expansion-panel [expanded]="expandedPanelIndex == i">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="malou-text-13--semibold malou-color-text-1">
                            {{ hour.value.hoursType?.getLocaleName(lang) }}
                        </mat-panel-title>
                        <mat-panel-description class="flex flex-row-reverse pr-3">
                            <mat-icon
                                class="icon-btn mr-2 h-4 !w-4"
                                color="primary"
                                [svgIcon]="SvgIcon.TRASH"
                                (click)="removeHoursAtIndex(i)"></mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <div class="px-2">
                            <app-business-hours-form
                                [id]="hour.value.hoursType?._id"
                                [shouldHoursHavePadding]="true"
                                [businessHours]="hour.value.periods ?? []"
                                [isClosedTemporarily]="restaurant.isClosedTemporarily"
                                (updateHours)="updateHoursAtIndex($event, i)"
                                (hasError)="handleFormErrors($event)">
                            </app-business-hours-form>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
            }
        </mat-accordion>
    }

    @if (hourTypes.length > 0) {
        <ng-container [ngTemplateOutlet]="addHoursTemplate"></ng-container>
    }
</form>

<ng-template #addHoursTemplate>
    <div class="mt-2 flex justify-start">
        <button
            class="malou-btn-flat flex items-center !pl-0"
            mat-button
            matTooltip="{{ 'information.other_hours.add_hour' | translate }}"
            [matMenuTriggerFor]="hourTypeSelectionMenu">
            <mat-icon class="icon-btn mr-2 h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
            <span>
                {{ 'information.other_hours.add_hour' | translate }}
            </span>
        </button>
    </div>
    <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #hourTypeSelectionMenu="matMenu">
        @for (item of hourTypes; track item) {
            <button class="!flex !h-8 items-center" mat-menu-item (click)="addOtherHours(item)">
                <span class="malou-text-12--regular malou-color-text-2">
                    {{ item.getLocaleName(lang) }}
                </span>
            </button>
        }
    </mat-menu>
</ng-template>
