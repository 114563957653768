import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { GetCredentialsService } from ':modules/platforms/platforms-connection-modals/shared/services/get-credentials.service';

type AuthorizedPlatformKeys = PlatformKey.GMB | PlatformKey.FACEBOOK | PlatformKey.ZENCHEF | PlatformKey.DELIVEROO;

@Injectable({
    providedIn: 'root',
})
export class GetStepIndexFromCredentialService {
    private readonly _getCredentialsService = inject(GetCredentialsService);

    execute(platformKey: AuthorizedPlatformKeys, notConnectedStepIndex: number, connectedStepIndex: number): Observable<number> {
        return this._getCredentialsService.execute(platformKey).pipe(
            map((credentials) => {
                if (credentials.length > 0) {
                    return connectedStepIndex;
                } else {
                    return notConnectedStepIndex;
                }
            })
        );
    }
}
