<div class="flex h-full flex-col">
    <div class="flex flex-1 flex-col gap-5 overflow-auto px-9 py-6">
        <div class="malou-text-18--bold text-malou-color-text-1">{{ 'admin.profile.alerts.title' | translate }}</div>
        <div class="malou-simple-card flex flex-col gap-y-10 p-6.5">
            <ng-container>
                <div class="flex gap-x-2">
                    <div class="flex min-w-0 grow flex-col">
                        <div class="flex justify-between">
                            <div class="malou-text-13--semibold text-malou-color-text-1">
                                {{ 'admin.profile.alerts.untreated_notifications' | translate }}
                            </div>
                            <div>
                                <app-slide-toggle
                                    activeLabel="{{ 'common.yes' | translate }}"
                                    inactiveLabel="{{ 'common.no' | translate }}"
                                    theme="primary"
                                    [switchWidth]="65"
                                    [checked]="wantsFeedbackNotifications"
                                    (onToggle)="toggleReceiveFeedback()">
                                </app-slide-toggle>
                            </div>
                        </div>
                        <div class="malou-text-13--regular text-malou-color-text-2">
                            {{ 'admin.profile.alerts.untreated_notifications_description' | translate }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="malou-simple-card flex flex-col gap-y-10 p-6.5">
            <div class="malou-text-13--semibold text-malou-color-text-1">
                {{ 'admin.profile.alerts.reminders' | translate }}
            </div>
            <ng-container [ngTemplateOutlet]="reviewReplyReminderTemplate"></ng-container>
        </div>
    </div>
</div>

<ng-template #reviewReplyReminderTemplate>
    <div class="malou-color-background-light flex justify-between rounded-[10px] p-3">
        <div class="gat-y-2 flex flex-col">
            <span class="malou-text-13--bold text-malou-color-text-1">
                {{ 'admin.profile.alerts.review_reminder.title' | translate }}
            </span>
            <span class="malou-text-10--regular italic text-malou-color-text-2">
                {{ 'admin.profile.alerts.review_reminder.text' | translate }}
            </span>
        </div>
        <div>
            <app-slide-toggle
                activeLabel="{{ 'common.yes' | translate }}"
                inactiveLabel="{{ 'common.no' | translate }}"
                theme="primary"
                [switchWidth]="65"
                [checked]="wantsReviewReplyReminderNotification()"
                (onToggle)="toggleReceiveReviewReplyReminderNotifications()">
            </app-slide-toggle>
        </div>
    </div>
</ng-template>
