import { ChangeDetectionStrategy, Component, computed, inject, input, output, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Tick, TooltipItem } from 'chart.js';
import { isNumber, sum, sumBy } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { PlatformKey } from '@malou-io/package-utils';

import { ChartDataArray, malouChartColorBluePurple, malouChartColorPurple } from ':shared/helpers';
import { LARGE_TOOLTIP_TAB, SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

type BarChartType = Extract<ChartType, 'bar'>;

export type ReviewCountDataTotemsDetails = Record<string, number>;
export type ReviewCountData = {
    star: string;
    starValue: number | undefined;
    count: Partial<Record<PlatformKey, number>> & { total: number };
}[];
export type ReviewsCountData = { name: string; data: ReviewCountData }[];

@Component({
    selector: 'app-statistics-totems-estimated-review-count-chart',
    templateUrl: './totems-estimated-review-count-chart.component.html',
    styleUrls: ['./totems-estimated-review-count-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotemsEstimatedReviewCountChartComponent {
    reviewsCountData = input.required<ReviewsCountData>();
    hiddenDatasetIndexes = input<number[]>([]);
    hiddenDatasetIndexesChange = output<number[]>();

    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly STAR_CLICKED_BY_TRANSLATION = {
        [this._translateService.instant('statistics.totems.unknown_star')]: -1,
        [this._translateService.instant('statistics.totems.one_star')]: 1,
        [this._translateService.instant('statistics.totems.two_stars')]: 2,
        [this._translateService.instant('statistics.totems.three_stars')]: 3,
        [this._translateService.instant('statistics.totems.four_stars')]: 4,
        [this._translateService.instant('statistics.totems.five_stars')]: 5,
    };

    readonly chartDataSets: Signal<(ChartDataset<BarChartType, ChartDataArray> & { metadata: ReviewCountDataTotemsDetails[] })[]> =
        computed(() => {
            let dataset = this._computeChartData(this.reviewsCountData(), this.chartLabels());
            if (this.hiddenDatasetIndexes.length) {
                dataset = dataset.filter((_, index) => !this.hiddenDatasetIndexes().includes(index));
            }
            return dataset;
        });
    readonly chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions());
    readonly chartLabels: Signal<string[]> = computed(() =>
        this._computeChartLabels(this.STAR_CLICKED_BY_TRANSLATION, this.reviewsCountData())
    );

    private _hiddenDatasetIndexes: number[] = [];

    private _computeChartLabels(labels: Record<string, number | undefined>, reviewCountData: ReviewsCountData): string[] {
        let filteredLabels = Object.keys(labels);
        Object.entries(labels).forEach(([starName, starValue]) => {
            const totalForAllDatasets = sum(
                reviewCountData?.map((reviewType) => reviewType.data?.find(({ starValue: star }) => starValue === star)?.count?.total ?? 0)
            );
            if (!totalForAllDatasets) {
                filteredLabels = filteredLabels.filter((label) => label !== starName);
            }
        });
        return filteredLabels;
    }

    private _computeChartData(
        reviewCountData: ReviewsCountData,
        labels: string[]
    ): (ChartDataset<BarChartType, ChartDataArray> & { metadata: ReviewCountDataTotemsDetails[] })[] {
        return reviewCountData
            ?.filter(({ data }) => sumBy(data, 'count.total') > 0)
            ?.map(({ name, data }) => ({
                label: name,
                borderColor: this._getChartColor(name),
                backgroundColor: this._getChartColor(name),
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: data?.filter(({ star }) => labels.includes(star))?.map(({ count }) => count.total ?? null),
                metadata: data?.filter(({ star }) => labels.includes(star))?.map(({ count }) => count),
            }));
    }

    private _getChartColor(name: string): string {
        return name === this._translateService.instant('statistics.totems.wheel_of_fortune')
            ? malouChartColorPurple
            : malouChartColorBluePurple;
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    filter: (tooltipItem: TooltipItem<any>): boolean => tooltipItem.formattedValue !== '0',
                    itemSort: (a: TooltipItem<any>, b: TooltipItem<any>): number => b.datasetIndex - a.datasetIndex,
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems),
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                        afterLabel: (tooltipItem: TooltipItem<any>) => this._computeTooltipAfterLabel(tooltipItem),
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            this._hiddenDatasetIndexes.push(index);
                            ci.hide(index);
                            legendItem.hidden = true;
                        } else {
                            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
                            ci.show(index);
                            legendItem.hidden = false;
                        }
                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
                    },
                },
            },
            scales: {
                xAxis: {
                    stacked: true,
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (_, index: number, _ticks: Tick[]): string => this.chartLabels()[index],
                    },
                },
                yAxis: {
                    stacked: true,
                    axis: 'y',
                    type: 'linear',
                    offset: false,
                    ticks: {
                        stepSize: 1,
                    },
                },
            },
        };
    }

    private _computeTooltipTitle(items: TooltipItem<any>[]): string {
        return items[0].label ?? '';
    }

    private _computeTooltipLabel(item: TooltipItem<any>): string {
        return `${SMALL_TOOLTIP_TAB}${item.dataset.label ?? ''}`;
    }

    private _computeTooltipAfterLabel(item: TooltipItem<any>): string[] {
        const details = item.dataset.metadata?.[item.dataIndex];
        if (!details || !Object.keys(details).length) {
            return [];
        }
        return Object.entries(details)
            .filter(([key, _]) => key !== 'total')
            .map(([key, value]) => `${LARGE_TOOLTIP_TAB}${this._enumTranslatePipe.transform(key, 'platform_key')} : ${value}`);
    }
}
