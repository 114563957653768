import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ToastService } from ':core/services/toast.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatButtonModule, TranslateModule, IllustrationPathResolverPipe],
})
export class ConfirmModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    illustration = Illustration;

    constructor(
        private readonly _router: Router,
        private readonly _dialogRef: MatDialogRef<ConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            password: string;
        },
        public readonly _clipboard: Clipboard,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {}

    goToLogin(): void {
        this._router.navigate(['./auth/login']);
        this.close();
    }

    close(): void {
        this._dialogRef.close();
    }

    copy(password: string): void {
        this._clipboard.copy(password);
        this._toastService.openSuccessToast(this._translateService.instant('login.confirm_account.password_copied'));
    }
}
