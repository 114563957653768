import { Clipboard } from '@angular/cdk/clipboard';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { ChangeLockData, ComparisonRowComponent } from ':shared/components/comparison-row/comparison-row.component';
import { PlatformComparisonAction } from ':shared/interfaces/value-with-difference.interface';
import { Comparison, PlatformComparisonWithStatus } from ':shared/models';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

export type SinglePlatformLockedFields = Record<
    string,
    {
        lock: boolean;
        changed: boolean;
    }
>;
@Component({
    selector: 'app-platform-comparison',
    templateUrl: './platform-comparison.component.html',
    styleUrls: ['./platform-comparison.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatTooltipModule,
        MatExpansionModule,
        MatIconModule,
        TranslateModule,
        NgClass,
        NgTemplateOutlet,
        CloseWithoutSavingModalComponent,
        ComparisonRowComponent,
        PlatformLogoPathResolverPipe,
    ],
})
export class PlatformComparisonComponent implements OnInit, AfterViewInit {
    @Input() platformComparison: PlatformComparisonWithStatus;
    @Input() action: PlatformComparisonAction;
    @Output() changeLock = new EventEmitter<SinglePlatformLockedFields>();

    readonly PlatformComparisonAction = PlatformComparisonAction;

    disableAnimation = true;
    platformsLockedFields: SinglePlatformLockedFields = {};

    constructor(
        private readonly _clipboard: Clipboard,
        private readonly _toastService: ToastService,
        private readonly _translate: TranslateService,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    ngOnInit(): void {
        this.platformsLockedFields = this._initPlatformLockedFields();
        if (this.action === PlatformComparisonAction.COPY) {
            this._hideLockedFields();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => (this.disableAnimation = false));
    }

    handleChangeLock(event: ChangeLockData): void {
        const { lock, fieldKey } = event;
        this.platformsLockedFields[fieldKey] = {
            lock,
            changed: !this.platformsLockedFields[fieldKey]?.changed,
        };
        this.changeLock.emit(this.platformsLockedFields);
    }

    onCopy(comparison: Comparison, options?: { valueToCopy: any }): void {
        const valueToCopy = options?.valueToCopy ?? comparison.reference;
        this._clipboard.copy(valueToCopy);
        this._toastService.openSuccessToast(this._translate.instant('common.copied_to_the_clipboard'));
    }

    private _initPlatformLockedFields(): SinglePlatformLockedFields {
        if (!this.platformComparison.platformData?.lockedFields?.length) {
            return {};
        }
        return this.platformComparison.platformData.lockedFields.reduce((acc, lockField) => {
            acc[lockField] = {
                lock: true,
                changed: false,
            };
            return acc;
        }, {});
    }

    private _hideLockedFields(): void {
        const lockedFields = this.platformComparison.platformData?.lockedFields;
        this.platformComparison.data =
            this.platformComparison.data?.filter((comparison) => !lockedFields?.includes(comparison.key)) || null;
    }
}
