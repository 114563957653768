import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { failedAccessStatus, PlatformCategory, PlatformGroup } from '@malou-io/package-utils';

import { SearchComponent } from ':shared/components/search/search.component';
import { MappedPlatforms, PlatformState } from ':shared/models';

export enum ReconnectFilterOption {
    ALL = 'all',
    FAILED = 'failed',
}

@Component({
    selector: 'app-platforms-filters',
    templateUrl: './platforms-filters.component.html',
    styleUrls: ['./platforms-filters.component.scss'],
    standalone: true,
    imports: [MatButtonToggleModule, TranslateModule, SearchComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformsFiltersComponent {
    readonly ReconnectFilterOption = ReconnectFilterOption;

    platforms = input.required<MappedPlatforms<PlatformCategory | PlatformGroup>>();
    searchChange = output<string>();
    reconnectFilterChange = output<ReconnectFilterOption>();

    readonly connectedPlatformCount = computed(() => this._countConnectedPlatforms(this.platforms()));
    readonly allPlatformCount = computed(() => this._countPlatforms(this.platforms()));
    readonly shouldReconnectFilterBeVisible = computed(() => this._countPlatformsInError(this.platforms()) > 0);
    readonly text = signal('');

    reconnectFilter = ReconnectFilterOption.ALL;

    onSearchChange(search: string): void {
        this.searchChange.emit(search);
    }

    changeReconnectFilterOption(option: ReconnectFilterOption): void {
        if (this.reconnectFilter !== option) {
            this.reconnectFilterChange.emit(option);
        }
        this.reconnectFilter = option;
    }

    private _countConnectedPlatforms(platformsCategory: MappedPlatforms<PlatformCategory | PlatformGroup>): number {
        return Object.values(platformsCategory).reduce(
            (acc, platforms) => acc + platforms.filter((platform) => platform.state === PlatformState.CONNECTED).length,
            0
        );
    }

    private _countPlatforms(platformsCategory: MappedPlatforms<PlatformCategory | PlatformGroup>): number {
        return Object.values(platformsCategory).reduce((acc, platforms) => acc + platforms.length, 0);
    }

    private _countPlatformsInError(platformsCategory: MappedPlatforms<PlatformCategory | PlatformGroup>): number {
        return Object.values(platformsCategory).reduce((acc, platforms) => {
            const failedPlatforms = platforms.filter((platform) => {
                const isConnectedPlatform = platform.state && [PlatformState.CONNECTED, PlatformState.FOUND].includes(platform.state);
                const hasFailedAccessStatus = platform.accessStatus && failedAccessStatus.includes(platform.accessStatus);
                return isConnectedPlatform && hasFailedAccessStatus;
            });
            return acc + failedPlatforms.length;
        }, 0);
    }
}
