import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';

import { APP_DEFAULT_LANGUAGE } from '@malou-io/package-utils';

import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Restaurant } from ':shared/models/';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { RestaurantBusinessHoursComponent } from '../restaurant-business-hours/restaurant-business-hours.component';

@Component({
    selector: 'app-restaurant-other-hours',
    templateUrl: './restaurant-other-hours.component.html',
    styleUrls: ['./restaurant-other-hours.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatExpansionModule,
        TranslateModule,
        RestaurantBusinessHoursComponent,
        ApplySelfPurePipe,
        IllustrationPathResolverPipe,
    ],
})
export class RestaurantOtherHoursComponent {
    @Input() restaurant: Restaurant;
    @Output() openModal = new EventEmitter<void>();

    lang = localStorage.getItem(LocalStorageKey.LANG) || APP_DEFAULT_LANGUAGE;

    openHoursModal(): void {
        this.openModal.emit();
    }
}
