import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Sort } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { compact, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { catchError, combineLatest, debounceTime, EMPTY, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import {
    AggregationTimeScale,
    InsightsChart,
    isNotNil,
    MalouMetric,
    PlatformKey,
    PostPublicationStatus,
    PostType,
} from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { InsightsService } from ':modules/statistics/insights.service';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import { TopPostCardSkeletonComponent } from ':shared/components/top-post-card-skeleton/top-post-card-skeleton.component';
import { TopPostCardComponent, TopPostCardInputData } from ':shared/components/top-post-card/top-post-card.component';
import { formatDate, getClosestValueFromDate, isDateSetOrGenericPeriod } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { DatesAndPeriod, InsightsByPlatform, Post, PostsWithInsightsByPlatforms, PostWithInsights, Restaurant } from ':shared/models';
import { CreateArrayPipe } from ':shared/pipes/create-array.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import * as StatisticsActions from '../../store/statistics.actions';
import * as StatisticsSelectors from '../../store/statistics.selectors';
import { PostsComponent } from './posts/posts.component';
import { StoriesComponent } from './stories/stories.component';

enum SocialNetworkInsightsTabs {
    POSTS,
    REELS,
    STORIES,
}

export interface FollowersByDayObject {
    [key: string]: number;
}

@Component({
    selector: 'app-posts-insights-table',
    templateUrl: './posts-insights-table.component.html',
    styleUrls: ['./posts-insights-table.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        PostsComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        LazyLoadImageModule,
        MatTabsModule,
        StoriesComponent,
        TopPostCardComponent,
        TopPostCardSkeletonComponent,
        CreateArrayPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostsInsightsTableComponent implements OnInit {
    @Input() shouldDisplayPostInsightsTable = true;
    @Input() shouldDisplayStoryInsightsTable = true;
    @Input() shouldDisplayReelInsightsTable = true;
    @Input() shouldHidePublicationsTablesIfNoData = false; // by default there is an error message
    @Input() shouldDetailPostTables = false;
    @Input() shouldLazyLoadMedia = true;
    @Input() postsTableSortOptions: Sort | undefined = undefined;
    @Input() reelsTableSortOptions: Sort | undefined = undefined;
    @Input() storiesTableSortOptions: Sort | undefined = undefined;
    @Output() tableSortOptionsChange = new EventEmitter<{ chart: InsightsChart; value: Sort }>();
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);

    readonly httpError: WritableSignal<any | null> = signal(null);
    readonly isLoading = signal(true);
    readonly areAllPlatformsInError = signal(false);
    readonly platformsErrorTooltip: WritableSignal<string | null> = signal(null);
    readonly storiesHasNoData = signal(false);

    readonly reels = computed(() => this._postsAndReels().filter((elem) => elem.postType === PostType.REEL));
    readonly posts = computed(() => this._postsAndReels().filter((elem) => elem.postType !== PostType.REEL));

    readonly top3Posts: Signal<PostWithInsights[]> = computed(() => this._getTop3Posts(this.posts()));
    readonly topPostCardInputsFromPosts: Signal<TopPostCardInputData[]> = computed(() => this.top3Posts().map(this._mapToTopPostCardInput));
    readonly top3Reels: Signal<PostWithInsights[]> = computed(() => this._getTop3Posts(this.reels()));
    readonly topPostCardInputsFromReels: Signal<TopPostCardInputData[]> = computed(() => this.top3Reels().map(this._mapToTopPostCardInput));

    readonly storiesCount: Signal<number> = computed(() => this._allPosts().filter((post) => post.isStory)?.length);
    readonly reelsCount: Signal<number> = computed(() => this.reels().length);
    readonly postsCount: Signal<number> = computed(() => this.posts().length);

    selectedTab: SocialNetworkInsightsTabs = SocialNetworkInsightsTabs.POSTS;
    readonly SocialNetworkInsightsTabs = SocialNetworkInsightsTabs;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly trackByUrlFn = TrackByFunctionFactory.get('url');

    private readonly _destroyRef = inject(DestroyRef);
    private _postsAndReels: WritableSignal<PostWithInsights[]> = signal([]);
    private _allPosts: WritableSignal<Post[]> = signal([]);

    constructor(
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _postsService: PostsService,
        private readonly _insightsService: InsightsService,
        private readonly _enumTranslatePipe: EnumTranslatePipe
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this.dates$, this.platformKeys$])
            .pipe(
                filter(
                    ([restaurant, dates, platformKeys]: [Restaurant, DatesAndPeriod, PlatformKey[]]) =>
                        !!restaurant && isDateSetOrGenericPeriod(dates) && platformKeys.length > 0
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([restaurant, dates, platformKeys]: [Restaurant, DatesAndPeriod, PlatformKey[]]) => {
                    const restaurantId = restaurant._id;
                    const { startDate, endDate } = dates;
                    const allPosts$ =
                        startDate && endDate
                            ? this._postsService
                                  .getPostsBetweenDates$(restaurantId, formatDate(startDate, false), formatDate(endDate, false))
                                  .pipe(map((res) => res?.data.posts))
                            : of(null);

                    return forkJoin([
                        allPosts$,
                        this._postsService.getPostsWithInsights(restaurantId, platformKeys, startDate, endDate).pipe(
                            map((res) => res.data),
                            catchError((error) => {
                                this.httpError.set(error);
                                this.isLoading.set(false);
                                return EMPTY;
                            })
                        ),
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurantId],
                                platformsKeys: platformKeys,
                                startDate,
                                endDate,
                                metrics: [MalouMetric.FOLLOWERS],
                                aggregators: [AggregationTimeScale.BY_DAY],
                            })
                            .pipe(
                                map((res) => res.data[restaurantId]),
                                catchError((error) => {
                                    this.httpError.set(error);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        of(platformKeys),
                    ]);
                }),
                filter(([_allPosts, dataOrNull]) => isNotNil(dataOrNull)),
                switchMap(
                    ([allPosts, postsWithInsightsByPlatform, currentFollowersInsights, platforms]: [
                        Post[],
                        PostsWithInsightsByPlatforms,
                        InsightsByPlatform,
                        PlatformKey[],
                    ]) => {
                        this._store.dispatch(StatisticsActions.editPostsWithInsightsRawData({ data: postsWithInsightsByPlatform }));
                        this._store.dispatch(StatisticsActions.editFollowersRawData({ data: currentFollowersInsights }));
                        this._allPosts.set(allPosts.filter((post) => post.isStory && post.published === PostPublicationStatus.PUBLISHED));
                        const platformsInError: PlatformKey[] = this._getPlatformsInError(
                            postsWithInsightsByPlatform,
                            currentFollowersInsights,
                            platforms
                        );
                        platformsInError.forEach((platform) => {
                            delete currentFollowersInsights[platform];
                        });

                        if (platforms.length === platformsInError.length) {
                            this.areAllPlatformsInError.set(true);
                            this.isLoading.set(false);
                            this.platformsErrorTooltip.set(this._getPlatformsErrorTooltip(platformsInError));
                            return EMPTY;
                        }

                        if (platformsInError.length) {
                            this.platformsErrorTooltip.set(this._getPlatformsErrorTooltip(platformsInError));
                        }

                        return of([postsWithInsightsByPlatform, currentFollowersInsights]);
                    }
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([postsWithInsightsByPlatform, currentFollowersInsights]: [PostsWithInsightsByPlatforms, InsightsByPlatform]) => {
                const followersCountByPlatformAndDay: Partial<Record<PlatformKey, FollowersByDayObject>> =
                    this._getFollowerCountByPlatform(currentFollowersInsights);

                this._postsAndReels.set(this._getPostsInsights(postsWithInsightsByPlatform, followersCountByPlatformAndDay));
                this.isLoading.set(false);
            });
    }

    handleTabChange(tabIndex: number): void {
        this.selectedTab = tabIndex;
    }

    private _mapToTopPostCardInput(post: PostWithInsights): TopPostCardInputData {
        return {
            postType: post.postType,
            platformKey: post.key,
            url: post.url,
            thumbnailUrl: post.thumbnail,
            createdAt: post.createdAt,
            likes: post.likes,
            comments: post.comments,
            shares: post.shares,
            saves: post.saved,
            impressions: post.impressions || post.plays,
            engagementRate: post.engagementRate ?? 0,
        };
    }

    private _getTop3Posts(posts: PostWithInsights[]): PostWithInsights[] {
        return posts
            .sort((a, b) => {
                if (a.engagementRate === null && b.engagementRate === null) {
                    return 0;
                }
                if (a.engagementRate === null) {
                    return 1;
                }
                if (b.engagementRate === null) {
                    return -1;
                }
                return b.engagementRate - a.engagementRate;
            })
            .slice(0, 3);
    }

    private _getPostsInsights(
        postsWithInsightsByPlatforms: PostsWithInsightsByPlatforms,
        followersCountByPlatformAndDay: Partial<Record<PlatformKey, FollowersByDayObject>>
    ): PostWithInsights[] {
        return postsWithInsightsByPlatforms
            .map((postsWithInsightsByPlatform) =>
                Object.entries(postsWithInsightsByPlatform)
                    .map(([key, value]) =>
                        (value.data ?? []).map(
                            (post) =>
                                new PostWithInsights({
                                    ...post,
                                    key,
                                    nbFollowers: this._getFollowerCountByDate(
                                        new Date(post.createdAt),
                                        followersCountByPlatformAndDay[key]
                                    ),
                                })
                        )
                    )
                    .flat()
            )
            .flat();
    }

    private _getFollowerCountByDate(date: Date, followersByDay: FollowersByDayObject): number | null {
        const formattedTargetDate = DateTime.fromJSDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        const possibleDates = Object.keys(followersByDay).map((d) => new Date(d));
        const closestDate = getClosestValueFromDate(formattedTargetDate, possibleDates);
        return closestDate ? followersByDay[closestDate.toISOString()] : null;
    }

    private _getFollowerCountByPlatform(currentFollowersInsights: InsightsByPlatform): Partial<Record<PlatformKey, FollowersByDayObject>> {
        return Object.entries(currentFollowersInsights).reduce((acc, cur) => {
            const followersByDay = cur[1][AggregationTimeScale.BY_DAY]?.[MalouMetric.FOLLOWERS];
            const followersByDayObject = {};
            followersByDay?.forEach((insight) => (followersByDayObject[insight.date] = insight.value));
            return {
                ...acc,
                [cur[0]]: followersByDayObject,
            };
        }, {});
    }

    private _getPlatformsInError(
        postsWithInsightsByPlatforms: PostsWithInsightsByPlatforms,
        currentFollowersInsights: InsightsByPlatform,
        filteredPlatforms: PlatformKey[]
    ): PlatformKey[] {
        const postsWithInsightsPlatformsError = postsWithInsightsByPlatforms
            .map((postsWithInsightsByPlatform) =>
                Object.entries(postsWithInsightsByPlatform).map(([key, value]) =>
                    value.error && filteredPlatforms.includes(key as PlatformKey) ? (key as PlatformKey) : null
                )
            )
            .flat()
            .filter(Boolean);

        const currentFollowersPlatformsError = filteredPlatforms.filter((platform) => currentFollowersInsights[platform]?.error);

        return compact(uniq([...postsWithInsightsPlatformsError, ...currentFollowersPlatformsError]));
    }

    private _getPlatformsErrorTooltip(platformsInError: PlatformKey[]): string {
        return platformsInError.map((platform) => this._enumTranslatePipe.transform(platform, 'platform_key')).join(', ');
    }

    private _reset(): void {
        this.httpError.set(null);
        this.isLoading.set(true);
        this.areAllPlatformsInError.set(false);
        this.platformsErrorTooltip.set(null);
    }
}
