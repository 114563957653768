<div class="malou-dialog">
    <div class="malou-dialog__header">
        <p class="text-malou-color-text-1">
            {{ 'clients.import_modal.import_clients' | translate }}
        </p>
        <button class="malou-btn-icon" mat-icon-button (click)="cancel()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body">
        <mat-stepper
            class="custom-stepper-header w-full pb-6 md:h-full"
            labelPosition="bottom"
            [@.disabled]="true"
            [linear]="true"
            #stepper="matHorizontalStepper">
            <mat-step class="md:flex md:flex-1 md:flex-col">
                <p class="malou-text-12--medium mb-9 text-malou-color-text-2">
                    {{ 'clients.import_modal.choose_format' | translate }}
                </p>
                <div class="flex justify-between gap-4 md:flex-1 md:flex-col">
                    @for (source of DOWNLOAD_SOURCES; track trackByKeyFn($index, source)) {
                        <div
                            class="group flex h-24 w-44 cursor-pointer items-center justify-center rounded-[10px] border border-malou-color-border-primary transition-all duration-300 hover:bg-malou-color-background-dark md:w-full md:flex-1"
                            data-cy="fileFormatDiv"
                            matRipple
                            [ngClass]="{
                                'selected-source': selectedSource === source.key,
                            }"
                            (click)="selectedSource = source.key; next()">
                            @if (source.key !== 'malou') {
                                <img class="h-12" [lazyLoad]="source.key | platformLogoPathResolver" [alt]="source.key" />
                            }
                            @if (source.key === 'malou') {
                                <span class="malou-text-12--semibold">{{ source.displayName }}</span>
                            }
                        </div>
                    }
                </div>
            </mat-step>
            @if (selectedSource === 'malou') {
                <mat-step class="md:flex md:flex-1 md:flex-col">
                    <div>
                        <p class="texte-12--medium text-malou-color-text-2">
                            {{ 'clients.import_modal.should_download_file' | translate }}
                            <br />
                            {{ 'clients.import_modal.should_download_file_warning' | translate }}
                        </p>
                        <div class="my-8 flex h-20 gap-4 md:h-40 md:flex-1 md:flex-col">
                            <a class="flex flex-1" href="/assets/Fichier_squelette_Malou.xlsx" download="Fichier_squelette_Malou.xlsx">
                                <div
                                    class="group flex flex-1 cursor-pointer flex-col items-center justify-center rounded-[10px] border border-malou-color-border-primary hover:bg-malou-color-background-dark"
                                    matRipple>
                                    <p class="malou-text-12--semibold">
                                        {{ 'clients.import_modal.dont_have_model' | translate }}
                                    </p>
                                    <span class="malou-text-12--regular mt-1.5 italic">{{ 'common.download_file' | translate }}</span>
                                </div>
                            </a>
                            <div
                                class="group flex flex-1 cursor-pointer flex-col items-center justify-center rounded-[10px] border border-malou-color-border-primary hover:bg-malou-color-background-dark"
                                matRipple
                                (click)="next()">
                                <p class="malou-text-12--semibold">
                                    {{ 'clients.import_modal.have_model' | translate }}
                                </p>
                                <span class="malou-text-12--regular mt-1.5 italic">{{ 'common.import_file' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <button class="malou-btn-raised--secondary !h-11" mat-raised-button matStepperPrevious>
                            {{ 'clients.import_modal.previous' | translate }}
                        </button>
                    </div>
                </mat-step>
            }
            <mat-step>
                <input
                    class="hidden-file-input"
                    type="file"
                    accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    (change)="onFileChange($event)"
                    #fileInput />
                @if (selectedSource && !isValidFile) {
                    <div>
                        <p class="malou-text-12--regular mb-4 text-malou-color-text-2">
                            {{ 'clients.import_modal.step_2_title' | translate: { clientFileType: selectedSource | platformName } }}
                        </p>
                        <div
                            class="flex h-64 w-full flex-col items-center justify-center rounded-[10px] border border-dashed border-malou-color-border-primary"
                            id="dragarea"
                            ondragover="event.stopPropagation(); event.preventDefault()"
                            appDnd
                            [ngClass]="{
                                '!border-malou-color-border-secondary opacity-50': isDragOver,
                            }"
                            (fileDropped)="onFileChange($event)"
                            (dragenter)="onDragEnter()"
                            (dragleave)="onDragLeave($event)">
                            <div
                                class="relative flex h-[90%] w-[95%] items-center justify-center rounded-[10px] border border-malou-color-border-primary bg-malou-color-background-light"
                                [ngClass]="{
                                    '!border-malou-color-border-secondary': isDragOver,
                                }">
                                <div class="flex flex-col items-center gap-4">
                                    <div>
                                        <img class="h-28 opacity-50" [lazyLoad]="'Taster' | illustrationPathResolver" />
                                    </div>
                                    <p class="malou-text-12--semibold">
                                        {{ 'clients.import_modal.drop_file' | translate }}
                                    </p>
                                    <p class="malou-text-12--semibold cursor-pointer text-malou-color-primary" (click)="fileInput.click()">
                                        {{ 'clients.import_modal.import_client_file' | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 flex justify-end gap-4">
                            <button class="malou-btn-raised--secondary !h-11" mat-raised-button matStepperPrevious>
                                {{ 'clients.import_modal.previous' | translate }}
                            </button>
                            <button class="malou-btn-raised--primary !h-11" mat-raised-button disabled="true">
                                {{ 'common.import' | translate }}
                            </button>
                        </div>
                    </div>
                }
                @if (selectedSource && isValidFile) {
                    <div class="flex flex-col">
                        <div class="max-h-[450px] overflow-auto p-2">
                            <div class="flex gap-2">
                                <p class="malou-text-12--medium text-malou-color-text-2">
                                    {{ 'clients.import_modal.successfull_import' | translate: { fileName: uploadedFile?.name } }}
                                </p>
                                <p class="malou-text-12--semibold cursor-pointer text-malou-color-primary" (click)="clearFile()">
                                    {{ 'common.delete' | translate }}
                                </p>
                            </div>
                            @if (nbDuplicatedClients) {
                                <div
                                    class="my-2 flex rounded-[10px] border border-malou-color-border-primary bg-malou-color-background-light p-4">
                                    <div class="flex-1">
                                        <div class="inline-block">
                                            <div
                                                class="malou-status--waiting"
                                                [matTooltip]="'clients.import_modal.client_exist' | translate">
                                                <span class="mt-0.5 text-xs">!</span>
                                            </div>
                                        </div>
                                        <span class="malou-text-12--semibold">
                                            {{ 'clients.import_modal.client_exist_warning' | translate: { nb: nbDuplicatedClients } }}
                                        </span>
                                        <span class="malou-text-12--regular">
                                            {{ 'clients.import_modal.client_exist_warning_question' | translate }}
                                        </span>
                                    </div>
                                    <div class="flex basis-1/6 items-center">
                                        <app-slide-toggle
                                            [switchWidth]="70"
                                            [checked]="isCheckedDuplicatedClients"
                                            [activeLabel]="'common.yes' | translate"
                                            [inactiveLabel]="'common.no' | translate"
                                            (onToggle)="onToggleDuplicatedClients()"></app-slide-toggle>
                                    </div>
                                </div>
                            }
                            <div class="my-2">
                                <div class="rounded-[10px] bg-malou-color-background-light p-4">
                                    <span class="malou-text-12--bold text-malou-color-text-1">{{ fileLength }}</span>
                                    <span class="malou-text-12--bold text-malou-color-text-1">
                                        {{
                                            fileLength === 1
                                                ? ('clients.import_modal.clients_length_singular' | translate)
                                                : ('clients.import_modal.clients_length' | translate)
                                        }}</span
                                    >
                                    <mat-table class="malou-mat-table mt-5" [dataSource]="dataSource" #table="matTable">
                                        <ng-container matColumnDef="firstname">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ 'clients.firstname' | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let c; table: table" [matTooltip]="c.firstName ?? ''">
                                                {{ c.firstName | shortText: 20 }}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="lastname">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ 'clients.lastname' | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let c; table: table">
                                                <div class="flex items-center gap-2">
                                                    @if (c.isDuplicated) {
                                                        <div
                                                            class="malou-status--waiting"
                                                            [matTooltip]="'clients.import_modal.client_exist' | translate">
                                                            <span class="mt-0.5 text-xs">!</span>
                                                        </div>
                                                    }
                                                    <span>
                                                        {{ c.lastName | shortText: 20 }}
                                                    </span>
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="email">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ 'clients.email' | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let c; table: table" [matTooltip]="c.email ?? ''">
                                                {{ c.email | shortText: 20 }}
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS" mat-header-row></mat-header-row>
                                        <mat-row *matRowDef="let c; columns: DISPLAYED_COLUMNS; table: table"></mat-row>
                                    </mat-table>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 flex">
                            <div class="flex-1">
                                @if (isFileUploaded && isValidFile) {
                                    <mat-checkbox
                                        class="h-9"
                                        color="primary"
                                        [checked]="allClientsAcceptContact"
                                        (change)="toogleAcceptContact($event)">
                                        <span class="malou-text-10--regular !leading-none text-malou-color-text-2">{{
                                            'clients.import_modal.clients_accept_contact' | translate
                                        }}</span>
                                    </mat-checkbox>
                                }
                            </div>
                            <div class="flex flex-1 justify-end gap-4">
                                <button
                                    class="malou-btn-raised--secondary !h-11"
                                    mat-raised-button
                                    matStepperPrevious
                                    (click)="clearFile()">
                                    {{ 'clients.import_modal.previous' | translate }}
                                </button>
                                <button
                                    class="malou-btn-raised--primary !h-11"
                                    mat-raised-button
                                    matStepperNext
                                    [disabled]="!isFileUploaded || !isValidFile || !allClientsAcceptContact"
                                    (click)="upload()">
                                    {{ 'clients.import_modal.confirm' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </mat-step>
        </mat-stepper>
    </div>
</div>
