<div class="h-full overflow-y-auto">
    <mat-table
        class="malou-mat-table"
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        [matSortActive]="DEFAULT_SORT.active"
        [matSortDirection]="DEFAULT_SORT.direction"
        (matSortChange)="sortChange.emit($event)">
        <ng-container [matColumnDef]="TableField.RESTAURANT_NAME">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'reviews_ratings_average_table.restaurant_name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.RESTAURANT_NAME] }}
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.AVERAGE_RATING">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'reviews_ratings_average_table.average_rating' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                <div class="flex gap-x-2">
                    <div>
                        {{ data[TableField.AVERAGE_RATING] | shortNumber }}
                    </div>
                    <div>
                        <app-number-evolution
                            size="small"
                            [value]="data.evolution"
                            [displayedValue]="data.evolution | shortNumber: { shouldDisplayMinusSign: false }"></app-number-evolution>
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: displayedColumns"></mat-row>
    </mat-table>
</div>
