import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ReviewsRating } from ':modules/reviews/reviews.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

type Data = ReviewsRating[];

@Injectable({ providedIn: 'root' })
export class ReviewsRatingsTotalCsvInsightsService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _enumTranslatePipe: EnumTranslatePipe
    ) {
        super();
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data.length;
    }

    protected override _getData$(): Observable<Data> {
        return this._store.select(StatisticsSelectors.selectReviewsRatingsTotalData).pipe(take(1));
    }

    protected override _getCsvDataRows(data: Data): CsvAsStringArrays {
        return data
            .map((d) => {
                const rating = d.rating.toString();
                return d.platforms.map((platform) => {
                    const platformKeyTranslated = this._enumTranslatePipe.transform(platform.key, 'platform_key');
                    const reviewCount = platform.nbReviews.toString();
                    return [platformKeyTranslated, reviewCount, rating];
                });
            })
            .flat();
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return ['Platform', 'Review count', 'Rating'];
    }
}
