import { DecimalPipe, NgClass } from '@angular/common';
import { Component, computed, DestroyRef, effect, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { chain, cloneDeep, isNumber } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import {
    AggregationTimeScale,
    ApplicationLanguage,
    isNotNil,
    MalouMetric,
    PlatformDefinitions,
    PlatformKey,
} from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { InsightsService } from ':modules/statistics/insights.service';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, InsightsByPlatform, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

interface PlatformWithRatingValue {
    platformKey: string;
    value: number;
}

export interface CurrentAndDiffPlatformRating {
    platform: string;
    current: number;
    diff: number;
}

@Component({
    selector: 'app-statistics-seo-platforms-ratings',
    templateUrl: './platforms-ratings.component.html',
    styleUrls: ['./platforms-ratings.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatIconModule,
        MatTooltipModule,
        NumberEvolutionComponent,
        SkeletonComponent,
        DecimalPipe,
        PlatformLogoPathResolverPipe,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        IncludesPipe,
        StatisticsHttpErrorPipe,
        LazyLoadImageModule,
        ApplyPurePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class PlatformsRatingsComponent implements OnInit, KillSubscriptions {
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;

    dates$: Observable<{ startDate: Date | null; endDate: Date | null }> = this._store
        .select(StatisticsSelector.selectDatesFilter)
        .pipe(map(({ startDate, endDate }) => ({ startDate, endDate })));
    platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    restaurant$: Observable<Restaurant> = this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil));
    showTitle = false;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    ratings: CurrentAndDiffPlatformRating[] = [];
    httpError: any;
    wantsRatingBeforeRestaurantCreationDate = false;
    isLoading = signal(true);
    readonly PlatformKey = PlatformKey;

    readonly currentLang = signal(this._translateService.currentLang);
    readonly localeForNumberTransformation = computed(() =>
        this.currentLang() === ApplicationLanguage.EN ? ApplicationLanguage.EN : ApplicationLanguage.FR
    );

    constructor(
        private readonly _insightsService: InsightsService,
        private readonly _restaurantsService: RestaurantsService,
        public readonly _translateService: TranslateService,
        private readonly _destroyRef: DestroyRef,
        private readonly _store: Store
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        this._translateService.onLangChange.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((res) => {
            this.currentLang.set(res.lang as ApplicationLanguage);
        });

        combineLatest([this.restaurant$, this.dates$, this.platformKeys$])
            .pipe(
                filter(([_restaurant, dates, _platforms]) => !!dates.startDate && !!dates.endDate),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([restaurant, dates, platformKeys]: [Restaurant, DatesAndPeriod, PlatformKey[]]) => {
                    const { startDate, endDate } = dates;
                    this.wantsRatingBeforeRestaurantCreationDate = endDate
                        ? new Date(endDate).getTime() < new Date(restaurant.createdAt).getTime()
                        : true;
                    if (this.wantsRatingBeforeRestaurantCreationDate) {
                        this.isLoading.set(false);
                        return EMPTY;
                    }

                    const allPlatformsWithRating: string[] = PlatformDefinitions.getPlatformKeysWithRating();
                    const connectedPlatformsWithRating: PlatformKey[] = platformKeys.filter((platform: string) =>
                        allPlatformsWithRating.includes(platform)
                    );
                    return forkJoin([
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurant._id],
                                startDate,
                                endDate,
                                platformsKeys: connectedPlatformsWithRating,
                                metrics: [MalouMetric.PLATFORM_RATING],
                                aggregators: [AggregationTimeScale.BY_DAY],
                            })
                            .pipe(
                                map((res) => res?.data[restaurant._id]),
                                tap((res) =>
                                    this._store.dispatch(
                                        StatisticsActions.editPlatformsRatingsData({
                                            data: cloneDeep(res),
                                        })
                                    )
                                ),
                                map((res) => this._getLatestRatingForEachPlatformFromPeriod(res)),
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurant._id],
                                startDate,
                                endDate,
                                platformsKeys: connectedPlatformsWithRating,
                                metrics: [MalouMetric.PLATFORM_RATING],
                                aggregators: [AggregationTimeScale.BY_DAY],
                                previousPeriod: true,
                            })
                            .pipe(
                                map((res) => res?.data[restaurant._id]),
                                map((res) => this._getLatestRatingForEachPlatformFromPeriod(res)),
                                catchError((error) => {
                                    if (!error.error?.message?.match(/Time range too long. Maximum start time is 18 months ago/)) {
                                        this.httpError = error;
                                        this.hasDataChange.emit(false);
                                        this.isLoading.set(false);
                                        return EMPTY;
                                    }
                                    return of([]);
                                })
                            ),
                    ]);
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([lastPlatformRatingsFromCurrentPeriod, lastPlatformRatingsFromPreviousPeriod]: [
                    PlatformWithRatingValue[],
                    PlatformWithRatingValue[],
                ]) => {
                    this.ratings = this._getCurrentAndDiffPlatformRatings(
                        lastPlatformRatingsFromCurrentPeriod,
                        lastPlatformRatingsFromPreviousPeriod
                    );
                    this.isLoading.set(false);
                },
                error: (err) => {
                    console.error('err :>> ', err);
                },
            });
    }

    areSomeRatingsNotNull(ratings: CurrentAndDiffPlatformRating[]): boolean {
        return !!ratings.length && ratings.some((r) => isNumber(r.current));
    }

    platformHasRatingOutOfTen(platformKey: string): boolean {
        return PlatformDefinitions.hasRatingOutOfTen(platformKey);
    }

    private _getLatestRatingForEachPlatformFromPeriod(platformsInsights?: InsightsByPlatform): PlatformWithRatingValue[] {
        if (!platformsInsights) {
            return [];
        }
        return Object.keys(platformsInsights)?.map((platformKey) => {
            const platformInsightsByDayRatings =
                platformsInsights?.[platformKey]?.[AggregationTimeScale.BY_DAY]?.[MalouMetric.PLATFORM_RATING];
            if (!platformInsightsByDayRatings) {
                return { platformKey, value: null };
            }
            const platformInsightsByDayRatingsCleaned = platformInsightsByDayRatings
                .sort((a, b) => new Date(b.date)?.getTime() - new Date(a.date)?.getTime())
                .filter((rating) => !!rating.value);
            if (!platformInsightsByDayRatingsCleaned[0]?.value) {
                return { platformKey, value: null };
            }
            return {
                platformKey,
                value: platformInsightsByDayRatingsCleaned[0].value,
            };
        });
    }

    private _getCurrentAndDiffPlatformRatings(
        currentPlatformRatings: PlatformWithRatingValue[],
        previousPlatformRatings: PlatformWithRatingValue[]
    ): CurrentAndDiffPlatformRating[] {
        const data = [currentPlatformRatings, previousPlatformRatings].flat();

        const currentAndDiffPlatformRatings = chain(data)
            .groupBy('platformKey')
            .map((rest, platformKey) => {
                const previousValue = rest[1]?.value;
                const currentValue = rest[0]?.value ?? previousValue;
                return {
                    platform: platformKey,
                    current: currentValue,
                    diff: currentValue - previousValue,
                };
            })
            .value();
        return currentAndDiffPlatformRatings.filter((cDP) => cDP.current >= 0);
    }

    private _reset(): void {
        this.httpError = null;
        this.isLoading.set(true);
        this.wantsRatingBeforeRestaurantCreationDate = false;
    }
}
