import { PlatformKey } from '@malou-io/package-utils';

import { MalouPeriod, Restaurant } from './';

export interface ReviewsFilters {
    platforms?: PlatformKey[];
    startDate?: Date | null;
    endDate?: Date | null;
    period?: MalouPeriod;
    text?: string;
    /** Zero is a special value and matches reviews without rating. */
    ratings?: (0 | 1 | 2 | 3 | 4 | 5)[];
    answered?: boolean;
    notAnswered?: boolean;
    pending?: boolean;
    notAnswerable?: boolean;
    archived?: boolean;
    unarchived?: boolean;
    showPrivate?: boolean;
    withText?: boolean;
    withoutText?: boolean;
    restaurants?: Restaurant[];
    aggregatedViewRestaurants?: Restaurant[];
}

export enum StatusReviewsFilter {
    ANSWERED = 'answered',
    NOT_ANSWERED = 'notAnswered',
    PENDING = 'pending',
    NOT_ANSWERABLE = 'notAnswerable',
}

export enum CommentReviewsFilters {
    WITH_TEXT = 'withText',
    WITHOUT_TEXT = 'withoutText',
}

export enum ArchiveReviewsFilters {
    ARCHIVED = 'archived',
    UNARCHIVED = 'unarchived',
}
