import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ReportsService } from ':core/services/report.service';
import { ToastService } from ':core/services/toast.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { ToastComponent } from ':shared/components-v3/toast/toast.component';
import { SelectRecipientsComponent } from ':shared/components/select-recipients/select-recipients.component';
import { SelectRestaurantsComponent } from ':shared/components/select-restaurants/select-restaurants.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { AsTypePipe } from ':shared/pipes/as.pipe';

import { BaseReportsSettingsComponent } from '../base-reports-settings/base-reports-settings.component';

@Component({
    selector: 'app-reviews-reports-settings',
    templateUrl: './reviews-reports-settings.component.html',
    styleUrls: ['./reviews-reports-settings.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatExpansionModule,
        SlideToggleComponent,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        SelectRestaurantsComponent,
        SelectRecipientsComponent,
        AsyncPipe,
        ToastComponent,
        NgTemplateOutlet,
        AsTypePipe,
    ],
})
export class ReviewsReportsSettingsComponent extends BaseReportsSettingsComponent {
    readonly SvgIcon = SvgIcon;
    constructor(
        protected readonly _toastService: ToastService,
        protected readonly _translate: TranslateService,
        protected readonly _formBuilder: FormBuilder,
        protected readonly _reportsService: ReportsService
    ) {
        super(_toastService, _translate, _formBuilder, _reportsService);
    }
    showInvalidFormToast(): void {
        this._toastService.openErrorToast(this._translate.instant('user.reports-settings.invalid_form'));
    }

    showSuccessToast(): void {
        this._toastService.openSuccessToast(this._translate.instant('user.reports-settings.successfully_saved'));
    }
}
