<div class="relative flex h-full justify-center">
    <div class="absolute left-2 top-2 flex w-[96%] gap-x-2">
        @for (story of storyCarousel; track trackByUrlFn(i, story); let i = $index) {
            <div
                class="h-[3px] grow rounded-[4px]"
                [ngClass]="{ 'bg-white': i <= currentStoryIdx, 'bg-white/[.35]': i > currentStoryIdx }"></div>
        }
    </div>

    <div class="absolute top-[20px] flex w-full justify-between px-4">
        <div class="flex items-center gap-x-2">
            <img class="h-8 w-8 rounded-full" [src]="profilePictureUrl ?? ('default_logo' | imagePathResolver)" />
            <p class="malou-text-14--semibold malou-color-white" style="text-shadow: 1px 1px 1px black">{{ '@' + igAccountName }}</p>
            <p class="malou-text-14--regular malou-color-white" style="text-shadow: 1px 1px 1px black">{{ currentStory?.postedAt }}</p>
        </div>

        <mat-icon
            class="z-10 mt-2 !h-4 !w-4 cursor-pointer"
            [svgIcon]="slideAnimationOn ? 'pause' : 'play'"
            (click)="onPlayerClick()"></mat-icon>
    </div>

    @if (!currentStories.length) {
        <ng-template *ngTemplateOutlet="noStory"></ng-template>
    } @else {
        @switch (currentStory?.type) {
            @case (PostType.IMAGE) {
                <ng-container [ngTemplateOutlet]="imageMedia"></ng-container>
            }
            @case (PostType.VIDEO) {
                <ng-container [ngTemplateOutlet]="videoMedia"></ng-container>
            }
            @default {
                <ng-container [ngTemplateOutlet]="imageMedia"></ng-container>
            }
        }
    }

    <div class="absolute left-0 top-[45%] z-10 flex w-full translate-y-[50%] transform px-3">
        <mat-icon
            class="!h-6 !w-6 cursor-pointer !text-malou-color-text-white"
            [svgIcon]="SvgIcon.CHEVRON_LEFT"
            (click)="previousMedia()"></mat-icon>
        <mat-icon
            class="!ml-auto !h-6 !w-6 cursor-pointer !text-malou-color-text-white"
            [svgIcon]="SvgIcon.CHEVRON_RIGHT"
            (click)="nextMedia()"></mat-icon>
    </div>
</div>

<ng-template #imageMedia>
    <img class="story-media rounded-[10px] object-cover" [src]="currentStory?.url" (error)="onImgError($event)" />
</ng-template>

<ng-template #videoMedia>
    <video class="story-media rounded-[10px] object-cover" id="videoMedia" autoplay [muted]="true" (ended)="onVideoEnd()">
        <source type="video/mp4" [src]="currentStory?.url + '#t=0.1'" />
    </video>
</ng-template>

<ng-template #noStory>
    <div class="flex h-full w-full flex-col items-center justify-center bg-white p-4">
        <img alt="Plate illustration" [src]="'Cook' | illustrationPathResolver" />
        <p class="malou-text-10--bold mt-4 text-center text-malou-color-text-1">
            {{ 'stories.carousel.no_current_stories' | translate }}
        </p>
    </div>
</ng-template>
