import { z } from 'zod';

import { NfcsPlatformKey, NfcStar } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const searchNfcsQueryValidator = z
    .object({
        text: z.string().optional(),
        restaurant_id: objectIdValidator.optional(),
        active: z.string().transform(Boolean).optional(),
        page: z.string().transform(Number).optional(),
        limit: z.string().transform(Number).optional(),
    })
    .refine((a) => a.page === undefined || a.limit !== undefined, {
        message: 'limit is required when page is provided',
        path: ['limit'],
    })
    .transform((data) => ({
        text: data.text,
        restaurantId: data.restaurant_id,
        active: data.active,
        page: data.page,
        limit: data.limit,
    }));
export type SearchNfcsQueryDto = z.infer<typeof searchNfcsQueryValidator>;

export const searchNfcsBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator).optional(),
});
export type SearchNfcsBodyDto = z.infer<typeof searchNfcsBodyValidator>;

// ----------------------------------------------------------------------

export const createTotemBodyValidator = z.object({
    chipName: z.string(),
    restaurantId: objectIdValidator,
    active: z.boolean(),
    name: z.string().nullish(),
    platformKey: z.nativeEnum(NfcsPlatformKey),
    redirectionLink: z.string().url(),
    notes: z.string().nullish(),
    starsRedirected: z.array(z.nativeEnum(NfcStar)),
});
export type CreateTotemBodyDto = z.infer<typeof createTotemBodyValidator>;

export const updateTotemParamsValidator = z.object({
    nfcId: objectIdValidator,
});
export type UpdateTotemParamsDto = z.infer<typeof updateTotemParamsValidator>;

export const updateTotemBodyValidator = z.object({
    chipName: z.string().optional(),
    restaurantId: objectIdValidator,
    active: z.boolean(),
    name: z.string().nullish(),
    platformKey: z.nativeEnum(NfcsPlatformKey),
    redirectionLink: z.string().url(),
    notes: z.string().nullish(),
    starsRedirected: z.array(z.nativeEnum(NfcStar)).nullish(),
});
export type UpdateTotemBodyDto = z.infer<typeof updateTotemBodyValidator>;

export const updateStickerParamsValidator = z.object({
    nfcId: objectIdValidator,
});
export type UpdateStickerParamsDto = z.infer<typeof updateStickerParamsValidator>;

export const updateStickerBodyValidator = z.object({
    restaurantId: objectIdValidator,
    chipName: z.string().nullish(),
    active: z.boolean(),
    platformKey: z.nativeEnum(NfcsPlatformKey),
    redirectionLink: z.string().url(),
    notes: z.string().nullish(),
    starsRedirected: z.array(z.nativeEnum(NfcStar)).nullish(),
});
export type UpdateStickerBodyDto = z.infer<typeof updateStickerBodyValidator>;

export const getStickerByRestaurantIdParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type GetStickerByRestaurantIdParamsDto = z.infer<typeof getStickerByRestaurantIdParamsValidator>;

export const deleteNfcParamsValidator = z.object({
    nfcId: objectIdValidator,
});
export type DeleteNfcParamsDto = z.infer<typeof deleteNfcParamsValidator>;

export const getNfcByChipnameParamsValidator = z.object({
    chipname: z.string(),
});
export type GetTotemByChipnameParamsDto = z.infer<typeof getNfcByChipnameParamsValidator>;
