import { CaslRole } from '../../constants';

export enum NotificationChannel {
    EMAIL = 'email',
    MOBILE = 'mobile',
    WEB = 'web',
}

export enum NotificationType {
    REVIEW = 'review',
    REVIEW_REPLY_REMINDER = 'review_reply_reminder',
    SPECIAL_HOUR = 'special_hour',
}

export const NEGATIVE_REVIEW_MAX_RATING = 3;
export const NEGATIVE_REVIEW_MAX_DAYS_NOTIFICATION = 30;
export const NEGATIVE_REVIEW_MIN_DAYS_NOTIFICATION = 2;

export const NOTIFICATIONS_ROLE = Object.freeze({
    [NotificationType.REVIEW_REPLY_REMINDER]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR, CaslRole.MODERATOR],
    [NotificationType.SPECIAL_HOUR]: [CaslRole.ADMIN],
});
