import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';
import { restaurantAiSettingsValidator } from './restaurant-ai-settings.dto';

export const updateRestaurantAiSettingsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type UpdateRestaurantAiSettingsParamsDto = z.infer<typeof updateRestaurantAiSettingsParamsValidator>;

export const updateRestaurantAiSettingsBodyValidator = z.object({
    restaurantAiSettings: restaurantAiSettingsValidator,
});

export type UpdateRestaurantAiSettingsBodyDto = z.infer<typeof updateRestaurantAiSettingsBodyValidator>;
