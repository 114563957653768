<app-permissions-headband class="fixed left-0 right-0 top-0 z-20"></app-permissions-headband>
<mat-sidenav-container class="h-screen w-screen !bg-malou-color-background-light">
    <mat-sidenav
        [opened]="!isSmallScreen || isSidenavOpened"
        [position]="isSmallScreen ? 'end' : 'start'"
        [mode]="isSmallScreen ? 'over' : 'side'">
        <app-sidenav-content></app-sidenav-content>
    </mat-sidenav>
    <mat-sidenav-content style="{{ isSmallScreen ? '' : 'margin-left: ' + matSidenavWidth + 'px;' }}">
        <div class="flex h-full flex-col">
            <app-header
                [showLogo]="isSmallScreen || !isSidenavOpened"
                [showRouting]="!isSmallScreen"
                [showLangSelection]="!isSmallScreen"
                [showProfile]="!isSmallScreen"
                [showSidenavToggle]="isSmallScreen"></app-header>
            <div class="min-h-0 grow !bg-malou-color-background-light">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer-manager [isAggregatedView]="isAggregatedView"></app-footer-manager>
    </mat-sidenav-content>
</mat-sidenav-container>
