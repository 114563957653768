import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

@Component({
    selector: 'app-detected-inconsistencies',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        SkeletonComponent,
        EnumTranslatePipe,
        FlagPathResolverPipe,
    ],
    templateUrl: './detected-inconsistencies.component.html',
    styleUrl: './detected-inconsistencies.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectedInconsistenciesComponent {
    readonly totalDifferencesCount = input<number>();
    readonly openPlatformsComparisons = output<void>();
}
