import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { compact } from 'lodash';
import { map } from 'rxjs';

import { CaslRole, Role } from '@malou-io/package-utils';

import { OrganizationsService } from ':core/services/organizations.service';
import { User } from ':modules/user/user';
import { InputNumberComponent } from ':shared/components/input-number/input-number.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { UpsertKind } from ':shared/enums/upsert-kind.enum';
import { getFormControlRecordFromDefaultValue } from ':shared/helpers/form-control-from-default-value';
import { INullableFormControlRecord } from ':shared/interfaces/form-control-record.interface';
import { Organization } from ':shared/models/organization';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ValuesPipe } from ':shared/pipes/values.pipe';

export interface UserUpsertForm {
    name: string;
    lastname: string;
    email: string;
    password?: string;
    role: Role;
    organizations: Organization[];
    caslRole: CaslRole;
}

interface UpsertUserModalInputData {
    upsertKind: UpsertKind;
    user?: User;
    organizations?: Organization[];
}

@Component({
    selector: 'app-upsert-user-modal',
    templateUrl: './upsert-user-modal.component.html',
    styleUrls: ['./upsert-user-modal.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        TranslateModule,
        SelectComponent,
        SelectChipListComponent,
        ReactiveFormsModule,
        MatButtonModule,
        InputTextComponent,
        ValuesPipe,
        InputNumberComponent,
    ],
    providers: [EnumTranslatePipe],
})
export class UpsertUserModalComponent {
    readonly SvgIcon = SvgIcon;
    formGroup: FormGroup<INullableFormControlRecord<UserUpsertForm>>;
    allOrganizations: Organization[];
    readonly UpsertKind = UpsertKind;
    readonly CaslRole = CaslRole;
    readonly OrganizationRole = {
        OWNER: CaslRole.OWNER,
        GUEST: CaslRole.GUEST,
    };
    readonly displayedRoles = [Role.ADMIN, Role.MALOU_BASIC];

    constructor(
        private readonly _dialogRef: MatDialogRef<UpsertUserModalComponent>,
        private readonly _organizationsService: OrganizationsService,
        private readonly _formBuilder: FormBuilder,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        @Inject(MAT_DIALOG_DATA)
        public data: UpsertUserModalInputData
    ) {
        const formControlRecord: INullableFormControlRecord<UserUpsertForm> = this._getFormControlRecord(data);
        this.formGroup = this._formBuilder.group(formControlRecord);

        this._organizationsService
            .index()
            .pipe(map((res) => res.data))
            .subscribe((res) => {
                this.allOrganizations = res;
                const newValues = (this.data.organizations ?? []).map((organization) =>
                    this.allOrganizations.find((o) => o._id === organization._id)
                );
                const newValuesFiltered = compact(newValues);
                this.formGroup.get('organizations')?.setValue(newValuesFiltered);
            });
    }

    save(): void {
        this._dialogRef.close(this.formGroup.getRawValue());
    }

    close(): void {
        this._dialogRef.close();
    }

    organizationDisplayWith(organization: Organization): string {
        return organization.name;
    }

    roleDisplayWith = (role: Role): string => this._enumTranslatePipe.transform(role, 'role');

    caslRoleDisplayWith = (caslRole: CaslRole): string => this._enumTranslatePipe.transform(caslRole, 'casl_role');

    private _getFormControlRecord(data: UpsertUserModalInputData): INullableFormControlRecord<UserUpsertForm> {
        return getFormControlRecordFromDefaultValue(
            {
                name: data.user?.name ?? '',
                lastname: data.user?.lastname ?? '',
                email: data.user?.email ?? '',
                ...(data.upsertKind === UpsertKind.INSERT ? { password: '' } : {}),
                role: data.user?.role ?? Role.MALOU_BASIC,
                organizations: [],
                caslRole: data.user?.caslRole ?? CaslRole.GUEST,
            },
            {
                name: { validators: Validators.required },
                lastname: { validators: Validators.required },
                email: { validators: Validators.required },
                ...(data.upsertKind === UpsertKind.INSERT ? { password: { validators: Validators.required } } : {}),
                role: { validators: Validators.required },
                caslRole: { validators: Validators.required },
            }
        );
    }
}
