import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import { InputMediaType } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import { MediaPickerFilter } from ':modules/media/media-picker-modal/media-picker-modal.interface';
import { Media } from ':shared/models/media';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { MediaFileUploaderComponent } from '../media-file-uploader/media-file-uploader.component';

@Component({
    selector: 'app-media-picker-button',
    standalone: true,
    imports: [TranslateModule, MatIconModule, MatMenuModule, MatButtonModule, MediaFileUploaderComponent],
    templateUrl: './media-picker-button.component.html',
    styleUrls: ['./media-picker-button.component.scss'],
})
export class MediaPickerButtonComponent {
    @Input() accept = 'image/png, image/jpeg, video/quicktime, video/mp4';
    @Input() multiple = true;
    @Input() maxVideoSize: number;
    @Input() maxImageSize: number;

    @Output() fileChanged: EventEmitter<Media[]> = new EventEmitter<Media[]>();
    @Output() startReadingFile: EventEmitter<number> = new EventEmitter<number>();
    @Output() processError: EventEmitter<Error | string> = new EventEmitter<Error>();

    readonly SvgIcon = SvgIcon;

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _customDialogService: CustomDialogService
    ) {}

    openMediaPickerModal(): void {
        this._customDialogService
            .open(MediaPickerModalComponent, {
                width: '600px',
                data: {
                    restaurant: this._restaurantsService.currentRestaurant,
                    multi: this.multiple,
                    selectedMedias: [],
                    filter: this._getMediaTypeFilter(),
                    maxVideoSize: this.maxVideoSize,
                },
            })
            .afterClosed()
            .subscribe({
                next: (res: Media[]) => {
                    if (res?.length) {
                        this.fileChanged.emit(res);
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    onFinishUpload(event: Media[]): void {
        this.fileChanged.emit(event);
    }

    onStartReadingFile(event: number): void {
        this.startReadingFile.emit(event);
    }

    onProcessError(event: Error | string): void {
        this.processError.emit(event);
    }

    private _getMediaTypeFilter(): MediaPickerFilter {
        if (this.accept.includes(InputMediaType.VIDEO) && this.accept.includes(InputMediaType.IMAGE)) {
            return MediaPickerFilter.ALL;
        }
        if (this.accept.includes(InputMediaType.VIDEO)) {
            return MediaPickerFilter.ONLY_VIDEO;
        }
        return MediaPickerFilter.ONLY_IMAGE;
    }
}
