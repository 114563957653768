export class RestaurantRankingFormat {
    public constructor(public name: string, public place_id: string, public vicinity?: string, public formatted_address?: string) {}
}

export class RestaurantRankingFormatWithScore extends RestaurantRankingFormat {
    constructor(
        public score: number,
        public name: string,
        public place_id: string,
        public vicinity?: string,
        public formatted_address?: string
    ) {
        super(name, place_id, vicinity, formatted_address);
    }

    public addToScore(number: number): void {
        this.score += number;
    }
}
