<div class="malou-dialog h-full">
    <div class="malou-dialog__header !items-start">
        <div class="flex w-full flex-col">
            <span class="malou-text-18--bold mb-2 w-[90%]">
                {{ 'restaurants_list.restaurant_setup_modal.confirm' | translate }}
            </span>
            <span class="malou-text-12--regular malou-color-text-2 italic">{{
                'restaurants_list.restaurant_setup_modal.data_is_confidential' | translate
            }}</span>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body mb-8 !overflow-hidden">
        <ng-container
            [ngTemplateOutlet]="hasMoreThanOneRestaurant() ? multipleRestaurantsTemplate : singleRestaurantTemplate"></ng-container>
        <div class="!mt-7 flex items-center justify-end sm:flex-col">
            <button
                class="malou-btn-raised--secondary mr-4 !h-11 items-center justify-center sm:mb-5 sm:mr-0 sm:w-full"
                mat-raised-button
                (click)="close()">
                {{ 'common.later' | translate }}
            </button>
            <button
                class="malou-btn-raised--primary flex !h-11 items-center justify-center sm:w-full"
                mat-raised-button
                (click)="saveManyRestaurantSettings()">
                @if (isSavingRestaurantSettings()) {
                    <app-malou-spinner class="flex w-[93px] justify-center" size="small" color="white"></app-malou-spinner>
                } @else {
                    {{ 'common.save' | translate }}
                }
            </button>
        </div>
    </div>
</div>

<ng-template #multipleRestaurantsTemplate>
    <app-select
        class="mt-2 w-full"
        [formControl]="currencyForm()"
        [title]="'restaurants_list.restaurant_setup_modal.currency' | translate"
        [values]="currencyOptions"
        [displayWith]="currencyDisplayWith">
    </app-select>
    <div class="mt-3 flex max-h-[45vh] flex-col overflow-y-auto pr-2">
        @for (restaurant of restaurants$ | async; track trackByIdFn($index, restaurant); let index = $index; let isLast = $last) {
            <div
                class="malou-color-bg-white malou-border-primary my-2 flex w-full flex-col rounded-[10px] p-5"
                [ngClass]="{ '!mt-0': index === 0, '!mb-0': isLast }"
                [formGroup]="restaurantSettingsControls()[restaurant._id]">
                <div class="relative mb-5 flex w-full items-center justify-between">
                    <div class="flex max-w-[85%] items-center">
                        <img
                            class="malou-avatar--small"
                            [lazyLoad]="restaurant?.logo?.getMediaUrl('small') ?? ''"
                            [defaultImage]="'default-picture-grey' | imagePathResolver" />
                        <div class="ml-3 flex min-w-0 grow flex-col leading-4">
                            <span class="malou-text-14--semibold text-malou-color-text-1">{{ restaurant?.internalName }}</span>
                            <span class="malou-text-10--regular truncate font-normal italic text-malou-color-text-2">{{
                                getRestaurantSubtext | applyPure: restaurant
                            }}</span>
                        </div>
                    </div>
                    <button
                        class="malou-btn-icon--secondary btn-xl"
                        id="duplicateModalButton"
                        mat-icon-button
                        [matTooltip]="'roi.aggregated_settings.duplicate_settings' | translate"
                        (click)="openDuplicateDropdown($event, restaurant._id)">
                        <mat-icon class="icon-btn h-3 w-4" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                    </button>
                    @if (showDuplicateModal() && currentDuplicatedRestaurantSettings()?.restaurantId === restaurant._id) {
                        <div
                            class="malou-card duplicate-settings-modal-box-shadow !absolute right-[-9px] z-10 w-[270px] !px-0 !py-2"
                            id="duplicateModalTemplate">
                            <ng-container *ngTemplateOutlet="duplicateModalTemplate"></ng-container>
                        </div>
                    }
                </div>
                <app-select
                    class="mb-3 w-full"
                    formControlName="category"
                    [title]="'restaurants_list.restaurant_setup_modal.restaurant_category' | translate"
                    [values]="categoriesOptions"
                    [inputReadOnly]="true"
                    [displayWith]="categoryDisplayWith"></app-select>
                <app-input-date-picker
                    class="mb-3"
                    formControlName="openingDate"
                    [title]="'restaurants_list.restaurant_setup_modal.opening_date' | translate"
                    [placeholder]="'restaurants_list.restaurant_setup_modal.opening_date' | translate">
                </app-input-date-picker>
                <app-input-number
                    class="mb-3 w-full"
                    [value]="restaurantSettingsControls()[restaurant._id].get('averageTicket')?.value ?? null"
                    [title]="'restaurants_list.restaurant_setup_modal.average_ticket' | translate"
                    [placeholder]="'restaurants_list.restaurant_setup_modal.average_ticket_placeholder' | translate"
                    [min]="1"
                    [max]="1000"
                    (inputNumberChange)="onAverageTicketChange($event, restaurant._id)">
                </app-input-number>
                <app-select
                    class="w-full"
                    formControlName="revenue"
                    [title]="'restaurants_list.restaurant_setup_modal.average_revenue_per_month' | translate"
                    [placeholder]="'restaurants_list.restaurant_setup_modal.average_revenue_per_month_placeholder' | translate"
                    [values]="revenueOptions"
                    [inputReadOnly]="true"
                    [displayWith]="revenueDisplayWith"></app-select>
            </div>
        }
    </div>
</ng-template>

<ng-template #singleRestaurantTemplate>
    @if (singleRestaurantId()) {
        <div class="flex flex-col" [formGroup]="restaurantSettingsControls()[singleRestaurantId()]">
            <app-select
                class="mb-3 w-full"
                formControlName="category"
                [title]="'restaurants_list.restaurant_setup_modal.restaurant_category' | translate"
                [values]="categoriesOptions"
                [inputReadOnly]="true"
                [displayWith]="categoryDisplayWith"></app-select>
            <app-input-date-picker
                class="mb-3"
                formControlName="openingDate"
                [title]="'restaurants_list.restaurant_setup_modal.opening_date' | translate"
                [placeholder]="'restaurants_list.restaurant_setup_modal.opening_date' | translate">
            </app-input-date-picker>
            <div class="mb-3 mt-5 flex justify-between sm:flex-col">
                <app-select
                    class="w-24 sm:w-full"
                    [formControl]="currencyForm()"
                    [title]="'restaurants_list.restaurant_setup_modal.currency' | translate"
                    [values]="currencyOptions"
                    [displayWith]="currencyDisplayWith">
                </app-select>
                <app-input-number
                    class="ml-5 w-full sm:ml-0 sm:w-full"
                    [value]="restaurantSettingsControls()[singleRestaurantId()].get('averageTicket')?.value ?? null"
                    [title]="'restaurants_list.restaurant_setup_modal.average_ticket' | translate"
                    [placeholder]="'restaurants_list.restaurant_setup_modal.average_ticket_placeholder' | translate"
                    [min]="1"
                    [max]="1000"
                    (inputNumberChange)="onAverageTicketChange($event)">
                </app-input-number>
            </div>
            <app-select
                class="ml-24 pl-5 sm:ml-0 sm:w-full sm:pl-0"
                formControlName="revenue"
                [title]="'restaurants_list.restaurant_setup_modal.average_revenue_per_month' | translate"
                [placeholder]="'restaurants_list.restaurant_setup_modal.average_revenue_per_month_placeholder' | translate"
                [values]="revenueOptions"
                [inputReadOnly]="true"
                [displayWith]="revenueDisplayWith"></app-select>
        </div>
    }
</ng-template>

<ng-template #duplicateModalTemplate>
    <div class="malou-card__body flex max-h-[250px] flex-col gap-y-1 overflow-y-auto !p-0">
        <div class="flex items-center px-3 py-2">
            <mat-checkbox color="primary" [checked]="areAllRestaurantsChecked()" (change)="toggleAllDuplicate($event)"> </mat-checkbox>
            <div class="malou-text-13--semibold ml-3 text-malou-color-text-1">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.your_establishments' | translate }}
            </div>
        </div>
        @for (restaurant of restaurants(); track trackByIdFn($index, restaurant)) {
            <div
                class="flex cursor-pointer items-center px-3 py-2 hover:bg-malou-color-background-dark"
                [ngClass]="{
                    'opacity-50': restaurant.id === currentDuplicatedRestaurantSettings()?.restaurantId,
                    'bg-malou-color-background-dark': isChecked()(restaurant._id),
                }"
                (click)="toggleDuplicate(!isChecked()(restaurant._id), restaurant._id)">
                <mat-checkbox
                    color="primary"
                    [checked]="isChecked()(restaurant._id)"
                    [disabled]="restaurant.id === currentDuplicatedRestaurantSettings()?.restaurantId">
                </mat-checkbox>
                <div class="ml-3">
                    <div class="malou-text-12--semibold text-malou-color-text-1">
                        {{ restaurant.name }}
                    </div>
                    <div class="malou-text-10--regular italic text-malou-color-text-2">
                        {{ restaurant.address | applySelfPure: 'getFullFormattedAddress' }}
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>
