<div
    class="flex flex-col rounded-[15px] p-3.5"
    [ngClass]="review()?.comments?.length || whiteBorders() ? 'container-border-white' : 'container-border-grey'">
    <div class="flex">
        <span class="malou-text-10--bold">{{ 'reviews.review_analyses.semantic_analysis' | translate }}</span>
    </div>

    @if (showOriginalTextWarning()) {
        <span class="malou-text-10 mt-3 italic">{{ 'reviews.review_analyses.show_original_text' | translate }}</span>
    } @else {
        @switch (analysisStatus()) {
            @case (ReviewAnalysisStatus.DONE) {
                <div class="mt-3 flex flex-wrap gap-y-2.5">
                    @for (segmentAnalysis of segmentAnalyses(); track segmentAnalysis) {
                        <div class="mr-[10px]">
                            <div
                                class="malou-chip--rounded-20"
                                [ngClass]="{
                                    'malou-chip--success':
                                        segmentAnalysis.sentiment.toLowerCase() === SemanticAnalysisSentiment.POSITIVE.toLowerCase(),
                                    'malou-chip--error':
                                        segmentAnalysis.sentiment.toLowerCase() === SemanticAnalysisSentiment.NEGATIVE.toLowerCase(),
                                }"
                                [class.chip-hover-opacity]="isAnyChipHovered()"
                                (mouseenter)="onMouseEnter(segmentAnalysis)"
                                (mouseleave)="onMouseLeave()">
                                <span>{{ 'reviews.review_analyses.' + segmentAnalysis.tag | translate }}</span>
                            </div>
                        </div>
                    }
                </div>
            }
            @case (ReviewAnalysisStatus.REVIEW_TOO_OLD) {
                <div class="mt-3 flex">
                    <span class="malou-text-10 italic">{{ 'reviews.review_analyses.review_too_old' | translate }}</span>
                </div>
            }
            @case (ReviewAnalysisStatus.FAILED) {
                <div class="mt-3 flex">
                    <span class="malou-text-10 italic">{{ 'reviews.review_analyses.not_analyzed' | translate }}</span>
                </div>
            }
            @case (ReviewAnalysisStatus.UNSUPPORTED_PLATFORM) {
                <div class="mt-3 flex">
                    <span class="malou-text-10 italic">{{ 'reviews.review_analyses.platform_not_supported' | translate }}</span>
                </div>
            }
            @case (ReviewAnalysisStatus.NO_RESULTS) {
                <div class="mt-3 flex">
                    <span class="malou-text-10 italic">{{ 'reviews.review_analyses.no_results' | translate }}</span>
                </div>
            }
            @default {
                <div class="mt-3 flex">
                    <span class="malou-text-10 italic">{{ 'reviews.review_analyses.not_yet_analyzed' | translate }}</span>
                </div>
            }
        }
    }
</div>
