import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { PlatformKey } from '@malou-io/package-utils';

import { DatesAndPeriod, Nfc } from ':shared/models';

import { AggregatedStatisticsState, PlatformFilterPage } from './aggregated-statistics.interface';

const selectFeature = createFeatureSelector<AggregatedStatisticsState>('aggregatedStatistics');

export const selectDatesFilter = createSelector(selectFeature, (state): DatesAndPeriod => state.filters.dates);

export const selectPlatformsFilter = (props: {
    page: PlatformFilterPage | undefined;
}): MemoizedSelector<object, PlatformKey[], DefaultProjectorFn<PlatformKey[]>> =>
    createSelector(selectFeature, (state): PlatformKey[] => (props.page ? state.filters.platforms[props.page] : []));

export const selectRestaurantsIdsFilter = createSelector(selectFeature, (state): string[] => state.filters.restaurantsIds);

export const selectRoiRestaurantsIdsFilter = createSelector(selectFeature, (state): string[] => state.filters.roiRestaurantsIds);

export const selectReviewsWithAnalysisByRestaurantData = createSelector(
    selectFeature,
    (state) => state.data.reviewsWithAnalysisByRestaurant
);

export const selectPlatformsRatingsByRestaurantData = createSelector(selectFeature, (state) => state.data.platformsRatingsByRestaurant);

export const selectTotemsFilter = createSelector(selectFeature, (state): Nfc[] => state.filters.totems);

export const selectReviewCountsData = createSelector(selectFeature, (state) => state.data.reviewCounts);

export const selectAverageReviewsRatingsData = createSelector(selectFeature, (state) => state.data.averageReviewsRatings);

export const selectBoosterStatsData = createSelector(selectFeature, (state) => state.data.boosterStatsData);

export const selectTimeScaleFilter = createSelector(selectFeature, (state) => state.filters.timeScale);
