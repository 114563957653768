import { UploadedUppyFile } from '@uppy/core';
import { from, Observable, of, tap } from 'rxjs';

import { MediaDto } from '@malou-io/package-dto';
import { MediaConvertedStatus, MediaDimension, MediaType, PictureSize, PictureSizeRecord } from '@malou-io/package-utils';

import { SimpleUserInterface } from ':modules/user/user';
import { parseAspectRatio } from ':shared/helpers/dimensions';

import { createVideoElement, getThumbnailVideoCoverIdSelector, getVideoCoverUrl, pickImagesFromVideo } from '../helpers/video-cover-url';
import { ImageProperties } from './image-properties';
import { MediaProperties } from './media-errors';

const DEFAULT_NAME = 'no name';

export type MediumType = 'IMAGE' | 'VIDEO';

export type UppyTemporaryMedia = UploadedUppyFile<Record<string, unknown>, Record<string, unknown>> & Partial<Media>;

export interface ResizeMetadata {
    width: number;
    height: number;
    cropPosition: {
        top: number;
        left: number;
    };
    aspectRatio: number;
}

export class Media {
    _id: string;
    id: string;
    restaurantId?: string;
    socialId: string;
    title: string;
    name: string;
    description: string;
    category: string; // 'cover', 'additional'
    format: 'png' | 'jpeg' | 'jpg' | 'mov' | 'quicktime' | 'mp4';
    type: MediaType.PHOTO | MediaType.VIDEO;
    urls: PictureSizeRecord<string>;
    sizes: PictureSizeRecord<number>;
    dimensions: PictureSizeRecord<MediaDimension>;
    duration?: number;
    uploader: SimpleUserInterface;
    postsCount: number;
    publishedCount: number;
    createdAt: Date;
    updatedAt: Date;
    postIds?: string[] = [];
    originalMediaId?: string;
    errors?: string[];
    userTags: UserTag[];
    convertedStatus?: MediaConvertedStatus | null;
    thumbnail?: string;
    resizeMetadata: ResizeMetadata;
    folderId: string | null;

    public constructor(init: Partial<Media> | Partial<MediaDto> = {}) {
        Object.assign(this, init);
        this.id = init?.id ?? (init as any)?._id;
    }

    getFullname(): string {
        // We have a name, so use it.
        if (this.name) {
            return this.name;
        }
        // We have a title, so use it.
        if (this.title) {
            return `${this.title}.${this.format}`;
        }
        // We have neither a name nor a title, so just use a generic name.
        return `${DEFAULT_NAME}.${this.format}`;
    }

    getFullnameWithFormat(): string {
        // We have a name, so use it.
        if (this.name) {
            return `${this.name}.${this.format}`;
        }
        // We have a title, so use it.
        if (this.title) {
            return `${this.title}.${this.format}`;
        }
        // We have neither a name nor a title, so just use a generic name.
        return `${DEFAULT_NAME}.${this.format}`;
    }

    isImage(): boolean {
        return this.type === MediaType.PHOTO;
    }

    isVideo(): boolean {
        return this.type === MediaType.VIDEO;
    }

    getMediaUrl(size: string = PictureSize.ORIGINAL): string {
        return this.urls?.[size] ?? this.urls?.[PictureSize.ORIGINAL];
    }

    getBytesForSize(size: string = PictureSize.ORIGINAL): number {
        return this.sizes && parseInt(this.sizes[size] ?? this.sizes[PictureSize.ORIGINAL], 10);
    }

    getErrors(): string[] {
        return this.errors ?? [];
    }

    setErrors(errors: string[]): void {
        this.errors = errors;
    }

    hasErrors(): boolean {
        return !!this.errors?.length;
    }

    getErrorsCount(): number {
        return this.errors?.length ?? 0;
    }

    getUserTags(): UserTag[] {
        return this.userTags ?? [];
    }

    setUserTags(userTags: UserTag[]): void {
        this.userTags = userTags;
    }

    hasUserTags(): boolean {
        return !!this.userTags?.length;
    }

    removeUserTag(userTag: UserTag): void {
        this.userTags = this.userTags.filter((ut) => ut.username !== userTag.username);
    }

    getUserTagsStringified(): string {
        return this.userTags.map((u) => u.username).join(', ');
    }

    getRandomMediaName(length: number): string {
        return Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
            .toString(36)
            .slice(1);
    }

    getVideoCoverUrl$(offsetTimeInSeconds = 0.0): Observable<string> {
        if (!this.isVideo()) {
            throw new Error('Media is not a video');
        }
        const video = createVideoElement(this);
        return from(getVideoCoverUrl(video, offsetTimeInSeconds)).pipe(
            tap(() => {
                document.getElementById(getThumbnailVideoCoverIdSelector(this))?.remove();
            })
        );
    }

    pickImagesFromVideo$(offsetTimeInSeconds = 0.0): Observable<string[]> {
        if (!this.isVideo()) {
            throw new Error('Media is not a video');
        }
        return from(pickImagesFromVideo(this, offsetTimeInSeconds));
    }

    getAspectRatio(pictureSize: PictureSize = PictureSize.ORIGINAL): number {
        const dimensions = this.dimensions?.[pictureSize] ?? this.dimensions?.[PictureSize.ORIGINAL];
        return dimensions.width / dimensions.height;
    }

    getProperties$(): Observable<ImageProperties | null> {
        if (!this.isImage()) {
            return of(null);
        }
        return new Observable((observer) => {
            const img = new Image();
            img.src = this.getMediaUrl(PictureSize.IG_FIT);
            img.onload = (): void => {
                const imgProperties = new ImageProperties({
                    bytes: this.getBytesForSize(PictureSize.IG_FIT),
                    width: img.width,
                    height: img.height,
                });
                observer.next(imgProperties);
                observer.complete();
            };
        });
    }

    setMediaResizeMetadata(properties: MediaProperties): void {
        this.resizeMetadata = {
            width: properties.width,
            height: properties.height,
            cropPosition: {
                top: 0,
                left: 0,
            },
            aspectRatio: parseAspectRatio(properties.width / properties.height),
        };

        this.dimensions = {
            ...(this.dimensions ?? {}),
            original: {
                width: properties.width,
                height: properties.height,
            },
        };
    }
}

export interface UserTag {
    username: string;
    x: number;
    y: number;
}
