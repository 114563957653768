@if (keywords$ | async; as keywords) {
    @if (!(isLoading$ | async)) {
        @if (keywords.error) {
            <ng-container [ngTemplateOutlet]="keywordsErrorTemplate" [ngTemplateOutletContext]="{ keywords: keywords }"></ng-container>
        }
        @if (keywords.value) {
            <div class="malou-simple-card flex h-full flex-col gap-3 px-6 py-3 md:px-2">
                <div>
                    <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
                </div>
                <div class="overflow-y-scroll pr-4">
                    <ng-container [ngTemplateOutlet]="dataTemplate"></ng-container>
                </div>
            </div>
        }
    } @else {
        <app-skeleton heightClass="h-full" skeletonClass="!h-full secondary-bg"></app-skeleton>
    }
} @else {
    <app-skeleton heightClass="h-full" skeletonClass="!h-full secondary-bg"></app-skeleton>
}

<ng-template #titleTemplate>
    <div class="flex items-center justify-between gap-2 md:flex-col md:items-start">
        <div class="flex items-center gap-2">
            <div class="malou-text-section-title malou-color-text-1">
                {{ 'aggregated_statistics.seo.keywords.google_ranking' | translate }}
            </div>
            @if (warningTooltip) {
                <div class="malou-status--waiting pdf-hidden" [matTooltip]="warningTooltip">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </div>
            }
        </div>

        @if (!shouldHideTableClickableElements) {
            <mat-button-toggle-group class="malou-group-toggle-btn" [value]="ToggleMode.ALL_KEYWORDS" (change)="onToggleChange($event)">
                <mat-button-toggle [value]="ToggleMode.ALL_KEYWORDS">{{
                    (screenSizeService.isPhoneScreen ? 'common.all' : 'aggregated_statistics.seo.keywords.all_keywords') | translate
                }}</mat-button-toggle>
                <mat-button-toggle [value]="ToggleMode.COMMON_KEYWORDS">{{
                    (screenSizeService.isPhoneScreen
                        ? 'aggregated_statistics.seo.keywords.in_common'
                        : 'aggregated_statistics.seo.keywords.common_keywords'
                    ) | translate
                }}</mat-button-toggle>
            </mat-button-toggle-group>
        } @else {
            <div class="flex items-center">
                <span class="malou-color-text-2 malou-text-11--regular italic">{{
                    'aggregated_statistics.seo.keywords.all_keywords' | translate
                }}</span>
            </div>
        }
    </div>
</ng-template>

<ng-template #dataTemplate>
    <div class="flex flex-col">
        <div class="hidden grow md:block">
            <app-sort-by-filters
                [sortOptions]="SORT_OPTIONS"
                [sortBy]="Column.RESTAURANT_NAME"
                [sortOrder]="1"
                (changeSortBy)="onSortByChange($event)"
                (toggleSortOrder)="onSortOrderChange()">
            </app-sort-by-filters>
        </div>
        <ng-container [ngTemplateOutlet]="listTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #listTemplate>
    @if (dataSource.data.length) {
        <mat-table
            class="malou-mat-table"
            matSort
            matSortDisableClear
            [dataSource]="dataSource"
            [matSortActive]="defaultSort.active"
            [matSortDirection]="defaultSort.direction"
            (matSortChange)="onSortChange($event)"
            #table="matTable">
            <ng-container [matColumnDef]="Column.RESTAURANT_NAME">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'aggregated_statistics.seo.keywords.restaurant' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let keywordTableDataRow; table: table">
                    <span
                        class="malou-text-13--semibold malou-color-text-1"
                        [matTooltip]="keywordTableDataRow | applySelfPure: 'getKeywordsToString' | translate">
                        {{ keywordTableDataRow.restaurantName }}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container [matColumnDef]="Column.CURRENT_TOP_20">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'aggregated_statistics.seo.keywords.top20' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let keywordTableDataRow; table: table">
                    <div class="flex">
                        <div class="malou-text-13--regular malou-color-text-1 mr-2 hidden w-12 md:block">
                            {{ 'aggregated_statistics.seo.keywords.top20' | translate }}
                        </div>
                        <div class="malou-text-13--regular malou-color-text-1">
                            @if (!(keywordTableDataRow | applySelfPure: 'hasError')) {
                                <span>
                                    {{ keywordTableDataRow.currentTop20 }}
                                </span>
                            } @else {
                                <ng-container
                                    [ngTemplateOutlet]="rawError"
                                    [ngTemplateOutletContext]="{ keywordTableDataRow }"></ng-container>
                            }
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container [matColumnDef]="Column.CURRENT_TOP_3">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'aggregated_statistics.seo.keywords.top3' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let keywordTableDataRow; table: table">
                    <div class="flex">
                        <div class="malou-text-13--regular malou-color-text-1 mr-2 hidden w-12 md:inline">
                            {{ 'aggregated_statistics.seo.keywords.top3' | translate }}
                        </div>
                        <div class="malou-text-13--regular malou-color-text-1">
                            @if (!(keywordTableDataRow | applySelfPure: 'hasError')) {
                                <span>
                                    {{ keywordTableDataRow.currentTop3 }}
                                </span>
                            } @else {
                                <ng-container
                                    [ngTemplateOutlet]="rawError"
                                    [ngTemplateOutletContext]="{ keywordTableDataRow }"></ng-container>
                            }
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            @if (!shouldHideTableClickableElements) {
                <ng-container [matColumnDef]="Column.VIEW_KEYWORD_LIST">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let keywordTableDataRow; table: table">
                        <span class="malou-text-13--semibold malou-color-text-primary" (click)="goToKeywordsStats(keywordTableDataRow)">{{
                            'aggregated_statistics.seo.keywords.view_keywords_list' | translate
                        }}</span>
                    </mat-cell>
                </ng-container>
            }
            <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
            <mat-row *matRowDef="let keywordTableDataRow; columns: DISPLAYED_COLUMNS; table: table; let i = index"></mat-row>
        </mat-table>
    } @else {
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
            <span class="malou-text-10--regular">{{ 'statistics.errors.change_filters' | translate }}</span>
        </div>
    }
</ng-template>

<ng-template let-keywords="keywords" #keywordsErrorTemplate>
    <div class="flex flex-col items-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.server_error' | translate }}</span>
        <span class="malou-text-10--regular">{{ keywords.error }}</span>
    </div>
</ng-template>

<ng-template let-keywordTableDataRow="keywordTableDataRow" #rawError>
    <div class="malou-status--waiting" [matTooltip]="keywordTableDataRow.error ?? ''">
        <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
    </div>
</ng-template>
