import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { omit } from 'lodash';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { isNotNil, ReviewAnalysisTag, SemanticAnalysisSentiment } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { selectReviewsFilters } from ':modules/reviews/store/reviews.selectors';
import { Restaurant, Review, ReviewsFilters, SemanticAnalysis } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { AbstractCsvService, CsvAsStringArrays, DEFAULT_CSV_PAGINATION } from ':shared/services/csv-services/csv-service.abstract';

import { MAX_DOWNLOAD_REVIEWS_DURATION_IN_DAYS } from './csv-e-reputation.interface';
import { createDateIntervalWithInDaysDurationCondition, getReviewCsvRowData } from './helper-functions';

interface Data {
    reviews: Review[];
    restaurants: Restaurant[] | undefined;
}

@Injectable({ providedIn: 'root' })
export class AggregatedReviewsCsvService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _reviewsService: ReviewsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _shortNumberPipe: ShortNumberPipe
    ) {
        super();
    }

    protected override _getData$(): Observable<Data> {
        const reviewsFilters$: Observable<ReviewsFilters> = this._store.select(selectReviewsFilters);
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        return forkJoin([reviewsFilters$.pipe(take(1))]).pipe(
            switchMap(([reviewsFilters]) => {
                const { startDate, endDate } = createDateIntervalWithInDaysDurationCondition(
                    reviewsFilters?.startDate,
                    reviewsFilters?.endDate,
                    MAX_DOWNLOAD_REVIEWS_DURATION_IN_DAYS
                );
                const reviews$ = this._reviewsService
                    .getSelectedRestaurantsReviewsPaginated(
                        reviewsFilters.aggregatedViewRestaurants?.map((restaurant) => restaurant._id) ?? [restaurantId],
                        DEFAULT_CSV_PAGINATION,
                        {
                            ...omit(reviewsFilters, ['restaurants', 'period', 'aggregatedViewRestaurants']),
                            startDate,
                            endDate,
                        }
                    )
                    .pipe(map((reviews) => reviews.reviews as Review[]));

                return forkJoin({ reviews: reviews$, restaurants: of(reviewsFilters.aggregatedViewRestaurants) });
            })
        );
    }

    protected _isDataValid(_data: Data): boolean {
        if (_data.restaurants) {
            return _data.restaurants.length > 0 && _data.reviews.length > 0;
        }
        return false;
    }

    protected override _getCsvDataRows({ reviews, restaurants }: Data): CsvAsStringArrays {
        return reviews
            .map((review) => {
                const restaurant = restaurants?.find((r) => r._id === review.restaurantId);
                if (!restaurant) {
                    return null;
                }
                const rating = this._shortNumberPipe.transform(review.rating);
                const platform = this._enumTranslatePipe.transform(review.key, 'platform_key');
                const restaurantName = restaurant.name;
                const restaurantInternalName = restaurant.internalName ?? '';
                const restaurantAddress = restaurant.getFullFormattedAddress();
                const [date, ...rest] = getReviewCsvRowData(review, rating, platform);
                return [date, restaurantName, restaurantInternalName, restaurantAddress, ...rest];
            })
            .filter(isNotNil);
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return [
            'Review Date',
            'Location',
            'Location Internal Name',
            'Location Address',
            'Review Text',
            'Reviewer',
            'Rating',
            'Platform',
            'Answer 1',
            'Answer Date 1',
            'Answer 2',
            'Answer Date 2',
            'Answer 3',
            'Answer Date 3',
            'Atmosphere Positive',
            'Atmosphere Negative',
            'Service Positive',
            'Service Negative',
            'Food Positive',
            'Food Negative',
            'Price Positive',
            'Price Negative',
            'Hygiene Positive',
            'Hygiene Negative',
            'Wait Time Positive',
            'Wait Time Negative',
        ];
    }

    private _getJoinedSemanticAnalysisSegments(
        semanticAnalysis: SemanticAnalysis | null,
        tag: ReviewAnalysisTag,
        sentiment: SemanticAnalysisSentiment
    ): string {
        return (
            semanticAnalysis?.segmentAnalyses
                ?.filter((e) => e.tag === tag && e.sentiment === sentiment)
                .map((e) => e.segment)
                .join(' & ') ?? ''
        );
    }
}
