<ng-container [ngTemplateOutlet]="restaurant.otherHours.length ? otherHoursTemplate : noOtherHoursTemplate"></ng-container>

<ng-template #otherHoursTemplate>
    <div class="malou-expansion-panel flex h-60">
        <mat-accordion class="w-full">
            @for (hour of restaurant.otherHours; track hour; let i = $index) {
                <mat-expansion-panel class="px-2">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="malou-text-13--semibold malou-color-text-1">
                            {{ hour.hoursType | applySelfPure: 'getLocaleName' : lang }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <div class="px-4">
                            <app-restaurant-business-hours [businessHours]="hour.periods"> </app-restaurant-business-hours>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
</ng-template>

<ng-template #noOtherHoursTemplate>
    <div class="flex h-60 flex-col items-center justify-end gap-y-4">
        <img class="w-32" alt="Burger illustration" [src]="'Burger' | illustrationPathResolver" />
        <div class="px-10 text-center">{{ 'information.other_hours.not_specified' | translate }}</div>
        <button class="malou-btn-flat" mat-button (click)="openHoursModal()">
            {{ 'information.other_hours.add_other_hours' | translate }}
        </button>
    </div>
</ng-template>
