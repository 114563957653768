@let isFeaturePlatformsUpdatesV2Enabled = isFeaturePlatformsUpdatesV2Enabled$ | async;

<div class="malou-card group !m-0 h-full" id="hoursContainer">
    <div class="malou-card__header !pb-[15px]">
        <div class="malou-text-18--bold">
            {{ 'information.hours.hours' | translate }}
        </div>
        @if (isFeaturePlatformsUpdatesV2Enabled) {
            <div class="invisible flex gap-4 group-hover:visible md:visible">
                <button class="malou-btn-icon--secondary btn-xl" data-cy="editInfosBtn" mat-icon-button (click)="openHoursModal()">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                </button>
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    data-cy="editInfosBtn"
                    mat-icon-button
                    [disabled]="isDuplicateBtnDisabled()"
                    (click)="openHoursDuplicationModal()">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                </button>
            </div>
        } @else {
            <button class="malou-btn-icon--secondary btn-xl" data-cy="editInfosBtn" mat-icon-button (click)="openHoursModal()">
                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
            </button>
        }
    </div>
    <div class="malou-card__body h-full overflow-y-auto">
        <mat-tab-group class="h-full" animationDuration="5ms" mat-align-tabs="start" (selectedIndexChange)="handleTabChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="malou-text-14--medium">
                        {{ 'information.hours.open' | translate }}
                    </span>
                </ng-template>
                @if (!restaurant().isClosedTemporarily) {
                    <app-restaurant-business-hours [businessHours]="restaurant().regularHours"> </app-restaurant-business-hours>
                } @else {
                    <div class="mt-6 flex flex-col justify-around overflow-hidden">
                        <span class="malou-text-13--bold mb-[30px]">{{ 'information.hours.temporarily_closed_business' | translate }}</span>
                        <span class="malou-text-13 italic">{{ 'information.hours.uncheck_to_edit_hours' | translate }}</span>
                    </div>
                }
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="malou-text-14--medium" [innerHTML]="'information.hours.exceptional' | translate"></span>
                </ng-template>
                @if (!restaurant().isClosedTemporarily) {
                    <app-restaurant-special-hours [restaurant]="restaurant()"> </app-restaurant-special-hours>
                } @else {
                    <div class="mt-6 flex flex-col justify-around overflow-hidden">
                        <span class="malou-text-13--bold mb-[30px]">{{ 'information.hours.temporarily_closed_business' | translate }}</span>
                        <span class="malou-text-13 italic">{{ 'information.hours.uncheck_to_edit_hours' | translate }}</span>
                    </div>
                }
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="malou-text-14--medium" [innerHTML]="'information.hours.other' | translate"> </span>
                </ng-template>
                @if (!restaurant().isClosedTemporarily) {
                    <app-restaurant-other-hours [restaurant]="restaurant()" (openModal)="openHoursModal()"> </app-restaurant-other-hours>
                } @else {
                    <div class="mt-6 flex flex-col justify-around overflow-hidden">
                        <span class="malou-text-13--bold mb-[30px]">{{ 'information.hours.temporarily_closed_business' | translate }}</span>
                        <span class="malou-text-13 italic">{{ 'information.hours.uncheck_to_edit_hours' | translate }}</span>
                    </div>
                }
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
