import { z } from 'zod';

import { InformationUpdateAttributeValue, InformationUpdatePlatformStateStatus, PlatformKey } from '@malou-io/package-utils';

import {
    addressDtoValidator,
    latlngDtoValidator,
    otherHourValidator,
    phoneDtoValidator,
    regularHourValidator,
    specialHourValidator,
} from '../restaurant';
import { objectIdValidator } from '../utils/validators';

const informationUpdateDataAttributeValidator = z.object({
    name: z.string(),
    value: z.nativeEnum(InformationUpdateAttributeValue),
});

const informationUpdateDataValidator = z.object({
    address: addressDtoValidator.nullish(),
    attributes: z.array(informationUpdateDataAttributeValidator).nullish(),
    categoryName: z.string().nullish(),
    secondaryCategoriesNames: z.array(z.string()).nullish(),
    coverUrl: z.string().nullish(),
    shortDescription: z.string().nullish(),
    longDescription: z.string().nullish(),
    isClosedTemporarily: z.boolean().nullish(),
    latlng: latlngDtoValidator.nullish(),
    logoUrl: z.string().nullish(),
    menuUrl: z.string().nullish(),
    name: z.string().nullish(),
    openingDate: z.string().datetime().nullish(),
    otherHours: z.array(otherHourValidator).nullish(),
    phone: phoneDtoValidator.nullish(),
    regularHours: z.array(regularHourValidator).nullish(),
    specialHours: z.array(specialHourValidator).nullish(),
    website: z.string().nullish(),
});
export type InformationUpdateDataBodyDto = z.infer<typeof informationUpdateDataValidator>;

export const postInformationUpdateDataBodyValidator = z.object({
    restaurantId: objectIdValidator,
    data: informationUpdateDataValidator,
    previousData: informationUpdateDataValidator,
});
export type PostInformationUpdateDataBodyDto = z.infer<typeof postInformationUpdateDataBodyValidator>;

export const validateInformationUpdateBodyValidator = z.object({
    restaurantId: objectIdValidator,
});
export type ValidateInformationUpdateBodyDto = z.infer<typeof validateInformationUpdateBodyValidator>;

export const updateInformationUpdatePlatformStateStatusBodyValidator = z.object({
    restaurantId: objectIdValidator,
    platformKey: z.nativeEnum(PlatformKey),
    status: z.nativeEnum(InformationUpdatePlatformStateStatus),
});
export type UpdateInformationUpdatePlatformStateStatusBodyDto = z.infer<typeof updateInformationUpdatePlatformStateStatusBodyValidator>;

export const getMergedInformationUpdateBodyValidator = z.array(
    z.object({
        restaurantId: objectIdValidator,
        platformKeys: z.array(z.nativeEnum(PlatformKey)),
    })
);
export type GetMergedInformationUpdateBodyDto = z.infer<typeof getMergedInformationUpdateBodyValidator>;

export const hasRestaurantEverBeenUpdatedBodyValidator = z.object({
    restaurantId: objectIdValidator,
});
export type HasRestaurantEverBeenUpdatedBodyDto = z.infer<typeof hasRestaurantEverBeenUpdatedBodyValidator>;

export const startInformationUpdateParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type StartInformationUpdateParams = z.infer<typeof startInformationUpdateParamsValidator>;
