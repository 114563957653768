<mat-form-field class="menu-select h-6">
    <mat-select
        class="remove-arrow"
        panelClass="custom-option-panel mt-2 min-w-[120px]"
        [(value)]="currentOption"
        [compareWith]="compareOptions"
        [disableOptionCentering]="true"
        [hideSingleSelectionIndicator]="true"
        (selectionChange)="change($event)">
        <mat-select-trigger>
            {{ displaySelectedOption | applyPure: currentOption }}
        </mat-select-trigger>
        @for (option of options; track option) {
            <mat-option class="remove-check mb-[1px]" [value]="option.value">
                {{ option.viewValue }}
            </mat-option>
        }
    </mat-select>
</mat-form-field>
