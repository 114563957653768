import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, filter, map, Observable, switchMap, tap } from 'rxjs';

import {
    isNotNil,
    isValidDate,
    numberOfMonthsSinceGivenDate,
    PlatformKey,
    ROI_HIDDEN_FIRST_MONTHS_NUMBER,
    SimilarRestaurantCategory,
} from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { SimilarRestaurantsService } from ':core/services/similar-restaurants.service';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiInsightsCreationState, RoiService } from ':modules/roi/roi.service';
import { UpdateRoiSettingsModalComponent } from ':modules/roi/update-roi-settings-modal/update-roi-settings-modal.component';
import { UpsertRoiSettingsComponent } from ':modules/roi/upsert-roi-settings/upsert-roi-settings.component';
import { NoConnectedPlatformsComponent } from ':shared/components-v3/no-connected-platforms/no-connected-platforms.component';
import { LoaderPageComponent } from ':shared/components/loader-page/loader-page.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod, Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { FiltersComponent } from '../filters/filters.component';
import * as StatisticsSelector from '../store/statistics.selectors';
import { EstimatedCustomersComponent } from './estimated-customers/estimated-customers.component';
import { MonthlyEstimatedCustomersAndPerformanceChartComponent } from './monthly-estimated-customers-and-performance-chart/monthly-estimated-customers-and-performance-chart.component';
import { PerformanceScoreComponent } from './performance-score/performance-score.component';
import { SavedTimeComponent } from './saved-time/saved-time.component';
import { TipsComponent } from './tips/tips.component';

@Component({
    selector: 'app-roi',
    templateUrl: './roi.component.html',
    styleUrls: ['./roi.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        MatButtonModule,
        UpsertRoiSettingsComponent,
        NgTemplateOutlet,
        TranslateModule,
        SavedTimeComponent,
        EstimatedCustomersComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        NoConnectedPlatformsComponent,
        FiltersComponent,
        TipsComponent,
        SkeletonComponent,
        PerformanceScoreComponent,
        MonthlyEstimatedCustomersAndPerformanceChartComponent,
        LoaderPageComponent,
    ],
})
export class RoiComponent implements OnInit {
    private readonly _store = inject(Store);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _screenService = inject(ScreenSizeService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _similarRestaurantsService = inject(SimilarRestaurantsService);
    private readonly _roiService = inject(RoiService);

    readonly platformsService = inject(PlatformsService);
    readonly roiContext = inject(RoiContext);

    readonly PlatformKey = PlatformKey;
    readonly Illustration = Illustration;

    readonly selectedTimeScaleFilter$ = this._store.select(StatisticsSelector.selectTimeScaleFilter);
    readonly selectedMonths$ = this.selectedTimeScaleFilter$.pipe(
        map((timeScale) => getSelectedMonthsNumberFromTimeScaleFilter(timeScale))
    );
    readonly selectedMonths: Signal<number> = toSignal(this.selectedMonths$, {
        initialValue: getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS),
    });

    readonly reload$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    readonly isRecentRestaurant: WritableSignal<boolean> = signal(true);
    readonly MIN_MONTHS_TO_SHOW_HALF_YEAR_OF_INSIGHTS = 6;

    readonly initialCategory$: Observable<SimilarRestaurantCategory | null> = this._similarRestaurantsService
        .get(this._restaurantsService.currentRestaurant?._id)
        .pipe(map((res) => res.data?.restaurantCategory ?? null));
    readonly initialCategory: Signal<SimilarRestaurantCategory | null> = toSignal(this.initialCategory$, { initialValue: null });
    readonly alreadyHasSettings: Signal<boolean> = computed(
        () => this.isLoading() || (this.roiContext.isRoiSettingsComplete()(this.roiContext.roiSettings()) && !!this.initialCategory())
    );

    readonly restaurantLimitDate: Signal<Date | null> = toSignal(
        this._restaurantsService.restaurantSelected$.pipe(
            filter(isNotNil),
            map((restaurant) => {
                const mostRecentLimitDate = new Restaurant(restaurant).getMostRecentDateBetweenCreationAndOpening();
                if (!mostRecentLimitDate || !isValidDate(mostRecentLimitDate)) {
                    return null;
                }
                const endOfMonthMostRecentLimitDate = DateTime.fromJSDate(mostRecentLimitDate).endOf('month').endOf('day');
                return endOfMonthMostRecentLimitDate.plus({ month: ROI_HIDDEN_FIRST_MONTHS_NUMBER }).toJSDate();
            })
        ),
        { initialValue: null }
    );

    readonly isBeforeLimitDate: Signal<boolean> = computed(() => {
        const limitDate = this.restaurantLimitDate();
        if (!limitDate) {
            return false;
        }
        return new Date().getTime() < DateTime.fromJSDate(limitDate).plus({ month: 1 }).toJSDate().getTime();
    });

    readonly isLoading: WritableSignal<boolean> = signal(true);
    readonly creationStartDate: WritableSignal<Date | null> = signal(null);
    readonly creationEstimatedTime: WritableSignal<number> = signal(Number.MAX_SAFE_INTEGER);
    readonly isCreatingRoiInsights = computed(() => !!this.creationStartDate());
    readonly hasRoiInsights: WritableSignal<boolean> = signal(true);

    ngOnInit(): void {
        this._fetchLastRoiSettings();
        this._checkIfHasRoiInsights();
        this._initCreationStartDateAndEstimationTime();
    }

    openUpdateRoiSettingsModal(): void {
        this._customDialogService
            .open(UpdateRoiSettingsModalComponent, {
                panelClass: this._screenService.isPhoneScreen ? 'malou-dialog-panel--full' : 'malou-dialog-panel--fit-content',
                data: {
                    roiSettings: this.roiContext.roiSettings,
                },
            })
            .afterClosed()
            .subscribe({
                next: (res) => {
                    if (res?.needsToBeUpdated) {
                        this.reload$.next(true);
                    }
                },
            });
    }

    private _checkIfHasRoiInsights(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this._roiService.roiInsightsCreationState$])
            .pipe(
                filter(([restaurant, _]) => isNotNil(restaurant)),
                switchMap(([restaurant]) => this._roiService.checkHasInsights(restaurant!._id))
            )
            .subscribe({
                next: (res) => this.hasRoiInsights.set(res.data),
            });
    }

    private _fetchLastRoiSettings(): void {
        combineLatest([this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil)), this.reload$])
            .pipe(
                tap(() => this.isLoading.set(true)),
                switchMap(([restaurant]) => {
                    const monthsSinceCreation = numberOfMonthsSinceGivenDate(new Date(restaurant.createdAt));
                    this.isRecentRestaurant.set(monthsSinceCreation < this.MIN_MONTHS_TO_SHOW_HALF_YEAR_OF_INSIGHTS);
                    return this.roiContext.getRoiSettings(restaurant._id);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: () => this.isLoading.set(false),
                error: () => this.isLoading.set(false),
            });
    }

    private _initCreationStartDateAndEstimationTime(): void {
        combineLatest([this._roiService.roiInsightsCreationState$, this._restaurantsService.restaurantSelected$])
            .pipe(filter(([_, restaurant]) => isNotNil(restaurant)))
            .subscribe({
                next: ([creationCurrentState, restaurant]: [
                    {
                        [restaurantId: string]: RoiInsightsCreationState;
                    },
                    Restaurant,
                ]) => {
                    this.creationStartDate.set(creationCurrentState[restaurant._id]?.creationStartDate ?? null);
                    this.creationEstimatedTime.set(creationCurrentState[restaurant._id]?.creationEstimatedTime ?? Number.MAX_SAFE_INTEGER);
                },
            });
    }
}
