import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Subject, takeUntil } from 'rxjs';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { SearchComponent } from ':shared/components/search/search.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { KillSubscriptions } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import * as MessageTemplatesActions from '../store/message-templates.actions';
import {
    selectMessageTemplatesCount,
    selectMessageTemplatesSortBy,
    selectMessageTemplatesSortOrder,
} from '../store/message-templates.selectors';

@Component({
    selector: 'app-message-template-actions-header',
    templateUrl: './message-template-actions-header.component.html',
    styleUrls: ['./message-template-actions-header.component.scss'],
    standalone: true,
    imports: [
        SearchComponent,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        SortByFiltersComponent,
        MatTooltipModule,
        MatMenuModule,
        AsyncPipe,
        TranslateModule,
    ],
})
@AutoUnsubscribeOnDestroy()
export class MessageTemplateActionsHeaderComponent implements OnInit, KillSubscriptions {
    @Input() hasSelectedTemplates: boolean;
    @Output() searchChange: EventEmitter<string> = new EventEmitter();
    @Output() duplicateSelected: EventEmitter<string> = new EventEmitter();
    @Output() deleteSelected: EventEmitter<void> = new EventEmitter();
    @Output() clickOnInputFile: EventEmitter<void> = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject();

    readonly DuplicationDestination = DuplicationDestination;

    readonly sortBy$ = this._store.select(selectMessageTemplatesSortBy);
    readonly sortOrder$ = this._store.select(selectMessageTemplatesSortOrder).pipe(map((order) => (order === 'asc' ? 1 : -1)));

    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'name', label: this._translate.instant('templates.review.filters.name') },
        { key: 'text', label: this._translate.instant('templates.review.filters.text') },
    ];

    showAdvancedFilters = false;
    filterCount = 0;
    templateCount = 0;

    constructor(
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    ngOnInit(): void {
        this._store
            .select(selectMessageTemplatesCount)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((count) => (this.templateCount = count));
    }

    onSearch(search: string): void {
        this.searchChange.emit(search);
    }

    duplicate(duplicationDestination: string): void {
        this.duplicateSelected.emit(duplicationDestination);
    }

    delete(): void {
        this.deleteSelected.emit();
    }

    clickOnInput(): void {
        this.clickOnInputFile.emit();
    }

    onSortByChange(sortBy: string): void {
        this._store.dispatch(MessageTemplatesActions.editMessageTemplatesSort({ sortBy }));
    }

    onSortOrderChange(order: number): void {
        const sortOrder = order === 1 ? 'asc' : 'desc';

        this._store.dispatch(MessageTemplatesActions.editMessageTemplatesSort({ sortOrder }));
    }

    toggleAdvancedFilters(): void {
        this.showAdvancedFilters = !this.showAdvancedFilters;
    }
}
