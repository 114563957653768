import { Routes } from '@angular/router';

import { AUTH_ROUTES } from ':core/auth/auth.routing';
import { CampaignNegativeReviewComponent } from ':core/components/campaign-negative-review/campaign-negative-review.component';
import { EmailVerificationFailureComponent } from ':core/components/email-verification-failure/email-verification-failure.component';
import { EmailVerificationSuccessComponent } from ':core/components/email-verification-success/email-verification-success.component';
import { PageNotFoundComponent } from ':core/components/page-not-found/page-not-found.component';
import { ReviewsCopyClipboardComponent } from ':core/components/reviews-copy-clipboard/reviews-copy-clipboard.component';
import { UnsubscribeToCampaignsComponent } from ':core/components/unsubscribe-to-campaigns/unsubscribe-to-campaigns.component';
import { MaintenanceComponent } from ':core/maintenance/maintenance.component';
import { GetMyGiftComponent } from ':modules/get-my-gift/get-my-gift.component';
import { PlayWheelOfFortuneRootComponent } from ':modules/play-wheel-of-fortune/play-wheel-of-fortune-root.component';
import { WheelOfFortuneNotFoundComponent } from ':modules/play-wheel-of-fortune/wheel-of-fortune-not-found/wheel-of-fortune-not-found.component';
import { WheelOfFortuneRedirectComponent } from ':modules/play-wheel-of-fortune/wheel-of-fortune-redirect/wheel-of-fortune-redirect.component';
import { WheelOfFortuneRulesComponent } from ':modules/play-wheel-of-fortune/wheel-of-fortune-rules/wheel-of-fortune-rules.component';
import { UnsubscribeReportV2Component } from ':modules/reports/unsubscribe-report-v2/unsubscribe-report-v2.component';
import { UnsubscribeReportComponent } from ':modules/reports/unsubscribe-report/unsubscribe-report.component';
import { ScanComponent } from ':modules/scan/scan.component';
import { WheelOfFortuneMessagesComponent } from ':modules/wheel-of-fortune-messages/wheel-of-fortune-messages.component';

export const LOGGED_OUT_ROUTES: Routes = [
    {
        path: 'auth',
        loadChildren: () => AUTH_ROUTES,
    },
    {
        path: 'unsubscribe_report',
        component: UnsubscribeReportComponent,
    },
    {
        path: 'unsubscribe_report_v2',
        component: UnsubscribeReportV2Component,
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
    },
    {
        path: 'unsubscribe_campaign',
        component: UnsubscribeToCampaignsComponent,
    },
    {
        path: 'campaign_review/give_review',
        component: CampaignNegativeReviewComponent,
    },
    {
        path: 'email_verification_success',
        component: EmailVerificationSuccessComponent,
    },
    {
        path: 'email_verification_failure',
        component: EmailVerificationFailureComponent,
    },
    {
        path: 'nfc',
        component: ScanComponent,
    },
    {
        path: 'wheel-of-fortune',
        component: PlayWheelOfFortuneRootComponent,
    },
    {
        path: 'get-my-gift/:gift_draw_id',
        component: GetMyGiftComponent,
    },
    {
        path: 'wheel-of-fortune-rules',
        component: WheelOfFortuneRulesComponent,
    },
    {
        path: 'wheel-of-fortune-redirect',
        component: WheelOfFortuneRedirectComponent,
    },
    {
        path: 'wheel-of-fortune-messages',
        component: WheelOfFortuneMessagesComponent,
    },
    {
        path: 'wheel-of-fortune-not-found',
        component: WheelOfFortuneNotFoundComponent,
    },
    {
        path: 'reviews-copy-clipboard',
        component: ReviewsCopyClipboardComponent,
    },
    { path: '**', component: PageNotFoundComponent },
];
