<div
    class="edit-modal-container md:h-full"
    [ngClass]="{
        'h-fit': !displayCloseModal() || (displayCloseModal() && isPhoneScreen()),
        'h-[350px]': displayCloseModal() && !isPhoneScreen(),
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal() ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal.set(false)">
    </app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header !py-5">
            <div class="title">
                <div class="malou-text-18--bold text-malou-color-text-1">
                    {{ 'restaurant_ai_settings.modals.upsert.title' | translate }}
                </div>
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body max-h-[60vh] md:!overflow-y-scroll">
            <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
        </div>

        <div class="malou-dialog__footer">
            <button class="malou-btn-raised--secondary--alt !h-11 md:grow" mat-raised-button [disabled]="isSubmitting()" (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>
            <button
                class="malou-btn-raised--primary !h-11 md:grow"
                id="tracking_restaurant_ai_settings_edit_validation_button"
                data-testid="general-ai-settings-save-btn"
                mat-raised-button
                [disabled]="isSubmitting() || !areRestaurantAiSettingsValid()"
                (click)="submit()">
                @if (isSubmitting()) {
                    <app-malou-spinner class="flex w-[93px] justify-center" size="small" color="white"></app-malou-spinner>
                } @else {
                    {{ 'common.save' | translate }}
                }
            </button>
        </div>
    </div>
</ng-template>

<ng-template #formTemplate>
    @if (restaurantAiSettings()) {
        <mat-tab-group
            class="h-full"
            animationDuration="5ms"
            mat-align-tabs="start"
            [selectedIndex]="selectedTabIndex()"
            (selectedIndexChange)="handleTabChange($event)">
            @for (tab of TABS(); track tab.key) {
                <mat-tab [labelClass]="'tracking_edit_custom_ai_settings_modal_' + tab.key + '_tab'">
                    <ng-template mat-tab-label>
                        <div class="malou-text-14--regular flex items-center text-malou-color-text-2">
                            <span class="tab-title" [class]="{ 'malou-text-14--semibold': selectedTab().key === tab.key }">{{
                                tab.label
                            }}</span>
                        </div>
                    </ng-template>
                    @if (selectedTab().key === tab.key) {
                        <div class="my-4 flex flex-col justify-between">
                            @switch (selectedTab().key) {
                                @case (TabKeys.GENERAL) {
                                    <app-general-ai-settings
                                        [(restaurantAiSettings)]="restaurantAiSettings"
                                        [disabled]="isSubmitting()"></app-general-ai-settings>
                                }
                                @case (TabKeys.ADVANCED) {
                                    <app-advanced-ai-settings
                                        [(restaurantAiSettings)]="restaurantAiSettings"
                                        [selectedKeywords]="selectedKeywords()"
                                        [disabled]="isSubmitting()"
                                        (close)="confirmClose()"></app-advanced-ai-settings>
                                }
                            }
                        </div>
                    }
                </mat-tab>
            }
        </mat-tab-group>
    }
</ng-template>
