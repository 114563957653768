import { z } from 'zod';

import { PlatformKey } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const createCredentialBodyValidator = z.object({
    key: z.nativeEnum(PlatformKey),
    userId: objectIdValidator,
    authId: z.string(),
    lastSeenWorking: z.string().optional(),
    organizationId: objectIdValidator.optional(),
    organizationIds: z.array(objectIdValidator).optional(),
    accessToken: z.string().optional(),
});
export type CreateCredentialBodyDto = z.infer<typeof createCredentialBodyValidator>;

// ------------------------------------------------------------------------------------------

export const patchCredentialParamsValidator = z.object({
    credentialId: objectIdValidator,
});
export type PatchCredentialParamsDto = z.infer<typeof patchCredentialParamsValidator>;

export const patchCredentialBodyValidator = z.object({
    key: z.nativeEnum(PlatformKey).optional(),
    userId: objectIdValidator.optional(),
    authId: z.string().optional(),
    lastSeenWorking: z.string().optional(),
    organizationId: objectIdValidator.optional(),
    organizationIds: z.array(objectIdValidator).optional(),
    accessToken: z.string().optional(),
});
export type PatchCredentialBodyDto = z.infer<typeof patchCredentialBodyValidator>;

// ------------------------------------------------------------------------------------------

export const deleteCredentialParamsValidator = z.object({
    credentialId: objectIdValidator,
});
export type DeleteCredentialParamsDto = z.infer<typeof deleteCredentialParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getPlatformPermissionsQueryValidator = z
    .object({
        platform_id: objectIdValidator,
    })
    .transform((data) => ({
        platformId: data.platform_id,
    }));
export type GetPlatformPermissionsQueryDto = z.infer<typeof getPlatformPermissionsQueryValidator>;

// ------------------------------------------------------------------------------------------

export const getPlatformPermissionsForUserRestaurantsParamsValidator = z
    .object({
        platform_key: z.nativeEnum(PlatformKey),
    })
    .transform((data) => ({
        platformKey: data.platform_key,
    }));
export type GetPlatformPermissionsForUserRestaurantsParamsDto = z.infer<typeof getPlatformPermissionsForUserRestaurantsParamsValidator>;
