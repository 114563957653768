import { FileFormat, MediaCategory, MediaConvertedStatus, MediaType, PictureSize } from '@malou-io/package-utils';

export interface MediaDto {
    id: string;
    restaurantId?: string;
    userId?: string;
    title?: string;
    name?: string;
    duplicatedFromRestaurantId?: string;
    category: MediaCategory;
    format: FileFormat;
    type: MediaType;
    urls: PictureSizeRecordDto<string>;
    sizes: PictureSizeRecordDto<number>;
    postIds?: string[];
    originalMediaId?: string;
    socialId?: string;
    convertedStatus?: MediaConvertedStatus;
    dimensions?: PictureSizeRecordDto<MediaDimensionDto>;
    duration?: number;
}

export interface PictureSizeRecordDto<T extends string | number | MediaDimensionDto> {
    [PictureSize.ORIGINAL]?: T;
    [PictureSize.SMALL]?: T;
    [PictureSize.COVER]?: T;
    [PictureSize.SMALL_COVER]?: T;
    [PictureSize.IG_FIT]?: T;
}

export interface MediaDimensionDto {
    width: number;
    height: number;
}

export interface GetVideoInformationResponseDto {
    height: number;
    width: number;
    durationInSeconds: number;
}
