import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformKey } from '@malou-io/package-utils';

import { ChatbotService } from ':core/services/chatbot.service';
import { GoToStepParam } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CreateArrayPipe } from ':shared/pipes/create-array.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

@Component({
    selector: 'app-platforms-connection-stepper',
    templateUrl: './platforms-connection-stepper.component.html',
    styleUrls: ['./platforms-connection-stepper.component.scss'],
    standalone: true,
    imports: [RouterOutlet, NgTemplateOutlet, PlatformLogoPathResolverPipe, MatIconModule, CreateArrayPipe, NgClass, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformsConnectionStepperComponent {
    private readonly _chatbotService = inject(ChatbotService);

    readonly SvgIcon = SvgIcon;

    title = input.required<string>();
    subtitle = input<string>();
    platformKeyIcon = input<PlatformKey>();
    steps = input.required<string[]>();
    currentStep = input.required<number>();
    goToStep = output<GoToStepParam>();

    paddingClass = computed(() => {
        const stepsCount = this.steps().length;
        if (stepsCount <= 4) {
            return 'py-6';
        } else if (stepsCount <= 6) {
            return 'py-4';
        } else {
            return 'py-2';
        }
    });

    onContactCLick(): void {
        this._chatbotService.show();
    }

    onStepClick(index: number): void {
        if (this.currentStep() <= index) {
            return;
        }
        this.goToStep.emit({ type: 'absolute', value: index });
    }
}
