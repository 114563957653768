import { NgTemplateOutlet } from '@angular/common';
import { Component, effect, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsAverageRatingWithRange } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import * as StatisticsSelectors from '../../store/statistics.selectors';

@Component({
    selector: 'app-review-ratings-average-kpi',
    templateUrl: './review-ratings-average-kpi.component.html',
    styleUrls: ['./review-ratings-average-kpi.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        NumberEvolutionComponent,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        StatisticsHttpErrorPipe,
    ],
})
export class ReviewRatingsAverageKpiComponent implements OnInit, KillSubscriptions {
    @Output() hasDataChange = new EventEmitter<boolean>();
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;

    currentAverageRating?: number;
    diffAverageRating?: number;

    httpError: any;
    isLoading = signal(true);

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store,
        private readonly _reviewsService: ReviewsService
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        combineLatest([this.dates$, this.platformKeys$, this._restaurantsService.restaurantSelected$])
            .pipe(
                filter(
                    ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]) =>
                        isNotNil(restaurant) && isDateSetOrGenericPeriod(dates) && platforms.length > 0
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]): Observable<
                        [ReviewsAverageRatingWithRange, ReviewsAverageRatingWithRange]
                    > => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;

                        return forkJoin([
                            this._reviewsService.getRestaurantsReviewsAverageChartData([restaurantId], platforms, startDate, endDate).pipe(
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            this._reviewsService
                                .getRestaurantsReviewsAverageChartData([restaurantId], platforms, startDate, endDate, true)
                                .pipe(
                                    catchError((error) => {
                                        this.httpError = error;
                                        this.hasDataChange.emit(false);
                                        this.isLoading.set(false);
                                        return EMPTY;
                                    })
                                ),
                        ]);
                    }
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(
                ([currentReviewsAverageRating, previousReviewsAverageRating]: [
                    ReviewsAverageRatingWithRange,
                    ReviewsAverageRatingWithRange,
                ]) => {
                    this.currentAverageRating = this._computeCurrentAverageRating(currentReviewsAverageRating);
                    this.diffAverageRating = this._computeDiffAverageRating(this.currentAverageRating, previousReviewsAverageRating);

                    this.isLoading.set(false);
                }
            );
    }

    private _computeCurrentAverageRating(currentReviewsAverageRating: ReviewsAverageRatingWithRange): number | undefined {
        return currentReviewsAverageRating?.results?.[0]?.averageRating;
    }

    private _computeDiffAverageRating(
        currentAverageRating: number | undefined,
        previousReviewsAverageRating: ReviewsAverageRatingWithRange
    ): number | undefined {
        const previousAverageRating = previousReviewsAverageRating?.results?.[0]?.averageRating;
        if (currentAverageRating && previousAverageRating) {
            return currentAverageRating - previousAverageRating;
        }
    }

    private _reset(): void {
        this.httpError = undefined;
        this.isLoading.set(true);
    }
}
