import { PlatformKey } from '@malou-io/package-utils';

const defaultOrder = [
    PlatformKey.GMB,
    PlatformKey.FACEBOOK,
    PlatformKey.INSTAGRAM,
    PlatformKey.TRIPADVISOR,
    PlatformKey.YELP,
    PlatformKey.LAFOURCHETTE,
    PlatformKey.FOURSQUARE,
    PlatformKey.PAGESJAUNES,
    PlatformKey.ZENCHEF,
    PlatformKey.MAPSTR,
    PlatformKey.DELIVEROO,
    PlatformKey.UBEREATS,
    PlatformKey.PRIVATE,
];

export function getSortedPlatformKeys(platformKeys: PlatformKey[], order: PlatformKey[] = defaultOrder): PlatformKey[] {
    return platformKeys.sort((a, b) => order.indexOf(a) - order.indexOf(b));
}
