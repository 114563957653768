import { NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { formatPhoneNumber, platformsKeys } from '@malou-io/package-utils';

import { ComparisonRowComponent } from ':shared/components/comparison-row/comparison-row.component';
import { Comparison, ComparisonKey, DISPLAYED_NULLISH_VALUE, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

const DEFAULT_COUNTRY_CODE = 'FR';
@Component({
    selector: 'app-information-suggestions-modal',
    templateUrl: './information-suggestions-modal.component.html',
    styleUrls: ['./information-suggestions-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule, NgTemplateOutlet, ComparisonRowComponent],
})
export class InformationSuggestionModalComponent {
    readonly SvgIcon = SvgIcon;
    comparisons: Comparison[];

    displayCloseModal = false;
    isClosed = false;
    platformName = platformsKeys.GMB.fullName;

    constructor(
        public dialogRef: MatDialogRef<InformationSuggestionModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            restaurant: Restaurant;
            comparisons: Comparison[];
        }
    ) {
        this.comparisons = data.comparisons;
    }

    cancel(shouldReject: boolean): void {
        if (shouldReject) {
            this.dialogRef.close(this.acceptOrReject('reject'));
            return;
        }
        this.dialogRef.close();
    }

    save(): void {
        this.dialogRef.close(this.acceptOrReject('accept'));
    }

    acceptOrReject(acceptReject: 'accept' | 'reject'): Record<string, any> {
        const acceptRejectToComparisonKey = {
            accept: 'compared',
            reject: 'reference',
        };
        return this.comparisons.reduce((acc, comp) => {
            const value = comp[acceptRejectToComparisonKey[acceptReject]];
            // should handle nullish value and mapped values in platform-comparison service
            acc[comp.key] = value === DISPLAYED_NULLISH_VALUE ? null : this._mapToRestaurantValue(comp.key, value);
            return acc;
        }, {});
    }

    private _mapToRestaurantValue(key: ComparisonKey, value: any): any {
        switch (key) {
            case ComparisonKey.PHONE:
                return formatPhoneNumber(value, this.data.restaurant.address?.regionCode ?? DEFAULT_COUNTRY_CODE);
            default:
                return value;
        }
    }
}
