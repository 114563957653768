<div class="malou-text-12--regular flex h-[25px]">
    <p>{{ defaultText() }}</p>
    @if (displayAnimation()) {
        <div class="text-slider" [@textAnimation]="slideTextList().length" (@textAnimation.done)="onAnimationDone()">
            @for (text of slideTextList(); track text) {
                <p>{{ text }}...</p>
            }
        </div>
    }
</div>
