import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ChangeDetectionStrategy, Component, computed, inject, input, model, OnInit, output, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { DefaultPrompt, OpenaiPromptComponent } from ':shared/openai-prompt/openai-prompt.component';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

import { TextAreaComponent } from '../text-area/text-area.component';

export enum PostCaptionDisplayState {
    OPEN = 'open',
    CLOSED = 'closed',
}

@Component({
    selector: 'app-post-caption-ai-generation',
    templateUrl: './post-caption-ai-generation.component.html',
    styleUrls: ['./post-caption-ai-generation.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatIconModule,
        TranslateModule,
        TextAreaComponent,
        OpenaiPromptComponent,
        PlatformLogoPathResolverPipe,
        CdkTextareaAutosize,
        MatButtonModule,
        ReactiveFormsModule,
        ApplyPurePipe,
        MalouSpinnerComponent,
    ],
})
export class PostCaptionAiGenerationComponent implements OnInit {
    readonly displayState = input.required<PostCaptionDisplayState>();
    readonly defaultPrompt = input.required<DefaultPrompt>();
    readonly promptFormControl = input(new FormControl<string>(''));
    readonly isSmallScreen = input(false);
    readonly onClose = output<void>();
    readonly onGeneratePromptClick = output<void>();
    readonly lastPrompt = model('');
    readonly isGenerating = model(false);
    readonly isSeo = input.required<boolean>();

    readonly translate = inject(TranslateService);

    readonly SvgIcon = SvgIcon;
    readonly isOpen = computed(() => this.displayState() === PostCaptionDisplayState.OPEN);
    readonly BasePrompt = DefaultPrompt;

    readonly PROMPT_IDEAS = {
        [DefaultPrompt.INTRODUCE_DISH]: this.translate.instant('openai.introduce_dish_post'),
        [DefaultPrompt.ANNOUNCE_EVENT]: this.translate.instant('openai.announce_event'),
        [DefaultPrompt.WRITE_CONTEST]: this.translate.instant('openai.contest_post'),
    };

    readonly selectedPromptIndex = signal(
        Object.keys(this.PROMPT_IDEAS).findIndex((key) => this.promptFormControl().value?.trim().includes(this.PROMPT_IDEAS[key].trim()))
    );

    ngOnInit(): void {
        this.promptFormControl().valueChanges.subscribe((value: string) => {
            this.lastPrompt.update(() => value);
            this.selectedPromptIndex.update(() =>
                Object.keys(this.PROMPT_IDEAS).findIndex((key) => value.trim().includes(this.PROMPT_IDEAS[key].trim()))
            );
        });
    }

    close(): void {
        this.onClose.emit();
    }

    generatePrompt(): void {
        this.isGenerating.set(true);

        this.onGeneratePromptClick.emit();
        if (this.isSmallScreen()) {
            this.close();
        }
    }

    getObjectKeys = (obj: object): string[] => Object.keys(obj);

    onPromptIdeaClick(promptKey: string): void {
        const prompt = this.PROMPT_IDEAS[promptKey];
        this.promptFormControl().setValue(prompt);
        this.lastPrompt.update(() => prompt);
    }
}
