import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DAYS } from '@malou-io/package-utils';

import { TimePeriod } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';

interface RestaurantHours {
    [key: string]: TimePeriod[];
}

@Component({
    selector: 'app-restaurant-business-hours',
    templateUrl: './restaurant-business-hours.component.html',
    styleUrls: ['./restaurant-business-hours.component.scss'],
    standalone: true,
    imports: [TitleCasePipe, TranslateModule, EnumTranslatePipe, FormatTimePipe],
})
export class RestaurantBusinessHoursComponent implements OnChanges {
    @Input() businessHours: TimePeriod[] = [];
    @Input() withIcon = true;

    readonly DAYS = DAYS;
    public restaurantHours: RestaurantHours = {};

    ngOnChanges(): void {
        this.restaurantHours = {};
        for (const timePeriod of this.businessHours) {
            if (!(timePeriod.openDay in this.restaurantHours)) {
                this.restaurantHours[timePeriod.openDay] = [];
            }
            this.restaurantHours[timePeriod.openDay].push(timePeriod);
        }
    }
}
