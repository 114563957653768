export class Interaction {
    id: string;
    text: string;
    isAiInteraction: boolean;
    originalInteractionId?: string;

    static getUpdatedInteractions(interactions: Interaction[], newInteraction: Interaction): Interaction[] | undefined {
        if (!newInteraction.text.length) {
            return;
        }
        if (interactions.map((i) => i.text.trim()).includes(newInteraction.text.trim())) {
            return;
        }

        if (newInteraction.isAiInteraction) {
            return interactions
                .filter((int) => int.isAiInteraction)
                .concat(newInteraction)
                .concat(interactions.filter((int) => !int.isAiInteraction));
        }

        const interactionIndex = interactions.findIndex((int) => int.originalInteractionId === newInteraction.originalInteractionId);
        if (interactionIndex !== -1) {
            interactions[interactionIndex] = newInteraction;
            return [...interactions];
        }
        return interactions.concat(newInteraction);
    }
}
