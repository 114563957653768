<div class="cursor-move">
    <div class="flex w-full items-center">
        <mat-icon class="mr-1" color="primary" [svgIcon]="SvgIcon.DRAGGABLE"></mat-icon>
        <div class="mr-2">
            <app-social-post-media
                class="w-auto"
                [customMediaClass]="'!w-[75px] !h-[75px] !rounded-[10px]'"
                [postType]="post.postType"
                [workingMedia]="post.getMalouMediaUrl('small')"
                [firstAttachmentType]="post.getFirstMediumType()"
                [showVideoControls]="false"
                [thumbnailUrl]="post.getSocialMediaThumbnail()"
                (loadedVideoMetadata)="onLoadedVideoMetadata($event)">
            </app-social-post-media>
        </div>
        @if (mediaDuration || warningText) {
            <div class="flex flex-col">
                @if (warningText) {
                    <div
                        class="malou-text-10 malou-bg-state-warn--light flex items-center rounded p-1 px-2 italic text-malou-color-state-warn">
                        <mat-icon class="mr-1 !h-4 !w-4" [svgIcon]="SvgIcon.WARNING"></mat-icon>
                        {{ warningText }}
                    </div>
                }
                @if (mediaDuration) {
                    <div>
                        <app-media-duration [duration]="mediaDuration" [post]="post"></app-media-duration>
                        <app-story-video-error class="malou-color-warning" [videoValidity]="videoValidity"></app-story-video-error>
                    </div>
                }
            </div>
        }
        <button
            class="malou-btn-icon--secondary btn-xl !ml-auto mr-3.5"
            appDnd
            mat-icon-button
            (click)="deletePost(); $event.stopPropagation()">
            <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
        </button>
    </div>
</div>
