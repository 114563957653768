<ng-container *ngTemplateOutlet="hasFetchedTemplates ? page : loading"></ng-container>

<ng-template #page>
    <div class="flex h-full flex-col">
        <ng-container *ngTemplateOutlet="actionHeader"></ng-container>

        @if (dataSource.filteredData.length > 0) {
            <div class="min-h-0 grow overflow-y-auto px-7.5">
                <ng-container *ngTemplateOutlet="screenSizeService.isPhoneScreen ? mobileList : desktopTable"></ng-container>
            </div>
        } @else {
            <div class="grid h-[65vh] w-full place-items-center">
                <div class="flex w-full flex-col items-center gap-y-6">
                    <img alt="Cook illustration" height="100" width="100" [lazyLoad]="Illustration.Cook | illustrationPathResolver" />
                    <div class="malou-text-14--bold malou-color-text-1 max-w-[70%] text-center">
                        {{
                            hasSearch
                                ? ('templates.message.no_templates_with_filters.title' | translate)
                                : ('templates.message.no_templates.title' | translate)
                        }}
                        <div class="malou-text-10--regular mt-3 text-center">
                            {{ hasSearch ? '' : ('templates.message.no_templates.subtitle' | translate) }}
                        </div>
                    </div>
                    @if (!hasSearch) {
                        <button class="malou-btn-raised--primary" mat-raised-button (click)="openEditTemplateModal()">
                            {{ 'templates.message.no_templates.add_template' | translate }}
                        </button>
                    }
                </div>
            </div>
        }
        <app-scroll-to-top
            class="fixed right-7.5"
            [ngClass]="{
                'bottom-8': footersVisibilityCount === 0,
                'bottom-24': footersVisibilityCount >= 1,
            }"
            [container]="SCROLL_CONTAINER_SELECTOR">
        </app-scroll-to-top>
    </div>
</ng-template>

<ng-template #actionHeader>
    <div class="px-7.5">
        <app-message-template-actions-header
            [hasSelectedTemplates]="selection.selected.length > 0"
            (searchChange)="onSearchChange($event)"
            (duplicateSelected)="onDuplicateSelected($event)"
            (deleteSelected)="onDeleteSelected()"
            (clickOnInputFile)="onClickOnInputFile()"></app-message-template-actions-header>
    </div>
</ng-template>

<ng-template #mobileList>
    <div class="my-5 flex w-full flex-col gap-y-2">
        <div class="malou-text-13--semibold malou-color-text-1 pl-5">
            <app-noop-mat-checkbox
                color="primary"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                (click)="$event ? toggleAllFiltered() : null">
            </app-noop-mat-checkbox>
            <span class="ml-4">{{ 'templates.message.select_all' | translate }}</span>
        </div>
        @for (template of dataSource.filteredData; track trackByIdFn(i, template); let i = $index) {
            <div
                class="malou-card !m-0 !flex-none cursor-pointer gap-y-5 !p-5"
                [class.selected]="selection.isSelected(template)"
                [class.duplicated]="duplicatedTemplateIds | includes: template._id"
                [id]="template._id"
                (click)="$event.stopPropagation(); selection.toggle(template); selectMultiple($event, i, template)">
                <div class="flex gap-x-4">
                    <ng-container [ngTemplateOutlet]="checkboxTemplate" [ngTemplateOutletContext]="{ template, i }"></ng-container>
                    <div class="mt-1 flex flex-col gap-y-2">
                        <div class="malou-text-13--semibold malou-color-text-1 mr-6">{{ template.name || '-' }}</div>
                        <div class="malou-text-12--regular">{{ replaceVariableTextToChipText | applyPure: template.text }}</div>
                    </div>
                </div>
                <div class="absolute right-3 top-3" (click)="$event.stopPropagation()">
                    <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                    </button>
                    <mat-menu class="malou-mat-menu malou-box-shadow" #actionsMenu="matMenu">
                        <ng-container [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
                    </mat-menu>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #desktopTable>
    <mat-table
        class="malou-mat-table my-5"
        matSort
        matSortDisableClear
        matSortDirection="asc"
        matSortActive="name"
        aria-label="Templates table"
        [dataSource]="dataSource"
        #table="matTable">
        <ng-container [matColumnDef]="MessageTemplateColumns.SELECT">
            <mat-header-cell *matHeaderCellDef class="column-checkbox">
                <app-noop-mat-checkbox
                    color="primary"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    (click)="$event ? toggleAllFiltered() : null">
                </app-noop-mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let template; table: table; let i = index">
                <ng-container [ngTemplateOutlet]="checkboxTemplate" [ngTemplateOutletContext]="{ template, i }"></ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="MessageTemplateColumns.NAME">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'templates.wording' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let template; table: table" class="truncate" [matTooltip]="template.name || '-'" #templateName>
                <div class="malou-text-13--semibold truncate !text-malou-color-text-1">
                    {{ template.name || '-' }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="MessageTemplateColumns.TEXT">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xxlarge" mat-sort-header>
                {{ 'templates.text' | translate }}
            </mat-header-cell>
            <mat-cell
                *matCellDef="let template; table: table"
                class="malou-mat-table-cell-xxlarge"
                [matTooltip]="replaceVariableTextToChipText | applyPure: template.text">
                <div class="truncate">
                    {{ replaceVariableTextToChipText | applyPure: template.text }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="MessageTemplateColumns.ACTIONS">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-fit-content"></mat-header-cell>
            <mat-cell
                *matCellDef="let template; table: table"
                class="column-more malou-mat-table-cell-fit-content pr-5"
                (click)="$event.stopPropagation()">
                <div class="flex">
                    <div class="flex h-full w-full justify-end" [matMenuTriggerFor]="actionsMenu">
                        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                    </div>
                    <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                        <ng-container [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ template }"></ng-container>
                    </mat-menu>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
            *matRowDef="let template; columns: displayedColumns; let i = index"
            class="row"
            [id]="template._id"
            [class.selected]="selection.isSelected(template) || (duplicatedTemplateIds | includes: template._id)"
            [class.duplicated]="duplicatedTemplateIds | includes: template._id"
            (click)="$event.stopPropagation(); selection.toggle(template); selectMultiple($event, i, template)"></mat-row>
    </mat-table>
</ng-template>

<ng-template let-template="template" let-i="i" #checkboxTemplate>
    <app-noop-mat-checkbox
        color="primary"
        [checked]="selection.isSelected(template)"
        (click)="$event.stopPropagation(); selectMultiple($event, i, template)">
    </app-noop-mat-checkbox>
</ng-template>

<ng-template let-template="template" #actionsTemplate>
    <button class="custom-menu-item" data-cy="editTemplateBtn" mat-menu-item (click)="openEditTemplateModal(template)">
        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
        {{ 'common.edit' | translate }}
    </button>
    <button
        class="malou-menu-item malou-menu-submenu-icon"
        data-cy="duplicateTemplateBtn"
        mat-menu-item
        [matMenuTriggerFor]="duplicateActions">
        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
        {{ 'common.duplicate' | translate }}
    </button>

    <mat-menu class="malou-mat-menu" #duplicateActions="matMenu">
        <button class="malou-menu-item" mat-menu-item (click)="duplicateTemplates(DuplicationDestination.HERE, template)">
            {{ 'templates.review.actions.duplicate_here' | translate }}
        </button>
        <button class="malou-menu-item" mat-menu-item (click)="duplicateTemplates(DuplicationDestination.OUT, template)">
            {{ 'common.to_other_venues' | translate }}
        </button>
    </mat-menu>
    <button class="malou-menu-item" data-cy="deleteTemplateBtn" mat-menu-item (click)="deleteTemplatesByIds([template._id])">
        <mat-icon class="!h-4 !w-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
        {{ 'common.delete' | translate }}
    </button>
</ng-template>

<ng-template #loading>
    <div class="h-full overflow-y-auto px-7.5">
        <div class="w-full">
            <app-skeleton skeletonClass="secondary-bg h-[50px] w-full"></app-skeleton>
        </div>

        <div class="mt-7 flex w-full justify-between px-7 md:hidden">
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[8vw] mr-3"></app-skeleton>
            <div class="w-[10vh]"></div>
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[8vw] mr-3"></app-skeleton>
            <div class="w-[10vh]"></div>
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[8vw] mr-3" [count]="2"></app-skeleton>
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[10vw] mr-3"></app-skeleton>
        </div>

        <div class="mt-3 w-full">
            <app-skeleton skeletonClass="secondary-bg h-[50px] md:h-[100px] w-full" [count]="9"></app-skeleton>
        </div>
    </div>
</ng-template>
