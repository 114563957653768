@if (isLoading()) {
    <app-skeleton skeletonClass="!h-36 secondary-bg"></app-skeleton>
} @else {
    @if (!httpError) {
        @if (currentAverageRating) {
            <div class="malou-simple-card flex h-36 w-64 flex-col justify-center !bg-malou-color-background-light px-6 py-2 lg:h-fit">
                <div class="flex flex-col justify-center gap-4 lg:flex-row lg:items-center lg:justify-start">
                    <div class="flex items-center justify-between">
                        <div class="malou-background-gradient-5 flex h-12.5 w-12.5 shrink-0 items-center justify-center rounded-full">
                            <mat-icon class="malou-color-white !h-4 !w-4" [svgIcon]="SvgIcon.STAR_BORDER"></mat-icon>
                        </div>
                    </div>
                    <div class="flex flex-col lg:grow">
                        <div class="flex items-center justify-between lg:justify-between">
                            <span class="malou-text-40--bold malou-color-text-1 mr-2 leading-none">{{
                                currentAverageRating | shortNumber
                            }}</span>
                            @if (currentAverageRating) {
                                <app-number-evolution
                                    [value]="diffAverageRating ?? null"
                                    [displayedValue]="
                                        diffAverageRating | shortNumber: { shouldDisplayMinusSign: false }
                                    "></app-number-evolution>
                            }
                        </div>
                        <span class="malou-text-12--medium malou-color-text-2 whitespace-nowrap">{{
                            'statistics.e_reputation.reviews_kpis.average_reviews_rating' | translate
                        }}</span>
                    </div>
                </div>
            </div>
        } @else {
            <div class="mt-[-20px] flex h-36 flex-col items-center">
                <img class="mb-3 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
                <span class="malou-text-10--regular">{{ 'statistics.e_reputation.reviews_kpis.no_data' | translate }}</span>
            </div>
        }
    } @else {
        <div class="mt-[-20px] flex h-36 flex-col items-center">
            <img class="mb-3 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
            <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
        </div>
    }
}
