import { PlatformUpdateTime } from '../../constants/platforms';

export enum YextAddRequestStatus {
    SUBMITTED = 'SUBMITTED',
    PROCESSING = 'PROCESSING',
    COMPLETE = 'COMPLETE',
    CANCELED = 'CANCELED',
    REVIEW = 'REVIEW',
    FAILED = 'FAILED',
}

export enum YextPublisherId {
    '118_000_FR' = '118000FR',
    '192_COM' = '192COM',
    '8_COUPONS' = '8COUPONS',
    ACOMPIO_DE = 'ACOMPIO',
    ACOMPIO_FR = 'ACOMPIOFR',
    ACOMPIO_UK = 'ACOMPIOUK',
    AMAZON_ALEXA = 'ALEXA',
    AMERICAN_EXPRESS = 'AMERICANEXPRESS',
    AMERICAN_TOWNS = 'AMERICANTOWNSCOM',
    APPLE = 'APPLE',
    AROUND_ME = 'AROUNDME',
    AUSKUNFT = 'AUSKUNFT',
    BETTER_BUSINESS_BUREAU = 'BETTERBUSINESSBUREAU',
    BING = 'BING',
    BRANCHENBUCH = 'BRANCHENBUCH',
    BRAVE = 'BRAVE',
    BROWN_BOOK_NET = 'BROWNBOOKNET',
    BRUNCH_LUNCH_DINNER = 'BRUNCHLUNCHDINNER',
    BUNDES_TELEFONBUCH = 'BUNDESTELEFONBUCH',
    CENTRAL_INDEX = 'CENTRALINDEX',
    CENTRAL_INDEX_IE = 'CENTRALINDEXIE',
    CENTRAL_INDEX_US = 'CENTRALINDEXUS',
    CHAMBER_OF_COMMERCE = 'CHAMBEROFCOMMERCECOM',
    CITY_SEARCH = 'CITYSEARCH',
    CITY_SQUARES = 'CITYSQUARES',
    CYLEX = 'CYLEX',
    DAS_OERTLICHE = 'DASOERTLICHE',
    DIALO = 'DIALO',
    DOORDASH = 'DOORDASH',
    DUN_AND_BRADSTREET = 'DUNANDBRADSTREET',
    E_LOCAL = 'ELOCAL',
    ENIRO = 'ENIRO',
    EZ_LOCAL = 'EZLOCAL',
    FINDERR = 'FINDERR',
    FOURSQUARE = 'FOURSQUARE',
    GELBE_SEITEN = 'GELBESEITEN',
    GO_LOCAL = 'GOLOCAL',
    GO_LOCAL_247 = 'GOLOCAL247',
    GO_YELLOW = 'GOYELLOW',
    GOLDEN_PAGES_IE = 'GOLDENPAGESIE',
    GOUDENGIDS = 'GOUDENGIDS',
    GOUDENGIDS_NL = 'GOUDENGIDSNL',
    GULE_SIDER = 'GULESIDER',
    HELP_CH = 'HELPCH',
    HERE = 'HERE',
    HEROLD = 'HEROLD',
    HITTA = 'HITTA',
    HOODSPOT = 'LAPOSTE',
    HORAIRE = 'HORAIRE', // Not in GSheet and in publisher call but present in Yext website
    HORAIRES_D_OUVERTURE_24 = 'HORAIRESDOUVERTURE24',
    HOST_ME = 'HOSTME',
    HOT_FROG = 'HOTFROG',
    HOURS_COM = 'HOURSCOM',
    I_BEGIN = 'IBEGIN',
    I_GLOBAL = 'IGLOBAL',
    IMONES_LT = 'IMONESLT',
    IN_YOUR_AREA = 'INYOURAREA',
    INFO_IS_INFO = 'INFOISINFO',
    INFOBEL = 'INFOBEL',
    INSIDER_PAGES = 'INSIDERPAGES',
    JUSTACOTE = 'JUSTACOTE',
    KOOMIO = 'KOOMIO',
    KRAK = 'KRAK',
    LOCAL_COM = 'LOCALCOM',
    LOCAL_STACK = 'LOCALSTACK',
    MAP_QUEST = 'MAPQUEST',
    MARKTPLATZ_MITTELSTAND = 'MARKTPLATZMITTELSTAND',
    MEINESTADT = 'MEINESTADT',
    MERCHANT_CIRCLE = 'MERCHANTCIRCLE',
    // MONEY_MAILER = 'MONEYMAILER', // No updateTime in GSheet
    MY_LOCAL_SERVICES = 'MYLOCALSERVICES',
    MY_LOCAL_SERVICES_UK = 'MYLOCALSERVICESUK',
    MY_TOWN_IE = 'MYTOWNIE',
    N_49 = 'N49CA',
    NAJISTO_CZ = 'NAJISTOCZ',
    NAVMII = 'NAVMII',
    NEXTDOOR = 'NEXTDOOR',
    NOCHOFFEN = 'NOCHOFFEN',
    OEFFNUNGSZEITEN_BUCH = 'OEFFNUNGSZEITENBUCH',
    OEFFNUNGSZEITEN_COM = 'OEFFNUNGSZEITENCOM',
    OPENDI = 'OPENDI',
    OPENDI_BE = 'OPENDIBE',
    OPENDI_DK = 'OPENDIDK',
    OPENDI_FR = 'OPENDIFR',
    OPENDI_LU = 'OPENDILU',
    OPENDI_NL = 'OPENDINL',
    OPENDI_NO = 'OPENDINO',
    OPENDI_PL = 'OPENDIPL',
    OPENDI_PT = 'OPENDIPT',
    OPENDI_RO = 'OPENDIRO',
    OPENDI_SE = 'OPENDISE',
    OPENDI_SI = 'OPENDISI',
    OPENDI_UK = 'OPENDIUK',
    OPENING_STIJDEN_NL = 'OPENINGSTIJDENNL',
    OPENING_TIMES_CO_UK = 'OPENINGTIMESCOUK',
    OPENTABLE = 'OPENTABLE',
    OPENUREN = 'OPENUREN', // Not in publisher call but in GSheet
    ORTSDIENST_DE = 'ORTSDIENSTDE',
    PAGES_24_CH = 'PAGES24CH',
    PAGES_24_FR = 'PAGES24FR',
    PAGES_24_NL = 'PAGES24NL',
    PETIT_FUTE = 'PETITFUTE',
    PHONE_BOOK_IE = 'EIRPHONEBOOKIE',
    PITNEY_BOWES = 'PITNEYBOWES',
    PROPERTY_CAPSULE = 'PROPERTYCAPSULE',
    SAFE_GRAPH = 'SAFEGRAPH',
    SEZNAM = 'SEZNAM',
    SHOW_ME_LOCAL = 'SHOWMELOCAL',
    SNAPCHAT = 'SNAPCHAT',
    SOLEO = 'SOLEO',
    STADTBRANCHENBUCH = 'STADTBRANCHENBUCH',
    STADTBRANCHENBUCH_CH = 'STADTBRANCHENBUCHCH',
    T_ONLINE = 'TONLINE',
    TELEPHONE_CITY = 'TELEPHONECITY',
    TELLOWS = 'TELLOWS',
    TOMTOM = 'TOMTOM',
    TOP_RATED_LOCAL = 'TOPRATEDLOCAL',
    TRIPADVISOR = 'TRIPADVISORREVIEWS',
    TRUSTPILOT = 'TRUSTPILOT',
    TUPALO = 'TUPALO',
    US_CITY_NET = 'USCITYNET',
    VISA_LIETUVA_LT = 'VISALIETUVALT',
    WESHOPLOCAL = 'WESHOPLOCAL',
    WHERE_TO = 'WHERETO',
    WOGIBTSWAS_AT = 'WOGIBTSWASAT',
    WOGIBTSWAS_DE = 'WOGIBTSWASDE',
    YAHOO = 'YAHOO',
    YALWA = 'YALWA',
    YANDEX = 'YANDEX',
    YELL = 'YELL',
    YELLOW_MOXIE = 'YELLOWMOXIE',
    YELLOW_PAGE_CITY = 'YELLOWPAGECITYCOM',
    YELLOW_PAGES_DIRECTORY = 'YELLOWPAGESGOESGREEN',
    YELLOWMAP = 'YELLOWMAP',
    YELP = 'YELP',
    YP_COM = 'YPCOM',
    ZLATE_STRANKY_CZ = 'ZLATESTRANKYCZ',
    ZLATE_STRANKY_SK = 'ZLATESTRANKYSK',
}

export enum UnsupportedYextPublisherId {
    ALL_MENUS = 'ALLMENUS', // No info in GSheet
    FACEBOOK = 'FACEBOOK',
    GMB = 'GOOGLEMYBUSINESS',
    NATIONALHEALTHSERVICE = 'NATIONALHEALTHSERVICE',
}

export enum YextFeature {
    DUAL_SYNC = 'DUAL_SYNC',
    SUPPRESSION = 'SUPPRESSION',
    SUPPRESSION_BY_URL = 'SUPPRESSION_BY_URL',
    PUBLISHER_SUGGESTIONS = 'PUBLISHER_SUGGESTIONS',
    REVIEW_MONITORING = 'REVIEW_MONITORING',
    ANALYTICS = 'ANALYTICS',
}

export const supportedYextFeatures: YextFeature[] = [YextFeature.DUAL_SYNC];

export interface YextPublisherConfig {
    name: string;
    logo: string;
    doesLogoNeedBorders: boolean;
    logoBackgroundColor?: string;
    updateTime: PlatformUpdateTime;
    updateFields: {
        name: boolean;
        phone: boolean;
        address: boolean;
        website: boolean;
        description: boolean;
        menuUrl: boolean;
        hours: boolean;
        holidayHours: boolean;
        temporaryClosed: boolean;
        logo: boolean;
        categories: boolean;
        cover: boolean;
    };
    displayPriority: number; // higher is better
}

export const yextPublishers: Record<YextPublisherId, YextPublisherConfig> = {
    [YextPublisherId['118_000_FR']]: {
        name: '118000.fr',
        logo: '118000-fr',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId['192_COM']]: {
        name: '192.com',
        logo: '192-com',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId['8_COUPONS']]: {
        name: '8coupons',
        logo: '8-coupons',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.ACOMPIO_DE]: {
        name: 'Acompio',
        logo: 'acompio',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: false,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.ACOMPIO_UK]: {
        name: 'Acompio UK',
        logo: 'acompio',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: false,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.ACOMPIO_FR]: {
        name: 'Acompio FR',
        logo: 'acompio',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: false,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.AMAZON_ALEXA]: {
        name: 'Amazon Alexa',
        logo: 'amazon-alexa',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: false,
            description: false,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 2,
    },
    [YextPublisherId.AMERICAN_EXPRESS]: {
        name: 'American Express',
        logo: 'american-express',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: false,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.AMERICAN_TOWNS]: {
        name: 'American Towns',
        logo: 'american-towns',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#005488',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            // All false, dont have info on GSheet
            name: false,
            phone: false,
            address: false,
            website: false,
            description: false,
            menuUrl: false,
            hours: false,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: false,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.APPLE]: {
        name: 'Apple',
        logo: 'apple',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#EDEDED',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: true,
            logo: false,
            categories: true,
            cover: true,
        },
        displayPriority: 3,
    },
    [YextPublisherId.AROUND_ME]: {
        name: 'AroundMe',
        logo: 'around-me',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.AUSKUNFT]: {
        name: 'Auskunft',
        logo: 'auskunft',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.BETTER_BUSINESS_BUREAU]: {
        name: 'Better Business Bureau',
        logo: 'better-business-bureau',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.DAILY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.BING]: {
        name: 'Bing',
        logo: 'bing',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: false,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 2,
    },
    [YextPublisherId.BRANCHENBUCH]: {
        name: 'Branchenbuch',
        logo: 'branchenbuch',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },

    [YextPublisherId.BRAVE]: {
        name: 'Brave',
        logo: 'brave',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.DAILY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.BROWN_BOOK_NET]: {
        name: 'Brownbook.net',
        logo: 'brown-book',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#313131',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.BRUNCH_LUNCH_DINNER]: {
        name: 'Brunch Lunch Dinner',
        logo: 'brunch-lunch-dinner',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.BUNDES_TELEFONBUCH]: {
        name: 'Bundes Telefonbuch',
        logo: 'bundes-telefonbuch',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.CENTRAL_INDEX]: {
        name: 'Central Index',
        logo: 'central-index',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.CENTRAL_INDEX_IE]: {
        name: 'Central Index IE',
        logo: 'central-index',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.CENTRAL_INDEX_US]: {
        name: 'Central Index US',
        logo: 'central-index',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.CHAMBER_OF_COMMERCE]: {
        name: 'Chamber of Commerce',
        logo: 'chamber-of-commerce',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.CITY_SEARCH]: {
        name: 'Citysearch',
        logo: 'city-search',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.CITY_SQUARES]: {
        name: 'City Squares',
        logo: 'city-squares',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.CYLEX]: {
        name: 'Cylex',
        logo: 'cylex',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.DAS_OERTLICHE]: {
        name: 'Das Örtliche',
        logo: 'das-oertliche',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.DIALO]: {
        name: 'Dialo',
        logo: 'dialo',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.DOORDASH]: {
        name: 'DoorDash',
        logo: 'doordash',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: false,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.DUN_AND_BRADSTREET]: {
        name: 'Dun & Bradstreet',
        logo: 'dun-bradstreet',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.DAILY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.E_LOCAL]: {
        name: 'eLocal',
        logo: 'elocal',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: false,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.ENIRO]: {
        name: 'Eniro',
        logo: 'eniro',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.EZ_LOCAL]: {
        name: 'EZlocal',
        logo: 'ez-local',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.FINDERR]: {
        name: 'Finderr',
        logo: 'finderr',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.FOURSQUARE]: {
        name: 'Foursquare',
        logo: 'foursquare',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: false,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.GELBE_SEITEN]: {
        name: 'Gelbe Seiten',
        logo: 'gelbe-seiten',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.GO_LOCAL]: {
        name: 'Go Local',
        logo: 'go-local',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.GO_LOCAL_247]: {
        name: 'GoLocal247',
        logo: 'go-local-247',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.GO_YELLOW]: {
        name: 'Go Yellow',
        logo: 'go-yellow',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.GOLDEN_PAGES_IE]: {
        name: 'Golden Pages IE',
        logo: 'goldenpages-ie',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.GOUDENGIDS]: {
        name: 'Goudengids',
        logo: 'goudengids',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.DAILY,
        logoBackgroundColor: '#ffffff',
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.GOUDENGIDS_NL]: {
        name: 'Goudengids NL',
        logo: 'goudengids',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.DAILY,
        logoBackgroundColor: '#ffffff',
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.GULE_SIDER]: {
        name: 'Gule Sider',
        logo: 'gule-sider',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HELP_CH]: {
        name: 'Help.ch',
        logo: 'help-ch',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HERE]: {
        name: 'Here',
        logo: 'here',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: false,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HEROLD]: {
        name: 'Herold',
        logo: 'herold',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HITTA]: {
        name: 'Hitta',
        logo: 'hitta',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.DAILY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HOODSPOT]: {
        name: 'Hoodspot',
        logo: 'hoodspot',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HORAIRE]: {
        name: 'Horaires.fr',
        logo: 'les-horaires',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: false,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HORAIRES_D_OUVERTURE_24]: {
        name: 'Horaires d’Ouverture 24',
        logo: 'horaires-d-ouverture-24',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HOST_ME]: {
        name: 'Host Me',
        logo: 'host-me',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HOT_FROG]: {
        name: 'HotFrog',
        logo: 'hot-frog',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.HOURS_COM]: {
        name: 'Hours.com',
        logo: 'hours-com',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: false,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.I_BEGIN]: {
        name: 'iBegin',
        logo: 'i-begin',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.I_GLOBAL]: {
        name: 'iGlobal',
        logo: 'i-global',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.IMONES_LT]: {
        name: 'Imones.lt',
        logo: 'imones',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.IN_YOUR_AREA]: {
        name: 'In Your Area',
        logo: 'in-your-area',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: false,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.INFOBEL]: {
        name: 'Infobel',
        logo: 'infobel',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#0086BF',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.INFO_IS_INFO]: {
        name: 'Info is info',
        logo: 'info-is-info',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.INSIDER_PAGES]: {
        name: 'Insider Pages',
        logo: 'insider-pages',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.JUSTACOTE]: {
        name: 'Justacote',
        logo: 'justacote',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.KOOMIO]: {
        name: 'Koomio',
        logo: 'koomio',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.KRAK]: {
        name: 'Krak',
        logo: 'krak',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#D80526',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.LOCAL_COM]: {
        name: 'Local.com',
        logo: 'local-com',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#8A8E90',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.LOCAL_STACK]: {
        name: 'LocalStack',
        logo: 'local-stack',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            // All false, dont have info on GSheet
            name: false,
            phone: false,
            address: false,
            website: false,
            description: false,
            menuUrl: false,
            hours: false,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: false,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.MAP_QUEST]: {
        name: 'MapQuest',
        logo: 'map-quest',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.MARKTPLATZ_MITTELSTAND]: {
        name: 'Marktplatz Mittelstand',
        logo: 'marktplatz-mittelstand',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.MEINESTADT]: {
        name: 'Meine Stadt',
        logo: 'meinestadt',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.MERCHANT_CIRCLE]: {
        name: 'MerchantCircle',
        logo: 'merchant-circle',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.MY_LOCAL_SERVICES]: {
        name: 'My Local Services',
        logo: 'my-local-services',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#FFCE04',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.MY_LOCAL_SERVICES_UK]: {
        name: 'My Local Services UK',
        logo: 'my-local-services',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#FFCE04',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.MY_TOWN_IE]: {
        name: 'My Town',
        logo: 'my-town',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },

    [YextPublisherId.N_49]: {
        name: 'n49',
        logo: 'n49',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.NAJISTO_CZ]: {
        name: 'Najisto',
        logo: 'najisto',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.NAVMII]: {
        name: 'Navmii',
        logo: 'navmii',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.NEXTDOOR]: {
        name: 'Nextdoor',
        logo: 'nextdoor',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.NOCHOFFEN]: {
        name: 'Nochoffen',
        logo: 'nochoffen',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OEFFNUNGSZEITEN_COM]: {
        name: 'Öffnungszeiten',
        logo: 'oeffnungszeiten',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OEFFNUNGSZEITEN_BUCH]: {
        name: 'Öffnungszeitenbuch',
        logo: 'oeffnungszeiten',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI]: {
        name: 'Opendi',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_UK]: {
        name: 'Opendi UK',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_BE]: {
        name: 'Opendi BE',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_DK]: {
        name: 'Opendi DK',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_FR]: {
        name: 'Opendi FR',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_NL]: {
        name: 'Opendi NL',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_NO]: {
        name: 'Opendi NO',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_PL]: {
        name: 'Opendi PL',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_SE]: {
        name: 'Opendi SE',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_LU]: {
        name: 'Opendi LU',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_PT]: {
        name: 'Opendi PT',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_RO]: {
        name: 'Opendi RO',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENDI_SI]: {
        name: 'Opendi SI',
        logo: 'opendi',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENING_STIJDEN_NL]: {
        name: 'Opening Stijden',
        logo: 'opening-stijden',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENING_TIMES_CO_UK]: {
        name: 'Opening Times',
        logo: 'opening-times',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: false,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENUREN]: {
        name: 'Openuren',
        logo: 'openuren',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: false,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.OPENTABLE]: {
        name: 'Opentable',
        logo: 'opentable',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: false,
            phone: true,
            address: false,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.ORTSDIENST_DE]: {
        name: 'Ortsdienst',
        logo: 'ortsdienst',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.PAGES_24_CH]: {
        name: 'Pages24 CH',
        logo: 'pages-24',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#FF4D00',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.PAGES_24_FR]: {
        name: 'Pages24 FR',
        logo: 'pages-24',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#FF4D00',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.PAGES_24_NL]: {
        name: 'Pages24 NL',
        logo: 'pages-24',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#FF4D00',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.PETIT_FUTE]: {
        name: 'Petit Futé',
        logo: 'petit-fute',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.PHONE_BOOK_IE]: {
        name: 'Phone Book',
        logo: 'phone-book',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.PITNEY_BOWES]: {
        name: 'Pitney Bowes',
        logo: 'pitney-bowes',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.PROPERTY_CAPSULE]: {
        name: 'Property Capsule',
        logo: 'property-capsule',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: false,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.SAFE_GRAPH]: {
        name: 'SafeGraph',
        logo: 'safe-graph',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: false,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.SEZNAM]: {
        name: 'Seznam',
        logo: 'seznam',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: true,
            phone: false,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.SHOW_ME_LOCAL]: {
        name: 'ShowMeLocal',
        logo: 'show-me-local',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#FF5300',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: false,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: false,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.SNAPCHAT]: {
        name: 'Snapchat',
        logo: 'snapchat',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: false,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 2,
    },
    [YextPublisherId.SOLEO]: {
        name: 'Soleo',
        logo: 'soleo',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.DAILY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: false,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.STADTBRANCHENBUCH]: {
        name: 'Stadtbranchenbuch',
        logo: 'stadtbranchenbuch',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.STADTBRANCHENBUCH_CH]: {
        name: 'Stadtbranchenbuch CH',
        logo: 'stadtbranchenbuch',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.T_ONLINE]: {
        name: 'T-Online',
        logo: 't-online',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.TELEPHONE_CITY]: {
        name: 'Telephone City',
        logo: 'telephone-city',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#4EA3DC',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.TELLOWS]: {
        name: 'Tellows',
        logo: 'tellows',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.TOMTOM]: {
        name: 'TomTom',
        logo: 'tomtom',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.MONTHLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: false,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.TOP_RATED_LOCAL]: {
        name: 'Top Rated Local',
        logo: 'top-rated-local',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.TRIPADVISOR]: {
        name: 'TripAdvisor',
        logo: 'tripadvisor',
        doesLogoNeedBorders: false,
        logoBackgroundColor: '#06E49B',
        updateTime: PlatformUpdateTime.WEEKLY, //
        updateFields: {
            name: true,
            phone: false,
            address: true,
            website: false,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.TRUSTPILOT]: {
        name: 'Trustpilot',
        logo: 'trustpilot',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: false,
            phone: false,
            address: false,
            website: false,
            description: false,
            menuUrl: false,
            hours: false,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },

    [YextPublisherId.TUPALO]: {
        name: 'Tupalo',
        logo: 'tupalo',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.US_CITY_NET]: {
        name: 'USCity.net',
        logo: 'us-city-net',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#F0F2F4',
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.VISA_LIETUVA_LT]: {
        name: 'Visa Lietuva',
        logo: 'visa-lietuva',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.WESHOPLOCAL]: {
        name: 'WeShopLocal',
        logo: 'we-shop-local',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.WHERE_TO]: {
        name: 'Where To',
        logo: 'where-to',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.WOGIBTSWAS_AT]: {
        name: 'WoGibtsWas.at',
        logo: 'wogibtswas',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: false,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.WOGIBTSWAS_DE]: {
        name: 'WoGibtsWas.at',
        logo: 'wogibtswas',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.YAHOO]: {
        name: 'Yahoo',
        logo: 'yahoo',
        doesLogoNeedBorders: true,
        logoBackgroundColor: '#ffffff',
        updateTime: PlatformUpdateTime.DAILY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.YALWA]: {
        name: 'Yalwa',
        logo: 'yalwa',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.YANDEX]: {
        name: 'Yandex',
        logo: 'yandex',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.DAILY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: false,
            menuUrl: false,
            hours: true,
            holidayHours: false,
            temporaryClosed: true,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 1,
    },
    [YextPublisherId.YELL]: {
        name: 'Yell',
        logo: 'yell',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.YELLOW_MOXIE]: {
        name: 'YellowMoxie',
        logo: 'yellow-moxie',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            // All false, dont have info on GSheet
            name: false,
            phone: false,
            address: false,
            website: false,
            description: false,
            menuUrl: false,
            hours: false,
            holidayHours: false,
            temporaryClosed: false,
            logo: false,
            categories: false,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.YELLOW_PAGE_CITY]: {
        name: 'Yellow Page City',
        logo: 'yellow-page-city',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.WEEKLY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.YELLOW_PAGES_DIRECTORY]: {
        name: 'Yellow Pages Directory',
        logo: 'yellow-pages-directory',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.YELLOWMAP]: {
        name: 'Yellow Map',
        logo: 'yellow-map',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },

    [YextPublisherId.YELP]: {
        name: 'Yelp',
        logo: 'yelp',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.DAILY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: false,
            hours: true,
            holidayHours: true,
            temporaryClosed: true,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.YP_COM]: {
        name: 'YP.com',
        logo: 'yp',
        doesLogoNeedBorders: false,
        updateTime: PlatformUpdateTime.DAILY,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: false,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.ZLATE_STRANKY_CZ]: {
        name: 'Zlate Stranky CZ',
        logo: 'zlate-stranky',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
    [YextPublisherId.ZLATE_STRANKY_SK]: {
        name: 'Zlate Stranky SK',
        logo: 'zlate-stranky',
        doesLogoNeedBorders: true,
        updateTime: PlatformUpdateTime.REAL_TIME,
        updateFields: {
            name: true,
            phone: true,
            address: true,
            website: true,
            description: true,
            menuUrl: true,
            hours: true,
            holidayHours: true,
            temporaryClosed: false,
            logo: true,
            categories: true,
            cover: false,
        },
        displayPriority: 0,
    },
};

export const YextPublisherIds = Object.values(YextPublisherId) as YextPublisherId[];

export enum ListingStatus {
    WAITING_ON_YEXT = 'WAITING_ON_YEXT',
    WAITING_ON_CUSTOMER = 'WAITING_ON_CUSTOMER',
    WAITING_ON_PUBLISHER = 'WAITING_ON_PUBLISHER',
    LIVE = 'LIVE',
    UNAVAILABLE = 'UNAVAILABLE',
    OPTED_OUT = 'OPTED_OUT',
}
