<div
    class="m-auto flex flex-col sm:m-0 sm:justify-between"
    [ngClass]="alreadyHasSettings() ? 'sm:h-full' : ''"
    [formGroup]="roiSettingsForm">
    @if (!alreadyHasSettings()) {
        <div class="mb-4 flex sm:flex-col">
            <img class="mr-4 h-9 w-9 sm:mb-5" [src]="'rocket' | emojiPathResolver" />
            <div class="flex flex-col">
                <span class="malou-text-18--bold malou-color-text-1">
                    {{ 'roi.settings.congrats_several_months_with_malou' | translate: { months: restaurantExistingMonthsWithMalou() } }}
                </span>
                <span class="malou-text-12--regular malou-color-text-2 !font-medium">
                    {{ 'roi.settings.enter_parameters' | translate }}
                </span>
                <span class="malou-text-12--regular malou-color-text-2 italic">{{ 'roi.settings.data_is_confidential' | translate }}</span>
            </div>
        </div>
    }
    <app-select
        class="w-full"
        formControlName="category"
        [title]="'roi.settings.restaurant_category' | translate"
        [values]="categoriesOptions"
        [inputReadOnly]="true"
        [displayWith]="categoryDisplayWith"></app-select>
    <div class="mb-4 mt-5 flex justify-between sm:flex-col">
        <app-select
            class="w-24 sm:w-full"
            formControlName="currency"
            [title]="'roi.settings.currency' | translate"
            [values]="currencyOptions"
            [displayWith]="currencyDisplayWith">
        </app-select>
        <app-input-number
            class="ml-5 w-full sm:ml-0 sm:w-full"
            [value]="averageTicket ?? null"
            [title]="'roi.settings.average_ticket' | translate"
            [placeholder]="'roi.settings.average_ticket_placeholder' | translate"
            [min]="1"
            [max]="1000"
            (inputNumberChange)="onAverageTicketChange($event)">
        </app-input-number>
    </div>
    <app-select
        class="ml-24 pl-5 sm:ml-0 sm:w-full sm:pl-0"
        formControlName="revenue"
        [title]="'roi.settings.average_revenue_per_month' | translate"
        [placeholder]="'roi.settings.average_revenue_per_month_placeholder' | translate"
        [values]="revenueOptions"
        [inputReadOnly]="true"
        [displayWith]="revenueDisplayWith"></app-select>
    @if (alreadyHasSettings()) {
        <span class="malou-text-11 malou-color-text-2 ml-24 mt-3 pl-5 italic sm:ml-0 sm:pl-0">{{
            'roi.settings.not_retroactive' | translate
        }}</span>
    }
    <div class="!mt-7 flex items-center justify-end sm:flex-col">
        @if (alreadyHasSettings()) {
            <button
                class="malou-btn-raised--secondary mr-4 !h-11 items-center justify-center sm:mb-5 sm:mr-0 sm:w-full"
                mat-raised-button
                (click)="cancel()">
                {{ 'common.cancel' | translate }}
            </button>
        }
        <button
            class="malou-btn-raised--primary flex !h-11 items-center justify-center sm:w-full"
            mat-raised-button
            [disabled]="roiSettingsForm.invalid"
            [ngClass]="{ 'cursor-not-allowed': roiSettingsForm.invalid }"
            (click)="saveRoiSettings()">
            @if (!alreadyHasSettings() && isSavingRoiSettings()) {
                <app-malou-spinner class="flex w-[93px] justify-center" size="small" color="white"></app-malou-spinner>
            } @else {
                {{ (!alreadyHasSettings() ? 'roi.settings.see_earnings' : 'common.save') | translate }}
            }
        </button>
    </div>
</div>
