<div class="h-full w-full">
    @if (!hasMedias) {
        <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
    } @else {
        <div class="hide-scrollbar flex h-full w-full flex-col gap-y-1 overflow-y-auto">
            <ng-container [ngTemplateOutlet]="actionsTemplate"></ng-container>
            <div class="mt-5 flex justify-center">
                <ng-container *ngTemplateOutlet="mediaTemplate"></ng-container>
            </div>
        </div>
    }
</div>

<ng-template #emptyTemplate>
    <app-media-file-uploader
        [accept]="getAcceptedInputFileTypes | applyPure: acceptedMediaTypes"
        [multiple]="true"
        [disabled]="disabled"
        [maxVideoSize]="STORY_MAX_VIDEO_SIZE"
        [maxImageSize]="DEFAULT_MAX_IMAGE_SIZE"
        (fileProcessed)="onFileProcessed($event)"
        (startReadingFile)="startReadingFile()"
        (processError)="processError($event)"
        (dragEnter)="dragOver()"
        (dragLeave)="dragLeave()"
        #mediaUploader>
        <div
            class="grid h-full w-full place-items-center rounded-[10px] border border-malou-color-background-dark bg-malou-color-background-light"
            [ngClass]="{ 'border-dashed border-malou-color-primary': draggingOver }"
            (click)="$event.stopPropagation()">
            <div class="flex flex-col items-center gap-y-4">
                <img class="h-[100px] w-[100px]" [src]="'Goggles' | illustrationPathResolver" />
                <div class="malou-text-12--semibold text-malou-color-text-1">
                    {{ 'edit_image.drag_drop_file' | translate }}
                </div>
                <button class="malou-btn-flat" data-testid="upload-file-btn" (click)="mediaUploader.openFilePicker()">
                    {{ 'edit_image.upload' | translate }}
                </button>
                <button
                    class="malou-btn-flat"
                    data-testid="choose-from-gallery-btn"
                    (click)="openMediaPickerModal(); $event.stopPropagation()">
                    {{ 'edit_image.browse' | translate }}
                </button>
            </div>
        </div>
    </app-media-file-uploader>
</ng-template>

<ng-template #actionsTemplate>
    <div class="flex w-full items-center justify-between md:overflow-hidden">
        <div class="flex w-full gap-x-3 pt-1 sm:flex-wrap">
            <ng-container [ngTemplateOutlet]="cropButtonTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="rotateButtonTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="textEditionButtonTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="backgroundEditionButtonTemplate"></ng-container>
        </div>
        <ng-container [ngTemplateOutlet]="resetButtonTemplate"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="textEditionTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="cropTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="rotateTemplate"></ng-container>
</ng-template>

<ng-template #cropButtonTemplate>
    <div
        class="crop-options-container flex items-center"
        [matTooltip]="'gallery.crop' | translate"
        [ngClass]="{
            'dimensions-toggled': currentEditionMode === EditionMode.CROPPING,
            'cursor-pointer': currentEditionMode !== EditionMode.CROPPING && !isCurrentMediaVideo && !disabled,
            'opacity-50': isCurrentMediaVideo || disabled,
        }"
        (click)="openCropEditor()">
        <mat-icon class="z-10 !h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.DIMENSION"></mat-icon>
    </div>
</ng-template>

<ng-template #cropTemplate>
    @if (currentEditionMode === EditionMode.CROPPING) {
        <div class="flex flex-wrap justify-between gap-y-2">
            <div
                class="crop-options-container flex max-w-[240px] items-center"
                [matTooltip]="'gallery.crop' | translate"
                [ngClass]="{
                    'dimensions-toggled': currentEditionMode === EditionMode.CROPPING,
                    'cursor-pointer': currentEditionMode !== EditionMode.CROPPING && !isCurrentMediaVideo && !disabled,
                    'opacity-50': isCurrentMediaVideo || disabled,
                }">
                <mat-icon class="z-10 !h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.DIMENSION"></mat-icon>
                <div class="crop-options malou-text-12--regular flex cursor-pointer items-center gap-x-2 text-malou-color-border-secondary">
                    <span
                        [ngClass]="{
                            'selected-crop-option': cropOption === CropOption.SQUARE,
                        }"
                        (click)="changeAspectRatio(CropOption.SQUARE)">
                        {{ 'edit_image.square' | translate }}
                    </span>
                    <span
                        [ngClass]="{
                            'selected-crop-option': cropOption === CropOption.VERTICAL,
                        }"
                        (click)="changeAspectRatio(CropOption.VERTICAL)">
                        {{ 'edit_image.vertical' | translate }}
                    </span>
                    <span
                        [ngClass]="{
                            'selected-crop-option': cropOption === CropOption.LANDSCAPE,
                        }"
                        (click)="changeAspectRatio(CropOption.LANDSCAPE)">
                        {{ 'edit_image.landscape' | translate }}
                    </span>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="cancelCommitTemplate"></ng-container>
        </div>
    }
</ng-template>

<ng-template #cancelCommitTemplate>
    <div class="flex items-center gap-x-2">
        @if (!loading) {
            <button class="malou-btn-icon--secondary !h-10 !w-10" mat-icon-button (click)="closeDimensionEditors()">
                <mat-icon [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
            <button class="malou-btn-icon--primary !h-10 !w-10" mat-icon-button (click)="loading = true; commitCurrentEdition()">
                <mat-icon [svgIcon]="SvgIcon.CHECK"></mat-icon>
            </button>
        }
        @if (loading) {
            <div>
                <app-malou-spinner size="small"></app-malou-spinner>
            </div>
        }
    </div>
</ng-template>

<ng-template #rotateButtonTemplate>
    <div
        class="crop-options-container flex items-center"
        [matTooltip]="'gallery.rotation' | translate"
        [ngClass]="{
            'dimensions-toggled': currentEditionMode === EditionMode.ROTATING,
            'cursor-pointer': currentEditionMode !== EditionMode.ROTATING && !isCurrentMediaVideo && !disabled,
            'opacity-50': isCurrentMediaVideo || disabled,
        }"
        (click)="openRotationEditor()">
        <mat-icon class="z-10 !h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.ROTATION"></mat-icon>
    </div>
</ng-template>

<ng-template #rotateTemplate>
    @if (currentEditionMode === EditionMode.ROTATING) {
        <div class="flex flex-wrap justify-between gap-y-2">
            <div
                class="crop-options-container flex items-center"
                [matTooltip]="'gallery.rotation' | translate"
                [ngClass]="{
                    'dimensions-toggled': currentEditionMode === EditionMode.ROTATING,
                    'cursor-pointer': currentEditionMode !== EditionMode.ROTATING && !isCurrentMediaVideo && !disabled,
                    'opacity-50': isCurrentMediaVideo || disabled,
                }"
                (click)="openRotationEditor()">
                <mat-icon class="z-10 !h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.ROTATION"></mat-icon>
                <div class="crop-options flex h-6 items-center gap-x-2">
                    <mat-slider
                        class="malou-mat-slider"
                        color="primary"
                        min="0"
                        max="360"
                        step="15"
                        showTickMarks
                        discrete
                        [disabled]="isCurrentMediaVideo || disabled"
                        [displayWith]="displayRotationWith"
                        #ngSlider>
                        <input matSliderThumb (input)="onSliderInput($event)" #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="malou-text-12--regular text-malou-color-chart-purple">{{ ngSliderThumb.value }}°</div>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="cancelCommitTemplate"></ng-container>
        </div>
    }
</ng-template>

<ng-template #textEditionButtonTemplate>
    @if (isTextEditable) {
        <div class="flex">
            <div
                class="crop-options-container flex items-center"
                [matTooltip]="'edit_image.add_text_edition' | translate"
                [ngClass]="{
                    'dimensions-toggled !pr-5': currentEditionMode === EditionMode.TEXT_EDITING,
                    'cursor-pointer': !isCurrentMediaVideo && !disabled,
                    'opacity-50': isCurrentMediaVideo || disabled,
                }"
                (click)="toggleTextEdition()">
                <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.EDIT_FONT"></mat-icon>
            </div>
        </div>
    }
</ng-template>

<ng-template #backgroundEditionButtonTemplate>
    @if (isTextEditable) {
        <div
            class="crop-options-container flex items-center"
            [(colorPicker)]="bgColor"
            [ngClass]="{
                'dimensions-toggled !pr-5': currentEditionMode === EditionMode.BACKGROUND_EDITING,
                'cursor-pointer': !isCurrentMediaVideo && !disabled,
                'opacity-50': isCurrentMediaVideo || disabled,
            }"
            [cpCancelButton]="true"
            [cpPresetColors]="DEFAULT_BACKGROUND_PRESET_COLORS"
            [cpEyeDropper]="true"
            [cpDisabled]="isCurrentMediaVideo || disabled"
            [ngStyle]="{ color: bgColor }"
            (colorPickerChange)="editBackgroundColor($event)">
            ▉
        </div>
    }
</ng-template>

<ng-template #resetButtonTemplate>
    @if (currentEditionMode !== EditionMode.ROTATING && currentEditionMode !== EditionMode.CROPPING) {
        <mat-icon
            class="z-10 ml-3 !h-3.5 !w-3.5 cursor-pointer"
            color="primary"
            [svgIcon]="SvgIcon.SYNC"
            [class.opacity-50]="!canResetCurrentImage()"
            [matTooltip]="'common.reinitialize_changes' | translate"
            (click)="canResetCurrentImage() && resetCurrentImage()"></mat-icon>
    }
</ng-template>

<ng-template #textEditionTemplate>
    @if (currentEditionMode === EditionMode.TEXT_EDITING) {
        <div>
            <div class="flex w-full items-stretch justify-between gap-x-1">
                <app-simple-select
                    class="flex-3"
                    [formControl]="fontStyleForm"
                    [values]="fontStyles"
                    [displayWith]="displayWithKey"></app-simple-select>
                <app-simple-select class="flex-2" [formControl]="fontSizeForm" [values]="fontSizes"></app-simple-select>
                <button
                    class="malou-border-color-dark !w-10 flex-1 rounded border"
                    [(colorPicker)]="fontColor"
                    [cpCancelButton]="true"
                    [cpEyeDropper]="true"
                    [cpPresetColors]="['#000000', '#3797f0', '#70c150', '#f9c85a', '#f98a30', '#ed4956', '#ce0c6a', '#a407bb']"
                    [ngStyle]="{ color: fontColor }"
                    (colorPickerChange)="editTextColor($event)">
                    A
                </button>
                <button
                    class="malou-border-color-dark !w-10 flex-1 rounded border"
                    [(colorPicker)]="fontBgColor"
                    [cpCancelButton]="true"
                    [cpEyeDropper]="true"
                    [ngStyle]="{ color: fontBgColor }"
                    (colorPickerChange)="editTextBackgroundColor($event)">
                    <b> ▉ </b>
                </button>
            </div>
        </div>
    }
</ng-template>

<ng-template #mediaTemplate>
    <div class="aspect-9/16 w-[250px]" id="backgroundImageContainer" [ngStyle]="{ backgroundColor: bgColor }">
        <div class="relative h-full w-full">
            <div class="relative h-full w-full">
                <div class="h-full w-full">
                    @switch (currentMedia.type) {
                        @case ('video') {
                            <video
                                class="h-full w-full object-contain"
                                id="videoPlayer"
                                muted
                                loop
                                playsinline
                                [src]="currentMediaUrl"></video>
                        }
                        @default {
                            <div
                                class="flex h-full items-center justify-center"
                                id="editImageContainer"
                                [ngStyle]="{ cursor: currentEditionMode === EditionMode.TEXT_EDITING ? 'crosshair' : 'default' }"
                                (click)="addTextTag($event)"
                                #editedImage>
                                <ng-container [ngTemplateOutlet]="imageCropperTemplate"></ng-container>
                                <img
                                    class="!rounded-none"
                                    id="imageContainer"
                                    [ngClass]="
                                        (currentMedia | applySelfPure: 'getAspectRatio' : PictureSize.IG_FIT) >= CropOption.VERTICAL
                                            ? 'w-[250px]'
                                            : 'h-[445px]'
                                    "
                                    [hidden]="displayImageCropper"
                                    [src]="currentMediaUrl" />
                            </div>
                        }
                    }
                </div>
                @if (currentMedia | applySelfPure: 'isVideo') {
                    <div
                        class="absolute left-0 top-0 grid h-full w-full cursor-pointer place-items-center transition-all"
                        [ngClass]="{ 'opacity-0 hover:opacity-100': isVideoPlaying() }"
                        (click)="playVideo()">
                        <div class="grid h-24 w-24 place-items-center rounded-full bg-white bg-opacity-70">
                            <mat-icon class="!h-9 !w-9" color="primary" [svgIcon]="isVideoPlaying() ? 'pause' : 'play'"></mat-icon>
                        </div>
                    </div>
                }
                @if (currentMedia | applySelfPure: 'isVideo') {
                    <div class="absolute bottom-3 left-3">
                        <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="muteUnmuteVideo()">
                            <mat-icon color="primary" [svgIcon]="isVideoMuted() ? 'sound-off' : 'sound-on'"></mat-icon>
                        </button>
                    </div>
                }
            </div>

            @if (currentMedia.getErrors(); as mediaErrors) {
                @if (mediaErrors.length) {
                    <div class="absolute left-0 top-0 w-full p-3" data-html2canvas-ignore="true">
                        <div class="flex w-full flex-col gap-y-2">
                            @for (mediaError of mediaErrors; track mediaError) {
                                <div
                                    class="bg-light-error malou-text-10--regular w-full rounded-[10px] px-4 py-3 italic text-malou-color-state-error">
                                    {{ mediaError }}
                                </div>
                            }
                        </div>
                    </div>
                }
            }
        </div>
    </div>
</ng-template>

<ng-template #imageCropperTemplate>
    @if (displayImageCropper) {
        <image-cropper
            class="!p-0"
            id="cropped-image"
            style="max-height: 65vh"
            [transform]="{ rotate: angle }"
            [imageURL]="currentMediaUrl + '?x-request=foo'"
            [maintainAspectRatio]="true"
            [containWithinAspectRatio]="currentEditionMode === EditionMode.ROTATING"
            [aspectRatio]="cropOption"
            [format]="format"
            [imageQuality]="100"
            [alignImage]="'center'"
            [cropperMinWidth]="128"
            [onlyScaleDown]="true"
            [hideResizeSquares]="currentEditionMode === EditionMode.ROTATING"
            (imageCropped)="onImageCropped($event)"
            #cropper>
        </image-cropper>
    }
</ng-template>
