import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { ZenchefConnectionStep0Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-zenchef-modal/zenchef-connection-step-0/zenchef-connection-step-0.component';
import { ZenchefConnectionStep1Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-zenchef-modal/zenchef-connection-step-1/zenchef-connection-step-1.component';
import {
    PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
    PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
    ParentStepperComponentDialogData,
    PlatformsConnectionParentStepperComponent,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/platforms-connection-parent-stepper.component';
import { createStep } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/step.interface';
import { GetStepIndexFromCredentialService } from ':modules/platforms/platforms-connection-modals/shared/services/get-step-index-from-credential.service';
import { SelectCredentialAndBusinessStepComponent } from ':modules/platforms/platforms-connection-modals/shared/steps/select-credential-and-business-step/select-credential-and-business-step.component';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const NOT_CONNECTED_STEP_INDEX = 0;
const CONNECTED_STEP_INDEX = 2;

export interface ZenchefConnectionModalResult {
    hasDataChanged?: boolean;
}
@Injectable({ providedIn: 'root' })
export class ZenchefConnectionModalService {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _translateService = inject(TranslateService);
    private readonly _getStepIndexFromCredentialService = inject(GetStepIndexFromCredentialService);

    open(): Observable<ZenchefConnectionModalResult | undefined> {
        return this._getStepIndexFromCredentialService.execute(PlatformKey.ZENCHEF, NOT_CONNECTED_STEP_INDEX, CONNECTED_STEP_INDEX).pipe(
            switchMap((stepIndex) =>
                this._customDialogService
                    .open<
                        PlatformsConnectionParentStepperComponent,
                        ParentStepperComponentDialogData<undefined, ZenchefConnectionModalResult>,
                        ZenchefConnectionModalResult
                    >(PlatformsConnectionParentStepperComponent, {
                        height: PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
                        width: PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
                        data: {
                            steps: [
                                {
                                    stepName: this._translateService.instant('platforms.connection_new.zenchef.step_0.step_name'),
                                    component: ZenchefConnectionStep0Component,
                                    componentInputs: {},
                                },
                                {
                                    stepName: this._translateService.instant('platforms.connection_new.zenchef.step_1.step_name'),
                                    component: ZenchefConnectionStep1Component,
                                    componentInputs: {},
                                },
                                createStep(
                                    this._translateService.instant('platforms.connection_new.zenchef.step_2.step_name'),
                                    SelectCredentialAndBusinessStepComponent,
                                    {
                                        titleTranslationKey: this._translateService.instant(
                                            'platforms.connection_new.zenchef.step_2.step_name'
                                        ),
                                        goToStepParam: { type: 'relative', value: -1 },
                                        platformKey: PlatformKey.ZENCHEF,
                                        showNewOauthButton: false,
                                        showPlatformIconInModalTitle: false,
                                    }
                                ),
                            ],
                            stepperTitle: this._translateService.instant('platforms.connection_new.zenchef.stepper.title'),
                            stepperSubtitle: this._translateService.instant('platforms.connection_new.zenchef.stepper.subtitle'),
                            stepperPlatformKeyIcon: PlatformKey.ZENCHEF,
                            stepIndex: stepIndex,
                        },
                    })
                    .afterClosed()
            )
        );
    }
}
