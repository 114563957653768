import { AsyncPipe, Location, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Inject, OnInit, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { uniqWith } from 'lodash';
import { BehaviorSubject, map, switchMap } from 'rxjs';

import { BusinessCategory, PlatformKey, Role } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { adminAuthList } from ':core/constants';
import { CredentialsService } from ':core/services/credentials.service';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { PlatformsConnectionStepperComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/platforms-connection-stepper/platforms-connection-stepper.component';
import { ChooseOrganizationModalComponent } from ':modules/restaurant-list/restaurants/choose-organization-modal/choose-organization-modal.component';
import { CreateRestaurantFromPlatformModalComponent } from ':modules/restaurant-list/restaurants/create-restaurant-from-platform-modal/create-restaurant-from-platform-modal.component';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Credential } from ':shared/models';
import { Organization } from ':shared/models/organization';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

export interface LastStep {
    picture: string;
    title: string;
    details?: string[];
    primaryButtonLabel?: string;
    secondaryButtonLabel?: string;
    onClickPrimaryButton?: () => void;
    onClickSecondaryButton?: () => void;
}

interface TypeTranslate {
    value: BusinessCategory;
    name: string;
    description: string;
    detailsHTML: string;
    connexionText: string;
    associatedPlatform: string;
}

enum Step {
    ChooseRestaurantType,
    ChooseOrganization,
    CreateRestaurant,
}

@Component({
    selector: 'app-connect-restaurant-modal',
    templateUrl: './connect-restaurant-modal.component.html',
    styleUrls: ['./connect-restaurant-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatStepperModule,
        CreateRestaurantFromPlatformModalComponent,
        ChooseOrganizationModalComponent,
        PlatformsConnectionStepperComponent,
        MalouSpinnerComponent,
        AsyncPipe,
        PlatformLogoPathResolverPipe,
        TranslateModule,
        ApplyPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectRestaurantModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly stepper = viewChild<MatStepper>('stepper');
    readonly createRestaurantFromPlatformModalComponent =
        viewChild<CreateRestaurantFromPlatformModalComponent>('appCreateRestaurantPlatformModal');

    readonly VENUE_TYPE_OPTIONS: TypeTranslate[] = [
        {
            value: BusinessCategory.LOCAL_BUSINESS,
            name: this._translate.instant('restaurants_list.connect_restaurant.local_business'),
            description: this._translate.instant('restaurants_list.connect_restaurant.local_business_description'),
            detailsHTML: this._translate.instant('restaurants_list.connect_restaurant.local_business_text_html'),
            connexionText: this._translate.instant('restaurants_list.connect_restaurant.connect_gmb'),
            associatedPlatform: PlatformKey.GMB,
        },
        {
            value: BusinessCategory.BRAND,
            name: this._translate.instant('restaurants_list.connect_restaurant.brand'),
            description: this._translate.instant('restaurants_list.connect_restaurant.brand_description'),
            detailsHTML: this._translate.instant('restaurants_list.connect_restaurant.brand_text_html'),
            connexionText: this._translate.instant('restaurants_list.connect_restaurant.connect_facebook'),
            associatedPlatform: PlatformKey.FACEBOOK,
        },
    ];

    readonly STEP_NAMES: string[] = [
        this._translate.instant('restaurants_list.connect_restaurant.choose_type'),
        this._translate.instant('restaurants_list.connect_restaurant.add_organization'),
        this._translate.instant('restaurants_list.connect_restaurant.add_your_restaurant'),
    ];

    readonly isLoading$ = new BehaviorSubject(true);

    readonly currentUser$ = this._store.select(selectUserInfos);

    readonly selectedVenueType = signal<BusinessCategory>(this.VENUE_TYPE_OPTIONS[0].value);
    readonly currentPlatformKey = signal<string>(PlatformKey.GMB);
    readonly selectedAuthId = signal<string | undefined>(undefined);
    readonly selectedStep = signal<Step>(Step.ChooseRestaurantType);
    readonly oauthUrl = signal<string>('');

    readonly credentials = signal<{ [key: string]: Credential[] } | undefined>(undefined);

    readonly lastStepProps = signal<Partial<LastStep>>({});
    readonly isLastStepValid = signal(false);

    readonly organizations = signal<Organization[]>([]);
    readonly selectedOrganization = signal<Organization | undefined>(undefined);

    readonly isSmallScreen = toSignal(this._screenSizeService.resize$.pipe(map((elt) => elt.size === ScreenSize.IsSmallScreen)), {
        initialValue: this._screenSizeService.isPhoneScreen,
    });

    readonly addressGmbWarningMessage = signal<string>('');
    readonly venueTypeDetails = computed((): TypeTranslate | undefined => this._getTypeOptions(this.selectedVenueType()));

    readonly createdRestaurantId = signal<string | null>(null);

    readonly PlatformKey = PlatformKey;
    readonly Step = Step;

    readonly shouldDisablePrimaryButton = computed((): boolean => {
        const step = this.selectedStep();
        if (step === Step.ChooseOrganization) {
            return !this.selectedOrganization();
        } else if (step === Step.CreateRestaurant) {
            return !this.isLastStepValid();
        }
        return false;
    });

    private _user: User;
    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _dialogRef: MatDialogRef<ConnectRestaurantModalComponent>,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _credentialsService: CredentialsService,
        private readonly _location: Location,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            authId: string;
            hasBeenRedirected: boolean;
            organizationId?: string;
            restaurantType?: BusinessCategory;
        }
    ) {}

    ngOnInit(): void {
        this._updateOauthUrl();
        this._onCurrentUserChange();
    }

    filterOutAdminCredentials(): (credential: Credential) => boolean {
        return (credential: Credential) => {
            if (this._user.role !== Role.ADMIN) {
                return !adminAuthList.includes(credential.authId);
            }
            return true;
        };
    }

    getStepTitle(): string {
        return this.STEP_NAMES[this.selectedStep()];
    }

    selectRestaurantType(type: BusinessCategory): void {
        this.selectedVenueType.set(type);
        this.currentPlatformKey.set(this.selectedVenueType() === BusinessCategory.LOCAL_BUSINESS ? PlatformKey.GMB : PlatformKey.FACEBOOK);
        this._setSelectedAuthIdByPlatformKey(this.currentPlatformKey());
        this._updateOauthUrl();
    }

    openConnectionOrGoToNextStep(lastStep: Partial<LastStep & { createdRestaurantId?: string }>): void {
        if (this.hasConnectedCredentials(this.currentPlatformKey())) {
            this.lastStepProps.set(lastStep);
            this.createdRestaurantId.set(lastStep?.createdRestaurantId ?? null);
            if (this.selectedStep() === Step.CreateRestaurant) {
                this.cancel({ lastStep: this.lastStepProps() });
                return;
            }
            this.stepper()?.next();
            const currentSelectedStep = this.selectedStep();
            this.selectedStep.set(currentSelectedStep + 1);
            return;
        }
        this.onEditRestaurantConnection();
    }

    close(): void {
        this.cancel({ lastStep: this.lastStepProps() });
    }

    hasConnectedCredentials(platformKey: string): boolean {
        return !!this.credentials()?.[platformKey]?.length;
    }

    cancel({ lastStep }: { lastStep?: Partial<LastStep> } = {}): void {
        if (!this.isSmallScreen()) {
            this._dialogRef.close({ lastStep });
            return;
        }
        setTimeout(() => {
            this._dialogRef.close({ lastStep });
        }, 400);
    }

    goPreviousStep(): void {
        this.stepper()?.previous();
        const currentSelectedStep = this.selectedStep();
        this.selectedStep.set(currentSelectedStep - 1);
    }

    setOrganization(organization: Organization): void {
        this.selectedOrganization.set(organization);
    }

    createRestaurant(selectedStep: Step): void {
        const selectedOrganization = this.selectedOrganization();
        if (!selectedOrganization) {
            return;
        }
        if (selectedStep === Step.ChooseOrganization) {
            this.stepper()?.next();
            const currentSelectedStep = this.selectedStep();
            this.selectedStep.set(currentSelectedStep + 1);
            return;
        }
        this.createRestaurantFromPlatformModalComponent()?.createRestaurant(selectedOrganization);
    }

    setAddressGmbWarningMessage(message: string): void {
        this.addressGmbWarningMessage.set(message);
    }

    getStepButtonLabel = (step: Step): string => {
        if (step === Step.ChooseOrganization) {
            return this._translate.instant('common.next');
        }
        return this._translate.instant('common.add');
    };

    onCreateRestaurantFromPlatformValidation(isValid: boolean): void {
        this.isLastStepValid.set(isValid);
    }

    onEditRestaurantConnection(): void {
        // save current state for later when redirected back to this page
        localStorage.setItem(
            LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS,
            JSON.stringify({
                organizationId: this.selectedOrganization()?._id,
                restaurantType: this.selectedVenueType(),
            })
        );
        window.location.href = this.oauthUrl();
    }

    private _getTypeOptions(type: BusinessCategory | undefined): TypeTranslate | undefined {
        return type ? this.VENUE_TYPE_OPTIONS.find((typeOpt) => typeOpt.value === type) : undefined;
    }

    private _setSelectedAuthIdByPlatformKey(platformKey: string): void {
        const selectedAuthId = this.selectedAuthId();
        const credentials = this.credentials();
        if (selectedAuthId && credentials?.[platformKey]?.find((cred) => cred.authId === selectedAuthId)) {
            return;
        }
        this.selectedAuthId.set(this.credentials()?.[platformKey]?.[0]?.authId);
    }

    private _updateOauthUrl(): void {
        this._credentialsService
            .getOauthLoginUrl(this.currentPlatformKey())
            .pipe(map((res) => res.data))
            .subscribe({
                next: (url) => {
                    this.oauthUrl.set(
                        url +
                            '&state={"location":"' +
                            encodeURIComponent(this._location.path()) +
                            '","user":"' +
                            this._user._id +
                            '","platformKey":"' +
                            this.currentPlatformKey() +
                            '","webVersion":"' +
                            environment.APP_WEB_VERSION +
                            '"}'
                    );
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _onCurrentUserChange(): void {
        this.currentUser$
            .pipe(
                switchMap((user: User) => {
                    this.organizations.set(user.organizations);
                    this.isLoading$.next(true);
                    this._user = user;
                    return this._credentialsService.getOrganizationCredentialsForUser(['_id', 'authId', 'key', 'name', 'active']);
                }),
                map((res) => res.data),
                map((organizationCredentials) => organizationCredentials.filter(this.filterOutAdminCredentials())),
                map((organizationCredentials) => ({
                    gmb: uniqWith(
                        (organizationCredentials || [])
                            .filter((cred: Credential) => cred.key === PlatformKey.GMB)
                            .sort((cred1: Credential, cred2: Credential) => Number(cred2.active) - Number(cred1.active)),
                        (credA: Credential, credB: Credential) => credA.authId === credB.authId
                    ),
                    facebook: uniqWith(
                        (organizationCredentials || [])
                            .filter((cred: Credential) => cred.key === PlatformKey.FACEBOOK)
                            .sort((cred1: Credential, cred2: Credential) => Number(cred2.active) - Number(cred1.active)),
                        (credA: Credential, credB: Credential) => credA.authId === credB.authId
                    ),
                })),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (res) => {
                    this.credentials.set(res);
                    this._initStepperWithPreviousFormData();
                    this.isLoading$.next(false);
                },
                error: (err) => {
                    this.isLoading$.next(false);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _initStepperWithPreviousFormData(): void {
        if (this.data.hasBeenRedirected) {
            const organization = this.organizations().find((org) => org._id === this.data.organizationId);
            const restaurantType = this.data.restaurantType;
            const authId = this.data.authId;

            if (!restaurantType || !authId) {
                return;
            }

            this.selectedAuthId.set(authId);
            this.selectRestaurantType(restaurantType);

            if (!organization) {
                // Go to the choose organization step
                this.stepper()?.next();
                this.selectedStep.set(Step.ChooseOrganization);
                return;
            }

            this.setOrganization(organization);
            // Go to last step
            this.stepper()?.next();
            this.stepper()?.next();
            this.selectedStep.set(Step.CreateRestaurant);
        }
    }
}
