<div
    class="malou-card !m-0 cursor-pointer !px-6 !py-4 md:!p-4"
    [class.selected-media]="isSelected"
    (click)="$event.stopPropagation(); onMediaContainerClick()"
    (pointerdown)="$event.stopPropagation()">
    <div class="malou-card__body flex w-full grow flex-col p-0">
        <ng-container [ngTemplateOutlet]="mediaHeaderWithActions" [ngTemplateOutletContext]="{ media, isSelected }"> </ng-container>

        <ng-container [ngTemplateOutlet]="mediaImage" [ngTemplateOutletContext]="{ media }"> </ng-container>
    </div>
</div>

<ng-template let-media="media" let-isSelected="isSelected" #mediaHeaderWithActions>
    <div class="mb-3 flex items-center justify-between">
        <div class="media-header-title-max-width flex items-center">
            <mat-checkbox
                color="primary"
                [checked]="isSelected"
                (click)="$event.stopPropagation()"
                (pointerdown)="$event.stopPropagation()"
                (change)="onMediaSelected($event)"></mat-checkbox>
            <div class="ml-3 max-w-[88%]">
                <div class="malou-text-12--semibold max-w-fit truncate" [matTooltip]="media.name ?? media.title ?? '.' + media.format">
                    {{ media | applySelfPure: 'getFullname' : media.name }}
                </div>
                <div class="malou-text-10 italic text-malou-color-text-2">
                    {{ media.createdAt | formatDate: 'shortDate' }}
                </div>
            </div>
        </div>
        @if (!screenSizeService.isPhoneScreen) {
            <div class="flex items-center rounded-full p-2" matRipple>
                <mat-icon
                    class="!h-4 !w-4"
                    color="primary"
                    [svgIcon]="SvgIcon.ELLIPSIS"
                    [matMenuTriggerFor]="actionsMenu"
                    (click)="$event.stopPropagation()"
                    (pointerdown)="$event.stopPropagation()"></mat-icon>
            </div>
        }
        @if (screenSizeService.isPhoneScreen) {
            <mat-icon
                color="primary"
                matRipple
                [svgIcon]="SvgIcon.ELLIPSIS"
                [matMenuTriggerFor]="actionsMenu"
                (click)="$event.stopPropagation()"
                (pointerdown)="$event.stopPropagation()"></mat-icon>
        }
        <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
            <button class="flex gap-x-3" mat-menu-item (click)="renameMedia()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                <span class="malou-text-14--regular">{{ 'gallery.rename' | translate }}</span>
            </button>
            <button class="flex gap-x-3" mat-menu-item [matMenuTriggerFor]="duplicateMenu">
                <mat-icon color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                <span class="malou-text-14--regular">{{ 'gallery.duplicate' | translate }}</span>
            </button>
            @if (media.type !== 'video') {
                <button class="flex gap-x-3" mat-menu-item (click)="showEditModal()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.edit_image' | translate }}</span>
                </button>
            }
            <button class="flex gap-x-3" mat-menu-item (click)="downloadSingleMedia()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.DOWNLOAD"></mat-icon>
                <span class="malou-text-14--regular">{{ 'gallery.download' | translate }}</span>
            </button>
            <button class="flex gap-x-3" mat-menu-item (click)="onMoveMedia()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.SYNC_ALT"></mat-icon>
                <span class="malou-text-14--regular">{{ 'gallery.move' | translate }}</span>
            </button>
            <button class="flex gap-x-3" mat-menu-item (click)="deleteMedia()">
                <mat-icon color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                <span class="malou-text-14--regular">{{ 'gallery.delete' | translate }}</span>
            </button>
        </mat-menu>

        <mat-menu class="malou-mat-menu malou-box-shadow" #duplicateMenu="matMenu">
            <button mat-menu-item (click)="duplicateMedia(DuplicationDestination.HERE)">
                <span class="malou-text-14--regular">
                    {{ 'gallery.duplicate_here' | translate }}
                </span>
            </button>
            <button mat-menu-item (click)="duplicateMedia(DuplicationDestination.OUT)">
                <span class="malou-text-14--regular">
                    {{ 'common.to_other_venues' | translate }}
                </span>
            </button>
        </mat-menu>
    </div>
</ng-template>

<ng-template let-media="media" #mediaImage>
    <div class="media-container media-container relative h-[120px] w-full rounded-xl md:h-[100px]" (click)="openCarousel($event)">
        @if (media | applySelfPure: 'isImage') {
            <img
                class="h-full w-full rounded-lg object-cover"
                [defaultImage]="'default-picture-grey' | imagePathResolver"
                [lazyLoad]="media | applySelfPure: 'getMediaUrl' : 'small'"
                [alt]="media.name" />
        } @else {
            @if (media.thumbnail) {
                <img
                    class="h-full w-full rounded-lg object-cover"
                    [defaultImage]="'default-picture-grey' | imagePathResolver"
                    [lazyLoad]="media.thumbnail"
                    [alt]="media.name" />
            }
            @if (!media.thumbnail) {
                <video
                    class="h-full w-full rounded-lg object-cover"
                    muted
                    preload="auto"
                    [src]="media | applySelfPure: 'getMediaUrl'"></video>
            }
            <div class="absolute bottom-2 left-2 grid place-items-center rounded-full" matRipple>
                <mat-icon class="!h-[30px] !w-[30px] !text-[30px] text-white">play_arrow</mat-icon>
            </div>
        }

        <ng-container [ngTemplateOutlet]="mediaTags" [ngTemplateOutletContext]="{ media, neverPosted: media.postIds?.length === 0 }">
        </ng-container>
    </div>
</ng-template>

<ng-template let-media="media" let-neverPosted="neverPosted" #mediaTags>
    <div class="absolute bottom-2 right-2">
        @if (neverPosted) {
            <div
                class="malou-chip malou-chip--success-light !h-[26px] rounded-full"
                [matTooltip]="'gallery.never_posted_tooltip' | translate">
                <span class="malou-text-10--semibold text-malou-color-text-green">{{ 'gallery.never_posted' | translate }}</span>
            </div>
        }
    </div>
</ng-template>
