import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, forkJoin, map, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';

type AuthorizedPlatformKeys = PlatformKey.GMB | PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM;

@Injectable({
    providedIn: 'root',
})
export class GetOauthUrlService {
    private readonly _store = inject(Store);
    private readonly _credentialsService = inject(CredentialsService);
    private readonly _location = inject(Location);
    private readonly _restaurantsService = inject(RestaurantsService);

    execute(platformKey: AuthorizedPlatformKeys): Observable<string> {
        const oauthLoginUrl$ = this._credentialsService.getOauthLoginUrl(platformKey).pipe(map((res) => res.data));
        const userInfo$ = this._store.select(selectUserInfos).pipe(filter(isNotNil), take(1));

        return forkJoin([oauthLoginUrl$, userInfo$]).pipe(
            map(([url, user]) => {
                const stateQueryParam = {
                    location: encodeURIComponent(this._location.path()),
                    user: user._id,
                    platformKey: platformKey,
                    restaurantId: this._restaurantsService.currentRestaurant._id,
                };
                const queryParams = `&state=${JSON.stringify(stateQueryParam)}`;
                return `${url}${queryParams}`;
            })
        );
    }
}
