import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';

import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';

import { selectUnansweredCommentCount, selectUnansweredMentionCount } from '../comments/store/comments.reducer';
import { selectUnreadConversations } from '../messages/messages.reducer';

@Component({
    selector: 'app-interactions',
    templateUrl: './interactions.component.html',
    styleUrls: ['./interactions.component.scss'],
    standalone: true,
    imports: [NavigationHeaderComponent, RouterOutlet],
})
export class InteractionsComponent implements OnInit {
    readonly unreadConversationCount$: Observable<number> = this._store.select(selectUnreadConversations);
    readonly unansweredCommentCount$: Observable<number> = this._store.select(selectUnansweredCommentCount);
    readonly unansweredMentionCount$: Observable<number> = this._store.select(selectUnansweredMentionCount);

    private readonly _messagesTab: NavigationTab = {
        label: this._translate.instant('app_sidebar.interactions.messages', { messagesNumber: 0 }),
        link: './messages',
    };
    private readonly _moderationTab: NavigationTab = {
        label: this._translate.instant('app_sidebar.interactions.moderation'),
        link: './comments',
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    readonly TABS = [this._messagesTab, this._moderationTab];

    private _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _translate: TranslateService,
        private readonly _store: Store
    ) {}

    ngOnInit(): void {
        this.unreadConversationCount$.subscribe((count) => {
            this._messagesTab.label = this._translate.instant('app_sidebar.interactions.messages', {
                messagesNumber: count < 0 ? 0 : count,
            });
            this._messagesTab.withDot = count > 0;
        });

        combineLatest([this.unansweredCommentCount$, this.unansweredMentionCount$])
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(([unansweredCommentCount, unansweredMentionCount]) => {
                this._moderationTab.withDot = unansweredCommentCount + unansweredMentionCount > 0;
            });
    }
}
