import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';

@Component({
    selector: 'app-boosters',
    templateUrl: './boosters.component.html',
    styleUrls: ['./boosters.component.scss'],
    standalone: true,
    imports: [NavigationHeaderComponent, RouterOutlet],
})
export class BoostersComponent {
    readonly TABS: NavigationTab[] = [
        { label: this._translate.instant('app_sidebar.boosters.wheel_of_fortune'), link: './wheels-of-fortune', isNew: true },
        { label: this._translate.instant('app_sidebar.boosters.totems'), link: './totems' },
    ];

    constructor(private readonly _translate: TranslateService) {}
}
