<div class="malou-dialog">
    <!-- justify end close button cross icon -->
    <div class="malou-dialog__header !justify-end"></div>
    <!-- dialog content -->
    <div class="malou-dialog__body !overflow-hidden text-center">
        <div class="mb-6 grid w-full place-items-center">
            <img class="w-[90px]" [src]="illustration.KarlOk | illustrationPathResolver" />
        </div>
        <div class="malou-text-18--bold mb-4 text-malou-color-text-1">
            <span>{{ 'login.confirm_account.verified' | translate }}</span>
        </div>

        <div class="malou-text-13--regular text-malou-color-text-2">
            <span>{{ 'login.confirm_account.connect_with_temp_pwd' | translate }}</span>
            <br />

            <div class="mx-auto my-3 flex w-fit items-center gap-2 rounded-xl bg-malou-color-background-dark p-3">
                <span class="text-malou-color-text-1">{{ data.password }}</span>
                <mat-icon class="!h-4 cursor-pointer" color="primary" [svgIcon]="SvgIcon.COPY" (click)="copy(data.password)"></mat-icon>
            </div>
        </div>
    </div>

    <!-- dialog footer -->
    <div class="malou-dialog__footer !justify-center gap-x-2">
        <button class="malou-btn-raised--primary" mat-raised-button (click)="goToLogin()">
            {{ 'login.confirm_account.connect' | translate }}
        </button>
    </div>
</div>
