import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { ReviewNotification } from ':core/components/notification-center/models/review-notification.model';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Restaurant } from ':shared/models';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-review-notification-item',
    templateUrl: './review-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, TranslateModule, DatePipe, ImagePathResolverPipe, PluralTranslatePipe, MatButtonModule],
})
export class ReviewNotificationItemComponent extends NotificationItemComponent {
    readonly notification = computed(() => this.initialNotification() as ReviewNotification);
    readonly restaurantService = inject(RestaurantsService);
    readonly restaurant = computed(() =>
        this.restaurantService
            .restaurants()
            .find((restaurant: Restaurant) => restaurant.id === this.notification()?.data?.restaurantIds?.[0])
    );

    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);
}
