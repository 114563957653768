import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, computed, Inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { xor } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    filter,
    interval,
    map,
    Observable,
    Subject,
    Subscription,
    switchMap,
    take,
    takeUntil,
    tap,
    throwError,
} from 'rxjs';

import { MalouErrorCode, PlatformDefinitions, PostType } from '@malou-io/package-utils';

import { periodOptions } from ':core/constants';
import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MentionsService } from ':modules/mentions/mentions.service';
import { selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import { FilterOption, GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { formatHours, formatStringDate } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import {
    AvailablePlatform,
    Comment,
    CommentOrigin,
    CommentReply,
    CommentsFilters,
    FetchedState,
    Label,
    MalouPeriod,
    MentionReply,
    Pagination,
    Platform,
    Post,
    Restaurant,
    SocialAttachment,
} from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { ConcatPipe } from ':shared/pipes/concat.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { ToogleableFiltersKey } from '../comments.interface';
import { CommentsService } from '../comments.service';
import { LabelsService } from '../labels.services';
import * as CommentsActions from '../store/comments.actions';
import { selectCommentsFilters } from '../store/comments.reducer';

const DEFAULT_PAGINATION: Pagination = { pageNumber: 0, pageSize: 20, total: 0 };

@AutoUnsubscribeOnDestroy()
@Component({
    selector: 'app-answer-comment-modal',
    templateUrl: './answer-comment-modal.component.html',
    styleUrls: ['./answer-comment-modal.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        FormsModule,
        MatButtonModule,
        SkeletonComponent,
        SocialPostMediaComponent,
        MatTooltipModule,
        TextAreaComponent,
        ReactiveFormsModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        NgClass,
        SortByFiltersComponent,
        MatMenuModule,
        GroupedDateFiltersComponent,
        MatRippleModule,
        MatButtonToggleModule,
        AsyncPipe,
        PlatformLogoPathResolverPipe,
        ShortTextPipe,
        ApplyPurePipe,
        ApplySelfPurePipe,
        TranslateModule,
        SearchComponent,
        LazyLoadImageModule,
        ConcatPipe,
        ApplyPurePipe,
        AvatarPipe,
    ],
})
export class AnswerCommentModalComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly pagination$: BehaviorSubject<Pagination>;

    readonly currentPlatforms$: Observable<Platform[]> = this._store.select(selectCurrentPlatforms);
    readonly filters$: Observable<CommentsFilters> = this._store
        .select(selectCommentsFilters)
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));

    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'date', label: this._translate.instant('moderation.filter_label.date') },
        { key: 'platform', label: this._translate.instant('moderation.filter_label.platform') },
    ];

    readonly CommentType = CommentOrigin;
    readonly PostType = PostType;

    SortBy = ChartSortBy;
    reviewerNameTemplate: WritableSignal<Label | null> = signal(null);
    replyTemplates: WritableSignal<Label[]> = signal([]);
    currentComment?: Comment;
    commentsList: Comment[];
    filters: CommentsFilters;
    loading = true;
    replyTextFormControl = new FormControl<string>('', [Validators.required]);
    saveModelFormControl = new FormControl<boolean>(false);
    availablePlatforms: AvailablePlatform[] = [];

    hasConnectedPlatform = false;
    filterCount = 0;

    MalouPeriod = MalouPeriod;
    periodOptions = periodOptions.map((period) => period.key);

    updateStatusSubscription: Subscription;
    seeMoreReplies = true;
    seeMoreCurrentCommentText = true;

    allReplyTemplates: Signal<Label[]> = computed(() => {
        const reviewerNameTemplate = this.reviewerNameTemplate();
        return reviewerNameTemplate ? [...this.replyTemplates(), reviewerNameTemplate] : this.replyTemplates();
    });

    constructor(
        private readonly _commentsService: CommentsService,
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _labelsService: LabelsService,
        private readonly _translate: TranslateService,
        private readonly _mentionsService: MentionsService,
        private readonly _dialogService: DialogService,
        private readonly _toastService: ToastService,
        private readonly _spinnerService: SpinnerService,
        private readonly _postsService: PostsService,
        private readonly _http: HttpClient,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _dialogRef: MatDialogRef<AnswerCommentModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            commentId?: string;
            pageSize?: string;
            mentionId?: string;
            fromPosts?: boolean;
            postSocialId?: string;
        }
    ) {
        const initialPagination = DEFAULT_PAGINATION;
        if (data.pageSize) {
            initialPagination.pageSize = parseInt(data.pageSize, 10);
        }
        this.pagination$ = new BehaviorSubject(initialPagination);
    }

    ngOnInit(): void {
        this._fetchLabels();
        if (!this.data.fromPosts || !this.data.postSocialId) {
            this._fetchCommentsList();
        } else {
            this._fetchPostComments(this.data.postSocialId);
        }

        this._getAvailablePlatforms$().subscribe({
            next: (platforms) => {
                this.hasConnectedPlatform = platforms.some((p) => p.connected);
                this.availablePlatforms = platforms.filter((p) => p.connected);
            },
        });

        this.filters$.subscribe({
            next: (newFilters) => {
                this.filters = newFilters;
                this.filterCount = this._computeFilterCount(newFilters);
            },
        });
    }

    close(): void {
        this._dialogRef.close();
    }

    formatSocialDate(date?: Date): string {
        if (!date) {
            return '-';
        }
        return `${formatStringDate(date)} - ${formatHours(date)}`;
    }

    removeLabel(label: Label): void {
        this._dialogService.open({
            title: this._translate.instant('moderation.delete_label_title'),
            message: this._translate.instant('moderation.delete_label_text'),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translate.instant('common.yes_delete'),
                action: () => {
                    this.replyTemplates.update((replyTemplates) =>
                        replyTemplates.filter((replyTemplate) => replyTemplate._id !== label._id)
                    );
                    this._labelsService.deleteLabel(label._id).subscribe({
                        error: (err) => {
                            console.warn('err :>> ', err);
                            this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                        },
                    });
                },
            },
            secondaryButton: {
                label: this._translate.instant('common.cancel'),
            },
        });
    }

    choseTemplate(template: Label): void {
        this._incrementClickCount(template);
        const textarea = document.getElementsByTagName('textarea')[0] as HTMLTextAreaElement;
        const cursorPosition = textarea.selectionStart;

        const textBeforeCursor = this.replyTextFormControl.value?.slice(0, cursorPosition) ?? '';

        const textAfterCursor = this.replyTextFormControl.value?.slice(cursorPosition) ?? '';

        this.replyTextFormControl.setValue(`${textBeforeCursor} ${template.text} ${textAfterCursor}`);
    }

    changeCurrentComment(comment: Comment): void {
        this.currentComment = comment;
        this.replyTextFormControl.setValue('');
        this.shiftFirstReplyTemplate(comment);
    }

    shiftFirstReplyTemplate(comment: Comment): void {
        this.reviewerNameTemplate.set(new Label({ text: `@${comment?.reviewer?.displayName}` }));
    }

    getChipColor = (comment: Comment): string => {
        switch (comment.type) {
            case CommentOrigin.MENTION_IN_COMMENT:
                return 'malou-chip--pink-light';
            case CommentOrigin.MENTION_IN_POST:
                return 'malou-chip--warn';
            default:
                return 'malou-chip--purple-light';
        }
    };

    getChipLabel = (comment: Comment): string => {
        const label = this.getChipText(comment);
        return label.slice(0, 1);
    };

    getChipText = (comment: Comment): string => this._translate.instant(`moderation.comment_origin.${comment.type}`) as string;

    reply(): void {
        if (this.currentComment?.type !== CommentOrigin.COMMENT) {
            this._replyToMention();
        } else {
            this._replyToComment();
        }
    }

    startUpdateComments(): void {
        this._spinnerService.show();
        this._commentsService
            .synchronizeRestaurantComments(this._restaurantsService.currentRestaurant._id)

            .subscribe({
                next: () => {
                    this.startStatusWatcher();
                },
                error: (err) => {
                    this._dialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this._translate.instant('moderation.error_synchronize_comments'),
                        message: new HttpErrorPipe(this._translate).transform(err),
                        primaryButton: {
                            label: this._translate.instant('common.close'),
                        },
                    });
                    this._spinnerService.hide();
                },
            });
    }

    startStatusWatcher(): void {
        this.updateStatusSubscription = interval(1000)
            .pipe(
                switchMap(() => this._restaurantsService.restaurantSelected$),
                filter(Boolean),
                switchMap((restaurant: Restaurant) => this._restaurantsService.show(restaurant._id).pipe(map((res) => res.data))),
                map((restaurant: Restaurant) => restaurant?.currentState?.comments?.fetched),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (status) => {
                    const hasFinished = this.hasFinished(status);
                    if (hasFinished) {
                        this._spinnerService.hide();
                        this.updateFinished();
                    }
                },
            });
    }

    updateFinished(): void {
        this.updateStatusSubscription?.unsubscribe();
        const endSub$ = new Subject();
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(Boolean),
                switchMap((restaurant: Restaurant) => this._restaurantsService.update(restaurant._id, { commentsLastUpdate: new Date() })),
                takeUntil(endSub$)
            )
            .subscribe({
                next: () => {
                    endSub$.next(true);
                    this._restaurantsService.reloadSelectedRestaurant();
                },
                error: (err) => {
                    this._dialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this._translate.instant('moderation.error_synchronize_comments'),
                        message: new HttpErrorPipe(this._translate).transform(err),
                        primaryButton: {
                            label: this._translate.instant('common.close'),
                        },
                    });
                    this._spinnerService.hide();
                    endSub$.next(true);
                },
            });
    }

    hasFinished(status: { [key: string]: FetchedState<string> }): boolean {
        if (!status) {
            return true;
        }
        if (
            Object.values(status)
                .map((sts) => sts.status)
                .includes('pending')
        ) {
            return false;
        }
        return true;
    }

    onSearchChange(text: string): void {
        if (text.length >= 3 || (text.length === 0 && text !== this.filters.text)) {
            this.editCommentsFilters({ text });
        }
    }

    onSortByChange(sortBy: string): void {
        this.editCommentsFilters({ sortBy });
    }

    onSortOrderChange(): void {
        this.editCommentsFilters({ sortOrder: this.filters.sortOrder ? this.filters.sortOrder * -1 : undefined });
    }

    editCommentsFilters(filterDiffs: Partial<CommentsFilters>): void {
        this._store.dispatch({ type: CommentsActions.editCommentsFilters.type, filterDiffs });
    }

    toggleFilter(filterKey: ToogleableFiltersKey): void {
        const currentValue = this.filters[filterKey];
        this.editCommentsFilters({ [filterKey]: !currentValue });
    }

    toggleSelectedPlatforms(platform: AvailablePlatform): void {
        const platforms = xor(this.filters.platforms, [platform.key]);
        this.editCommentsFilters({ platforms });
    }

    clearFilters(): void {
        this._store.dispatch({
            type: CommentsActions.resetCommentsFilters.type,
            availablePlatforms: this.availablePlatforms.map((p) => p.key),
        });
    }

    refreshPost(comment: Comment | undefined = this.currentComment): void {
        if (!comment) {
            return;
        }
        if (comment.type === CommentOrigin.COMMENT && comment.post?._id) {
            this._postsService
                .refresh(comment.post._id)
                .pipe(map((res) => res.data))
                .subscribe({
                    next: (post) => {
                        if (post._id) {
                            post.http = this._http;
                            comment.post = new Post(post);
                            comment.post.initWorkingPic('small');
                        }
                    },
                    error: (err) => console.warn('fail silently :>> ', err),
                });
        } else {
            this._mentionsService.refresh(comment._id).subscribe({
                next: (res) => {
                    if (comment.post) {
                        comment.post.socialAttachments = res.data.post.socialAttachments as SocialAttachment[];
                    }
                },
                error: (err) => console.warn('fail silently :>> ', err),
            });
        }
    }

    onScrollDown(): void {
        this.pagination$.next({ ...this.pagination$.value, pageNumber: this.pagination$.value.pageNumber + 1 });
    }

    isLastComment(commentsList: Comment[], currentComment?: Comment): boolean {
        return commentsList[commentsList?.length - 1]?._id === currentComment?._id;
    }

    toggleArchived(comment: Comment): void {
        comment.archived = !comment.archived;
        this._commentsService
            .updateCommentById(comment._id, {
                archived: comment.archived,
                type: comment.type,
            })
            .subscribe({
                error: (err) => {
                    console.warn('Error Archiving:', err);
                    comment.archived = !comment.archived;
                },
            });

        if (!this.filters.archived) {
            this.commentsList.splice(
                this.commentsList.findIndex((c) => c._id === comment._id),
                1
            );
        }

        this._commentsService.archiveComment(comment);
    }

    reverseArray = (array?: CommentReply[]): CommentReply[] => array?.slice()?.reverse() ?? [];

    private _replyToComment(): void {
        const replyText = this.replyTextFormControl.value;
        const currentComment = this.currentComment;
        if (!currentComment || !replyText) {
            return;
        }

        this._spinnerService.show();
        this._commentsService
            .reply(replyText, currentComment._id)
            .pipe(
                catchError((err) => {
                    if (this._isCommentDeleted(err)) {
                        return this._commentsService.deleteCommentById(currentComment._id).pipe(switchMap(() => throwError(() => err)));
                    }
                    return throwError(() => err);
                }),
                tap(() => {
                    if (this.saveModelFormControl.value) {
                        this._createLabel(replyText);
                    }
                })
            )
            .subscribe({
                next: (res) => {
                    if (!this.currentComment) {
                        return;
                    }
                    const newComment = new Comment(res.data);
                    this.currentComment.replies = [...newComment.replies];
                    this.commentsList[this.commentsList.findIndex((comment) => comment._id === this.currentComment?._id)] =
                        this.currentComment;
                    this._spinnerService.hide();
                    this._toastService.openSuccessToast(this._translate.instant('comments_modal.comment_replied'));

                    // go to next comment
                    const currentCommentIndex = this.commentsList.findIndex((comment) => comment._id === this.currentComment?._id);
                    const nextComment = currentCommentIndex !== -1 ? this.commentsList[currentCommentIndex + 1] : this.commentsList[0];
                    this.saveModelFormControl.setValue(false);
                    if (nextComment) {
                        this.currentComment = nextComment;
                        this.replyTextFormControl.setValue('');
                    } else {
                        this.currentComment = this.commentsList[0];
                        this._scrollToComment();
                    }
                    this.shiftFirstReplyTemplate(this.currentComment);
                },
                error: (err) => {
                    this._spinnerService.hide();
                    this._toastService.openErrorToast(this._clarifyError(err, this.currentComment));
                    console.warn('err :>> ', err);
                },
            });
    }

    private _replyToMention(): void {
        const replyText = this.replyTextFormControl.value;
        if (!replyText || !this.currentComment) {
            return;
        }
        this._spinnerService.show();
        const reply: Partial<MentionReply> = {
            text: replyText,
            author: {
                name: this._restaurantsService.currentRestaurant.name,
                picture: this._restaurantsService.currentRestaurant.logo?.getMediaUrl(),
            },
            socialCreatedAt: new Date(),
        };

        this._mentionsService
            .reply(this.currentComment._id, reply)
            .pipe(
                tap(() => {
                    if (this.saveModelFormControl.value) {
                        this._createLabel(replyText);
                    }
                })
            )
            .subscribe({
                next: ({ data }) => {
                    if (!this.currentComment) {
                        return;
                    }
                    this.currentComment.replies = data?.replies?.map((r) => new CommentReply(r as any)) ?? [];
                    this._spinnerService.hide();
                    this._toastService.openSuccessToast(this._translate.instant('comments_modal.comment_replied'));
                    this.saveModelFormControl.setValue(false);
                    this.replyTextFormControl.setValue('');
                    const currentCommentIndex = this.commentsList.findIndex((c) => c._id === this.currentComment?._id);
                    const nextComment = currentCommentIndex !== -1 ? this.commentsList[currentCommentIndex + 1] : this.commentsList[0];
                    if (nextComment) {
                        this.currentComment = nextComment;
                        this.replyTextFormControl.setValue('');
                    } else {
                        this.currentComment = this.commentsList[0];
                        this._scrollToComment();
                    }
                    this.shiftFirstReplyTemplate(this.currentComment);
                },
                error: (err) => {
                    console.warn('err :>> ', err);
                    this._spinnerService.hide();
                    this._toastService.openErrorToast(this._clarifyError(err, this.currentComment));
                },
            });
    }

    private _computeFilterCount(newFilters: CommentsFilters): number {
        const isDefaultPeriod = newFilters.period === MalouPeriod.ALL;
        const isDefaultPlatforms = this.availablePlatforms.every((p) => p.checked);
        const isDefaultStatus = newFilters.answered === true && newFilters.notAnswered === true;
        const isDefaultTaggedIn = newFilters.isComment === true && newFilters.isMention === true;
        const isDefaultOptions = newFilters.archived === false && newFilters.withoutOwnComment === true;

        return [!isDefaultPeriod, !isDefaultPlatforms, !isDefaultStatus, !isDefaultOptions, !isDefaultTaggedIn].filter(Boolean).length;
    }

    private _getAvailablePlatforms$(): Observable<AvailablePlatform[]> {
        return combineLatest([this.currentPlatforms$, this.filters$.pipe(map((filters) => filters.platforms))]).pipe(
            filter(([platforms]) => !!platforms),
            map(([platforms, platformsFilters]) => {
                const commentsPlatforms = PlatformDefinitions.getPlatformKeysWithComment();
                const connectedPlatforms = platforms.map((plat) => plat.key);
                return commentsPlatforms.map((p) => ({
                    key: p,
                    connected: connectedPlatforms.includes(p),
                    checked: !!platformsFilters?.includes(p),
                }));
            }),
            takeUntil(this.killSubscriptions$)
        );
    }

    private _fetchCommentsList(): void {
        combineLatest([this.filters$.pipe(tap(() => this._emptyCommentsListAndResetPagination())), this.pagination$])
            .pipe(
                switchMap(([filters, pagination]) =>
                    this._commentsService.getRestaurantCommentsPaginated(
                        this._restaurantsService.currentRestaurant._id,
                        pagination,
                        filters
                    )
                )
            )
            .subscribe({
                next: ({ data: { comments } }) => {
                    if (!this.currentComment) {
                        const commentFound = comments.find((comment) => [this.data.commentId, this.data.mentionId].includes(comment._id));
                        if (commentFound) {
                            this.changeCurrentComment(commentFound);
                        }
                    }
                    this.commentsList = [...this.commentsList, ...comments];
                    this.loading = false;

                    this._scrollToComment();
                },
            });
    }

    private _fetchPostComments(postSocialId: string): void {
        this.filters$
            .pipe(switchMap((filters) => this._commentsService.getCommentsAndMentionsByPostSocialId(postSocialId, filters)))
            .subscribe({
                next: ({ data: comments }) => {
                    if (!this.currentComment) {
                        const commentFound = comments.find((comment) => [this.data.commentId, this.data.mentionId].includes(comment._id));
                        if (commentFound) {
                            this.changeCurrentComment(commentFound);
                        }
                    }
                    this.commentsList = comments;
                    if (!this.data.commentId || !this.data.mentionId) {
                        this.currentComment = this.commentsList[0];
                    }
                    this.loading = false;
                    this._scrollToComment();
                },
                error: (err) => {
                    console.warn(err);
                },
            });
    }

    private _scrollToComment(): void {
        setTimeout(() => {
            // scroll to current comment
            const currentCommentElement = document.getElementById('comment-' + this.currentComment?._id);
            if (currentCommentElement) {
                currentCommentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 500);
    }

    private _emptyCommentsListAndResetPagination(): void {
        this.commentsList = [];
        this.pagination$.next(DEFAULT_PAGINATION);
    }

    private _clarifyError(err: HttpErrorResponse, comment: Comment | undefined): string {
        if (err?.error?.malouErrorCode?.match(MalouErrorCode.PLATFORM_CREDENTIAL_NOT_FOUND)) {
            return this._translate.instant('moderation.answer_comment_modal.reconnect_platform');
        }
        if (err?.error?.message?.match(/Permissions error/)) {
            return this._translate.instant('moderation.answer_comment_modal.reconnect_with_auth');
        }
        if (err?.error?.message?.match(/must be granted/)) {
            return this._translate.instant('moderation.answer_comment_modal.reconnect_with_auth');
        }
        if (err?.error?.message?.match(/does not exist/)) {
            return this._translate.instant('moderation.answer_comment_modal.deleted_comment');
        }
        if (err?.error?.message?.match(/There was an error posting to this/)) {
            return this._translate.instant('moderation.answer_comment_modal.reply_too_long');
        }
        if (err?.error?.message?.match(/This API call does not support the requested response/)) {
            return this._translate.instant('moderation.answer_comment_modal.reply_not_accepted');
        }
        if (this._commentDoesNotExist(err)) {
            return this._translate.instant('moderation.answer_comment_modal.reconnect', { platformKey: comment?.platformKey });
        }
        return err?.error?.message || err?.message || String(err);
    }

    private _createLabel(message: string): void {
        const tagRegex = /\@([a-zA-Z0-9]*(\_|\.)?)*/;
        const newLabel = new Label({
            restaurantId: this._restaurantsService.currentRestaurant._id,
            text: message.replace(tagRegex, ''),
        });
        this._labelsService.create(newLabel).subscribe({
            next: ({ data }) => {
                this.replyTemplates.update((replyTemplates) => [...replyTemplates, data]);
                this._toastService.openSuccessToast(this._translate.instant('moderation.answer_comment_modal.model_saved'));
            },
            error: (err) => {
                if (err.error.duplicateRecordError && err.status !== 403) {
                    this._toastService.openErrorToast(this._translate.instant('moderation.answer_comment_modal.model_already_exists'));
                }
            },
        });
    }

    private _incrementClickCount(label: Label): void {
        const { clickRate, _id } = label;
        this._labelsService
            .update(_id, {
                clickRate: clickRate + 1,
            })
            .subscribe({
                next: ({ data }) => {
                    const labelUpdated = new Label(data);
                    const labelFound = this.replyTemplates().find((template) => template._id === labelUpdated._id);
                    if (labelFound) {
                        labelFound.clickRate++;
                    }
                },
                error: (err) => {
                    console.warn('err :>> ', err);
                },
            });
    }

    private _commentDoesNotExist(err: HttpErrorResponse): boolean {
        return err?.error?.malouErrorCode === MalouErrorCode.COMMENT_NOT_FOUND;
    }

    private _isCommentDeleted(err: HttpErrorResponse): boolean {
        return err?.error?.message?.match(/does not exist/);
    }

    private _fetchLabels(): void {
        this._labelsService
            .getLabelsByRestaurantId(this._restaurantsService.currentRestaurant._id)
            .pipe(take(1))
            .subscribe({
                next: (res) => {
                    this.replyTemplates.set(
                        res.data
                            ?.map((tpl) => {
                                if (tpl.isDefault) {
                                    return new Label({ ...tpl, text: this._translate.instant(tpl.text) });
                                }
                                return new Label(tpl);
                            })
                            ?.sort((tplA, tplB) => tplB.clickRate - tplA.clickRate)
                    );
                },
                error: (err) => {
                    console.warn(err);
                },
            });
    }
}
