import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { catchError, combineLatest, forkJoin, Observable, of, tap, throwError } from 'rxjs';
import { filter, finalize, map, switchMap, take } from 'rxjs/operators';

import { MergedInformationUpdateDto, PostInformationUpdateDataBodyDto } from '@malou-io/package-dto';
import {
    errorReplacer,
    InformationUpdatePlatformStateStatus,
    isNotNil,
    PlatformAccessType,
    PlatformDefinition,
    PlatformDefinitions,
    PlatformKey,
} from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { InformationUpdatesService } from ':core/services/information-update.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { SuggestionsService } from ':core/services/suggestions.service';
import { ToastService } from ':core/services/toast.service';
import { AttributesUpdateData } from ':modules/informations/attributes-modal/attributes-modal.component';
import { AttributesComponent } from ':modules/informations/attributes/attributes.component';
import { DescriptionUpdateData } from ':modules/informations/description-modal/description-modal.component';
import { DescriptionComponent } from ':modules/informations/description/description.component';
import { HoursModalComponent, HoursModalTabs, HoursUpdateData } from ':modules/informations/hours-modal/hours-modal.component';
import { HoursComponent } from ':modules/informations/hours/hours.component';
import { InformationSuggestionModalComponent } from ':modules/informations/information-suggestions-modal/information-suggestions-modal.component';
import { Suggestion } from ':modules/informations/information-suggestions-modal/store/suggestions.interface';
import { selectCurrentSuggestion } from ':modules/informations/information-suggestions-modal/store/suggestions.reducer';
import { InformationUpdateData } from ':modules/informations/infos-restaurant-modal/infos-restaurant-modal.component';
import { InfosRestaurantComponent } from ':modules/informations/infos-restaurant/infos-restaurant.component';
import { PlatformsComparisonsModalComponent } from ':modules/informations/platforms-comparisons-modal/platforms-comparisons-modal.component';
import { platformsUpdateConfig } from ':modules/informations/platforms-update-config';
import * as InformationsActions from ':modules/informations/store/informations.actions';
import { InformationState, InformationsUpdateStatus } from ':modules/informations/store/informations.interface';
import * as fromStore from ':modules/informations/store/informations.reducer';
import * as PlatformActions from ':modules/platforms/store/platforms.actions';
import { selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import * as RestaurantsActions from ':modules/restaurant-list/restaurant-list.actions';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import {
    getDataBodyDtoFromAttributesUpdateData,
    getDataBodyDtoFromDescriptionUpdateData,
    getDataBodyDtoFromHoursUpdateData,
    getDataBodyDtoFromInformationUpdateData,
    getPreviousDataBodyDtoFromRestaurant,
} from ':shared/helpers/information-updates.helper';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Step } from ':shared/interfaces/step.interface';
import { ComparisonKey, Platform, PlatformComparisonWithStatus, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { DetectedInconsistenciesComponent } from './detected-inconsistencies/detected-inconsistencies.component';
import { InformationSuggestionsComponent } from './information-suggestions/information-suggestions.component';
import { InformationsGaugeComponent } from './informations-gauge/informations-gauge.component';
import { InformationsUpdatesStateComponent } from './informations-updates-state/informations-updates-state.component';

enum UpdateState {
    DIFFERENT = 'different',
    WAITING = 'waiting',
    UPDATED = 'updated',
    ERROR = 'error',
}

interface PlatformUpdateState {
    key: PlatformKey;
    status: InformationUpdatePlatformStateStatus;
}

interface DuplicationResponse {
    success: boolean;
    restaurant: Restaurant;
}

const APPROXIMATE_PRODUCTION_RELEASE_DATE = new Date('2023-12-18T14:00:00');

@Component({
    selector: 'app-general-information',
    templateUrl: './general-information.component.html',
    styleUrls: ['./general-information.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        InfosRestaurantComponent,
        DescriptionComponent,
        HoursComponent,
        AttributesComponent,
        AsyncPipe,
        PlatformLogoPathResolverPipe,
        TranslateModule,
        ApplyPurePipe,
        DetectedInconsistenciesComponent,
        InformationsUpdatesStateComponent,
        InformationsGaugeComponent,
        AsyncPipe,
        InformationSuggestionsComponent,
    ],
})
export class GeneralInformationComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly InformationUpdatePlatformStateStatus = InformationUpdatePlatformStateStatus;
    readonly trackByKeyFn = TrackByFunctionFactory.get('key');

    private readonly _restaurant$: Observable<Restaurant | null> = this._restaurantsService.restaurantSelected$;
    readonly restaurant = toSignal(this._restaurant$);

    readonly restaurantHasAccessToGmb = computed((): boolean => !!this.restaurant()?.access.some((a) => a.platformKey === PlatformKey.GMB));

    private readonly _platforms$: Observable<Platform[]> = this._store.select(selectCurrentPlatforms).pipe(
        map((platforms) => {
            if (platforms?.length) {
                return platforms.map((platform) => new Platform(platform));
            }
            return platforms;
        })
    );
    private readonly _suggestions$ = this._store.select(selectCurrentSuggestion);

    readonly UpdateState = UpdateState;

    readonly isInformationsDirty$: Observable<boolean | undefined> = this._store.select(fromStore.selectCurrentInformationIsDirty);
    readonly updateState = signal<UpdateState | null>(null);
    readonly informationTranslate = this._translateService.instant('information');
    readonly platformPropertiesToUpdate = signal<string[]>([]);
    readonly referencePlatformForRestaurant = signal<PlatformKey | null>(null);
    readonly isLoading = signal(true);
    readonly platformsUpdateState = signal<PlatformUpdateState[]>([]);
    readonly totalDifferencesCount = signal<number>(0);
    readonly showDifferencesButton = signal(false);
    readonly hasStartedUpdate = signal(false);
    private _platformsComparisonsWithStatus = signal<PlatformComparisonWithStatus[]>([]);
    private _isSuggestionModalOpen = signal(false);

    private readonly _destroyRef = inject(DestroyRef);

    readonly isFeaturePlatformsUpdatesEnabled = toSignal(inject(ExperimentationService).isFeatureEnabled$('release-platforms-updates'), {
        initialValue: false,
    });
    readonly suggestionsCount = toSignal(this._suggestions$.pipe(map((suggestion) => suggestion?.comparisons?.length ?? 0)), {
        initialValue: 0,
    });

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _spinnerService: SpinnerService,
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _dialogService: DialogService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _informationUpdatesService: InformationUpdatesService,
        private readonly _translateService: TranslateService,
        private readonly _suggestionService: SuggestionsService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _toastService: ToastService,
        private readonly _experimentationService: ExperimentationService
    ) {}

    ngOnInit(): void {
        this._store
            .select(selectCurrentPlatforms)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((platforms) => {
                const referencePlatformKey = this._restaurantsService.currentRestaurant.isBrandBusiness()
                    ? PlatformKey.FACEBOOK
                    : PlatformKey.GMB;
                const referencePlatform = platforms.find((p) => p.key === referencePlatformKey);
                this._store.dispatch({
                    type: InformationsActions.editInformation.type,
                    information: {
                        platformPropertiesToUpdate: referencePlatform?.platformPropertiesToUpdate,
                    },
                });
            });

        combineLatest([this._restaurant$, this._store.select(fromStore.selectCurrentInformations)])
            .pipe(
                filter(([restaurant]) => !!restaurant),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([restaurant, infos]: [Restaurant, InformationState]) => {
                this.showDifferencesButton.set(
                    !!restaurant.createdAt && new Date(restaurant.createdAt) > APPROXIMATE_PRODUCTION_RELEASE_DATE
                );
                this.platformPropertiesToUpdate.set(infos?.platformPropertiesToUpdate || []);
                this._initializeUpdateState();
            });

        combineLatest([this._restaurant$, this._platforms$])
            .pipe(
                filter(([restaurant, platforms]) => !!(restaurant && platforms)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ([restaurant, platforms]: [Restaurant, Platform[]]) => {
                    this._platformsComparisonsWithStatus.set(
                        this._buildPlatformsComparisonWithStatus(PlatformDefinitions.getNonPrivatePlatforms(), platforms, restaurant)
                    );
                    this.totalDifferencesCount.set(this._getTotalDiff());
                    this.referencePlatformForRestaurant.set(restaurant.isBrandBusiness() ? PlatformKey.FACEBOOK : PlatformKey.GMB);
                    this._store.dispatch({
                        type: InformationsActions.editInformation.type,
                        information: {
                            restaurantId: restaurant._id,
                        },
                    });
                    this._store.dispatch({
                        type: InformationsActions.selectInformation.type,
                        restaurantId: restaurant._id,
                    });
                    if (this._route.snapshot.queryParams.openSpecialHours) {
                        this.openSpecialHoursModal(this._route.snapshot.queryParams.date);
                    }
                    setTimeout(() => {
                        // needed to prevent conflict with route navigation from side navigation
                        this._router.navigate(['./'], { relativeTo: this._route });
                    }, 0);
                    this.isLoading.set(false);
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading.set(false);
                },
            });

        this._suggestions$
            .pipe(
                filter((suggestion) => !this._experimentationService.isFeatureEnabled('release-platforms-updates') && !!suggestion),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (suggestion) => {
                    const hasSuggestions = suggestion?.comparisons?.length;
                    const restaurant = this.restaurant() ?? suggestion?.restaurant;
                    if (hasSuggestions && restaurant) {
                        this.openSuggestions({
                            restaurant,
                            suggestion,
                        });
                    }
                },
            });
    }

    updateRestaurantInformation(data: InformationUpdateData): void {
        const afterPublishValidationFn = (): void => {
            const body: PostInformationUpdateDataBodyDto = {
                restaurantId: this._restaurantsService.currentRestaurant._id,
                data: getDataBodyDtoFromInformationUpdateData(data),
                previousData: getPreviousDataBodyDtoFromRestaurant(this._restaurantsService.currentRestaurant),
            };
            this._informationUpdatesService.postInformationUpdateData(body).subscribe({
                error: (err) => {
                    console.error(JSON.stringify(err, errorReplacer));
                },
            });
        };
        this.updateRestaurant(data, true, afterPublishValidationFn);
    }

    updateRestaurantDescription(data: DescriptionUpdateData): void {
        const afterPublishValidationFn = (): void => {
            const body: PostInformationUpdateDataBodyDto = {
                restaurantId: this._restaurantsService.currentRestaurant._id,
                data: getDataBodyDtoFromDescriptionUpdateData(data),
                previousData: getPreviousDataBodyDtoFromRestaurant(this._restaurantsService.currentRestaurant),
            };
            this._informationUpdatesService.postInformationUpdateData(body).subscribe({
                error: (err) => {
                    console.error(JSON.stringify(err, errorReplacer));
                },
            });
        };
        this.updateRestaurant(data as Partial<Restaurant>, true, afterPublishValidationFn);
    }

    updateRestaurantHours(data: HoursUpdateData): void {
        const afterPublishValidationFn = (): void => {
            const body: PostInformationUpdateDataBodyDto = {
                restaurantId: this._restaurantsService.currentRestaurant._id,
                data: getDataBodyDtoFromHoursUpdateData(data),
                previousData: getPreviousDataBodyDtoFromRestaurant(this._restaurantsService.currentRestaurant),
            };
            this._informationUpdatesService.postInformationUpdateData(body).subscribe({
                error: (err) => {
                    console.error(JSON.stringify(err, errorReplacer));
                },
            });
        };
        this.updateRestaurant(data, true, afterPublishValidationFn);
    }

    updateRestaurantAttributes(data: AttributesUpdateData): void {
        const afterPublishValidationFn = (): void => {
            const body: PostInformationUpdateDataBodyDto = {
                restaurantId: this._restaurantsService.currentRestaurant._id,
                data: getDataBodyDtoFromAttributesUpdateData(data),
                previousData: getPreviousDataBodyDtoFromRestaurant(this._restaurantsService.currentRestaurant),
            };
            this._informationUpdatesService.postInformationUpdateData(body).subscribe({
                error: (err) => {
                    console.error(JSON.stringify(err, errorReplacer));
                },
            });
        };
        this.updateRestaurant(data, true, afterPublishValidationFn);
    }

    updateRestaurant(data: Partial<Restaurant>, validateOnly = true, afterPublishValidationFn?: () => void): void {
        forkJoin([of(data), this._restaurant$.pipe(take(1))])
            .pipe(
                filter(([update, restaurant]) => !!update && !!restaurant),
                tap(() => this._spinnerService.show()),
                switchMap(([update, restaurant]: [Partial<Restaurant>, Restaurant]) =>
                    forkJoin([
                        of(update),
                        this._isReferencePlatformConnected(restaurant, this.referencePlatformForRestaurant()!)
                            ? this._restaurantsService.publish(restaurant._id, this.referencePlatformForRestaurant()!, update, validateOnly)
                            : of({ msg: 'ok' }),
                        of(restaurant),
                    ])
                ),
                switchMap(([update, platformDb, restaurant]) => {
                    this._store.dispatch({
                        type: InformationsActions.editInformation.type,
                        information: {
                            platformPropertiesToUpdate: Object.keys(update),
                            isFooterVisible: true,
                            updateLoadingState: InformationsUpdateStatus.NOT_STARTED,
                        },
                    });
                    this.hasStartedUpdate.set(false);
                    if (!platformDb.msg) {
                        return throwError('error_publish');
                    }
                    afterPublishValidationFn?.();
                    return this._restaurantsService.update(restaurant._id, update);
                }),
                switchMap((res) => {
                    this._restaurantsService.setSelectedRestaurant(res.data);
                    return combineLatest([this.isInformationsDirty$, this._restaurant$]).pipe(take(1));
                }),
                finalize(() => {
                    this._spinnerService.hide();
                })
            )
            .subscribe({
                next: () => {
                    this._spinnerService.hide();
                    if (this.isFeaturePlatformsUpdatesEnabled()) {
                        this._toastService.openSuccessToast(this._translateService.instant('informations.update.sent'));
                    } else {
                        this._toastService.openSuccessToast(this._translateService.instant('informations.update.success'));
                    }
                },
                error: (error) => {
                    console.warn(error);
                    this._spinnerService.hide();
                    if (error?.status === 403 && !!error.error?.casl) {
                        return combineLatest([this.isInformationsDirty$, this._restaurant$]).pipe(take(1));
                    } else if (error?.error?.status === 400) {
                        this._toastService.openErrorToast(this.informationTranslate.detected_error + error?.error?.message);
                    } else if (error?.error?.message?.match(/not_found/)) {
                        this._toastService.openErrorToast(this.informationTranslate.user_not_authorized);
                    } else if (
                        error?.error?.message?.includes('not valid for this location') ||
                        error?.error?.message?.includes('Invalid attribute_id provided')
                    ) {
                        this._toastService.openErrorToast(this.informationTranslate.user_not_authorized);
                    } else {
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(error));
                    }
                    return combineLatest([this.isInformationsDirty$, this._restaurant$]).pipe(take(1));
                },
            });
    }

    startUpdate(): void {
        this.hasStartedUpdate.set(true);
        this._store.dispatch({
            type: InformationsActions.editInformation.type,
            information: {
                isFooterVisible: true,
                shouldStartUpdate: true,
                updateLoadingState: InformationsUpdateStatus.LOADING,
            },
        });
    }

    openSpecialHoursModal(date: Date | null = null): void {
        this._customDialogService
            .open(HoursModalComponent, {
                panelClass: ['malou-dialog-panel'],
                height: undefined,
                width: '750px',
                data: {
                    restaurant: this.restaurant(),
                    prefilledStartDate: date,
                    selectedTab: HoursModalTabs.SPECIAL_HOURS,
                },
            })
            .afterClosed()
            .subscribe((data: Partial<Restaurant>) => {
                this.updateRestaurant(data);
            });
    }

    openSuggestions({ restaurant, suggestion }: { restaurant: Restaurant; suggestion: Suggestion }): void {
        if (!this._isSuggestionModalOpen()) {
            this._isSuggestionModalOpen.set(true);
            this._customDialogService
                .open(InformationSuggestionModalComponent, {
                    width: '850px',
                    minHeight: '30vh',
                    height: 'auto',
                    disableClose: true,
                    data: {
                        comparisons: suggestion.comparisons,
                        restaurant,
                    },
                })
                .afterClosed()
                .pipe(
                    tap(() => {
                        this._isSuggestionModalOpen.set(false);
                    }),
                    filter(Boolean)
                )
                .subscribe({
                    next: (data: Record<string, any>) => this._handleSuggestionUpdated(data),
                    error: () => this._openSuggestionErrorModal(),
                });
            return;
        }
        const currentRestaurant = this.restaurant();
        if (currentRestaurant) {
            this._suggestionService.emitShouldFetchSuggestions(currentRestaurant, true);
        }
    }

    openPlatformsComparisons(): void {
        this._customDialogService
            .open(PlatformsComparisonsModalComponent, {
                height: undefined,
                width: '1000px',
                data: {
                    comparedPlatforms: this._platformsComparisonsWithStatus().filter((platform) =>
                        PlatformDefinitions.shouldCompareInformation(platform.key)
                    ),
                    totalDifferencesCount: this.totalDifferencesCount(),
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result?.updated) {
                        this._toastService.openSuccessToast(
                            this._translateService.instant('information.update_modal.locked_fields_updated')
                        );
                        const restaurant = this.restaurant();
                        if (restaurant) {
                            this._store.dispatch({
                                type: PlatformActions.loadPlatformsData.type,
                                restaurantId: restaurant._id,
                            });
                        }
                    }
                },
            });
    }

    getIconNameAndBackgroundColorFromPlatformUpdateState(platformUpdateState: PlatformUpdateState): { icon: string; color: string } {
        switch (platformUpdateState.status) {
            case InformationUpdatePlatformStateStatus.PENDING:
                return {
                    icon: 'alarm',
                    color: 'warn',
                };
            case InformationUpdatePlatformStateStatus.ERROR:
                return {
                    icon: 'cross',
                    color: 'error',
                };
            case InformationUpdatePlatformStateStatus.DONE:
            default:
                return {
                    icon: 'check',
                    color: 'success',
                };
        }
    }

    getDelayText = (platformKey: string): string => {
        const delayLabelName = platformsUpdateConfig.find((p) => p.key === platformKey)?.delayLabelName;
        return this._translateService.instant(`information.update_modal.${delayLabelName}`);
    };

    getPlatformFullName(platformKey: PlatformKey): string {
        return PlatformDefinitions.getPlatformDefinition(platformKey)?.fullName ?? '';
    }

    onPrepareDescriptionsDuplication(descriptionSize: string): void {
        const titleKey = 'information.description.duplicate_descriptions';
        const customUpdateFn = (restaurant: Restaurant): Partial<Restaurant> => {
            const descriptionsToKeep = restaurant.descriptions.filter((description) => description.size !== descriptionSize);
            const descriptionsToDuplicate =
                this.restaurant()?.descriptions.filter((description) => description.size === descriptionSize) ?? [];
            return {
                descriptions: [...descriptionsToKeep, ...descriptionsToDuplicate],
            };
        };
        const afterPublishValidationFn = (restaurant: Restaurant, update: Partial<Restaurant>): void => {
            const body: PostInformationUpdateDataBodyDto = {
                restaurantId: restaurant._id,
                data: getDataBodyDtoFromDescriptionUpdateData(update),
                previousData: getPreviousDataBodyDtoFromRestaurant(restaurant),
            };
            this._informationUpdatesService.postInformationUpdateData(body).subscribe({
                error: (err) => {
                    console.error(JSON.stringify(err, errorReplacer));
                },
            });
        };
        this._prepareDuplication({ titleKey, customUpdateFn, afterPublishValidationFn });
    }

    onPrepareAttributesDuplication(): void {
        const titleKey = 'information.attributes.duplicate_attributes';
        const customUpdateFn = (_restaurant: Restaurant): Partial<Restaurant> => ({ attributeList: this.restaurant()?.attributeList });
        const afterPublishValidationFn = (restaurant: Restaurant, update: Partial<Restaurant>): void => {
            const body: PostInformationUpdateDataBodyDto = {
                restaurantId: restaurant._id,
                data: getDataBodyDtoFromAttributesUpdateData(update),
                previousData: getPreviousDataBodyDtoFromRestaurant(restaurant),
            };
            this._informationUpdatesService.postInformationUpdateData(body).subscribe({
                error: (err) => {
                    console.error(JSON.stringify(err, errorReplacer));
                },
            });
        };
        this._prepareDuplication({ titleKey, customUpdateFn, afterPublishValidationFn });
    }

    onPrepareHoursDuplication(selectedTab: HoursModalTabs): void {
        const titleKey = this._getTitleHoursDuplicationModal(selectedTab);
        const restaurantKeyToDuplicate = this._getRestaurantHoursKeyToDuplicate(selectedTab);
        const toastSuccessMessage = this._getToastSuccessMessageForHoursDuplication(selectedTab);
        const customUpdateFn = (_restaurant: Restaurant): Partial<Restaurant> => ({
            [restaurantKeyToDuplicate]: this.restaurant()?.[restaurantKeyToDuplicate],
        });
        const afterPublishValidationFn = (restaurant: Restaurant, update: Partial<Restaurant>): void => {
            const body: PostInformationUpdateDataBodyDto = {
                restaurantId: restaurant._id,
                data: getDataBodyDtoFromHoursUpdateData(update),
                previousData: getPreviousDataBodyDtoFromRestaurant(restaurant),
            };
            this._informationUpdatesService.postInformationUpdateData(body).subscribe({
                error: (err) => {
                    console.error(JSON.stringify(err, errorReplacer));
                },
            });
        };
        this._prepareDuplication({
            titleKey,
            customUpdateFn,
            afterPublishValidationFn,
            toastSuccessMessage,
            withoutBrandBusiness: true,
        });
    }
    _getToastSuccessMessageForHoursDuplication(selectedTab: HoursModalTabs): string {
        switch (selectedTab) {
            case HoursModalTabs.REGULAR_HOURS:
                return this._translateService.instant('information.hours.duplicate_regular_hours_success');
            case HoursModalTabs.SPECIAL_HOURS:
                return this._translateService.instant('information.hours.duplicate_special_hours_success');
            default:
                return this._translateService.instant('information.hours.duplicate_other_hours_success');
        }
    }

    private _getRestaurantHoursKeyToDuplicate(selectedTab: HoursModalTabs): string {
        switch (selectedTab) {
            case HoursModalTabs.REGULAR_HOURS:
                return 'regularHours';
            case HoursModalTabs.SPECIAL_HOURS:
                return 'specialHours';
            default:
                return 'otherHours';
        }
    }

    private _getTitleHoursDuplicationModal(selectedTab: HoursModalTabs): string {
        switch (selectedTab) {
            case HoursModalTabs.REGULAR_HOURS:
                return 'information.hours.duplicate_regular_hours';
            case HoursModalTabs.SPECIAL_HOURS:
                return 'information.hours.duplicate_special_hours';
            default:
                return 'information.hours.duplicate_other_hours';
        }
    }

    private _prepareDuplication({
        titleKey,
        customUpdateFn,
        afterPublishValidationFn,
        withoutBrandBusiness = false,
        toastSuccessMessage,
    }: {
        titleKey: string;
        customUpdateFn: (restaurant: Restaurant) => Partial<Restaurant>;
        afterPublishValidationFn: (restaurant: Restaurant, update: Partial<Restaurant>) => void;
        withoutBrandBusiness?: boolean;
        toastSuccessMessage?: string;
    }): void {
        const steps = this._getStepsForDuplication(customUpdateFn, afterPublishValidationFn);

        const initialData: RestaurantsSelectionData = {
            skipOwnRestaurant: true,
            withoutBrandBusiness,
            selectedRestaurants: [],
        };

        this._customDialogService.open(StepperModalComponent, {
            data: {
                steps,
                initialData,
                title: this._translateService.instant(titleKey),
                onSuccess: (results: DuplicationResponse[]) => {
                    this._onDuplicationSuccess({
                        results,
                        toastSuccessMessage: toastSuccessMessage || this._translateService.instant('information.duplication_success'),
                    });
                },
                onError: () => {
                    this._onDuplicationError();
                },
            },
        });
    }

    private _onDuplicationSuccess({ results, toastSuccessMessage }: { results: DuplicationResponse[]; toastSuccessMessage: string }): void {
        const restaurantsInError = results.filter((e) => !e.success);
        const restaurantNamesInError = restaurantsInError.map((e) => e.restaurant.name);
        if (restaurantNamesInError.length) {
            if (restaurantNamesInError.length === results.length) {
                this._toastService.openErrorToast(this._translateService.instant('information.duplication_error'));
            } else {
                this._toastService.openWarnToast(
                    this._translateService.instant('information.duplication_partial_error', {
                        restaurantNames: restaurantNamesInError.join(', '),
                    })
                );
            }
        } else {
            this._toastService.openSuccessToast(toastSuccessMessage);
        }
        this._store.dispatch(RestaurantsActions.loadRestaurants());
        this._customDialogService.closeAll();
    }

    private _onDuplicationError(): void {
        this._toastService.openErrorToast(this._translateService.instant('information.duplication_error'));
        this._customDialogService.closeAll();
    }

    private _getStepsForDuplication(
        customUpdateFn: (restaurant: Restaurant) => Partial<Restaurant>,
        afterPublishValidationFn: (restaurant: Restaurant, update: Partial<Restaurant>) => void
    ): Step[] {
        return [
            {
                component: RestaurantsSelectionComponent,
                subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.subtitle'),
                primaryButtonText: this._translateService.instant('common.duplicate'),
                nextFunction$: (data: RestaurantsSelectionData): Observable<DuplicationResponse[]> =>
                    this._dialogService
                        .open<Observable<DuplicationResponse[]> | null>({
                            title: this._translateService.instant('information.duplication_warning_dialog_title'),
                            message: this._translateService.instant('information.duplication_warning_dialog_message'),
                            variant: DialogVariant.INFO,
                            primaryButton: {
                                label: this._translateService.instant('common.ok'),
                                action: () =>
                                    this._duplicateToRestaurants$(data.selectedRestaurants || [], customUpdateFn, afterPublishValidationFn),
                            },
                            secondaryButton: {
                                label: this._translateService.instant('common.back'),
                                action: () => null,
                            },
                        })
                        .afterClosed()
                        .pipe(
                            filter(Boolean),
                            switchMap((res) => res)
                        ),
            },
        ];
    }

    private _duplicateToRestaurants$(
        restaurants: Restaurant[],
        customUpdateFn: (restaurant: Restaurant) => Partial<Restaurant>,
        afterPublishValidationFn: (restaurant: Restaurant, update: Partial<Restaurant>) => void
    ): Observable<DuplicationResponse[]> {
        const currentRestaurant = this.restaurant();
        if (!currentRestaurant) {
            return of([]);
        }
        const duplicatedFromRestaurantId = currentRestaurant._id;
        const observables = restaurants.map((restaurant) => {
            const update = customUpdateFn(restaurant);
            const referencePlatformForRestaurant = restaurant.isBrandBusiness() ? PlatformKey.FACEBOOK : PlatformKey.GMB;
            const publishRes$: Observable<{
                msg?: string;
            }> = this._isReferencePlatformConnected(restaurant, referencePlatformForRestaurant)
                ? this._restaurantsService.publish(restaurant._id, referencePlatformForRestaurant, update, true)
                : of({ msg: 'ok' });
            return publishRes$.pipe(
                switchMap((publishRes) => {
                    if (!publishRes.msg) {
                        return throwError(() => new Error('error while publishing'));
                    }
                    afterPublishValidationFn(restaurant, update);
                    return this._restaurantsService.update(restaurant._id, update, duplicatedFromRestaurantId).pipe(
                        map((e) => ({
                            success: true,
                            restaurant: e.data,
                        }))
                    );
                }),
                catchError((err) => {
                    console.error(err);
                    return of({ success: false, restaurant });
                })
            );
        });
        return forkJoin(observables);
    }

    private _getTotalDiff(): number {
        let total = 0;
        this._platformsComparisonsWithStatus().forEach((plat) => {
            total += plat.getNbDiff();
        });
        return total;
    }

    private _isReferencePlatformConnected(
        restaurant: Restaurant | null | undefined = this.restaurant(),
        referencePlatformKey: PlatformKey
    ): boolean {
        return !!restaurant?.access.find((a) => a.platformKey === referencePlatformKey);
    }

    private _initializeUpdateState(): void {
        const restaurant = this.restaurant();
        if (!restaurant) {
            return;
        }
        const platformKeysToFetch = restaurant.access
            .filter((a) => a.active)
            .filter((a) => a.accessType !== PlatformAccessType.AUTO)
            .map((a) => a.platformKey);

        const body = [
            {
                restaurantId: restaurant._id,
                platformKeys: platformKeysToFetch,
            },
        ];

        forkJoin({
            mergedInformationUpdateResponseDto: this._informationUpdatesService
                .getMergedInformationUpdateData(body)
                .pipe(map((res) => res.data)),
            hasRestaurantEverBeenUpdated: this._informationUpdatesService
                .hasRestaurantEverBeenUpdated({ restaurantId: restaurant._id })
                .pipe(map((res) => res.data)),
        }).subscribe((data) => {
            const restaurantData = data.mergedInformationUpdateResponseDto.find((e) => e.restaurantId === restaurant._id);
            this._setUpdateState(restaurantData?.mergedInformationUpdatesByPlatform, data.hasRestaurantEverBeenUpdated);
        });
    }

    private _setUpdateState(
        mergedInformationUpdateDto: MergedInformationUpdateDto[] | undefined,
        hasRestaurantEverBeenUpdated: boolean
    ): void {
        if (!hasRestaurantEverBeenUpdated) {
            this.updateState.set(UpdateState.DIFFERENT);
            return;
        }

        if (!mergedInformationUpdateDto || mergedInformationUpdateDto.length === 0) {
            this.updateState.set(UpdateState.UPDATED);
            return;
        }

        this.platformsUpdateState.set(
            mergedInformationUpdateDto.map((mergedInformationUpdates) => ({
                key: mergedInformationUpdates.platformState.key,
                status: mergedInformationUpdates.platformState.status,
            }))
        );

        const atLeastOnePlatformIsInError = mergedInformationUpdateDto.some(
            (p) => p.platformState.status === InformationUpdatePlatformStateStatus.ERROR
        );
        if (atLeastOnePlatformIsInError) {
            this.updateState.set(UpdateState.ERROR);
            return;
        }

        const atLeastOnePlatformIsPending = mergedInformationUpdateDto.some(
            (p) => p.platformState.status === InformationUpdatePlatformStateStatus.PENDING
        );
        if (atLeastOnePlatformIsPending) {
            this.updateState.set(UpdateState.WAITING);
            return;
        }

        const updatesDoneAtWithDelay = mergedInformationUpdateDto
            .map((e) => {
                const platformDelayInMilliseconds = platformsUpdateConfig.find((p) => p.key === e.platformState.key)?.delayInMilliseconds;
                if (!platformDelayInMilliseconds || !e.platformState.updateDoneAt) {
                    return null;
                }
                return DateTime.fromJSDate(e.platformState.updateDoneAt).plus({ milliseconds: platformDelayInMilliseconds }).toJSDate();
            })
            .filter(isNotNil);

        if (updatesDoneAtWithDelay.length > 0 && updatesDoneAtWithDelay.some((e) => e > new Date())) {
            this.updateState.set(UpdateState.WAITING);
            return;
        }

        this.updateState.set(UpdateState.UPDATED);
    }

    private _handleSuggestionUpdated(data: Record<string, any>): void {
        this._isSuggestionModalOpen.set(false);
        this.updateRestaurant(data, false);
        const restaurant = this.restaurant();
        if (restaurant) {
            this._suggestionService.emitShouldFetchSuggestions(restaurant, true);
        }
    }

    private _openSuggestionErrorModal(): void {
        this._isSuggestionModalOpen.set(false);
        this._toastService.openErrorToast(this.informationTranslate.suggestions.unknown_error);
        const restaurant = this.restaurant();
        if (restaurant) {
            this._suggestionService.emitShouldFetchSuggestions(restaurant, true);
        }
    }

    private _buildPlatformsComparisonWithStatus(
        platformList: PlatformDefinition[],
        restaurantPlatforms: Platform[],
        restaurant: Restaurant
    ): PlatformComparisonWithStatus[] {
        return platformList
            .filter((pl) => PlatformDefinitions.shouldCompareInformation(pl.key as PlatformKey))
            .map((p) => {
                const specificComparisonKeysForPlatforms: Partial<Record<PlatformKey, ComparisonKey[]>> = {
                    [PlatformKey.UBEREATS]: [ComparisonKey.NAME, ComparisonKey.SPECIAL_HOURS],
                };
                const restaurantPlatform = restaurantPlatforms.find((rp) => rp.key === p.key) || null;
                return new PlatformComparisonWithStatus(restaurant, restaurantPlatform, p, specificComparisonKeysForPlatforms[p.key]);
            });
    }
}
