import { PostInformationUpdateDataBodyDto } from '@malou-io/package-dto';
import { InformationUpdateAttributeValue, RestaurantAttributeValue, WithRequired } from '@malou-io/package-utils';

import { AttributesUpdateData } from ':modules/informations/attributes-modal/attributes-modal.component';
import { DescriptionUpdateData } from ':modules/informations/description-modal/description-modal.component';
import { HoursUpdateData } from ':modules/informations/hours-modal/hours-modal.component';
import { InformationUpdateData } from ':modules/informations/infos-restaurant-modal/infos-restaurant-modal.component';
import { DescriptionSize, Restaurant, RestaurantAttribute } from ':shared/models';

export function getDataBodyDtoFromInformationUpdateData(data: InformationUpdateData): PostInformationUpdateDataBodyDto['data'] {
    return {
        name: data.name,
        menuUrl: data.menuUrl,
        website: data.website,
        openingDate: data.openingDate?.toISOString(),
        logoUrl: data.logo?.getMediaUrl(),
        coverUrl: data.cover?.getMediaUrl(),
        phone: data.phone,
        categoryName: data.category?.categoryName?.backup,
        secondaryCategoriesNames: data.categoryList?.map((category) => category.categoryName?.backup),
        address: data.address,
        latlng: data.latlng,
    };
}

export function getDataBodyDtoFromDescriptionUpdateData(data: DescriptionUpdateData): PostInformationUpdateDataBodyDto['data'] {
    return {
        longDescription: data.descriptions?.find((e) => e.size === DescriptionSize.LONG && e.text)?.text ?? null,
        shortDescription: data.descriptions?.find((e) => e.size === DescriptionSize.SHORT && e.text)?.text ?? null,
    };
}

export function getDataBodyDtoFromHoursUpdateData(data: HoursUpdateData): PostInformationUpdateDataBodyDto['data'] {
    return {
        isClosedTemporarily: data.isClosedTemporarily,
        regularHours: data.regularHours,
        specialHours: data.specialHours,
    };
}

export function getDataBodyDtoFromAttributesUpdateData(data: AttributesUpdateData): PostInformationUpdateDataBodyDto['data'] {
    return {
        attributes:
            data.attributeList
                ?.filter((e): e is WithRequired<RestaurantAttribute, 'attribute'> => !!e.attribute)
                .filter((e) => e.attributeValue !== RestaurantAttributeValue.NOT_CONCERNED)
                .map((e) => ({
                    name: e.attribute.attributeName.fr ?? e.attribute.attributeName.en,
                    value: e.attributeValue as Omit<
                        RestaurantAttributeValue,
                        RestaurantAttributeValue.NOT_CONCERNED
                    > as InformationUpdateAttributeValue,
                })) ?? null,
    };
}

export function getPreviousDataBodyDtoFromRestaurant(restaurant: Restaurant): PostInformationUpdateDataBodyDto['previousData'] {
    return {
        ...getDataBodyDtoFromInformationUpdateData(restaurant),
        ...getDataBodyDtoFromDescriptionUpdateData(restaurant),
        ...getDataBodyDtoFromHoursUpdateData(restaurant),
        ...getDataBodyDtoFromAttributesUpdateData(restaurant),
    };
}
