import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Restaurant } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

import { Suggestion } from '../information-suggestions-modal/store/suggestions.interface';
import { selectCurrentSuggestion } from '../information-suggestions-modal/store/suggestions.reducer';

@Component({
    selector: 'app-information-suggestions',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        SkeletonComponent,
        EnumTranslatePipe,
        FlagPathResolverPipe,
        PlatformLogoPathResolverPipe,
    ],
    templateUrl: './information-suggestions.component.html',
    styleUrl: './information-suggestions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationSuggestionsComponent {
    readonly openSuggestions = output<{ restaurant: Restaurant; suggestion: Suggestion }>();
    readonly suggestion = toSignal(inject(Store).select(selectCurrentSuggestion));
    private readonly _restaurant = toSignal(inject(RestaurantsService).restaurantSelected$);

    openModalSuggestions(): void {
        const suggestion = this.suggestion();
        const restaurant = this._restaurant() ?? this.suggestion()?.restaurant;
        if (restaurant && suggestion && suggestion?.comparisons?.length) {
            this.openSuggestions.emit({ restaurant, suggestion });
        }
    }
}
