import { NgClass, NgStyle } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    Input,
    OnInit,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';

import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { SentenceCasePipe } from ':shared/pipes/sentence-case.pipe';

import { addOpacityToColor } from '../../helpers/color';
import { Gift } from '../../models/gift';

const GIFT_SUBSECTOR_SIZE = 3;
const CIRCLE_SIZE = 2 * Math.PI;
const EXTRA_TURNS_NUMBER = 6;
const ANIMATION_DURATION = 20000;
const TEXT_DISPLAY_ANGLE = 269;

@Component({
    selector: 'app-animated-wheel-of-fortune',
    templateUrl: './animated-wheel-of-fortune.component.html',
    styleUrls: ['./animated-wheel-of-fortune.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle, ApplyPurePipe, SentenceCasePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimatedWheelOfFortuneComponent implements OnInit {
    @Input() primaryColor: Signal<string>;
    @Input() secondaryColor: Signal<string>;
    @Input() gifts: Signal<Gift[]>;
    @Input() shouldStartAnimation: WritableSignal<boolean>;
    @Input() wheelContainerSize: string;
    @Input() isPreview: WritableSignal<boolean>;

    @ViewChild('wheelDisplay', { static: true }) wheelDisplay: ElementRef<HTMLDivElement>;

    readonly probabilityFn: Signal<(gift: Gift) => number> = computed(() => (gift: Gift): number => {
        const totalWeight = this.gifts().reduce(
            (currentWeight, currentGift) => currentWeight + currentGift.getWeightAccordingToStocks(),
            0
        );
        return totalWeight === 0 ? 0 : Math.round((100 * gift.getWeightAccordingToStocks()) / totalWeight);
    });

    private readonly _isInitialized = signal(false);

    readonly sectors: Signal<Gift[]> = computed(() => {
        if (!this._isInitialized()) {
            return [];
        }
        const sortedGifts = this.gifts().sort((a, b) => (this.probabilityFn()(a) > this.probabilityFn()(b) ? -1 : 1));
        const allSectors = new Array(8).fill(0).map((_, idx) => sortedGifts[idx % sortedGifts.length]);
        return allSectors;
    });

    readonly allSubSectors: Signal<number[]> = computed(() => [...new Array(this.sectors().length * GIFT_SUBSECTOR_SIZE).keys()]);

    readonly getColor: Signal<(index: number) => string> = computed(() => {
        const primaryColor = this.primaryColor();
        const secondaryColor = this.secondaryColor();
        return (index: number): string =>
            (Math.floor(index / GIFT_SUBSECTOR_SIZE) % this.allSubSectors().length) % 2 === 0 ? primaryColor : secondaryColor;
    });

    readonly getRotateDegreeValue: Signal<(index: number) => number | null> = computed(() => (index: number): number | null => {
        const rotateSize = 360 / this.allSubSectors().length;
        return rotateSize * index;
    });

    readonly getRotateDegrees: Signal<(index: number) => string | null> = computed(
        () =>
            (index: number): string | null =>
                `rotate(${this.getRotateDegreeValue()(index)}deg)`
    );

    readonly getRotateDegreesText: Signal<(index: number) => string | null> = computed(
        () =>
            (index: number): string | null =>
                `rotate(${this.getRotateDegreeValue()(index) || 0 + TEXT_DISPLAY_ANGLE}deg)`
    );

    readonly currentlyHoveredColor: Signal<string> = computed(() => {
        const index =
            Math.floor(this.allSubSectors().length - (this._currentAngle() / CIRCLE_SIZE) * this.allSubSectors().length) %
            this.allSubSectors().length;
        return this.getColor()(index);
    });

    readonly isSmallScreen: WritableSignal<boolean> = signal(false);

    private readonly _singleSectorSize: Signal<number> = computed(() =>
        this.allSubSectors()?.length ? CIRCLE_SIZE / this.allSubSectors().length : 0
    );
    private readonly _isWheelTurning: WritableSignal<boolean> = signal(false);
    private readonly _spinAnimation: WritableSignal<Animation | null> = signal(null);
    private readonly _currentAngle: WritableSignal<number> = signal(0);
    private readonly _finalAngle: WritableSignal<number> = signal(0);

    ngOnInit(): void {
        this.isSmallScreen.set(window.screen.width <= 390);
        // needed to trigger sectors() once the component is initialized and the input gifts is no longer undefined
        this._isInitialized.set(true);

        if (this.shouldStartAnimation()) {
            if (!this._isWheelTurning()) {
                // Displaying random result for wheel as draw is being drawn simultaneously
                const selectedGiftIndex = this._randomBetweenRange(0, this.sectors().length);
                this._isWheelTurning.set(true);

                // The gift text we want to land on is situed in sectors with index % 3 = 1, because each gift as 3 subsectors with the text in the middle one
                const chosenTextSectorIndex = 1 + selectedGiftIndex * GIFT_SUBSECTOR_SIZE;
                this._spinWheelUntilIndex(chosenTextSectorIndex);
            }
        }
    }

    isSectorWithText(index: number): boolean {
        return index % GIFT_SUBSECTOR_SIZE === 1;
    }

    addOpacityToColor(color: string, opacity = 0.95): string {
        return addOpacityToColor(color, opacity);
    }

    private _spinWheelUntilIndex(winningSectorIndex: number): void {
        const angAbs = this._moduloWithNegative(this._finalAngle(), CIRCLE_SIZE);

        let finalAngleToReach = this._singleSectorSize() * winningSectorIndex - this._randomBetweenRange(0, this._singleSectorSize());
        finalAngleToReach = this._moduloWithNegative(finalAngleToReach, CIRCLE_SIZE);
        const angleDiff = this._moduloWithNegative(finalAngleToReach - angAbs, CIRCLE_SIZE);

        const extraTurns = CIRCLE_SIZE * EXTRA_TURNS_NUMBER;
        this._finalAngle.update((angle) => angle + angleDiff + extraTurns);

        this._spinAnimation.set(
            this.wheelDisplay.nativeElement.animate([{ rotate: `${this._finalAngle()}rad` }], {
                duration: ANIMATION_DURATION,
                easing: 'cubic-bezier(0.1, 0.7, 0, 1.5)',
                fill: 'forwards',
            })
        );
        this._updateCurrentAngle();

        this._spinAnimation()?.addEventListener('finish', () => {
            this._isWheelTurning.set(false);
        });
    }

    private _updateCurrentAngle = (): void => {
        if (this._isWheelTurning()) {
            const currentProgress = this._spinAnimation()?.effect?.getComputedTiming().progress;
            if (currentProgress) {
                const angDiff = this._finalAngle() - this._currentAngle();
                const angCurr = angDiff * currentProgress;
                const currentAngAbs = this._moduloWithNegative(this._currentAngle() + angCurr, CIRCLE_SIZE);
                this._currentAngle.set(currentAngAbs);
            }
            requestAnimationFrame(this._updateCurrentAngle);
        }
    };

    private _randomBetweenRange(min: number, max: number): number {
        return Math.random() * (max - min) + min;
    }

    private _moduloWithNegative(number: number, modulo: number): number {
        return ((number % modulo) + modulo) % modulo;
    }
}
