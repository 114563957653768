<div class="line-height-none" [ngClass]="{ small: size() === SlideToggleSize.SMALL }" [class.opacity-50]="disabled()">
    <label class="switch" [for]="id" [ngStyle]="{ width: switchWidthInPx() }">
        <input type="checkbox" [value]="checked()" [disabled]="disabled()" [id]="id" [attr.data-testid]="testId()" (change)="toggle()" />
        <div
            class="slider round"
            [class.slider-checked]="checked()"
            [ngClass]="{
                'slider--primary': theme() === 'primary',
                'slider--white': theme() === 'white',
            }">
            @if (size() === SlideToggleSize.MEDIUM) {
                <div class="content-container">
                    @if (checked()) {
                        <span class="active"> {{ activeLabel() }} </span>
                    } @else {
                        <span class="inactive"> {{ inactiveLabel() }} </span>
                    }
                </div>
            }
            <div class="circle" [ngStyle]="{ transform: checked() ? circleStyle() : '' }">
                @if (checked() && size() === SlideToggleSize.MEDIUM) {
                    <mat-icon [svgIcon]="SvgIcon.CHECK"></mat-icon>
                }
                @if (!checked() && size() === SlideToggleSize.MEDIUM) {
                    <mat-icon [svgIcon]="SvgIcon.CROSS"></mat-icon>
                }
            </div>
        </div>
    </label>
</div>
