<ng-container [ngTemplateOutlet]="isChartLoading() || isParentLoading() ? loadingTemplate : chartTemplate"></ng-container>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="secondary-bg px-4 py-6 flex flex-col gap-2 min-h-[350px]">
        <div class="flex justify-end">
            <app-skeleton class="w-[300px]"></app-skeleton>
        </div>
        <div class="flex h-full justify-around">
            <app-skeleton class="!h-full w-[15px]" justifyContent="justify-end" skeletonClass="h-[60%]"></app-skeleton>
            <app-skeleton class="!h-full w-[15px]" justifyContent="justify-end" skeletonClass="h-[45%]"></app-skeleton>
            <app-skeleton class="!h-full w-[15px]" justifyContent="justify-end" skeletonClass="h-[90%]"></app-skeleton>
            <app-skeleton class="!h-full w-[15px]" justifyContent="justify-end" skeletonClass="h-[100%]"></app-skeleton>
            <app-skeleton class="!h-full w-[15px]" justifyContent="justify-end" skeletonClass="h-[40%]"></app-skeleton>
            <app-skeleton class="!h-full w-[15px]" justifyContent="justify-end" skeletonClass="h-[70%]"></app-skeleton>
        </div>
    </app-skeleton>
</ng-template>

<ng-template #chartTemplate>
    <div class="malou-simple-card flex flex-col p-6">
        <div class="relative h-[350px]">
            <canvas
                baseChart
                [datasets]="chartDataSets()"
                [labels]="chartLabels()"
                [options]="chartOptions()"
                [plugins]="[TEXT_LAST_COLUMN, CENTERED_BAR_CHART, Y_AXIS_CUSTOMERS_TITLE, Y_AXIS_SCORE_TITLE, LEGEND_PLUGIN]"></canvas>
        </div>
    </div>
</ng-template>
