import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    GetMergedInformationUpdateBodyDto,
    GetMergedInformationUpdateResponseDto,
    HasRestaurantEverBeenUpdatedBodyDto,
    PostInformationUpdateDataBodyDto,
    UpdateInformationUpdatePlatformStateStatusBodyDto,
    ValidateInformationUpdateBodyDto,
} from '@malou-io/package-dto';
import { ApiResultV2, InformationUpdatePlatformKey } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { UpdateStatus } from ':shared/models/oauth-update-status';

@Injectable({
    providedIn: 'root',
})
export class InformationUpdatesService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/information-updates`;

    constructor(private readonly _http: HttpClient) {}

    postInformationUpdateData(body: PostInformationUpdateDataBodyDto): Observable<void> {
        return this._http.post<void>(`${this.API_BASE_URL}/post-information-update-data`, body);
    }

    validateInformationUpdate(body: ValidateInformationUpdateBodyDto): Observable<void> {
        return this._http.post<void>(`${this.API_BASE_URL}/validate-information-update`, body);
    }

    getMergedInformationUpdateData(
        body: GetMergedInformationUpdateBodyDto
    ): Observable<ApiResultV2<GetMergedInformationUpdateResponseDto>> {
        return this._http.post<ApiResultV2<GetMergedInformationUpdateResponseDto>>(
            `${this.API_BASE_URL}/get-merged-information-update-data`,
            body
        );
    }

    hasRestaurantEverBeenUpdated(body: HasRestaurantEverBeenUpdatedBodyDto): Observable<ApiResultV2<boolean>> {
        return this._http.post<ApiResultV2<boolean>>(`${this.API_BASE_URL}/has-restaurant-ever-been-updated`, body);
    }

    updateInformationUpdatePlatformStatusStatus(body: UpdateInformationUpdatePlatformStateStatusBodyDto): Observable<void> {
        return this._http.post<void>(`${this.API_BASE_URL}/update-information-update-platform-state-status`, body);
    }

    startUpdate(restaurantId: String): Observable<ApiResultV2<Record<InformationUpdatePlatformKey, UpdateStatus>>> {
        return this._http.get<ApiResultV2<Record<InformationUpdatePlatformKey, UpdateStatus>>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/start`,
            {
                withCredentials: true,
            }
        );
    }
}
