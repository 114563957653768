import { Routes } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ForgotPasswordConfirmComponent } from './forgot-password-confirm/forgot-password-confirm.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NeedsConfirmComponent } from './needs-confirm/needs-confirm.component';
import { NewPasswordComponent } from './new-password/new-password.component';

export const AUTH_ROUTES: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'password/forgot',
                component: ForgotPasswordComponent,
            },
            {
                path: 'password/confirm',
                component: ForgotPasswordConfirmComponent,
            },
            {
                path: 'password/new/:id/:token',
                component: NewPasswordComponent,
            },
            {
                path: 'needs-confirm',
                component: NeedsConfirmComponent,
            },
            {
                path: 'confirm/:uid/:token',
                component: ConfirmComponent,
            },
        ],
    },
];
