import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PlatformAccessStatus, PlatformAccessType, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ApiResult, Restaurant } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class UpdatePlatformAccessService {
    private readonly _restaurantsService = inject(RestaurantsService);

    execute(platformKey: PlatformKey.OPENTABLE): Observable<ApiResult<Restaurant>> {
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        return this._restaurantsService.updatePlatformAccess(restaurantId, {
            platformKey,
            accessType: PlatformAccessType.MANAGER,
            status: PlatformAccessStatus.NEED_REVIEW,
            lastUpdated: new Date(),
            lastVerified: undefined,
            active: true,
        });
    }
}
