<ng-container *ngTemplateOutlet="hasFetchedCampaigns ? page : loadinPageTemplate"></ng-container>

<ng-template #page>
    @if (dataSource.data.length > 0) {
        <div class="flex h-full flex-col">
            <div class="flex w-full items-center gap-x-4 px-7.5 pb-1 md:px-6">
                <p class="w-36 whitespace-nowrap md:hidden md:!text-[0.9375rem]">
                    <span class="malou-text-section-title malou-color-text-1">{{ 'campaigns.campaigns' | translate }}</span>
                    <i class="malou-text-18--regular malou-color-text-1 italic">({{ dataSource.filteredData.length }})</i>
                </p>
                <div class="grow">
                    <app-search
                        [debounceTime]="0"
                        [placeholder]="'common.search' | translate"
                        (searchChange)="applyFilter($event)"></app-search>
                </div>
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    mat-icon-button
                    [matTooltip]="'common.delete' | translate"
                    [disabled]="!selection.selected.length"
                    (click)="deleteSelectedCampaigns()">
                    <mat-icon
                        [svgIcon]="SvgIcon.TRASH"
                        [matTooltip]="!selection.selected.length ? ('campaigns.bulk.delete' | translate) : ''"></mat-icon>
                </button>
                <button
                    class="malou-btn-raised--primary btn-xl !h-12.5 md:!hidden"
                    mat-raised-button
                    [disabled]="hasCampaignInProgress"
                    (click)="openCreateCampaignModal()">
                    {{ 'campaigns.create_campaign' | translate }}
                </button>
                <button
                    class="malou-btn-icon--primary btn-xl !hidden md:!flex md:!h-full"
                    mat-icon-button
                    (click)="openCreateCampaignModal()">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.ADD"></mat-icon>
                </button>
            </div>
            <div class="min-h-0 grow overflow-y-auto px-7.5 md:px-6">
                <div class="hidden md:mt-2 md:block">
                    <app-sort-by-filters
                        [sortOptions]="sortOptions"
                        [sortBy]="'createdAt'"
                        [sortOrder]="1"
                        (changeSortBy)="onSortByChange($event)"
                        (toggleSortOrder)="onSortOrderChange()">
                    </app-sort-by-filters>
                </div>
                @if (dataSource.filteredData.length) {
                    <mat-table class="malou-mat-table" matSort [trackBy]="trackByIdFn" [dataSource]="dataSource" #table="matTable">
                        <ng-container matColumnDef="select">
                            <mat-header-cell *matHeaderCellDef>
                                <app-noop-mat-checkbox
                                    classInput="malou-text-13--regular"
                                    color="primary"
                                    [checked]="isAllSelected | applyPure: selection.selected.length"
                                    [indeterminate]="isAllSelectedIndeterminate | applyPure: selection.selected.length"
                                    (click)="$event ? toggleAllFiltered() : null">
                                    <span class="malou-text-13--semibold ml-5 hidden whitespace-normal text-malou-color-text-2 sm:block">
                                        {{ 'campaigns.select_all_campaigns' | translate }}
                                    </span>
                                </app-noop-mat-checkbox>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let campaign; table: table; let i = index">
                                <app-noop-mat-checkbox
                                    classInput="malou-text-13--regular"
                                    color="primary"
                                    [checked]="selection.isSelected(campaign)">
                                </app-noop-mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xxlarge" mat-sort-header>
                                <span>
                                    {{ 'campaigns.name' | translate }}
                                </span>
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let campaign; table: table"
                                class="malou-mat-table-cell-xxlarge font-semibold !text-malou-color-text-1"
                                [matTooltip]="campaign.name">
                                <span>
                                    {{ (campaign.name | shortText: 50) || '-' }}
                                </span>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="createdAt">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'campaigns.date' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let campaign; table: table">
                                <div class="flex items-center gap-1">
                                    <mat-icon
                                        class="icon-btn !hidden !h-4 text-malou-color-primary sm:!block md:ml-[-4px]"
                                        [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
                                    <span>
                                        {{ (campaign.createdAt | formatDate: 'shortDate') || '-' }}
                                    </span>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="platform">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'campaigns.platform_text' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let campaign; table: table">
                                <img class="malou-avatar--xs" [src]="campaign.platformKey | platformLogoPathResolver" />
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xlarge" mat-sort-header>
                                {{ 'campaigns.type' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let campaign; table: table" class="malou-mat-table-cell-xlarge">
                                @switch (campaign.type) {
                                    @case (campaignsType.REVIEW_BOOSTER) {
                                        <div class="malou-chip--rounded-20 malou-chip--pink-light flex gap-2.5">
                                            <span>{{ getCampaignType | applyPure: campaign }}</span>
                                        </div>
                                    }
                                    @default {
                                        <div class="malou-chip--rounded-20 malou-chip--purple-light flex gap-2.5">
                                            <span>{{ getCampaignType | applyPure: campaign }}</span>
                                        </div>
                                    }
                                }
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="mailsNumber">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'campaigns.report.sent_mails' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let campaign; table: table">
                                <div class="flex items-center gap-1">
                                    <mat-icon
                                        class="icon-btn !hidden !h-4 text-malou-color-primary sm:!block md:ml-[-4px]"
                                        [svgIcon]="SvgIcon.PAPER_PLANE"></mat-icon>
                                    <span>
                                        {{
                                            (campaign.contactInteractions.length <= 1 ? 'campaigns.mail' : 'campaigns.mails')
                                                | translate
                                                    : {
                                                          number: campaign.contactInteractions.length,
                                                      }
                                        }}
                                    </span>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="responseRate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'campaigns.report.response_rate' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let campaign; table: table">
                                <div class="flex gap-1">
                                    <div class="malou-text-13--bold">{{ getResponseRate | applyPure: campaign }} %</div>
                                    <div class="hidden sm:block">
                                        {{ 'campaigns.report.response_rate' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="unsubscribedRate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'campaigns.report.unsubscribed_rate' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let campaign; table: table">
                                <div class="flex gap-1">
                                    <div class="malou-text-13--bold">{{ getUnsubscribedRate | applyPure: campaign }} %</div>
                                    <div class="hidden sm:block">
                                        {{ 'campaigns.report.unsubscribed_rate' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let campaign; table: table" (click)="$event.stopPropagation()">
                                <button class="malou-btn-icon" mat-icon-button [matMenuTriggerFor]="actionsMenu">
                                    <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                                </button>
                                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                                    <button class="flex gap-x-3" mat-menu-item (click)="openReport(campaign)">
                                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.EYE"></mat-icon>
                                        <span class="malou-text-14--regular">{{ 'campaigns.open_report' | translate }}</span>
                                    </button>
                                    <button
                                        class="flex gap-x-3"
                                        data-cy="deleteBtn"
                                        mat-menu-item
                                        (click)="deleteOneCampaign(campaign._id)">
                                        <mat-icon class="!h-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                        <span class="malou-text-14--regular">{{ 'campaigns.delete' | translate }}</span>
                                    </button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row
                            *matRowDef="let campaign; columns: displayedColumns; table: table; let i = index"
                            [class.selected]="selection.isSelected(campaign)"
                            (click)="selection.toggle(campaign)"></mat-row>
                    </mat-table>
                } @else {
                    <app-no-results
                        [title]="'campaigns.no_match' | translate"
                        [details]="['campaigns.no_match_details' | translate]"
                        [picture]="'Goggles' | illustrationPathResolver">
                    </app-no-results>
                }
            </div>
        </div>
    } @else {
        <div class="flex-1">
            <div class="flex h-full w-full flex-1 items-center justify-center">
                <div class="flex flex-col items-center">
                    <img class="h-32" loading="lazy" [src]="'Goggles' | illustrationPathResolver" />
                    <p class="malou-text-14--bold mb-4 mt-9 text-center">
                        {{ 'campaigns.no_results' | translate }}
                    </p>
                    <span class="malou-text-10--regular mb-6 text-center">{{ 'campaigns.no_results_details' | translate }}</span>
                    <button class="malou-btn-raised--primary" mat-raised-button (click)="openCreateCampaignModal()">
                        {{ 'campaigns.create_campaign' | translate }}
                    </button>
                </div>
            </div>
        </div>
    }
</ng-template>

<ng-template #no_results>
    <app-no-results
        [title]="'campaigns.no_match' | translate"
        [details]="['campaigns.no_match_details' | translate]"
        [picture]="'Goggles' | illustrationPathResolver">
    </app-no-results>
</ng-template>

<ng-template #loadinPageTemplate>
    <div class="h-full overflow-y-auto px-7.5">
        <div class="w-full">
            <app-skeleton skeletonClass="w-full h-[50px] sm:mb-2 secondary-bg"></app-skeleton>
            <div class="mb-2 mt-7 sm:hidden">
                <app-skeleton
                    flexDirection="flex-row"
                    justifyContent="justify-around"
                    skeletonClass="w-full h-[30px] w-[100px] secondary-bg"
                    [count]="4"></app-skeleton>
            </div>
            <app-skeleton gapClass="gap-1" skeletonClass="w-full h-[50px] sm:h-60 secondary-bg" [count]="7"></app-skeleton>
        </div>
    </div>
</ng-template>
