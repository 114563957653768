<div class="flex h-full flex-col">
    <div class="mb-3">
        <app-search [debounceTime]="0" [placeholder]="'common.search' | translate" (searchChange)="onSearchChange($event)"></app-search>
    </div>
    <div class="flex h-full flex-col overflow-auto">
        <ng-container [ngTemplateOutlet]="generatorLocationsTableTemplate"> </ng-container>
    </div>
</div>

<ng-template #generatorLocationsTableTemplate>
    <div class="flex flex-col gap-y-3">
        @for (restaurantAndLocation of filteredInputData(); track restaurantAndLocation.restaurant._id; let i = $index) {
            <div class="flex justify-between lg:flex-col lg:gap-y-1">
                <div class="ml-2 flex w-[250px] items-center gap-x-2 lg:w-full">
                    <img
                        class="malou-avatar--medium h-[45px] w-[45px]"
                        [src]="
                            restaurantAndLocation?.restaurant?.logo?.getMediaUrl('small') ?? ('default-picture-grey' | imagePathResolver)
                        " />
                    <div class="ml-2">
                        <div class="flex items-center gap-x-1">
                            <div
                                class="flex !h-3 !w-3 items-center justify-center rounded-[50%]"
                                [matTooltip]="
                                    (restaurantAndLocation?.restaurant?.isBrandBusiness() ? 'common.brand_account' : 'common.business')
                                        | translate
                                "
                                [ngClass]="
                                    restaurantAndLocation?.restaurant?.isBrandBusiness()
                                        ? 'bg-malou-color-state-warn'
                                        : 'bg-malou-color-chart-pink--light'
                                ">
                                <mat-icon
                                    class="!h-2 text-white"
                                    [svgIcon]="restaurantAndLocation?.restaurant?.isBrandBusiness() ? 'heart' : 'localisation'"></mat-icon>
                            </div>
                            <div class="malou-text-12--bold leading-6 text-malou-color-text-1">
                                {{ restaurantAndLocation?.restaurant?.name }}
                            </div>
                        </div>
                        @if (!restaurantAndLocation?.restaurant?.isBrandBusiness()) {
                            <div class="malou-text-12 italic leading-6">
                                {{ restaurantAndLocation?.restaurant?.address }}
                            </div>
                        }
                    </div>
                </div>

                <div class="flex w-[250px] flex-col lg:w-full">
                    @if (i === 0) {
                        <span class="malou-text-12 mb-1 text-malou-color-text-1">
                            {{ 'restaurant_edit_locations.city' | translate }}*
                        </span>
                    }
                    <app-input-autocomplete
                        icon="localisation"
                        [id]="getLocationAutocompleteId | applyPure: restaurantAndLocation.restaurant._id"
                        [options]="apiLocationOptions()"
                        [initialValue]="restaurantAndLocation.locationForm.get('apiLocation')?.value"
                        [filterFn]="apiLocationFilterFn"
                        [writeValueFn]="apiLocationWriteValueFn"
                        [displayWithFn]="apiLocationDisplayWithFn"
                        [required]="true"
                        [messageWhenFewResults]="'keywords.generator.no_city_found' | translate"
                        [errorMessage]="apiLocationErrorMessage()[restaurantAndLocation.restaurant._id]"
                        (valueSelected)="onApiLocationSelected($event, restaurantAndLocation.restaurant._id)"
                        (dirty)="onApiLocationDirty(restaurantAndLocation.restaurant._id)">
                    </app-input-autocomplete>
                </div>

                <div class="flex w-[90px] flex-col lg:w-full" [formGroup]="restaurantAndLocation.locationForm">
                    @if (i === 0) {
                        <span class="malou-text-12 mb-1 text-malou-color-text-1">
                            {{ 'restaurant_edit_locations.postal_code' | translate }}*
                        </span>
                    }
                    <app-input-text formControlName="postalCode" (inputTextChange)="onInputTextChange($event)"></app-input-text>
                </div>

                <div class="flex w-[330px] flex-col lg:w-full">
                    @if (i === 0) {
                        <span class="malou-text-12 mb-1 text-malou-color-text-1">
                            {{ 'restaurant_edit_locations.nearby_places' | translate }}
                        </span>
                    }
                    <app-select-chip-list
                        class="w-full"
                        [multiSelectionElementWrap]="true"
                        [displayWith]="displayBrickWith"
                        [values]="recommendedTouristics[restaurantAndLocation.restaurant._id]"
                        [selectedValues]="restaurantAndLocation.locationForm.get('touristics')?.value ?? []"
                        [placeholder]="'keywords.generator.search_location' | translate"
                        [buildValueFromText]="buildNewItem | applyPure: BricksCategory.TOURISTIC_AREA"
                        [hideSelectedValues]="true"
                        [filteredValuesTrackByFn]="trackById"
                        (selectChipListChange)="listChanged($event, restaurantAndLocation.locationForm)">
                        <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
                            <div class="malou-chip--rounded-4 malou-chip--primary !w-auto whitespace-normal">
                                <span class="max-w-[170px] truncate">{{ displayBrickWith | applyPure: value }}</span>
                                <mat-icon
                                    class="malou-chip-icon--right malou-color-primary shrink-0"
                                    [svgIcon]="SvgIcon.REMOVE"
                                    (click)="deleteValueAt(index)"></mat-icon>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" #optionTemplate>
                            <div class="malou-chip--rounded-4 malou-chip--primary">
                                <span>
                                    {{ displayBrickWith | applyPure: value }}
                                </span>
                                <mat-icon class="malou-color-primary z-10 !mr-0 ml-3 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                            </div>
                        </ng-template>
                    </app-select-chip-list>
                </div>
            </div>
        }
    </div>
</ng-template>
