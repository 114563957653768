import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, TimeUnit, TooltipItem } from 'chart.js';
import { isNumber } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { PlatformKey } from '@malou-io/package-utils';

import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    malouChartColorABC,
    malouChartColorDeliveroo,
    malouChartColorFacebook,
    malouChartColorFoursquare,
    malouChartColorGMB,
    malouChartColorInstagram,
    malouChartColorLaFourchette,
    malouChartColorLighterBlue,
    malouChartColorLighterPink,
    malouChartColorMapstr,
    malouChartColorOpentable,
    malouChartColorPagesjaunes,
    malouChartColorResy,
    malouChartColorTripadvisor,
    malouChartColorUberEats,
    malouChartColorYelp,
    malouChartColorZenchef,
} from ':shared/helpers';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type BarChartType = Extract<ChartType, 'bar'>;

const colorByPlatformKey: Record<PlatformKey, string> = {
    [PlatformKey.ABC]: malouChartColorABC,
    [PlatformKey.DELIVEROO]: malouChartColorDeliveroo,
    [PlatformKey.FACEBOOK]: malouChartColorFacebook,
    [PlatformKey.FOURSQUARE]: malouChartColorFoursquare,
    [PlatformKey.GMB]: malouChartColorGMB,
    [PlatformKey.INSTAGRAM]: malouChartColorInstagram,
    [PlatformKey.LAFOURCHETTE]: malouChartColorLaFourchette,
    [PlatformKey.MAPSTR]: malouChartColorMapstr,
    [PlatformKey.OPENTABLE]: malouChartColorOpentable,
    [PlatformKey.PAGESJAUNES]: malouChartColorPagesjaunes,
    [PlatformKey.PRIVATE]: malouChartColorLighterPink,
    [PlatformKey.RESY]: malouChartColorResy,
    [PlatformKey.TRIPADVISOR]: malouChartColorTripadvisor,
    [PlatformKey.UBEREATS]: malouChartColorUberEats,
    [PlatformKey.WEBSITE]: malouChartColorLighterBlue,
    [PlatformKey.YELP]: malouChartColorYelp,
    [PlatformKey.ZENCHEF]: malouChartColorZenchef,
};

export type ReviewsRatingsCount = Partial<Record<PlatformKey, ChartDataArray>>;

@Component({
    selector: 'app-reviews-ratings-evolution-chart',
    templateUrl: './reviews-ratings-evolution-chart.component.html',
    styleUrls: ['./reviews-ratings-evolution-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    providers: [ShortNumberPipe, EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsRatingsEvolutionChartComponent {
    readonly data = input.required<ReviewsRatingsCount>();
    readonly labels = input.required<Date[]>();
    readonly viewBy = input.required<ViewBy>();
    readonly hiddenDatasetIndexes = input.required<number[]>();
    readonly hiddenDatasetIndexesChange = output<number[]>();

    readonly CHART_TYPE: BarChartType = 'bar';

    readonly chartDataSets = computed((): ChartDataset<BarChartType, ChartDataArray>[] => this._computeChartData(this.data()));
    readonly chartLabels = computed((): Date[] => {
        const unfilteredLabels = this._computeChartLabels(this.labels());
        const hiddenDatasetIndexes = this.hiddenDatasetIndexes();
        return hiddenDatasetIndexes.length
            ? unfilteredLabels.filter((_, index) => !hiddenDatasetIndexes.includes(index))
            : unfilteredLabels;
    });
    readonly chartOption = computed((): ChartOptions<BarChartType> => this._computeChartOptions(this.viewBy()));
    private _hiddenDatasetIndexes: number[] = [];

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _translateService: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe
    ) {}

    private _computeChartData(data: ReviewsRatingsCount): ChartDataset<BarChartType, ChartDataArray>[] {
        return Object.keys(data).map((platform: PlatformKey) => ({
            label: this._enumTranslate.transform(platform, 'platform_key'),
            borderColor: this._getChartColor(platform),
            backgroundColor: this._getChartColor(platform),
            xAxisID: 'xAxis',
            yAxisID: 'yAxis',
            data: data[platform] as ChartDataArray,
        }));
    }

    private _getChartColor(platformKey: PlatformKey): string {
        return colorByPlatformKey[platformKey];
    }

    private _computeChartLabels(labels: Date[]): Date[] {
        return labels;
    }

    private _computeChartOptions(viewBy: ViewBy): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems, viewBy),
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            this._hiddenDatasetIndexes.push(index);
                            ci.hide(index);
                            legendItem.hidden = true;
                        } else {
                            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
                            ci.show(index);
                            legendItem.hidden = false;
                        }
                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
                    },
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeToolTipFormatFromViewBy(viewBy),
                        isoWeekday: true,
                        unit: this._computeTimeUnitFromViewBy(viewBy),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number | string) => this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipTitle(item: TooltipItem<BarChartType>[], viewBy: ViewBy): string {
        const title: string = item[0].label;
        if (viewBy === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.e_reputation.reviews_ratings.week_of');
            return `${weekOf} ${title}`;
        }
        return title;
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _computeToolTipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }
}
