<span class="malou-text-10--regular text-malou-color-text-2">
    @switch (platformAccessStatus()) {
        @case (PlatformAccessStatus.MISSING_PERMISSIONS) {
            {{ 'informations.missing_permissions' | translate: { platformName: platformKey() | platformName } }}
            <ng-container [ngTemplateOutlet]="connect"></ng-container>
        }
        @case (PlatformAccessStatus.BAD_ACCESS) {
            @if ([PlatformKey.PAGESJAUNES, PlatformKey.DELIVEROO, PlatformKey.LAFOURCHETTE] | includes: platformKey()) {
                {{ 'informations.invalid_credentials' | translate }}
            } @else if ([PlatformKey.TRIPADVISOR, PlatformKey.YELP, PlatformKey.FOURSQUARE, PlatformKey.RESY] | includes: platformKey()) {
                {{ 'informations.need_gestions_client_as_admin' | translate }}
            } @else {
                {{ 'platforms.connection.bad_access' | translate }}
            }
            <ng-container [ngTemplateOutlet]="connect"></ng-container>
        }
        @case (PlatformAccessStatus.UNCLAIMED_PAGE) {
            {{ 'informations.unclaimed_page_reconnect_platform' | translate: { platformName: platformKey() | platformName } }}
        }
        @case (PlatformAccessStatus.NOT_FOUND) {
            {{ 'informations.not_found_page' | translate }}
        }
        @case (PlatformAccessStatus.INVALID_PAGE) {
            {{ 'informations.page_does_not_exist_yet_details' | translate }}
        }
        <!-- not sure that this status still exists. -->
        @case (PlatformAccessStatus.UNVERIFIED) {
            {{ 'platforms.connection.disconnected_platform' | translate }}
        }
        <!-- not sure that this status still exists. -->
        @case (PlatformAccessStatus.FAILED) {
            {{ 'platforms.connection.failed' | translate }}
        }
        @default {}
    }
</span>

<ng-template #connect>
    <span class="malou-text-10--semibold malou-color-primary cursor-pointer" (click)="goToPlatforms()">
        {{ 'informations.connect' | translate }}
    </span>
</ng-template>
