<div
    class="h-full w-full"
    [ngClass]="{
        safari: isSafari,
    }">
    <ng-container *ngTemplateOutlet="!currentMedia() ? uploadTemplate : reelView"></ng-container>
</div>

<ng-template #reelView>
    <div class="flex h-full w-full flex-col gap-y-4">
        <div class="relative flex w-full flex-[5] justify-center overflow-hidden" id="reelContainer">
            <div
                class="flex aspect-9/16 flex-col items-center justify-center overflow-hidden rounded-xl bg-black transition-all"
                id="videoContainer"
                [ngStyle]="
                    isSafari
                        ? {
                              width: videoPlayerContainerWidth(),
                          }
                        : {}
                ">
                <video
                    class="max-h-none w-full max-w-none"
                    id="videoPlayer"
                    muted
                    loop
                    playsinline
                    [src]="currentMediaUrl"
                    #videoPlayer></video>
            </div>
            <div
                class="absolute left-0 top-0 grid h-full w-full cursor-pointer place-items-center transition-all"
                [ngClass]="{ 'opacity-0 hover:opacity-100': isVideoPlaying() }"
                (click)="playVideo()">
                <div class="grid h-24 w-24 place-items-center rounded-full bg-white bg-opacity-70">
                    <mat-icon class="!h-9 !w-9" color="primary" [svgIcon]="isVideoPlaying() ? 'pause' : 'play'"></mat-icon>
                </div>
            </div>
            <div class="absolute bottom-3 left-0 flex flex-col gap-y-3">
                <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="removeMedia()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                </button>
                <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="muteUnmuteVideo()">
                    <mat-icon color="primary" [svgIcon]="isVideoMuted() ? 'sound-off' : 'sound-on'"></mat-icon>
                </button>
            </div>
        </div>
        <app-media-thumbnail-picker
            [media]="currentMedia()!"
            [thumbnail]="thumbnail"
            [thumbnailOffsetTimeInMs]="thumbnailOffsetTimeInMs"
            [shouldShowSlider]="!isSafari"
            (thumbnailChange)="onThumbnailChanged($event)"
            (sliderImagesLoaded)="onSliderImagesLoaded()"></app-media-thumbnail-picker>
    </div>
</ng-template>

<ng-template #uploadTemplate>
    <app-media-file-uploader
        [accept]="'video/*'"
        [multiple]="false"
        [disabled]="false"
        [maxVideoSize]="maxVideoSize"
        [maxNumberOfFiles]="maxNumberOfFiles"
        (fileProcessed)="onUploadFinished($event)"
        (startReadingFile)="onUploadStarted()"
        (processError)="processError($event)"
        (dragEnter)="dragOver()"
        (dragLeave)="dragLeave()"
        (progressChanged)="onProgressChanged($event)"
        #mediaUploader>
        @if (isLoadingMedia()) {
            <div
                class="flex h-full w-full items-center justify-center border border-malou-color-background-dark bg-malou-color-background-light px-12">
                <mat-progress-bar color="primary" mode="determinate" [value]="mediaLoadingPercentage()"></mat-progress-bar>
            </div>
        } @else {
            <div
                class="grid h-full w-full place-items-center rounded-[10px] border border-malou-color-background-dark bg-malou-color-background-light"
                [ngClass]="{ 'border-dashed border-malou-color-primary': draggingOver() }"
                (click)="$event.stopPropagation()">
                <div class="flex flex-col items-center gap-y-4">
                    <img class="h-[100px] w-[100px]" [src]="'Goggles' | illustrationPathResolver" />
                    <div class="malou-text-12--semibold text-malou-color-text-1">
                        {{ 'edit_image.drag_drop_file' | translate }}
                    </div>
                    <button class="malou-btn-flat" data-testid="upload-file-btn" (click)="mediaUploader.openFilePicker()">
                        {{ 'edit_image.upload' | translate }}
                    </button>
                    <button
                        class="malou-btn-flat"
                        data-testid="choose-from-gallery-btn"
                        (click)="openMediaPickerModal(); $event.stopPropagation()">
                        {{ 'edit_image.browse' | translate }}
                    </button>
                </div>
            </div>
        }
    </app-media-file-uploader>
</ng-template>
