import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';

import { ChecklistService } from ':core/services/checklist.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Checklist } from ':shared/models/checklist';

import { ChecklistComponent } from '../checklist/checklist.component';

@Component({
    selector: 'app-restaurant-container',
    standalone: true,
    imports: [RouterOutlet, ChecklistComponent, AsyncPipe],
    templateUrl: './restaurant-container.component.html',
    // Warning, putting changeDetection: ChangeDetectionStrategy.OnPush here has introduced a bug in displaying multiple views.
    // Please investigate and fix before re-enabling.
    styleUrl: './restaurant-container.component.scss',
})
export class RestaurantContainerComponent {
    readonly checklist$ = this._restaurantsService.restaurantSelected$.pipe(
        filter((restaurant) => !!restaurant?._id),
        switchMap((restaurant) => this._checklistService.getChecklistForRestaurant(restaurant!._id)),
        map(({ data: checklistDto }) => (checklistDto ? new Checklist(checklistDto) : null))
    );
    readonly isChecklistFeatureEnabled$ = this._experimentationService.isFeatureEnabled$('experiment-checklist');

    constructor(
        private readonly _checklistService: ChecklistService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _experimentationService: ExperimentationService
    ) {}
}
