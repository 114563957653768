import { DateTime } from 'luxon';

import { GiftDto, LightRestaurantDto, LightWheelOfFortuneDto, WheelOfFortuneDto } from '@malou-io/package-dto';
import {
    DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR,
    DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR,
    GiftClaimStartDateOption,
    isBeforeNow,
    isBeforeToday,
    PictureSize,
    WheelOfFortuneRedirectionPlatformKey,
} from '@malou-io/package-utils';

import { LocalStorageKey } from '../enums/local-storage-key';
import { Gift } from './gift';
import { Media } from './media';
import { LightRestaurant } from './restaurant';

export interface WheelOfFortuneGlobalSettings {
    giftClaimStartDateOption: GiftClaimStartDateOption;
    giftClaimDurationInDays: number;
    startDate: Date | null;
    endDate: Date | null;
    isEndDateMandatory: boolean;
    redirectionPlatform: WheelOfFortuneRedirectionPlatformKey;
}

export interface EmptyGiftData {
    stockId: string;
    gift: Gift;
}

export enum WheelOfFortuneState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PROGRAMMED = 'PROGRAMMED',
}

export enum WheelOfFortuneType {
    AGGREGATED = 'AGGREGATED',
    RESTAURANT = 'RESTAURANT',
}

export interface WheelOfFortuneParameters {
    primaryColor: string;
    secondaryColor: string;
    media: Media | null;
    giftClaimStartDateOption: GiftClaimStartDateOption;
    giftClaimDurationInDays: number;
    redirectionPlatform: WheelOfFortuneRedirectionPlatformKey;
}

export interface LightWheelOfFortuneParameters {
    primaryColor: string;
    secondaryColor: string;
    mediaId: string | null;
    giftClaimStartDateOption: GiftClaimStartDateOption;
    giftClaimDurationInDays: number;
    redirectionPlatform: WheelOfFortuneRedirectionPlatformKey;
}

interface IWheelOfFortune {
    id?: string;
    restaurants: LightRestaurant[];
    totemIds: string[];
    gifts: Gift[];
    parameters: WheelOfFortuneParameters;
    startDate: Date | null;
    endDate: Date | null;
}

export class WheelOfFortune implements IWheelOfFortune {
    id?: string;
    restaurants: LightRestaurant[];
    totemIds: string[];
    gifts: Gift[];
    parameters: WheelOfFortuneParameters;
    startDate: Date | null;
    endDate: Date | null;

    constructor(init: IWheelOfFortune | WheelOfFortuneDto) {
        this.id = init.id;
        this.startDate = init.startDate ? new Date(init.startDate) : null;
        this.endDate = init.endDate ? new Date(init.endDate) : null;
        this.gifts = init.gifts.map((gift: Gift | GiftDto) => new Gift(gift));
        this.totemIds = init.totemIds;
        this.restaurants = init.restaurants.map((restaurant: LightRestaurant | LightRestaurantDto) => new LightRestaurant(restaurant));
        this.parameters = {
            primaryColor: init.parameters.primaryColor || DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR,
            secondaryColor: init.parameters.secondaryColor || DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR,
            media: init.parameters.media ? new Media(init.parameters.media) : null,
            giftClaimStartDateOption: init.parameters.giftClaimStartDateOption,
            giftClaimDurationInDays: init.parameters.giftClaimDurationInDays,
            redirectionPlatform: init.parameters.redirectionPlatform,
        };
    }

    getState(): WheelOfFortuneState {
        if (!this.startDate || (this.endDate && isBeforeNow(this.endDate))) {
            return WheelOfFortuneState.INACTIVE;
        }

        if (isBeforeNow(this.startDate)) {
            return WheelOfFortuneState.ACTIVE;
        }

        return WheelOfFortuneState.PROGRAMMED;
    }

    getGiftWinProbability(gift: Gift): number {
        const totalWeight = this.gifts.reduce((currentWeight, currentGift) => currentWeight + currentGift.getWeightAccordingToStocks(), 0);
        return totalWeight === 0 ? 0 : Math.round((100 * gift.getWeightAccordingToStocks()) / totalWeight);
    }

    getFormattedStartDate(): string {
        return this._getFormattedDate(this.startDate);
    }

    getFormattedEndDate(): string {
        return this._getFormattedDate(this.endDate);
    }

    isExpired(): boolean {
        return this.endDate ? isBeforeToday(this.endDate) : false;
    }

    getWheelOfFortuneUrl({ isFromTotem = true }: { isFromTotem: boolean }): string {
        return `${window.location.origin}/wheel-of-fortune?wofId=${this.id}&isFromTotem=${isFromTotem}`;
    }

    getWheelOfFortuneUrlForRestaurant({
        restaurantId,
        isFromTotem = true,
    }: {
        restaurantId: string;
        isFromTotem?: boolean;
    }): string | null {
        const wheelOfFortuneRestaurantIds = this.restaurants.map(({ id }) => id);
        if (!wheelOfFortuneRestaurantIds.includes(restaurantId)) {
            return null;
        }
        return `${window.location.origin}/wheel-of-fortune?wofId=${this.id}&restaurantId=${restaurantId}&isFromTotem=${isFromTotem}`;
    }

    getLogoUrl(size: string = PictureSize.ORIGINAL): string | null {
        return this.parameters.media?.getMediaUrl(size) || null;
    }

    getFirstGiftWithEmptyStock(): EmptyGiftData | null {
        const alreadyDisplayedEmptyStockIds = JSON.parse(localStorage.getItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_EMPTY_STOCK_MODAL) || '[]');

        let stockId: string | null = null;
        const firstGiftWithEmptyStock = this.gifts?.find((gift) =>
            gift.stocks.find((stock) => {
                if (stock.quantity === 0 && !alreadyDisplayedEmptyStockIds.includes(stock.id)) {
                    stockId = stock.id;
                    return true;
                }
                return false;
            })
        );
        if (!firstGiftWithEmptyStock || !stockId) {
            return null;
        }
        return { stockId, gift: firstGiftWithEmptyStock };
    }

    private _getFormattedDate(date: Date | null): string {
        if (!date) {
            return '';
        }

        return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT).replace(/\//g, '.');
    }
}

export class LightWheelOfFortune {
    id?: string;
    parameters: LightWheelOfFortuneParameters;
    startDate: Date | null;
    endDate: Date | null;

    constructor(init: LightWheelOfFortuneDto) {
        this.id = init.id;
        this.startDate = init.startDate ? new Date(init.startDate) : null;
        this.endDate = init.endDate ? new Date(init.endDate) : null;
        this.parameters = {
            primaryColor: init.parameters.primaryColor || DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR,
            secondaryColor: init.parameters.secondaryColor || DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR,
            mediaId: init.parameters.mediaId,
            giftClaimStartDateOption: init.parameters.giftClaimStartDateOption,
            giftClaimDurationInDays: init.parameters.giftClaimDurationInDays,
            redirectionPlatform: init.parameters.redirectionPlatform,
        };
    }

    isExpired(): boolean {
        return this.endDate ? isBeforeToday(this.endDate) : false;
    }
}
