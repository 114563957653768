<form class="mb-3 flex w-full items-center justify-between gap-x-4 sm:flex-wrap" [formGroup]="form">
    <app-search
        class="grow sm:basis-full sm:pb-2 sm:pr-0"
        [placeholder]="'common.search' | translate"
        [debounceTime]="500"
        (searchChange)="onSearchChange($event)"></app-search>

    <app-select
        class="min-w-[210px]"
        formControlName="selection"
        [values]="SELECTION_OPTIONS"
        [displayWith]="selectionDisplayWith"
        (selectChange)="selectionChange($event)">
    </app-select>

    <div class="flex items-center gap-x-4">
        <span class="malou-text-12--medium malou-color-text-1">{{ 'admin.access.show_treated' | translate }}</span>
        <app-slide-toggle
            [activeLabel]="'common.yes' | translate"
            [inactiveLabel]="'common.no' | translate"
            [switchWidth]="70"
            [checked]="showProcessed"
            (onToggle)="toggleShowTreated(!$event)">
        </app-slide-toggle>
    </div>

    <mat-button-toggle-group class="malou-group-toggle-btn" formControlName="viewType">
        <mat-button-toggle [value]="PlatformsManagementViewType.BUSINESS">
            <span>{{ 'admin.platform_management.business' | translate }}</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="PlatformsManagementViewType.PLATFORM">
            <span>{{ 'admin.platform_management.platform' | translate }}</span>
        </mat-button-toggle>
    </mat-button-toggle-group>
</form>
