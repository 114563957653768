import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
    AiInteractionRelatedEntityCollection,
    AiInteractionType,
    APP_DEFAULT_LANGUAGE,
    ApplicationLanguage,
    mapLanguageStringToApplicationLanguage,
    TranslationSource,
} from '@malou-io/package-utils';

import { AiService } from ':core/services/ai.service';
import { ToastService } from ':core/services/toast.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { PrivateReview } from ':shared/models/private-review';
import { Restaurant } from ':shared/models/restaurant';
import { Review } from ':shared/models/review';
import { OpenaiErrorService } from ':shared/openai-prompt/openai-errors.service';

@Component({
    selector: 'app-review-translations',
    templateUrl: './review-translations.component.html',
    styleUrls: ['./review-translations.component.scss'],
    standalone: true,
    imports: [TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewTranslationsComponent {
    readonly review = input.required<Review | PrivateReview>();
    readonly restaurant = input.required<Restaurant | null>();
    readonly showTranslation = input.required<boolean>();

    readonly showTranslatedText = output<boolean>();

    private readonly _aiService = inject(AiService);
    private readonly _openaiErrorService = inject(OpenaiErrorService);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _store = inject(Store);

    readonly isTranslating = signal(false);
    readonly currentLang = localStorage.getItem(LocalStorageKey.LANG) || APP_DEFAULT_LANGUAGE;
    readonly originalReviewLang = computed(() => {
        const reviewLang = this.review().lang;
        const prettyReviewLang = this._translateService.instant(`common.langs.${reviewLang}`);
        return this.currentLang === ApplicationLanguage.EN ? prettyReviewLang : prettyReviewLang.toLowerCase();
    });

    showOriginal(): void {
        this.showTranslatedText.emit(false);
    }

    translateReviewText(): void {
        const currentLangAsApplicationLanguage = mapLanguageStringToApplicationLanguage(this.currentLang);
        const restaurantId = this.restaurant()?._id;
        if (currentLangAsApplicationLanguage !== this.currentLang || !restaurantId) {
            return;
        }

        if (!this.review().hasTranslations(currentLangAsApplicationLanguage)) {
            this.isTranslating.set(true);

            this._aiService
                .translateText({
                    relatedEntityId: this.review()._id,
                    relatedEntityCollection: AiInteractionRelatedEntityCollection.REVIEWS,
                    type: AiInteractionType.REVIEW_TRANSLATION,
                    restaurantId,
                    text: this.review().text,
                    lang: currentLangAsApplicationLanguage,
                })
                .subscribe({
                    next: (res) => {
                        const { data: translatedText } = res;
                        const source = TranslationSource.SERVERLESS_AI_TEXT_GENERATOR;
                        this._store.dispatch(
                            ReviewsActions.addTranslationToReview({
                                reviewId: this.review()._id,
                                text: translatedText,
                                language: currentLangAsApplicationLanguage,
                                source,
                            })
                        );
                        this.showTranslatedText.emit(true);
                        this.isTranslating.set(false);
                        this._aiService.handleAiInteraction();
                        this._addTranslationToReview(translatedText, currentLangAsApplicationLanguage, source);
                    },
                    error: (err) => {
                        console.warn('err >', err);
                        if (err.error?.status === 429) {
                            this._openaiErrorService.openRateLimitErrorDialog();
                        } else {
                            this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                        }
                        this.isTranslating.set(false);
                    },
                });
        } else {
            this.showTranslatedText.emit(true);
        }
    }

    private _addTranslationToReview(translatedText: string, language: ApplicationLanguage, source: TranslationSource): void {
        this._reviewsService
            .addTranslationToReview(this.review()._id, translatedText, language, source, this.review().isPrivate())
            .subscribe({
                next: () => {
                    console.info('Translation added to review');
                },
                error: (err) => {
                    console.error('Error while adding translation to review', err);
                },
            });
    }
}
