import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';

import { RestaurantsService } from ':core/services/restaurants.service';
import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';

@Component({
    selector: 'app-reputation',
    templateUrl: './reputation.component.html',
    styleUrls: ['./reputation.component.scss'],
    standalone: true,
    imports: [NavigationHeaderComponent, RouterOutlet],
})
export class ReputationComponent {
    readonly TABS: NavigationTab[] = [
        { label: `${this._translate.instant('reputation.customer_reviews')}`, link: './reviews' },
        { label: this._translate.instant('reputation.campaigns'), link: './campaigns' },
    ];

    readonly redirecting$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly _translate: TranslateService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _router: Router
    ) {
        combineLatest([this._restaurantsService.restaurantSelected$, this.redirecting$])
            .pipe(
                filter(([_, redirecting]) => !redirecting),
                takeUntilDestroyed()
            )
            .subscribe(([restaurant]) => {
                if (restaurant?.isBrandBusiness()) {
                    this.redirecting$.next(true);
                    this._router.navigate(['restaurants', restaurant._id, 'dashboard']);
                }
            });
    }
}
