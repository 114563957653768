// doc: https://betterprogramming.pub/sync-your-state-in-local-storage-with-ngrx-9d6ceba93fc0
import { Action, ActionReducer } from '@ngrx/store';
import { cloneDeep, merge, pick } from 'lodash';

import { objectIdValidator } from '@malou-io/package-dto';

import { initialState as initialAggregatedStatisticsState } from ':modules/aggregated-statistics/store/aggregated-statistics.reducer';
import { initialState as initialReviewsState } from ':modules/reviews/store/reviews.reducer';
import { initialState as initialUserState } from ':modules/user/store/user.reducer';

import { Restaurant } from '../shared/models';

type AppState = Record<string, any>;

export const INITIAL_STORE_STATE = {
    user: initialUserState,
    aggregatedStatistics: initialAggregatedStatisticsState,
    reviews: initialReviewsState,
};

export const MAX_ACCOUNTS_STATES_TO_SAVE = 2;

export const LOGOUT_ACTION = 'LOGOUT_ACTION';

function setSavedState(state: any, localStorageKey: string): void {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
}

export function getSavedState(localStorageKey: string | null): any {
    if (!localStorageKey) {
        return undefined;
    }
    const savedState = JSON.parse(localStorage.getItem(localStorageKey) as any);
    if (!_isLocalStateFullyLoaded(savedState)) {
        return undefined;
    }
    const mappedState = {
        ...savedState,
        aggregatedStatistics: {
            ...savedState.aggregatedStatistics,
            filters: {
                ...savedState.aggregatedStatistics.filters,
                dates: {
                    ...savedState.aggregatedStatistics.filters.dates,
                    startDate: savedState.aggregatedStatistics.filters.dates.startDate
                        ? new Date(savedState.aggregatedStatistics.filters.dates.startDate)
                        : null,
                    endDate: savedState.aggregatedStatistics.filters.dates.endDate
                        ? new Date(savedState.aggregatedStatistics.filters.dates.endDate)
                        : null,
                },
                restaurantsIds: savedState.aggregatedStatistics.filters.restaurantsIds,
                roiRestaurantsIds: savedState.aggregatedStatistics.filters.roiRestaurantsIds,
            },
        },
        reviews: {
            ...savedState.reviews,
            filters: {
                ...savedState.reviews.filters,
                startDate: savedState.reviews.filters.startDate ? new Date(savedState.reviews.filters.startDate) : null,
                endDate: savedState.reviews.filters.endDate ? new Date(savedState.reviews.filters.endDate) : null,
                restaurants: savedState.reviews.filters.restaurants.map((restaurant: any) => new Restaurant(restaurant)),
                aggregatedViewRestaurants: savedState.reviews.filters.aggregatedViewRestaurants.map(
                    (restaurant: any) => new Restaurant(restaurant)
                ),
            },
        },
    };

    return mappedState;
}

// the keys from state which we'd like to save.
const stateKeys = ['aggregatedStatistics.filters', 'reviews.filters'];
// the key for the local storage.
const syncLocalStorageKey = '__ngrx_state__';

export function storageMetaReducer<S extends AppState, A extends Action = Action>(reducer: ActionReducer<S, A>): any {
    let onInit = true;
    return function (state: S, action: A): S {
        if (action.type === LOGOUT_ACTION) {
            onInit = true;
            return state;
        }
        const nextState = reducer(state, action);
        const userId = nextState.user?.infos?._id;
        const userEmail = nextState.user?.infos?.email;
        if (!userId) {
            return nextState;
        }
        const localStorageKey = getLocalStorageKey(userId) as string;
        if (onInit) {
            onInit = false;
            const savedState = getSavedState(localStorageKey) || {};
            return merge(cloneDeep(nextState), savedState);
        }
        const stateToSave = pick(nextState, stateKeys);

        if (_isNotAdminManagerAccount(userEmail) && _canSaveAccountState(userId)) {
            setSavedState(stateToSave, localStorageKey);
        }
        return nextState;
    };
}

export function logout<S extends AppState, A extends Action = Action>(reducer: ActionReducer<S, A>): any {
    return function (state, action) {
        return reducer(action.type === LOGOUT_ACTION ? { ...state, ...INITIAL_STORE_STATE } : state, action);
    };
}

function _canSaveAccountState(userId: string): Boolean {
    const savedAccountsStates: string[] = [];
    let isCurrentAccountSaved = false;
    for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i)?.includes(syncLocalStorageKey)) {
            const key = localStorage.key(i);
            if (key) {
                const [, stateUserId] = key?.split('.');
                if (objectIdValidator.safeParse(stateUserId).success) {
                    savedAccountsStates.push(key);
                    if (userId === stateUserId) {
                        isCurrentAccountSaved = true;
                    }
                }
            }
        }
    }

    if (isCurrentAccountSaved) {
        return true;
    }

    if (savedAccountsStates.length >= MAX_ACCOUNTS_STATES_TO_SAVE) {
        return false;
    }
    return true;
}

function _isLocalStateFullyLoaded(state: any): boolean {
    return stateKeys
        .map((key) => (key.includes('.') ? key.split('.')[0] : key))
        .reduce((acc, key) => acc && state?.hasOwnProperty(key), true);
}

function _isNotAdminManagerAccount(email: string): boolean {
    return email !== 'admin-app@malou.io';
}

export function getLocalStorageKey(userId?: string): string | null {
    if (!userId) {
        return null;
    }
    return `${syncLocalStorageKey}.${userId}`;
}
