import { Component, computed, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';

@Component({
    selector: 'app-seo',
    templateUrl: './seo.component.html',
    styleUrls: ['./seo.component.scss'],
    standalone: true,
    imports: [NavigationHeaderComponent, RouterOutlet],
})
export class SeoComponent {
    readonly restaurant = toSignal(this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil)), { initialValue: null });
    readonly tabs: Signal<NavigationTab[]> = computed(() => {
        const tabs = [{ label: this._translate.instant('app_sidebar.seo.informations'), link: './informations' }];
        if (!this.restaurant()?.isBrandBusiness()) {
            tabs.push({ label: this._translate.instant('app_sidebar.seo.posts'), link: './posts' });
        }
        return tabs;
    });
    private _redirecting = false;

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _router: Router,
        private readonly _translate: TranslateService
    ) {
        this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil), takeUntilDestroyed()).subscribe((restaurant) => {
            const informationsActive = this._router.isActive(`/restaurants/${restaurant._id}/seo/informations`, {
                fragment: 'ignored',
                queryParams: 'ignored',
                matrixParams: 'exact',
                paths: 'subset',
            });
            if (restaurant.isBrandBusiness() && !informationsActive && !this._redirecting) {
                this._redirecting = true;
                this._router.navigate(['/restaurants', restaurant._id, 'seo', 'informations']);
            }
        });
    }
}
