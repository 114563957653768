<div class="mx-7.5 h-full py-5 md:mx-6">
    @if (!isLoading && areAllPlatformsDisconnected) {
        <div class="mb-3.5 h-full">
            <ng-container *ngTemplateOutlet="noplatformavailable"></ng-container>
        </div>
    } @else {
        @if (!screenSizeService.isPhoneScreen) {
            <div class="flex h-full">
                <!-- Conversation left Side -->
                <div class="flex w-[33vw] min-w-[33vw] flex-col overflow-hidden">
                    <app-messages-filters
                        [conversationStatusFilters]="(conversationStatusFilters$ | async) ?? []"
                        [availablePlatforms$]="availablePlatforms$"
                        [hasUpdateFinished$]="hasUpdateFinished$"
                        [areAllSocialPlatformsDisconnected]="areAllSocialPlatformsDisconnected"
                        [currentGoogleAgent]="currentGoogleAgent"
                        [checkedConversationsWithMessages]="checkedConversationsWithMessages"
                        (onConversationStatusChange)="conversationStatusFiltersChange($event)"
                        (onChangeSelectedPlatforms)="changeSelectedPlatforms($event)"
                        (onSearchValueChange)="searchChanged($event)"
                        (onSynchronizeMessages)="synchronizeMessages()"
                        (onOpenSettingsModal)="openSettingsModal()"
                        (onMarkConversationsAsUnread)="markSelectedConversationsAsUnread()"
                        (onMarkConversationsAsRead)="markSelectedConversationsAsRead()"
                        (onAddConversationsToFavorites)="addSelectedConversationsToFavorites()"
                        (onRemoveConversationsFromFavorites)="removeSelectedConversationsFromFavorites()"
                        (onUnarchiveConversations)="unarchiveSelectedConversations()"
                        (onArchiveConversations)="archiveSelectedConversations()">
                    </app-messages-filters>
                    <!-- Warnings & Errors container from google messages here -->
                    @if (showGoogleErrorPanel && !(restaurant | applySelfPure: 'isBrandBusiness') && isGoogleMessagingFeatureEnabled()) {
                        <div class="error-panel malou-color-state-error relative mb-5 h-fit w-full rounded-[10px] px-4 py-3">
                            @if (creatingMessagesProfile$ | async) {
                                <mat-progress-bar class="mb-2" color="warn" mode="indeterminate"></mat-progress-bar>
                            }
                            <div class="absolute right-1 top-1">
                                <button
                                    class="malou-btn-icon"
                                    mat-icon-button
                                    [disabled]="creatingMessagesProfile$ | async"
                                    (click)="showGoogleErrorPanel = false">
                                    <mat-icon class="!h-[16px] !w-[16px]" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                                </button>
                            </div>
                            <ng-container *ngTemplateOutlet="unfoundagent"></ng-container>
                        </div>
                    }
                    <!-- END Warnings container -->
                    <div
                        class="hide-scrollbar !m-0 h-full overflow-y-auto !p-0"
                        [ngClass]="{
                            '!border-0': isLoading || errorLoadingConversations,
                            'bg-[#F9FAFF]': isLoading || errorLoadingConversations,
                            'malou-card': !isLoading && !errorLoadingConversations,
                        }">
                        <app-conversations
                            [isLoading]="isLoading"
                            [errorLoadingConversations]="errorLoadingConversations"
                            [conversationsWithMessages]="conversationsWithMessages"
                            [areAllPlatformsDisconnected]="areAllPlatformsDisconnected"
                            [selectedConversation]="selectedConversation"
                            [selectedTabIndex]="selectedTabIndex"
                            [filteredConversations]="filteredConversations"
                            [paginations]="paginations"
                            [conversationsTextSearch]="conversationsTextSearch"
                            [checkedConversations]="checkedConversationsWithMessages"
                            (onSelectedTabChange)="onSelectedTabChange($event)"
                            (onLoadMoreConversations)="loadMoreConversations()"
                            (onSelectConversation)="onSelectConversation($event)"
                            (onCheckConversation)="onCheckConversation($event)"
                            (onChangeArchivedConversation)="onChangeArchivedConversation($event)"
                            (onRemoveConversationFromFavorite)="onRemoveConversationFromFavorite($event)">
                        </app-conversations>
                    </div>
                </div>
                <!-- END Conversation left Side -->
                <!-- Conversation right panel -->
                @if (!isLoading && (checkedPlatforms$ | async)?.length && selectedConversation) {
                    <div class="h-full grow">
                        <app-conversation-panel
                            [scrollDownEvent]="scrollDown"
                            [conversationChanged$]="conversationChanged$"
                            (conversationClosed)="selectedConversation = null">
                        </app-conversation-panel>
                    </div>
                }
                <!-- END Conversation right panel -->
            </div>
        } @else {
            @if (!selectedConversation) {
                <div class="full-h flex min-h-[75vh] w-full flex-col">
                    <app-messages-filters
                        [conversationStatusFilters]="(conversationStatusFilters$ | async) ?? []"
                        [availablePlatforms$]="availablePlatforms$"
                        [hasUpdateFinished$]="hasUpdateFinished$"
                        [areAllSocialPlatformsDisconnected]="areAllSocialPlatformsDisconnected"
                        [currentGoogleAgent]="currentGoogleAgent"
                        [checkedConversationsWithMessages]="checkedConversationsWithMessages"
                        (onConversationStatusChange)="conversationStatusFiltersChange($event)"
                        (onChangeSelectedPlatforms)="changeSelectedPlatforms($event)"
                        (onSearchValueChange)="searchChanged($event)"
                        (onSynchronizeMessages)="synchronizeMessages()"
                        (onOpenSettingsModal)="openSettingsModal()"
                        (onMarkConversationsAsUnread)="markSelectedConversationsAsUnread()"
                        (onMarkConversationsAsRead)="markSelectedConversationsAsRead()"
                        (onAddConversationsToFavorites)="addSelectedConversationsToFavorites()"
                        (onRemoveConversationsFromFavorites)="removeSelectedConversationsFromFavorites()"
                        (onUnarchiveConversations)="unarchiveSelectedConversations()"
                        (onArchiveConversations)="archiveSelectedConversations()">
                    </app-messages-filters>
                    <!-- Warnings & Errors container from google messages here -->
                    @if (showGoogleErrorPanel && isGoogleMessagingFeatureEnabled()) {
                        <div class="error-panel malou-color-state-error relative mb-5 min-h-[50px] w-full rounded-[10px] px-4 py-3">
                            @if (creatingMessagesProfile$ | async) {
                                <mat-progress-bar class="mb-2" color="warn" mode="indeterminate"></mat-progress-bar>
                            }
                            <div class="absolute right-1 top-1">
                                <button
                                    class="malou-btn-icon"
                                    mat-icon-button
                                    [disabled]="creatingMessagesProfile$ | async"
                                    (click)="showGoogleErrorPanel = false">
                                    <mat-icon class="!h-[16px] !w-[16px]" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                                </button>
                            </div>
                            <ng-container *ngTemplateOutlet="unfoundagent"></ng-container>
                        </div>
                    }
                    <!-- END Warnings container -->
                    <div class="hide-scrollbar malou-card !m-0 h-full overflow-y-auto !p-0">
                        <app-conversations
                            [isLoading]="isLoading"
                            [errorLoadingConversations]="errorLoadingConversations"
                            [conversationsWithMessages]="conversationsWithMessages"
                            [areAllPlatformsDisconnected]="areAllPlatformsDisconnected"
                            [selectedConversation]="selectedConversation"
                            [selectedTabIndex]="selectedTabIndex"
                            [filteredConversations]="filteredConversations"
                            [paginations]="paginations"
                            [conversationsTextSearch]="conversationsTextSearch"
                            [checkedConversations]="checkedConversationsWithMessages"
                            (onSelectedTabChange)="onSelectedTabChange($event)"
                            (onLoadMoreConversations)="loadMoreConversations()"
                            (onSelectConversation)="onSelectConversation($event)"
                            (onCheckConversation)="onCheckConversation($event)"
                            (onChangeArchivedConversation)="onChangeArchivedConversation($event)"
                            (onRemoveConversationFromFavorite)="onRemoveConversationFromFavorite($event)">
                        </app-conversations>
                    </div>
                </div>
            }
            @if (!isLoading && (checkedPlatforms$ | async)?.length && selectedConversation) {
                <div>
                    <app-conversation-panel
                        [scrollDownEvent]="scrollDown"
                        [conversationChanged$]="conversationChanged$"
                        (conversationClosed)="selectedConversation = null">
                    </app-conversation-panel>
                </div>
            }
        }
    }
</div>

<ng-template #noplatformavailable>
    <app-no-connected-platforms [message]="'messages.please_connect_platforms' | translate"></app-no-connected-platforms>>
</ng-template>

<ng-template #googleError>
    @if (!isLoading && !areAllPlatformsDisconnected && !(restaurant | applySelfPure: 'isBrandBusiness') && agentStatus !== 'AVAILABLE') {
        <div class="panel">
            <!-- unavailable agent  -->
            @if (agentStatus === 'UNFOUND' && !areAllPlatformsDisconnected) {
                <div class="h-full">
                    <ng-container *ngTemplateOutlet="unfoundagent"></ng-container>
                </div>
            }
            <!-- End unavailable agnet -->
        </div>
    }
</ng-template>

<!-- Google messages specific -->

<ng-template #unfoundagent>
    <span class="malou-color-state-error malou-text-10--regular">
        {{ 'messages.conversations.invalid_gmb_permissions' | translate }}
        <br />
        <button class="malou-color-state-error malou-text-10--semibold !p-0" mat-button (click)="initBusinessMessagesAgent()">
            {{ 'messages.conversations.activate_messaging' | translate }}
        </button>
    </span>
</ng-template>

<!-- mobile version -->
