import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PlatformAccessStatus, PlatformKey } from '@malou-io/package-utils';

import { NoResultsComponent } from ':shared/components/no-results/no-results.component';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { InformationUpdateDetailErrorComponent } from './information-update-detail-error/information-update-detail-error.component';
import { InformationUpdateStateErrorComponent } from './information-update-state-error/information-update-state-error.component';

@Component({
    selector: 'app-information-updates-state-modal',
    standalone: true,
    imports: [
        TranslateModule,
        MatIconModule,
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        TranslateModule,
        NoopMatCheckboxComponent,
        NoResultsComponent,
        SkeletonComponent,
        SortByFiltersComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        ApplyPurePipe,
        FormatDatePipe,
        IllustrationPathResolverPipe,
        PlatformLogoPathResolverPipe,
        ShortTextPipe,
        PlatformNamePipe,
        FormatDatePipe,
        InformationUpdateStateErrorComponent,
        InformationUpdateDetailErrorComponent,
    ],
    templateUrl: './information-updates-state-modal.component.html',
    styleUrl: './information-updates-state-modal.component.scss',
})
export class InformationUpdatesStateModalComponent implements OnInit {
    @ViewChild('otherUpdatesTable', { read: MatSort, static: true }) sort1: MatSort;
    @ViewChild('errorUpdatesTable', { read: MatSort, static: true }) sort2: MatSort;

    readonly translateService = inject(TranslateService);

    readonly errorUpdatesSource = new MatTableDataSource<any>([
        {
            platformKey: PlatformKey.LAFOURCHETTE,
            platformAccessStatus: PlatformAccessStatus.BAD_ACCESS,
        },
        {
            platformKey: PlatformKey.TRIPADVISOR,
            platformAccessStatus: PlatformAccessStatus.BAD_ACCESS,
        },
        {
            platformKey: PlatformKey.PAGESJAUNES,
            platformAccessStatus: PlatformAccessStatus.BAD_ACCESS,
        },
        {
            platformKey: PlatformKey.FACEBOOK,
            platformAccessStatus: PlatformAccessStatus.MISSING_PERMISSIONS,
        },
        {
            platformKey: PlatformKey.TRIPADVISOR,
            platformAccessStatus: PlatformAccessStatus.INVALID_PAGE,
        },
        {
            platformKey: PlatformKey.FOURSQUARE,
            platformAccessStatus: PlatformAccessStatus.UNCLAIMED_PAGE,
        },
    ]);

    readonly date = new Date();

    readonly SvgIcon = SvgIcon;

    readonly otherUpdatesSource = new MatTableDataSource<any>([
        {
            platformKey: PlatformKey.PAGESJAUNES,
            state: true,
            infosUpdated: 'Horaires, Description, Caracteristiques',
        },
        {
            platformKey: PlatformKey.DELIVEROO,
            state: true,
            infosUpdated: 'Horaires, Description, Caracteristiques',
        },
        {
            platformKey: PlatformKey.FOURSQUARE,
            state: false,
            infosUpdated: 'Horaires, Description, Caracteristiques',
        },
        {
            platformKey: PlatformKey.RESY,
            state: true,
            infosUpdated: 'Horaires, Description, Caracteristiques',
        },
        {
            platformKey: PlatformKey.OPENTABLE,
            state: true,
            infosUpdated: 'Horaires, Description, Caracteristiques',
        },
        {
            platformKey: PlatformKey.ZENCHEF,
            state: false,
            infosUpdated: 'Horaires, Description, Caracteristiques',
        },
        {
            platformKey: PlatformKey.FACEBOOK,
            state: true,
            infosUpdated: 'Horaires, Description, Caracteristiques',
        },
        {
            platformKey: PlatformKey.MAPSTR,
            state: true,
            infosUpdated: 'Horaires, Description, Caracteristiques',
        },
    ]);

    errorUpdatesDisplayedColumns: string[] = ['platformKey', 'state', 'details'];
    otherDisplayedColumns: string[] = ['platformKey', 'state', 'infosUpdated'];

    constructor(
        private readonly _dialogRef: MatDialogRef<InformationUpdatesStateModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {}
    ) {}

    ngOnInit(): void {
        this.errorUpdatesSource.sort = this.sort2;
        this.otherUpdatesSource.sort = this.sort1;
    }

    close(): void {
        this._dialogRef.close();
    }
}
