import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, Observable } from 'rxjs';

import { GmbAgentLaunchDto, GmbLocationLaunchDto } from '@malou-io/package-dto';

import { environment } from ':environments/environment';
import { ApiResult, GoogleCommunicationsAgent, GoogleCommunicationsLocation } from ':shared/models';

@Injectable({ providedIn: 'root' })
export class GoogleBusinessCommunicationsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/messages/google`;

    constructor(private readonly _http: HttpClient) {}

    getRestaurantAgent(restaurantId: string): Observable<ApiResult<GoogleCommunicationsAgent>> {
        return this._http.get<ApiResult<GoogleCommunicationsAgent>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/agents`);
    }

    createAgent(restaurantId: string): Observable<ApiResult<GoogleCommunicationsAgent>> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/agents/create`, {});
    }

    createLocation(restaurantId: string): Observable<ApiResult<GoogleCommunicationsLocation>> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/locations/create`, {});
    }

    verifyAgent(restaurantId: string): Observable<ApiResult> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/agents/verify`, {});
    }

    verifyLocation(restaurantId: string): Observable<ApiResult> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/locations/verify`, {});
    }

    launchAgent(restaurantId: string): Observable<ApiResult<GmbAgentLaunchDto>> {
        return this._http.post<ApiResult<GmbAgentLaunchDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/agents/launch`, {});
    }

    launchLocation(restaurantId: string): Observable<ApiResult<GmbLocationLaunchDto>> {
        return this._http.post<ApiResult<GmbLocationLaunchDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/locations/launch`, {});
    }

    updateAgent(restaurantId: string, update: any): Observable<ApiResult<GoogleCommunicationsAgent>> {
        return this._http.put<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/agents/update`, { update });
    }

    unlaunchAgent(restaurantId: string, agentName: string): Observable<ApiResult<GoogleCommunicationsAgent>> {
        return this._http.put<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/agents/update/unlaunch`, { agentName });
    }

    getAgentVerificationState(restaurantId: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/agents/verification_state`);
    }

    getLocationVerificationState(restaurantId: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/locations/verification_state`);
    }

    activateGoogleMessaging$(restaurantId: string): Observable<ApiResult> {
        return this.createAgent(restaurantId).pipe(
            concatMap(() => this.createLocation(restaurantId)),
            concatMap(() => this.verifyAgent(restaurantId)),
            concatMap(() => this.verifyLocation(restaurantId)),
            concatMap(() => this.launchAgent(restaurantId)),
            concatMap(() => this.launchLocation(restaurantId))
        );
    }
}
