<app-modal-structure
    [title]="'platforms.connection_new.zenchef.step_0.step_name' | translate"
    [primaryButtonText]="'platforms.connection_new.zenchef.step_0.open_zenchef' | translate"
    [secondaryButtonText]="'common.cancel' | translate"
    [showTertiaryButton]="false"
    [tertiaryButtonText]="'platforms.connection_new.shared.see_tutorial' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="close.emit({})"
    (tertiaryClick)="onTertiaryClick()">
    <div class="flex flex-col items-center gap-y-8 pt-20">
        <img class="h-auto w-full" [src]="'zenchef-api-token' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
        <div
            class="malou-text-10--regular malou-text-color-2"
            [innerHtml]="'platforms.connection_new.zenchef.step_0.description' | translate: { class: 'malou-text-10--semibold' }"></div>
    </div>
</app-modal-structure>
