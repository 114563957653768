<div class="malou-simple-card min-w-0 flex-1 px-6 py-7">
    <div class="h-12.5">
        <div class="malou-text-section-title malou-color-text-1">
            {{ 'statistics.e_reputation.platforms_ratings.marks' | translate }}
        </div>
    </div>
    <div class="flex gap-6">
        <app-statistics-seo-platforms-ratings
            class="w-full"
            (hasDataChange)="hasDataChange.emit($event)"
            (isLoadingEvent)="isPlatformRatingsLoading.set($event)">
        </app-statistics-seo-platforms-ratings>
        <app-review-ratings-average-kpi
            (hasDataChange)="hasDataChange.emit($event)"
            (isLoadingEvent)="isReviewRatingsLoading.set($event)"></app-review-ratings-average-kpi>
    </div>
</div>
