import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import * as SuggestionsActions from './suggestions.actions';
import { Suggestion, SuggestionState } from './suggestions.interface';

export const adapter: EntityAdapter<Suggestion> = createEntityAdapter<Suggestion>({
    selectId: (suggestion: Suggestion) => suggestion.restaurant?._id || '',
});

export const defaultSuggestionState: Suggestion = {
    comparisons: [],
    restaurant: null,
};

export const initialState: SuggestionState = adapter.getInitialState({ restaurantId: undefined });

export const selectSuggestionState = createFeatureSelector<SuggestionState>('suggestions');

export const selectSuggestionEntities = createSelector(selectSuggestionState, (state) => state.entities);

export const selectCurrentSuggestionId = createSelector(selectSuggestionState, (state) => state.restaurantId);

export const selectCurrentSuggestion = createSelector(
    selectSuggestionEntities,
    selectCurrentSuggestionId,
    (suggestionEntities, suggestionId) => (suggestionId ? suggestionEntities[suggestionId] : null)
);

const suggestionsReducer = createReducer(
    initialState,
    on(SuggestionsActions.loadInformationSuggestions, (state, { restaurant }) => ({ ...state, restaurant })),
    on(SuggestionsActions.editInformationSuggestions, (state, { suggestion }) => {
        const entityState = suggestion.restaurant?._id ? (state.entities[suggestion.restaurant._id] ?? {}) : {};
        const realSuggestion = {
            ...defaultSuggestionState,
            ...entityState,
            ...suggestion,
        };
        return adapter.upsertOne(realSuggestion, { ...state, restaurantId: suggestion.restaurant?._id });
    })
);

export function reducer(state: SuggestionState | undefined, action: Action): SuggestionState {
    return suggestionsReducer(state, action);
}
