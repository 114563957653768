import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

export enum ButtonStyle {
    // In row, right aligned, one gray background, one purple background
    CLASSIC = 'CLASSIC',
    // In column, all gray background
    LONG_TEXT = 'LONG_TEXT',
}

/**
 * Component that represent our modal structure.
 *
 * It contains:
 * - A header with title and close button
 * - A scrollable body
 * - A footer with 2 buttons
 */
@Component({
    selector: 'app-modal-structure',
    templateUrl: './modal-structure.component.html',
    styleUrls: ['./modal-structure.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatButtonModule, PlatformLogoPathResolverPipe],
})
export class ModalStructureComponent {
    title = input.required<string>();
    titleImage = input<{ class: string; src: string } | null>(null);
    buttonStyle = input.required<ButtonStyle>();

    primaryButtonText = input.required<string>();
    primaryButtonSubText = input<string | null>(null);
    primaryButtonDisabled = input<boolean>(false);

    secondaryButtonText = input.required<string>();
    secondaryButtonSubText = input<string | null>(null);

    tertiaryButtonText = input('');

    readonly SvgIcon = SvgIcon;
    showTertiaryButton = input<boolean>(false);

    readonly ButtonStyle = ButtonStyle;
    close = output<void>();
    primaryClick = output<void>();
    secondaryClick = output<void>();
    tertiaryClick = output<void>();

    onClose(): void {
        this.close.emit();
    }

    onPrimaryButtonClick(): void {
        this.primaryClick.emit();
    }

    onSecondaryButtonClick(): void {
        this.secondaryClick.emit();
    }

    onTertiaryButtonClick(): void {
        this.tertiaryClick.emit();
    }
}
