<div class="malou-dialog">
    <div class="malou-dialog__body !mr-0 flex h-[80vh] flex-col justify-between !p-0">
        <ng-container [ngTemplateOutlet]="resentMail() ? resentMailTemplate : mainTemplate"></ng-container>

        <div class="malou-text-12--semibold left-0 flex w-full items-baseline justify-center pb-3 text-center">
            <span>{{ 'play_wheel_of_fortune.powered_by' | translate }}</span>
            <img class="ml-1 max-h-2" [src]="'malou_logo_black' | imagePathResolver" />
        </div>
    </div>
</div>

<ng-template #mainTemplate>
    <div class="hide-scrollbar mt-4 flex h-full flex-col items-center justify-between overflow-y-auto px-6" #scrollableElement>
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'gift' }"></ng-container>
        <div class="flex flex-col" [ngClass]="{ 'mt-14': hasGivenDetails() }">
            <span class="malou-text-24--bold text-center text-malou-color-text-1">{{
                'play_wheel_of_fortune.display_draw_details.you_won' | translate
            }}</span>
        </div>
        @if (!hasGivenDetails()) {
            <span class="malou-text-15--semibold mb-2 text-center">{{
                'play_wheel_of_fortune.give_contact_details.give_details_to_get_gift' | translate
            }}</span>
            <div class="flex w-full flex-col px-2.5" [formGroup]="clientDetailsForm">
                <app-input-text
                    class="mb-2 w-full"
                    formControlName="name"
                    autocomplete="given-name"
                    [required]="true"
                    [title]="'play_wheel_of_fortune.give_contact_details.firstname' | translate">
                </app-input-text>
                <div class="flex gap-2" formGroupName="phone">
                    <div class="flex-1">
                        <app-select
                            formControlName="prefix"
                            [required]="false"
                            [title]="'play_wheel_of_fortune.give_contact_details.phone' | translate"
                            [placeholder]="'play_wheel_of_fortune.give_contact_details.phone' | translate"
                            [values]="PHONE_CODES"
                            [errorMessage]="phonePrefix?.dirty && phonePrefix?.errors?.error"
                            [displayWith]="displayWithPhone"></app-select>
                    </div>
                    <div class="flex flex-3">
                        <app-input-text
                            class="w-full"
                            formControlName="digits"
                            autocomplete="tel-national"
                            [title]="'&nbsp;'"
                            [placeholder]="'clients.add_manual.phone_placeholder' | translate"
                            [errorMessage]="phoneDigits?.dirty && phoneDigits?.errors?.error"></app-input-text>
                    </div>
                </div>
                <app-input-text
                    class="mb-2 w-full"
                    formControlName="email"
                    autocomplete="email"
                    [required]="true"
                    [title]="'play_wheel_of_fortune.give_contact_details.email' | translate"
                    [autocapitalize]="'none'"
                    [errorMessage]="email?.invalid ? email?.errors?.error : null">
                </app-input-text>
            </div>
        } @else {
            <div class="my-4 flex h-full flex-col justify-between">
                <div class="malou-text-34--bold text-center" [ngStyle]="{ color: data.primaryColor }">
                    {{ gift()?.name }}
                </div>
                <div class="flex flex-col items-center text-center">
                    <div class="malou-text-24--semibold mb-3 text-malou-color-text-1">
                        {{ 'play_wheel_of_fortune.display_draw_details.see_mailbox' | translate }}
                    </div>
                    <div class="malou-text-12 italic">{{ 'play_wheel_of_fortune.display_draw_details.check_spam' | translate }}</div>
                </div>
                <div class="malou-text-13 flex items-center justify-center xxs:flex-col">
                    {{ 'play_wheel_of_fortune.display_draw_details.email_not_received' | translate }}
                    <button class="malou-btn-flat malou-text-weight-semibold" mat-button (click)="resendMail()">
                        {{ 'play_wheel_of_fortune.display_draw_details.resend_mail' | translate }}
                    </button>
                </div>
            </div>
        }
    </div>

    @if (!hasGivenDetails()) {
        <div class="px-6" [ngClass]="{ 'malou-box-shadow-top': showBoxShadow() }">
            <div class="mt-3 flex w-full justify-start">
                <mat-checkbox color="primary" [checked]="hasAcceptedRules()" (change)="toggleHasAcceptedRules()">
                    <div class="mr-2 flex items-center">
                        <span class="malou-text-11--regular malou-color-text-1">{{
                            'play_wheel_of_fortune.give_contact_details.i_agree' | translate
                        }}</span>
                        <span class="malou-text-11--bold malou-color-text-1 ml-1 cursor-pointer underline" (click)="openGameRules()">{{
                            'play_wheel_of_fortune.give_contact_details.rules' | translate
                        }}</span>
                    </div>
                </mat-checkbox>
            </div>
            <button
                class="malou-btn-raised--dark btn-xl !mt-3 mb-5 flex !h-[69px] w-full items-center justify-center"
                id="tracking_wof_get_my_gift_cta"
                mat-raised-button
                [disabled]="!hasCorrectlyFilledForm() || isSavingClientData()"
                [ngClass]="{ 'cursor-not-allowed': !hasCorrectlyFilledForm() }"
                (click)="saveClientDetails()">
                @if (isSavingClientData()) {
                    <app-malou-spinner class="flex justify-center" size="small" color="white"></app-malou-spinner>
                } @else {
                    {{ 'play_wheel_of_fortune.give_contact_details.get_my_gift' | translate }}
                }
            </button>
        </div>
    }
</ng-template>

<ng-template #resentMailTemplate>
    <div class="flex h-[75vh] flex-col items-center">
        <div class="mb-[15%] mt-[10%] flex justify-center">
            <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'mailbox' }"></ng-container>
        </div>

        <div class="malou-text-24--semibold mb-8 text-center text-malou-color-text-1">
            {{ 'play_wheel_of_fortune.display_draw_details.mail_resent.title' | translate }}
        </div>
        <div class="malou-text-12--regular text-center italic text-malou-color-text-2">
            {{ 'play_wheel_of_fortune.display_draw_details.mail_resent.spam' | translate }}
        </div>
    </div>
</ng-template>

<ng-template let-img="img" #imageWithBackground>
    <div class="relative flex h-[115px] w-[115px] justify-center">
        <div class="relative h-[110px] w-[110px] rounded-full" [ngStyle]="{ 'background-color': data.primaryColor }"></div>
        <img
            class="absolute left-[15px] h-[100px] w-[100px]"
            [ngClass]="{
                'top-[12px]': img === 'gift',
                'top-[6px]': img === 'mailbox',
            }"
            [alt]="img"
            [src]="img | imagePathResolver: { folder: 'wheels-of-fortune' }" />
    </div>
</ng-template>
