<button
    class="malou-btn-raised--primary ml-3 mr-1 !h-12"
    data-testid="reviews-download-btn"
    mat-raised-button
    [matMenuTriggerFor]="actionsMenu">
    {{ 'common.download' | translate }}
</button>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
    <button class="flex gap-x-3" data-testid="reviews-download-pdf-btn" mat-menu-item (click)="onDownloadFormatSelect(DownloadFormat.PDF)">
        <span class="malou-text-14--regular text-malou-color-text-2">{{ 'reviews.download_insights.pdf' | translate }}</span>
    </button>
    <button class="flex gap-x-3" data-testid="reviews-download-csv-btn" mat-menu-item (click)="onDownloadFormatSelect(DownloadFormat.CSV)">
        <span class="malou-text-14--regular text-malou-color-text-2">{{ 'reviews.download_insights.csv' | translate }}</span>
    </button>
</mat-menu>
