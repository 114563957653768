import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SpecialTimePeriod } from ':shared/models';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';

@Pipe({
    name: 'specialTimePeriodText',
    standalone: true,
})
export class SpecialTimePeriodPipe implements PipeTransform {
    constructor(
        private readonly _translate: TranslateService,
        private readonly _formatTimePipe: FormatTimePipe
    ) {}

    transform(hour: SpecialTimePeriod): string {
        const isEnglishNotation = this._translate.currentLang === 'en';
        if (hour?.isClosed) {
            return this._translate.instant('information.special_hours.closed');
        }
        if (!hour || !hour.openTime || !hour.closeTime) {
            return this._translate.instant('information.special_hours.not_specified');
        }
        if (hour.openTime === '00:00' && hour.closeTime === '24:00') {
            return this._translate.instant('common.open_24h');
        }
        if (hour.openTime === '24:00') {
            return this._formatTimePipe.transform('00:00', isEnglishNotation);
        }

        const openTime = this._formatTimePipe.transform(hour.openTime, isEnglishNotation);
        const closeTime = this._formatTimePipe.transform(hour.closeTime, isEnglishNotation);
        return `${openTime} - ${closeTime}`;
    }
}
