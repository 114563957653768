<div
    class="edit-modal-container md:h-full"
    [ngClass]="{
        'h-[90vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false"> </app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <div class="title">
                <div class="malou-text-18--bold text-malou-color-text-1">{{ data.title }}</div>

                <div class="malou-text-12--regular malou-color-text-2 italic">
                    {{ 'automations.reviewReplies.subtitle' | translate }}
                </div>
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body md:!overflow-y-scroll">
            <div class="[&>*:not(:first-child)]:mt-3">
                @for (automation of automations; track automation) {
                    <div>
                        <ng-container [ngTemplateOutlet]="automationTemplate" [ngTemplateOutletContext]="{ automation }"></ng-container>
                    </div>
                }
            </div>
        </div>

        <div class="malou-dialog__footer">
            <button class="malou-btn-raised--secondary--alt !h-11 md:grow" mat-raised-button [disabled]="isLoading" (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>
            <button
                class="malou-btn-raised--primary !h-11 md:grow"
                mat-raised-button
                [disabled]="isLoading || !areAutomationsValid"
                (click)="submit()">
                {{ 'common.save' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template let-automation="automation" #automationTemplate>
    <div class="malou-border-color-dark malou-background-white rounded-[10px] border">
        <div
            class="flex items-center justify-between p-5"
            [ngClass]="{
                'malou-background-light': automation.active,
                'rounded-[10px]': !automation.active,
                'rounded-t-[10px]': automation.active,
            }">
            <div class="malou-text-14--bold text-malou-color-text-1">
                {{ getRatingCategoryTitle | applyPure: automation.ratingCategory }}
            </div>
            <div class="flex items-center gap-x-3">
                <span class="malou-text-14">{{ 'automations.reviewReplies.enable' | translate }}</span>
                <app-slide-toggle
                    [activeLabel]="'common.yes' | translate"
                    [inactiveLabel]="'common.no' | translate"
                    [switchWidth]="70"
                    [checked]="automation.active"
                    (onToggle)="toggleActive(automation)">
                </app-slide-toggle>
            </div>
        </div>

        @if (automation.active) {
            <div class="p-5">
                <div>
                    <span class="malou-text-12--regular malou-color-text-2 mb-2">
                        {{ 'automations.reviewReplies.edit.answerWith' | translate }}
                    </span>
                    <mat-radio-group
                        class="flex flex-wrap justify-between"
                        color="primary"
                        [value]="automation.replyMethod"
                        (change)="onReplyMethodChange(automation, $event)">
                        <mat-radio-button [value]="ReviewReplyAutomationMethod.AI">
                            <span class="malou-text-12--semibold">
                                {{ 'automations.reviewReplies.edit.replyMethod.ai' | translate }} - {{ aiRemainingCredits }}/{{
                                    AI_HARD_LIMIT_CALL_COUNT
                                }}
                                {{ 'automations.reviewReplies.edit.credits' | translate }}
                                <span class="malou-text-12--regular">
                                    ({{ 'automations.reviewReplies.edit.recommended' | translate }})
                                </span>
                            </span>
                        </mat-radio-button>
                        <mat-radio-button
                            [matTooltip]="getAvailableTemplateTooltip | applyPure: automation"
                            [value]="ReviewReplyAutomationMethod.TEMPLATES">
                            <span class="malou-text-12--semibold">
                                {{ 'automations.reviewReplies.edit.replyMethod.templates' | translate }} ({{
                                    automation.templates?.length
                                }})
                            </span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <ng-container
                    [ngTemplateOutlet]="
                        data.availableAutoResponsePlatforms.length ? automationConfig : platformsNotConnected
                    "></ng-container>
                <ng-template #automationConfig>
                    @if (automation.replyMethod === ReviewReplyAutomationMethod.AI) {
                        <ng-container [ngTemplateOutlet]="aiConfigTemplate" [ngTemplateOutletContext]="{ automation }"> </ng-container>
                    }
                    @if (automation.replyMethod === ReviewReplyAutomationMethod.TEMPLATES) {
                        <ng-container
                            [ngTemplateOutlet]="automation.templates?.length ? templatesConfigTemplate : templatesNotFoundTemplate"
                            [ngTemplateOutletContext]="{ automation }">
                        </ng-container>
                    }
                </ng-template>
            </div>
        }
    </div>
</ng-template>

<ng-template let-automation="automation" #aiConfigTemplate>
    <div class="malou-text-12--regular malou-color-text-2 mb-2 mt-4">
        {{ 'automations.reviewReplies.edit.auto_send_for_platforms' | translate }}
    </div>

    <app-select-platforms
        [values]="data.availableAutoResponsePlatforms"
        [selectedValues]="automation.aiConfig?.sendAutomaticallyToThesePlatformKeys"
        (selectPlatformsChange)="onAiConfigPlatformsChange(automation, $event)">
    </app-select-platforms>

    <div class="malou-text-10--regular malou-color-text-2 mt-6 italic">
        {{ 'automations.reviewReplies.edit.platforms_not_selected_reply_generation' | translate }}
    </div>

    @if (aiMonthlyCallCount >= AI_HARD_LIMIT_CALL_COUNT) {
        <ng-container [ngTemplateOutlet]="aiHardLimitReached"> </ng-container>
    }
</ng-template>

<ng-template let-automation="automation" #templatesConfigTemplate>
    <div class="malou-expansion-panel mt-3">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header class="malou-background-light-important custom-expansion-panel-header !rounded-none">
                    <span class="malou-text-12 italic">{{
                        getTemplateConfigTitle | applyPure: automation : automation.templateConfigs?.length
                    }}</span>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <div
                        [ngClass]="{
                            'max-h-[335px]': !screenSizeService.isPhoneScreen,
                            'overflow-scroll': !screenSizeService.isPhoneScreen && automation.templates?.length > 5,
                        }">
                        @for (template of automation.templates; track template) {
                            <div
                                class="flex items-center justify-between px-3 py-3 md:flex-col md:justify-start"
                                [ngClass]="{ 'h-[67px]': !screenSizeService.isPhoneScreen }">
                                <div class="w-9/12 md:w-full" [matTooltip]="replaceVariableTextToChipText | applyPure: template.text">
                                    <span class="malou-text-12--bold">{{ template.name }}</span> -
                                    <span class="malou-text-12--regular">{{
                                        replaceVariableTextToChipText | applyPure: template.text | shortText: 50
                                    }}</span>
                                </div>
                                <div class="flex items-center md:mt-3 md:w-full">
                                    <div class="mr-2">
                                        <app-slide-toggle
                                            [activeLabel]="'common.yes' | translate"
                                            [inactiveLabel]="'common.no' | translate"
                                            [switchWidth]="70"
                                            [checked]="
                                                isTemplateSelected | applyPure: automation : template : automation.templateConfigs?.length
                                            "
                                            (onToggle)="onTogglePlatformConfigsActive(automation, template)">
                                        </app-slide-toggle>
                                    </div>
                                    @for (platformKey of data.availableAutoResponsePlatforms; track platformKey) {
                                        <div>
                                            <img
                                                class="ml-1 cursor-pointer rounded-full opacity-50"
                                                height="24"
                                                width="24"
                                                [ngClass]="{
                                                    'malou-color-border--primary border-2 !opacity-100':
                                                        (isPlatformChecked
                                                        | applyPure
                                                            : automation
                                                            : template
                                                            : platformKey
                                                            : automation.templateConfigs?.length
                                                            : reRenderSelectedPlatformsCount),
                                                }"
                                                [src]="platformKey | platformLogoPathResolver"
                                                [alt]="platformKey"
                                                (click)="onTemplateConfigsPlatformChange(automation, template, platformKey)" />
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        @if (!automation.templateConfigs?.length) {
                            <mat-error class="malou-color-state-error malou-text-10 mt-2 px-3 italic">
                                {{ 'automations.reviewReplies.edit.templates_not_selected' | translate }}
                            </mat-error>
                        }
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>

<ng-template #aiHardLimitReached>
    <div class="malou-color-background--warning malou-color-border--warning my-3 flex rounded-[10px] border p-2">
        <mat-icon class="malou-bg-state-warn malou-color-white mr-2 mt-1 !h-4 !w-4 rounded-full" [svgIcon]="SvgIcon.EXCLAMATION_MARK">
        </mat-icon>
        <div class="flex flex-col py-1">
            <div class="malou-text-12--semibold mb-1">
                {{ 'automations.reviewReplies.edit.ai_hard_limit_reached' | translate }}
            </div>
            <div class="malou-text-12--regular italic">
                {{ 'automations.reviewReplies.edit.ai_hard_limit_reached_subtext' | translate }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templatesNotFoundTemplate>
    <div class="malou-color-background--warning malou-color-border--warning my-3 flex rounded-[10px] border p-2">
        <mat-icon class="malou-bg-state-warn malou-color-white mr-2 mt-1 !h-4 !w-4 rounded-full" [svgIcon]="SvgIcon.EXCLAMATION_MARK">
        </mat-icon>
        <div class="flex flex-col py-1">
            <div class="malou-text-12--semibold mb-1">
                {{ 'automations.reviewReplies.edit.templates_not_found' | translate }}
            </div>
            <div class="malou-text-12--regular italic">
                {{ 'automations.reviewReplies.edit.templates_not_found_subtext' | translate }}
                <span class="malou-color-primary malou-text-12--semibold cursor-pointer" (click)="goToTemplates()">
                    {{ 'automations.reviewReplies.edit.templates_not_found_subtext_link' | translate }}
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #platformsNotConnected>
    <div class="malou-color-background--warning malou-color-border--warning my-3 flex rounded-[10px] border p-2">
        <mat-icon class="malou-bg-state-warn malou-color-white mr-2 mt-1 !h-4 !w-4 rounded-full" [svgIcon]="SvgIcon.EXCLAMATION_MARK">
        </mat-icon>
        <div class="flex flex-col py-1">
            <div class="malou-text-12--semibold mb-1">
                {{ 'automations.reviewReplies.edit.platforms_not_connected' | translate }}
            </div>
            <div class="malou-text-12--regular italic">
                {{ 'automations.reviewReplies.edit.platforms_not_connected_subtext' | translate }}
                <span class="malou-color-primary malou-text-12--semibold cursor-pointer" (click)="goToPlatforms()">
                    {{ 'automations.reviewReplies.edit.platforms_not_connected_subtext_link' | translate }}
                </span>
            </div>
        </div>
    </div>
</ng-template>
