<div class="h-full overflow-y-auto">
    <mat-table
        class="malou-mat-table"
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        [matSortActive]="DEFAULT_SORT.active"
        [matSortDirection]="DEFAULT_SORT.direction">
        <ng-container [matColumnDef]="TableField.RESTAURANT_NAME">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.restaurant_name' | translate }}
            </mat-header-cell>
            <mat-cell
                *matCellDef="let data"
                class="malou-text-10--bold mr-0.5 !justify-start !pl-0.5"
                [matTooltip]="data[TableField.RESTAURANT_NAME]">
                <img
                    class="mr-2 h-10 w-10 rounded-md"
                    alt="restaurant logo"
                    [src]="data.restaurantLogo || ('default_cover' | imagePathResolver)" />
                <div class="flex justify-start">
                    @if (data.isMissingData) {
                        <mat-icon
                            class="malou-bg-state-warn malou-color-white malou-border-color-warn relative mr-1 !h-3.5 !w-5 rounded-full border-2"
                            [svgIcon]="SvgIcon.EXCLAMATION_MARK"
                            [matTooltipPosition]="'below'"
                            [matTooltip]="'roi.aggregated_performance.errors.one.no_data' | translate"></mat-icon>
                    }
                    <span class="truncate text-ellipsis"> {{ data[TableField.RESTAURANT_NAME] }} </span>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.ADDITIONAL_CLIENTS">
            <mat-header-cell *matHeaderCellDef class="justify-end" mat-sort-header>
                {{ 'roi.aggregated_estimated_customers.additional_clients' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data" class="justify-end">
                @if (data.showData) {
                    <div class="flex gap-x-2">
                        <div>
                            {{ data[TableField.ADDITIONAL_CLIENTS] | shortNumber }}
                        </div>
                    </div>
                    @if (screenSizeService.isPhoneScreen) {
                        <span>
                            {{ 'roi.aggregated_estimated_customers.additional_clients' | translate }}
                        </span>
                    }
                } @else if (!data.showData) {
                    <span class="malou-color-text-2 malou-text-12--regular absolute left-[25%] md:relative">
                        {{ 'roi.aggregated_performance.errors.one.no_data' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.ADDITIONAL_CLIENTS_SIMILAR_LOCATIONS">
            <mat-header-cell *matHeaderCellDef class="flex-2 justify-end" mat-sort-header>
                {{ 'roi.aggregated_estimated_customers.additional_clients_similar_locations' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data" class="flex-2 justify-end">
                @if (data.showData) {
                    {{ data[TableField.ADDITIONAL_CLIENTS_SIMILAR_LOCATIONS] | shortNumber }}
                    @if (screenSizeService.isPhoneScreen) {
                        <span>
                            {{ 'roi.aggregated_estimated_customers.additional_clients_similar_locations' | translate }}
                        </span>
                    }
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.EARNINGS">
            <mat-header-cell *matHeaderCellDef class="justify-end" mat-sort-header>
                {{ 'roi.aggregated_estimated_customers.earnings' | translate: { currency: roiContext.displayedGlobalCurrency() } }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data" class="justify-end">
                @if (data.showData) {
                    {{ data[TableField.EARNINGS] | shortNumber }}
                    @if (screenSizeService.isPhoneScreen) {
                        <span>
                            {{
                                'roi.aggregated_estimated_customers.earnings'
                                    | translate: { currency: roiContext.displayedGlobalCurrency() }
                            }}
                        </span>
                    }
                }
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: displayedColumns" class="!relative"></mat-row>
    </mat-table>
</div>
