import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { YextPublisherId, yextPublishers } from '@malou-io/package-utils';

import { PublisherIdWithUrl } from ':modules/platforms/platforms.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-automatically-connected-platforms-modal',
    templateUrl: './automatically-connected-platforms-modal.component.html',
    styleUrls: ['./automatically-connected-platforms-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        MatExpansionModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        TranslateModule,
        PluralTranslatePipe,
        PlatformLogoPathResolverPipe,
        EnumTranslatePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class AutomaticallyConnectedPlatformsModalComponent implements KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    publishers: PublisherIdWithUrl[] = [];
    readonly yextPublishers = yextPublishers;

    constructor(
        public readonly dialogRef: MatDialogRef<AutomaticallyConnectedPlatformsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            publishers: PublisherIdWithUrl[];
        }
    ) {
        this.publishers = data.publishers;
    }

    close(): void {
        this.dialogRef.close();
    }

    openPublisherInformationModal(publisherId: YextPublisherId): void {
        this.dialogRef.close(publisherId);
    }

    openPublisherUrl(publisher: PublisherIdWithUrl): void {
        window.open(publisher.url, '_blank');
    }
}
