<div class="flex h-full flex-col">
    <div class="flex flex-1 flex-col gap-5 overflow-auto px-9 py-6">
        <div class="malou-text-18--bold text-malou-color-text-1">{{ 'admin.profile.my_profile' | translate }}</div>
        <div class="flex flex-col rounded-[10px] border border-malou-color-border-primary bg-white p-6.5">
            <div class="flex w-full gap-10 md:mt-4 md:flex-wrap md:gap-5">
                <div class="md:flex md:w-full md:justify-between">
                    @if (!user?.profilePicture?.urls?.small) {
                        <div
                            class="flex h-40 w-36 items-center justify-center rounded-xl border border-malou-color-border-primary bg-malou-color-background-light md:h-14 md:w-14">
                            <img class="h-20 opacity-50 md:h-6" [src]="Illustration.Karl | illustrationPathResolver" />
                        </div>
                    } @else {
                        <img
                            class="malou-border-primary h-40 w-36 rounded-xl object-cover object-center md:h-14 md:w-14"
                            [src]="user?.profilePicture?.urls?.small" />
                    }

                    <div class="hidden gap-5 md:flex">
                        <a
                            class="malou-text-13--semibold mt-4 block text-malou-color-primary hover:underline"
                            target="_blank"
                            [href]="CGU_LINK_DATA">
                            {{ 'profile.cgu' | translate }}
                        </a>
                        <button class="malou-btn-icon--secondary btn-xl !m-0" mat-icon-button (click)="openEditUserModal()">
                            <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        </button>
                    </div>
                </div>

                <div class="flex w-full justify-between">
                    <div class="flex flex-col justify-around md:gap-4">
                        <div class="flex items-center">
                            <mat-icon class="icon-btn !h-4 text-malou-color-primary" color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                            <div class="malou-text-14--bold ml-4 truncate text-malou-color-text-1 md:hidden">
                                {{ user?.fullname }}
                            </div>
                            <div class="malou-text-14--bold ml-4 hidden truncate text-malou-color-text-1 md:block">
                                {{ user?.name + '.' + user?.lastname?.charAt(0) | shortText: 15 }}
                            </div>
                        </div>
                        <div class="flex items-center">
                            <mat-icon class="icon-btn !h-4 text-malou-color-primary" color="primary" [svgIcon]="SvgIcon.LETTER"></mat-icon>
                            <div class="malou-text-14--regular ml-4 text-malou-color-text-1">
                                {{ user?.email }}
                            </div>
                        </div>
                        <div class="flex items-center">
                            <mat-icon class="icon-btn !h-4 text-malou-color-primary" color="primary" [svgIcon]="SvgIcon.FOLDER"></mat-icon>
                            <div class="malou-text-14--regular ml-4 text-malou-color-text-1">
                                {{ user?.role | titlecase }}
                            </div>
                        </div>
                    </div>
                    <div class="flex gap-5 md:hidden">
                        <a
                            class="malou-text-13--semibold mt-4 block text-malou-color-primary hover:underline"
                            target="_blank"
                            [href]="CGU_LINK_DATA"
                            >{{ 'admin.profile.conditions' | translate }}</a
                        >
                        <button class="malou-btn-icon--secondary btn-xl !m-0" mat-icon-button (click)="openEditUserModal()">
                            <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
