import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as PlatformsActions from ':modules/platforms/store/platforms.actions';

@Injectable()
export class GroupsResolver {
    constructor(private readonly _store: Store) {}

    resolve(): any {
        this._store.dispatch(PlatformsActions.editSelectedRestaurantId({ restaurantId: '' }));
    }
}
