export enum FileSize {
    OCTET = 'octet',
    KILOOCTET = 'kilooctet',
    MEGAOCTET = 'megaoctet',
}

export enum InputFileType {
    PNG = 'image/png',
    JPEG = 'image/jpeg',
    SVG = 'image/svg+xml',
    VIDEO = 'video/mp4',
    QUICKTIME = 'video/quicktime',
}

export enum PictureSize {
    ORIGINAL = 'original',
    SMALL = 'small',
    COVER = 'cover',
    SMALL_COVER = 'smallCover',
    IG_FIT = 'igFit',
}

export interface PictureSizeRecord<T extends string | number | MediaDimension> {
    original: T;
    small?: T;
    cover?: T;
    smallCover?: T;
    igFit?: T;
}

export interface MediaDimension {
    width: number;
    height: number;
}
