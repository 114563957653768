import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, combineLatest, debounceTime, EMPTY, filter, map, Observable, switchMap, take, tap } from 'rxjs';

import { NfcWithRestaurantDto } from '@malou-io/package-dto';
import { InsightsChart, InsightsTab, isNotNil } from '@malou-io/package-utils';

import { NfcService } from ':core/services/nfc.service';
import { ToastService } from ':core/services/toast.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, Nfc, Restaurant } from ':shared/models';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { FiltersComponent } from '../filters/filters.component';
import { AggregatedStatisticsFiltersContext } from '../filters/filters.context';
import * as AggregatedStatisticsActions from '../store/aggregated-statistics.actions';
import { PlatformFilterPage } from '../store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from '../store/aggregated-statistics.selectors';
import { AggregatedBoostersScanCountComponent } from './aggregated-boosters-scan-count/aggregated-boosters-scan-count.component';
import { AggregatedWheelOfFortuneEstimatedReviewCountComponent } from './aggregated-wheel-of-fortune-estimated-review-count/aggregated-wheel-of-fortune-estimated-review-count.component';
import { AggregatedWheelOfFortuneGiftsDistributionComponent } from './aggregated-wheel-of-fortune-gifts-distribution/aggregated-wheel-of-fortune-gifts-distribution.component';
import { AggregatedWheelOfFortuneGiftsKpisComponent } from './aggregated-wheel-of-fortune-gifts-kpis/aggregated-wheel-of-fortune-gifts-kpis.component';
import { AggregatedBoostersStatisticsData, AggregatedWheelOfFortuneGiftsStatisticsData } from './booster.interface';
import { BoostersAggregatedDataFetchingService } from './services/get-boosters-aggregated-data.service';

@Component({
    selector: 'app-boosters',
    templateUrl: './boosters.component.html',
    styleUrls: ['./boosters.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        TranslateModule,
        AsyncPipe,
        IllustrationPathResolverPipe,
        AggregatedBoostersScanCountComponent,
        AggregatedWheelOfFortuneEstimatedReviewCountComponent,
        AggregatedWheelOfFortuneGiftsDistributionComponent,
        AggregatedWheelOfFortuneGiftsKpisComponent,
        FiltersComponent,
        SkeletonComponent,
        MatButtonModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoostersComponent implements AfterViewInit {
    @ViewChild('topOfComponent') topOfComponent: ElementRef<HTMLElement>;

    isLoadingBoosters: WritableSignal<boolean> = signal(true);
    isErrorBoosters: WritableSignal<boolean> = signal(false);
    isLoadingGifts: WritableSignal<boolean> = signal(true);
    isErrorGifts: WritableSignal<boolean> = signal(false);
    boostersData$: Observable<AggregatedBoostersStatisticsData>;
    wheelOfFortuneData$: Observable<AggregatedBoostersStatisticsData>;
    giftsData$: Observable<AggregatedWheelOfFortuneGiftsStatisticsData>;

    readonly PlatformFilterPage = PlatformFilterPage;
    readonly totems$: Observable<Nfc[]> = this._store.select(AggregatedStatisticsSelectors.selectTotemsFilter);
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter);
    readonly restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;
    readonly restaurantsWithBoosterPackActivated$: Observable<Restaurant[]> = this.restaurants$.pipe(
        map((restaurants) => restaurants.filter((restaurant) => restaurant.boosterPack?.activated))
    );

    readonly atLeastOneBoosterPackActivated = toSignal(
        this.restaurantsWithBoosterPackActivated$.pipe(
            map((restaurants) => restaurants.length > 0),
            takeUntilDestroyed(this._destroyRef)
        ),
        { initialValue: false }
    );

    readonly nfcIds: WritableSignal<string[]> = signal([]);

    restaurantsTotems$: Observable<NfcWithRestaurantDto[]> = this._store.select(selectOwnRestaurants).pipe(
        switchMap((restaurants) =>
            this._nfcsService.search(
                {},
                restaurants.map((restaurant) => restaurant._id)
            )
        ),
        map((res) => res.data || [])
    );
    readonly restaurantsTotems: Signal<NfcWithRestaurantDto[]> = toSignal(this.restaurantsTotems$, { initialValue: [] });

    readonly InsightsChart = InsightsChart;
    readonly chartOptions: WritableSignal<ChartOptions> = signal({
        [InsightsChart.AGGREGATED_BOOSTERS_SCAN_COUNT]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
            nfcs: [],
        },
        [InsightsChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION]: {
            tableSortOptions: undefined,
        },
        [InsightsChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_ESTIMATED_REVIEWS_COUNT]: {
            tableSortOptions: undefined,
        },
    });

    isLoading = computed(() => this.isLoadingBoosters() || this.isLoadingGifts());

    constructor(
        private readonly _store: Store,
        private readonly _destroyRef: DestroyRef,
        private readonly _translateService: TranslateService,
        private readonly _nfcsService: NfcService,
        private readonly _toastService: ToastService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _getBoostersAggregatedDataService: BoostersAggregatedDataFetchingService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        this.boostersData$ = combineLatest([this.restaurants$, this.dates$, this.totems$]).pipe(
            filter(([restaurants, dates]) => restaurants.length > 0 && isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isErrorBoosters.set(false);
                this.isLoadingBoosters.set(true);
            }),
            debounceTime(500),
            filter(([_, dates]) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
            switchMap(([restaurants, dates, nfcs]: [Restaurant[], DatesAndPeriod, Nfc[]]) => {
                const data = this._getBoostersAggregatedDataService.getChartsData(nfcs, dates, restaurants);
                this.onNfcChange(nfcs.map((nfc) => nfc.id));
                this.isLoadingBoosters.set(false);
                return data;
            }),
            catchError(() => {
                this.isErrorBoosters.set(true);
                this.isLoadingBoosters.set(false);
                return EMPTY;
            }),
            takeUntilDestroyed(this._destroyRef)
        );
        this.giftsData$ = combineLatest([this.restaurantsWithBoosterPackActivated$, this.dates$]).pipe(
            filter(([restaurants, dates]) => restaurants.length > 0 && isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isErrorGifts.set(false);
                this.isLoadingGifts.set(true);
            }),
            debounceTime(500),
            filter(([_, dates]) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
            switchMap(([restaurants, dates]: [Restaurant[], DatesAndPeriod]) => {
                const data = this._getBoostersAggregatedDataService.getGiftsData(dates, restaurants);
                this.isLoadingGifts.set(false);
                return data;
            }),
            catchError(() => {
                this.isErrorGifts.set(true);
                this.isLoadingGifts.set(false);
                return EMPTY;
            }),
            takeUntilDestroyed(this._destroyRef)
        );

        this.wheelOfFortuneData$ = this.boostersData$.pipe(
            map((data) => {
                this._store.dispatch(AggregatedStatisticsActions.editBoosterStatsData({ data }));
                return {
                    ...data,
                    scans: data.scans.filter((scan) => scan.isWheelOfFortuneRelated()),
                    previousScans: data.previousScans.filter((scan) => scan.isWheelOfFortuneRelated()),
                };
            })
        );
    }

    ngAfterViewInit(): void {
        setTimeout(() =>
            this.topOfComponent?.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        );
    }

    openDownloadStatisticsModal(): void {
        combineLatest([
            this._store.select(AggregatedStatisticsSelectors.selectDatesFilter),
            this._store.select(AggregatedStatisticsSelectors.selectRestaurantsIdsFilter),
        ])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, restaurantIds]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.AGGREGATED_BOOSTERS,
                                filters: {
                                    dates: { startDate, endDate },
                                    restaurantIds,
                                    nfcIds: this.nfcIds(),
                                },
                                chartOptions: this.chartOptions(),
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onSortByChange(chart: InsightsChart, value: ChartSortBy): void {
        this.chartOptions.update((options) => ({ ...options, [chart]: { ...options[chart], chartSortBy: value } }));
    }

    onNfcChange(value: string[]): void {
        this.nfcIds.set(value);
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions.update((options) => ({
            ...options,
            [chart]: {
                ...options[chart],
                tableSortOptions: value,
            },
        }));
    }
}
