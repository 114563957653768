import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { days } from ':core/constants';
import { TimePeriod } from ':shared/models';

interface CustomTimePeriod {
    openDay: string;
    openTime: string | null;
    closeDay: string;
    closeTime: string | null;
    isClosed: boolean;
    isPrimaryPeriod: boolean;
    openTime2: string | null;
    closeTime2: string | null;
}

@Injectable({
    providedIn: 'root',
})
export class HoursToTextService {
    constructor(private readonly _translate: TranslateService) {}

    generateTextFromHours(hours: TimePeriod[], lang: string): string {
        const mergedHours = this._mergeHours(hours);
        let text = this._translate.instant('messages.message_area.we_are');
        for (let i = 0; i < mergedHours.length; i++) {
            const range = mergedHours[i];

            if (range.length === 1) {
                if (range[0].isClosed) {
                    text += this._translate.instant('messages.message_area.closed_day', { closedDay: days[range[0].openDay][lang] });
                } else if (range[0].openTime && range[0].closeTime) {
                    text += this._translate.instant('messages.message_area.open_day', {
                        openDay: days[range[0].openDay][lang],
                        openTime: this._formatTime(range[0].openTime, lang),
                        closeTime: this._formatTime(range[0].closeTime, lang),
                    });
                    const openTime2 = range[0].openTime2;
                    const closeTime2 = range[0].closeTime2;
                    if (openTime2 && closeTime2) {
                        text += this._translate.instant('messages.message_area.open_day_more', {
                            openTime2: this._formatTime(openTime2, lang),
                            closeTime2: this._formatTime(closeTime2, lang),
                        });
                    }
                }
            } else {
                const firstDay = range[0];
                const lastDay = range[range.length - 1];
                if (firstDay.isClosed) {
                    text += this._translate.instant('messages.message_area.closed_days', {
                        startDay: days[firstDay.openDay][lang],
                        endDay: days[lastDay.openDay][lang],
                    });
                } else if (firstDay.openTime && firstDay.closeTime) {
                    text += this._translate.instant('messages.message_area.open_days', {
                        startDay: days[firstDay.openDay][lang],
                        endDay: days[lastDay.openDay][lang],
                        openTime: this._formatTime(firstDay.openTime, lang),
                        closeTime: this._formatTime(firstDay.closeTime, lang),
                    });
                    if (firstDay.openTime2 && firstDay.closeTime2) {
                        text += this._translate.instant('messages.message_area.open_day_more', {
                            openTime2: this._formatTime(firstDay.openTime2, lang),
                            closeTime2: this._formatTime(firstDay.closeTime2, lang),
                        });
                    }
                }
            }
        }
        return text
            .replace(/, et/g, this._translate.instant('messages.message_area.and'))
            .replace(/24:00h/g, this._translate.instant('messages.message_area.midnight'))
            .replace(/00:00h/g, this._translate.instant('messages.message_area.midnight'))
            .replace(/12:00h/g, this._translate.instant('messages.message_area.noon'))
            .replace(/:00/g, '')
            .replace(/,([^,]*)$/, '');
    }

    private _isSameHours(h1: CustomTimePeriod, h2: CustomTimePeriod): boolean {
        if (h1.isClosed !== h2.isClosed) {
            return false;
        }
        if (h1.isClosed) {
            return true;
        }
        return (
            h1.openTime === h2.openTime && h1.closeTime === h2.closeTime && h1.openTime2 === h2.openTime2 && h1.closeTime2 === h2.closeTime2
        );
    }

    private _sortHours(hours: TimePeriod[]): CustomTimePeriod[] {
        const keys = Object.keys(days);
        let res: CustomTimePeriod[] = [];
        for (let i = 0; i < keys.length; i++) {
            const sameDayHours = hours.filter((d) => d.openDay === keys[i]);
            if (sameDayHours.length > 1) {
                res.push({
                    ...sameDayHours[0],
                    openTime: sameDayHours[0].openTime ?? null,
                    closeTime: sameDayHours[0].closeTime ?? null,
                    openTime2: sameDayHours[1].openTime ?? null,
                    closeTime2: sameDayHours[1].closeTime ?? null,
                });
            } else {
                res = res.concat(sameDayHours as any as CustomTimePeriod[]);
            }
        }
        return res;
    }

    private _mergeHours(hours: TimePeriod[]): CustomTimePeriod[][] {
        const sortedHours = this._sortHours(hours);
        const len = sortedHours.length;
        let j = 0;
        const res: CustomTimePeriod[][] = [];

        while (j < len) {
            const range: CustomTimePeriod[] = [];
            range.push(sortedHours[j]);
            for (let i = j; i < len - 1; i++) {
                if (this._isSameHours(sortedHours[i], sortedHours[i + 1])) {
                    range.push(sortedHours[i + 1]);
                    j++;
                } else {
                    break;
                }
            }
            j++;
            res.push(range);
        }
        return res;
    }

    private _formatTime(time: string, lang: string): string {
        switch (lang) {
            case 'en':
                const hours = parseInt(time.split(':')[0], 10);
                const minutes = parseInt(time.split(':')[1], 10);
                const date = new Date();
                date.setHours(hours, minutes, 0, 0);
                return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).replace(/:00/g, '');
            default:
                return time;
        }
    }
}
