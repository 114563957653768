import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Tick, TooltipItem } from 'chart.js';
import { groupBy } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { isNotNil, PartialRecord, ReviewAnalysisTag, SemanticAnalysisSentiment } from '@malou-io/package-utils';

import { ChartDataArray, malouChartColorGreen, malouChartColorRed } from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ReviewWithAnalysis, SegmentWithReview } from ':shared/models';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DEFAULT_DISPLAYED_TAGS: ReviewAnalysisTag[] = [
    ReviewAnalysisTag.FOOD,
    ReviewAnalysisTag.SERVICE,
    ReviewAnalysisTag.ATMOSPHERE,
    ReviewAnalysisTag.PRICE,
    ReviewAnalysisTag.EXPEDITIOUSNESS,
    ReviewAnalysisTag.HYGIENE,
];

type BarChartType = Extract<ChartType, 'bar'>;
@Component({
    selector: 'app-tags-bar-chart',
    templateUrl: './tags-bar-chart.component.html',
    styleUrls: ['./tags-bar-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe],
})
export class TagsBarChartComponent implements OnInit, OnChanges {
    @Input() reviewsByRestaurantId: Record<string, ReviewWithAnalysis[]>;
    segments: SegmentWithReview[];
    analysisTag = ReviewAnalysisTag;
    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: ChartDataset<BarChartType, ChartDataArray>[];
    chartLabels: string[];
    chartOption: ChartOptions<BarChartType>;

    displayedTags: ReviewAnalysisTag[] = [];

    constructor(
        private readonly _translate: TranslateService,
        private readonly _shortNumberPipe: ShortNumberPipe
    ) {}

    ngOnInit(): void {
        this.chartOption = this._computeChartOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'reviewsByRestaurantId')) {
            this.displayedTags = DEFAULT_DISPLAYED_TAGS;
            const reviews: ReviewWithAnalysis[] = Object.values(this.reviewsByRestaurantId).flat();
            this.segments = this._buildSegmentsWithReview(reviews);
            this.chartDataSets = this._computeChartData(this.segments);
            this.chartLabels = this._computeChartLabels(this.segments);
        }
    }

    private _buildSegmentsWithReview(reviews: ReviewWithAnalysis[]): SegmentWithReview[] {
        return reviews
            .map((review) =>
                review?.semanticAnalysis?.segmentAnalyses.map((segment) => ({
                    ...segment,
                    review,
                }))
            )
            .flat()
            .filter(isNotNil)
            .filter((el) => el.tag !== ReviewAnalysisTag.OVERALL_EXPERIENCE);
    }

    private _computeChartLabels(segments: SegmentWithReview[]): string[] {
        const tagsMap = this._buildTagsMap(segments);
        return this.displayedTags.map((tag) => `${this._translate.instant(`review_analysis_tags.${tag}`)}  (${tagsMap[tag]?.length ?? 0})`);
    }

    private _computeChartData(data: SegmentWithReview[]): ChartDataset<BarChartType, ChartDataArray>[] {
        const positiveSegments = data.filter((s) => s?.sentiment === SemanticAnalysisSentiment.POSITIVE);
        const negativeSegments = data.filter((s) => s?.sentiment === SemanticAnalysisSentiment.NEGATIVE);

        const positiveTagsMap = this._buildTagsMap(positiveSegments);
        const negativeTagsMap = this._buildTagsMap(negativeSegments);

        this.displayedTags.forEach((tag) => {
            if (!positiveTagsMap[tag]?.length && !negativeTagsMap[tag]?.length) {
                this.displayedTags = this.displayedTags.filter((t) => t !== tag);
            }
        });

        this.displayedTags = this.displayedTags.filter((tag) => positiveTagsMap[tag]?.length || negativeTagsMap[tag]?.length);

        return [
            {
                borderColor: malouChartColorGreen,
                backgroundColor: malouChartColorGreen,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: this.displayedTags.map((label) => positiveTagsMap[label]?.length || 0),
            },
            {
                borderColor: malouChartColorRed,
                backgroundColor: malouChartColorRed,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: this.displayedTags.map((label) => negativeTagsMap[label]?.length || 0),
            },
        ];
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => '',
                        label: (tooltipItems: TooltipItem<any>) => this._computeTooltipLabel(tooltipItems),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'category',
                    stacked: true,
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    stacked: true,
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipLabel(tooltipItems: TooltipItem<any>): string {
        const sentiment =
            tooltipItems.datasetIndex === 0
                ? this._translate.instant('statistics.e_reputation.reviews_analysis.positive')
                : this._translate.instant('statistics.e_reputation.reviews_analysis.negative');
        return this._translate.instant('statistics.e_reputation.reviews_analysis.feeling', {
            sentimentsNum: tooltipItems.formattedValue,
            sentiment,
        });
    }

    private _buildTagsMap(segmentsWithReview: SegmentWithReview[]): PartialRecord<ReviewAnalysisTag, SegmentWithReview[]> {
        const object = groupBy(segmentsWithReview, 'tag');
        Object.values(this.analysisTag)
            .filter((tag) => tag !== ReviewAnalysisTag.OVERALL_EXPERIENCE)
            .forEach((tag) => (object[tag] = object[tag] || []));
        return object;
    }
}
