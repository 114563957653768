import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { DatePipe, NgClass, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MergedInformationUpdateDto } from '@malou-io/package-dto';
import { InformationUpdatePlatformStateStatus, PlatformAccessType, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { InformationUpdatesService } from ':core/services/information-update.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import {
    UpdatesComparisonModalComponent,
    UpdatesComparisonModalComponentInput,
} from ':modules/admin/platforms-management/updates/updates-comparaison/updates-comparison-modal.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { PlatformAccess, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

export interface PlatformManagementUpdateData {
    platformKey: PlatformKey;
    platformAccess: PlatformAccess;
    mergedInformationUpdate: MergedInformationUpdateDto;
    restaurant: Restaurant;
}

export interface PlatformManagementUpdateDoneData {
    platformKey: PlatformKey;
    restaurant: Restaurant;
    status: InformationUpdatePlatformStateStatus;
}

enum DISPLAYED_COLUMNS {
    PLATFORM_KEY = 'platformKey',
    ACCESS_TYPE = 'accessType',
    LAST_VERIFIED = 'lastVerified',
    LAST_UPDATED = 'lastUpdated',
    LOGIN = 'login',
    PASSWORD = 'password',
    STATUS = 'status',
    MERGED_INFORMATION_UPDATE = 'mergedInformationUpdate',
}

interface TabData {
    [DISPLAYED_COLUMNS.PLATFORM_KEY]: PlatformKey;
    [DISPLAYED_COLUMNS.ACCESS_TYPE]: PlatformAccessType;
    [DISPLAYED_COLUMNS.LAST_VERIFIED]?: Date;
    [DISPLAYED_COLUMNS.LAST_UPDATED]?: Date;
    [DISPLAYED_COLUMNS.LOGIN]?: string;
    [DISPLAYED_COLUMNS.PASSWORD]?: string;
    [DISPLAYED_COLUMNS.STATUS]: InformationUpdatePlatformStateStatus;
    [DISPLAYED_COLUMNS.MERGED_INFORMATION_UPDATE]: MergedInformationUpdateDto;
    originalData: PlatformManagementUpdateData;
}

@Component({
    selector: 'app-updates',
    templateUrl: './updates.component.html',
    styleUrls: ['./updates.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        ClipboardModule,
        FormsModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        DatePipe,
        TranslateModule,
        MatTooltipModule,
        EnumTranslatePipe,
        ImagePathResolverPipe,
        PlatformLogoPathResolverPipe,
        TitleCasePipe,
    ],
})
export class UpdatesComponent implements OnInit {
    @Input() updatesData: PlatformManagementUpdateData[];
    @Input() isBusinessView: boolean;

    readonly SvgIcon = SvgIcon;
    platformUpdate = output<PlatformManagementUpdateDoneData>();

    readonly AccessType = PlatformAccessType;
    readonly InformationUpdatePlatformStatusStatus = InformationUpdatePlatformStateStatus;
    readonly DISPLAYED_COLUMNS_VALUES = Object.values(DISPLAYED_COLUMNS);
    readonly INFORMATION_UPDATE_PLATFORM_STATUS_STATUS = Object.values(InformationUpdatePlatformStateStatus);

    dataSource: MatTableDataSource<TabData> = new MatTableDataSource([]);

    passwordsCache: Record<string, string> = {};

    constructor(
        private readonly _translate: TranslateService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _informationUpdatesService: InformationUpdatesService,
        private readonly _platformsService: PlatformsService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _clipboard: Clipboard,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _changeDetectorRef: ChangeDetectorRef
    ) {}

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    ngOnInit(): void {
        this.dataSource.data = this.updatesData.map((update) => ({
            platformKey: update.platformKey,
            accessType: update.platformAccess.accessType,
            lastVerified: update.platformAccess.lastVerified,
            lastUpdated: update.mergedInformationUpdate.oldestValidatedAt
                ? new Date(update.mergedInformationUpdate.oldestValidatedAt)
                : undefined,
            login: update.platformAccess.data?.login,
            password: update.platformAccess.data?.password,
            status: update.mergedInformationUpdate.platformState.status,
            mergedInformationUpdate: update.mergedInformationUpdate,
            originalData: update,
        }));
    }

    openCompareUpdatesModal(tabData: TabData): void {
        this._platformsService
            .getPlatformsForRestaurant(tabData.originalData.restaurant._id)
            .pipe(map((res) => res.data))
            .subscribe((platforms) => {
                const platform = platforms.find((p) => p.key === tabData.originalData.platformKey);
                this._customDialogService.open<UpdatesComparisonModalComponent, UpdatesComparisonModalComponentInput>(
                    UpdatesComparisonModalComponent,
                    {
                        width: '80%',
                        data: {
                            restaurantId: tabData.originalData.restaurant._id,
                            mergedInformationUpdate: tabData[DISPLAYED_COLUMNS.MERGED_INFORMATION_UPDATE],
                            platform,
                        },
                    }
                );
            });
    }

    changeStatus(event: Event, tabData: TabData): void {
        const target = event.target as HTMLSelectElement;
        const status: InformationUpdatePlatformStateStatus = target.value as InformationUpdatePlatformStateStatus;
        this._informationUpdatesService
            .updateInformationUpdatePlatformStatusStatus({
                restaurantId: tabData.originalData.restaurant._id,
                platformKey: tabData.platformKey,
                status,
            })
            .subscribe({
                next: () => {
                    tabData[DISPLAYED_COLUMNS.STATUS] = status;
                    this._changeDetectorRef.detectChanges();
                    this.platformUpdate.emit({
                        platformKey: tabData.platformKey,
                        restaurant: tabData.originalData.restaurant,
                        status,
                    });
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    copied(): void {
        this._toastService.openSuccessToast(this._translate.instant('admin.update.compare_modal.copied'));
    }

    copyPassword(platformKey: PlatformKey, restaurantId: string): void {
        const cacheKey = `${restaurantId}_${platformKey}`;
        if (this.passwordsCache[cacheKey]) {
            this._clipboard.copy(this.passwordsCache[cacheKey]);
            this.copied();
            return;
        }
        this._getPlatformPassword$(restaurantId, platformKey).subscribe({
            next: (password) => {
                this.passwordsCache[cacheKey] = password;
                this._clipboard.copy(password);
                this.copied();
            },
            error: (err) => {
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    openPlatformTab(data: TabData): void {
        this._platformsService
            .getPlatformsForRestaurant(data.originalData.restaurant._id)
            .pipe(map((apiResult) => apiResult.data))
            .subscribe((platforms) => {
                const platform = platforms.find((p) => p.key === data.platformKey);
                if (!platform) {
                    return;
                }
                const platformLink = PlatformDefinitions.getPlatformDefinition(platform.key)?.updateLink?.(platform.socialId);
                if (!platformLink) {
                    return;
                }
                window.open(platformLink, '_blank');
            });
    }

    private _getPlatformPassword$(restaurantId: string, platformKey: string): Observable<string> {
        return this._restaurantsService.showAccess(restaurantId, platformKey).pipe(
            map((res) => res.data.password),
            catchError((err) => of(err))
        );
    }
}
