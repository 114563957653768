import { LightClientDto } from '@malou-io/package-dto';

import { Address, Phone } from '.';
import { IDisplayable } from '../interfaces';

export enum ClientsSource {
    MANUAL = 'manual',
    MALOU = 'malou',
    ZENCHEF = 'zenchef',
    LAFOURCHETTE = 'lafourchette',
    WHEEL_OF_FORTUNE = 'wheel_of_fortune',
}

export enum CivilityType {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other',
}

export enum ContactMode {
    EMAIL = 'email',
    SMS = 'sms',
}

export class Client implements IDisplayable {
    _id: string;
    restaurantId: string;
    lastName?: string;
    firstName?: string;
    civility: string;
    email: string;
    phone: Phone;
    address: Address;
    language: string;
    source: string;
    lastVisitedAt: Date;
    lastContactedAt: Date;
    contactCount: number;
    visitCount: number;
    accepts: string[];
    duplicateRecordError?: boolean;
    reviewsLeft: [
        {
            platformKey: string;
            hasLeftReview: boolean;
        },
    ];
    createdAt: Date;
    duplicatedKey?: string;
    send?: boolean;

    public constructor(init: Partial<Client> = {}) {
        Object.assign(this, init);
        if (init.phone) {
            Object.assign(this, { phone: new Phone(init.phone) });
        }
        if (init.address) {
            Object.assign(this, { address: new Address(init.address) });
        }
    }

    getDisplayedValue(): string {
        return this.firstName + ' ' + this.lastName;
    }
}

export class CampaignStarDetails {
    star: number;
    campaignName: string;
    campaignDate: Date;
    campaignPlatform: string;
}

export class ClientWithStats extends Client {
    sentEmails: number;
    givenStarsDetails: CampaignStarDetails[] = [];
    isUnsubscribed: boolean;

    public constructor(init: Partial<ClientWithStats> = {}) {
        super();
        Object.assign(this, init);
    }
}

export class EnableClient extends Client {
    enable: boolean;

    public constructor(init: Partial<EnableClient> = {}) {
        super();
        Object.assign(this, init);
    }
}

export class LightClient {
    id: string;
    civility?: string;
    firstName?: string;
    lastName?: string;
    address?: Address;
    phone?: Phone;
    email?: string;
    language?: string | null;

    constructor(init: LightClientDto) {
        this.id = init.id;
        this.civility = init.civility;
        this.firstName = init.firstName;
        this.lastName = init.lastName;
        this.address = new Address({
            locality: init.address?.locality ?? undefined,
            country: init.address?.country ?? undefined,
            postalCode: init.address?.locality ?? undefined,
            formattedAddress: init.address?.formattedAddress ?? undefined,
        });
        this.phone = new Phone(init.phone);
        this.email = init.email;
        this.language = init.language;
    }
}
