<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <span>{{ 'roles.new_user.add_users' | translate }}</span>
            <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
        </div>

        <div class="malou-dialog__body">
            <form [formGroup]="usersForm">
                @for (user of usersFormArray.controls; track user; let i = $index; let last = $last) {
                    <div class="my-3" formArrayName="usersFormArray">
                        <div class="flex gap-2 md:flex-col" [formGroupName]="i">
                            <div class="w-1/2 md:w-full">
                                <app-select
                                    formControlName="user"
                                    [title]="'roles.new_user.email' | translate: { userNumber: i + 1 }"
                                    [values]="availableCandidates()"
                                    [displayWith]="displayUser"
                                    [itemBuilder]="buildUser">
                                </app-select>
                                @if (usersFormArray.at(i).get('user')?.touched && usersFormArray.at(i).get('user')?.errors) {
                                    <mat-error class="malou-text-10--regular malou-color-state-error ml-1 py-1 italic">
                                        {{ 'common.required_field' | translate }}
                                    </mat-error>
                                }
                            </div>
                            <div class="flex w-full gap-2">
                                <div class="w-full">
                                    <app-select
                                        formControlName="caslRole"
                                        [title]="'roles.new_user.role' | translate"
                                        [values]="CASL_ROLES | map: 'key'"
                                        [displayWith]="displayCaslRole('text')">
                                        <ng-template let-value="value" #optionTemplate>
                                            <div class="flex w-full flex-col">
                                                <span class="malou-text-13--bold text-malou-color-text-2">{{
                                                    displayCaslRole('text')(value)
                                                }}</span>
                                                <span class="malou-text-10--regular truncate text-malou-color-text-2">{{
                                                    displayCaslRole('subtext')(value)
                                                }}</span>
                                            </div>
                                        </ng-template>
                                    </app-select>
                                    @if (usersFormArray.at(i).get('caslRole')?.touched && usersFormArray.at(i).get('caslRole')?.errors) {
                                        <mat-error class="malou-text-10--regular malou-color-state-error ml-1 py-1 italic">
                                            {{ 'common.required_field' | translate }}
                                        </mat-error>
                                    }
                                </div>
                                @if (i > 0) {
                                    <div class="flex flex-col justify-end">
                                        <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="removeUserForm(i)">
                                            <mat-icon class="icon-btn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                        </button>
                                        @if (
                                            (usersFormArray.at(i).get('caslRole')?.touched &&
                                                usersFormArray.at(i).get('caslRole')?.errors) ||
                                            (usersFormArray.at(i).get('user')?.touched && usersFormArray.at(i).get('user')?.errors)
                                        ) {
                                            <div class="h-[23px] w-full"></div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        @if (!last && usersFormArray.controls && usersFormArray.controls.length > 1) {
                            <mat-divider class="malou-border-color-dark !mt-4"></mat-divider>
                        }
                    </div>
                }
            </form>

            <div class="flex justify-start">
                <a class="malou-btn-flat !pl-0" mat-button (click)="addUserForm()">
                    <mat-icon class="icon-btn mr-2 h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                    {{ 'roles.new_user.add_user' | translate }}
                </a>
            </div>

            <div class="malou-color-background-dark mt-4 flex flex-col rounded-[10px] p-3">
                <mat-checkbox
                    class="malou-text-13--regular malou-color-text-2"
                    color="primary"
                    [checked]="displayRestaurantSelection"
                    (change)="toggleDisplayRestaurantsSelection()">
                    {{ 'roles.new_user.add_to_other_businesses' | translate }}
                </mat-checkbox>

                @if (displayRestaurantSelection) {
                    <section>
                        @if (ownedRestaurants$ | async; as ownedRestaurants) {
                            <app-select-restaurants
                                [title]="'roles.new_user.business' | translate"
                                [required]="true"
                                [values]="ownedRestaurants"
                                [selectedValues]="currentRestaurant ? [currentRestaurant] : []"
                                [displayedOptionCount]="8"
                                (selectRestaurantsChange)="restaurantsSelectionChanged($event)"></app-select-restaurants>
                        }
                    </section>
                }
            </div>
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <div class="flex md:w-1/2">
                <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
                    {{ 'common.cancel' | translate }}
                </button>
            </div>
            <div class="flex md:w-1/2" matTooltip="{{ 'roles.new_user.fill_required_fields' | translate }}">
                <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button [disabled]="usersForm.invalid" (click)="save()">
                    {{ 'common.add' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
