import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import * as SidenavActions from ':modules/sidenav-router/store/sidenav.actions';
import * as SidenavSelectors from ':modules/sidenav-router/store/sidenav.selectors';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-sidenav-toggle-button',
    templateUrl: './sidenav-toggle-button.component.html',
    styleUrls: ['./sidenav-toggle-button.component.scss'],
    standalone: true,
    imports: [MatButtonModule, TranslateModule, MatIconModule],
})
@AutoUnsubscribeOnDestroy()
export class SidenavToggleButtonComponent implements KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$ = new Subject<void>();

    isSidenavOpened = false;

    constructor(private readonly _store: Store) {
        this._store
            .select(SidenavSelectors.selectIsOpened)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((isOpened) => {
                this.isSidenavOpened = isOpened;
            });
    }

    toggleSidenav(): void {
        this._store.dispatch(SidenavActions.toggle());
    }
}
