<app-modal-structure
    [title]="'platforms.connection_new.google.step_1.step_name' | translate"
    [primaryButtonText]="'common.next' | translate"
    [primaryButtonDisabled]="apiTokenControl.invalid || isLoading() || !isMalouTokenValid()"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })">
    <div class="flex h-full flex-col gap-y-4">
        <app-input-text
            [formControl]="apiTokenControl"
            [showRequiredStar]="true"
            [title]="'platforms.connection_new.mapstr.step_1.api_token' | translate"
            [placeholder]="'xxxx-xxxx-xxxx'"
            [errorMessage]="inputErrorMessage()"
            [autocapitalize]="'none'">
        </app-input-text>
        @if (isLoading()) {
            <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
        }
        @if (isMalouTokenValid()) {
            <ng-container *ngTemplateOutlet="restaurantFoundTemplate"></ng-container>
        }

        <div class="mt-8 flex w-full flex-col items-center">
            <img
                class="h-[200px] w-[410px]"
                [src]="'mapstr-api-token' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
        </div>
    </div>
</app-modal-structure>

<ng-template #loadingTemplate>
    <div class="flex h-full items-center justify-center">
        <app-malou-spinner></app-malou-spinner>
    </div>
</ng-template>

<ng-template #restaurantFoundTemplate>
    <mat-radio-button class="rounded-md px-4 py-2 hover:bg-malou-color-background-purple" [checked]="true">
        <div class="flex items-center justify-between">
            <div class="ml-4 flex flex-col">
                <div class="malou-text-12--bold malou-color-text-1">{{ restaurant.name }}</div>
                <div class="malou-text-12--regular malou-color-text-1 italic">{{ restaurant.fullFormattedAddress }}</div>
            </div>
        </div>
    </mat-radio-button>
</ng-template>
