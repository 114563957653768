import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { ToastService } from ':core/services/toast.service';
import { ZenchefConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-zenchef-modal/zenchef-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { CreateCredentialService } from ':modules/platforms/platforms-connection-modals/shared/services/create-credential.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-zenchef-connection-step-1',
    templateUrl: './zenchef-connection-step-1.component.html',
    styleUrls: ['./zenchef-connection-step-1.component.scss'],
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        MalouSpinnerComponent,
        ImagePathResolverPipe,
        InputTextComponent,
        ReactiveFormsModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZenchefConnectionStep1Component extends BaseStepComponent<undefined, ZenchefConnectionModalResult> {
    private readonly _createCredentialService = inject(CreateCredentialService);
    private readonly _translateService = inject(TranslateService);
    private readonly _spinnerService = inject(SpinnerService);
    private readonly _toastService = inject(ToastService);
    private readonly _router = inject(Router);

    readonly ButtonStyle = ButtonStyle;

    readonly apiTokenControl = new FormControl<string>('', Validators.required);
    readonly loginControl = new FormControl<string>('', [Validators.required, Validators.email]);

    readonly isEmailInError$ = this.loginControl.statusChanges.pipe(map(() => this.loginControl.errors?.email ?? false));
    readonly isEmailInError = toSignal<boolean, boolean>(this.isEmailInError$, {
        initialValue: false,
    });
    currentLang = toSignal(this._translateService.onLangChange.pipe(map((v) => v.lang)), {
        initialValue: this._translateService.currentLang,
    });
    imageLang = computed(() => (this.currentLang() === 'fr' ? 'fr' : 'en'));

    onPrimaryClick(): void {
        const login = this.loginControl.value;
        const apiToken = this.apiTokenControl.value;
        if (!login || !apiToken) {
            this._toastService.openErrorToast(this._translateService.instant('common.error'));
            return;
        }
        this._spinnerService.show();
        this._createCredentialService.execute(PlatformKey.ZENCHEF, { type: 'apiToken', login, apiToken }).subscribe({
            next: (redirect) => {
                this._spinnerService.hide();
                this.close.emit({ hasDataChanged: true });
                this._router.navigateByUrl(`${redirect}&redirect=${this._router.url}`);
            },
            error: (err) => {
                console.error(err);
                this._spinnerService.hide();
                this._toastService.openErrorToast(this._translateService.instant('common.error'));
            },
        });
    }

    onTertiaryClick(): void {
        window.open(
            'https://welcomehomemalou.notion.site/Comment-connecter-Zenchef-la-MalouApp-b547bf0932c845168991144a9775170f',
            '_blank'
        );
    }
}
