@let storeSemanticAnalysisToggle = !!(storedSemanticAnalysisToggle$ | async);

<div class="flex items-center gap-x-4">
    <div class="malou-text-12--medium malou-color-text-1">
        {{ isAggregatedView() ? ('reviews.semantic_analysis_short' | translate) : ('reviews.semantic_analysis' | translate) }}
    </div>
    <app-slide-toggle
        activeLabel="{{ 'common.yes' | translate }}"
        inactiveLabel="{{ 'common.no' | translate }}"
        [id]="storeSemanticAnalysisToggle ? 'tracking_hide_reviews_semantic_analysis' : 'tracking_show_reviews_semantic_analysis'"
        [testId]="'reviews-semantic-analysis-btn'"
        [switchWidth]="70"
        [checked]="storeSemanticAnalysisToggle"
        (onToggle)="toggleSemanticAnalysis(!$event)">
    </app-slide-toggle>
</div>
