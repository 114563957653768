@let isFeaturePlatformsUpdatesV2Enabled = isFeaturePlatformsUpdatesV2Enabled$ | async;

<div class="footer-manager__container">
    <app-review-synchronization-footer
        class="fixed bottom-0 left-0"
        [ngClass]="(openedFootersOrder | applySelfPure: 'indexOf' : AvailableFooterType.REVIEW_SYNCHRONIZATION) === 0 ? 'z-10' : 'z-20'">
    </app-review-synchronization-footer>
    <app-post-publication-status-footer
        class="fixed bottom-0 left-0"
        [ngClass]="(openedFootersOrder | applySelfPure: 'indexOf' : AvailableFooterType.POSTING_STATUS) === 0 ? 'z-10' : 'z-20'">
    </app-post-publication-status-footer>
    @if (!isFeaturePlatformsUpdatesV2Enabled) {
        <app-information-update-footer
            class="fixed bottom-0 left-0"
            [isAggregatedView]="isAggregatedView"
            [ngClass]="(openedFootersOrder | applySelfPure: 'indexOf' : AvailableFooterType.INFORMATION_UPDATE) === 0 ? 'z-10' : 'z-20'">
        </app-information-update-footer>
    }
    <app-campaign-footer
        class="fixed bottom-0 left-0"
        [ngClass]="(openedFootersOrder | applySelfPure: 'indexOf' : AvailableFooterType.CAMPAIGN) === 0 ? 'z-10' : 'z-20'">
    </app-campaign-footer>
</div>
