import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { SpecialHourNotification } from ':core/components/notification-center/models/special-hour-notification.model';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-special-hour-notification-item',
    templateUrl: './special-hour-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, TranslateModule, DatePipe, MatButtonModule],
})
export class SpecialHourNotificationComponent extends NotificationItemComponent {
    readonly notification = computed(() => this.initialNotification() as SpecialHourNotification);
    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);
}
