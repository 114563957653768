import { NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    inject,
    input,
    OnInit,
    signal,
    Signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { isNumber, round } from 'lodash';
import { combineLatest, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { selectCurrentPlatformKeys } from ':modules/platforms/store/platforms.reducer';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiService } from ':modules/roi/roi.service';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod, Restaurant } from ':shared/models';
import { RoiPerformanceScoreDetails } from ':shared/models/roi-performance-score.model';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import * as StatisticsSelector from '../../store/statistics.selectors';
import { PerformanceGaugeChartComponent } from './performance-gauge-chart/performance-gauge-chart.component';
import { PerformanceScoreDetailsModalComponent } from './performance-score-details-modal/performance-score-details-modal.component';

@Component({
    selector: 'app-performance-score',
    standalone: true,
    imports: [
        NgStyle,
        MatIconModule,
        NgTemplateOutlet,
        MatTooltipModule,
        PerformanceGaugeChartComponent,
        NumberEvolutionComponent,
        ShortNumberPipe,
        TranslateModule,
        NgTemplateOutlet,
        SkeletonComponent,
        IllustrationPathResolverPipe,
    ],
    templateUrl: './performance-score.component.html',
    styleUrl: './performance-score.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceScoreComponent implements OnInit {
    readonly isParentLoading = input.required<boolean>();

    readonly performanceScoreData: WritableSignal<RoiPerformanceScoreDetails | null> = signal(null);
    readonly similarLocationsPerformanceScoreData: WritableSignal<any | null> = signal(null);

    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _roiContext = inject(RoiContext);

    readonly isPerformanceLoading = signal(false);

    readonly performanceScore: Signal<number | null> = computed(() => this._getPerformanceScore(this.performanceScoreData()));
    readonly performanceScoreEvolution: Signal<number | null> = computed(() => {
        const score = this.performanceScoreData()?.performanceScoreEvolution;
        return score ? round(score, 0) : null;
    });
    readonly isPerformanceScoreEvolutionTooHigh: Signal<boolean> = computed(
        () => Math.abs(this.performanceScoreEvolution() ?? 0) > this._MAX_DISPLAY_EVOLUTION
    );
    readonly hasSimilarLocations: Signal<boolean> = computed(() => isNumber(this.similarLocationsPerformanceScoreData()?.performanceScore));
    readonly similarLocationsPerformanceScore: Signal<number> = computed(() =>
        this.hasSimilarLocations() ? round(this.similarLocationsPerformanceScoreData()?.performanceScore, 0) : 0
    );

    readonly Illustration = Illustration;

    private readonly _store = inject(Store);
    private readonly _roiService = inject(RoiService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly SvgIcon = SvgIcon;
    readonly selectedTimeScaleFilter$ = this._store.select(StatisticsSelector.selectTimeScaleFilter);
    readonly selectedMonths$ = this.selectedTimeScaleFilter$.pipe(
        map((timeScale) => getSelectedMonthsNumberFromTimeScaleFilter(timeScale))
    );
    readonly selectedMonths: Signal<number> = toSignal(this.selectedMonths$, {
        initialValue: getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS),
    });

    private readonly _MAX_DISPLAY_EVOLUTION = 200;

    ngOnInit(): void {
        this._getPerformanceScoreDetails();
    }

    openDetails(): void {
        this._customDialogService.open(
            PerformanceScoreDetailsModalComponent,
            {
                height: 'unset',
                maxHeight: '90vh',
                data: {
                    performanceData: this.performanceScoreData(),
                },
            },
            { closeOnOutsideClick: true }
        );
    }

    private _getPerformanceScoreDetails(): any {
        combineLatest([
            this._restaurantsService.restaurantSelected$,
            this.selectedTimeScaleFilter$,
            this._store.select(selectCurrentPlatformKeys),
        ])
            .pipe(
                tap(() => this.isPerformanceLoading.set(true)),
                filter(([restaurant, _]: [Restaurant, MalouTimeScalePeriod | undefined, PlatformKey[]]) => isNotNil(restaurant)),
                switchMap(([restaurant, _, platformKeys]: [Restaurant, MalouTimeScalePeriod | undefined, PlatformKey[]]) =>
                    forkJoin([
                        this._roiService
                            .getPerformanceScoreForNLastMonths(restaurant._id, this.selectedMonths())
                            .pipe(map((res) => res.data)),
                        this._roiService
                            .getPerformanceScoreForNLastMonths(restaurant._id, this.selectedMonths(), {
                                similarLocations: true,
                            })
                            .pipe(map((res) => res.data)),
                        of(platformKeys),
                    ])
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ([performanceScoreData, similarLocationsData, platformKeys]) => {
                    this.performanceScoreData.set(
                        performanceScoreData ? new RoiPerformanceScoreDetails(performanceScoreData ?? {}, platformKeys) : null
                    );
                    this.similarLocationsPerformanceScoreData.set(similarLocationsData ?? null);
                    this._roiContext.performanceScore.set(this._getPerformanceScore(this.performanceScoreData()) ?? 0);
                    this.isPerformanceLoading.set(false);
                },
                error: (err) => {
                    console.warn(err);
                    this.isPerformanceLoading.set(false);
                },
            });
    }

    private _getPerformanceScore(performanceScoreData: RoiPerformanceScoreDetails | null): number | null {
        const score = performanceScoreData?.performanceScore;
        return score ? round(score, 0) : null;
    }
}
