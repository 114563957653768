@if (bindingId) {
    <div>
        <div class="malou-box-shadow posts-status-footer__container flex items-center justify-end px-6" id="post-publication-status">
            <ng-container [ngTemplateOutlet]="synchronizationStatusTemplate" [ngTemplateOutletContext]="{ status }"></ng-container>
            <ng-container [ngTemplateOutlet]="logosLoading" [ngTemplateOutletContext]="{ status }"></ng-container>
            <button class="malou-btn-icon ml-3" mat-icon-button (click)="close()">
                <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>
    </div>
}

<ng-template let-status="status" #synchronizationStatusTemplate>
    <div class="flex items-center">
        <div class="mr-2 flex items-center">
            @switch (status) {
                @case (SynchronizationStatus.LOADING) {
                    <div class="flex items-center">
                        <mat-icon class="malou-spin mr-4" color="primary" [svgIcon]="SvgIcon.SPINNER"></mat-icon>
                        <span class="malou-text-12--bold">
                            {{ 'posts.footer.posting_in_progress' | translate }}
                        </span>
                    </div>
                }
                @case (SynchronizationStatus.LOADED) {
                    <div class="malou-bg-state-success mr-2 grid h-4 w-4 place-items-center rounded-full">
                        <mat-icon class="!h-[9px] !w-[9px] text-white" [svgIcon]="SvgIcon.CHECK"> </mat-icon>
                    </div>
                    <span class="malou-text-12--bold">
                        {{ 'posts.footer.posting_ended_without_errors' | translate }}
                    </span>
                }
                @case (SynchronizationStatus.ERROR) {
                    <div class="malou-bg-state-error mr-2 grid h-4 w-4 place-items-center rounded-full">
                        <mat-icon class="!h-[9px] !w-[9px] text-white" [svgIcon]="SvgIcon.CROSS"> </mat-icon>
                    </div>
                    <span class="malou-text-12--bold">
                        {{ 'posts.footer.posting_ended_with_errors' | translate }}
                    </span>
                }
            }
        </div>
    </div>
</ng-template>

<ng-template let-status="status" #logosLoading>
    <div class="flex justify-evenly">
        @for (platformStatus of postingStatus; track platformStatus) {
            <div class="relative mx-1">
                <div class="icon-img">
                    @if (platformStatus.platformKey) {
                        <img
                            alt="logo {{ platformStatus.platformKey }}"
                            height="34"
                            width="34"
                            [src]="platformStatus.platformKey | platformLogoPathResolver" />
                    }
                    <div class="svg-container">
                        <svg class="circle">
                            <g>
                                <ellipse
                                    class="foreground"
                                    ry="17"
                                    rx="17"
                                    stroke-width="35"
                                    [ngClass]="{
                                        loading: platformStatus.step1 === StepStatus.PENDING,
                                        loaded: [StepStatus.SUCCESS, StepStatus.ERROR] | includes: platformStatus.step1,
                                    }" />
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="absolute bottom-0 right-0">
                    <div>
                        @if (platformStatus.step1) {
                            @switch (platformStatus.step1) {
                                @case (StepStatus.SUCCESS) {
                                    <div class="malou-bg-state-success grid h-4 w-4 place-items-center rounded-full">
                                        <mat-icon class="!h-[9px] !w-[9px] text-white" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                                    </div>
                                }
                                @case (StepStatus.ERROR) {
                                    <div class="malou-bg-state-error grid h-4 w-4 place-items-center rounded-full">
                                        <mat-icon
                                            class="!h-[9px] !w-[9px] text-white"
                                            [svgIcon]="SvgIcon.CROSS"
                                            [matTooltip]="platformStatus.step1Error | httpError">
                                        </mat-icon>
                                    </div>
                                }
                                @default {
                                    <ng-container [ngTemplateOutlet]="loadingIconTemplate"></ng-container>
                                }
                            }
                        }
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #loadingIconTemplate>
    <div class="malou-bg-state-warn grid h-4 w-4 place-items-center rounded-full">
        <mat-icon class="!h-[9px] !w-[9px] text-white" [svgIcon]="SvgIcon.ALARM"> </mat-icon>
    </div>
</ng-template>
