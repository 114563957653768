import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnInit, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { APP_DEFAULT_LANGUAGE, HeapEventName, MalouErrorCode } from '@malou-io/package-utils';

import { GiftDrawsService } from ':core/services/gift-draws.service';
import { HeapService } from ':core/services/heap.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { GiftDrawPopulated } from ':shared/models/gift-draw';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HtmlTagPipe } from ':shared/pipes/html-tag.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

import { RedirectWheelOfFortuneMobileComponent } from '../play-wheel-of-fortune/redirect-wheel-of-fortune-mobile/redirect-wheel-of-fortune-mobile.component';
import { WheelOfFortuneMessage } from '../wheel-of-fortune-messages/wheel-of-fortune-messages.component';

@Component({
    selector: 'app-get-my-gift',
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        MatButtonModule,
        TranslateModule,
        ApplySelfPurePipe,
        HtmlTagPipe,
        ImagePathResolverPipe,
        IncludesPipe,
        RedirectWheelOfFortuneMobileComponent,
    ],
    templateUrl: './get-my-gift.component.html',
    styleUrls: ['./get-my-gift.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetMyGiftComponent implements OnInit {
    readonly giftDraw: WritableSignal<GiftDrawPopulated | null> = signal(null);
    readonly logo = computed(() => this.giftDraw()?.getRestaurantLogoUrl() ?? null);

    readonly isOnSite = signal(false);
    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    private readonly _giftDrawId: string;
    private readonly _locale: string;
    private readonly _heapTrackingId: HeapEventName;

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _giftDrawsService: GiftDrawsService,
        private readonly _spinnerService: SpinnerService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _heapService: HeapService
    ) {
        this._locale = localStorage.getItem(LocalStorageKey.LANG) || APP_DEFAULT_LANGUAGE;
        this._giftDrawId = this._activatedRoute.snapshot.params.gift_draw_id;
        this._heapTrackingId = this._activatedRoute.snapshot.queryParams.heap;
    }

    ngOnInit(): void {
        if (!this._giftDrawId) {
            this._router.navigate(['./wheel-of-fortune-not-found']);
            return;
        }
        this._giftDrawsService.getGiftDrawById(this._giftDrawId).subscribe({
            next: (res) => {
                const giftDraw = new GiftDrawPopulated(res.data);
                this.giftDraw.set(giftDraw);
                this._trackHeapEvent(this._heapTrackingId, giftDraw);
                this._redirectAccordingToGiftDrawState(giftDraw);
            },
            error: (err) => {
                this._toastService.openErrorToast(this._translateService.instant('get_my_gift.errors.error_getting_gift_draw'));
                if (err.error.malouErrorCode === MalouErrorCode.GIFT_DRAW_NOT_FOUND) {
                    this._router.navigate(['./wheel-of-fortune-not-found']);
                }
            },
        });
    }

    onGiftRetrieved(): void {
        this._spinnerService.show();

        this._giftDrawsService.setGiftRetrieved(this._giftDrawId).subscribe({
            next: () => {
                this._spinnerService.hide();
                this._redirectToWheelOfFortuneNotifications(WheelOfFortuneMessage.THANKS_FOR_PLAYING);
            },
            error: () => {
                this._toastService.openErrorToast(this._translateService.instant('get_my_gift.errors.error_setting_gift_retrieved'));
                this._spinnerService.hide();
            },
        });
    }

    private _redirectAccordingToGiftDrawState(giftDraw: GiftDrawPopulated | undefined): void {
        if (!giftDraw) {
            return this._redirectToWheelOfFortuneNotifications(WheelOfFortuneMessage.GIFT_DRAW_NOT_SET);
        }
        if (giftDraw.hasAlreadyBeenClaimed()) {
            return this._redirectToWheelOfFortuneNotifications(WheelOfFortuneMessage.GIFT_DRAW_ALREADY_CLAIMED);
        }
        if (giftDraw.isExpired()) {
            return this._redirectToWheelOfFortuneNotifications(WheelOfFortuneMessage.GIFT_DRAW_EXPIRED);
        }
        if (giftDraw.isNotClaimableYet()) {
            return this._redirectToWheelOfFortuneNotifications(WheelOfFortuneMessage.GIFT_DRAW_NOT_CLAIMABLE_YET);
        }
    }

    private _redirectToWheelOfFortuneNotifications(message: WheelOfFortuneMessage): void {
        const date =
            message === WheelOfFortuneMessage.GIFT_DRAW_NOT_CLAIMABLE_YET
                ? this.giftDraw()?.getFormattedRetrievalStartDate(this._locale)
                : undefined;

        this._router.navigate(['./wheel-of-fortune-messages'], {
            queryParams: {
                message,
                color: this.giftDraw()?.wheelOfFortune.parameters.primaryColor,
                date,
            },
        });
    }

    private _trackHeapEvent(heapEventName: HeapEventName, giftDraw: GiftDrawPopulated): void {
        if (
            [HeapEventName.WHEEL_OF_FORTUNE_GIFT_EXPIRES_SOON, HeapEventName.WHEEL_OF_FORTUNE_RETRIEVE_GIFT].includes(heapEventName) &&
            giftDraw.client?.email &&
            giftDraw.id
        ) {
            this._heapService.track(heapEventName, { giftDrawId: giftDraw.id, receiverEmail: giftDraw.client.email });
        }
    }
}
