import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { HeaderComponent } from ':shared/components/header/header.component';

@Component({
    selector: 'app-restaurant-list',
    templateUrl: './restaurant-list.component.html',
    styleUrls: ['./restaurant-list.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet],
})
export class RestaurantListComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
