import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';
import { Restaurant } from ':shared/models';

@Component({
    selector: 'app-social',
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.scss'],
    standalone: true,
    imports: [NavigationHeaderComponent, RouterOutlet],
})
export class SocialComponent implements OnInit {
    readonly TABS: NavigationTab[] = [
        {
            label: this._translateService.instant('app_sidebar.social_networks.posts'),
            link: './socialposts',
        },
        {
            label: this._translateService.instant('app_sidebar.social_networks.stories'),
            link: './stories',
        },
        {
            label: this._translateService.instant('app_sidebar.social_networks.inspirations'),
            link: './inspirations',
        },
    ];

    restaurant: Restaurant;

    private _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil), takeUntilDestroyed(this._destroyRef)).subscribe((res) => {
            this.restaurant = res;
        });
    }
}
