<div
    class="flex flex-col gap-y-5 border-t border-malou-color-border-primary p-5"
    (click)="newSocialPostHashtagsContext.openPostHashtagsPanel()"
    (appClickOutside)="newSocialPostHashtagsContext.closePostHashtagsPanel()">
    @if (!newSocialPostHashtagsContext.isGeneratingHashtags() && newSocialPostHashtagsContext.selectedHashtags().length === 0) {
        <button
            class="malou-btn-flat !pl-0 !pr-0"
            data-testid="social-post-generate-hashtags-btn"
            mat-button
            [id]="
                'tracking_new_social_post_modal_generate_' + (newSocialPostContext.isReel() ? 'reel' : 'post') + '_hashtags_with_ai_button'
            "
            [disabled]="newSocialPostAiContext.isGeneratingPostTextFromIA() || postText().length === 0"
            (click)="chooseHashtagsWithAi()">
            <div class="flex items-center gap-x-1">
                <mat-icon
                    class="h-4 !w-4 text-malou-color-chart-purple--accent"
                    [svgIcon]="SvgIcon.MAGIC_WAND"
                    [ngClass]="{ 'mr-1': !newSocialPostContext.isSmallScreen() }"></mat-icon>

                <span class="!malou-text-10--semibold text-malou-color-chart-purple--accent">{{
                    'social_posts.new_social_post.add_hashtags' | translate
                }}</span>
            </div>
        </button>
    } @else if (!newSocialPostHashtagsContext.isGeneratingHashtags() && newSocialPostHashtagsContext.selectedHashtags().length > 0) {
        <div class="flex flex-wrap items-center gap-2">
            @for (hashtag of newSocialPostHashtagsContext.selectedHashtags(); track $index) {
                <div class="malou-chip--rounded-4 malou-chip--primary" data-testid="social-post-hashtag-chip">
                    <span>{{ hashtag.text }}</span>
                    <mat-icon
                        class="malou-chip-icon--right malou-color-primary shrink-0"
                        [svgIcon]="SvgIcon.REMOVE"
                        (click)="removeHashtag($index)"></mat-icon>
                </div>
            }
        </div>
    }
    <!--  -->
    @if (newSocialPostHashtagsContext.isGeneratingHashtags()) {
        <app-skeleton
            flexWrap="flex-wrap"
            flexDirection="flex-row"
            gapClass="gap-2"
            skeletonClass="!h-[2.5rem] !w-[6.5rem]"
            [count]="6"></app-skeleton>
    }
    <!--  -->
    @if (newSocialPostHashtagsContext.isGeneratingHashtags() || newSocialPostHashtagsContext.selectedHashtags().length > 0) {
        <div class="flex items-center gap-x-2">
            <mat-icon
                class="!w-4 cursor-pointer text-malou-color-chart-purple--accent"
                [svgIcon]="SvgIcon.MAGIC_WAND"
                (click)="newSocialPostHashtagsContext.openPostHashtagsPanel()"></mat-icon>
            <button
                class="malou-btn-raised--secondary btn-sm !h-auto !rounded !px-2.5 !py-2 !text-[10px] !font-medium !text-malou-color-chart-purple--accent"
                mat-raised-button
                [disabled]="newSocialPostHashtagsContext.isGeneratingHashtags()"
                (click)="retryHashtagsGeneration()">
                {{ 'common.retry' | translate }}
            </button>
        </div>
    }
</div>
