@if (!isAggregatedView() || !simplerCombinedReviewsExperimentationEnabled()) {
    <div class="malou-dialog__header">
        <span class="order-1 whitespace-nowrap">
            {{ 'reviews.reply_modal.respond_to_review' | translate }}
        </span>
        <div class="order-3 flex items-center md:order-2">
            @if (!isAggregatedView()) {
                <button
                    class="malou-btn-raised--secondary btn-sm mr-4 !h-8 !rounded !px-2.5 !py-2 !text-malou-color-chart-purple--accent"
                    mat-raised-button
                    (click)="goToAiSettings()">
                    <mat-icon [svgIcon]="SvgIcon.AUTOMATION"></mat-icon>
                    <span class="malou-text-10--medium">{{ 'reviews.reply_modal.automation' | translate }}</span>
                </button>
            }
            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>
    </div>
}

@let review = review$ | async;

@if (review) {
    <div class="malou-dialog__body">
        <form class="flex flex-col gap-y-4" [formGroup]="replyReviewForm">
            <app-basic-preview
                [id]="review._id"
                [review]="review"
                [withArchiveButton]="false"
                [withSeeMoreButton]="false"
                [isSelected]="true"
                [isSelectable]="false"
                [hasBorder]="false"
                [displayFullText]="true"
                [disabled]="false"
                [shouldDisplaySemanticAnalysis]="shouldDisplaySemanticAnalysis()"
                [isAggregatedView]="isAggregatedView()"
                [restaurant]="restaurant$ | async"
                [canShowTranslation]="true"
                (cancel)="close()"
                (select)="(null)"></app-basic-preview>
            <ng-container [ngTemplateOutlet]="commentsTemplate" [ngTemplateOutletContext]="{ reviewData: review }"></ng-container>
            @if (
                review.comments.length === 0 ||
                (review.comments.length && (review | canHaveMultipleReplies)) ||
                (review.comments.length && (review | canBeEdited))
            ) {
                @if (review.comments.length && (review | canHaveMultipleReplies)) {
                    <div class="malou-text-14--bold malou-color-text-1">
                        {{ 'reviews.reply_modal.new_reply' | translate }}
                    </div>
                } @else {
                    @if (review.comments.length && (review | canBeEdited)) {
                        <div class="malou-text-14--bold malou-color-text-1">
                            {{ 'reviews.reply_modal.edit_reply' | translate }}
                        </div>
                    }
                }
                <ng-container
                    [ngTemplateOutlet]="selectReviewReplyTemplate"
                    [ngTemplateOutletContext]="{ reviewData: review }"></ng-container>
                @if (review | isPrivate) {
                    <ng-container [ngTemplateOutlet]="privateTemplate"></ng-container>
                }
                <ng-container [ngTemplateOutlet]="chips"></ng-container>
                <div [ngClass]="{ 'flex gap-2': !screenSizeService.isPhoneScreen }">
                    <ng-container
                        [ngTemplateOutlet]="replyArea"
                        [ngTemplateOutletContext]="{
                            reviewData: review,
                        }"></ng-container>
                    <div class="mt-2">
                        <ng-container [ngTemplateOutlet]="keywords"></ng-container>
                    </div>
                </div>
                @if (!screenSizeService.isPhoneScreen) {
                    <ng-container [ngTemplateOutlet]="tips"></ng-container>
                }
                <ng-container [ngTemplateOutlet]="saveTemplate"></ng-container>
            }
        </form>
    </div>

    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        @if (
            review.comments.length === 0 ||
            (review.comments.length && (review | canHaveMultipleReplies)) ||
            (review.comments.length && (review | canBeEdited))
        ) {
            <button
                class="malou-btn-raised--primary !h-11 md:grow"
                id="tracking_answer_review_modal_reply_button"
                data-testid="answer-review-next-btn"
                mat-raised-button
                [disabled]="
                    doesTextHaveErrors() ||
                    (replyText$ | async) === '' ||
                    (saveTemplateForm.invalid && saveTemplateActivated.value) ||
                    (review.isPrivate() && !isPrivateFormValid())
                "
                (click)="replyAndGoNext()">
                {{ (isUpdate() ? 'reviews.reply_modal.update_and_next' : 'reviews.reply_modal.reply_and_next') | translate }}
            </button>
        } @else {
            <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button (click)="shouldGoNext()">
                {{ 'reviews.reply_modal.next' | translate }}
            </button>
        }
    </div>
}

<ng-template let-reviewData="reviewData" #commentsTemplate>
    @for (comment of review | applySelfPure: 'getComments'; track comment) {
        <div>
            <app-review-reply-preview
                [replyText]="comment.text"
                [rating]="reviewData.rating"
                [restaurant$]="restaurant$"
                [lang]="reviewData.lang"
                [keywordsLang]="reviewData.keywordsLang ?? ''"
                [restaurantKeywords$]="restaurantKeywords$"
                [reviewerName$]="reviewerName$"
                [restaurantAiSettings$]="restaurantAiSettings$"
                [commentDate]="comment.socialUpdatedAt ?? null"
                [reviewSocialCreatedAt]="reviewData.socialCreatedAt">
            </app-review-reply-preview>
        </div>
    }
</ng-template>

<ng-template #chips>
    <div class="flex flex-wrap gap-1">
        @for (chip of CHIP_LIST; track chip) {
            <div class="malou-chip--rounded-4 malou-chip--primary" (click)="addChipToText(chip)">
                <span>{{ chip }}</span>
            </div>
        }
    </div>
</ng-template>

<ng-template let-reviewData="reviewData" #replyArea>
    <div class="grow">
        <div class="flex justify-between">
            <div class="malou-text-10--regular malou-color-text-2 flex items-center">
                {{ 'reviews.reply_modal.message' | translate }}
            </div>
            <div class="flex">
                <ng-container [ngTemplateOutlet]="replyText.value ? aiModifyTemplate : aiGenerateTemplate"></ng-container>
                @if (reviewData.comments.length === 0) {
                    <div class="pb-1">
                        <app-interactions-browser
                            previousIconId="tracking_answer_review_modal_previous_interaction_button"
                            nextIconId="tracking_answer_review_modal_next_interaction_button"
                            [interactions]="interactions()"
                            [reset]="resetBrowser()"
                            (interactionChanged)="onInteractionChanged($event)">
                        </app-interactions-browser>
                    </div>
                }
            </div>
        </div>

        <app-text-area
            [testId]="'answer-review-text-area-input'"
            [textAreaId]="TEXT_AREA_ID"
            [placeholder]="'reviews.reply_modal.placeholder' | translate"
            [formControl]="replyText"
            [isEmojiPickerEnabled]="shouldEnableEmojiPicker | applyPure: reviewData"
            [debounceTime]="200"
            [rows]="7"
            [shouldDisplayAiInteractionHeadBand]="shouldDisplayAiInteractionHeadBand()"
            [isLoadingAnimationEnabled]="isTextAreaLoadingAnimationEnabled()"
            (textAreaChange)="onReplyTextChange($event)"
            (hideAiInteractionReply)="onHideAiInteractionReply(reviewData)"
            (input)="onTextAreaInput($event)">
            <ng-template #loadingAnimationTemplate>
                <app-infinite-text-slide [defaultText]="loadingAnimationDefaultText()" [slideTextList]="loadingAnimationSlideTextList()">
                </app-infinite-text-slide>
            </ng-template>
        </app-text-area>

        @if (doesTextHaveErrors()) {
            <div class="malou-text-10--medium malou-color-state-error">
                {{ replyText$ | async | replyTextToError }}
            </div>
        }
    </div>
</ng-template>

<ng-template #keywords>
    <app-keywords-score-gauge
        [text$]="replyText$"
        [restaurant$]="restaurant$"
        [textType$]="gaugeTextType$"
        [lang$]="lang$"
        [withLargeDetails]="true"
        [langOptions]="langOptions()"
        [keywords$]="restaurantKeywords$"
        [shouldDisplayKeywords]="true"
        [shouldDisplayKeywordsCategory]="screenSizeService.isPhoneScreen"
        [shouldDisplayTips]="screenSizeService.isPhoneScreen"
        [reviewerName$]="reviewerName$"
        [responseTime$]="responseTime$"
        [parentElementId$]="reviewId$"
        [restaurantAiSettings$]="restaurantAiSettings$"
        [title]="'reviews.reply_modal.keyword_score_gauge' | translate"
        (addKeyword)="addElementToText($event)"
        (indicationListChanged)="keywordsIndicationList.set($event)"
        #keywordsScoreGauge>
    </app-keywords-score-gauge>
</ng-template>

<ng-template #tips>
    <div class="malou-card malou-color-background-dark !m-0 w-full !flex-auto">
        <div class="malou-text-12--bold mb-4">{{ 'keywords_score.tips' | translate }}</div>
        <div class="flex">
            <app-keywords-score-tips class="flex gap-2" [indicationsList]="keywordsIndicationList()"></app-keywords-score-tips>
        </div>
    </div>
</ng-template>

<ng-template #saveTemplate>
    <app-save-reply-as-template
        [(shouldDisplayTemplateUpdateWarning)]="shouldDisplayTemplateUpdateWarning"
        [templates]="templates()"
        [saveTemplateForm]="saveTemplateForm"
        [availableCommentOptions]="availableCommentOptions"
        (saveTemplateActivedChange)="onSaveTemplateActivedChange($event)"
        (templateNameChange)="onTemplateNameChange($event)"
        (ratingsChange)="onRatingsChange($event)"></app-save-reply-as-template>
</ng-template>

<ng-template #aiGenerateTemplate>
    <app-ai-generate-reply
        [aiPromptButtonTooltip]="aiPromptButtonTooltip()"
        [disabled]="!restaurant$.value?.ai?.activated || isAiResponseLoading() || aiRateLimitReached()"
        (answerReviewWithAi)="answerReviewWithAi()"></app-ai-generate-reply>
</ng-template>

<ng-template #aiModifyTemplate>
    <app-ai-modify-reply
        [aiTranslateLangs]="langOptions()"
        [aiPromptButtonTooltip]="aiPromptButtonTooltip()"
        [disabled]="!restaurant$.value?.ai?.activated || isAiResponseLoading() || aiRateLimitReached()"
        [displayAiOptimizeButton]="isAiOptimizeButtonDisplayed()"
        (answerReviewWithAi)="answerReviewWithAi()"
        (aiOptimize)="aiOptimize()"
        (translateTextWithAi)="translateTextWithAi($event)"></app-ai-modify-reply>
</ng-template>

<ng-template let-reviewData="reviewData" #selectReviewReplyTemplate>
    <app-select-review-reply-template
        [review]="reviewData"
        [templates]="templates()"
        (templateChange)="onTemplateChange($event)"></app-select-review-reply-template>
</ng-template>

<ng-template #privateTemplate>
    <app-answer-private-review
        [restaurant]="restaurant$ | async"
        [currentUser]="currentUser()"
        [review]="review"
        (onValidChange)="updateIsPrivateFormValid($event)"
        #answerPrivateReview></app-answer-private-review>
</ng-template>

<ng-template #skeletonTemplate>
    <app-skeleton skeletonClass="secondary-bg h-[70vh] w-full p-4">
        <app-skeleton skeletonClass="h-[70px] w-full mt-4" [count]="5"></app-skeleton>
    </app-skeleton>
</ng-template>
