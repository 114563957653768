<div class="malou-dialog h-full">
    <div class="malou-dialog__header">
        <div class="flex flex-col">
            <span class="malou-text-18--bold">
                {{ 'roi.settings.settings' | translate }}
            </span>
            <span class="malou-text-12--regular malou-color-text-2 italic"
                >{{ 'roi.settings.confirm_settings' | translate }}{{ 'roi.settings.data_is_confidential' | translate }}</span
            >
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body mb-8 !overflow-hidden sm:!overflow-y-scroll">
        <app-upsert-roi-settings (onCancel)="close()" (onSave)="close(true)"></app-upsert-roi-settings>
    </div>
</div>
