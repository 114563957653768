<div class="rounded-[10px] bg-white p-3.5">
    <div class="malou-text-12--semibold">{{ 'social_posts.new_social_post.identify_account' | translate }}</div>
    <div class="malou-text-10--regular mt-3 italic text-malou-color-text-2">
        {{ 'social_posts.new_social_post.identify_account_description' | translate }}
    </div>
    <div class="malou-border-primary border-primary mt-3 flex h-12 self-stretch rounded-lg bg-white">
        <div class="w-full px-5">
            <input
                class="malou-text-12--medium box-border h-full w-full rounded-lg border-0 outline-none"
                type="text"
                [placeholder]="'common.search' | translate"
                [formControl]="tagControl()"
                [matAutocomplete]="auto" />
        </div>

        <div class="flex items-center pr-5">
            @if (!searching()) {
                <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
            }
            @if (searching()) {
                <mat-spinner class="stroke-malou-color-primary" [diameter]="16"></mat-spinner>
            }
        </div>
    </div>
    <mat-autocomplete
        class="!min-w-[300px] translate-x-[-10%] transform !rounded-[10px]"
        autoActiveFirstOption
        (optionSelected)="onAccountSelected.emit($event)"
        #auto="matAutocomplete">
        @if (foundAccount(); as foundAccount) {
            <mat-option [value]="foundAccount">
                <div class="flex items-center gap-x-3">
                    <img
                        class="h-7.5 w-7.5 rounded-full"
                        [defaultImage]="'default-picture-grey' | illustrationPathResolver"
                        [lazyLoad]="foundAccount.profile_picture_url"
                        [errorImage]="'default-picture-grey' | illustrationPathResolver" />
                    <span
                        class="malou-text-10--semibold text-malou-color-text-2"
                        [matTooltip]="foundAccount.username.length > 16 ? (foundAccount.username | shortText: 200) : ''">
                        {{ '@' }}{{ foundAccount.username | shortText: 16 }}
                        <span class="malou-text-10--regular italic text-malou-color-text-2"
                            >({{ foundAccount.followers_count }} {{ 'common.followers' | translate }})</span
                        >
                    </span>
                </div>
            </mat-option>
        }
    </mat-autocomplete>
</div>
