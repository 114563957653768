import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { selectCurrentPlatform } from ':modules/platforms/store/platforms.reducer';

type AuthorizedPlatformKeys =
    | PlatformKey.DELIVEROO
    | PlatformKey.FOURSQUARE
    | PlatformKey.LAFOURCHETTE
    | PlatformKey.OPENTABLE
    | PlatformKey.PAGESJAUNES
    | PlatformKey.RESY
    | PlatformKey.TRIPADVISOR
    | PlatformKey.UBEREATS
    | PlatformKey.YELP;

@Injectable({
    providedIn: 'root',
})
export class GetStepIndexFromPlatformService {
    private readonly _store = inject(Store);

    execute(platformKey: AuthorizedPlatformKeys, notConnectedStepIndex: number, connectedStepIndex: number): Observable<number> {
        const platform$ = this._store.select(selectCurrentPlatform({ platformKey })).pipe(take(1));
        return platform$.pipe(
            map((platform) => {
                if (platform) {
                    return connectedStepIndex;
                }
                return notConnectedStepIndex;
            })
        );
    }
}
