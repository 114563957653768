import { Component } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Settings } from 'luxon';

import { setLuxonDefaultTimeZone } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import * as UserSelectors from ':modules/user/store/user.selectors';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { MalouPeriod } from ':shared/models';

import * as AggregatedStatisticsActions from '../aggregated-statistics/store/aggregated-statistics.actions';

@Component({
    selector: 'app-aggregated-statistics-pdf',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './aggregated-statistics-pdf.component.html',
    styleUrls: ['./aggregated-statistics-pdf.component.scss'],
})
export class AggregatedStatisticsPdfComponent {
    constructor(
        private readonly _store: Store,
        private readonly _route: ActivatedRoute,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translateService: TranslateService
    ) {
        const parsedQueryParams = parseInsightsRouteParams(this._route.snapshot.queryParams.params);
        const { startDate, endDate, timeZone, restaurantIds } = parsedQueryParams;

        this._changeLuxonDefaultZone(timeZone);
        setLuxonDefaultTimeZone(timeZone);
        this._setLanguage();

        this._store.dispatch(
            AggregatedStatisticsActions.editDates({
                dates: { startDate, endDate, period: MalouPeriod.CUSTOM },
            })
        );

        this._restaurantsService.getRestaurantsByIds(restaurantIds ?? []).subscribe((restaurants) => {
            this._store.dispatch(AggregatedStatisticsActions.editRestaurants({ restaurants }));
        });
    }

    private _changeLuxonDefaultZone(timeZone: string): void {
        Settings.defaultZone = timeZone;
    }

    private _setLanguage(): void {
        this._store.select(UserSelectors.selectUserInfos).subscribe((user) => {
            if (user) {
                this._translateService.use(user.defaultLanguage);
            }
        });
    }
}
