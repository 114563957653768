<div class="flex flex-col sm:gap-4">
    <div class="mt-2 flex items-center justify-between gap-16">
        <div class="malou-text-section-title">
            <div class="flex items-center gap-2">
                @if (platformsErrorTooltip) {
                    <span
                        class="malou-status--waiting pdf-hidden"
                        [matTooltip]="'statistics.errors.platforms_error' | translate: { platforms: platformsErrorTooltip }">
                        <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                    </span>
                }
            </div>
        </div>
        <div class="hidden grow sm:block">
            <app-sort-by-filters
                [sortOptions]="SORT_OPTIONS"
                [sortBy]="'createdAt'"
                [sortOrder]="-1"
                (changeSortBy)="onSortByChange($event)"
                (toggleSortOrder)="onSortOrderChange()">
            </app-sort-by-filters>
        </div>
    </div>
    @if (!isLoading) {
        @if (areAllPlatformsInError) {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                <span class="malou-text-10--regular">{{
                    'statistics.errors.platforms_error' | translate: { platforms: platformsErrorTooltip }
                }}</span>
            </div>
        } @else {
            @if (!httpError) {
                @if (dataSource.data.length > 0) {
                    <mat-table
                        class="malou-mat-table"
                        matSort
                        matSortDisableClear
                        [dataSource]="dataSource"
                        [matSortActive]="defaultSort.active"
                        [matSortDirection]="defaultSort.direction"
                        #table="matTable">
                        <ng-container matColumnDef="picture">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <app-social-post-media
                                    class="!h-10"
                                    [postType]="postWithInsights.postType"
                                    [workingMedia]="postWithInsights.url"
                                    [firstAttachmentType]="postWithInsights.carouselUrls?.[0]?.type"
                                    [thumbnailUrl]="postWithInsights.thumbnail"
                                    [showVideoControls]="false"
                                    [customIconStyle]="{ top: '0px', right: '10px' }"
                                    [customMediaStyle]="{ height: '40px', width: '40px', 'border-radius': '6px' }"
                                    [socialLink]="postWithInsights.permalink"
                                    [shouldLazyLoadMedia]="shouldLazyLoadMedia">
                                </app-social-post-media>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="platform">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'statistics.social_networks.posts.platform' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                @if (shouldLazyLoadMedia) {
                                    <img class="h-4 w-4" [lazyLoad]="postWithInsights.key | platformLogoPathResolver" />
                                } @else {
                                    <img class="h-4 w-4" [src]="postWithInsights.key | platformLogoPathResolver" />
                                }
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="createdAt">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'statistics.social_networks.posts.created_at' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <span class="malou-color-text-1">
                                    @if (!postWithInsights.createdAt) {
                                        -
                                    }
                                    @if (postWithInsights.createdAt) {
                                        {{ postWithInsights.createdAt | formatDate: 'mediumDate' }}
                                        {{ postWithInsights.createdAt | formatDate: 'shortTime' }}
                                    }
                                </span>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="likes">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'statistics.social_networks.posts.likes' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <div
                                    class="sm:grid sm:grid-cols-[3.75rem_1fr]"
                                    [matTooltip]="
                                        (isInstagramCarrousel | applyPure: postWithInsights)
                                            ? ('statistics.social_networks.posts.carrousel_likes' | translate)
                                            : null
                                    ">
                                    <div class="pl-2">{{ postWithInsights.likes | shortNumber }}</div>
                                    <div class="malou-text-13--regular hidden sm:block">
                                        {{ 'statistics.social_networks.posts.likes' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="comments">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'statistics.social_networks.posts.comments' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                                    <div class="pl-2">{{ postWithInsights.comments | shortNumber }}</div>
                                    <div class="malou-text-13--regular hidden sm:block">
                                        {{ 'statistics.social_networks.posts.comments' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="shares">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'statistics.social_networks.posts.shares' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                                    <div class="pl-2">{{ postWithInsights.shares | shortNumber }}</div>
                                    <div class="malou-text-13--regular hidden sm:block">
                                        {{ 'statistics.social_networks.posts.shares' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="saved">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <mat-icon
                                    class="pdf-hidden mr-1 !h-4 !w-4"
                                    color="primary"
                                    [svgIcon]="SvgIcon.INFO"
                                    [matTooltip]="'statistics.social_networks.posts.not_retreived_for_facebook' | translate">
                                </mat-icon>
                                {{ 'statistics.social_networks.posts.saved' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                                    <div class="pl-2">
                                        {{ isFromFacebook(postWithInsights) ? '-' : (postWithInsights.saved | shortNumber) }}
                                    </div>
                                    <div class="malou-text-13--regular hidden sm:block">
                                        {{ 'statistics.social_networks.posts.saved' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="engagementRate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <mat-icon
                                    class="pdf-hidden mr-1 !h-4 !w-4"
                                    color="primary"
                                    [svgIcon]="SvgIcon.INFO"
                                    [matTooltip]="'statistics.social_networks.posts.engagement_details' | translate">
                                </mat-icon>
                                {{ 'statistics.social_networks.posts.engagement' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                                    <div class="pl-2">
                                        {{
                                            postWithInsights.engagementRate != null
                                                ? (postWithInsights.engagementRate | shortNumber: { content: '%' })
                                                : '-'
                                        }}
                                    </div>
                                    <div class="malou-text-13--regular hidden sm:block">
                                        {{ 'statistics.social_networks.posts.engagement' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="reach">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <mat-icon
                                    class="pdf-hidden mr-1 !h-4 !w-4"
                                    color="primary"
                                    [svgIcon]="SvgIcon.INFO"
                                    [matTooltip]="'statistics.social_networks.posts.reach_details' | translate">
                                </mat-icon>
                                {{ 'statistics.social_networks.posts.reach' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                                    <div class="pl-2">
                                        {{ postWithInsights.reach | shortNumber }}
                                    </div>
                                    <div class="malou-text-13--regular hidden sm:block">
                                        {{ 'statistics.social_networks.posts.reach' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="impressions">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'statistics.social_networks.posts.impressions' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                                    <div class="pl-2">
                                        {{ postWithInsights.impressions | shortNumber }}
                                    </div>
                                    <div class="malou-text-13--regular hidden sm:block">
                                        {{ 'statistics.social_networks.posts.impressions' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="plays">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'statistics.social_networks.posts.plays' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let postWithInsights; table: table">
                                <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                                    <div class="pl-2">{{ postWithInsights.plays | shortNumber }}</div>
                                    <div class="malou-text-13--regular hidden sm:block">
                                        {{ 'statistics.social_networks.posts.plays' | translate }}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
                        <mat-row *matRowDef="let postWithInsights; columns: DISPLAYED_COLUMNS; table: table; let i = index"></mat-row>
                    </mat-table>
                    @if (shouldDisplayElementsPaginated) {
                        <app-paginator
                            [shouldBeHidden]="dataSource.filteredData.length < 10"
                            [pageSize]="10"
                            [pageSizeOptions]="[5, 10, 20]"></app-paginator>
                    }
                } @else {
                    <div class="flex flex-col items-center py-6">
                        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                        <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
                        <span class="malou-text-10--regular">{{ 'statistics.errors.change_filters' | translate }}</span>
                    </div>
                }
            } @else {
                <div class="flex flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                    <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
                </div>
            }
        }
    } @else {
        <app-skeleton skeletonClass="!h-12 secondary-bg" [count]="5"></app-skeleton>
    }
</div>
