import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { BusinessCategory, isNotNil, sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { UpdateAggregatedRoiSettingsModalComponent } from ':modules/aggregated-statistics/roi/update-aggregated-roi-settings-modal/update-aggregated-roi-settings-modal.component';
import { NotificationsContext } from ':modules/notification-center/notifications.context';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import {
    ConnectRestaurantModalComponent,
    LastStep,
} from ':modules/restaurant-list/restaurants/connect-restaurant-modal/connect-restaurant-modal.component';
import { AfterCreateRestaurantModalComponent } from ':modules/restaurant-list/restaurants/create-restaurant-from-platform-modal/after-create-restaurant-modal/after-create-restaurant-modal.component';
import { EditInternalNameModalComponent } from ':modules/restaurant-list/restaurants/edit-internal-name-modal/edit-internal-name-modal.component';
import { CardRestaurantComponent } from ':modules/restaurant-list/restaurants/restaurant-card/restaurant-card.component';
import { RestaurantSetupModalComponent } from ':modules/roi/restaurant-setup-modal/restaurant-setup-modal.component';
import { RoiContext } from ':modules/roi/roi.context';
import { getShouldShowRoiSettingsModal } from ':modules/roi/utils/get-should-show-roi-settings-modal';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { UsersService } from ':modules/user/users.services';
import { NoResultsComponent } from ':shared/components/no-results/no-results.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { RestaurantSetupLegacyModalComponent } from ':shared/components/restaurant-setup-legacy-modal/restaurant-setup-legacy-modal.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

interface AppState {
    user: any;
}

@Component({
    selector: 'app-restaurants',
    templateUrl: './restaurants.component.html',
    styleUrls: ['./restaurants.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        CardRestaurantComponent,
        NoResultsComponent,
        PaginatorComponent,
        SearchComponent,
        SkeletonComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        LazyLoadImageModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        IllustrationPathResolverPipe,
        AsyncPipe,
    ],
})
export class RestaurantsComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    readonly SvgIcon = SvgIcon;

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly BusinessCategory = BusinessCategory;

    readonly NUMBER_OF_ELEMENTS_PER_PAGE = 50;
    readonly DISPLAYED_COLUMNS: string[] = ['logo', 'name', 'fullFormattedAddress'];

    public readonly isPaying$ = this._store.select((state) => state.user?.infos?.role).pipe(map((role) => role !== 'malou-free'));
    public isLoadingRestaurants = true;
    readonly restaurants$ = this._store.select(selectOwnRestaurants);

    public dataSource: MatTableDataSource<Restaurant> = new MatTableDataSource([]);
    public routerLink = '/dashboard';
    restaurantsList: Restaurant[];
    isCardView = true;

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        public readonly _store: Store<AppState>,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _customDialogService: CustomDialogService,
        private readonly _screenService: ScreenSizeService,
        private readonly _userService: UsersService,
        private readonly _roiContext: RoiContext,
        private readonly _notificationsContext: NotificationsContext,
        private readonly _activatedRoute: ActivatedRoute
    ) {
        this._restaurantsService
            .index('name address cover logo updatedAt createdAt organizationId internalName active type')
            .subscribe((restaurants) => {
                this.isLoadingRestaurants = false;
                this.restaurantsList = restaurants.sort(sortRestaurantsByInternalNameThenName);
                this.dataSource.data = this.restaurantsList;
                this.applyFilter(null);
            });
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sort;
        }
    }

    @ViewChild(PaginatorComponent) set paginator(paginatorComponent: PaginatorComponent) {
        if (paginatorComponent) {
            this.dataSource.paginator = paginatorComponent.matPaginator;
        }
    }

    ngOnInit(): void {
        this.isPaying$.subscribe((isPaying) => {
            if (!isPaying) {
                this.routerLink = '/';
            }
        });
        this.dataSource.filterPredicate = (data: Restaurant, filterText: string): boolean =>
            data.name.toLowerCase().includes(filterText) || data.internalName.toLowerCase().includes(filterText);

        this._showRestaurantSetupLegacyModalIfMissingInformation();
        this._showRoiSettingsModalIfMissingInformation();
    }

    ngAfterViewInit(): void {
        this._route.queryParams.subscribe({
            next: (params) => {
                if (params.auth_id) {
                    this._checkIfShouldOpenConnectRestaurantModal(params.auth_id);
                }
            },
            error: (err) => console.warn('err :>>', err),
        });
    }

    currentPageData(): Restaurant[] {
        return this.dataSource._pageData(this.dataSource.filteredData);
    }

    applyFilter(textFilter: string | null): void {
        this.dataSource.filter = textFilter?.trim()?.toLowerCase() || '';
    }

    openConnectRestaurantModal(data = {}): void {
        this._customDialogService
            .open(ConnectRestaurantModalComponent, {
                panelClass: 'malou-dialog-panel',
                height: '85vh',
                width: '800px',
                data,
            })
            .afterClosed()
            .subscribe({
                next: (res) => {
                    if (res.lastStep) {
                        this._openAfterCreateRestaurantModal(res.lastStep);
                        this._notificationsContext.emitReload();
                    }
                },
            });
    }

    openEditInternalNameModal(restaurant: Restaurant): void {
        this._customDialogService
            .open(EditInternalNameModalComponent, {
                maxHeight: '750px',
                height: 'fit-content',
                data: {
                    restaurant,
                },
            })
            .afterClosed()
            .subscribe();
    }

    private _openAfterCreateRestaurantModal(props: Partial<LastStep>): void {
        this._customDialogService
            .open(AfterCreateRestaurantModalComponent, {
                panelClass: 'malou-dialog-panel',
                height: 'fit-content',
                width: '600px',
                data: {
                    picture: props.picture,
                    primaryButtonLabel: props.primaryButtonLabel,
                    secondaryButtonLabel: props.secondaryButtonLabel,
                    title: props.title,
                    details: props.details,
                    onClickSecondaryButton: props.onClickSecondaryButton,
                    onClickPrimaryButton: props.onClickPrimaryButton,
                },
            })
            .afterClosed();
    }

    private _showRestaurantSetupLegacyModalIfMissingInformation(): void {
        this._store
            .select(selectUserInfos)
            .pipe(filter(isNotNil), take(1))
            .subscribe({
                next: (user) => {
                    if (!user?.shouldShowLegacyRestaurantSettingsModal) {
                        return;
                    }
                    this._customDialogService
                        .open(RestaurantSetupLegacyModalComponent, {
                            panelClass: this._screenService.isPhoneScreen ? 'malou-dialog-panel--full' : 'malou-dialog-panel--fit-content',
                        })
                        .afterClosed()
                        .pipe(switchMap(() => this._userService.update(user._id, { shouldShowLegacyRestaurantSettingsModal: false })))
                        .subscribe();
                },
            });
    }

    private _showRoiSettingsModalIfMissingInformation(): void {
        this._notificationsContext.restaurantsWithFetchedRoiSettings$.subscribe(({ restaurants }) => {
            this._openRoiSettingsUpdateModal(restaurants);
        });
    }

    private _openRoiSettingsUpdateModal(restaurants: Restaurant[]): void {
        const restaurantWithoutRoiSettings = restaurants.filter((restaurant) =>
            this._roiContext.restaurantsIdsWithoutRoiSettings().includes(restaurant._id)
        );

        const shouldForceShowModal = this._activatedRoute.snapshot.queryParams.showRoiPopup === 'true';
        if (shouldForceShowModal) {
            this._router.navigate(['./']);
        }

        const shouldShowModal = getShouldShowRoiSettingsModal(this._roiContext.getLatestCreatedRestaurantsWithoutRoiSettings(restaurants));

        if (shouldShowModal || shouldForceShowModal) {
            localStorage.setItem(LocalStorageKey.IS_ROI_SETTINGS_POPUP_SHOWN, 'true');
            if (restaurantWithoutRoiSettings.length > 1) {
                this._openUpdateAggregatedRoiSettingsModal();
            } else {
                this._openRestaurantSetupModal();
            }
        }
    }

    private _openUpdateAggregatedRoiSettingsModal(): void {
        this._customDialogService
            .open(UpdateAggregatedRoiSettingsModalComponent, {
                panelClass: this._screenService.isPhoneScreen ? 'malou-dialog-panel--full' : 'malou-dialog-panel--fit-content',
                maxWidth: '600px',
                closeOnNavigation: false,
                autoFocus: false,
                data: {
                    isFromNotification: true,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.needsToBeUpdated) {
                    this._notificationsContext.emitReload();
                }
            });
    }

    private _openRestaurantSetupModal(): void {
        this._customDialogService
            .open(RestaurantSetupModalComponent, {
                panelClass: this._screenService.isPhoneScreen ? 'malou-dialog-panel--full' : 'malou-dialog-panel--fit-content',
                maxWidth: '600px',
                closeOnNavigation: false,
                autoFocus: false,
                data: {
                    restaurantId: this._roiContext.restaurantsIdsWithoutRoiSettings()[0],
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.needsToBeUpdated) {
                    this._notificationsContext.emitReload();
                }
            });
    }

    private _checkIfShouldOpenConnectRestaurantModal(authId: string): void {
        const restaurantCreationFormInProgressAsString = localStorage.getItem(LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS);
        localStorage.removeItem(LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS);
        if (!restaurantCreationFormInProgressAsString) {
            console.error('Key RESTAURANT_CREATION_FORM_IN_PROGRESS not found in localstorage');
            return;
        }
        const { organizationId, restaurantType } = JSON.parse(restaurantCreationFormInProgressAsString);
        if (!restaurantType) {
            console.error('No restaurantType in LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS');
            return;
        }

        this._router.navigate(['.'], { relativeTo: this._route });

        setTimeout(() => {
            this.openConnectRestaurantModal({
                authId,
                hasBeenRedirected: true,
                organizationId,
                restaurantType,
            });
        });
    }
}
