import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort, MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { isNil } from 'lodash';

import { ReviewsRatingsAverageData } from ':modules/aggregated-statistics/e-reputation/reviews-ratings-average/reviews-ratings-average-chart/reviews-ratings-average-chart.component';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

enum TableField {
    RESTAURANT_NAME = 'restaurantName',
    AVERAGE_RATING = 'averageRating',
}

const DEFAULT_SORT: { active: TableField; direction: SortDirection } = {
    active: TableField.AVERAGE_RATING,
    direction: 'asc',
};

interface DataSource {
    [TableField.RESTAURANT_NAME]: string;
    [TableField.AVERAGE_RATING]: number | null;
    evolution: number | null;
}

@Component({
    selector: 'app-reviews-ratings-average-table',
    templateUrl: './reviews-ratings-average-table.component.html',
    styleUrls: ['./reviews-ratings-average-table.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatTableModule, MatSortModule, TranslateModule, NumberEvolutionComponent, ShortNumberPipe],
})
export class ReviewsRatingsAverageTableComponent implements OnInit, OnChanges {
    @Input() data: ReviewsRatingsAverageData[];
    @Input() previousData: ReviewsRatingsAverageData[];
    @Input() sortBy: Sort | undefined;
    @Output() sortChange: EventEmitter<Sort> = new EventEmitter();

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sort;
            if (this.sortBy) {
                this.dataSource.sort.sort({ id: this.sortBy.active, start: this.sortBy.direction, disableClear: true });
            }
        }
    }

    readonly TableField = TableField;
    readonly DEFAULT_SORT = DEFAULT_SORT;
    readonly displayedColumns = Object.values(TableField);

    readonly dataSource: MatTableDataSource<DataSource> = new MatTableDataSource();

    ngOnInit(): void {
        this.updateMatDataSource(this.data, this.previousData);
    }

    updateMatDataSource(data: ReviewsRatingsAverageData[], previousData: ReviewsRatingsAverageData[]): void {
        this.dataSource.data = this.computeDataSource(data, previousData);
    }
    computeDataSource(data: ReviewsRatingsAverageData[], previousData: ReviewsRatingsAverageData[]): DataSource[] {
        return data.map((d) => {
            const currentAverage = d.averageRating;
            const previousAverage = previousData.find((previous) => previous.restaurant._id === d.restaurant._id)?.averageRating;
            const evolution = isNil(currentAverage) || isNil(previousAverage) ? null : currentAverage - previousAverage;
            return {
                [TableField.RESTAURANT_NAME]: d.restaurant.name,
                [TableField.AVERAGE_RATING]: currentAverage,
                evolution: evolution,
            };
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'data', 'previousData')) {
            this.updateMatDataSource(this.data, this.previousData);
        }
    }
}
