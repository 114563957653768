import { CdkDrag, CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { LowerCasePipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { AfterViewChecked, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, catchError, debounceTime, from, map, Observable, of, skip, Subject } from 'rxjs';

import { DEFAULT_MAX_IMAGE_SIZE, TimeInMilliseconds } from '@malou-io/package-utils';

import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import { MediaPickerFilter } from ':modules/media/media-picker-modal/media-picker-modal.interface';
import { MediaService } from ':modules/media/media.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

import { Media } from '../../models';
import { VideoMediaAnalyzer } from '../../models/media-analyzer';
import { ThumbnailChange } from '../../models/thumbnail-change';
import { CustomDialogService } from '../../services/custom-dialog.service';
import { MediaFileUploaderComponent } from '../media-file-uploader/media-file-uploader.component';

const SLIDER_WIDTH_IN_PX = 36;
const DEFAULT_TIME_IN_MS = 0;

enum ThumbnailType {
    SLIDER = 'slider',
    ALL = 'all',
}

@Component({
    selector: 'app-media-thumbnail-picker',
    templateUrl: './media-thumbnail-picker.component.html',
    styleUrls: ['./media-thumbnail-picker.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        NgStyle,
        CdkDrag,
        MatIconModule,
        TranslateModule,
        MediaFileUploaderComponent,
        LowerCasePipe,
        ApplySelfPurePipe,
    ],
})
export class MediaThumbnailPickerComponent implements OnInit, AfterViewChecked {
    @ViewChild('dragElement') dragElement: CdkDrag | undefined;

    @Input() public media: Media;
    @Input() public thumbnail: Media | undefined;
    @Input() public thumbnailOffsetTimeInMs: number | undefined = 0;
    @Input() public shouldShowSlider = true;
    @Output() public thumbnailChange: EventEmitter<ThumbnailChange> = new EventEmitter<ThumbnailChange>();
    @Output() public sliderImagesLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly ACCEPTED_IMAGE_TYPES = 'image/png, image/jpeg';
    readonly DEFAULT_MAX_IMAGE_SIZE = DEFAULT_MAX_IMAGE_SIZE;

    thumbnailUrl: string | null = null;
    sliderThumbnailUrl: string | null = null;
    sliderImageUrls: string[] = [];
    slideBarSizeInPx = 0;
    sliderPosition$: Subject<number> = new Subject<number>();
    needToSetDragElementPosition = true;
    dragElementTimeInMs?: number;
    thumbnailOffsetTimeInSeconds$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    firstVideoFrameUrl: string | null = null;
    mediaDurationInSeconds: number | null = null;
    isLoadingSliderImages = false;
    shouldSeekThumbnailInVideo = true;

    readonly SvgIcon = SvgIcon;

    private _attachmentsName?: string;
    private _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _spinnerService: SpinnerService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translate: TranslateService,
        private readonly _mediaService: MediaService
    ) {}

    get attachmentsName(): string | undefined {
        return this._attachmentsName;
    }

    @Input() set attachmentsName(value: string | null) {
        if (!value) {
            return;
        }
        const splittedValue = value.split('.');
        const extension = splittedValue.pop();
        this._attachmentsName = `${splittedValue.join('.')}_thumbnail.${extension}`;
    }

    ngOnInit(): void {
        this.shouldSeekThumbnailInVideo = this.shouldShowSlider;
        this.thumbnailOffsetTimeInSeconds$.pipe(skip(1), takeUntilDestroyed(this._destroyRef)).subscribe((time) => {
            this.updateThumbnail(this.media, time);
        });
        this.sliderPosition$.pipe(debounceTime(50), takeUntilDestroyed(this._destroyRef)).subscribe((xPositionInSlideBar) => {
            const timeInSeconds = this._toTimeInSeconds(xPositionInSlideBar);
            this.updateThumbnail(this.media, timeInSeconds, ThumbnailType.SLIDER);
        });
        if (this.thumbnail) {
            this.thumbnailUrl = this.thumbnail?.getMediaUrl('igFit');
        } else if (this.thumbnailOffsetTimeInMs) {
            this.thumbnailOffsetTimeInSeconds$.next(this.thumbnailOffsetTimeInMs / TimeInMilliseconds.SECOND);
        } else if (this.shouldSeekThumbnailInVideo) {
            this.thumbnailOffsetTimeInMs ??= DEFAULT_TIME_IN_MS; // If we have no thumbnail here, we have to set the default value to have an image to display
            this.thumbnailOffsetTimeInSeconds$.next(this.thumbnailOffsetTimeInMs / TimeInMilliseconds.SECOND);
            this._setDragElementToTimeInMs(DEFAULT_TIME_IN_MS);
        }
        this.firstVideoFrameUrl = this.media.thumbnail ?? null;
        this.sliderThumbnailUrl = this.thumbnailUrl;
        this._getMediaDurationInSeconds$()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((duration) => {
                this.mediaDurationInSeconds = duration;
            });
        if (this.shouldShowSlider) {
            this._initSliderImages(this.media);
        }
    }

    ngAfterViewChecked(): void {
        const slideBar = document.getElementById('slide-bar');
        this.slideBarSizeInPx = slideBar?.offsetWidth ?? 0;
        if (this.dragElementTimeInMs && this.slideBarSizeInPx && this.mediaDurationInSeconds) {
            this._setDragElementToTimeInMs(this.dragElementTimeInMs);
            this.dragElementTimeInMs = undefined;
        }
    }

    updateThumbnail(media: Media, timeInSeconds: number, thumbnailType = ThumbnailType.ALL): void {
        media
            .getVideoCoverUrl$(timeInSeconds)
            .pipe(
                catchError((error) => {
                    console.warn('Error when loading video file', error);
                    return [];
                })
            )
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((thumbnailUrl) => {
                this.sliderThumbnailUrl = thumbnailUrl;
                if (thumbnailType === ThumbnailType.SLIDER) {
                    return;
                }
                this.thumbnailUrl = thumbnailUrl;
                const thumbnailOffsetTimeInMs = Math.round(timeInSeconds * TimeInMilliseconds.SECOND);
                this.thumbnailChange.emit({
                    thumbnailOffsetTimeAndUrl: {
                        thumbnailOffsetTimeInMs,
                        thumbnailUrl: this.thumbnailUrl,
                    },
                });
                if (this.needToSetDragElementPosition) {
                    this.needToSetDragElementPosition = false;
                    this._setDragElementToTimeInMs(thumbnailOffsetTimeInMs);
                }
            });
    }

    onDragEnded(event: CdkDragEnd): void {
        const xPositionInSlideBar = this._getDragElementXPosition(event);
        const timeInSeconds = this._toTimeInSeconds(xPositionInSlideBar);
        this.thumbnailOffsetTimeInSeconds$.next(timeInSeconds);
    }

    onDragMoved(event: CdkDragMove): void {
        const xPositionInSlideBar = this._getDragElementXPosition(event);
        this.sliderPosition$.next(xPositionInSlideBar);
    }

    removeThumbnailMedia(): void {
        this.thumbnail = undefined;
        this.needToSetDragElementPosition = true;
        if (this.shouldSeekThumbnailInVideo) {
            this.updateThumbnail(this.media, this.thumbnailOffsetTimeInSeconds$.value);
        } else {
            if (this.media.thumbnail) {
                this.thumbnailUrl = this.media.thumbnail;
                this.thumbnailChange.emit({});
            } else {
                this.thumbnailUrl = null;
                this.thumbnailChange.emit({ thumbnail: undefined });
            }
        }
    }

    openMediaPickerModal(): void {
        this._customDialogService
            .open(MediaPickerModalComponent, {
                width: '600px',
                data: {
                    restaurant: this._restaurantsService.currentRestaurant,
                    multi: false,
                    filter: MediaPickerFilter.ONLY_IMAGE,
                },
            })
            .afterClosed()
            .pipe(
                map((medias: Media[]) => {
                    if (medias?.length > 0) {
                        return medias[0];
                    }
                    return null;
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (imageMedia: Media | null) => {
                    if (imageMedia) {
                        this.thumbnail = imageMedia;
                        this.thumbnailUrl = imageMedia.getMediaUrl('igFit');
                        this.thumbnailChange.emit({ thumbnail: imageMedia });
                    }
                },
                error: (err) => {
                    console.warn('Error when getting thumbnail from video', err);
                },
            });
    }

    onFinishUpload(createdMedias: Media[]): void {
        this._spinnerService.hide();
        if (createdMedias.length > 0) {
            this.thumbnail = createdMedias[0];
            this.thumbnailUrl = this.thumbnail.getMediaUrl('igFit');
            this.thumbnailChange.emit({ thumbnail: this.thumbnail });
        }
    }

    startReadingFile(): void {
        this._spinnerService.show();
    }

    processError(error: Error | string): void {
        console.error('Error during uploading image', error);
        this._spinnerService.hide();
        this._toastService.openErrorToast(this._translate.instant('media_thumbnail_slider.error_uploading_image'));
    }

    private _getDragElementXPosition(event: CdkDragEnd | CdkDragMove): number {
        const freeDragPosition = event.source?.getFreeDragPosition();
        if (isNaN(freeDragPosition?.x)) {
            if (this._isCdkDragEnd(event)) {
                return event.distance.x;
            }
            return event.pointerPosition.x;
        }
        return freeDragPosition.x;
    }

    private _setDragElementToTimeInMs(timeInMs: number): void {
        if (this.dragElement && this.mediaDurationInSeconds) {
            const position = { x: this._toXPositionInSlideBar(timeInMs, this.mediaDurationInSeconds * TimeInMilliseconds.SECOND), y: 0 };
            this.dragElement.setFreeDragPosition(position);
            return;
        }
        this.dragElementTimeInMs = timeInMs;
    }

    private _xPositionInSlideBarToPercentage(xPosition: number): number {
        return (xPosition / (this.slideBarSizeInPx - SLIDER_WIDTH_IN_PX)) * 100;
    }

    private _toTimeInSeconds(xPositionInSlideBar: number): number {
        const mediaDurationInSeconds = this.mediaDurationInSeconds ?? 1;
        const percentage = this._xPositionInSlideBarToPercentage(xPositionInSlideBar);
        return (percentage / 100) * mediaDurationInSeconds;
    }

    private _toXPositionInSlideBar(time: number, mediaDuration: number): number {
        const percentage = (time / mediaDuration) * 100;
        return (percentage / 100) * (this.slideBarSizeInPx - SLIDER_WIDTH_IN_PX);
    }

    private _getMediaDurationInSeconds$(): Observable<number> {
        if (!this.mediaDurationInSeconds) {
            const mediaAnalyzer = new VideoMediaAnalyzer(this._mediaService);
            return from(mediaAnalyzer.getMetadata(this.media.getMediaUrl('igFit'))).pipe(
                map((metadata) => metadata.duration as number),
                catchError((error) => {
                    console.warn('Error when loading video file', error);
                    return [];
                })
            );
        }
        return of(this.mediaDurationInSeconds);
    }

    private _isCdkDragEnd(event: CdkDragEnd | CdkDragMove): event is CdkDragEnd {
        return (event as CdkDragEnd).distance !== undefined;
    }

    private _initSliderImages(media: Media): void {
        this.isLoadingSliderImages = true;
        media
            .pickImagesFromVideo$(5)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((imageUrls) => {
                this.sliderImageUrls = imageUrls;
                this.sliderImagesLoaded.emit(true);
                this.isLoadingSliderImages = false;
            });
    }
}
