import { ChangeDetectionStrategy, Component, effect, inject, input, model, OnInit, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { omit } from 'lodash';
import { forkJoin } from 'rxjs';

import { OrganizationsService } from ':core/services/organizations.service';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Organization } from ':shared/models/organization';

interface OrganizationWithRestaurantsCount extends Organization {
    restaurantsCount: number;
}

@Component({
    selector: 'app-choose-organization-modal',
    templateUrl: './choose-organization-modal.component.html',
    styleUrls: ['./choose-organization-modal.component.scss'],
    standalone: true,
    imports: [MatLegacyRadioModule, FormsModule, TranslateModule, MatTooltipModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseOrganizationModalComponent implements OnInit {
    readonly organizations = input.required<Organization[]>();
    readonly defaultOrganization = input<Organization>();
    readonly setOrganization = output<Organization>();
    readonly selectedOrganization = model<OrganizationWithRestaurantsCount | null>(null);
    readonly organizationsWithRestaurantsCount = signal<OrganizationWithRestaurantsCount[]>([]);

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    private readonly _organizationsService = inject(OrganizationsService);

    constructor() {
        effect(
            () => {
                const defaultOrganization = this.defaultOrganization();
                const organizationsWithRestaurantsCount = this.organizationsWithRestaurantsCount();
                const selectedOrganization = this.selectedOrganization();

                if (defaultOrganization && defaultOrganization._id !== selectedOrganization?._id) {
                    const organizationWithRestaurantCount = organizationsWithRestaurantsCount.find(
                        (orga) => orga._id === defaultOrganization._id
                    );
                    if (organizationWithRestaurantCount) {
                        this.selectedOrganization.set(organizationWithRestaurantCount);
                    }
                }
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        const organizations = this.organizations();
        if (organizations.length) {
            forkJoin(organizations.map((orga) => this._organizationsService.getOrganizationRestaurantsCount(orga._id))).subscribe(
                (organizationsWithRestaurantsCount) => {
                    this.organizationsWithRestaurantsCount.set(
                        organizations.map((orga, index) => ({
                            ...orga,
                            restaurantsCount: organizationsWithRestaurantsCount[index],
                        }))
                    );
                }
            );
        }
    }

    selectOrganization({ value: organization }: { value: OrganizationWithRestaurantsCount }): void {
        this.setOrganization.emit(omit(organization, ['restaurantsCount']));
    }
}
