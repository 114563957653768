<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : page"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="close()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #page>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <div class="flex flex-col">
                <div>{{ title }}</div>
                @if (displayMode === NfcDisplayMode.BASIC && nfc() | applySelfPure: 'isTotem') {
                    <div class="malou-text-10--regular malou-color-text-2 italic">
                        {{ 'admin.nfcs.chip_number' | translate: { chipName: nfc()?.chipName } }}
                    </div>
                }
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="close({ shouldCheckChangesBeforeClose: true })">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>
        <div class="malou-dialog__body">
            <form [formGroup]="nfcForm">
                <div class="flex flex-col gap-y-2">
                    @if (upsertableFieldNames | includes: NfcUpsertFieldName.CHIP_NAME) {
                        <app-input-text
                            formControlName="chipName"
                            [required]="isTotem()"
                            [title]="'admin.nfcs.chip_name' | translate"></app-input-text>
                    }

                    <div>
                        @if (restaurants() && upsertableFieldNames | includes: NfcUpsertFieldName.RESTAURANT) {
                            <app-select-restaurants
                                title="{{ 'common.business' | translate }}"
                                formControlName="restaurant"
                                [required]="true"
                                [values]="restaurants()"
                                [multiSelection]="false"
                                [computeObjectHash]="compareByRestaurantId"
                                [showValuesSelectedCount]="false">
                            </app-select-restaurants>
                        }
                        @if (allRestaurantsAlreadyHaveSticker()) {
                            <mat-error class="malou-color-state-error malou-text-10 mt-2 italic">
                                {{ 'admin.nfcs.all_restaurants_have_sticker' | translate }}
                            </mat-error>
                        }
                    </div>

                    @if (upsertableFieldNames | includes: NfcUpsertFieldName.ACTIVE) {
                        <span class="malou-text-10--regular malou-color-text-2">{{ 'admin.nfcs.active' | translate }}</span>
                    }
                    @if (upsertableFieldNames | includes: NfcUpsertFieldName.ACTIVE) {
                        <app-slide-toggle
                            [switchWidth]="70"
                            [checked]="active"
                            [activeLabel]="'common.yes' | translate"
                            [inactiveLabel]="'common.no' | translate"
                            (onToggle)="toggleActive(!$event)">
                        </app-slide-toggle>
                    }

                    @if (upsertableFieldNames | includes: NfcUpsertFieldName.NAME) {
                        <app-input-text formControlName="name" [title]="'admin.nfcs.totem_name' | translate"></app-input-text>
                    }

                    @if (upsertableFieldNames | includes: NfcUpsertFieldName.PLATFORM_KEY) {
                        <app-select formControlName="platformKey" [title]="'admin.nfcs.redirection' | translate" [values]="platformKeys">
                            <ng-template let-value="value" #simpleSelectedValueTemplate>
                                <div class="malou-text-12--medium malou-color-text-1 flex h-full items-center gap-x-2 pl-1">
                                    <img class="h-8 w-8" [src]="value | platformLogoPathResolver" [alt]="value" />
                                    <div>
                                        <span>{{ value | enumTranslate: 'platform_key' }}</span>
                                        @if (value === WHEEL_OF_FORTUNE && activeWheelOfFortune()) {
                                            <span class="ml-1 italic"
                                                >({{
                                                    activeWheelOfFortune()?.parameters?.redirectionPlatform?.toString() ?? ''
                                                        | enumTranslate: 'platform_key'
                                                }})</span
                                            >
                                        }
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template let-value="value" #optionTemplate>
                                <div class="malou-text-12--regular flex items-center gap-x-2">
                                    <img class="h-8 w-8" [src]="value | platformLogoPathResolver" [alt]="value" />
                                    <div>
                                        <span>{{ value | enumTranslate: 'platform_key' }}</span>
                                        @if (value === WHEEL_OF_FORTUNE && activeWheelOfFortune()) {
                                            <span class="ml-1 italic"
                                                >({{
                                                    activeWheelOfFortune()?.parameters?.redirectionPlatform?.toString() ?? ''
                                                        | enumTranslate: 'platform_key'
                                                }})</span
                                            >
                                        }
                                    </div>
                                </div>
                            </ng-template>
                        </app-select>
                    }

                    @if (upsertableFieldNames | includes: NfcUpsertFieldName.STARS_REDIRECTED) {
                        <app-select-star-with-number
                            formControlName="starsRedirected"
                            [title]="'admin.nfcs.stars_redirected_long_text' | translate"
                            [values]="STARS_REDIRECTED_VALUES"></app-select-star-with-number>
                    }

                    @if (upsertableFieldNames | includes: NfcUpsertFieldName.NOTES) {
                        <app-text-area formControlName="notes" [title]="'admin.nfcs.notes' | translate"></app-text-area>
                    }
                </div>
            </form>
        </div>
        <div class="malou-dialog__footer md:justify-between">
            <button class="malou-btn-raised--secondary !h-11" mat-raised-button (click)="close({ shouldCheckChangesBeforeClose: true })">
                {{ 'common.cancel' | translate }}
            </button>
            <button class="malou-btn-raised--primary !h-11" mat-raised-button [disabled]="!nfcForm.valid" (click)="submit()">
                {{ nfc() ? ('common.save' | translate) : ('common.add' | translate) }}
            </button>
        </div>
    </div>
</ng-template>
