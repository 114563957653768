<ng-container *ngTemplateOutlet="isPhoneScreen() ? mobileList : desktopTable"></ng-container>

<ng-template #desktopTable>
    <mat-table class="malou-mat-table my-5" matSort [dataSource]="dataSource()" #table="matTable">
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    color="primary"
                    [checked]="isAllSelected()"
                    [indeterminate]="!isAllSelected()"
                    (change)="$event ? toggleAllRows() : null">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <mat-checkbox
                    color="primary"
                    [checked]="selection().isSelected(row)"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection().toggle(row) : null">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="keyword">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'keywords.keywords' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table" class="malou-text-13--semibold truncate !text-malou-color-text-1">
                <div class="truncate" [matTooltip]="row.keyword">
                    {{ row.keyword }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'keywords.validation.lang' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table; let i = index" matTooltip="{{ getPrettyLang | applyPure: row.language }}">
                <img
                    class="ml-4 !h-4 !w-4 rounded-full"
                    [lazyLoad]="row.language | flagPathResolver"
                    [alt]="getPrettyLang | applyPure: row.language" />
                <ng-container [ngTemplateOutlet]="updateKeywordLang" [ngTemplateOutletContext]="{ keyword: row }"> </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="volume">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                <span class="flex items-center gap-x-1">
                    <span>
                        {{ 'keywords.popularity' | translate }}
                    </span>
                </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <div class="flex cursor-pointer items-center">
                    <app-keywords-popularity [popularity]="row.popularity"></app-keywords-popularity>
                    @if (isAdmin()) {
                        <mat-icon
                            class="visible ml-2 !h-4 !w-4"
                            color="primary"
                            [svgIcon]="SvgIcon.EDIT"
                            [ngClass]="{ invisible: !!row.volumeFromAPI }"
                            (click)="openCustomVolumeModal(row)">
                        </mat-icon>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="competitors">
            <mat-header-cell *matHeaderCellDef>
                <span class="flex w-full items-center justify-center gap-x-1">
                    <span>{{ 'keywords.competitors_col' | translate }}</span>
                </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table" class="justify-center">
                <button
                    class="malou-btn-flat !p-0"
                    [disabled]="!row.currentPosition?.rank"
                    (click)="openCompetitorsList(row.keyword, row.ranking, row.currentPosition?.rank)">
                    {{ 'common.see' | translate }} ( {{ row.ranking.length }} )
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
        <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS; table: table"></mat-row>
    </mat-table>
</ng-template>

<ng-template #mobileList>
    <div class="malou-card !m-0 !my-5 !flex-row gap-x-3 !py-4">
        <div class="flex w-full items-center gap-x-2" [matMenuTriggerFor]="sortMenu">
            <mat-icon
                class="!h-4 !w-4"
                color="primary"
                [svgIcon]="sortDirection() === 1 ? 'arrow-up' : 'arrow-down'"
                (click)="$event.stopPropagation(); changeSortOrder()"></mat-icon>
            <span class="malou-text-13--medium">{{ 'common.sort' | translate }} : </span>
            <span class="malou-text-13--bold max-w-[50%] truncate">{{ selectedSortOption().text }}</span>
            <mat-icon class="ml-auto !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
        </div>

        <mat-menu class="malou-mat-menu malou-box-shadow !mt-3.5 !w-[100vw] !rounded-xl" #sortMenu="matMenu">
            @for (sort of sortOptions(); track sort) {
                <button class="custom-menu-item" mat-menu-item (click)="sortKeywords(sort)">
                    <span class="malou-text-13--regular !text-malou-color-text-1">{{ sort.text }}</span>
                </button>
            }
        </mat-menu>
    </div>

    <div class="mb-12 flex w-full flex-col gap-y-2">
        @for (row of dataSource().data; track trackByIdFn($index, row)) {
            <div class="malou-card !m-0 !flex-none gap-y-5 !p-5">
                <span class="malou-text-13--semibold truncate">{{ row.keyword }}</span>
                <div class="flex items-center gap-x-3">
                    <span class="malou-text-13--regular">
                        {{ 'keywords.popularity' | translate }}
                    </span>
                    <app-keywords-popularity [popularity]="row.popularity"></app-keywords-popularity>
                    @if (isAdmin()) {
                        <mat-icon
                            class="!h-4 !w-4"
                            color="primary"
                            [svgIcon]="SvgIcon.EDIT"
                            [ngClass]="{ 'existing-volume': !!row.volumeFromAPI }"
                            (click)="openCustomVolumeModal(row)">
                        </mat-icon>
                    }
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template let-keyword="keyword" #updateKeywordLang>
    <mat-icon class="ml-3 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CHEVRON_DOWN" [matMenuTriggerFor]="actionsMenu"></mat-icon>
    <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
        @for (lang of APPLICATION_LANGUAGES; track lang) {
            <div mat-menu-item [disabled]="isKeywordLangUpdating()(keyword)">
                <button class="!flex items-center gap-x-3" (click)="updateKeywordLanguage(keyword, lang)">
                    <img class="h-4 w-4" [lazyLoad]="lang | flagPathResolver" [alt]="getPrettyLang | applyPure: lang" />
                    <span class="malou-text-14--regular">{{ getPrettyLang | applyPure: lang }}</span>
                </button>
            </div>
        }
    </mat-menu>
</ng-template>
