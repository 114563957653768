<div class="my-4 flex flex-col gap-y-3">
    <ng-container
        [ngTemplateOutlet]="shouldShowSlider ? thumbnailPickerWithSliderTemplate : thumbnailPickerWithoutSliderTemplate"></ng-container>
</div>

<ng-template #thumbnailPickerWithSliderTemplate>
    <div class="relative flex w-full max-w-full items-center gap-x-3 rounded-[10px] bg-malou-color-background-light p-3">
        <div class="flex h-[85px] w-full items-center" id="slide-bar-container">
            <div
                class="flex h-[75px] w-full overflow-hidden rounded-[10px] bg-malou-color-background-dark"
                id="slide-bar"
                (click)="removeThumbnailMedia()">
                @for (imageUrl of sliderImageUrls; track imageUrl) {
                    <div class="flex-1">
                        <img class="h-[75px] w-full object-cover opacity-70" [src]="imageUrl" />
                    </div>
                }
            </div>
            @if (!thumbnail) {
                <div
                    class="slider-bar"
                    cdkDragLockAxis="x"
                    cdkDragBoundary="#slide-bar-container"
                    cdkDrag
                    (cdkDragEnded)="onDragEnded($event)"
                    (cdkDragMoved)="onDragMoved($event)"
                    #dragElement="cdkDrag">
                    <ng-container [ngTemplateOutlet]="sliderThumbnailUrl ? sliderWithImageTemplate : defaultSliderTemplate"></ng-container>
                </div>
            }
        </div>
        <div [ngClass]="{ 'small-media': thumbnail }">
            <ng-container [ngTemplateOutlet]="thumbnailUrl ? thumbnailPreviewWithImageTemplate : defaultThumbnailPreviewTemplate">
            </ng-container>
            <div
                class="absolute right-1 top-2 hidden transition-all hover:scale-105"
                [ngClass]="{ 'cursor-pointer': !isLoadingSliderImages }"
                (click)="!isLoadingSliderImages && removeThumbnailMedia()">
                <div class="grid h-5 w-5 place-items-center rounded-full bg-white">
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="malou-color-text-1 malou-text-13--semibold flex justify-center gap-x-1">
        {{ 'common.or' | translate }}
        <app-media-file-uploader
            id="thumbnailUppyId"
            [accept]="ACCEPTED_IMAGE_TYPES"
            [multiple]="false"
            [disabled]="isLoadingSliderImages"
            [maxImageSize]="DEFAULT_MAX_IMAGE_SIZE"
            (finishUpload)="onFinishUpload($event)"
            (startReadingFile)="startReadingFile()"
            (processError)="processError($event)"
            #mediaUploader>
            <button
                class="malou-btn-flat malou-text-13--semibold"
                mat-flat-button
                [ngClass]="{ 'cursor-default': isLoadingSliderImages }"
                (click)="mediaUploader.openFilePicker()">
                {{ 'media_thumbnail_slider.choose_photo' | translate }}
            </button>
        </app-media-file-uploader>
        {{ 'common.or' | translate | lowercase }}
        <button
            class="malou-btn-flat malou-text-13--semibold"
            mat-flat-button
            [ngClass]="{ 'cursor-default': isLoadingSliderImages }"
            (click)="!isLoadingSliderImages && openMediaPickerModal()">
            {{ 'media_thumbnail_slider.see_gallery' | translate }}
        </button>
    </div></ng-template
>

<ng-template #sliderWithImageTemplate>
    <img class="h-full w-full rounded-[4px] object-cover" [src]="sliderThumbnailUrl" />
</ng-template>

<ng-template #defaultSliderTemplate>
    <div class="h-full w-full rounded-[4px] bg-malou-color-background-dark"></div>
</ng-template>

<ng-template #thumbnailPreviewWithImageTemplate>
    <img class="h-[75px] min-w-[75px] rounded-[10px] object-cover" width="75" height="75" [src]="thumbnailUrl" />
</ng-template>

<ng-template #defaultThumbnailPreviewTemplate>
    <div class="h-[75px] min-w-[75px] rounded-[10px] bg-malou-color-background-dark"></div>
</ng-template>

<ng-template #thumbnailPickerWithoutSliderTemplate>
    <div class="relative flex w-full max-w-full items-center justify-between gap-x-3 rounded-[10px] bg-malou-color-background-light p-3">
        <div class="malou-color-text-1 malou-text-13--semibold flex flex-col justify-center gap-x-1">
            <div>{{ 'media_thumbnail_slider.choose_cover' | translate }}</div>
            <div>
                {{ 'media_thumbnail_slider.from' | translate }}
                <button class="malou-btn-flat malou-text-13--semibold" mat-flat-button (click)="openMediaPickerModal()">
                    {{ 'media_thumbnail_slider.see_gallery' | translate }}
                </button>
                {{ 'common.or' | translate | lowercase }}
                <app-media-file-uploader
                    id="thumbnailUppyId"
                    [accept]="ACCEPTED_IMAGE_TYPES"
                    [multiple]="false"
                    [maxImageSize]="DEFAULT_MAX_IMAGE_SIZE"
                    (finishUpload)="onFinishUpload($event)"
                    (startReadingFile)="startReadingFile()"
                    (processError)="processError($event)">
                    <button class="malou-btn-flat malou-text-13--semibold relative" mat-flat-button>
                        {{ 'media_thumbnail_slider.choose_photo' | translate }}
                    </button>
                </app-media-file-uploader>
            </div>
        </div>
        <div [ngClass]="{ 'small-media': thumbnail }">
            @if (!thumbnailUrl) {
                <video class="h-[75px] min-w-[75px] rounded-[10px] object-cover" preload="metadata" width="75" height="75">
                    <source type="video/mp4" [src]="media.urls.original + '#t=0.01'" />
                </video>
            } @else {
                <img class="h-[75px] min-w-[75px] rounded-[10px] object-cover" width="75" height="75" [src]="thumbnailUrl" />
            }
            <div class="absolute right-1 top-2 hidden cursor-pointer transition-all hover:scale-105" (click)="removeThumbnailMedia()">
                <div class="grid h-5 w-5 place-items-center rounded-full bg-white">
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </div>
            </div>
        </div>
    </div>
</ng-template>
