<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [displayWith]="displayWith()"
    [computeObjectHash]="computeObjectHash()"
    [multiSelection]="true"
    [multiSelectionElementWrap]="multiSelectionElementWrap()"
    [maxSelectableValues]="maxSelectableValues()"
    [checkboxOption]="checkboxOption()"
    [showSelectAllCheckbox]="showSelectAllCheckbox()"
    [maxSelectedValuesToDisplay]="maxSelectedValuesToDisplay()"
    [shouldSwitchToWrapModeOnClick]="shouldSwitchToWrapModeOnClick()"
    [buildValueFromText]="buildValueFromText()"
    [selectedValues]="selectedValues()"
    [control]="inputAutoCompleteControl"
    [hideSelectedValues]="hideSelectedValues()"
    [filteredValuesTrackByFn]="filteredValuesTrackByFn()"
    [groupSelectedValuesAtTop]="groupSelectedValuesAtTop()"
    [selectAllCheckboxMessage]="selectAllCheckboxMessage()"
    [displayedOptionCount]="displayedOptionCount()"
    [hideArrow]="hideArrow()"
    [testId]="testId()"
    (selectBaseChange)="selectChipListChange.emit($event)">
    <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
        @if (selectedValueTemplateInput(); as selectedValueTemplateInput) {
            <ng-container
                [ngTemplateOutlet]="selectedValueTemplateInput"
                [ngTemplateOutletContext]="{ value, index, deleteValueAt }"></ng-container>
        } @else {
            <div
                class="malou-chip--rounded-4 malou-chip--primary"
                [class.!whitespace-normal]="multiSelectionElementWrap()"
                [class.!w-auto]="multiSelectionElementWrap()">
                <span>{{ displayWith() | applyPure: value }}</span>
                <mat-icon
                    class="malou-chip-icon--right malou-color-primary shrink-0"
                    [svgIcon]="SvgIcon.REMOVE"
                    (click)="deleteValueAt(index)"></mat-icon>
            </div>
        }
    </ng-template>

    @if (optionTemplateInput(); as optionTemplateInput) {
        <ng-template let-value="value" let-isValueSelected="isValueSelected" #optionTemplate>
            <ng-container [ngTemplateOutlet]="optionTemplateInput" [ngTemplateOutletContext]="{ value, isValueSelected }"></ng-container>
        </ng-template>
    }

    @if (buildValueFromText()) {
        <ng-template #valueBuilderTemplate>
            @if (inputAutoCompleteControl.value?.length > 0 && !(doesItemExist | applyPure: inputAutoCompleteControl.value : values())) {
                <div class="malou-chip--rounded-4 malou-chip--primary flex items-center">
                    <span>
                        {{ inputAutoCompleteControl.value }}
                    </span>
                    <mat-icon class="ml-2 !h-3.5 !w-3.5 text-malou-color-primary" [svgIcon]="SvgIcon.ADD"> </mat-icon>
                </div>
            }
        </ng-template>
    }
</app-select-base>
