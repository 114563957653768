import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

export interface MenuSelectOption {
    value: any;
    viewValue: string;
}

/**
 * (TODO: try to add MDX documentation in storybook)
 *
 * If you want to use this component in a form :
 *
 * langControl: FormControl;
 * form: FormGroup
 * options = [
 *     { value: { id: 1, name: 'Fr' }, viewValue: 'French' },
 *     { value: { id: 2, name: 'En' }, viewValue: 'English' },
 *     { value: { id: 3, name: 'Sp' }, viewValue: 'Spanish' },
 * ]
 * currentOption = { id: 1, name: 'Fr' }
 * constructor() {
 *     this.langControl = new FormControl(this.currentOption);
 *     this.form = new FormGroup({
 *         lang: this.langControl
 *     });
 * }
 */
@Component({
    selector: 'app-menu-select',
    templateUrl: './menu-select.component.html',
    styleUrls: ['./menu-select.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, MatOptionModule, ApplyPurePipe],
})
export class MenuSelectComponent {
    @Output() selectionChange = new EventEmitter();
    @Input() currentOption: string | null = null;
    @Input() options: MenuSelectOption[];
    @Input() compareOptions: (o1: any, o2: any) => boolean = (o1: any, o2: any) => o1 === o2;
    @Input() displaySelectedOption: (currentSelectedOption: any) => any = (option) => option;

    change(event): void {
        this.selectionChange.emit(event);
    }
}
