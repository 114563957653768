import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PlatformKey, PostPublicationStatus, PostType } from '@malou-io/package-utils';

import { ExtendedPostPublicationStatus } from ':core/constants';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { Story } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

import { MediaDurationComponent } from '../media-duration/media-duration.component';

@Component({
    selector: 'app-story',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        AsyncPipe,
        ApplyPurePipe,
        TranslateModule,
        MatIconModule,
        NgClass,
        NgStyle,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        SocialPostMediaComponent,
        EnumTranslatePipe,
        PluralTranslatePipe,
        MediaDurationComponent,
        MatMenuModule,
        PlatformLogoPathResolverPipe,
    ],
    templateUrl: './story.component.html',
    styleUrls: ['./story.component.scss'],
})
export class StoryComponent implements OnInit {
    @Input() story: Story;
    @Output() storyChecked = new EventEmitter<boolean>();
    @Output() deleteStoryEvent = new EventEmitter<string>();
    @Output() editStoryEvent = new EventEmitter<string>();
    @Output() duplicate = new EventEmitter<{ destination: DuplicationDestination; story: Story }>();

    readonly SvgIcon = SvgIcon;
    clearError: string | null | undefined;
    mediaDuration: number;

    isChecked = false;
    PublicationStatus = PostPublicationStatus;
    DuplicationDestination = DuplicationDestination;

    readonly DEFAULT_PHOTO_DURATION = 5;

    constructor(
        public readonly screenSizeService: ScreenSizeService,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        if (this.story.published === PostPublicationStatus.ERROR) {
            this.clearError = this.story.clarifyError?.(this.story.errorData, this._translateService);
        }
        if (this.story?.postType === PostType.IMAGE) {
            this.mediaDuration = this.DEFAULT_PHOTO_DURATION;
        }
    }

    onLoadedVideoMetadata(event: Event): void {
        const duration = Math.floor((event.target as HTMLVideoElement).duration);
        this.mediaDuration = duration;
    }

    onPostCheckedChange(event: MatCheckboxChange): void {
        this.storyChecked.emit(event.checked);
    }

    deleteStory(): void {
        this.deleteStoryEvent.emit(this.story._id);
    }

    editStory(): void {
        this.editStoryEvent.emit(this.story._id);
    }

    isStoryNotPublishedYet(statusType: ExtendedPostPublicationStatus): boolean {
        return [ExtendedPostPublicationStatus.DRAFT, ExtendedPostPublicationStatus.PENDING, ExtendedPostPublicationStatus.ERROR].includes(
            statusType
        );
    }

    duplicateStory(destination: DuplicationDestination): void {
        this.duplicate.emit({ destination, story: this.story });
    }

    platformKeysToShow(): PlatformKey[] {
        if (this.story.key) {
            return [this.story.key];
        }
        return this.story.keys;
    }
}
