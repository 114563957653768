import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { IGAccount } from ':modules/inspirations/inspirations/edit-inspiring-accounts-dialog/edit-inspiring-accounts-dialog.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

@Component({
    selector: 'app-tag-account',
    templateUrl: './tag-account.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatAutocompleteModule,
        TranslateModule,
        ReactiveFormsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        IllustrationPathResolverPipe,
        LazyLoadImageModule,
        MatTooltipModule,
        ShortTextPipe,
    ],
})
export class TagAccountComponent {
    readonly tagControl = input.required<FormControl<string | null>>();
    readonly foundAccount = input.required<IGAccount | null>();
    readonly searching = input.required<boolean>();

    readonly onAccountSelected = output<MatAutocompleteSelectedEvent>();

    readonly SvgIcon = SvgIcon;
}
