import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { NotificationType } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { NotificationItemActionsComponent } from './components/notification-item-actions/notification-item-actions.component';
import { NegativeReviewReminderNotificationItemComponent } from './components/notification-item/negative-review-reminder-notification-item/negative-review-reminder-notification-item.component';
import { ReviewNotificationItemComponent } from './components/notification-item/review-notification-item/review-notification-item.component';
import { SpecialHourNotificationComponent } from './components/notification-item/special-hour-notification-item/special-hour-notification-item.component';
import { NotificationCenterContext } from './context/notification-center.context';
import { NotificationService } from './services/notifications.service';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        NegativeReviewReminderNotificationItemComponent,
        NotificationItemActionsComponent,
        ReviewNotificationItemComponent,
        SpecialHourNotificationComponent,
        MatButtonModule,
        MatIconModule,
    ],
})
export class NotificationCenterComponent implements OnInit {
    readonly notificationCenterContext = inject(NotificationCenterContext);

    readonly NotificationType = NotificationType;
    readonly SvgIcon = SvgIcon;

    private readonly _notificationService = inject(NotificationService);

    ngOnInit(): void {
        this._notificationService.getNotifications().subscribe({
            next: (notifications) => {
                this.notificationCenterContext.notifications.set(notifications);
            },
            error: (error) => {
                console.error(error);
            },
        });
    }
}
