<header class="malou-header sticky top-0 z-10 flex items-center justify-between px-9 md:px-4 gap-x-2 {{ background }}">
    <div>
        @if (showLogo) {
            <img id="brand-logo" routerLink="/" [src]="'malou_logo' | imagePathResolver" />
        }
    </div>
    <div class="flex items-center sm:gap-3">
        @if (showRouting) {
            <p
                class="malou-tab mr-5 sm:hidden"
                data-testid="header-all-venues-btn"
                title="{{ 'header.grouped_view_link_text' | translate }}"
                (click)="navigateTo('groups')">
                {{ 'header.grouped_view_link_text' | translate }}
            </p>
            <p
                class="malou-tab mr-5 sm:hidden"
                data-testid="header-admin-btn"
                title="{{ 'header.admin' | translate }}"
                [hidden]="(userInfos$ | async)?.role !== Role.ADMIN"
                (click)="navigateTo('admin')">
                {{ 'header.admin' | translate }}
            </p>
            <mat-icon
                class="malou-color-primary !hidden !h-5 !w-5 shrink-0 sm:!block"
                [svgIcon]="SvgIcon.AGGREGATED_STATS"
                (click)="navigateTo('groups')"></mat-icon>
            <mat-icon
                class="malou-color-primary !hidden !h-5 !w-5 shrink-0 sm:!block"
                [svgIcon]="SvgIcon.PROFILE"
                (click)="navigateTo('admin')"></mat-icon>
            <a class="malou-tab mr-5 sm:hidden" title="{{ 'common.refer_a_friend' | translate }}" target="_blank" [href]="REFERRAL_LINK">
                {{ 'common.refer_a_friend' | translate }}
            </a>
        }

        @if (showLangSelection) {
            <app-menu-select
                data-testid="header-langs-btn"
                [options]="langs"
                [currentOption]="currentLang"
                [displaySelectedOption]="displaySelectedOption"
                (selectionChange)="changeLang($event)">
            </app-menu-select>
        }
        @if (!experimentationService.isFeatureEnabled('release-notification-center')) {
            <app-notifications></app-notifications>
        } @else {
            <button
                class="malou-btn-icon mb-0 mr-9 flex"
                data-testid="header-notifications-btn"
                mat-icon-button
                (click)="notificationCenterContext.open(); $event.stopPropagation()">
                <mat-icon
                    class="!h-5 !w-5"
                    color="primary"
                    matBadgeSize="small"
                    aria-hidden="false"
                    [svgIcon]="SvgIcon.NOTIFICATIONS"></mat-icon>
                <!-- [matBadgeHidden]="(notificationCount$ | async) === 0"
                    [matBadge]="0" -->
            </button>
        }

        @if (showProfile) {
            @if (userInfos$ | async; as user) {
                <div class="flex shrink-0 items-center">
                    <img
                        class="malou-avatar--small mr-2.5 sm:mr-0"
                        [src]="user.profilePicture ? user.profilePicture.urls.small : ('Karl' | illustrationPathResolver)"
                        [matMenuTriggerFor]="actionsMenu" />
                    <button
                        class="malou-text-12--bold text-malou-color-text-1 md:hidden"
                        data-testid="header-profile-btn"
                        [matMenuTriggerFor]="actionsMenu">
                        {{ 'hello' | translate }} {{ ' ' + user.name }}
                    </button>
                    <mat-menu class="malou-mat-menu malou-box-shadow mt-6 !rounded-xl" #actionsMenu="matMenu">
                        <button class="flex gap-x-3" mat-menu-item [routerLink]="'/users/' + user._id">
                            <mat-icon class="!h-5 !w-5 shrink-0" color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                            <span class="malou-text-14--regular text-malou-color-text-2">{{
                                'header.profile_menu.my_profile' | translate
                            }}</span>
                        </button>
                        <button class="flex gap-x-3" mat-menu-item [routerLink]="'/users/' + user._id + '/notifications-settings'">
                            <mat-icon class="!h-5 !w-5 shrink-0" color="primary" [svgIcon]="SvgIcon.WARNING"></mat-icon>
                            <span class="malou-text-14--regular text-malou-color-text-2">{{
                                'header.profile_menu.my_alerts' | translate
                            }}</span>
                        </button>
                        <button
                            class="flex gap-x-3"
                            data-testid="header-my-reports-btn"
                            mat-menu-item
                            [routerLink]="'/users/' + user._id + '/reports-settings'">
                            <mat-icon class="!h-5 !w-5 shrink-0" color="primary" [svgIcon]="SvgIcon.FOLDER"></mat-icon>
                            <span class="malou-text-14--regular text-malou-color-text-2"
                                >{{ 'header.profile_menu.my_reports' | translate }}
                            </span>
                        </button>
                        <button class="flex gap-x-3" data-testid="header-logout-btn" data-cy="deleteBtn" mat-menu-item (click)="logout()">
                            <mat-icon class="malou-color-state-error !h-5 !w-5 shrink-0" [svgIcon]="SvgIcon.IMPORT"></mat-icon>
                            <span class="malou-text-14--regular text-malou-color-text-2">{{
                                'header.profile_menu.logout' | translate
                            }}</span>
                        </button>
                    </mat-menu>
                </div>
            }
        }
        @if (showSidenavToggle) {
            <app-sidenav-toggle-button></app-sidenav-toggle-button>
        }
    </div>
</header>
