<ng-container *ngTemplateOutlet="isGeneratingKeywords() ? generatingKeywords : mainTemplate"></ng-container>

<ng-template #mainTemplate>
    <ng-container *ngTemplateOutlet="hasFetchedRankings() ? page : loading"></ng-container>
</ng-template>

<ng-template #loading>
    <div class="h-full overflow-y-auto px-7.5">
        <div class="w-full">
            <app-skeleton skeletonClass="secondary-bg h-[90px] w-full"></app-skeleton>
        </div>

        <div class="mt-3 w-full">
            <app-skeleton skeletonClass="secondary-bg h-[50px] w-full"></app-skeleton>
        </div>

        <div class="mt-7 flex w-full justify-between px-7 md:hidden">
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[8vw] mr-3" [count]="3"></app-skeleton>
            <app-skeleton flexDirection="flex-row" skeletonClass="secondary-bg h-[15px] w-[8vw] mr-3" [count]="3"></app-skeleton>
        </div>

        <div class="mt-3 w-full">
            <app-skeleton skeletonClass="secondary-bg h-[50px] md:h-[100px] w-full mb-1" [count]="6"></app-skeleton>
        </div>
    </div>
</ng-template>

<ng-template #page>
    <div class="h-full overflow-y-auto px-7.5 pb-4">
        <ng-container *ngTemplateOutlet="generatorForm"></ng-container>

        <div class="mt-5 flex w-full items-center justify-between">
            @if (hasAnsweredToForm() || hasSelectedKeywords()) {
                <div class="malou-text-18--bold text-malou-color-text-1">
                    {{ 'keywords.keywords' | translate }}
                    <span class="malou-text-18 italic" data-testid="keywords-list-nb-keywords">({{ dataSource().data.length }})</span>
                </div>
            }
            <div class="flex gap-x-2">
                @if (hasSelectedKeywords()) {
                    <button
                        class="malou-btn-icon--secondary btn-xl"
                        mat-icon-button
                        [matTooltip]="'keywords.duplicate_other_restaurants' | translate"
                        [disabled]="selectionIsEmpty()"
                        (click)="openDuplicateKeywordsModal()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                    </button>

                    <button
                        class="malou-btn-raised--primary btn-xl !h-12.5"
                        id="tracking_keywords_modification"
                        mat-raised-button
                        [disabled]="!hasAnsweredToForm()"
                        (click)="openValidationModal()">
                        {{ 'common.edit' | translate }}
                    </button>
                }
            </div>
        </div>
        @if (hasSelectedKeywords()) {
            <app-selected-keywords-list
                [(dataSource)]="dataSource"
                [selection]="selection()"
                [restaurantId]="restaurant._id"
                [keywords]="keywords()"></app-selected-keywords-list>
        } @else {
            <div class="grid h-[65vh] w-full place-items-center">
                <div class="flex w-full flex-col items-center gap-y-6">
                    <img height="100" width="100" [lazyLoad]="'Cles' | illustrationPathResolver" />
                    <div class="malou-text-14--bold max-w-[70%] text-center">
                        {{ 'keywords.no_keywords' | translate }}
                        <div class="malou-text-10--regular mt-3 text-center">
                            {{
                                hasAnsweredToForm()
                                    ? ('keywords.no_keywords_subtitle_with_answers' | translate)
                                    : ('keywords.no_keywords_subtitle' | translate)
                            }}
                        </div>
                    </div>
                    <button
                        class="malou-btn-raised--primary !h-12.5"
                        mat-raised-button
                        (click)="hasAnsweredToForm() ? openValidationModal() : openGeneratorFormModal()">
                        {{ hasAnsweredToForm() ? ('keywords.add_keywords' | translate) : ('keywords.answer_to_form' | translate) }}
                    </button>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #generatorForm>
    <app-keywords-generation-form-answers
        [hasSelectedKeywords]="hasSelectedKeywords()"
        [hasAnsweredToForm]="hasAnsweredToForm()"
        [generatorFormAnswers]="generatorFormAnswers()"
        (openGeneratorFormModal)="openGeneratorFormModal()"></app-keywords-generation-form-answers>
</ng-template>

<ng-template #generatingKeywords>
    <app-loader-page
        data-testid="keywords-generator-loader"
        [generationStartDate]="generationStartDate()"
        [generationEstimatedTime]="generationEstimatedTime()"></app-loader-page>
</ng-template>
