import { RestaurantAiSettingsDto } from '@malou-io/package-dto';
import { ApplicationLanguage, CustomerNaming, RemoveMethodsFromClass, ReplyTone } from '@malou-io/package-utils';

export class RestaurantAiSettings {
    restaurantId: string;
    restaurantName: string;
    replyTone: ReplyTone;
    defaultLanguageResponse: ApplicationLanguage;
    customerNaming: CustomerNaming;
    restaurantKeywordIds: string[] = [];
    forbiddenWords: string[] = [];
    catchphrase?: string = '';
    shouldTranslateCatchphrase: boolean;
    catchphraseTranslationId?: string;
    signatures?: string[];
    shouldTranslateSignature: boolean;
    signatureTranslationIds?: string[];

    constructor(init: RemoveMethodsFromClass<RestaurantAiSettings>) {
        this.restaurantId = init.restaurantId;
        this.restaurantName = init.restaurantName;
        this.replyTone = init.replyTone;
        this.defaultLanguageResponse = init.defaultLanguageResponse;
        this.customerNaming = init.customerNaming;
        this.restaurantKeywordIds = init.restaurantKeywordIds;
        this.forbiddenWords = init.forbiddenWords;
        this.catchphrase = init.catchphrase;
        this.shouldTranslateCatchphrase = init.shouldTranslateCatchphrase;
        this.catchphraseTranslationId = init.catchphraseTranslationId;
        this.signatures = init.signatures;
        this.shouldTranslateSignature = init.shouldTranslateSignature;
        this.signatureTranslationIds = init.signatureTranslationIds;
    }

    static fromRestaurantAiSettingsDto(dto: RestaurantAiSettingsDto): RestaurantAiSettings {
        return new RestaurantAiSettings({
            restaurantId: dto.restaurantId,
            restaurantName: dto.restaurantName,
            replyTone: dto.replyTone,
            defaultLanguageResponse: dto.defaultLanguageResponse,
            customerNaming: dto.customerNaming,
            restaurantKeywordIds: dto.restaurantKeywordIds,
            forbiddenWords: dto.forbiddenWords,
            catchphrase: dto.catchphrase,
            shouldTranslateCatchphrase: dto.shouldTranslateCatchphrase,
            catchphraseTranslationId: dto.catchphraseTranslationId,
            signatures: dto.signatures,
            shouldTranslateSignature: dto.shouldTranslateSignature,
            signatureTranslationIds: dto.signatureTranslationIds,
        });
    }
}
