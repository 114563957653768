import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { DescriptionsSizes } from ':core/constants';
import { ExperimentationService } from ':core/services/experimentation.service';
import { InformationsContext } from ':modules/informations/informations.context';
import { RestaurantDescriptionComponent } from ':shared/components/restaurant-informations/restaurant-description/restaurant-description.component';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { DescriptionModalComponent, DescriptionModalTabs, DescriptionUpdateData } from '../description-modal/description-modal.component';

@Component({
    selector: 'app-description',
    templateUrl: './description.component.html',
    styleUrls: ['./description.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatTabsModule, RestaurantDescriptionComponent, TranslateModule, AsyncPipe],
})
export class DescriptionComponent implements OnInit {
    @Input() restaurant: Restaurant;
    @Output() descriptionsChange: EventEmitter<DescriptionUpdateData> = new EventEmitter();
    @Output() prepareDescriptionsDuplication: EventEmitter<string> = new EventEmitter<string>();

    readonly SvgIcon = SvgIcon;

    selectedSize$ = new BehaviorSubject(DescriptionsSizes.LONG.key);
    descriptionsSizes: string[] = ['long', 'short'];

    selectedTab = DescriptionModalTabs.LONG_DESCRIPTION;

    readonly isFeaturePlatformsUpdatesV2Enabled$ = this._experimentationService.isFeatureEnabled$('release-platforms-updates');

    private readonly _informationsContext = inject(InformationsContext);

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _experimentationService: ExperimentationService
    ) {
        this._informationsContext.openRestaurantDescriptionsModal$.pipe(takeUntilDestroyed()).subscribe(() => this.openDescriptionDialog());
    }

    ngOnInit(): void {
        if (this.restaurant.isBrandBusiness()) {
            this.selectedSize$.next(DescriptionsSizes.SHORT.key);
        }
    }

    editDescription(event: any): void {
        this.descriptionsChange.emit(event);
    }

    handleTabChange(tabIndex: number): void {
        const descriptionSize = this.descriptionsSizes[tabIndex];
        this.selectedSize$.next(descriptionSize);
        this.selectedTab = tabIndex;
    }

    openDescriptionDialog(): void {
        this._customDialogService
            .open<DescriptionModalComponent, any, DescriptionUpdateData>(DescriptionModalComponent, {
                height: undefined,
                data: { restaurant: this.restaurant, selectedTab: this.selectedTab },
            })
            .afterClosed()
            .subscribe({
                next: (data) => {
                    if (data && Object.keys(data).length > 0) {
                        this.descriptionsChange.emit(data);
                    }
                },
            });
    }

    onPrepareDescriptionsDuplication(): void {
        const descriptionSize = this.descriptionsSizes[this.selectedTab];
        this.prepareDescriptionsDuplication.emit(descriptionSize);
    }
}
