import { Routes } from '@angular/router';

import { CREDENTIALS_ROUTES } from ':core/credentials/credentials.routing';
import { HeaderRouterComponent } from ':modules/header-router/header-router.component';
import { RESTAURANTS_LIST_ROUTES } from ':modules/restaurant-list/restaurant-list.routing';
import { USERS_ROUTES } from ':modules/user/users.routing';

import { ADMIN_ROUTES } from '../admin/admin.routing';

export const HEADER_ROUTER_ROUTES: Routes = [
    {
        path: '',
        component: HeaderRouterComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'restaurants',
            },
            {
                path: 'restaurants',
                loadChildren: () => RESTAURANTS_LIST_ROUTES,
            },
            {
                path: 'users',
                loadChildren: () => USERS_ROUTES,
            },
            {
                path: 'credentials',
                loadChildren: () => CREDENTIALS_ROUTES,
            },
            {
                path: 'admin',
                runGuardsAndResolvers: 'always',
                loadChildren: () => ADMIN_ROUTES,
            },
        ],
    },
];
