import { FormControl, FormControlOptions, FormGroup } from '@angular/forms';

import { INullableFormControlRecord } from '../interfaces/form-control-record.interface';

export function getFormControlRecordFromDefaultValue<T extends Object>(
    defaultValue: T,
    options: Partial<Record<keyof T, FormControlOptions>> = {}
): INullableFormControlRecord<T> {
    return Object.entries(defaultValue).reduce((acc, [key, value]) => {
        if (isFormGroup(value)) {
            acc[key] = value;
            return acc;
        }
        acc[key] = options[key] ? new FormControl(value, options[key]) : new FormControl(value);
        return acc;
    }, {} as INullableFormControlRecord<T>);
}

function isFormGroup(value: any): value is FormGroup {
    return value instanceof FormGroup;
}
