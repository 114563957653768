import { isEqual, sortBy } from 'lodash';

import { InformationUpdateDataDto, MergedInformationUpdateDto } from '@malou-io/package-dto';
import { PartialRecord, PlatformKey } from '@malou-io/package-utils';

import { ComparisonKey } from ':shared/models';

const keysToShowByPlatform: PartialRecord<PlatformKey, Record<keyof InformationUpdateDataDto, boolean>> = {
    [PlatformKey.TRIPADVISOR]: {
        address: true,
        attributes: true,
        categoryName: true,
        secondaryCategoriesNames: true,
        coverUrl: true,
        shortDescription: false,
        longDescription: true,
        isClosedTemporarily: true,
        latlng: false,
        logoUrl: true,
        menuUrl: true,
        name: true,
        openingDate: false,
        otherHours: false,
        phone: true,
        regularHours: true,
        specialHours: true,
        website: true,
    },
    [PlatformKey.FOURSQUARE]: {
        address: true,
        attributes: true,
        categoryName: true,
        secondaryCategoriesNames: true,
        coverUrl: true,
        shortDescription: true,
        longDescription: false,
        isClosedTemporarily: false,
        latlng: false,
        logoUrl: true,
        menuUrl: false,
        name: true,
        openingDate: false,
        otherHours: false,
        phone: true,
        regularHours: true,
        specialHours: false,
        website: true,
    },
    [PlatformKey.YELP]: {
        address: true,
        attributes: true,
        categoryName: true,
        secondaryCategoriesNames: true,
        coverUrl: true,
        shortDescription: true,
        longDescription: true,
        isClosedTemporarily: true,
        latlng: false,
        logoUrl: true,
        menuUrl: true,
        name: true,
        openingDate: false,
        otherHours: false,
        phone: true,
        regularHours: true,
        specialHours: true,
        website: true,
    },
    [PlatformKey.PAGESJAUNES]: {
        address: true,
        attributes: true,
        categoryName: true,
        secondaryCategoriesNames: true,
        coverUrl: true,
        shortDescription: false,
        longDescription: true,
        isClosedTemporarily: true,
        latlng: false,
        logoUrl: true,
        menuUrl: true,
        name: true,
        openingDate: false,
        otherHours: false,
        phone: true,
        regularHours: true,
        specialHours: true,
        website: true,
    },
    [PlatformKey.LAFOURCHETTE]: {
        address: false,
        attributes: true,
        categoryName: true,
        secondaryCategoriesNames: true,
        coverUrl: false,
        shortDescription: false,
        longDescription: true,
        isClosedTemporarily: false,
        latlng: false,
        logoUrl: false,
        menuUrl: false,
        name: false,
        openingDate: false,
        otherHours: false,
        phone: true,
        regularHours: true,
        specialHours: false,
        website: true,
    },
};

const lockedFieldsToKeyOfInformationUpdateDataDtoMapping: Record<ComparisonKey, (keyof InformationUpdateDataDto)[]> = {
    [ComparisonKey.NAME]: ['name'],
    [ComparisonKey.DESCRIPTIONS]: ['shortDescription', 'longDescription'],
    [ComparisonKey.PHONE]: ['phone'],
    [ComparisonKey.POSTAL_CODE]: [],
    [ComparisonKey.FORMATTED_ADDRESS]: [],
    [ComparisonKey.COUNTRY]: [],
    [ComparisonKey.LOCALITY]: [],
    [ComparisonKey.ADDRESS]: ['address', 'latlng'],
    [ComparisonKey.MONDAY]: [],
    [ComparisonKey.TUESDAY]: [],
    [ComparisonKey.WEDNESDAY]: [],
    [ComparisonKey.THURSDAY]: [],
    [ComparisonKey.FRIDAY]: [],
    [ComparisonKey.SATURDAY]: [],
    [ComparisonKey.SUNDAY]: [],
    [ComparisonKey.WEBSITE]: ['website'],
    [ComparisonKey.MENU_URL]: ['menuUrl'],
    [ComparisonKey.IS_CLAIMED]: [],
    [ComparisonKey.IS_CLOSED_TEMPORARILY]: ['isClosedTemporarily'],
    [ComparisonKey.ATTRIBUTES]: ['attributes'],
    [ComparisonKey.SPECIAL_HOURS]: ['specialHours'],
    [ComparisonKey.REGULAR_HOURS]: ['regularHours'],
    [ComparisonKey.CATEGORY]: ['categoryName'],
    [ComparisonKey.CATEGORY_LIST]: ['secondaryCategoriesNames'],
};

const sortKeysByKey: Partial<Record<keyof InformationUpdateDataDto, string[]>> = {
    attributes: ['name'],
    secondaryCategoriesNames: [],
    regularHours: ['openDay', 'openTime', 'closeDay', 'closeTime'],
    specialHours: [
        'startDate.year',
        'startDate.month',
        'startDate.day',
        'openTime',
        'endDate.year',
        'endDate.month',
        'endDate.day',
        'closeTime',
    ],
    otherHours: ['hoursTypeId'],
};

export namespace MergedInformationUpdateHelper {
    export function hasDiff(
        mergedInformationUpdate: MergedInformationUpdateDto,
        platformPick: { platformKey: PlatformKey; lockedFields?: string[] }
    ): boolean {
        const keysToCheck = getInformationUpdateKeysToCheck(mergedInformationUpdate, platformPick);

        for (const key of keysToCheck) {
            let current: InformationUpdateDataDto[keyof InformationUpdateDataDto];
            let previous: InformationUpdateDataDto[keyof InformationUpdateDataDto];
            switch (key) {
                case 'attributes':
                case 'secondaryCategoriesNames':
                case 'regularHours':
                case 'specialHours':
                case 'otherHours':
                    const currentTemp = mergedInformationUpdate.mergedData[key];
                    const previousTemp = mergedInformationUpdate.mergedData[key];
                    current = _getSortedValue(currentTemp, sortKeysByKey[key]);
                    previous = _getSortedValue(previousTemp, sortKeysByKey[key]);
                    break;
                default:
                    current = mergedInformationUpdate.mergedData[key];
                    previous = mergedInformationUpdate.previousData[key];
                    break;
            }
            if (isEqual(current, previous)) {
                continue;
            }
            return true;
        }

        return false;
    }

    export function getInformationUpdateKeysToCheck(
        mergedInformationUpdate: MergedInformationUpdateDto,
        platformPick: { platformKey: PlatformKey; lockedFields?: string[] }
    ): (keyof InformationUpdateDataDto)[] {
        return Object.keys(mergedInformationUpdate.mergedData)
            .filter((key: keyof InformationUpdateDataDto) => _filterKeyAccordingToPlatformLockedFields(key, platformPick.lockedFields))
            .filter((key: keyof InformationUpdateDataDto) =>
                _filterKeyAccordingToPlatform(key, platformPick.platformKey)
            ) as (keyof InformationUpdateDataDto)[];
    }

    function _getSortedValue<T extends Array<any> | undefined | null>(value: T, fields?: string | string[]): T {
        return Array.isArray(value) ? (sortBy(value, ...(fields ? [fields] : [])) as T) : value;
    }

    function _filterKeyAccordingToPlatform(key: keyof InformationUpdateDataDto, platformKey: PlatformKey): boolean {
        return keysToShowByPlatform[platformKey]?.[key] ?? false;
    }

    function _filterKeyAccordingToPlatformLockedFields(key: keyof InformationUpdateDataDto, lockedFields?: string[]): boolean {
        if (!lockedFields) {
            return true;
        }
        const isLockedField = lockedFields.some((lockedField) =>
            (lockedFieldsToKeyOfInformationUpdateDataDtoMapping[lockedField] ?? []).includes(key)
        );
        return !isLockedField;
    }
}
