export enum ReplyTone {
    FORMAL = 'FORMAL',
    INFORMAL = 'INFORMAL',
    DOES_NOT_MATTER = 'DOES_NOT_MATTER',
}

export enum CustomerNaming {
    FIRSTNAME = 'FIRSTNAME',
    FULLNAME = 'FULLNAME',
    TITLE_AND_LASTNAME = 'TITLE_AND_LASTNAME',
}
