import { Routes } from '@angular/router';

import { RoiRestaurantGuard } from ':core/auth/roi.guard';
import { BoostersComponent } from ':modules/statistics/boosters/boosters.component';

import { EReputationComponent } from './e-reputation/e-reputation.component';
import { RoiComponent } from './roi/roi.component';
import { SeoComponent } from './seo/seo.component';
import { SocialNetworksComponent } from './social-networks/social-networks.component';
import { StatisticsComponent } from './statistics.component';

export const STATISTICS_ROUTES: Routes = [
    {
        path: '',
        component: StatisticsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'roi',
            },
            {
                path: 'roi',
                component: RoiComponent,
                canActivate: [RoiRestaurantGuard],
            },
            {
                path: 'seo',
                component: SeoComponent,
            },
            {
                path: 'e-reputation',
                component: EReputationComponent,
            },
            {
                path: 'social-networks',
                component: SocialNetworksComponent,
            },
            {
                path: 'totems',
                redirectTo: 'boosters',
            },
            {
                path: 'boosters',
                component: BoostersComponent,
            },
        ],
    },
];
