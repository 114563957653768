import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal, WritableSignal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { filter, forkJoin, of, Subject, switchMap, takeUntil } from 'rxjs';

import { isNotNil, MAX_TIPS_TO_SHOW_CONGRATS, MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { TipItemComponent } from ':modules/roi/roi-tip-item/tip-item.component';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiService } from ':modules/roi/roi.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { KillSubscriptions } from ':shared/interfaces';
import { Restaurant } from ':shared/models';
import { RoiTipItem } from ':shared/models/roi-tip.model';
import { RoiTipsPathResolver } from ':shared/pipes/roi-tips-path-resolver.pipe';

@Component({
    selector: 'app-tips',
    templateUrl: './tips.component.html',
    styleUrls: ['./tips.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RoiTipsPathResolver, TranslateModule, TipItemComponent, SkeletonComponent, NgTemplateOutlet, SkeletonComponent],
})
export class TipsComponent implements OnInit, KillSubscriptions {
    readonly isParentLoading = input.required<boolean>();

    public roiContext = inject(RoiContext);

    readonly isError: WritableSignal<boolean> = signal(false);
    readonly PlatformKey = PlatformKey;
    readonly isTipsLoading = signal(false);

    readonly roiTips: WritableSignal<RoiTipItem[]> = signal([]);
    readonly MAX_TIPS_TO_SHOW_CONGRATS = MAX_TIPS_TO_SHOW_CONGRATS;
    readonly MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS = MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS;

    killSubscriptions$: Subject<void> = new Subject<void>();

    private readonly _roiService = inject(RoiService);
    private readonly _restaurantsService = inject(RestaurantsService);
    public readonly screenSizeService = inject(ScreenSizeService);

    ngOnInit(): void {
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(isNotNil),
                switchMap((restaurant: Restaurant) => {
                    this.isTipsLoading.set(true);
                    return forkJoin({
                        tips: this._roiService.getTipsForRestaurant(restaurant.id),
                        restaurantId: of(restaurant.id),
                    });
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ({ restaurantId, tips }) => {
                    this.isTipsLoading.set(false);
                    this.roiTips.set(tips.data.map((tip) => new RoiTipItem({ ...tip, restaurantId })));
                },
                error: () => {
                    this.isTipsLoading.set(false);
                    this.isError.set(true);
                },
            });
    }
}
