import { EntityConstructor, NotificationChannel, NotificationType } from '@malou-io/package-utils';

export type NotificationProps = EntityConstructor<Notification>;

export class Notification {
    id: string;
    userIds: string[];
    restaurantId: string;
    data: Record<string, any> | any;
    readAt: Date | null;
    type: NotificationType;
    error: string | null;
    channel: NotificationChannel;
    completedAt: Date | null;
    createdAt?: Date | undefined;
    updatedAt?: Date | undefined;

    constructor(props: NotificationProps) {
        this.id = props.id;
        this.userIds = props.userIds;
        this.restaurantId = props.restaurantId;
        this.data = props.data;
        this.readAt = props.readAt;
        this.type = props.type;
        this.error = props.error;
        this.channel = props.channel;
        this.completedAt = props.completedAt;
        this.createdAt = props.createdAt;
        this.updatedAt = props.updatedAt;
    }

    copyWith(props: Partial<NotificationProps>): Notification {
        return new Notification({ ...this, ...props });
    }
}
