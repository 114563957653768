import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from ':environments/environment';
import { ApiResult, Category } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/categories`;

    constructor(private readonly _http: HttpClient) {}

    getAllCategories(): Observable<ApiResult> {
        return this._http.get<ApiResult>(this.API_BASE_URL).pipe(
            map((res) => {
                res.data = res.data.map((cat) => new Category(cat));
                return res;
            })
        );
    }
}
