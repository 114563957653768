import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { BusinessCategory } from '@malou-io/package-utils';

import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-restaurant-card',
    templateUrl: './restaurant-card.component.html',
    styleUrls: ['./restaurant-card.component.scss'],
    standalone: true,
    imports: [MatTooltipModule, MatIconModule, TranslateModule, IllustrationPathResolverPipe, LazyLoadImageModule],
})
export class CardRestaurantComponent implements OnInit {
    @Input() restaurant: Restaurant;
    @Output() editInternalName = new EventEmitter<Restaurant>();

    readonly SvgIcon = SvgIcon;
    readonly maxLengthRestaurantName = 17;
    BusinessCategory = BusinessCategory;
    logoUrl: string;
    coverUrl: string;

    constructor(private readonly _router: Router) {}

    ngOnInit(): void {
        this.logoUrl = (this.restaurant?.logo?.urls?.igFit || this.restaurant?.logo?.urls?.small) as string;
        this.coverUrl = (this.restaurant?.cover?.urls?.igFit || this.restaurant?.cover?.urls?.small) as string;
    }

    emitEditInternalName(): void {
        this.editInternalName.emit(this.restaurant);
    }

    redirectToDashboard(): void {
        this._router.navigate(['restaurants', this.restaurant._id, 'dashboard']);
    }
}
