import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, Input, signal, ViewChild, WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { map, of, switchMap } from 'rxjs';

import { HtmlDownloader } from ':shared/helpers/html-downloader';
import { QrCode } from ':shared/helpers/qr-code';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { NfcWithStats } from '../../../models';

@Component({
    selector: 'app-sticker',
    standalone: true,
    imports: [NgClass, NgTemplateOutlet, MatIconModule, TranslateModule, ImagePathResolverPipe],
    templateUrl: './sticker.component.html',
    styleUrls: ['./sticker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickerComponent {
    @ViewChild('stickerContainer', { static: false }) set htmlContainer(htmlElementRef: ElementRef<HTMLElement>) {
        if (htmlElementRef) {
            this.containerHtmlElement.set(htmlElementRef.nativeElement);
        }
    }

    @Input() set sticker(value: NfcWithStats) {
        this._nfcWithStats.set(value);
    }

    readonly SvgIcon = SvgIcon;

    get sticker(): WritableSignal<NfcWithStats | null> {
        return this._nfcWithStats;
    }

    readonly containerHtmlElement = signal<HTMLElement | null>(null);
    readonly restaurantId: WritableSignal<string | null> = signal(null);

    readonly qrCode = new QrCode();
    readonly qrCodeUrl = computed(() => this.sticker()?.getNfcUrl() || '');
    readonly qrCodeImage = toSignal(
        toObservable(this.qrCodeUrl).pipe(
            switchMap((url) => {
                if (!url) {
                    return of('');
                }
                const image = this.qrCode.generateQrCode$(url);
                return image.pipe(map((res) => res.replace(/^data:image\/[^;]*/, 'data:application/octet-stream')));
            })
        )
    );
    readonly stickerRestaurantName = computed(() => this.sticker()?.getRestaurantName() || '');

    private readonly _nfcWithStats: WritableSignal<NfcWithStats | null> = signal(null);

    constructor(private readonly _htmlDownloader: HtmlDownloader) {
        effect(() => {
            const htmlToDownload = this.containerHtmlElement();
            if (htmlToDownload && this.qrCodeImage()) {
                this._htmlDownloader.downloadPng(htmlToDownload, 'Sticker - Design').subscribe();
            }
        });
    }
}
