<div class="restaurant-card w-fit md:w-full" (click)="redirectToDashboard()">
    <div class="flex justify-between">
        <p
            class="malou-text-18--bold mb-5 truncate text-malou-color-text-1"
            [matTooltip]="restaurant.internalName.length > maxLengthRestaurantName ? restaurant.internalName : ''">
            {{ restaurant.internalName }}
        </p>
        <mat-icon
            class="edit-internal-name-btn mt-1 !h-4 text-malou-color-primary"
            [svgIcon]="SvgIcon.EDIT"
            (click)="$event.stopPropagation(); emitEditInternalName()"></mat-icon>
    </div>
    <div class="restaurant-card-images h-44 w-52 md:h-20 md:w-full">
        <img class="restaurant-card-logo" [lazyLoad]="logoUrl || ('Grey-burger' | illustrationPathResolver)" />
        <img class="restaurant-card-cover" [lazyLoad]="coverUrl || ('Grey-burger' | illustrationPathResolver)" />
        @if (restaurant.type === 'local_business') {
            <div class="restaurant-type-logo--local-business" [matTooltip]="'restaurants_list.business.business' | translate">
                <mat-icon [svgIcon]="SvgIcon.LOCALISATION"></mat-icon>
            </div>
        } @else {
            <div class="restaurant-type-logo--brand" [matTooltip]="'restaurants_list.brand_account' | translate">
                <mat-icon [svgIcon]="SvgIcon.HEART"></mat-icon>
            </div>
        }
    </div>

    <div class="restaurant-card-content">
        @if (restaurant.type === BusinessCategory.BRAND) {
            <mat-icon class="!h-4 text-malou-color-primary" [svgIcon]="SvgIcon.HEART"></mat-icon>
        } @else {
            <mat-icon class="!h-4 text-malou-color-primary" [svgIcon]="SvgIcon.LOCALISATION"></mat-icon>
        }
        @if (restaurant.type === BusinessCategory.BRAND) {
            <div>
                <p class="leading-[1.125rem]">{{ 'common.brand_account' | translate }}</p>
            </div>
        } @else {
            <div>
                <p class="leading-[1.125rem]">{{ restaurant.address?.formattedAddress }}</p>
                <p class="leading-[1.125rem]">
                    {{ restaurant.address?.postalCode }} {{ restaurant.address?.locality }} {{ restaurant.address?.country }}
                </p>
            </div>
        }
    </div>
</div>
