import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { compact } from 'lodash';
import { concatMap, from, Observable } from 'rxjs';

import { PlatformDefinitions } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { PlatformComparisonAction } from ':shared/interfaces/value-with-difference.interface';
import { ApiResult, Platform, PlatformComparisonWithStatus, PlatformStatus } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

import { PlatformComparisonComponent, SinglePlatformLockedFields } from '../platform-comparison/platform-comparison.component';

interface PlatformLockedFields {
    [platformId: string]: {
        [fieldKey: string]: {
            lock: boolean;
            changed: boolean;
        };
    };
}
@Component({
    selector: 'app-platforms-comparisons-modal',
    templateUrl: './platforms-comparisons-modal.component.html',
    styleUrls: ['./platforms-comparisons-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        CloseWithoutSavingModalComponent,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatTooltipModule,
        PlatformLogoPathResolverPipe,
        TranslateModule,
        PlatformComparisonComponent,
        EnumTranslatePipe,
    ],
})
export class PlatformsComparisonsModalComponent implements OnInit, AfterViewInit {
    readonly SvgIcon = SvgIcon;
    readonly PlatformStatus = PlatformStatus;

    comparedPlatforms: PlatformComparisonWithStatus[];
    disableAnimation = true;
    totalDifferencesCount: number | undefined;
    displayCloseModal = false;
    platformsLockedFields: PlatformLockedFields = {};
    action: PlatformComparisonAction;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            comparedPlatforms: PlatformComparisonWithStatus[];
            totalDifferencesCount?: number;
            action?: PlatformComparisonAction;
        },
        private readonly _dialogRef: MatDialogRef<PlatformsComparisonsModalComponent>,
        private readonly _platformsService: PlatformsService,
        private readonly _toastService: ToastService,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.comparedPlatforms = data.comparedPlatforms.sort((a, b) => {
            if (a.data && !b.data) {
                return -1;
            }
            if (!a.data && b.data) {
                return 1;
            }
            return 0;
        });

        this.totalDifferencesCount = this.data.totalDifferencesCount;
        this.action = this.data.action ?? PlatformComparisonAction.LOCK;
    }

    ngOnInit(): void {
        this.platformsLockedFields = this._initPlatformLockedFields();
    }

    ngAfterViewInit(): void {
        setTimeout(() => (this.disableAnimation = false));
    }

    close(): void {
        const updated = Object.values(this.platformsLockedFields).some((e) => Object.keys(e).length !== 0);
        if (updated) {
            this.displayCloseModal = true;
        } else {
            this.confirmClose();
        }
    }

    confirmClose(data?: any): void {
        this._dialogRef.close(data);
    }

    updateLockField$(platformId: string, field: string, lock: boolean): Observable<ApiResult<any>> {
        return lock ? this._platformsService.lockField(platformId, field) : this._platformsService.unlockField(platformId, field);
    }

    updateNow(): void {
        const updates: Array<{ platformId: string; field: string; lock: boolean }> = compact(
            Object.entries(this.platformsLockedFields).flatMap(([platformId, value]) =>
                Object.entries(value).map(([field, updateData]) =>
                    updateData.changed ? { platformId, field, lock: updateData.lock } : null
                )
            )
        );

        if (!updates.length) {
            this.confirmClose();
            return;
        }

        from(updates)
            .pipe(concatMap((update) => this.updateLockField$(update.platformId, update.field, update.lock)))
            .subscribe({
                complete: () => {
                    this.confirmClose({ updated: true });
                },
                error: (err) => this._toastService.openErrorToast(err?.error?.message || err?.message || String(err)),
            });
    }

    accessPlatform(platformComparison: PlatformComparisonWithStatus): void {
        const platform = platformComparison.platformData;
        if (!platform) {
            return;
        }
        const platformLink = PlatformDefinitions.getPlatformDefinition(platform.key)?.updateLink?.(platform.socialId);
        if (!platformLink) {
            return;
        }
        window.open(platformLink, '_blank');
    }

    onChangeLock(platformComparison: PlatformComparisonWithStatus, event: SinglePlatformLockedFields): void {
        if (!platformComparison.platformData) {
            return;
        }
        this.platformsLockedFields[platformComparison.platformData._id] = event;
    }

    private _initPlatformLockedFields(): PlatformLockedFields {
        const platformsLockedFields = {};
        (this.comparedPlatforms.filter((cp) => !!cp.platformData) as { platformData: Platform }[]).forEach(({ platformData }) => {
            platformsLockedFields[platformData._id] = {};
            platformData.lockedFields.forEach((lockField) => {
                platformsLockedFields[platformData._id][lockField] = {
                    lock: true,
                    changed: false,
                };
            });
        });

        return platformsLockedFields;
    }
}
