import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { max } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, switchMap, tap } from 'rxjs';

import { isNotNil, isValidDate, ROI_HIDDEN_FIRST_MONTHS_NUMBER, sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiInsightsCreationState, RoiService } from ':modules/roi/roi.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { LoaderPageComponent } from ':shared/components/loader-page/loader-page.component';
import { Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { FiltersComponent } from '../filters/filters.component';
import { AggregatedStatisticsFiltersContext } from '../filters/filters.context';
import { PlatformFilterPage } from '../store/aggregated-statistics.interface';
import { selectRoiRestaurantsIdsFilter } from '../store/aggregated-statistics.selectors';
import { AggregatedEstimatedCustomersComponent } from './aggregated-estimated-customers/aggregated-estimated-customers.component';
import { AggregatedMonthlyEstimatedCustomersComponent } from './aggregated-monthly-estimated-customers/aggregated-monthly-estimated-customers.component';
import { AggregatedPerformanceComponent } from './aggregated-performance/aggregated-performance.component';
import { AggregatedSavedTimeComponent } from './aggregated-saved-time/aggregated-saved-time.component';
import { UpdateAggregatedRoiSettingsModalComponent } from './update-aggregated-roi-settings-modal/update-aggregated-roi-settings-modal.component';
import { UpsertAggregatedRoiSettingsComponent } from './upsert-aggregated-roi-settings/upsert-aggregated-roi-settings.component';

@Component({
    selector: 'app-roi',
    standalone: true,
    imports: [
        NgClass,
        MatButtonModule,
        NgTemplateOutlet,
        TranslateModule,
        AggregatedSavedTimeComponent,
        AggregatedEstimatedCustomersComponent,
        FiltersComponent,
        UpsertAggregatedRoiSettingsComponent,
        LoaderPageComponent,
        AsyncPipe,
        AggregatedPerformanceComponent,
        AggregatedMonthlyEstimatedCustomersComponent,
        IllustrationPathResolverPipe,
    ],
    templateUrl: './roi.component.html',
    styleUrl: './roi.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoiComponent implements OnInit {
    private readonly _store = inject(Store);
    private readonly _screenService = inject(ScreenSizeService);
    private readonly _roiService = inject(RoiService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _destroyRef = inject(DestroyRef);
    readonly roiContext = inject(RoiContext);

    readonly Illustration = Illustration;
    readonly isLoading: WritableSignal<boolean> = signal(false);
    readonly isLoadingFetchRoiSettings: WritableSignal<boolean> = signal(false);
    readonly hasSomeInitialSettings$: Observable<boolean> = combineLatest([
        toObservable(this.isLoadingFetchRoiSettings),
        toObservable(this.roiContext.restaurantsRoiSettings),
        toObservable(this.roiContext.restaurantsCategories),
    ]).pipe(
        filter(([isLoading, _, restaurantsCategories]) => !isLoading && !!restaurantsCategories.length),
        switchMap(([_, restaurantsRoiSettings, restaurantsCategories]) =>
            of(this.roiContext.getHasSomeInitialSettings(restaurantsRoiSettings, restaurantsCategories))
        ),
        tap(() => this.isLoading.set(false)),
        takeUntilDestroyed(this._destroyRef)
    );

    readonly reload$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    readonly PlatformFilterPage = PlatformFilterPage;

    readonly currentUser$ = this._store.select(selectUserInfos);

    readonly restaurants$: Observable<Restaurant[]> = combineLatest([this._store.select(selectOwnRestaurants), this.currentUser$]).pipe(
        filter(([_, user]) => isNotNil(user)),
        map(
            ([restaurants, user]) =>
                restaurants
                    .filter((restaurant) => !restaurant.isBrandBusiness() && (user?.isAdmin() || restaurant.roiActivated))
                    .sort(sortRestaurantsByInternalNameThenName) ?? []
        )
    );

    readonly hasAtLeastTwoRestaurantsSelected$: Observable<boolean> = this._store
        .select(selectRoiRestaurantsIdsFilter)
        .pipe(map((restaurants) => restaurants.length >= 2));

    readonly restaurantsLimitDate = toSignal(
        this._aggregatedStatisticsFiltersContext.savedRestaurantsWithRoiSettings$.pipe(
            map((restaurants) => {
                const allDates = restaurants
                    .map((restaurant) => {
                        if (!restaurant) {
                            return null;
                        }
                        const mostRecentLimitDate = new Restaurant(restaurant).getMostRecentDateBetweenCreationAndOpening();
                        if (!mostRecentLimitDate || !isValidDate(mostRecentLimitDate)) {
                            return null;
                        }
                        const endOfMonthMostRecentLimitDate = DateTime.fromJSDate(mostRecentLimitDate).endOf('month').endOf('day');
                        return endOfMonthMostRecentLimitDate.plus({ month: ROI_HIDDEN_FIRST_MONTHS_NUMBER }).toJSDate().getTime();
                    })
                    .filter(isNotNil);
                const mostRecentDate = max(allDates) ?? null;
                return mostRecentDate ? new Date(mostRecentDate) : null;
            })
        ),
        { initialValue: null }
    );

    readonly restaurantIdForCreationWatcher$: BehaviorSubject<string | null> = new BehaviorSubject(null);
    readonly creationStartDate: WritableSignal<Date | null> = signal(null);
    readonly creationEstimatedTime: WritableSignal<number> = signal(Number.MAX_SAFE_INTEGER);
    readonly isCreatingRoiInsights = computed(() => !!this.creationStartDate());

    ngOnInit(): void {
        this._fetchLastRoiSettingsForRestaurants();
        this._initCreationStartDateAndEstimationTime();
    }

    openUpdateRoiSettingsModal(): void {
        this._customDialogService
            .open(UpdateAggregatedRoiSettingsModalComponent, {
                panelClass: this._screenService.isPhoneScreen ? 'malou-dialog-panel--full' : 'malou-dialog-panel--fit-content',
                maxWidth: '600px',
                autoFocus: false,
                data: {},
            })
            .afterClosed()
            .subscribe({
                next: (res) => {
                    if (res?.needsToBeUpdated) {
                        this.reload$.next(true);
                    }
                },
            });
    }

    handleOnSaveSettings(evt?: { restaurantIdForCreationWatcher: string | null }): void {
        this.reload$.next(true);
        if (evt?.restaurantIdForCreationWatcher) {
            this.restaurantIdForCreationWatcher$.next(evt.restaurantIdForCreationWatcher);
        }
    }

    private _fetchLastRoiSettingsForRestaurants(): void {
        combineLatest([this.restaurants$, this.reload$, this._roiService.roiInsightsCreationState$])
            .pipe(
                filter(isNotNil),
                tap(() => {
                    this.isLoading.set(true);
                    this.isLoadingFetchRoiSettings.set(true);
                }),
                switchMap(([restaurants, _reload, _state]: [Restaurant[], boolean, { [restaurantId: string]: RoiInsightsCreationState }]) =>
                    this.roiContext.getRoiSettingsForRestaurants(restaurants.map((restaurant) => restaurant.id))
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: () => {
                    this.isLoadingFetchRoiSettings.set(false);
                },
                error: () => {
                    this.isLoading.set(false);
                    this.isLoadingFetchRoiSettings.set(false);
                },
            });
    }

    private _initCreationStartDateAndEstimationTime(): void {
        combineLatest([this._roiService.roiInsightsCreationState$, this.restaurantIdForCreationWatcher$])
            .pipe(filter(([_, restaurantId]) => isNotNil(restaurantId)))
            .subscribe({
                next: ([creationCurrentState, restaurantId]: [
                    {
                        [restaurantId: string]: RoiInsightsCreationState;
                    },
                    string,
                ]) => {
                    this.creationStartDate.set(creationCurrentState[restaurantId]?.creationStartDate ?? null);
                    this.creationEstimatedTime.set(creationCurrentState[restaurantId]?.creationEstimatedTime ?? Number.MAX_SAFE_INTEGER);
                },
            });
    }
}
