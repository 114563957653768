import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    inject,
    input,
    OnInit,
    output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, take, tap } from 'rxjs/operators';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import * as KeywordsActions from ':modules/keywords/store/keywords.actions';
import {
    DEFAULT_PAGINATION,
    DEFAULT_REVIEW_PAGE_SIZE,
    DEFAULT_STORE_PAGINATION,
    INITIAL_REVIEW_DISPLAY_SIZE,
} from ':modules/reviews/answer-review-container/answer-review.interface';
import { AnswerReviewComponent } from ':modules/reviews/answer-review-container/answer-review/answer-review.component';
import { BasicPreviewComponent } from ':modules/reviews/basic-preview/basic-preview.component';
import { CanBeRepliedPipe } from ':modules/reviews/pipe/can-be-replied.pipe';
import { ReviewsHeaderComponent } from ':modules/reviews/reviews-header/reviews-header.component';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ReviewStrategyType } from ':modules/reviews/reviews/reviews.strategy';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import {
    selectArchivesReviewsFilters,
    selectEstimatedReviewCount,
    selectHasLoadedAllReviews,
    selectRestaurantsFilter,
    selectReviews,
    selectReviewsById,
    selectReviewsFilters,
    selectTextReviewsFilters,
    selectUnansweredReviewCount,
} from ':modules/reviews/store/reviews.selectors';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { LowerUpperPagination } from ':shared/interfaces/lower-upper-pagination.interface';
import { Restaurant, Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-answer-reviews-container',
    templateUrl: './answer-review-container.component.html',
    styleUrls: ['./answer-review-container.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        AnswerReviewComponent,
        BasicPreviewComponent,
        CloseWithoutSavingModalComponent,
        ReviewsHeaderComponent,
        SkeletonComponent,
        InfiniteScrollModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        ApplyPurePipe,
        AsyncPipe,
        CanBeRepliedPipe,
        IllustrationPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerReviewContainerComponent implements OnInit, AfterViewInit {
    readonly data = input.required<{
        review: Review | PrivateReview;
        pagination: LowerUpperPagination;
        isAggregatedView: boolean;
        displaySemanticAnalyses: Signal<boolean>;
    }>();
    readonly onClose = output<{
        reviewId: string;
        pagination: LowerUpperPagination;
    }>();
    readonly trackByUpdatedAtFn = TrackByFunctionFactory.get('updatedAt');

    readonly selectedReview$ = new BehaviorSubject<Review | PrivateReview | undefined>(undefined);
    readonly selectedReview: Signal<Review | PrivateReview | undefined> = toSignal(this.selectedReview$);

    readonly reviews$ = this._store.select(selectReviews);

    readonly reviews: WritableSignal<(Review | PrivateReview)[]> = signal([]);

    readonly displayCloseModal = signal(false);

    readonly scrollContainerSelector = '#scrollContainer';

    readonly hasSetReviews$ = new Subject<boolean>();
    readonly simplerCombinedReviewsExperimentationEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('experiment-simpler-combined-reviews')
    );
    readonly displayedReviews: WritableSignal<(Review | PrivateReview)[]> = signal([]);

    readonly reviewCount$ = this._store.select(selectEstimatedReviewCount);
    readonly reviewCount = toSignal(this.reviewCount$, { initialValue: undefined });
    readonly allReviewsLoaded = computed(() => this.reviewCount() === this.reviews().length);

    private _isArchivedToggled: boolean | undefined;
    private _unansweredReviewCount = 0;
    private _hasLoadedAllReviews: boolean;
    private _firstLoad = true;
    private _onNextReviewsScrollToFirst = false;
    private _selectedRestaurants: Restaurant[];
    private _pageOffset = 0;
    private _pagination = { ...DEFAULT_PAGINATION };

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        public readonly screenSizeService: ScreenSizeService,
        private readonly _store: Store,
        private readonly _translateService: TranslateService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _toastService: ToastService,
        private readonly _reviewsService: ReviewsService,
        private readonly _experimentationService: ExperimentationService
    ) {}

    ngOnInit(): void {
        this.selectedReview$.next(this.data().review);
        this._pagination = { ...this.data().pagination };
        this._store.select(selectArchivesReviewsFilters).subscribe((archivedFilters) => {
            this._isArchivedToggled = archivedFilters.archived;
        });
        this.selectedReview$
            .pipe(
                filter(Boolean),
                distinctUntilChanged((a, b) => a._id === b._id),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((review) => {
                this._onSelectReviewChange(review);
            });

        this._store.select(selectHasLoadedAllReviews).subscribe((_hasLoadedAllReviews) => {
            this._hasLoadedAllReviews = _hasLoadedAllReviews;
        });

        this._store
            .select(selectReviewsFilters)
            .pipe(skip(1), takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                if (!this.simplerCombinedReviewsExperimentationEnabled() || !this.data().isAggregatedView) {
                    return;
                }
                this._onNextReviewsScrollToFirst = true;
                this._pagination = { ...DEFAULT_PAGINATION };
                this._pageOffset = 0;
            });
        this.reviews$
            .pipe(
                tap((reviews) => {
                    if (this._firstLoad) {
                        this._firstLoad = false;
                        this._pageOffset = this._pagination.lower;
                        if (this._needSliceReviews(reviews)) {
                            const index = reviews.findIndex((review) => review._id === this.data().review._id);
                            const pageNumber = Math.floor(index / DEFAULT_REVIEW_PAGE_SIZE) + this._pageOffset;
                            this._pagination = {
                                lower: Math.max(pageNumber - 1, 0),
                                upper: pageNumber + 1,
                            };
                        }
                    }
                }),
                tap((reviews) => {
                    const selectedReview = reviews.find((review) => review._id === this.selectedReview()?._id);
                    if (selectedReview) {
                        this.selectedReview$.next(selectedReview);
                    }
                }),
                map((reviews) => {
                    const lower = this._pagination.lower - this._pageOffset;
                    const upper = this._pagination.upper - this._pageOffset + 1;
                    const sliced = reviews.slice(lower * DEFAULT_REVIEW_PAGE_SIZE, upper * DEFAULT_REVIEW_PAGE_SIZE);
                    return sliced;
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((reviews) => {
                setTimeout(() => {
                    this.reviews.set(reviews); // faster display of the dialog in case there are a lot of reviews to be displayed
                    if (this._onNextReviewsScrollToFirst) {
                        this._onNextReviewsScrollToFirst = false;
                        this.onSelectReview(this.reviews()[0]?._id);
                    }
                    this.hasSetReviews$.next(true);
                }, 0);
            });

        this._store
            .select(selectUnansweredReviewCount)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((count) => {
                this._unansweredReviewCount = count ?? 0;
            });

        this._store
            .select(selectRestaurantsFilter)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((restaurants) => (this._selectedRestaurants = restaurants));

        this._store
            .select(selectTextReviewsFilters)
            .pipe(skip(1))
            .subscribe((text) => {
                if (text.length >= 3 || text.length === 0) {
                    this._resetReviewsAndPagination();
                }
            });

        if (!this.data().isAggregatedView) {
            this._initRestaurantKeywords();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._scrollToReview(this.selectedReview()?._id);
        }, 500);
    }

    confirmClose(): void {
        this._resetReplies();
        this._router.navigate([], {
            relativeTo: this._route,
        });
        this.onClose.emit({ reviewId: this.selectedReview()!._id, pagination: this._pagination });
    }

    goNext(review: Review | PrivateReview | null): void {
        this._selectNextReview(this.reviews(), review ?? this.selectedReview()!);
    }

    onSelectReview(reviewId: string): void {
        this._store
            .select(selectReviewsById(reviewId))
            .pipe(take(1))
            .subscribe((review) => {
                if (review) {
                    this.selectedReview$.next(review);
                }
            });
    }

    onScrollDown(): void {
        const lastReviewId = this.reviews()[this.reviews().length - 1]?._id;
        this._increaseUpperPagination();
        this._getOrFetchReviews(ReviewStrategyType.CONCAT, lastReviewId);
    }

    onScrollUp(): void {
        if (this._pagination.lower === 0) {
            return;
        }

        const firstReviewId = this.reviews()[0]?._id;
        this._decreaseLowerPagination();
        this._getOrFetchReviews(ReviewStrategyType.CONCAT_BEFORE, firstReviewId);
    }

    getRestaurantFromReview = (review: Review | PrivateReview): Restaurant | undefined =>
        this.data()?.isAggregatedView
            ? this._selectedRestaurants.find((restaurant) => restaurant._id === review.restaurantId)
            : this._restaurantsService.currentRestaurant;

    setDisplayCloseModal = (value: boolean): void => {
        this.displayCloseModal.set(value);
    };

    onArchivedReview(index: number): void {
        const review = this.reviews()[index];
        const reviewsCopy = [...this.reviews()];
        if (!this._isArchivedToggled) {
            this.reviews.update((current) => current.slice(0, index).concat(current.slice(index + 1)));
        }
        const reviewObj = { ...review, archived: !review.archived };
        const newReview = review.isPrivate() ? new PrivateReview(<PrivateReview>reviewObj) : new Review(<Review>reviewObj);
        this._reviewsService.updateReviewArchivedValue(review._id, !review.archived, review.isPrivate()).subscribe({
            next: () => {
                this._toggleReviewArchived(newReview);
                if (!this._isArchivedToggled) {
                    this._selectNextReview(reviewsCopy, review);
                    this._toastService.openSuccessToast(this._translateService.instant('reviews.toast.archived'));
                } else {
                    this._toastService.openSuccessToast(this._translateService.instant('reviews.toast.unarchived'));
                }
            },
            error: (err) => {
                if (!this._isArchivedToggled) {
                    this.reviews.update((current) => [...current.slice(0, index), review, ...current.slice(index)]);
                }
                this._toastService.openErrorToast(err.message);
            },
        });
    }

    private _toggleReviewArchived(review: Review | PrivateReview): void {
        this._store.dispatch({
            type: ReviewsActions.toggleArchived.type,
            review,
        });
    }

    private _resetReviewsAndPagination(): void {
        this._resetPagination();
        this._fetchReviews(ReviewStrategyType.REPLACE);
    }

    private _fetchReviews(strategyType: ReviewStrategyType): void {
        this._store.dispatch(ReviewsActions.fetchReviews({ strategyType }));
    }

    private _getOrFetchReviews(strategyType: ReviewStrategyType, reviewId: string): void {
        this._store.dispatch(ReviewsActions.getOrFetchReviews({ strategyType, reviewId }));
    }

    private _resetReplies(): void {
        this._store.dispatch(ReviewsActions.resetReplies());
    }

    private _resetPagination(): void {
        this._store.dispatch(ReviewsActions.resetPagination());
        this._pagination = { ...DEFAULT_PAGINATION };
    }

    private _setPagination(pageNumber: number): void {
        this._store.dispatch(
            ReviewsActions.setPagination({
                pagination: {
                    ...DEFAULT_STORE_PAGINATION,
                    pageNumber,
                },
            })
        );
    }

    private _scrollToReview(reviewId: string | undefined): void {
        if (!reviewId) {
            return;
        }
        const reviewElement = document.getElementById(reviewId);

        if (reviewElement) {
            reviewElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    private _onSelectReviewChange(review: Review | PrivateReview): void {
        setTimeout(() => {
            this._scrollToReview(this.selectedReview()?._id);
            this._router.navigate([], {
                relativeTo: this._route,
                queryParams: { reviewId: review._id },
            });
        }, 50);
    }

    private _increaseUpperPagination(increment: number = 1): void {
        this._pagination.upper += increment;
        this._setPagination(this._pagination.upper);
    }

    private _decreaseLowerPagination(decrement: number = 1): void {
        this._pagination.lower -= decrement;
        if (this._pagination.lower < this.data().pagination.lower) {
            this._pageOffset -= decrement;
        }
        this._setPagination(this._pagination.lower);
    }

    private _selectNextReview(reviews: (Review | PrivateReview)[], currentReview: Review | PrivateReview): void {
        const hasNextReview = this._unansweredReviewCount > 0;
        if (!hasNextReview) {
            this._toastService.openSuccessToast(this._translateService.instant('reviews.all_reviews_answered'));
            return;
        }
        const index = reviews.findIndex((review) => review._id === currentReview._id);
        const nextReviews = reviews.slice(index + 1);
        let nextIndex = nextReviews.findIndex((review) => review.canBeReplied());

        if (nextIndex === -1 && this._hasLoadedAllReviews) {
            nextIndex = reviews.slice(0, index).findIndex((review) => review.canBeReplied());
            const nextReview = this.reviews()[nextIndex];
            if (!nextReview) {
                return;
            }
            this.selectedReview$.next(<Review>nextReview);
            return;
        } else if (nextIndex === -1 && !this._hasLoadedAllReviews) {
            // no more reviews to answer but there are still reviews to load
            this.onScrollDown();
            this.hasSetReviews$.pipe(take(1)).subscribe(() => {
                this._selectNextReview(this.reviews(), currentReview);
            });
            return;
        }
        if (nextIndex !== -1) {
            const nextReview = nextReviews[nextIndex];
            this.selectedReview$.next(<Review>nextReview);
        }
    }

    private _needSliceReviews(reviews: (Review | PrivateReview)[]): boolean {
        return reviews.length > INITIAL_REVIEW_DISPLAY_SIZE;
    }

    private _initRestaurantKeywords(): void {
        const restaurant = this._restaurantsService.currentRestaurant;
        if (restaurant) {
            this._store.dispatch({ type: KeywordsActions.loadKeywords.type, restaurantId: restaurant._id });
        }
    }
}
