@if (!hasConnectedPlatform && !isUpdating) {
    <app-no-connected-platforms [message]="'moderation.please_connect_platforms' | translate"></app-no-connected-platforms>>
} @else {
    @if (screenService.isPhoneScreen) {
        <div class="malou-color-background-light relative flex h-full grow-0 flex-col">
            <div class="mt-4 flex h-12.5 flex-row items-center gap-x-4 px-6">
                <ng-container *ngTemplateOutlet="sortByFilters"></ng-container>
                <ng-container *ngTemplateOutlet="filterMenuButton"></ng-container>
                <ng-container *ngTemplateOutlet="syncButton"></ng-container>
            </div>
            <div class="flex">
                <button class="malou-btn-flat !ml-6 !px-6" mat-button (click)="shouldShowAdvancedOptions = !shouldShowAdvancedOptions">
                    {{ 'reviews.advanced_options' | translate }}
                    <mat-icon
                        class="ml-1 !w-4"
                        color="primary"
                        [svgIcon]="shouldShowAdvancedOptions ? 'chevron-up' : 'chevron-down'"></mat-icon>
                </button>
            </div>
            @if (shouldShowAdvancedOptions) {
                <div class="mb-4 flex h-12.5 flex-row items-center gap-x-4 px-6">
                    <ng-container *ngTemplateOutlet="search"></ng-container>
                </div>
            }
            <div class="min-h-0 grow">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
            <div class="absolute inset-6 top-auto">
                <ng-container *ngTemplateOutlet="switchModeButtonsMobile"></ng-container>
            </div>
        </div>
    } @else {
        <div class="malou-color-background-light flex h-full flex-col">
            <div class="my-4 flex h-12.5 flex-row items-center gap-x-4 px-7.5">
                @if (screenService.isLargeDesktopScreen) {
                    <span class="malou-text-section-title malou-color-text-1 whitespace-nowrap md:!text-[0.9375rem]">
                        {{
                            'moderation.title.comments_and_mentions_with_count'
                                | translate: { commentsCount: commentCount$ | async, mentionsCount: mentionCount$ | async }
                        }}
                    </span>
                }
                @if (!screenService.isLargeScreen) {
                    <app-search
                        class="mr-0 grow"
                        [placeholder]="'common.search' | translate"
                        [debounceTime]="500"
                        (searchChange)="onSearchChange($event)"></app-search>
                }
                <ng-container *ngTemplateOutlet="sortByFilters"></ng-container>
                <ng-container *ngTemplateOutlet="filterMenuButton"></ng-container>
                <ng-container *ngTemplateOutlet="switchModeButtons"></ng-container>
                <ng-container *ngTemplateOutlet="syncButton"></ng-container>
            </div>
            @if (screenService.isLargeScreen) {
                <div class="mb-4 flex h-12.5 flex-row items-center px-7.5">
                    <ng-container *ngTemplateOutlet="search"></ng-container>
                </div>
            }
            <div class="min-h-0 grow">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
        </div>
    }
}

<ng-template #content>
    <app-comments-list-view [class.hidden]="isPostView" [isUpdating]="isUpdating"></app-comments-list-view>
    <app-comments-posts-view [class.hidden]="!isPostView" [isUpdating]="isUpdating"></app-comments-posts-view>
</ng-template>

<!-- Start Header element templates -->
<ng-template #title>
    <span class="malou-text-section-title malou-color-text-1 whitespace-nowrap md:!text-[0.9375rem]">
        {{
            'moderation.title.comments_and_mentions_with_count'
                | translate: { commentsCount: commentCount$ | async, mentionsCount: mentionCount$ | async }
        }}
    </span>
</ng-template>

<ng-template #filterMenuButton>
    <button
        class="malou-btn-icon--secondary btn-xl"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [matTooltip]="'common.filters' | translate"
        [disabled]="!hasConnectedPlatform || isUpdating">
        @if (filterCount > 0) {
            <span class="malou-status--count malou-text-10--medium absolute right-1.5 top-1.5">
                {{ filterCount }}
            </span>
        }
        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
    </button>
</ng-template>

<ng-template #search>
    <app-search
        class="mr-0 grow"
        [placeholder]="'common.search' | translate"
        [debounceTime]="500"
        (searchChange)="onSearchChange($event)"></app-search>
</ng-template>

<ng-template #sortByFilters>
    <div class="justify-start lg:flex-1">
        <app-sort-by-filters
            [sortOptions]="SORT_OPTIONS"
            [sortBy]="filters.sortBy ?? 'date'"
            [sortOrder]="filters.sortOrder ?? -1"
            (changeSortBy)="onSortByChange($event)"
            (toggleSortOrder)="onSortOrderChange()">
        </app-sort-by-filters>
    </div>
</ng-template>

<ng-template #switchModeButtons>
    <mat-button-toggle-group class="malou-group-toggle-btn !min-w-[6.25rem]">
        <mat-button-toggle checked="true" (click)="isPostView = false">
            <mat-icon [svgIcon]="SvgIcon.LIST"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle id="tracking_comments_switch_to_post_view" (click)="isPostView = true">
            <mat-icon [svgIcon]="SvgIcon.CARD"></mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
</ng-template>

<ng-template #switchModeButtonsMobile>
    <mat-button-toggle-group class="malou-group-toggle-mobile-btn">
        <mat-button-toggle checked="true" (click)="isPostView = false">
            <span class="">{{ 'moderation.switch_view.unique' | translate }}</span>
        </mat-button-toggle>
        <mat-button-toggle (click)="isPostView = true">
            <span class="">{{ 'moderation.switch_view.by_post' | translate }}</span>
        </mat-button-toggle>
    </mat-button-toggle-group>
</ng-template>

<ng-template #syncButton>
    <button
        class="malou-btn-icon--secondary btn-normal btn-xl"
        mat-icon-button
        [disabled]="!hasConnectedPlatform"
        [matTooltip]="getRefreshButtonTooltip | applyPure: hasConnectedPlatform"
        (click)="startUpdateComments()">
        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SYNC"></mat-icon>
    </button>
</ng-template>
<!-- End Header element templates -->

<!-- Start Filter Menu -->
<mat-menu class="malou-mat-menu malou-box-shadow !min-w-fit !rounded-[10px] md:w-screen md:!max-w-none" #menu="matMenu">
    <div class="w-[440px] md:w-full" (click)="$event.stopPropagation()">
        <div class="flex w-full justify-between p-6">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'common.filters' | translate }}
            </div>

            <button class="malou-btn-flat" mat-button [disabled]="filterCount == 0" (click)="clearFilters()">
                {{ 'common.reset' | translate }}
            </button>
        </div>
        <div class="hide-scrollbar mx-6 flex h-[50vh] grow flex-col gap-y-5 overflow-y-scroll pb-5 md:h-d-70">
            <ng-container [ngTemplateOutlet]="periodFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="platformsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="statusFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="isCommentOrMentionFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="optionsFilterTemplate"></ng-container>
        </div>
    </div>
</mat-menu>
<!-- End Filter Menu -->

<!-- Start Filter Menu contents templates -->
<ng-template #periodFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.period' | translate }}
        </div>
        <app-grouped-date-filters
            [startDate]="filters.startDate ?? null"
            [endDate]="filters.endDate ?? null"
            [periodOptions]="periodOptions"
            [defaultPeriod]="filters.period"
            [withLabel]="false"
            (chooseBoundaryDate)="editCommentsFilters($event)">
        </app-grouped-date-filters>
    </div>
</ng-template>

<ng-template #platformsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.platforms' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            @for (platform of availablePlatforms; track platform) {
                <button matRipple [disabled]="!platform.connected" (click)="toggleSelectedPlatforms(platform)">
                    <img
                        class="h-[29px] w-[29px] rounded-full"
                        [class.opacity-50]="!platform.checked"
                        [src]="platform.key | platformLogoPathResolver"
                        [alt]="platform.key + ' logo'" />
                </button>
            }
        </div>
    </div>
</ng-template>

<ng-template #statusFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.status' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ filters.answered }}"
                    [checked]="filters.answered"
                    (change)="toggleFilter('answered')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.answered' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ filters.notAnswered }}"
                    [checked]="filters.notAnswered"
                    (change)="toggleFilter('notAnswered')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.notAnswered' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</ng-template>

<ng-template #isCommentOrMentionFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.comment_or_mention' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ filters.isComment }}"
                    [checked]="filters.isComment"
                    (change)="toggleFilter('isComment')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.comment' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ filters.isMention }}"
                    [checked]="filters.isMention"
                    (change)="toggleFilter('isMention')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.mention' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</ng-template>

<ng-template #optionsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.options' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ filters.archived }}"
                    [checked]="filters.archived"
                    (change)="toggleFilter('archived')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.archived' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ !filters.withoutOwnComment }}"
                    [checked]="!filters.withoutOwnComment"
                    (change)="toggleFilter('withoutOwnComment')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.ownComments' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</ng-template>
