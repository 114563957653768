import { DateTime } from 'luxon';

import { EntityConstructor } from '@malou-io/package-utils';

import { Notification } from './notification.model';

export interface SpecialHourNotificationData {
    eventName: string;
    restaurantIds: string[];
    eventDate: Date;
}

type SpecialHourNotificationProps = EntityConstructor<SpecialHourNotification>;

export class SpecialHourNotification extends Notification {
    data: SpecialHourNotificationData;

    constructor(props: SpecialHourNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<SpecialHourNotificationProps>): SpecialHourNotification {
        return new SpecialHourNotification({ ...this, ...props });
    }

    getFormattedEventDate(): string {
        return DateTime.fromJSDate(this.data.eventDate).toFormat('dd MMMM');
    }
}
