import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { ChartReviewsStats } from ':shared/models';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

type Data = ChartReviewsStats[];

@Injectable({ providedIn: 'root' })
export class AggregatedAverageReviewsRatingsCsvInsightsService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _shortNumberPipe: ShortNumberPipe
    ) {
        super();
    }

    protected override _isDataValid(data: Data): boolean {
        return data.length !== 0;
    }

    protected override _getData$(): Observable<Data> {
        return this._store.select(AggregatedStatisticsSelectors.selectAverageReviewsRatingsData).pipe(take(1));
    }

    protected override _getCsvDataRows(data: Data): CsvAsStringArrays {
        return data.map((d) => {
            const restaurantName = d.restaurant.name;
            const restaurantInternalName = d.restaurant.internalName ?? '';
            const restaurantAddress = d.restaurant.address?.getFullFormattedAddress() ?? '';
            const averageReviewsRatings = typeof d.averageRating === 'number' ? this._shortNumberPipe.transform(d.averageRating) : '';
            return [restaurantName, restaurantInternalName, restaurantAddress, averageReviewsRatings];
        });
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return ['Location', 'Location Internal Name', 'Location Address', 'Reviews Average Rating'];
    }
}
