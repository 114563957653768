import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

import { ToastService } from ':core/services/toast.service';
import { InputNumberComponent } from ':shared/components/input-number/input-number.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { mapRestaurantWithTotemsAndWheelsToLightRestaurant, RestaurantWithTotemsAndWheels } from ':shared/models';
import { Gift } from ':shared/models/gift';
import { GiftStock } from ':shared/models/gift-stock';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { EditGiftStocksModalComponent, EditGiftStocksModalProps } from './edit-gift-stocks-modal/edit-gift-stocks-modal.component';

export const GIFT_NAME_MAX_LENGTH = 20;

@Component({
    selector: 'app-gifts-stocks-settings',
    standalone: true,
    imports: [MatIconModule, CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, InputNumberComponent, InputTextComponent],
    templateUrl: './gifts-stocks-settings.component.html',
    styleUrls: ['./gifts-stocks-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftsStocksSettingsComponent implements OnInit {
    @Input() gifts: WritableSignal<Gift[]>;
    @Input() restaurants: RestaurantWithTotemsAndWheels[];
    @Input() isUpdate: boolean;
    @Input() isDisabled: boolean;
    @Input() giftIdToOpen: string | null;
    @Output() isGiftStockOpened = new EventEmitter<void>();

    readonly SvgIcon = SvgIcon;

    readonly giftNameControls: WritableSignal<FormControl<string | null>[]> = signal([]);
    readonly allControlsAreSet = computed(() => this.gifts().every((_, index) => !!this.giftNameControls()[index]));

    readonly MIN_GIFT_COUNT = 1;
    readonly MAX_GIFT_COUNT = 8;
    readonly GIFT_NAME_MAX_LENGTH = GIFT_NAME_MAX_LENGTH;

    private readonly _DEFAULT_GIFT = new Gift({ name: '', weight: 1, stocks: [] });
    private readonly _DEFAULT_STOCK_QUANTITY = null;

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this._DEFAULT_GIFT.stocks = this.restaurants.map((restaurant) => ({
            id: '',
            restaurant: mapRestaurantWithTotemsAndWheelsToLightRestaurant(restaurant),
            quantity: this._DEFAULT_STOCK_QUANTITY,
        }));
        this.gifts().forEach((gift) => {
            this.giftNameControls.update((giftNameControls) => [
                ...giftNameControls,
                new FormControl<string>({ value: gift.name, disabled: this.isDisabled }, Validators.required),
            ]);
        });

        if (this.giftIdToOpen) {
            const giftIndex = this.gifts().findIndex((g) => g.id === this.giftIdToOpen);
            this.onEditStock(this.gifts()[giftIndex], giftIndex);
            this.isGiftStockOpened.emit();
        }
    }

    onRemoveGift(index: number): void {
        this.gifts.update((currentGifts) => {
            currentGifts.splice(index, 1);
            return [...currentGifts];
        });
        this.giftNameControls.update((giftNameControls) => {
            giftNameControls.splice(index, 1);
            return [...giftNameControls];
        });
    }

    onAddGift(): void {
        this.giftNameControls.update((giftNameControls) => [
            ...giftNameControls,
            new FormControl<string>({ value: this._DEFAULT_GIFT.name, disabled: this.isDisabled }, Validators.required),
        ]);
        this.gifts.update((currentGifts) => [...currentGifts, cloneDeep(this._DEFAULT_GIFT)]);
    }

    onWeightChange(index: number, newWeight: number | null): void {
        this.gifts.update((currentGifts) => {
            const giftToUpdate = currentGifts[index];

            if (giftToUpdate) {
                giftToUpdate.weight = newWeight ?? 0;
            }
            return [...currentGifts];
        });
    }

    onNameChange(index: number, newName: string): void {
        this.gifts.update((currentGifts) => {
            const giftToUpdate = currentGifts[index];

            if (giftToUpdate) {
                giftToUpdate.name = newName;
            }
            return [...currentGifts];
        });
    }

    onEditStock(gift: Gift, index: number): void {
        const initialStocks = this._getInitialGiftStocks(gift);

        this._customDialogService
            .open<EditGiftStocksModalComponent, EditGiftStocksModalProps, GiftStock[] | undefined>(EditGiftStocksModalComponent, {
                height: 'unset',
                data: { initialStocks, isUpdate: this.isUpdate, giftName: gift.name },
            })
            .afterClosed()
            .subscribe({
                next: (newGiftStocks) => {
                    if (newGiftStocks) {
                        this.gifts.update((currentGifts) => {
                            const giftToUpdate = currentGifts[index];
                            if (giftToUpdate) {
                                giftToUpdate.stocks = newGiftStocks;
                            }
                            return [...currentGifts];
                        });
                    }
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._translateService.instant('gallery.there_is_error') + String(err));
                },
            });
    }

    private _getInitialGiftStocks(gift: Gift): GiftStock[] {
        return this.restaurants.map((restaurant) => {
            const existingGiftStocks = gift.stocks.find((giftStock) => giftStock.restaurant.id === restaurant._id);
            return (
                existingGiftStocks ?? {
                    id: '',
                    restaurant: mapRestaurantWithTotemsAndWheelsToLightRestaurant(restaurant),
                    quantity: this._DEFAULT_STOCK_QUANTITY,
                }
            );
        });
    }
}
