import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import {
    FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE,
    FacebookInstagramConnectionModalResult,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { GetOauthUrlService } from ':modules/platforms/platforms-connection-modals/shared/services/get-oauth-url.service';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { RightArrowWithContentComponent } from ':shared/components/right-arrow-with-text/right-arrow-with-content.component';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-facebook-instagram-connection-step-5',
    templateUrl: './facebook-instagram-connection-step-5.component.html',
    styleUrls: ['./facebook-instagram-connection-step-5.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, RightArrowWithContentComponent, NgTemplateOutlet, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookInstagramConnectionStep5Component extends BaseStepComponent<
    FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE,
    FacebookInstagramConnectionModalResult
> {
    private readonly _getOauthUrlService = inject(GetOauthUrlService);
    private readonly _translateService = inject(TranslateService);

    readonly ButtonStyle = ButtonStyle;

    currentLang = toSignal(this._translateService.onLangChange.pipe(map((v) => v.lang)), {
        initialValue: this._translateService.currentLang,
    });
    imageLang = computed(() => (this.currentLang() === 'fr' ? 'fr' : 'en'));

    onPrimaryClick(): void {
        const platformKey = this.sharedData();
        this._getOauthUrlService.execute(platformKey).subscribe((url) => {
            window.location.href = url;
        });
    }
}
