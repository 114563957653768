import { NgClass } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { partition, sortBy } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { concat, Observable, reduce, switchMap, tap } from 'rxjs';

import { MalouErrorCode } from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { ToastService } from ':core/services/toast.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { DndDirective } from ':shared/directives/dnd.directive';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { BulkWriteResult } from ':shared/interfaces/bulkwrite.interface';
import { ApiResult } from ':shared/models';
import { Client, ClientsSource } from ':shared/models/client';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { ClientsService } from '../clients.service';

enum UploadMethod {
    Update = 'UPDATE',
    Import = 'IMPORT',
}

@Component({
    selector: 'app-client-file-import-modal',
    templateUrl: './client-file-import-modal.component.html',
    styleUrls: ['./client-file-import-modal.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTableModule,
        MatTooltipModule,
        MatRippleModule,
        MatStepperModule,
        TranslateModule,
        NgClass,
        SlideToggleComponent,
        DndDirective,
        IllustrationPathResolverPipe,
        PlatformLogoPathResolverPipe,
        PlatformNamePipe,
        ShortTextPipe,
        LazyLoadImageModule,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
})
export class ClientFileImportModalComponent {
    readonly SvgIcon = SvgIcon;
    @ViewChild('stepper') private _myStepper: MatStepper;

    readonly trackByKeyFn = TrackByFunctionFactory.get('key');

    readonly DOWNLOAD_SOURCES = [
        { key: ClientsSource.LAFOURCHETTE, displayName: this._translate.instant('clients.import_modal.the_fork') },
        { key: ClientsSource.ZENCHEF, displayName: this._translate.instant('clients.import_modal.zenchef') },
        { key: ClientsSource.MALOU, displayName: this._translate.instant('clients.import_modal.other_format') },
    ];
    readonly DISPLAYED_COLUMNS: string[] = ['lastname', 'firstname', 'email'];

    isValidFile = false;
    isFileUploaded = false;
    selectedSource: string;
    uploadedFile: File | null = null;
    fileLength: number | null = null;

    clients: Client[] = [];
    dataSource = new MatTableDataSource<Partial<Client> & { isDuplicated: boolean }>();
    firstRows: Array<Omit<Client, '_id'>> = [];
    allClientsAcceptContact = false;
    fileError: string | null = null;
    requiredHeaders = '';
    isDragOver = false;
    nbDuplicatedClients = 0;
    isCheckedDuplicatedClients = true;

    constructor(
        private readonly _dialogRef: MatDialogRef<ClientFileImportModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            restaurantId: string;
        },
        private readonly _clientsService: ClientsService,
        private readonly _translate: TranslateService,
        private readonly _spinnerService: SpinnerService,
        private readonly _toastService: ToastService,
        private readonly _malouDialogService: DialogService,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {}

    cancel(): void {
        this._dialogRef.close({ cancel: true });
    }

    getFileErrorText(): string {
        if (this.fileError === 'empty_file') {
            return this._translate.instant('clients.import_modal.empty_file_error');
        }
        return this.selectedSource === ClientsSource.MALOU
            ? this._translate.instant('clients.import_modal.format_error_malou')
            : this._translate.instant('clients.import_modal.format_error', {
                  platform: this.DOWNLOAD_SOURCES.find((s) => s.key === this.selectedSource)?.displayName,
              });
    }

    getErrorLinkText(): string {
        return this.selectedSource === ClientsSource.MALOU
            ? this._translate.instant('clients.import_modal.format_error_link_malou')
            : this._translate.instant('clients.import_modal.format_error_link');
    }

    onFileChange(event: Event): void {
        this.requiredHeaders = '';
        const eventTarget = event.target as HTMLInputElement;
        const file = event[0] || eventTarget.files?.[0];
        this.uploadedFile = file;
        if (eventTarget) {
            eventTarget.value = '';
        }
        if (this.uploadedFile) {
            this._spinnerService.show();
            this._clientsService
                .parseAndCheckFile(this.data.restaurantId, this.selectedSource, this.uploadedFile)
                .pipe(
                    tap((res) => {
                        if (res?.data) {
                            this.clients = res.data.clients?.filter((c) => c.email || c.phone)?.map((c) => new Client(c));
                            this.isValidFile = res.data.isValid;
                            this.clients = res.data.clients?.filter((c) => c.email || c.phone)?.map((c) => new Client(c));
                            this.fileLength = this.clients.length;
                            this.fileError = res.data.err || null;
                            this.firstRows = this.clients?.slice(0, 2);
                        } else {
                            this.isValidFile = false;
                        }
                    }),
                    switchMap(() => this._clientsService.getDuplicateClients(this.data.restaurantId, this.clients))
                )
                .subscribe({
                    next: (res) => {
                        const clientsWithIsDuplicated = this._mapDuplicateClients(this.clients, res.data);
                        this.dataSource.data = sortBy(clientsWithIsDuplicated, (c) => !c.isDuplicated);
                        this.nbDuplicatedClients = clientsWithIsDuplicated.filter((c) => c.isDuplicated).length;
                        if (this.nbDuplicatedClients > 0) {
                            this.isCheckedDuplicatedClients = false;
                        }
                        this.isFileUploaded = true;
                        this._spinnerService.hide();
                    },
                    error: (err) => {
                        console.warn('err :>>', err);
                        this._spinnerService.hide();
                        if (err.status === 403) {
                            return;
                        }
                        this._toastService.openErrorToast(`${this.getFileErrorText()} ${this.getErrorLinkText()} `);
                        this.isFileUploaded = true;
                        this.isValidFile = false;
                        if (err?.error?.malouErrorCode?.match(MalouErrorCode.CLIENT_LANGUAGE_NOT_FOUND__REQUIRED_HEADERS)) {
                            this.requiredHeaders = err.error.message.split('::').at(-1);
                        }
                    },
                });
        } else {
            this.isFileUploaded = false;
        }
    }

    toogleAcceptContact(event: MatCheckboxChange): void {
        this.allClientsAcceptContact = event.checked;
    }

    upload(): void {
        this._spinnerService.show();

        let upload$: Observable<ApiResult<any>>;

        const [duplicatedClients, newClients] = partition(this.dataSource.data, (c) => c.isDuplicated);

        if (!this.isCheckedDuplicatedClients) {
            upload$ = concat(...this._chunkUpload(newClients, UploadMethod.Import));
        } else {
            const concatenedImportAndUpdate = this._chunkUpload(duplicatedClients, UploadMethod.Update).concat(
                this._chunkUpload(newClients, UploadMethod.Import)
            );
            upload$ = concat(...concatenedImportAndUpdate);
        }

        upload$.pipe(reduce((acc, val) => acc.concat(val.data), [])).subscribe({
            next: (data) => {
                this._spinnerService.hide();
                this._dialogRef.close(data);
            },
            error: (err) => {
                this._spinnerService.hide();
                console.warn('err :>>', err);
                this._malouDialogService.open({
                    variant: DialogVariant.ERROR,
                    title: this._httpErrorPipe.transform(err),
                    message: this._httpErrorPipe.transform(err),
                    primaryButton: {
                        label: this._translate.instant('common.return'),
                    },
                });
            },
        });
    }

    clearFile(): void {
        this.uploadedFile = null;
        this.isFileUploaded = false;
        this.isValidFile = false;
        this.fileLength = null;
        this.dataSource.data = [];
        this.clients = [];
        this.nbDuplicatedClients = 0;
    }

    next(): void {
        this._myStepper.next();
    }

    onDragEnter(): void {
        this.isDragOver = true;
    }

    onDragLeave(event: DragEvent): void {
        const dragArea = document.getElementById('dragarea');
        if (dragArea && dragArea.contains(event.relatedTarget as Node)) {
            return;
        }
        this.isDragOver = false;
    }

    onToggleDuplicatedClients(): void {
        this.isCheckedDuplicatedClients = !this.isCheckedDuplicatedClients;
    }

    private _mapDuplicateClients(
        clients: Client[],
        duplicatedClients: Client[]
    ): Array<
        Partial<Client> & {
            isDuplicated: boolean;
        }
    > {
        return clients.map((c) => {
            const client = duplicatedClients.find((duplicatedClient) => {
                if (duplicatedClient.email && c.email && duplicatedClient.email === c.email) {
                    return true;
                }
                if (
                    duplicatedClient.phone &&
                    c.phone &&
                    duplicatedClient.phone.digits === c.phone.digits &&
                    duplicatedClient.phone.prefix === c.phone.prefix
                ) {
                    return true;
                }
                return false;
            });
            return {
                ...c,
                isDuplicated: !!client,
                _id: client?._id,
            };
        });
    }

    private _chunkUpload(
        clients: (Partial<Client> & {
            isDuplicated: boolean;
        })[],
        chunkFor: UploadMethod,
        chunkSize = 500
    ): Observable<
        ApiResult<
            | BulkWriteResult
            | {
                  imported: Client[];
                  duplicates: Client[];
              }
        >
    >[] {
        const chunks: Observable<
            ApiResult<
                | BulkWriteResult
                | {
                      imported: Client[];
                      duplicates: Client[];
                  }
            >
        >[] = [];
        for (let i = 0; i < clients.length; i += chunkSize) {
            const chunk = clients.slice(i, i + chunkSize);
            const mappedToClients = chunk.map((c) => new Client(c));
            switch (chunkFor) {
                case UploadMethod.Update:
                    chunks.push(this._clientsService.updateDuplicates(mappedToClients, this.data.restaurantId));
                    break;
                case UploadMethod.Import:
                    chunks.push(this._clientsService.importClientsFile(mappedToClients));
                    break;
                default:
                    throw new Error('chunkFor is not valid');
            }
        }
        return chunks;
    }
}
