<div class="malou-dialog malou-dialog-mobile">
    <div class="malou-dialog__header">
        <span class="max-w-[80%]">
            {{ 'keywords.evolution_cell_keyword' | translate: { text: keywordText } }}
        </span>
        <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
    </div>
    <div class="malou-dialog__body">
        <div class="flex items-center justify-between">
            <span class="malou-text-14--semibold mx-2 max-w-[80%]"> "{{ keywordText }}" </span>
            <mat-button-toggle-group class="malou-group-toggle-btn md:!hidden">
                <mat-button-toggle [checked]="showOnlyPeriod" (click)="changePeriod()">
                    {{ 'keywords.during_period' | translate }}
                </mat-button-toggle>
                <mat-button-toggle [checked]="!showOnlyPeriod" (click)="changePeriod(true)">
                    {{ 'keywords.from_beginning' | translate }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        @if (!isLoading) {
            <div class="p-4">
                @if (!!positions.length) {
                    <div class="p-4">
                        @if (!httpError) {
                            <div class="p-4">
                                <app-keyword-evolution-chart [positions]="positions"></app-keyword-evolution-chart>
                            </div>
                        } @else {
                            <div class="flex min-h-[364px] flex-col items-center justify-center py-6">
                                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                                <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
                            </div>
                        }
                    </div>
                } @else {
                    <div class="flex min-h-[364px] flex-col items-center justify-center py-6">
                        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                        <span class="malou-text-14--bold mb-2">{{ 'keywords.evolution_error' | translate }}</span>
                    </div>
                }
            </div>
        } @else {
            <app-skeleton skeletonClass="!h-[300px] secondary-bg"></app-skeleton>
        }
    </div>
</div>
