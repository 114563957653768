import { CommonModule, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    effect,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { catchError, combineLatest, EMPTY, filter, forkJoin, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { AggregationTimeScale, isNotNil, MalouMetric, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { InsightsService } from ':modules/statistics/insights.service';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, InsightsByPlatformByRestaurant, Restaurant, TimeScaleToMetricToDataValues } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import * as AggregatedStatisticsActions from '../../store/aggregated-statistics.actions';
import { PlatformFilterPage } from '../../store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from '../../store/aggregated-statistics.selectors';

interface RatingKpisEvolution {
    currentRating?: number;
    previousRating?: number;
    diffRating?: number;
    hasData: boolean;
}

interface RestaurantRatingKpis {
    restaurant: {
        _id: string;
        name: string;
    };
    platformKpis: { [key in PlatformKey]?: RatingKpisEvolution };
}

enum RatingKpisTableColumns {
    RESTAURANT = 'restaurant',
}

@Component({
    selector: 'app-reviews-rating-kpis',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        NumberEvolutionComponent,
        PlatformLogoPathResolverPipe,
        SkeletonComponent,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        StatisticsHttpErrorPipe,
        TranslateModule,
        MatSortModule,
        NgTemplateOutlet,
        MatTableModule,
        ApplyPurePipe,
        NgClass,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    templateUrl: './reviews-rating-kpis.component.html',
    styleUrls: ['./reviews-rating-kpis.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsRatingKpisComponent implements OnInit, KillSubscriptions {
    @Output() displayedColumnsCount: EventEmitter<number> = new EventEmitter<number>();
    @Input() tableSortOptions: Sort | undefined = undefined;
    @Output() tableSortOptionsChange = new EventEmitter<Sort>();
    @Output() hasDataChange: EventEmitter<boolean> = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    platformKeys$: Observable<PlatformKey[]> = this._store.select(
        AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter);
    restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;

    readonly RESTAURANT_COLUMN = RatingKpisTableColumns.RESTAURANT;
    dataSource: MatTableDataSource<RestaurantRatingKpis> = new MatTableDataSource<RestaurantRatingKpis>([]);
    displayedColumns: WritableSignal<(RatingKpisTableColumns | PlatformKey)[]> = signal([this.RESTAURANT_COLUMN]);

    isLoading: WritableSignal<boolean> = signal(true);
    httpError: WritableSignal<string | undefined> = signal(undefined);
    areAllRestaurantsKpisEmpty: WritableSignal<boolean> = signal(false);
    selectedPlatforms: PlatformKey[] = [];
    highestEvolution: number;
    worstRegression: number;

    restaurants: Restaurant[] = [];
    defaultSort: Sort = { active: this.RESTAURANT_COLUMN, direction: 'asc' };
    constructor(
        private readonly _store: Store,
        private readonly _translateService: TranslateService,
        private readonly _insightsService: InsightsService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                const { active, direction } = sort;
                this.tableSortOptionsChange.emit({ active, direction });
                switch (property) {
                    case this.RESTAURANT_COLUMN:
                        return item[property].name;
                    default:
                        return item.platformKpis[property]?.currentRating ?? 0;
                }
            };
            this.dataSource.sort = sort;
        }
    }

    ngOnInit(): void {
        combineLatest([this.restaurants$, this.dates$, this.platformKeys$])
            .pipe(
                filter(
                    ([restaurants, dates, platforms]) => isDateSetOrGenericPeriod(dates) && platforms.length > 0 && !!restaurants.length
                ),
                tap(() => {
                    this._reset();
                }),
                map(([restaurants, dates, platforms]) => [restaurants.filter((e) => !e.isBrandBusiness()), dates, platforms]),
                switchMap(([restaurants, dates, platforms]: [Restaurant[], DatesAndPeriod, PlatformKey[]]) => {
                    this.restaurants = restaurants;
                    const restaurantIds = restaurants.map((e) => e._id);
                    const { startDate, endDate } = dates;
                    this.selectedPlatforms = platforms;

                    const allPlatformsWithRating = PlatformDefinitions.getPlatformKeysWithRating();
                    const connectedPlatformsWithRating = platforms.filter((platform) => allPlatformsWithRating.includes(platform));

                    return forkJoin([
                        this._insightsService
                            .getInsights({
                                restaurantIds,
                                startDate,
                                endDate,
                                platformsKeys: connectedPlatformsWithRating,
                                metrics: [MalouMetric.PLATFORM_RATING],
                                aggregators: [AggregationTimeScale.BY_DAY],
                            })
                            .pipe(
                                map((res) => res.data),
                                catchError((error) => {
                                    this.httpError.set(error);
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        this._insightsService
                            .getInsights({
                                restaurantIds,
                                startDate,
                                endDate,
                                platformsKeys: connectedPlatformsWithRating,
                                metrics: [MalouMetric.PLATFORM_RATING],
                                aggregators: [AggregationTimeScale.BY_DAY],
                                previousPeriod: true,
                            })
                            .pipe(
                                map((res) => res.data),
                                catchError((error) => {
                                    this.httpError.set(error);
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                    ]);
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([currentRestaurantRatings, previousRestaurantRatings]: [
                    InsightsByPlatformByRestaurant,
                    InsightsByPlatformByRestaurant,
                ]) => {
                    this._store.dispatch(
                        AggregatedStatisticsActions.editPlatformsRatingsByRestaurantData({ data: cloneDeep(currentRestaurantRatings) })
                    );
                    const dataSource = this._getDataSource(currentRestaurantRatings, previousRestaurantRatings);
                    if (!dataSource?.length) {
                        this.areAllRestaurantsKpisEmpty.set(true);
                        this.isLoading.set(false);
                        this.hasDataChange.emit(false);
                        return;
                    }
                    this.dataSource = new MatTableDataSource<RestaurantRatingKpis>(dataSource || []);
                    this.displayedColumns.set([this.RESTAURANT_COLUMN, ...this._getPlatformsWithData(dataSource)]);
                    this._emitDisplayedColumnCount(this.displayedColumns().length);
                    this._initDataSourceSortOptions(this.tableSortOptions);

                    const highestEvolutionAndRegression = this._getHighestEvolutionAndRegression(dataSource);
                    this.highestEvolution = highestEvolutionAndRegression.highestEvolution;
                    this.worstRegression = highestEvolutionAndRegression.worstRegression;
                    this.isLoading.set(false);
                },
            });
    }

    getTableColumnDisplayName = (column: RatingKpisTableColumns | PlatformKey): string => {
        if (this._isRestaurantColumn(column)) {
            return this._translateService.instant('statistics.e_reputation.reviews_kpis.restaurants');
        }
        return PlatformDefinitions.getPlatformDefinition(column)?.fullName ?? '';
    };

    private _initDataSourceSortOptions(sortOptions: Sort | undefined): void {
        if (sortOptions) {
            this.defaultSort = sortOptions;
        }
    }

    private _isRestaurantColumn(column: RatingKpisTableColumns | PlatformKey): boolean {
        return column === this.RESTAURANT_COLUMN;
    }

    private _getDataSource(
        currentRestaurantRatings: InsightsByPlatformByRestaurant,
        previousRestaurantRatingd: InsightsByPlatformByRestaurant
    ): RestaurantRatingKpis[] | null {
        if (!Object.keys(currentRestaurantRatings)?.length) {
            return null;
        }
        return this.restaurants
            .map((restaurant) => {
                const currentRestaurantRating = currentRestaurantRatings[restaurant._id];
                const previousRestaurantRating = previousRestaurantRatingd[restaurant._id];

                if (!currentRestaurantRating) {
                    return null;
                }

                const restaurantDataSource: RestaurantRatingKpis = {
                    restaurant: {
                        _id: restaurant._id,
                        name: restaurant.internalName ?? restaurant.name,
                    },
                    platformKpis: {},
                };

                this.selectedPlatforms.forEach((platformKey) => {
                    const currentPlatformKpis = currentRestaurantRating[platformKey];
                    const previousPlatformKpis = previousRestaurantRating?.[platformKey];
                    restaurantDataSource.platformKpis[platformKey] = this._getPlatformRatingEvolution(
                        currentPlatformKpis,
                        previousPlatformKpis
                    );
                });
                return restaurantDataSource;
            })
            .filter(isNotNil);
    }

    private _getPlatformRatingEvolution(currentPlatformRating, previousPlatformRating): RatingKpisEvolution {
        const currentRating = this._getPlatformLastRating(currentPlatformRating);
        if (!currentPlatformRating || !currentRating) {
            return { hasData: false };
        }
        const previousRating = this._getPlatformLastRating(previousPlatformRating);
        return {
            currentRating,
            previousRating,
            diffRating: previousRating ? currentRating - previousRating : undefined,
            hasData: true,
        };
    }

    private _getPlatformLastRating(platformRatings: TimeScaleToMetricToDataValues | undefined): number | undefined {
        if (platformRatings?.error) {
            return undefined;
        }
        const ratings = platformRatings?.[AggregationTimeScale.BY_DAY]?.[MalouMetric.PLATFORM_RATING];
        if (!ratings?.length) {
            return undefined;
        }
        return ratings.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).filter((rating) => !!rating.value)[0]?.value;
    }

    private _getHighestEvolutionAndRegression(restaurantRatingKpis: RestaurantRatingKpis[]): {
        highestEvolution: number;
        worstRegression: number;
    } {
        return restaurantRatingKpis.reduce(
            (acc, restaurantKpis) => {
                const restaurantHighestEvolution = this._getEvolutionsForRestaurant(restaurantKpis.platformKpis);
                return {
                    highestEvolution: Math.max(restaurantHighestEvolution.highestEvolution, acc.highestEvolution),
                    worstRegression: Math.min(restaurantHighestEvolution.worstRegression, acc.worstRegression),
                };
            },
            { highestEvolution: 0, worstRegression: 0 }
        );
    }

    private _getEvolutionsForRestaurant(platformsKpis: { [key in PlatformKey]?: RatingKpisEvolution }): {
        highestEvolution: number;
        worstRegression: number;
    } {
        return Object.values(platformsKpis).reduce(
            (acc, platformKpi) => ({
                highestEvolution: Math.max(platformKpi?.diffRating || 0, acc.highestEvolution),
                worstRegression: Math.min(platformKpi?.diffRating || 0, acc.worstRegression),
            }),
            { highestEvolution: 0, worstRegression: 0 }
        );
    }

    private _getPlatformsWithData(dataSource: RestaurantRatingKpis[]): PlatformKey[] {
        return this.selectedPlatforms.filter((platformKey) =>
            dataSource.some((restaurantKpis) => restaurantKpis.platformKpis[platformKey]?.hasData)
        );
    }

    private _emitDisplayedColumnCount(count: number): void {
        this.displayedColumnsCount.emit(count);
    }

    private _reset(): void {
        this.httpError.set(undefined);
        this.areAllRestaurantsKpisEmpty.set(false);
        this.isLoading.set(true);
    }
}
