<div class="flex flex-col gap-4">
    <div class="malou-text-14--bold text-malou-color-text-1">
        {{ 'informations.optimization' | translate }}
    </div>

    <div class="w-[70%] sm:w-full">
        <app-loader-progress
            backgroundColor="bg-malou-color-background-white"
            [progressBarColorClass]="progressBarColorClass()"
            [progress]="scoreGauge"></app-loader-progress>
        @if (scoreGauge() === 100) {
            <span class="malou-text-10--regular mt-2 italic text-malou-color-text-2">
                {{ 'informations.gauge.completed' | translate }}
            </span>
        } @else {
            <button class="malou-btn-flat !malou-text-10--semibold sm:mt-2 sm:self-center" (click)="openInformationsModal()">
                {{ ctaText() }}
            </button>
        }
    </div>
</div>
