import { CaslRole, Role } from '@malou-io/package-utils';

import { Media, Restaurant } from ':shared/models';
import { Organization } from ':shared/models/organization';

export interface UserRestaurant {
    _id: string;
    restaurantId: string;
    userId: string;
    user: User;
    restaurant: Restaurant;
    gmbCredentialId: string;
    fbCredentialId: string;
    displayPermissionsModal: boolean;
    caslRole: CaslRole;
    displayHashtagsModal: boolean;
    displaySyncWarningModal?: boolean;
    displaySemanticAnalyses: boolean;
}

export interface MessagesNotificationsSettings {
    active: boolean;
    restaurantsIds?: string[];
    restaurants?: Partial<Restaurant>[];
}

export enum FrontendVersion {
    V2 = 'V2',
    V3 = 'V3',
}

export class User {
    _id: string;
    id: string;
    name: string;
    lastname: string;
    profilePicture: Media;
    role: Role;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    restaurants: any[] = [];
    verified: boolean;
    defaultLanguage: string;
    organizations: Organization[];
    organizationIds: string[];
    caslRole: CaslRole;
    password?: string;
    fullname?: string;
    settings?: UserSettings;
    preferredFrontendVersion: FrontendVersion;
    hasV3Access: boolean;
    shouldShowLegacyRestaurantSettingsModal: boolean;

    public constructor(init?: Partial<User>) {
        Object.assign(this, init);
        this.fullname = this.getFullname();
        this.id = init?.id ?? init?._id ?? this._id;
    }

    getFullname(): string {
        return [this.name, this.lastname].filter(Boolean).join(' ');
    }

    hasRestaurant(id: string): boolean {
        return this.restaurants.includes(id);
    }

    isAdmin(): boolean {
        return this.role === Role.ADMIN;
    }
}

interface UserSettings {
    receiveFeedbacks: boolean;
    receiveMessagesNotifications: { active: boolean; restaurantsIds: string[] };
    notificationSettings: {
        userDevicesTokens: string[];
        active: boolean;
        reviews: {
            active: boolean;
            realtime: boolean;
            receivingWeekDays: number[];
            concernedRatings: number[];
            includeAutoRepliedReviews: boolean;
        };
        messages: { active: boolean; realtime: boolean; receivingWeekDays: number[] };
        posts: { noMoreScheduledPosts: { active: boolean; lastNotificationSentDate: Date }; publishError: { active: boolean } };
    };
    notifications: {
        email: {
            reviewReplyReminder: {
                active: boolean;
            };
        };
    };
}

export class UserFactory {
    static createTestUser(): User {
        return new User({
            _id: 'some user id',
            name: 'Quentin',
            lastname: 'Crescent',
            profilePicture: undefined,
            role: Role.ADMIN,
            email: 'test-email@malou.io',
            createdAt: new Date(),
            updatedAt: new Date(),
            restaurants: [],
        });
    }
}

export interface SimpleUserInterface {
    _id: string;
    name?: string;
    picture?: string;
}
