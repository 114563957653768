@if (restaurantHours) {
    <div class="w-full">
        @for (day of DAYS; track day) {
            <div class="malou-row flex flex-col">
                <div class="flex flex-row">
                    <div class="malou-color-text-2 malou-text-13--medium min-w-[120px]">
                        <span class="malou-vertical-middle">{{ day | enumTranslate: 'days' | titlecase }}</span>
                    </div>
                    <div class="flex">
                        @if (!restaurantHours[day]) {
                            <span> {{ 'information.business_hours.not_specified' | translate }}</span>
                        }
                        <div class="flex flex-wrap">
                            @for (element of restaurantHours[day]; track element; let isLast = $last) {
                                <span class="ml-1.5">
                                    @if (!!element) {
                                        <span class="malou-text-12--regular whitespace-pre break-keep">
                                            {{
                                                element.isClosed
                                                    ? ('information.business_hours.closed' | translate)
                                                    : element.openTime === '00:00' && element.closeTime === '24:00'
                                                      ? ('common.open_24h' | translate)
                                                      : ((element.openTime === '24:00' ? '00:00' : (element.openTime ?? '')) | formatTime) +
                                                        ' - ' +
                                                        ((element.closeTime === '24:00' ? '00:00' : (element.closeTime ?? '')) | formatTime)
                                            }}
                                        </span>
                                    } @else {
                                        <span>{{ 'information.business_hours.not_specified' | translate }}</span>
                                    }
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
}
