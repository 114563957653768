import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    Injector,
    Input,
    OnInit,
    runInInjectionContext,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, isNil } from 'lodash';

import {
    APP_DEFAULT_LANGUAGE,
    errorReplacer,
    isNotNil,
    PlatformKey,
    TimeInMilliseconds,
    WheelOfFortuneRedirectionPlatformKey,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { PlatformsService } from ':core/services/platforms.service';
import { ScansService } from ':core/services/scans.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { LightRestaurant, NfcSnapshot, Platform } from ':shared/models';
import { WheelOfFortune, WheelOfFortuneState } from ':shared/models/wheel-of-fortune';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { CustomDialogService, DialogScreenSize } from ':shared/services/custom-dialog.service';

import { WheelOfFortuneMessage } from '../wheel-of-fortune-messages/wheel-of-fortune-messages.component';
import {
    DisplayDrawDetailsModalComponent,
    DisplayDrawDetailsModalInput,
} from './display-draw-details-modal/display-draw-details-modal.component';
import { LeaveReviewWheelOfFortuneModalComponent } from './leave-review-wheel-of-fortune-modal/leave-review-wheel-of-fortune-modal.component';
import { PlayWheelOfFortuneComponent } from './play-wheel-of-fortune/play-wheel-of-fortune.component';
import { RedirectWheelOfFortuneMobileComponent } from './redirect-wheel-of-fortune-mobile/redirect-wheel-of-fortune-mobile.component';
import { SelectRestaurantWheelOfFortuneModalComponent } from './select-restaurant-wheel-of-fortune-modal/select-restaurant-wheel-of-fortune-modal.component';

enum PlayWheelStep {
    LEAVE_REVIEW = 'leave_review',
    START_WHEEL = 'start_wheel',
    WHEEL_TURNING = 'wheel_turning',
}

@Component({
    selector: 'app-play-wheel-of-fortune-root',
    templateUrl: './play-wheel-of-fortune-root.component.html',
    styleUrls: ['./play-wheel-of-fortune-root.component.scss'],
    imports: [
        NgTemplateOutlet,
        NgStyle,
        NgClass,
        MatIconModule,
        ImagePathResolverPipe,
        ApplySelfPurePipe,
        TranslateModule,
        MalouSpinnerComponent,
        PlayWheelOfFortuneComponent,
        RedirectWheelOfFortuneMobileComponent,
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayWheelOfFortuneRootComponent implements OnInit {
    @Input() isPreview: WritableSignal<boolean> = signal(false);
    @Input() wheelOfFortunePreview: Signal<WheelOfFortune>;

    readonly SvgIcon = SvgIcon;

    PlayWheelStep = PlayWheelStep;

    readonly currentPlayWheelStep: WritableSignal<PlayWheelStep> = signal(PlayWheelStep.LEAVE_REVIEW);
    readonly wheelOfFortune: WritableSignal<WheelOfFortune | null> = signal(null);
    readonly selectedRestaurantId: WritableSignal<string | null> = signal(null);
    readonly selectedRestaurant: Signal<LightRestaurant | undefined> = computed(() =>
        this.wheelOfFortune()?.restaurants.find((rest) => rest.id === this.selectedRestaurantId())
    );
    readonly displayedWheelOfFortune: Signal<WheelOfFortune | null> = computed(() =>
        this.isPreview() ? this.wheelOfFortunePreview() : this.wheelOfFortune()
    );
    readonly lang: string = localStorage.getItem(LocalStorageKey.LANG) || APP_DEFAULT_LANGUAGE;
    readonly isCtaLoading: WritableSignal<boolean> = signal(false);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });
    readonly logo: Signal<string | null> = computed(() => this.wheelOfFortune()?.getLogoUrl() ?? null);

    readonly isInitialized = signal(false);

    private _scanId: string;
    private readonly _hasEmptyStocks: Signal<boolean> = computed(() => {
        if (!this.selectedRestaurantId()) {
            return false;
        }
        return (
            this.wheelOfFortune()?.gifts.every(
                (gift) => gift.stocks.find((stock) => stock.restaurant.id === this.selectedRestaurantId())?.quantity === 0
            ) ?? false
        );
    });

    private readonly _redirectionLink: WritableSignal<string | null> = signal(null);
    private readonly _scannedRestaurantId: string | null;
    private readonly _wofId: string;
    private readonly _isFromTotem: boolean = false;
    private readonly _isAlreadySavingScan: WritableSignal<boolean> = signal(false);
    private readonly _shouldRetrySaveWithScreenSize: WritableSignal<boolean> = signal(false);

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _platformsService: PlatformsService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _scansService: ScansService,
        private readonly _injector: Injector
    ) {
        this._scannedRestaurantId = this._route.snapshot.queryParams.restaurantId;
        this._wofId = this._route.snapshot.queryParams.wofId;
        this._isFromTotem = this._route.snapshot.queryParams.isFromTotem === 'true';
    }

    ngOnInit(): void {
        runInInjectionContext(this._injector, () => {
            effect(() => this._initializeRedirectionLink(), { allowSignalWrites: true });
        });

        runInInjectionContext(this._injector, () => {
            effect(
                () => {
                    if (isNotNil(this.isPhoneScreen()) && this._shouldRetrySaveWithScreenSize()) {
                        this._saveScanIfNotFromTotems();
                    }
                },
                { allowSignalWrites: true }
            );
        });

        runInInjectionContext(this._injector, () => {
            effect(() =>
                this._hasEmptyStocks() ? this._redirectToWheelOfFortuneNotifications(WheelOfFortuneMessage.WHEEL_OF_FORTUNE_EXPIRED) : null
            );
        });

        if (this.isPreview()) {
            this.currentPlayWheelStep.set(PlayWheelStep.START_WHEEL);
            this.isInitialized.set(true);
            return;
        }

        if (!this._wofId) {
            this._redirectOnError();
            return;
        }

        this._wheelsOfFortuneService.getWheelOfFortuneById(this._wofId).subscribe({
            next: async (res) => {
                this.isInitialized.set(true);
                if (res.data) {
                    this.wheelOfFortune.set(new WheelOfFortune(res.data));
                    if (this._scannedRestaurantId) {
                        this.selectedRestaurantId.set(this._scannedRestaurantId);
                        if (!this.selectedRestaurant()) {
                            this._router.navigate(['./wheel-of-fortune-not-found']);
                            return;
                        }
                        this._saveScanIfNotFromTotems();
                        await this._redirectIfAlreadyPlayed();
                    } else if (this.wheelOfFortune()?.restaurants?.length === 1) {
                        this.selectedRestaurantId.set(this.wheelOfFortune()?.restaurants?.[0].id ?? null);
                        this._saveScanIfNotFromTotems();
                        await this._redirectIfAlreadyPlayed();
                    }

                    if (this._shouldSkipLeaveReviewStep()) {
                        this.currentPlayWheelStep.set(PlayWheelStep.START_WHEEL);
                    }

                    const hasGifts = this.wheelOfFortune()?.gifts?.length;
                    const hasRestaurants = this.wheelOfFortune()?.restaurants?.length || this.selectedRestaurantId();
                    const wheelOfFortuneState = this.wheelOfFortune()?.getState();

                    if (wheelOfFortuneState === WheelOfFortuneState.PROGRAMMED) {
                        return this._redirectToWheelOfFortuneNotifications(WheelOfFortuneMessage.WHEEL_OF_FORTUNE_PROGRAMMED);
                    }

                    if (wheelOfFortuneState === WheelOfFortuneState.INACTIVE) {
                        return this._redirectToWheelOfFortuneNotifications(WheelOfFortuneMessage.WHEEL_OF_FORTUNE_EXPIRED);
                    }

                    if (!hasGifts || !hasRestaurants) {
                        this.wheelOfFortune.set(null);
                        return this._redirectOnError(this._translateService.instant('play_wheel_of_fortune.errors.error_getting_wof'));
                    } else {
                        this._saveScanIfNotFromTotems();
                        await this._redirectIfAlreadyPlayed();
                    }
                }
            },
            error: () => this._redirectOnError(this._translateService.instant('play_wheel_of_fortune.errors.error_getting_wof')),
        });
    }

    openSelectRestaurantModal(): void {
        if (!this.wheelOfFortune()?.restaurants) {
            return;
        }
        this._customDialogService
            .open(SelectRestaurantWheelOfFortuneModalComponent, {
                width: '100%',
                height: undefined,
                panelClass: ['malou-dialog-panel', 'malou-dialog-panel--fit-content'],
                data: {
                    restaurants: this.wheelOfFortune()?.restaurants,
                },
            })
            .afterClosed()
            .subscribe({
                next: async (res) => {
                    if (res.restaurantId) {
                        this.selectedRestaurantId.set(res.restaurantId);
                        if (this._shouldSkipLeaveReviewStep()) {
                            this.currentPlayWheelStep.set(PlayWheelStep.START_WHEEL);
                        }
                        this._saveScanIfNotFromTotems();
                        await this._redirectIfAlreadyPlayed();
                    }
                },
            });
    }

    openLeaveReviewModal(): void {
        if (!this._redirectionLink()) {
            if (!this.isCtaLoading()) {
                this._redirectOnError(this._translateService.instant('play_wheel_of_fortune.errors.errors_getting_link'));
            }
            return;
        }
        this._customDialogService
            .open(LeaveReviewWheelOfFortuneModalComponent, {
                width: '100%',
                height: undefined,
                panelClass: ['malou-dialog-panel', 'malou-dialog-panel--fit-content', '!min-h-[70vh]'],
                data: {
                    wheelOfFortuneId: this.wheelOfFortune()?.id,
                    restaurantId: this.selectedRestaurantId(),
                    primaryColor: this.wheelOfFortune()?.parameters.primaryColor,
                    secondaryColor: this.wheelOfFortune()?.parameters.secondaryColor,
                    redirectionLink: this._redirectionLink(),
                    redirectionPlatform: this.wheelOfFortune()?.parameters.redirectionPlatform,
                },
            })
            .afterClosed()
            .subscribe({
                next: (res) => {
                    if (res.hasLeftReview) {
                        this.currentPlayWheelStep.set(PlayWheelStep.START_WHEEL);
                        if (this._scanId) {
                            this._patchScan(this._scanId);
                        }
                    }
                },
            });
    }

    spinWheel(): void {
        const wheelAnimationDurationBeforeOpeningModal = 5 * TimeInMilliseconds.SECOND;
        setTimeout(() => {
            this._openDisplayDrawDetailsModal();
        }, wheelAnimationDurationBeforeOpeningModal);
    }

    openGameRules(): void {
        window.open(
            `${window.location.origin}/wheel-of-fortune-rules?name=${
                this.selectedRestaurant()?.name
            }&address=${this.selectedRestaurant()?.address?.getDisplayedValue()}`,
            '_blank'
        );
    }

    private _saveScanIfNotFromTotems(): void {
        if (this._isFromTotem || this._isAlreadySavingScan()) {
            return;
        }
        if (isNil(this.isPhoneScreen())) {
            this._shouldRetrySaveWithScreenSize.set(true);
            return;
        }
        this._shouldRetrySaveWithScreenSize.set(false);
        if (this.isPhoneScreen() && this.selectedRestaurantId()) {
            this._createScan();
        }
    }

    private _createScan(): void {
        if (this._isAlreadySavingScan()) {
            return;
        }
        this._isAlreadySavingScan.set(true);
        const restaurantId = this.selectedRestaurantId();
        if (!restaurantId) {
            return;
        }
        const wheelOfFortuneRedirectionLink = this.wheelOfFortune()?.getWheelOfFortuneUrlForRestaurant({
            restaurantId,
            isFromTotem: false,
        });
        if (!wheelOfFortuneRedirectionLink) {
            return;
        }
        const wheelOfFortunePlatformKey = this.wheelOfFortune()?.parameters.redirectionPlatform ?? PlatformKey.GMB;
        const fakeNfcForWheelOfFortune = NfcSnapshot.createWheelOfFortuneNfcSnapshot({
            restaurantId,
            redirectionLink: wheelOfFortuneRedirectionLink,
            platformKey: wheelOfFortunePlatformKey,
        }).toNfcSnapshotDto();
        this._scansService
            .create({
                nfcId: fakeNfcForWheelOfFortune.id,
                scannedAt: new Date().toISOString(),
                nfcSnapshot: fakeNfcForWheelOfFortune,
            })
            .subscribe({
                next: (res) => {
                    this._scanId = res.data?.id;
                },
                error: (e) => {
                    console.error('Error when creating scan', JSON.stringify(e, errorReplacer));
                },
            });
    }

    private _patchScan(scanId: string): void {
        this._scansService
            .patch(scanId, {
                redirectedAt: new Date().toISOString(),
            })
            .subscribe({
                error: (e) => {
                    console.error('Error when creating scan', JSON.stringify(e, errorReplacer));
                },
            });
    }

    private _initializeRedirectionLink(): void {
        const restaurantId = this.selectedRestaurantId();
        const platformKey = this.wheelOfFortune()?.parameters.redirectionPlatform;
        if (!platformKey || !restaurantId || platformKey === WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION) {
            return;
        }
        this.isCtaLoading.set(true);
        this._platformsService.getPlatform(platformKey, restaurantId).subscribe({
            next: (res) => {
                if (res?.data) {
                    const platform = new Platform(res.data);
                    if (!platform.getNfcRedirectionLink() && platformKey !== PlatformKey.GMB && this.wheelOfFortune()) {
                        this.wheelOfFortune.update((wheelOfFortune) => {
                            wheelOfFortune!.parameters.redirectionPlatform = PlatformKey.GMB;
                            return cloneDeep(wheelOfFortune);
                        });
                    } else {
                        this._redirectionLink.set(platform.getNfcRedirectionLink() ?? null);
                    }
                }
                this.isCtaLoading.set(false);
            },
            error: (err) => {
                console.error('err :>>', err);
                this._redirectOnError(this._translateService.instant('play_wheel_of_fortune.errors.errors_getting_link'));
                this.isCtaLoading.set(false);
            },
        });
    }

    private _openDisplayDrawDetailsModal(): void {
        const primaryColor = this.wheelOfFortune()?.parameters.primaryColor;
        const secondaryColor = this.wheelOfFortune()?.parameters.secondaryColor;
        const gifts = this.wheelOfFortune()?.gifts;
        const restaurant = this.selectedRestaurant();
        const wheelOfFortuneId = this.wheelOfFortune()?.id;

        if (!primaryColor || !secondaryColor || !gifts || !restaurant || !wheelOfFortuneId) {
            return;
        }

        this._customDialogService
            .open<DisplayDrawDetailsModalComponent, DisplayDrawDetailsModalInput>(
                DisplayDrawDetailsModalComponent,
                {
                    width: '100%',
                    height: undefined,
                    panelClass: ['malou-dialog-panel', 'malou-dialog-panel--fit-content', '!min-h-[80vh]'],
                    data: {
                        wheelOfFortuneId,
                        restaurant,
                        primaryColor,
                        secondaryColor,
                        gifts,
                    },
                },
                { animateScreenSize: DialogScreenSize.ALL }
            )
            .afterClosed();
    }

    private _redirectOnError(errorMessage?: string): void {
        this._router.navigate(['./wheel-of-fortune-not-found']);
        if (errorMessage) {
            this._toastService.openErrorToast(errorMessage);
        }
    }

    private async _redirectIfAlreadyPlayed(): Promise<void> {
        if (!this._canPlay()) {
            await this._router.navigate(['./wheel-of-fortune-messages'], {
                queryParams: {
                    message: WheelOfFortuneMessage.CLIENT_ALREADY_PLAYED,
                    color: this.wheelOfFortune()?.parameters.primaryColor,
                },
            });
        }
    }

    private _redirectToWheelOfFortuneNotifications(message: WheelOfFortuneMessage): void {
        const date = message === WheelOfFortuneMessage.WHEEL_OF_FORTUNE_PROGRAMMED ? this.wheelOfFortune()?.startDate : undefined;
        this._router.navigate(['./wheel-of-fortune-messages'], {
            queryParams: {
                message,
                color: this.wheelOfFortune()?.parameters.primaryColor,
                date,
            },
        });
    }

    private _shouldSkipLeaveReviewStep(): boolean {
        if (this.wheelOfFortune()?.parameters.redirectionPlatform === WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION) {
            return true;
        }
        const leavedReviewRestaurantWheelsOfFortune: { wheelOfFortuneId: string; restaurantId: string; platformKey: string }[] = JSON.parse(
            localStorage.getItem(LocalStorageKey.LEAVED_REVIEW_RESTAURANT_WHEELS_OF_FORTUNE) || '[]'
        );
        return !!leavedReviewRestaurantWheelsOfFortune.find(
            (playedWheelOfFortune) =>
                playedWheelOfFortune.wheelOfFortuneId === this.wheelOfFortune()?.id &&
                playedWheelOfFortune.restaurantId === this.selectedRestaurantId() &&
                playedWheelOfFortune.platformKey === this.wheelOfFortune()?.parameters.redirectionPlatform
        );
    }

    private _canPlay(): boolean {
        const playedRestaurantWheelsOfFortune: { wheelOfFortuneId: string; restaurantId: string }[] = JSON.parse(
            localStorage.getItem(LocalStorageKey.PLAYED_RESTAURANT_WHEELS_OF_FORTUNE) || '[]'
        );
        return !playedRestaurantWheelsOfFortune.some(
            (playedWheelOfFortune) =>
                playedWheelOfFortune.wheelOfFortuneId === this.wheelOfFortune()?.id &&
                playedWheelOfFortune.restaurantId === this.selectedRestaurantId()
        );
    }
}
