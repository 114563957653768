import { PlatformKey } from '@malou-io/package-utils';

export interface UpdateStatus {
    statusCode: number;
    success: boolean;
    message?: string;
    error?: string;
}

interface GmbUpdateStatus extends UpdateStatus {
    failedToUpdateProperties: string[];
}
interface FbUpdateStatus extends UpdateStatus {
    failedToUpdateProperties: string[];
}

const ERROR_MESSAGES = {
    phone: 'information.update_modal.phone_not_updated',
    address: 'information.update_modal.address_not_updated',
    attributes: 'information.update_modal.attributes_not_updated',
    openInfo: 'information.update_modal.open_info_not_updated',
    hours: 'information.update_modal.hours_not_updated',
    location: 'information.update_modal.address_not_updated',
    category: 'information.update_modal.category_not_updated',
};

export class OauthUpdateStatusFactory {
    static create(key: string, value: UpdateStatus): OauthUpdateStatus {
        switch (key) {
            case PlatformKey.GMB:
                return new GmbOauthUpdateStatus(key, value);
            case PlatformKey.FACEBOOK:
                return new FacebookOauthUpdateStatus(key, value);
            case PlatformKey.UBEREATS:
                return new UberEatsOauthUpdateStatus(key, value);
            default:
                throw new Error('Unknown key in OauthUpdateStatusFactory');
        }
    }
}

interface DisplayStatus {
    getInfos: () => { status: string; info: string[] };
}

abstract class OauthUpdateStatus implements DisplayStatus {
    protected key: string;
    protected value: UpdateStatus;

    constructor(key: string, value: UpdateStatus) {
        this.key = key;
        this.value = value;
    }

    abstract getInfos(): { status: string; info: string[]; failedToUpdateProperties?: string[] };
}

class GmbOauthUpdateStatus extends OauthUpdateStatus {
    protected value: GmbUpdateStatus;

    constructor(key: string, value: UpdateStatus) {
        super(key, value);
    }

    getInfos(): { status: string; info: string[]; failedToUpdateProperties: string[] } {
        let statusInfos;
        if (this.value?.success) {
            statusInfos = { status: 'updated', info: [] };
            this.value.failedToUpdateProperties.forEach((key) => {
                statusInfos = { status: 'warning', info: statusInfos.info.concat([ERROR_MESSAGES[key]]) };
            });
        } else {
            let errorInfo = '';
            if (this.value.message?.match(/Location must be verified/)) {
                errorInfo = 'information.update_modal.validate_gmb_changes';
            } else if (this.value.message?.match(/Request contains an invalid argument/)) {
                errorInfo = 'information.update_modal.invalid_argument';
            }
            statusInfos = {
                status: 'error',
                info: [errorInfo],
                failedToUpdateProperties: this.value.failedToUpdateProperties,
            };
        }
        return statusInfos;
    }
}

class FacebookOauthUpdateStatus extends OauthUpdateStatus {
    protected value: FbUpdateStatus;

    constructor(key: string, value: UpdateStatus) {
        super(key, value);
    }

    getInfos(): { status: string; info: string[] } {
        let statusInfos;
        if (this.value?.success) {
            statusInfos = { status: 'updated', info: [] };
            this.value.failedToUpdateProperties.forEach((key) => {
                statusInfos = { status: 'warning', info: statusInfos.info.concat([ERROR_MESSAGES[key]]) };
            });
        } else {
            if (this.value?.error?.match(/\[FB_CREDENTIAL\]/)) {
                statusInfos = { status: 'error', info: ['information.update_modal.reconnect_platform'] };
            } else if (this.value?.error?.match(/Permissions error/)) {
                statusInfos = { status: 'error', info: ['information.update_modal.reconnect_fb_account'] };
            } else if (this.value?.error?.match(/Two Factor Authentication/)) {
                statusInfos = { status: 'error', info: ['information.update_modal.user_does_not_have_sufficient_permissions'] };
            } else {
                statusInfos = { status: 'error', info: ['information.update_modal.update_failed', this.value.error] };
            }
        }
        return statusInfos;
    }
}

class UberEatsOauthUpdateStatus extends OauthUpdateStatus {
    constructor(key: string, value: UpdateStatus) {
        super(key, value);
    }

    getInfos(): { status: string; info: string[] } {
        let statusInfos;
        if (this.value.success) {
            statusInfos = { status: 'updated', info: [] };
        } else {
            statusInfos = { status: 'error', info: ['information.update_modal.update_failed'] };
        }
        return statusInfos;
    }
}
