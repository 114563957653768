import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationCenterContext } from '../../context/notification-center.context';
import { Notification } from '../../models/notification.model';

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, DatePipe, MatButtonModule],
})
export class NotificationItemComponent {
    readonly initialNotification = input.required<Notification>();
    readonly notificationCenterContext = inject(NotificationCenterContext);
}
