import { NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { map } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { boldenMentions } from ':shared/helpers';
import { Comment, CommentOrigin, Post } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { CommentsService } from '../comments.service';
import { selectPagination } from '../store/comments.reducer';

@Component({
    selector: 'app-comment-preview',
    templateUrl: './comment-preview.component.html',
    styleUrls: ['./comment-preview.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        SocialPostMediaComponent,
        ApplyPurePipe,
        ApplySelfPurePipe,
        AvatarPipe,
        FormatDatePipe,
        PlatformLogoPathResolverPipe,
        ShortTextPipe,
        TranslateModule,
    ],
})
export class CommentPreviewComponent implements OnInit {
    @Input() comment: Comment;
    @Input() isAnswered = false;
    @Output() archived = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    isExpanded = false;

    commentTextLimit = this.getCommentTextLimit();

    userImg: string;
    readonly boldenMentions = boldenMentions;

    currentPagination$ = this._store.select(selectPagination);
    currentPagination: { pageSize: number };

    readonly PlatformKey = PlatformKey;

    constructor(
        private readonly _postsService: PostsService,
        private readonly _commentsService: CommentsService,
        private readonly _screenService: ScreenSizeService,
        private readonly _translate: TranslateService,
        private readonly _http: HttpClient,
        private readonly _router: Router,
        private readonly _activeRoute: ActivatedRoute,
        private readonly _store: Store
    ) {
        this.currentPagination$.subscribe({
            next: (pagination) => {
                this.currentPagination = pagination;
            },
        });
    }

    ngOnInit(): void {
        // some comments had been poorly mapped, with no socialAttachment[Ø] url we need to refetch them
        if (!this.comment?.post?.socialAttachments?.[0]?.urls?.original) {
            this.refreshPost();
        }
        this.comment.post = new Post(this.comment.post);
        this.comment.post.http = this._http;
        this.comment.post.initWorkingPic('small');
        this._screenService.resize$.subscribe({
            next: () => {
                this.commentTextLimit = this.getCommentTextLimit();
            },
        });
    }

    formatTextToHtml(text: string): string {
        if (!text) {
            return text;
        }
        return text.replace(new RegExp('\n', 'g'), ' ');
    }

    refreshPost(): void {
        if (!this.comment.post?._id) {
            return;
        }
        this._postsService
            .refresh(this.comment.post._id)
            .pipe(map((res) => res.data))
            .subscribe({
                next: (post) => {
                    if (post?._id) {
                        post.http = this._http;
                        this.comment.post = new Post(post);
                        this.comment.post.initWorkingPic('small');
                    }
                },
                error: (err) => console.error('fail silently :>> ', err),
            });
    }

    toggleArchived(): void {
        this.comment.archived = !this.comment.archived;
        this._commentsService
            .updateCommentById(this.comment._id, {
                archived: this.comment.archived,
                type: this.comment.type,
            })
            .subscribe({
                error: (err) => {
                    console.warn('Error Archiving:', err);
                    this.comment.archived = !this.comment.archived;
                },
            });
        if (this.comment.archived) {
            this.archived.emit(this.comment);
        }
    }

    toggleExpanded(): void {
        this.isExpanded = !this.isExpanded;
    }

    getArchiveTooltipText = (comment: Comment): string =>
        comment.archived ? this._translate.instant('moderation.unarchive') : this._translate.instant('moderation.archive');

    getChipColor(comment: Comment): string {
        switch (comment.type) {
            case CommentOrigin.MENTION_IN_COMMENT:
                return 'malou-chip--pink-light';
            case CommentOrigin.MENTION_IN_POST:
                return 'malou-chip--warn';
            default:
                return 'malou-chip--purple-light';
        }
    }

    getChipLabel = (comment: Comment): string => {
        const label = this.getChipText(comment);
        if (this.isSmallChip()) {
            return label.slice(0, 1);
        }
        return label;
    };

    getChipText = (comment: Comment): string => this._translate.instant(`moderation.comment_origin.${comment.type}`);

    getCommentTextLimit(): number {
        if (this._screenService.isPhoneScreen) {
            return 35;
        }
        if (this._screenService.isLargeScreen) {
            return 60;
        }
        if (this._screenService.isDesktopScreen) {
            return 85;
        }
        return 180;
    }

    getIsPhoneScreen(): boolean {
        return this._screenService.isPhoneScreen;
    }

    isSmallChip(): boolean {
        return this._screenService.isLargeScreen || this._screenService.isPhoneScreen;
    }

    openAnswerModal(): void {
        // navigate to currentRoute with queryParam comment_id
        let commentId: string | undefined;
        let mentionId: string | undefined;
        if (this.comment.type === CommentOrigin.COMMENT) {
            commentId = this.comment._id;
        } else {
            mentionId = this.comment._id;
        }

        this._router.navigate([], {
            relativeTo: this._activeRoute,
            queryParams: { comment_id: commentId, page_size: this.currentPagination.pageSize, mention_id: mentionId },
            queryParamsHandling: 'merge',
        });
    }
}
