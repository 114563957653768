<div
    class="malou-card malou-card--active !m-0 !p-3 transition-all"
    [ngStyle]="{ 'background-color': story.storyStatus.backgroundColor, 'border-color': story.storyStatus.borderColor }">
    <div class="flex justify-between">
        <div class="flex">
            <div class="relative h-[105px] w-[105px]">
                <div class="absolute left-[7px] z-10">
                    <mat-checkbox color="primary" [checked]="isChecked" (change)="onPostCheckedChange($event)"></mat-checkbox>
                </div>
                <app-social-post-media
                    [customMediaClass]="'min-w-[105px] min-h-[105px] !rounded-[10px]'"
                    [postType]="story.postType"
                    [workingMedia]="story.getMalouMediaUrl()"
                    [firstAttachmentType]="story.getFirstMediumType()"
                    [showVideoControls]="false"
                    [thumbnailUrl]="story.getSocialMediaThumbnail()"
                    (loadedVideoMetadata)="onLoadedVideoMetadata($event)">
                </app-social-post-media>
            </div>
        </div>

        <div class="mx-4 flex grow flex-col p-2">
            <div class="malou-text-14--bold mb-3 flex items-center gap-x-3 text-malou-color-text-1">
                <div class="relative">
                    <div class="flex">
                        @for (platformKey of platformKeysToShow(); track platformKey; let index = $index; let first = $first) {
                            <img
                                class="malou-avatar--medium"
                                alt="logo"
                                [class.-ml-4]="!first"
                                [alt]="platformKey"
                                [src]="platformKey | platformLogoPathResolver" />
                        }
                    </div>
                </div>
                <span class="sm:!max-w-[70px] sm:overflow-clip">{{
                    story.storyStatus.type | enumTranslate: 'story_publication_status'
                }}</span>
                <mat-icon
                    class="!h-4 !w-4"
                    [matTooltip]="clearError ?? ''"
                    [ngStyle]="{ color: story.storyStatus.iconColor }"
                    [svgIcon]="story.storyStatus.icon">
                </mat-icon>
                @if (story.feedback?.feedbackMessages?.length) {
                    <span class="malou-color-warning malou-text-10--regular"
                        >{{ story.feedback?.feedbackMessages?.length }} {{ 'stories.story.feedback_not_processed' | translate }}</span
                    >
                }
            </div>
            <div class="malou-text-12--regular italic text-malou-color-text-2">
                <ng-container [ngTemplateOutlet]="story.isActive ? remainingHoursTemplate : publishedDateStatusTemplate"></ng-container>
            </div>
            @if (mediaDuration) {
                <ng-container class="mt-1">
                    <app-media-duration [duration]="mediaDuration" [post]="story"></app-media-duration>
                </ng-container>
            }
        </div>

        <div class="flex gap-x-3" [ngClass]="screenSizeService.isPhoneScreen ? 'flex-wrap justify-end' : ''">
            @if (isStoryNotPublishedYet | applyPure: story.storyStatus.type) {
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    mat-icon-button
                    [matTooltip]="'common.edit' | translate"
                    (click)="editStory()">
                    <mat-icon [svgIcon]="SvgIcon.EDIT"></mat-icon>
                </button>
            }
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [matMenuTriggerFor]="duplicateActions"
                [matTooltip]="'common.duplicate' | translate">
                <mat-icon [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
            </button>
            @if (isStoryNotPublishedYet | applyPure: story.storyStatus.type) {
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    mat-icon-button
                    [matTooltip]="'common.delete' | translate"
                    (click)="deleteStory()">
                    <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
                </button>
            }
        </div>
    </div>
</div>

<ng-template #publishedDateStatusTemplate>
    <span>
        {{ screenSizeService.isPhoneScreen ? story.storyStatus.smallSubText : story.storyStatus.subtext }}
        @if (story.author?.name && !screenSizeService.isPhoneScreen) {
            <span> {{ 'common.by' | translate }} {{ story.author?.name }}</span>
        }
    </span>
</ng-template>

<ng-template #remainingHoursTemplate>
    <span>
        {{ 'stories.story.hours_remaining' | translate: { hours: story.remainingHours } }}
    </span>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #duplicateActions="matMenu">
    <button mat-menu-item (click)="duplicateStory(DuplicationDestination.HERE)">
        <span>{{ 'common.here' | translate }}</span>
    </button>
    <button mat-menu-item (click)="duplicateStory(DuplicationDestination.OUT)">
        <span>{{ 'common.to_other_venues' | translate }}</span>
    </button>
</mat-menu>
