import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { compact } from 'lodash';
import { map } from 'rxjs/operators';

import { OrganizationsService } from ':core/services/organizations.service';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.services';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { getFormControlRecordFromDefaultValue } from ':shared/helpers/form-control-from-default-value';
import { INullableFormControlRecord } from ':shared/interfaces/form-control-record.interface';
import { Restaurant } from ':shared/models';
import { Organization } from ':shared/models/organization';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export interface RestaurantUpdateForm {
    organization?: Organization;
    users: User[];
}

interface UpdateRestaurantModalInputData {
    restaurant: Restaurant;
}

@Component({
    selector: 'app-update-restaurant-modal',
    templateUrl: './update-restaurant-modal.component.html',
    styleUrls: ['./update-restaurant-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, TranslateModule, SelectComponent, SelectChipListComponent, ReactiveFormsModule, MatButtonModule],
})
export class UpdateRestaurantModalComponent {
    readonly SvgIcon = SvgIcon;
    formGroup: FormGroup<INullableFormControlRecord<RestaurantUpdateForm>>;
    allOrganizations: Organization[];
    allUsers: User[];

    constructor(
        private readonly _dialogRef: MatDialogRef<UpdateRestaurantModalComponent>,
        private readonly _organizationsService: OrganizationsService,
        private readonly _usersService: UsersService,
        private readonly _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA)
        public data: UpdateRestaurantModalInputData
    ) {
        const formControlRecord: INullableFormControlRecord<RestaurantUpdateForm> = this._getFormControlRecord(data);
        this.formGroup = this._formBuilder.group(formControlRecord);
        this._organizationsService
            .index()
            .pipe(map((res) => res.data))
            .subscribe((res) => (this.allOrganizations = res));

        this._usersService.index().subscribe((res) => {
            this.allUsers = res;
            const newValues = this.data.restaurant.managers.map((m) => this.allUsers.find((user) => m.user._id === user._id));
            const newValuesFiltered = compact(newValues);
            this.formGroup.get('users')?.setValue(newValuesFiltered);
        });
    }

    save(): void {
        this._dialogRef.close(this.formGroup.getRawValue());
    }

    close(): void {
        this._dialogRef.close();
    }

    organizationDisplayWith(organization: Organization): string {
        return organization.name;
    }

    userDisplayWith(user: User): string {
        return user.email ?? user.fullname ?? `${user.name} ${user.lastname}`;
    }

    private _getFormControlRecord(data: UpdateRestaurantModalInputData): INullableFormControlRecord<RestaurantUpdateForm> {
        return getFormControlRecordFromDefaultValue(
            {
                organization: data.restaurant.organization,
                users: [],
            },
            {
                organization: { validators: Validators.required },
            }
        );
    }
}
