import { sortBy, take } from 'lodash';

import { RestaurantRankingFormat, RestaurantRankingFormatWithScore } from './restaurant-ranking-format';

const rankScores = [50, 43, 37, 31, 27, 23, 19, 17, 15, 13, 11, 9, 8, 7, 6, 5, 4, 3, 2, 1];

export type GeoSample = {
    ranking: RestaurantRankingFormat[];
};

export class KeywordRanking {
    private readonly _restaurants: RestaurantRankingFormatWithScore[];

    constructor(readonly geosamples: GeoSample[]) {
        this._restaurants = this._createRanking(geosamples);
    }

    getRestaurants(): RestaurantRankingFormatWithScore[] {
        return this._restaurants;
    }

    getPositionByPlaceId(placeId?: string): { rank: number; outOf: number } | null {
        if (this._restaurants.length < 1 || !placeId) {
            return null;
        }
        const rank = this._restaurants.findIndex((rest) => rest.place_id === placeId) + 1 || Infinity;
        return { rank, outOf: this._restaurants.length };
    }

    private _createRanking(geoSamples: GeoSample[]): RestaurantRankingFormatWithScore[] {
        if (!geoSamples || geoSamples.length < 0) {
            return [];
        }
        const ranking: RestaurantRankingFormatWithScore[] = [];
        geoSamples.forEach((geo) => {
            (geo.ranking || []).forEach((rest, i) => {
                const index = ranking.findIndex((r) => r.place_id === rest.place_id);
                if (index >= 0) {
                    ranking[index].addToScore(rankScores[i] || 0);
                } else {
                    ranking.push(
                        new RestaurantRankingFormatWithScore(
                            rankScores[i] || 0,
                            rest.name,
                            rest.place_id,
                            rest.vicinity,
                            rest.formatted_address
                        )
                    );
                }
            });
        });
        return take(sortBy(ranking, (r) => r.score).reverse(), 20);
    }
}
