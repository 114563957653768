<div class="align-center flex h-full w-full flex-col gap-4 overflow-y-auto px-8.5 py-4 pb-8">
    <ng-container [ngTemplateOutlet]="isCreatingRoiInsights() ? generatingRoiInsights : roiPageTemplate"></ng-container>
</div>

<ng-template #roiPageTemplate>
    <ng-container
        [ngTemplateOutlet]="
            ((hasSomeInitialSettings$ | async) ?? true) || isLoading() ? roiStatisticsTemplate : addSettingsTemplate
        "></ng-container>
</ng-template>

<ng-template #addSettingsTemplate>
    <app-upsert-aggregated-roi-settings
        class="flex items-center sm:items-start"
        (onSave)="handleOnSaveSettings($event)"></app-upsert-aggregated-roi-settings>
</ng-template>

<ng-template #roiStatisticsTemplate>
    <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>

    @if (hasAtLeastTwoRestaurantsSelected$ | async) {
        <div class="flex flex-wrap gap-4 sm:flex-col">
            <div class="h-full flex-1">
                <app-aggregated-estimated-customers [isParentLoading]="isLoading()"></app-aggregated-estimated-customers>
            </div>
            <div class="h-full w-[30%] sm:flex-1" [ngClass]="{ 'saved-time-container': !isLoading() }">
                <app-aggregated-saved-time [isParentLoading]="isLoading()"></app-aggregated-saved-time>
            </div>
        </div>

        <app-aggregated-monthly-estimated-customers [isParentLoading]="isLoading()"></app-aggregated-monthly-estimated-customers>

        <app-aggregated-performance [isParentLoading]="isLoading()"></app-aggregated-performance>
    } @else {
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
            <span class="malou-text-10--regular">{{ 'aggregated_statistics.errors.select_at_least_2_businesses' | translate }}</span>
        </div>
    }
</ng-template>

<ng-template #filtersTemplate>
    <div class="mb-6 flex items-end justify-between gap-4 sm:flex-col sm:items-center">
        <div class="w-1/2 flex-1 sm:w-full">
            <app-statistics-filters
                [page]="PlatformFilterPage.ROI"
                [showPlatformsFilter]="false"
                [timeScaleMinAcceptedDate]="restaurantsLimitDate()"></app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--secondary flex !h-12.5 items-center justify-center !bg-white sm:mt-2 sm:w-full"
            mat-raised-button
            (click)="openUpdateRoiSettingsModal()">
            {{ 'roi.settings.settings' | translate }}
            <span class="italic">{{
                'roi.aggregated_settings.settings_count'
                    | translate: { current: roiContext.restaurantsRoiSettings().length, total: (restaurants$ | async)?.length }
            }}</span>
        </button>
    </div>
</ng-template>

<ng-template #generatingRoiInsights>
    <app-loader-page
        class="h-full w-full"
        [generationStartDate]="creationStartDate()"
        [generationEstimatedTime]="creationEstimatedTime()"
        [title]="'roi.settings.creation_in_progress' | translate"
        [footerText]="'roi.settings.you_can_leave_while_creating' | translate"></app-loader-page>
</ng-template>
