import { Routes } from '@angular/router';

import { CommentsComponent } from '../comments/comments.component';
import { MessagesComponent } from '../messages/messages.component';
import { InteractionsComponent } from './interactions.component';

export const INTERACTIONS_ROUTES: Routes = [
    {
        path: '',
        component: InteractionsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'messages',
            },
            {
                path: 'messages',
                component: MessagesComponent,
            },
            {
                path: 'comments',
                component: CommentsComponent,
            },
        ],
    },
];
