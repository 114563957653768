import { NgClass, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import {
    Component,
    DestroyRef,
    effect,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, distinctUntilChanged, filter, map, Subject } from 'rxjs';

import { Day, DAYS } from '@malou-io/package-utils';

import { DEFAULT_CLOSED_TIME_PERIODS, times } from ':core/constants';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { isSameHours } from ':shared/helpers';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { TimePeriod } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';

interface IHoursModalForm {
    formHours: FormArray<HoursFormGroup>;
}

type HoursFormGroup = FormGroup<{
    openDay: FormControl<Day | null>;
    closeDay: FormControl<Day | null>;
    openTime: FormControl<string | null | undefined>;
    closeTime: FormControl<string | null | undefined>;
    isClosed: FormControl<boolean | null>;
    isPrimaryPeriod: FormControl<boolean | null>;
}>;

interface Hours {
    openDay?: Day | null;
    closeDay?: Day | null;
    openTime?: string | null;
    closeTime?: string | null;
    isClosed?: boolean | null;
    isPrimaryPeriod?: boolean | null;
}

@Component({
    selector: 'app-business-hours-form',
    templateUrl: './business-hours-form.component.html',
    styleUrls: ['./business-hours-form.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatDividerModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        SlideToggleComponent,
        NgTemplateOutlet,
        MatCheckboxModule,
        TitleCasePipe,
        TranslateModule,
        NgClass,
        EnumTranslatePipe,
        FormatTimePipe,
    ],
})
export class BusinessHoursFormComponent implements OnInit, OnDestroy {
    @Input() businessHours: TimePeriod[];
    @Input() id?: string;
    @Input() shouldHoursHavePadding = false;
    @Output() updateHours = new EventEmitter<{ regularHours: TimePeriod[] }>();
    @Output() hasError = new EventEmitter<boolean>();

    readonly duplicateModal = viewChild<ElementRef<HTMLElement>>('duplicateModal');
    readonly mouseEvent = signal<Partial<MouseEvent> | null>(null);

    readonly DAYS = DAYS;
    readonly changeIndex$ = new Subject<number>();

    isRestaurantClosed: boolean;
    hours: TimePeriod[] = [];
    // array of available times to choose from
    times: string[] = times;

    businessHoursForm: INullableFormGroup<IHoursModalForm>;
    showCopyModal = false;
    daysToDuplicate: Day[] = [];
    currentModalDay: Day | null = null;

    lastModifiedHour: TimePeriod[] = [];
    currentCopiedHours: TimePeriod[] = [];
    currentCopyModalIndex: number;

    readonly SvgIcon = SvgIcon;
    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _translate: TranslateService
    ) {
        this._initForm();

        effect(
            () => {
                const duplicateModal = this.duplicateModal();
                const mouseEvent = this.mouseEvent();

                if (!duplicateModal || !mouseEvent) {
                    return;
                }

                const container = document.getElementById('hoursModal');
                const onTop =
                    (container?.getBoundingClientRect().width ?? 0) > 767
                        ? (mouseEvent.clientY ?? 0) > 400
                        : (mouseEvent.clientY ?? 0) > 450;
                const style = onTop ? 'top: -295px;' : 'top: 36px;';
                duplicateModal.nativeElement.style.cssText = style;

                this.mouseEvent.set(null);
            },
            { allowSignalWrites: true }
        );
    }

    get formHours(): FormArray<HoursFormGroup> {
        return this.businessHoursForm.get('formHours') as FormArray<HoursFormGroup>;
    }

    @Input() set isClosedTemporarily(value: boolean) {
        this.isRestaurantClosed = value;
        if (value) {
            this.formHours.disable({ emitEvent: false });
        } else {
            this.formHours.enable({ emitEvent: false });
        }
    }

    // init formHours values
    ngOnInit(): void {
        this.hours = this.sortHours((this.businessHours.length > 0 && this.businessHours) || [...DEFAULT_CLOSED_TIME_PERIODS]);
        this.businessHoursForm.setControl('formHours', this._getFormArray(this.hours));
        this.businessHoursForm.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((formValue) => {
            const formHours = formValue.formHours?.map((h) => new TimePeriod(h as Partial<TimePeriod>));
            this.hasError.emit(this.businessHoursForm.invalid);
            if (formHours) {
                this.save(formHours);
            }
        });
        this._initCloseModalOnClick();
        this.businessHoursForm.setValidators(this.closeTimeValidator());

        combineLatest([
            this.changeIndex$,
            this.formHours.valueChanges.pipe(map((v) => v.map((h) => new TimePeriod(h as Partial<TimePeriod>)))),
        ])
            .pipe(
                map(([index, data]) => data.filter((d) => d.openDay === data[index].openDay)),
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
                filter((data) => {
                    if (data.length > 1) {
                        return true;
                    }
                    const isPeriodClosed = data[0].isClosed;
                    const doesPeriodHasOpenTime = 'openTime' in data[0];
                    const doesPeriodHasCloseTime = 'closeTime' in data[0];
                    return isPeriodClosed
                        ? !doesPeriodHasOpenTime && !doesPeriodHasCloseTime
                        : doesPeriodHasOpenTime && doesPeriodHasCloseTime;
                })
            )
            .subscribe((periods) => {
                if (!this.lastModifiedHour.length) {
                    this.lastModifiedHour = periods;
                }
                if (periods[0].openDay !== this.lastModifiedHour[0]?.openDay) {
                    if (isSameHours(periods, this.lastModifiedHour)) {
                        this.lastModifiedHour = [];
                        this.copy({ clientY: 200 }, periods[0].openDay, 1);
                    }
                } else {
                    this.lastModifiedHour = periods;
                }
            });
    }

    ngOnDestroy(): void {
        document?.removeEventListener('click', this._onClick);
    }

    sortHours(hours: TimePeriod[]): TimePeriod[] {
        return [...hours].sort((h1, h2) => {
            if (DAYS.indexOf(h1.openDay) < DAYS.indexOf(h2.openDay)) {
                return -1;
            }
            if (DAYS.indexOf(h1.openDay) > DAYS.indexOf(h2.openDay)) {
                return 1;
            }
            if (!h1.openTime) {
                return !h2.openTime ? 0 : -1;
            }
            if (!h2.openTime) {
                return 1;
            }
            if (h1.openTime < h2.openTime) {
                return -1;
            }
            if (h1.openTime > h2.openTime) {
                return 1;
            }
            return 0;
        });
    }

    isClosed(index: number): boolean {
        return this.formHours.get([index, 'isClosed'])?.value;
    }

    changeIsClosed($event: boolean, day: Day | null | undefined, index: number): void {
        this.changeIndex$.next(index);
        const openTimeCtrl = this.businessHoursForm.get(['formHours', index, 'openTime']);
        const closeTimeCtrl = this.businessHoursForm.get(['formHours', index, 'closeTime']);
        const numberOfHours = this.formHours.controls.filter((h) => h.value.openDay === day).length;
        if (numberOfHours > 1) {
            this._removeCurrentDayHours(index + 1, numberOfHours - 1);
        }
        if ($event) {
            openTimeCtrl?.setValue(null, { emitEvent: false });
            closeTimeCtrl?.setValue(null, { emitEvent: false });
            openTimeCtrl?.disable({ emitEvent: false });
            closeTimeCtrl?.disable({ emitEvent: false });
        } else {
            this.updateFullDay(index);
        }
        this.businessHoursForm.get(['formHours', index, 'isClosed'])?.setValue($event);
    }

    isLastPeriod(day: Day | null, index: number): boolean {
        const lastOpenDayIndex = this.formHours.controls.map((c) => c.value.openDay).lastIndexOf(day);
        return lastOpenDayIndex === index;
    }

    shouldHourBeVisible(day: Day | null | undefined, index: number): boolean {
        return this._isFirstPeriod(day, index);
    }

    shouldDeleteBtnBeVisible(day: Day | null | undefined): boolean {
        return !(this.formHours.controls.filter((h) => h.value.openDay === day).length === 1);
    }

    canAddPeriod(val: Hours): boolean {
        return this.formHours.value.filter((hour) => hour.openDay === val.openDay).length < 3;
    }

    createHourInput(hour: Hours): FormGroup {
        return this._fb.group({
            openDay: hour.openDay,
            openTime: '',
            closeDay: hour.openDay,
            closeTime: '',
            isClosed: [false],
            isPrimaryPeriod: [false],
        });
    }

    addHourInput(index: number, hour: Hours): void {
        this.changeIndex$.next(index);
        this.formHours.insert(index + 1, this.createHourInput(hour));
    }

    deleteHourInput(index: number): void {
        this.changeIndex$.next(index - 1);
        this.formHours.removeAt(index);
    }

    updateCloseDay(index: number): void {
        this.changeIndex$.next(index);
        const dayHours = this.formHours.at(index).value;
        if (!dayHours.openDay) {
            dayHours.closeDay = dayHours.openDay;
            return;
        }
        const currentOpenDayIndex = DAYS.indexOf(dayHours.openDay);
        const openTime = dayHours.openTime;
        const closeTime = dayHours.closeTime;
        // TODO : check if closeTime between midnight and 6 AM and smaller than openTime
        if (closeTime && openTime && closeTime < openTime) {
            dayHours.closeDay = DAYS[(currentOpenDayIndex + 1) % DAYS.length];
            return;
        }
        dayHours.closeDay = dayHours.openDay;
    }

    updateFullDay(index: number): void {
        this.changeIndex$.next(index);
        const openTimeCtrl = this.formHours.get([index, 'openTime']);
        const closeTimeCtrl = this.formHours.get([index, 'closeTime']);
        if (openTimeCtrl?.value === '24-24') {
            closeTimeCtrl?.disable({ emitEvent: false });
        } else {
            openTimeCtrl?.enable({ emitEvent: false });
            closeTimeCtrl?.enable({ emitEvent: false });
        }
    }

    copy(event: Partial<MouseEvent>, day: Day | null, index: number): void {
        this.currentCopyModalIndex = index;
        this.currentModalDay = day;
        this.currentCopiedHours = this.formHours.controls.map((formGroup) => new TimePeriod(formGroup.value as Partial<TimePeriod>));
        this.showCopyModal = true;
        this.mouseEvent.set(event);
    }

    isChecked(day: Day): boolean {
        return day === this.currentModalDay || this._shouldDuplicate(day);
    }

    toggleDuplicate(event: MatCheckboxChange, day: Day): void {
        let referenceHours: TimePeriod[];
        if (event.checked) {
            referenceHours = this.formHours.controls
                .filter((h) => h.value.openDay === this.currentModalDay)
                .map((fg) => new TimePeriod(fg.value as Partial<TimePeriod>));
        } else {
            referenceHours = this.currentCopiedHours.filter((h) => h.openDay === day);
        }

        this._validateDuplicate(referenceHours, day);
    }

    closeDuplicateModal(): void {
        this.showCopyModal = false;
        this.daysToDuplicate = [];
        this.currentModalDay = null;
        this.currentCopiedHours = [];
    }

    /**
     * Validate Close Time and OpenTime  // todo complete for more sophisticated conditions
     */
    closeTimeValidator(): ValidatorFn {
        return (): ValidationErrors | null => {
            this.formHours.controls.forEach((control) => {
                if (this._isInvalidFormControl(control)) {
                    control.setErrors({ error: this._translate.instant('information.business_hours.hours_should_be_different') });
                } else if (this._isControlNotComplete(control)) {
                    control.setErrors({ error: this._translate.instant('information.business_hours.should_not_be_empty') });
                } else {
                    control.setErrors(null);
                }
            });
            return null;
        };
    }

    // todo : add validator for time periods order 'openTime 1 < openTime 2'

    save(hours: TimePeriod[]): void {
        if (!this.businessHoursForm.valid) {
            return;
        }
        const hoursWithoutFineHours = this._filterFineHours(hours);
        const hoursWithCorrectCloseDays = this._makeOpenDayAndCloseDayEqualIfClosed(hoursWithoutFineHours);
        const updateData = this._cleanFullHoursDays(hoursWithCorrectCloseDays);
        this.updateHours.emit({ regularHours: updateData });
    }

    showTimeSlotsWarning(index: number): boolean {
        const timePeriodDay = this.formHours.at(index).value?.openDay;
        return this.formHours.value.filter((hour) => hour.openDay === timePeriodDay)?.length === 3;
    }

    private _validateDuplicate(referenceHours: TimePeriod[], dayToChange: Day): void {
        const hoursToChange = this.formHours.controls.filter((h) => h.value.openDay === dayToChange);
        const startingIndex = this.formHours.controls.findIndex((h) => h.value.openDay === dayToChange);
        this._removeCurrentDayHours(startingIndex, hoursToChange.length);

        for (let i = 0; i < referenceHours.length; i++) {
            const reference = referenceHours[i];
            const hoursFormGroup: HoursFormGroup = this._fb.group({
                openTime: new FormControl({
                    value: reference.openTime ?? (null as string | undefined | null),
                    disabled: !reference.openTime,
                }),
                closeTime: new FormControl({
                    value: reference.closeTime ?? (null as string | undefined | null),
                    disabled: !reference.closeTime,
                }),
                openDay: dayToChange,
                closeDay: dayToChange,
                isClosed: false as boolean,
                isPrimaryPeriod: false as boolean,
            });
            this.formHours.insert(startingIndex + i, hoursFormGroup);
        }
    }

    private _initForm(): void {
        this.businessHoursForm = this._fb.group({
            formHours: this._fb.array([]) as FormArray,
        });
    }

    private _getFormArray(hours: TimePeriod[]): FormArray<HoursFormGroup> {
        const hoursFormArray = new FormArray<HoursFormGroup>([]);
        hours.forEach((h) => {
            const disabled = h.isClosed;
            const periodValue = {
                openDay: h.openDay,
                closeDay: h.closeDay,
                openTime: new FormControl({ value: h.openTime === '24:00' ? '00:00' : h.openTime, disabled }),
                closeTime: new FormControl({ value: h.closeTime, disabled }),
                isClosed: h.isClosed,
                isPrimaryPeriod: h.isPrimaryPeriod,
            };
            hoursFormArray.push(this._fb.group(periodValue));
        });
        return hoursFormArray;
    }

    private _isFirstPeriod(day: Day | null | undefined, index: number): boolean {
        const firstOpenDayIndex = this.formHours.controls.findIndex((h) => h.value.openDay === day);
        return firstOpenDayIndex === index;
    }

    private _shouldDuplicate(day: Day): boolean {
        return this.daysToDuplicate.includes(day);
    }

    private _cleanFullHoursDays(hours: TimePeriod[]): TimePeriod[] {
        return hours.reduce((acc, hour) => {
            if (hour.isFullDay()) {
                hour.cleanFullDay();
            }
            return [...acc, hour];
        }, []);
    }

    private _filterFineHours(hours: TimePeriod[]): TimePeriod[] {
        return hours.filter((h) => h.isPrimaryPeriod || !h.isClosed);
    }

    private _makeOpenDayAndCloseDayEqualIfClosed(hours: TimePeriod[]): TimePeriod[] {
        return hours.map((hour) => {
            const elt = hour;
            if (hour.isClosed) {
                elt.closeDay = elt.openDay;
            }
            return elt;
        });
    }

    private _removeCurrentDayHours(startIndex: number, length: number): void {
        for (let i = 0; i < length; i++) {
            this.formHours.removeAt(startIndex);
        }
    }

    private _isInvalidFormControl(control: AbstractControl): boolean {
        return control.touched && control.value.closeTime === control.value.openTime && !control.value.isClosed;
    }

    private _isControlNotComplete(control: AbstractControl): boolean {
        const timePeriod = this._cleanFullHoursDays([new TimePeriod(control.value)])[0];
        return !control.value.isClosed && [timePeriod.closeTime, timePeriod.openTime].some((time) => [null, '', undefined].includes(time));
    }

    private _initCloseModalOnClick(): void {
        document?.addEventListener('click', (event) => this._onClick(event));
    }

    private _onClick(event: Event): void {
        const target = <HTMLElement>event.target;
        if (!target.closest('#duplicateModal') && !target.closest('#copyHours')) {
            this.closeDuplicateModal();
        }
    }
}
