<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [selectedValues]="selectedValues()"
    [displayWith]="displayWith()"
    [multiSelection]="multiSelection()"
    [checkboxOption]="multiSelection()"
    [groupSelectedValuesAtTop]="false"
    [showSelectAllCheckbox]="true"
    [selectAllCheckboxMessage]="'common.all_languages' | translate"
    [testId]="testId()"
    (selectBaseChange)="selectLanguagesChange.emit($event)">
    <ng-template let-value="value" #selectedValueTemplate>
        <img class="h-6 w-6" [src]="value | flagPathResolver" [alt]="value" />
    </ng-template>
    <ng-template let-value="value" #optionTemplate>
        <div class="flex items-center gap-x-2">
            <img class="h-4 w-4" [lazyLoad]="value | flagPathResolver" [alt]="value" />
            <span class="malou-text-13--regular">{{ displayWith() | applyPure: value }}</span>
        </div>
    </ng-template>
</app-select-base>
