import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { OneDigitNumberPipe } from ':shared/pipes/one-digit-number.pipe';

import { SidenavStateService } from './sidenav-content.component';

@Component({
    selector: 'app-sidenav-route',
    templateUrl: './sidenav-route.component.html',
    styleUrls: ['./sidenav-route.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgClass, OneDigitNumberPipe, RouterLink, RouterLinkActive],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavRouteComponent {
    readonly routerLink = input.required<string[]>();
    readonly svgIcon = input.required<string>();
    readonly showCrown = input<boolean>(false);
    readonly notificationCount = input<number>(0);
    readonly text = input.required<string>();

    constructor(public sidenavState: SidenavStateService) {}
}
