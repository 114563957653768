<app-modal-structure
    [title]="'platforms.connection_new.facebook_instagram.step_5.title' | translate"
    [primaryButtonText]="'platforms.connection_new.facebook_instagram.step_5.primary_button' | translate"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="goToStep.emit({ type: 'history', value: 1 })">
    <div class="flex flex-col gap-y-6 pt-6">
        <app-right-arrow-with-content>
            <ng-container
                [ngTemplateOutlet]="descriptionTemplate"
                [ngTemplateOutletContext]="{
                    text: 'platforms.connection_new.facebook_instagram.step_5.description_1' | translate: { class: 'malou-text-13--bold' },
                    showUrl: true,
                }"></ng-container>
        </app-right-arrow-with-content>
        <app-right-arrow-with-content>
            <ng-container
                [ngTemplateOutlet]="descriptionTemplate"
                [ngTemplateOutletContext]="{
                    text: 'platforms.connection_new.facebook_instagram.step_5.description_2' | translate: { class: 'malou-text-13--bold' },
                    showUrl: false,
                }"></ng-container>
        </app-right-arrow-with-content>
        <app-right-arrow-with-content>
            <ng-container
                [ngTemplateOutlet]="descriptionTemplate"
                [ngTemplateOutletContext]="{
                    text: 'platforms.connection_new.facebook_instagram.step_5.description_3' | translate: { class: 'malou-text-13--bold' },
                    showUrl: false,
                }"></ng-container>
        </app-right-arrow-with-content>
        <app-right-arrow-with-content>
            <ng-container
                [ngTemplateOutlet]="descriptionTemplate"
                [ngTemplateOutletContext]="{
                    text: 'platforms.connection_new.facebook_instagram.step_5.description_4' | translate: { class: 'malou-text-13--bold' },
                    showUrl: false,
                }"></ng-container>
        </app-right-arrow-with-content>
        <div class="self-center">
            <img
                class="malou-box-shadow h-[200px] w-auto"
                [src]="
                    'associate-facebook-and-instagram-accounts' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }
                " />
        </div>
    </div>
</app-modal-structure>

<ng-template let-showUrl="showUrl" let-text="text" #descriptionTemplate>
    <div class="malou-text-13--medium">
        <ng-container [ngTemplateOutlet]="descriptionTextTemplate" [ngTemplateOutletContext]="{ text }"></ng-container>
        @if (showUrl) {
            &nbsp;<ng-container *ngTemplateOutlet="description1UrlTemplate"></ng-container>
        }
    </div>
</ng-template>

<ng-template let-text="text" #descriptionTextTemplate>
    <span class="malou-color-text-1" [innerHtml]="text"></span>
</ng-template>

<ng-template #description1UrlTemplate>
    <a class="malou-color-text-primary whitespace-nowrap" href="https://facebook.com/pages/create" target="_blank">{{
        'platforms.connection_new.facebook_instagram.step_5.description_1_url' | translate
    }}</a>
</ng-template>
