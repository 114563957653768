@if (wheelOfFortuneMessage()) {
    <div class="flex h-full flex-col justify-between px-7">
        <div class="flex grow flex-col">
            @switch (wheelOfFortuneMessage()) {
                @case (WheelOfFortuneMessage.GIFT_DRAW_EXPIRED) {
                    <ng-container [ngTemplateOutlet]="expiredGiftDrawTemplate"></ng-container>
                }
                @case (WheelOfFortuneMessage.GIFT_DRAW_NOT_CLAIMABLE_YET) {
                    <ng-container [ngTemplateOutlet]="giftCannotBeClaimedYetTemplate"></ng-container>
                }
                @case (WheelOfFortuneMessage.GIFT_DRAW_ALREADY_CLAIMED) {
                    <ng-container [ngTemplateOutlet]="giftDrawAlreadyClaimedTemplate"></ng-container>
                }
                @case (WheelOfFortuneMessage.WHEEL_OF_FORTUNE_EXPIRED) {
                    <ng-container [ngTemplateOutlet]="expiredWheelOfFortuneTemplate"></ng-container>
                }
                @case (WheelOfFortuneMessage.WHEEL_OF_FORTUNE_PROGRAMMED) {
                    <ng-container [ngTemplateOutlet]="programmedWheelOfFortuneTemplate"></ng-container>
                }
                @case (WheelOfFortuneMessage.THANKS_FOR_PLAYING) {
                    <ng-container [ngTemplateOutlet]="thanksForPlayingTemplate"></ng-container>
                }
                @case (WheelOfFortuneMessage.CLIENT_ALREADY_PLAYED) {
                    <ng-container [ngTemplateOutlet]="clientAlreadyPlayed"></ng-container>
                }
            }
        </div>
        <div class="malou-text-12--semibold absolute bottom-[15px] left-0 flex w-full items-baseline justify-center text-center">
            <span>{{ 'play_wheel_of_fortune.powered_by' | translate }}</span>
            <img class="ml-1 max-h-2" [src]="'malou_logo_black' | imagePathResolver" />
        </div>
    </div>
}

<ng-template #thanksForPlayingTemplate>
    <div class="flex flex-col items-center">
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'pray' }"></ng-container>

        <div class="malou-text-24--semibold malou-color-text-1 text-center">
            {{ 'get_my_gift.image_with_background_templates.thanks_for_playing.title' | translate }}
        </div>
        <div
            class="malou-text-15--semibold malou-color-text-2 text-center leading-7"
            [ngClass]="{ 'mt-[25%]': isPhoneScreen(), 'mt-[5%]': !isPhoneScreen() }">
            {{ 'get_my_gift.image_with_background_templates.thanks_for_playing.subtitle' | translate }}
        </div>
    </div>
</ng-template>

<ng-template #giftDrawAlreadyClaimedTemplate>
    <div class="flex flex-col items-center">
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'thinking' }"></ng-container>

        <div class="malou-text-24--semibold malou-color-text-1 text-center">
            {{ 'get_my_gift.image_with_background_templates.already_claimed.title' | translate }}
        </div>
        <div
            class="malou-text-15--semibold malou-color-text-2 text-center leading-7"
            [ngClass]="{ 'mt-[25%]': isPhoneScreen(), 'mt-[5%]': !isPhoneScreen() }">
            {{ 'get_my_gift.image_with_background_templates.already_claimed.subtitle' | translate }}
        </div>
    </div>
</ng-template>

<ng-template #expiredGiftDrawTemplate>
    <div class="flex flex-col items-center">
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'sweat-smile' }"></ng-container>

        <div class="malou-text-24--semibold malou-color-text-1 text-center">
            {{ 'get_my_gift.image_with_background_templates.draw_is_expired' | translate }}
        </div>
    </div>
</ng-template>

<ng-template #giftCannotBeClaimedYetTemplate>
    <div class="flex flex-col items-center">
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'sweat-smile' }"></ng-container>

        <div class="malou-text-24--semibold malou-color-text-1 text-center">
            {{ 'get_my_gift.image_with_background_templates.gift_cannot_be_claimed_yet.title' | translate }}
        </div>

        <div
            class="malou-text-15--regular malou-color-text-2 text-center leading-7"
            [ngClass]="{ 'mt-[25%]': isPhoneScreen(), 'mt-[5%]': !isPhoneScreen() }"
            [innerHTML]="
                'get_my_gift.image_with_background_templates.gift_cannot_be_claimed_yet.retrieval_start_date'
                    | translate: { date: formattedDate() }
            "></div>
    </div>
</ng-template>

<ng-template #expiredWheelOfFortuneTemplate>
    <div class="flex flex-col items-center">
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'pleading-face' }"></ng-container>

        <div class="malou-text-24--semibold malou-color-text-1 text-center">
            {{ 'get_my_gift.image_with_background_templates.wof_is_expired.title' | translate }}
        </div>
        <div
            class="malou-text-15--semibold malou-color-text-2 text-center leading-7"
            [ngClass]="{ 'mt-[25%]': isPhoneScreen(), 'mt-[5%]': !isPhoneScreen() }">
            {{ 'get_my_gift.image_with_background_templates.wof_is_expired.come_back_later' | translate }}
        </div>
    </div>
</ng-template>

<ng-template #programmedWheelOfFortuneTemplate>
    <div class="flex flex-col items-center">
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'time' }"></ng-container>

        <div class="malou-text-24--semibold malou-color-text-1 text-center">
            {{ 'get_my_gift.image_with_background_templates.wof_is_programmed.title' | translate }}
        </div>
        <div
            class="malou-text-50--semibold malou-color-text-1 text-center leading-7"
            [ngClass]="{ 'mt-[25%]': isPhoneScreen(), 'mt-[5%]': !isPhoneScreen() }">
            {{ 'get_my_gift.image_with_background_templates.wof_is_programmed.countdown' | translate: countdown() }}
        </div>
    </div>
</ng-template>

<ng-template #clientAlreadyPlayed>
    <div class="flex flex-col items-center">
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'pleading-face' }"></ng-container>

        <div class="malou-text-24--semibold malou-color-text-1 text-center">
            {{ 'get_my_gift.image_with_background_templates.client_already_played.title' | translate }}
        </div>
        <div
            class="malou-text-15--medium malou-color-text-2 text-center leading-7"
            [ngClass]="{ 'mt-[25%]': isPhoneScreen(), 'mt-[5%]': !isPhoneScreen() }">
            {{ 'get_my_gift.image_with_background_templates.client_already_played.already_played' | translate }}
        </div>
        <div class="malou-text-15--semibold malou-color-text-2 mt-2 text-center leading-7">
            {{ 'get_my_gift.image_with_background_templates.client_already_played.cant_play_again' | translate }}
        </div>
    </div>
</ng-template>

<ng-template let-img="img" #imageWithBackground>
    <div class="relative ml-5" [ngClass]="{ '!mb-[20%] mt-[30%]': isPhoneScreen(), 'my-[5%]': !isPhoneScreen() }">
        <div
            class="absolute bottom-0 right-[20px] h-[140px] w-[140px] rounded-full"
            [ngClass]="{
                '!right-[6px]': img === 'thinking' || img === 'time',
                '!right-[10px]': ['hand-phone', 'pray'] | includes: img,
                '!right-[14px]': img === 'heart',
            }"
            [ngStyle]="{ 'background-color': primaryColor() }"></div>
        <img
            class="relative w-[140px]"
            [ngClass]="{
                'mb-[6px] !w-[119px]': img === 'thinking',
                'mb-[16px] !w-[110px]': img === 'hand-phone',
                'mb-[2px] !w-[124px]': img === 'heart',
                'mb-[12px] !w-[120px]': img === 'pray',
                'mb-[4px] !w-[124px]': img === 'time',
            }"
            [alt]="img"
            [src]="img | imagePathResolver: { folder: 'wheels-of-fortune' }" />
    </div>
</ng-template>
