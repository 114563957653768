export const environment = {
    production: true,
    GOOGLE_FRONTEND_KEY: 'AIzaSyCH-RI6GzRxev_GKsNDWsZSrqP4q8oR8qc',
    APP_MALOU_API_URL: 'https://development.api.malou.io',
    MAX_KEYWORDS_LENGTH: 5,
    S3_URI: 'https://malou-dev.s3.eu-west-3.amazonaws.com',
    environment: 'development',
    PUSHER_KEY: '0acfec10831b8018a77d',
    experimentation_app_proxy_url: 'https://experimentation.malou.io',
    experimentation_app_client_key: 'sdk-Z2vFGxgYNB6bYbg6',
    APP_WEB_VERSION: 'v3',
    BASE_URL: 'https://development.omni.malou.io',
    JIMO_PROJECT_ID: 'd0d1083f-2824-443c-b6e8-51aaecdf7a47',
};
