import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    forwardRef,
    inject,
    input,
    Input,
    OnInit,
    output,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, skip, tap } from 'rxjs/operators';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-input-number',
    templateUrl: 'input-number.component.html',
    styleUrls: ['./input-number.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputNumberComponent),
        },
    ],
    standalone: true,
    imports: [NgClass, FormsModule, MatIconModule, ReactiveFormsModule, TranslateModule, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberComponent implements OnInit, ControlValueAccessor {
    /**
     * Title
     *
     * @optional
     */
    @Input()
    title = '';

    /**
     * Placeholder
     *
     * @optional
     */
    @Input()
    placeholder = '';

    /**
     * Required option, will add an asterix after the title
     *
     * @optional
     */
    @Input()
    required = false;

    /**
     * Error message, will add a colored border and will display the error below the input-text
     *
     * @optional
     */
    @Input()
    errorMessage: string | undefined;

    /**
     * Subtitle
     *
     * @required
     */
    @Input()
    subtitle?: string;

    @Input()
    debounceTime = 0;

    @Input()
    min: number | null = null;

    @Input()
    max: number | null = null;

    @Input()
    step = 1;

    @Input()
    set disabled(disabled: boolean) {
        this._disabled = disabled;
        disabled ? this.control.disable() : this.control.enable();
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set value(value: number | null) {
        this._value = value;
        this._setValue();
    }

    readonly icon = input<SvgIcon>();
    readonly iconClass = input<string>('');

    /**
     * On value change output
     */
    readonly inputNumberChange = output<number | null>();

    readonly SvgIcon = SvgIcon;

    @ViewChild('inputNumber')
    inputNumber: ElementRef<HTMLInputElement>;

    isFocused = false;

    private _value: number | null = null;
    private _disabled = false;

    private readonly _destroyRef = inject(DestroyRef);

    control: FormControl<number | null> = new FormControl<number | null>(null);

    isTouched = false;
    onTouched: any = () => {};
    onChange: any = () => {};

    ngOnInit(): void {
        if (this.required) {
            this.control.addValidators(Validators.required);
        }

        this.control.valueChanges
            .pipe(
                debounceTime(this.debounceTime),
                skip(1),
                tap(() => this.markAsTouched()),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((value: number | null) => {
                this.onChange(value);
                this.inputNumberChange.emit(value);
            });

        this._setValue();
    }

    preventNonNumericalValue(event: KeyboardEvent): void {
        if (!event.key.match(/[0-9]/)) {
            event.preventDefault();
        }
    }

    writeValue(value: number | null): void {
        this.control.setValue(value);
    }

    markAsTouched(): void {
        if (!this.isTouched && this.onTouched) {
            this.onTouched();
            this.isTouched = true;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.control.disable() : this.control.enable();
    }

    stepUp(): void {
        const currentValue = this.control.value || this.min || 0;
        const newValue = this.max ? Math.min(this.max, currentValue + this.step) : currentValue + this.step;
        this.control.setValue(newValue);
    }

    stepDown(): void {
        const currentValue = this.control.value || this.min || 0;
        const newValue = this.min ? Math.max(this.min, currentValue - this.step) : currentValue - this.step;
        this.control.setValue(newValue);
    }

    stepUpDisabled(disabled: boolean, max: number | null, value: number | null): boolean {
        return disabled || (max != null && value != null && value >= max);
    }

    stepDownDisabled(disabled: boolean, min: number | null, value: number | null): boolean {
        return disabled || (min != null && value != null && value <= min);
    }

    private _setValue(): void {
        this.writeValue(this._value);
    }
}
