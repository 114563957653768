import { PlatformKey, TimeInMilliseconds } from '@malou-io/package-utils';

export const platformsUpdateConfig = [
    {
        key: PlatformKey.GMB,
        delayInMilliseconds: 0,
        delayLabelName: 'right_now',
        linkedPlatforms: ['googlemaps', 'waze'],
    },
    {
        key: PlatformKey.FACEBOOK,
        delayInMilliseconds: 0,
        delayLabelName: 'right_now',
        linkedPlatforms: ['instagram'],
    },
    {
        key: PlatformKey.MAPSTR,
        delayInMilliseconds: 0,
        delayLabelName: 'right_now',
        linkedPlatforms: [],
    },
    {
        key: PlatformKey.LAFOURCHETTE,
        delayInMilliseconds: 3 * TimeInMilliseconds.DAY,
        delayLabelName: 'up_to_three_days',
        linkedPlatforms: [],
    },
    {
        key: PlatformKey.TRIPADVISOR,
        delayInMilliseconds: TimeInMilliseconds.WEEK,
        delayLabelName: 'up_to_one_week',
        linkedPlatforms: [],
    },
    {
        key: PlatformKey.PAGESJAUNES,
        delayInMilliseconds: 2 * TimeInMilliseconds.WEEK,
        delayLabelName: 'up_to_two_weeks',
        linkedPlatforms: ['bing', 'yahoo', 'qwant', 'ooreka', 'mappy'],
    },
    {
        key: PlatformKey.YELP,
        delayInMilliseconds: 3 * TimeInMilliseconds.WEEK,
        delayLabelName: 'up_to_three_weeks',
        linkedPlatforms: ['apple'],
    },
    {
        key: PlatformKey.FOURSQUARE,
        delayInMilliseconds: 30 * TimeInMilliseconds.DAY,
        delayLabelName: 'up_to_a_month',
        linkedPlatforms: ['ubereats', 'twitter', 'apple', 'snapchat', 'microsoft'],
    },
];
