import { ChangeDetectionStrategy, Component, computed, inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';

import { ExperimentationService } from '../../core/services/experimentation.service';

@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss'],
    standalone: true,
    imports: [NavigationHeaderComponent, RouterOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesComponent {
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _translate = inject(TranslateService);
    private readonly _restaurantService = inject(RestaurantsService);

    readonly currentRestaurant = toSignal(this._restaurantService.restaurantSelected$, {
        initialValue: this._restaurantService.currentRestaurant,
    });

    readonly UNAVAILABLE_TABS_FOR_BRAND_BUSINESS = ['./keywords', './clients', './review-templates'];

    readonly tabs: Signal<NavigationTab[]> = computed(() => [
        { label: this._translate.instant('app_sidebar.library.gallery'), link: './gallery' },
        { label: this._translate.instant('app_sidebar.library.review_templates'), link: './review-templates' },
        { label: this._translate.instant('app_sidebar.library.message_templates'), link: './message-templates' },
        { label: this._translate.instant('app_sidebar.library.clients'), link: './clients' },
        { label: this._translate.instant('app_sidebar.seo.keywords'), link: './keywords' },
    ]);

    readonly filteredTabs: Signal<NavigationTab[]> = computed(() =>
        this.currentRestaurant()?.isBrandBusiness()
            ? this.tabs().filter((tab) => !this.UNAVAILABLE_TABS_FOR_BRAND_BUSINESS.includes(tab.link))
            : this.tabs()
    );
}
