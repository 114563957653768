<div class="flex h-full flex-1 flex-col bg-malou-color-background-light p-6.5 pb-0">
    <div class="malou-text-18--bold text-malou-color-text-1 md:hidden">
        {{ 'validation.modal.title_right' | translate: { number: dataSource().data.length } }}
    </div>
    <div class="mt-5 flex w-full items-center justify-between md:hidden">
        <div
            class="malou-text-12--regular max-w-[75%] leading-6 text-malou-color-text-2"
            [innerHTML]="'validation.modal.subtitle_right' | translate: { number: 2 }"></div>
    </div>

    @if (!loading()) {
        <div class="mt-5 max-h-full grow overflow-y-auto pb-6.5 md:mt-0">
            <mat-table class="malou-mat-table" matSort [dataSource]="dataSource()" #table="matTable">
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <app-noop-mat-checkbox
                            color="primary"
                            [testId]="'choose-keywords-select-all-input'"
                            [checked]="isAllSelected()"
                            [indeterminate]="!isAllSelected()"
                            (click)="$event ? toggleAllFiltered() : null">
                        </app-noop-mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; table: table; let i = index">
                        <app-noop-mat-checkbox
                            color="primary"
                            [checked]="selection().isSelected(row)"
                            (click)="$event.stopPropagation(); toggleSelected(row); selectMultiple($event, i)">
                        </app-noop-mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="text">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xlarge" mat-sort-header>
                        {{ 'keywords.validation.keywords' | translate }}
                        ({{ this.dataSource().filteredData.length }})
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; table: table" class="malou-mat-table-cell-xlarge">
                        <div class="malou-text-13--semibold max-w-full truncate pr-2 text-malou-color-text-1">
                            {{ row.text }}
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="language">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                        {{ 'keywords.validation.lang' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let row; table: table; let i = index"
                        class="malou-mat-table-cell-xsmall"
                        matTooltip="{{ getPrettyLang | applyPure: row.language }}"
                        (click)="$event.stopPropagation()">
                        <img class="h-4 w-4" [lazyLoad]="row.language | flagPathResolver" [alt]="getPrettyLang | applyPure: row.language" />
                        <ng-container [ngTemplateOutlet]="updateKeywordLangTemplate" [ngTemplateOutletContext]="{ keyword: row }">
                        </ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="volume">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                        {{ 'keywords.validation.popularity' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; table: table" class="malou-mat-table-cell-xsmall">
                        <app-keywords-popularity
                            [popularity]="row | applySelfPure: 'getPopularity' : allKeywords()"></app-keywords-popularity>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="move">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall justify-end">
                        <div class="flex" [matTooltip]="selectionCount() === 0 ? ('keywords.validation.select_to_remove' | translate) : ''">
                            <button
                                class="malou-btn-flat"
                                data-testid="choose-keywords-remove-all-input"
                                [disabled]="selectionCount() === 0"
                                (click)="removeSelectedKeywords()">
                                {{ 'common.remove' | translate }} ({{ selectionCount() }})
                            </button>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; table: table" class="malou-mat-table-cell-xsmall justify-end">
                        <button class="malou-btn-flat" (click)="removeSelectedKeyword(row)">
                            {{ 'keywords.validation.remove' | translate }}
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: DISPLAYED_COLUMNS; table: table; let i = index"
                    (click)="$event.stopPropagation(); toggleSelected(row); selectMultiple($event, i)"></mat-row>
            </mat-table>
            @if (!dataSource().data.length) {
                <button
                    class="malou-btn-raised--primary mt-4 w-full md:grow"
                    data-testid="keywords-choose-for-me-btn"
                    mat-raised-button
                    (click)="chooseKeywordsForMe()">
                    {{ 'keywords.validation.choose_for_me' | translate }}
                </button>
            }
            @if (dataSource().data.length < MAX_KEYWORDS_SELECTED) {
                @for (_i of MAX_KEYWORDS_SELECTED - dataSource().data.length | createArray; track _i) {
                    <div
                        class="mt-1 h-12 w-full rounded-[10px] border-[1px] border-solid border-malou-color-background-dark bg-malou-color-background-white md:h-[70px]"></div>
                }
            }
        </div>
    } @else {
        <div class="mt-5 max-h-full w-full grow overflow-y-auto">
            <app-skeleton skeletonClass="h-[50px] w-full" [count]="7"></app-skeleton>
        </div>
    }

    <div class="footer mr-0.5 flex items-center gap-x-4 self-end py-6.5 md:hidden">
        <button class="malou-btn-raised--secondary--alt !h-11 md:grow" mat-raised-button (click)="onClose()">
            {{ 'common.cancel' | translate }}
        </button>
        <button
            class="malou-btn-raised--primary !h-11 md:grow"
            data-testid="keywords-save-selected-btn"
            mat-raised-button
            (click)="onSave()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</div>

<ng-template let-keyword="keyword" #updateKeywordLangTemplate>
    <app-update-keyword-lang [keyword]="keyword" (updateKeywordLang)="updateKeywordLang($event)"></app-update-keyword-lang>
</ng-template>
