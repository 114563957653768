<div class="malou-dialog !h-[90vh] overflow-hidden md:!absolute md:bottom-0 md:!h-[80vh]">
    <div class="malou-dialog__header">
        <div class="malou-text__card-title">
            {{ 'media_picker_modal.choose_media' | translate }}
        </div>
        <button class="malou-btn-icon" color="primary" mat-icon-button (click)="cancel()">
            <mat-icon [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="mb-5 flex gap-x-2 px-[26px] md:hidden">
        <div class="grow md:mb-2">
            <app-search
                [debounceTime]="500"
                [placeholder]="'common.search' | translate"
                (searchChange)="onSearchByMediaTitle($event)"></app-search>
        </div>
        <div>
            <mat-button-toggle-group
                class="malou-group-toggle-btn"
                [value]="(filters$ | async)?.isNeverUsed"
                (change)="onEditNeverPosterOnlyFilter($event)">
                <mat-button-toggle [value]="false">
                    {{ 'media_picker_modal.all_media' | translate }}
                </mat-button-toggle>
                <mat-button-toggle [value]="true">
                    {{ 'media_picker_modal.never_posted_only' | translate }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div
        class="malou-dialog__body overflow-hidden"
        infinite-scroll
        [infiniteScrollDistance]="2"
        [scrollWindow]="false"
        [infiniteScrollContainer]="scrollContainerSelector"
        (scrolled)="onScrollDown()"
        #scrollContainerSelector>
        @if (!isLoadingFolder()) {
            <div>
                <div class="mb-5 hidden md:block">
                    <div class="grow md:mb-2">
                        <app-search
                            [debounceTime]="500"
                            [placeholder]="'common.search' | translate"
                            (searchChange)="onSearchByMediaTitle($event)"></app-search>
                    </div>
                </div>
                <app-folder-picker
                    [folders]="folders()"
                    [currentFolder]="currentFolder$ | async"
                    (openFolder)="onOpenFolder($event)"></app-folder-picker>
                @if (!isLoadingMedias()) {
                    <div class="flex h-full flex-col">
                        <div class="malou-text__card-title mb-4 text-malou-color-text-1 md:hidden">
                            {{ 'media_picker_modal.media' | translate }}
                        </div>
                        @if (medias().length > 0) {
                            <div class="grow">
                                <app-media-picker
                                    [selectedMedias]="selectedMedias"
                                    [medias]="medias"
                                    [multi]="multi"
                                    (selected)="onMediaSelect($event)">
                                </app-media-picker>
                            </div>
                        } @else {
                            <div class="grid grow place-items-center">
                                <div class="flex flex-col items-center gap-y-3">
                                    <img height="90" width="90" [src]="'Goggles' | illustrationPathResolver" />
                                    <div class="malou-text-14--bold">{{ 'media_picker_modal.empty' | translate }}</div>
                                    <div class="malou-text-10--regular text-malou-color-text-2">
                                        {{ 'media_picker_modal.empty_sub' | translate }}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                } @else {
                    <div class="h-full w-full overflow-y-auto pb-[30px]">
                        <div class="mb-5 w-full" id="title-skeleton">
                            <app-skeleton skeletonClass="w-full h-[40px] secondary-bg"></app-skeleton>
                        </div>
                        <div class="grid grid-cols-5 gap-3 md:grid-cols-3" id="media-list-skeleton">
                            @for (item of 16 | toRange; track item) {
                                <app-skeleton skeletonClass="h-[96px] w-full secondary-bg"></app-skeleton>
                            }
                        </div>
                    </div>
                }
            </div>
        } @else {
            <div class="h-full w-full overflow-y-auto pb-[30px]">
                <div class="mb-5 w-full" id="title-skeleton">
                    <app-skeleton skeletonClass="w-full h-[40px] secondary-bg"></app-skeleton>
                </div>
                <div class="grid grid-cols-5 gap-3 md:grid-cols-3" id="media-list-skeleton">
                    @for (item of 16 | toRange; track item) {
                        <app-skeleton skeletonClass="h-[96px] w-full secondary-bg"></app-skeleton>
                    }
                </div>
            </div>
        }
    </div>

    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="cancel()">
            {{ 'forms.buttons.cancel' | translate }}
        </button>
        <button
            class="malou-btn-raised--primary !h-11 md:grow"
            data-testid="media-picker-add-media-btn"
            mat-raised-button
            [disabled]="!selectedMedias.length"
            (click)="submit()">
            {{ 'common.add' | translate }}
        </button>
    </div>
</div>
