<div class="custom-height overflow-scroll px-8.5 py-4" #topOfComponent>
    <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>

    @if ((platformKeys$ | async)?.length !== 0) {
        <div class="mt-6 flex gap-6 md:flex-col">
            <div class="min-w-0 flex-1">
                <app-community
                    (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.COMMUNITY, $event)"
                    (viewByChange)="onViewByChange(InsightsChart.COMMUNITY, $event)"
                    (isLoadingEvent)="isCommunityLoading.set($event)">
                </app-community>
            </div>
            <div class="min-w-0 flex-1">
                <app-engagement
                    (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.ENGAGEMENT, $event)"
                    (viewByChange)="onViewByChange(InsightsChart.ENGAGEMENT, $event)"
                    (isLoadingEvent)="isEngagementLoading.set($event)">
                </app-engagement>
            </div>
        </div>
        <div class="mt-10">
            <ng-container *ngTemplateOutlet="postsAndStories"></ng-container>
        </div>
    } @else {
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [lazyLoad]="'Taster' | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
            <span class="malou-text-10--regular">{{ 'statistics.errors.platforms_not_connected' | translate }}</span>
        </div>
    }
</div>

<ng-template #filtersTemplate>
    <div class="mb-6 flex items-end gap-4 sm:flex-col sm:items-center">
        <div class="flex-1">
            <app-statistics-filters [showPlatformsFilter]="true" [platformFilterPage]="PlatformFilterPage.SOCIAL_NETWORKS">
            </app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--primary !h-12"
            mat-raised-button
            [disabled]="(platformKeys$ | async)?.length === 0 || isLoading()"
            (click)="openStatisticsDownload()">
            {{ 'statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #postsAndStories>
    <app-posts-insights-table
        (tableSortOptionsChange)="onTableSortOptionsChange($event)"
        (isLoadingEvent)="isPostInsightsTableLoading.set($event)">
    </app-posts-insights-table>
</ng-template>
