@if (isLoading) {
    <div class="flex h-full items-center justify-center">
        <app-malou-spinner></app-malou-spinner>
    </div>
} @else {
    <div class="flex h-full flex-col">
        <app-navigation-header containerClass="mx-8.5" [tabs]="tabs"></app-navigation-header>
        <div class="min-h-0 grow overflow-y-auto pb-14">
            <router-outlet></router-outlet>
        </div>
    </div>
}
