import { Routes } from '@angular/router';

import { RoiAggregatedRestaurantsGuard } from ':core/auth/roi.guard';

import { AggregatedStatisticsComponent } from './aggregated-statistics.component';
import { BoostersComponent } from './boosters/boosters.component';
import { EReputationComponent } from './e-reputation/e-reputation.component';
import { RoiComponent } from './roi/roi.component';
import { SeoComponent } from './seo/seo.component';
import { SocialNetworksComponent } from './social-networks/social-networks.component';

export const AGGREGATED_STATISTICS_ROUTES: Routes = [
    {
        path: '',
        component: AggregatedStatisticsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'roi',
            },
            {
                path: 'roi',
                component: RoiComponent,
                canActivate: [RoiAggregatedRestaurantsGuard],
            },
            {
                path: 'seo',
                component: SeoComponent,
            },
            {
                path: 'e-reputation',
                component: EReputationComponent,
            },
            {
                path: 'social-networks',
                component: SocialNetworksComponent,
            },
            {
                path: 'totems',
                redirectTo: 'boosters',
            },
            {
                path: 'boosters',
                component: BoostersComponent,
            },
        ],
    },
];
