import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import * as RestaurantsActions from '../../restaurant-list.actions';

@Component({
    standalone: true,
    selector: 'app-edit-internal-name-modal',
    templateUrl: './edit-internal-name-modal.component.html',
    styleUrls: ['./edit-internal-name-modal.component.scss'],
    imports: [MatButtonModule, TranslateModule, MatIconModule, InputTextComponent, FormsModule, ReactiveFormsModule],
})
export class EditInternalNameModalComponent {
    readonly SvgIcon = SvgIcon;
    internalNameForm: FormGroup = this._fb.group({
        internalNameControl: [this.data.restaurant.internalName, Validators.required],
    });

    constructor(
        private readonly _dialogRef: MatDialogRef<EditInternalNameModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            restaurant: Restaurant;
        },
        private readonly _fb: FormBuilder,
        private _restaurantService: RestaurantsService,
        private readonly _store: Store,
        private readonly _toastService: ToastService,
        private readonly _translate: TranslateService
    ) {}

    cancel(): void {
        this._dialogRef.close();
    }

    resetInternalName(): void {
        this.internalNameForm.get('internalNameControl')?.setValue(this.data.restaurant.name);
    }

    save(): void {
        const updatedInternalName = this.internalNameForm.get('internalNameControl')?.value.trim();
        this._restaurantService.update(this.data.restaurant._id, { internalName: updatedInternalName }).subscribe({
            next: (res) => {
                const restaurant = new Restaurant(res.data);
                this._store.dispatch({ type: RestaurantsActions.editRestaurant.type, restaurant });
                this._toastService.openSuccessToast(this._translate.instant('restaurant_list.internal_name_updated'));
                this._dialogRef.close();
            },
            error: (err) => {
                if (err.status === 409 && err.error?.message.match(/Duplicate entry/)) {
                    this.internalNameForm.get('internalNameControl')?.setErrors({ duplicate: true });
                } else {
                    console.warn('error :>>', err);
                    this._toastService.openErrorToast(this._translate.instant('common.unknown_error'));
                    this._dialogRef.close();
                }
            },
        });
    }
}
