<app-modal-structure
    [title]="translations().title | translate"
    [primaryButtonText]="translations().primaryButton | translate"
    [primaryButtonDisabled]="loginControl.invalid || passwordControl.invalid"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })">
    <div class="flex flex-col gap-y-4">
        <div class="malou-text-12--bold malou-color-text-1 mb-4">{{ translations().description | translate }}</div>
        <app-input-text
            [testId]="'password-managed-connection-email-input'"
            [formControl]="loginControl"
            [showRequiredStar]="true"
            [title]="translations().login | translate"
            [placeholder]="'address@email.com'"
            [errorMessage]="isEmailInError() ? (translations().invalidEmail | translate) : undefined"
            [autocapitalize]="'none'">
        </app-input-text>
        <app-input-text
            [testId]="'password-managed-connection-password-input'"
            [autocomplete]="isSafari ? 'current-password' : 'new-password'"
            [formControl]="passwordControl"
            [inputType]="'password'"
            [password]="true"
            [showEyeIcon]="true"
            [showRequiredStar]="true"
            [title]="translations().password | translate"
            [placeholder]="'xxxx'"
            [autocapitalize]="'none'">
        </app-input-text>
    </div>
</app-modal-structure>
