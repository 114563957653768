import { Routes } from '@angular/router';

import { INFORMATIONS_ROUTES } from ':modules/informations/informations-routing';
import { POSTS_ROUTES } from ':modules/posts/posts.routing';

import { SeoComponent } from './seo.component';

export const SEO_ROUTES: Routes = [
    {
        path: '',
        component: SeoComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'informations',
            },
            {
                path: 'informations',
                loadChildren: () => INFORMATIONS_ROUTES,
            },
            {
                path: 'posts',
                loadChildren: () => POSTS_ROUTES,
            },
        ],
    },
];
