export function groupBy(array, callback): any[] {
    const groups = {};
    array.forEach(function (o) {
        const group = callback(o);
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
        return groups[group];
    });
}
