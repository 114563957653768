import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';

import { PictureSize, REEL_MAX_VIDEO_SIZE } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import { MediaPickerFilter } from ':modules/media/media-picker-modal/media-picker-modal.interface';
import { MediaFileUploaderComponent } from ':shared/components/media-file-uploader/media-file-uploader.component';
import { MediaThumbnailPickerComponent } from ':shared/components/media-thumbnail-picker/media-thumbnail-picker.component';
import { DndDirective } from ':shared/directives/dnd.directive';
import { isSafari } from ':shared/helpers/is-safari';
import { Media } from ':shared/models';
import { ThumbnailChange } from ':shared/models/thumbnail-change';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const DEFAULT_THUMBNAIL_OFFSET_TIME = 1000;
@Component({
    selector: 'app-reels-editor',
    templateUrl: './reels-editor.component.html',
    styleUrls: ['./reels-editor.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        MediaFileUploaderComponent,
        ApplyPurePipe,
        NgClass,
        IllustrationPathResolverPipe,
        TranslateModule,
        NgTemplateOutlet,
        MatProgressBarModule,
        DndDirective,
        MatIconModule,
        MatButtonModule,
        NgStyle,
        MediaThumbnailPickerComponent,
    ],
})
export class ReelsEditorComponent implements OnInit, AfterViewInit {
    @Input() media: Media | undefined;
    @Input() thumbnail?: Media | undefined;
    @Input() thumbnailOffsetTimeInMs?: number | undefined = DEFAULT_THUMBNAIL_OFFSET_TIME;
    @Input() disabled = false;
    @Output() fileChanged = new EventEmitter<Media[]>();
    @Output() mediaSelected = new EventEmitter<Media[]>();
    @Output() thumbnailChange: EventEmitter<ThumbnailChange> = new EventEmitter<ThumbnailChange>();

    readonly maxVideoSize = REEL_MAX_VIDEO_SIZE;
    readonly maxNumberOfFiles = 1;
    readonly landscapeRatio = 16 / 9;
    readonly isSafari = isSafari;

    videoPlayerElement?: HTMLVideoElement;
    thumbnailUrl: string | null = null;

    mediaLoadingPercentage = signal(0);
    isLoadingMedia = signal(false);
    draggingOver = signal(false);
    currentMedia = signal<Media | undefined>(undefined);
    isThumbnailLoading = signal(true);
    videoPlayerContainerWidth = signal('100%');

    readonly SvgIcon = SvgIcon;

    private readonly _toastService = inject(ToastService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    get currentMediaUrl(): string | undefined {
        return this.currentMedia()?.getMediaUrl();
    }

    @ViewChild('videoPlayer', { static: false }) set videoPlayerRef(videoPlayerRef: ElementRef<HTMLVideoElement>) {
        if (videoPlayerRef) {
            this.videoPlayerElement = videoPlayerRef.nativeElement;
        }
    }

    ngOnInit(): void {
        this.currentMedia.set(this.media);
        this.thumbnailUrl = this.thumbnail?.getMediaUrl(PictureSize.IG_FIT) ?? null;
    }

    ngAfterViewInit(): void {
        if (this.currentMedia()) {
            this._setReelContainerSize();
        }
    }

    onUploadFinished(createdMedias: Media[]): void {
        const newMedia = createdMedias[0];
        this.isLoadingMedia.set(false);
        this.mediaLoadingPercentage.set(0);
        this.currentMedia.set(newMedia);
        this.fileChanged.emit(createdMedias);
        this._setReelContainerSize();
    }

    onUploadStarted(): void {
        this.mediaLoadingPercentage.set(0);
        this.isLoadingMedia.set(true);
    }

    processError(error: Error | string): void {
        this.mediaLoadingPercentage.set(0);
        this.isLoadingMedia.set(false);
        const errorMessage = typeof error === 'string' ? error : (error.message ?? error.toString());
        this._toastService.openErrorToast(errorMessage);
    }

    dragOver(): void {
        if (!this.currentMedia()) {
            this.draggingOver.set(true);
        }
    }

    dragLeave(): void {
        this.draggingOver.set(false);
    }

    openMediaPickerModal(): void {
        this._customDialogService
            .open(MediaPickerModalComponent, {
                width: '600px',
                data: {
                    restaurant: this._restaurantsService.currentRestaurant,
                    multi: false,
                    filter: MediaPickerFilter.ONLY_VIDEO,
                    maxVideoSize: this.maxVideoSize,
                },
            })
            .afterClosed()
            .pipe(filter((medias) => !!medias && medias.length > 0))
            .subscribe({
                next: (medias) => {
                    this.currentMedia.set(medias[0]);
                    this._setReelContainerSize();
                    this.mediaSelected.emit(medias);
                },
            });
    }

    onProgressChanged(progress: number): void {
        this.mediaLoadingPercentage.set(progress);
    }

    playVideo(): void {
        if (!this.videoPlayerElement) {
            return;
        }
        if (this.videoPlayerElement?.paused) {
            this.videoPlayerElement.play();
        } else {
            this.videoPlayerElement.pause();
        }
    }

    muteUnmuteVideo(): void {
        if (!this.videoPlayerElement) {
            return;
        }
        this.videoPlayerElement.muted = !this.videoPlayerElement.muted;
    }

    isVideoMuted(): boolean {
        return !!this.videoPlayerElement?.muted;
    }

    isVideoPlaying(): boolean {
        return !this.videoPlayerElement?.paused;
    }

    removeMedia(): void {
        this.thumbnailOffsetTimeInMs = DEFAULT_THUMBNAIL_OFFSET_TIME;
        this.thumbnail = undefined;
        this.thumbnailUrl = null;
        this.currentMedia.set(undefined);
        this.mediaSelected.emit([]);
    }

    onThumbnailChanged(thumbnailChange: ThumbnailChange): void {
        this.thumbnailChange.emit(thumbnailChange);
        if (thumbnailChange.thumbnail) {
            this.thumbnail = thumbnailChange.thumbnail;
            this.thumbnailUrl = thumbnailChange.thumbnail.getMediaUrl(PictureSize.IG_FIT);
            this.thumbnailOffsetTimeInMs = undefined;
            this._resetPoster();
            return;
        } else if (thumbnailChange.thumbnailOffsetTimeAndUrl) {
            this.thumbnailUrl = thumbnailChange.thumbnailOffsetTimeAndUrl.thumbnailUrl;
            this.thumbnailOffsetTimeInMs = thumbnailChange.thumbnailOffsetTimeAndUrl.thumbnailOffsetTimeInMs;
            this.thumbnail = undefined;
            this._resetPoster();
            return;
        }
        this.thumbnailUrl = this.currentMedia()?.thumbnail || null;
        this.thumbnail = undefined;
        this._resetPoster();
    }

    onSliderImagesLoaded(): void {
        this.isThumbnailLoading.set(false);
    }

    private _setReelContainerSize(): void {
        if (isSafari) {
            setTimeout(() => {
                const reelContainer = document.getElementById('reelContainer');
                this.videoPlayerContainerWidth.set((reelContainer!.offsetHeight / 16) * 9 + 'px');
            }, 100);
        }
    }

    private _resetPoster(): void {
        const thumbnail = this.thumbnail;
        const thumbnailOffsetTimeInMs = this.thumbnailOffsetTimeInMs;
        const thumbnailUrl = this.thumbnailUrl;
        this.thumbnail = undefined;
        this.thumbnailOffsetTimeInMs = undefined;
        this.thumbnailUrl = null;
        this._changeDetectorRef.detectChanges();
        this.thumbnail = thumbnail;
        this.thumbnailOffsetTimeInMs = thumbnailOffsetTimeInMs;
        this.thumbnailUrl = thumbnailUrl;
    }
}
