import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UppyFile } from '@uppy/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { GetVideoInformationBodyDto, GetVideoInformationResponseDto } from '@malou-io/package-dto';
import { ApiResultV2, MediaCategory, PictureSizeRecord } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { environment } from ':environments/environment';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { objectToQueryParams } from ':shared/helpers/query-params';
import { ApiResult, GalleryFilters, Media, Pagination, UppyTemporaryMedia } from ':shared/models';

import { PlatformMedia, PlatformMediaDownloaderService } from './utils/platform-media-downloader.service';

@Injectable({
    providedIn: 'root',
})
export class MediaService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/media`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _platformMediaDownloaderService: PlatformMediaDownloaderService
    ) {}

    uploadPlatformMedias(restaurantId: string, platformMedias: PlatformMedia[] | null | undefined): Observable<Media[]> {
        if (!platformMedias) {
            return of([]);
        }
        return this._platformMediaDownloaderService.downloadMedia(platformMedias).pipe(
            switchMap((downloadedMedias) =>
                this.uploadAndCreateMedia(
                    downloadedMedias.map((media) => ({
                        data: media.file,
                        metadata: {
                            category: media.category,
                            restaurantId,
                            title: media.name,
                            description: media.description,
                        },
                    }))
                )
            ),
            map((res) => res.data)
        );
    }

    getRestaurantMediasPaginated(
        restaurantId: string,
        pagination: Pagination,
        filters: GalleryFilters & { maxVideoSize?: number },
        folderId: string | null
    ): Observable<
        ApiResult<{
            medias: Media[];
            pagination: Pagination;
        }>
    > {
        const cleanFilters = removeNullOrUndefinedField({ ...pagination, ...filters });

        if (folderId || !filters.title) {
            cleanFilters.folderId = folderId;
        }

        return this._http
            .get<ApiResult<{ medias: Media[]; pagination: Pagination }>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, {
                params: objectToSnakeCase(cleanFilters),
                withCredentials: true,
            })
            .pipe(
                map((res) => {
                    res.data.medias = res.data.medias?.map((e) => new Media(e));
                    return res;
                })
            );
    }

    getMediumById(mediumId: string): Observable<ApiResult<Media>> {
        return this._http.get<ApiResult<Media>>(`${this.API_BASE_URL}/${mediumId}`).pipe(
            map((res) => {
                res.data = new Media(res.data);
                return res;
            })
        );
    }

    replaceMediaUrls(
        mediumId: string,
        file: File,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<Media>> {
        const uploadData = new FormData();
        uploadData.append('media', file);
        return this._http
            .post<ApiResult<Media>>(`${this.API_BASE_URL}/${mediumId}/replace`, uploadData, {
                withCredentials: true,
                params: { restaurant_id: restaurantId },
            })
            .pipe(
                map((res) => {
                    res.data = new Media(res.data);
                    return res;
                })
            );
    }

    updateMediaById(
        mediumId: string,
        media: Partial<Media>,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<Media>> {
        return this._http
            .put<
                ApiResult<Media>
            >(`${this.API_BASE_URL}/${mediumId}`, { media }, { withCredentials: true, params: { restaurant_id: restaurantId } })
            .pipe(
                map((res) => {
                    res.data = new Media(res.data);
                    return res;
                })
            );
    }

    deleteMedia(mediaIds: string[], restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        const params = objectToQueryParams({ mediaIds });
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, { withCredentials: true, params });
    }

    duplicateMediaForRestaurants(restaurantId: string, originalMedia: Media[], restaurantIds: string[]): Observable<ApiResult<Media[]>> {
        return this._http
            .post<
                ApiResult<Media[]>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/duplicate`, { originalMedia, restaurantIds }, { withCredentials: true })
            .pipe(
                map((res) => {
                    res.data = res.data.map((m) => new Media(m));
                    return res;
                })
            );
    }

    downloadSingleMedia(media: Media): Observable<Blob> {
        // add fingerprint otherwise the browser will cache the file and we cannot download
        // better solution would be to download directly from browser cache ?
        return this._http.get(media.getMediaUrl() + '?fingerprint=' + Math.random(), { responseType: 'blob' });
    }

    /**
     * Only upload aws url for media (especially used for conversation messages)
     */
    uploadOnly(
        category: string,
        file: File,
        data: { entityRelated: string; entityId: string }
    ): Observable<ApiResult<{ urls: PictureSizeRecord<string>; sizes: PictureSizeRecord<number> }>> {
        const uploadData = new FormData();
        const cleanFilters = objectToQueryParams({
            category,
            entityRelated: data.entityRelated,
            entityId: data.entityId,
        });
        uploadData.append('media', file);
        return this._http.post<ApiResult<{ urls: PictureSizeRecord<string>; sizes: PictureSizeRecord<number> }>>(
            `${this.API_BASE_URL}/upload_only`,
            uploadData,
            {
                params: cleanFilters,
                withCredentials: true,
            }
        );
    }

    getUploadParams(file: UppyFile): Observable<{
        method: any;
        url: any;
        fields: any;
        headers: any;
    }> {
        return this._http
            .post<ApiResult>(
                `${this.API_BASE_URL}/cloud-storage-upload-params`,
                { file },
                { withCredentials: true, params: { restaurant_id: this._restaurantsService.currentRestaurant._id } }
            )
            .pipe(
                map((res) => ({
                    method: res.data.method,
                    url: res.data.url,
                    fields: res.data.fields,
                    headers: res.data.headers,
                }))
            );
    }

    createManyMedias(medias: UppyTemporaryMedia[], folderId: string | null = null): Observable<ApiResult<Media[]>> {
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        const mediasToUpload = medias.map((media) => ({
            name: media.name,
            sizes: media.sizes,
            urls: media.urls,
            format: media.extension.toLowerCase(),
            type: media.type,
            dimensions: media.dimensions,
            duration: media.duration,
            folderId,
        }));
        return this._http
            .post<
                ApiResult<Media[]>
            >(`${this.API_BASE_URL}/create`, { medias: mediasToUpload }, { withCredentials: true, params: { restaurant_id: restaurantId } })
            .pipe(
                map((res) => {
                    res.data = res.data.map((m) => new Media(m));
                    return res;
                })
            );
    }

    getManyMedia(mediaIds: string[]): Observable<ApiResult<Media[]>> {
        return this._http
            .get<ApiResult<Media[]>>(`${this.API_BASE_URL}`, {
                params: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'media_ids[]': mediaIds,
                },
                withCredentials: true,
            })
            .pipe(
                map((res) => {
                    res.data = res.data.map((m) => new Media(m));
                    return res;
                })
            );
    }

    moveMediaTowardsFolder(mediaIds: string[], folderId: string | null): Observable<void> {
        return this._http.put<void>(`${this.API_BASE_URL}/move`, { folderId, mediaIds });
    }

    getVideoInformation(body: GetVideoInformationBodyDto): Observable<ApiResultV2<GetVideoInformationResponseDto>> {
        return this._http.post<ApiResultV2<GetVideoInformationResponseDto>>(`${this.API_BASE_URL}/get-video-information`, body, {
            withCredentials: true,
        });
    }

    uploadAndCreateMedia(
        files: {
            data: File;
            metadata: {
                category: MediaCategory;
                restaurantId?: string;
                userId?: string;
                title?: string;
                description?: string;
                originalMediaId?: string | null;
                desiredAspectRatio?: number;
            };
        }[]
    ): Observable<ApiResult<Media[]>> {
        const uploadData = new FormData();
        const restaurantId = files[0].metadata.restaurantId;
        const filesMetadata: any[] = [];
        for (const file of files) {
            uploadData.append('media', file.data);
            filesMetadata.push(file.metadata);
        }
        uploadData.append('metadata', JSON.stringify(filesMetadata));

        return this._http.post<ApiResult<Media[]>>(
            `${this.API_BASE_URL}${restaurantId ? `?restaurant_id=${restaurantId}` : ''}`,
            uploadData,
            {
                withCredentials: true,
            }
        );
    }
}
