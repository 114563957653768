import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-inspirations-root',
    templateUrl: './inspirations-root.component.html',
    styleUrls: ['./inspirations-root.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class InspirationsRootComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
