import { AsyncPipe, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CGU_LINK } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { HeaderComponent } from ':shared/components/header/header.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { SelectRestaurantsComponent } from ':shared/components/select-restaurants/select-restaurants.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { EditUserModalComponent } from '../edit-user-modal/edit-user-modal.component';
import { User } from '../user';

interface AppState {
    user: any;
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        HeaderComponent,
        InputDatePickerComponent,
        MalouSpinnerComponent,
        SelectRestaurantsComponent,
        SlideToggleComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ShortTextPipe,
        TitleCasePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ProfileComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject();

    user: User | null = null;

    shouldRedirect = false;

    readonly CGU_LINK_DATA = CGU_LINK;
    readonly Illustration = Illustration;

    constructor(
        private readonly _store: Store<AppState>,
        private readonly _customDialogService: CustomDialogService,
        private readonly _screenSizeService: ScreenSizeService
    ) {
        // need to load it once to trigger animations 'appearFromBottom' of modal
        this._screenSizeService.resize$.pipe(takeUntil(this.killSubscriptions$)).subscribe();
    }

    ngOnInit(): void {
        if (this.shouldRedirect) {
            return;
        }

        this._store.select(selectUserInfos).subscribe((user) => {
            this.user = user;
        });
    }

    openEditUserModal(): void {
        this._customDialogService
            .open(EditUserModalComponent, {
                width: '650px',
                height: 'auto',
                disableClose: false,
                data: {
                    user: this.user,
                },
            })
            .afterClosed();
    }
}
