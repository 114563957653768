import { NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, filter, startWith, switchMap } from 'rxjs';

import { APP_DEFAULT_LANGUAGE, BricksCategory, BrickType, isNotNil } from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { ApiLocationOption, ApiLocationType } from ':modules/generator/generator.component';
import { InputAutocompleteComponent } from ':shared/components/input-autocomplete/input-autocomplete.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { BaseStepComponent } from ':shared/components/stepper-modal/base-step.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { createCustomBrick } from ':shared/helpers/create-custom-brick';
import { IDisplayable } from ':shared/interfaces';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Brick, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

export interface PersonalizeGeneratorLocationsData {
    restaurant: Restaurant;
    locationForm: INullableFormGroup<{
        apiLocation: ApiLocationOption | null | undefined;
        touristics: string[];
        postalCode: string;
    }>;
}

@Component({
    selector: 'app-personalize-generator-locations',
    templateUrl: './personalize-generator-locations.component.html',
    styleUrls: ['./personalize-generator-locations.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        InputTextComponent,
        SearchComponent,
        SelectChipListComponent,
        ApplyPurePipe,
        ImagePathResolverPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        InputAutocompleteComponent,
    ],
})
export class PersonalizeGeneratorLocationsComponent extends BaseStepComponent<PersonalizeGeneratorLocationsData[]> implements OnInit {
    readonly BricksCategory = BricksCategory;
    currentLang = localStorage.getItem(LocalStorageKey.LANG) || APP_DEFAULT_LANGUAGE;
    recommendedTouristics: Record<string, Brick[]> = {};
    readonly apiLocationOptions: WritableSignal<ApiLocationOption[]> = signal([]);
    readonly filteredInputData: WritableSignal<PersonalizeGeneratorLocationsData[]> = signal([]);
    readonly apiLocationErrorMessage: WritableSignal<{ [restaurantId: string]: string }> = signal({});

    readonly SvgIcon = SvgIcon;

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _translateService: TranslateService,
        private readonly _keywordsService: KeywordsService,
        private readonly _httpClient: HttpClient
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();

        this._initApiLocationOptions();
        this.filteredInputData.set(this.inputData);
        this.inputData.forEach((restaurantAndLocationData) => {
            this._initGeneratedBricks(restaurantAndLocationData);
        });
    }

    onSearchChange(search: string): void {
        this.filteredInputData.set(
            this.inputData.filter((data) => data.restaurant.name.toLowerCase().includes(search.trim().toLowerCase()))
        );
    }

    displayBrickWith(brick: Brick): string {
        return brick.getDisplayedValue();
    }

    listChanged(value: IDisplayable[], formGroup: FormGroup): void {
        if (value.length >= 0) {
            formGroup.get('touristics')?.setValue(value);
            formGroup.get('touristics')?.markAsDirty();
        }
    }

    buildNewItem = (category: BricksCategory): ((text: string) => Brick) => {
        const currentLang = this.currentLang;
        return function (text: string) {
            return createCustomBrick(text, category, currentLang);
        };
    };

    trackById(_: number, item: Brick): string {
        return item?._id;
    }

    apiLocationFilterFn = (value: string, option: ApiLocationOption): boolean => {
        const words = value.split(' ');
        return words.every(
            (word) =>
                [ApiLocationType.CITY, ApiLocationType.COUNTRY, ApiLocationType.REGION].includes(option.apiLocationType) &&
                option.apiCanonicalName.toLowerCase().includes(word.toLowerCase())
        );
    };
    apiLocationWriteValueFn = (option: ApiLocationOption): string => option.apiCanonicalName;
    apiLocationDisplayWithFn = (option: ApiLocationOption): string => option.apiCanonicalName;

    onApiLocationSelected(option: ApiLocationOption | null | undefined, restaurantId: string): void {
        const restaurantApiLocationControl = this.inputData
            .find((data) => data.restaurant._id === restaurantId)
            ?.locationForm.get(['apiLocation']);

        if (restaurantApiLocationControl) {
            restaurantApiLocationControl.setValue(option);
            this.onApiLocationDirty(restaurantId);
        }

        this.valid.emit(this._isValid());
    }

    onApiLocationDirty(restaurantId: string): void {
        const restaurantApiLocationControl = this.inputData
            .find((data) => data.restaurant._id === restaurantId)
            ?.locationForm.get(['apiLocation']);
        if (restaurantApiLocationControl) {
            restaurantApiLocationControl.markAsDirty();
            this.apiLocationErrorMessage.set({
                ...this.apiLocationErrorMessage(),
                [restaurantId]: restaurantApiLocationControl.value ? '' : this._translateService.instant('common.required_field'),
            });
        }
    }

    onInputTextChange(_text: string): void {
        this.valid.emit(this._isValid());
    }

    getLocationAutocompleteId = (restaurantId: string): string => `apiLocation_${restaurantId}_autocomplete`;

    protected _submitData(): PersonalizeGeneratorLocationsData[] {
        return this.inputData;
    }

    protected _isValid(): boolean {
        return this.inputData.every((restaurantAndLocationData) => restaurantAndLocationData.locationForm.valid);
    }

    private _initGeneratedBricks(restaurantWithLocationData: PersonalizeGeneratorLocationsData): void {
        const { address, bricksPostalCode } = restaurantWithLocationData.restaurant;
        restaurantWithLocationData.locationForm
            .get('postalCode')
            ?.valueChanges.pipe(
                startWith(bricksPostalCode || address?.postalCode),
                filter(isNotNil),
                debounceTime(500),
                switchMap((postalCode) => this._keywordsService.getGeneratedBricks(postalCode, BrickType.POSTAL_CODE_TO_TOURISTIC_AREA)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (bricks) => {
                    if (bricks) {
                        const recommendedTouristics = this._getRecommendedTouristics(bricks);
                        this.recommendedTouristics[restaurantWithLocationData.restaurant._id] = recommendedTouristics;
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    private _getRecommendedTouristics(bricks: Brick[]): Brick[] {
        return bricks
            .filter((b) => [BricksCategory.STATION, BricksCategory.TOURISTIC_AREA].includes(b.category))
            .sort((a, b) => {
                if (a.meanSearchVol > b.meanSearchVol) {
                    return -1;
                } else {
                    return 1;
                }
            })
            .map((b) => new Brick(b, this.currentLang));
    }

    private _initApiLocationOptions(): void {
        this._httpClient.get('../../../assets/jsons/api_locations.json').subscribe((res: ApiLocationOption[]) => {
            this.apiLocationOptions.set(res);
        });
    }
}
