import { isEqual, omit } from 'lodash';

import { Day, RemoveMethodsFromClass } from '@malou-io/package-utils';

// we use the JS convention for dates (January = 0)
export class MyDate {
    day: number = new Date().getDate();
    month: number = new Date().getMonth();
    year: number = new Date().getFullYear();

    public constructor(init?: Partial<MyDate>) {
        Object.assign(this, init);
    }

    getDate(): Date {
        return new Date(this.year, this.month, this.day);
    }

    setDate(date: Date): void {
        this.day = date.getDate();
        this.month = date.getMonth();
        this.year = date.getFullYear();
    }

    toString(): string {
        return `${this.getDate().toDateString()}`;
    }

    equals(o: any): boolean {
        return JSON.stringify(this) === JSON.stringify(o);
    }
}

export class Period {
    openTime?: string | null;
    closeTime?: string | null;
    isClosed: boolean;

    shouldBeClosed(): boolean {
        return this.openTime === '00:00' && this.closeTime === '00:00' && !this.isClosed;
    }

    close(): void {
        this.isClosed = true;
        this.openTime = null;
        this.closeTime = null;
    }
}

export class TimePeriod extends Period {
    openDay: Day;
    closeDay: Day;
    isPrimaryPeriod: boolean; // Primary periods are the first periods of each day, created by default and can't be deleted.

    public constructor(init?: Partial<TimePeriod>) {
        super();
        Object.assign(this, init);
    }

    toString(): string {
        return `${this.openDay} : (${this.isClosed ? 'closed' : 'open'}): ${this.openTime} - ${this.closeTime}`;
    }

    equals(o: any): boolean {
        if (!(o instanceof TimePeriod)) {
            return false;
        }
        return isEqual(omit(this, 'isPrimaryPeriod'), omit(o, 'isPrimaryPeriod'));
    }

    hasSameHours(o: any): boolean {
        if (!(o instanceof TimePeriod)) {
            return false;
        }
        return isEqual(omit(this, 'openDay', 'closeDay'), omit(o, 'openDay', 'closeDay'));
    }

    isFullDay(): boolean {
        return this.openTime === '24-24';
    }

    cleanFullDay(): void {
        this.openTime = '00:00';
        this.closeTime = '24:00';
    }
}

export class SpecialTimePeriod extends Period {
    startDate: MyDate;
    endDate: MyDate;

    public constructor(init?: Partial<Period & { startDate: RemoveMethodsFromClass<MyDate>; endDate: RemoveMethodsFromClass<MyDate> }>) {
        super();
        Object.assign(this, init);
        if (init?.startDate) {
            this.startDate = new MyDate(init.startDate);
        }
        if (init?.endDate) {
            this.endDate = new MyDate(init.endDate);
        }
    }

    setStartDate(d: Date): void {
        this.startDate = new MyDate();
        this.startDate.setDate(d);
    }

    toString(): string {
        return `${this.startDate.toString()} : ${this.openTime} - ${this.endDate.toString()}: ${this.closeTime} (${
            this.isClosed ? 'closed' : 'open'
        })`;
    }
}

export class HoursType {
    _id: string;
    hoursType: string;
    hoursTypeName: Record<string, string>;
    platformKey: string;

    public constructor(init?: Partial<HoursType>) {
        Object.assign(this, init);
    }

    getLocaleName(lang: string): string {
        return this.hoursTypeName?.[lang] || this.hoursTypeName?.default || '';
    }
}

export interface IOtherPeriod {
    hoursType: HoursType;
    periods: TimePeriod[];
}

interface OtherPeriodProps {
    hoursType: RemoveMethodsFromClass<HoursType>;
    periods: RemoveMethodsFromClass<TimePeriod>[];
}

export class OtherPeriod implements IOtherPeriod {
    hoursType: HoursType;
    periods: TimePeriod[];

    public constructor(init?: Partial<OtherPeriodProps>) {
        this.hoursType = new HoursType(init?.hoursType);
        this.periods = (init?.periods || []).map((u: any) => new TimePeriod(u));
    }
}
