import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';

import { Attribute, RestaurantAttribute } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class InformationsContext {
    readonly allCategoryAttributes = signal<Attribute[]>([]);
    readonly restaurantAttributes = signal<RestaurantAttribute[]>([]);

    readonly openRestaurantDescriptionsModal$ = new Subject<void>();
    readonly openRestaurantAttributesModal$ = new Subject<void>();
    readonly openRestaurantInformationsModal$ = new Subject<void>();
    readonly openRestaurantHoursModal$ = new Subject<void>();
}
