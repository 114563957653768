import { z } from 'zod';

import { NfcsPlatformKey, NfcStar, NfcType } from '@malou-io/package-utils';

import { RestaurantResponseDto } from '../restaurant';
import { TimestampeableDto } from '../utils';
import { objectIdValidator } from '../utils/validators';

// !!! WARNING !!!
// if you make change to this validator, you must also make change to the interface
export const nfcDtoValidator = z.object({
    id: objectIdValidator,
    chipName: z.string().optional(),
    restaurantId: objectIdValidator,
    active: z.boolean(),
    name: z.string().nullish(),
    platformKey: z.nativeEnum(NfcsPlatformKey),
    redirectionLink: z.string().url(),
    notes: z.string().nullish(),
    starsRedirected: z.array(z.nativeEnum(NfcStar)),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
});

// Because on strict false, zod make all properties optional
// So when strict true, uncommented the next line and delete the interface
// export type NfcDto = z.infer<typeof nfcDtoValidator>;

// !!! WARNING !!!
// if you make change to this interface, you must also make change to the validator
export interface NfcDto extends TimestampeableDto {
    id: string;
    chipName?: string;
    restaurantId: string;
    active: boolean;
    name?: string | null;
    platformKey: NfcsPlatformKey;
    redirectionLink: string;
    notes?: string | null;
    starsRedirected: NfcStar[];
    type: NfcType;
}

export interface NfcWithRestaurantDto extends NfcDto {
    restaurant?: RestaurantResponseDto;
}
