import { z } from 'zod';

import { CountryCode, GiftClaimStartDateOption, WheelOfFortuneRedirectionPlatformKey } from '@malou-io/package-utils';

import { giftWithIdDtoValidator, giftWithoutIdDtoValidator } from '../gifts/gifts.dto';
import { mediaValidator } from '../media/media.dto';
import { lightRestaurantValidator } from '../restaurant/restaurant.dto';
import { objectIdValidator } from '../utils/validators';

const wheelOfFortuneDto = z.object({
    restaurants: z.array(lightRestaurantValidator),
    totemIds: z.array(objectIdValidator),
    gifts: z.array(giftWithoutIdDtoValidator),
    parameters: z.object({
        primaryColor: z.string(),
        secondaryColor: z.string(),
        media: mediaValidator.nullable(),
        giftClaimStartDateOption: z.nativeEnum(GiftClaimStartDateOption),
        giftClaimDurationInDays: z.number(),
        redirectionPlatform: z.nativeEnum(WheelOfFortuneRedirectionPlatformKey),
    }),
    startDate: z.coerce.date(),
    endDate: z.coerce.date().nullable(),
});

const wheelOfFortuneWithGiftIdDto = z.object({
    restaurants: z.array(lightRestaurantValidator),
    totemIds: z.array(objectIdValidator),
    gifts: z.array(giftWithIdDtoValidator),
    parameters: z.object({
        primaryColor: z.string(),
        secondaryColor: z.string(),
        media: mediaValidator.nullable(),
        giftClaimStartDateOption: z.nativeEnum(GiftClaimStartDateOption),
        giftClaimDurationInDays: z.number(),
        redirectionPlatform: z.nativeEnum(WheelOfFortuneRedirectionPlatformKey),
    }),
    startDate: z.coerce.date().nullable(),
    endDate: z.coerce.date().nullable(),
});

// ------------------------------------------------------------------------------------------

export const createAggregatedWheelOfFortuneBodyValidator = wheelOfFortuneDto;
export type CreateAggregatedWheelOfFortuneBodyDto = z.infer<typeof createAggregatedWheelOfFortuneBodyValidator>;

// ------------------------------------------------------------------------------------------

export const updateAggregatedWheelOfFortuneParamsValidator = z
    .object({
        aggregated_wheel_of_fortune_id: objectIdValidator,
    })
    .transform((data) => ({
        aggregatedWheelOfFortuneId: data.aggregated_wheel_of_fortune_id,
    }));
export type UpdateAggregatedWheelOfFortuneParamsDto = z.infer<typeof updateAggregatedWheelOfFortuneParamsValidator>;

export const updateAggregatedWheelOfFortuneBodyValidator = wheelOfFortuneWithGiftIdDto;
export type UpdateAggregatedWheelOfFortuneBodyDto = z.infer<typeof updateAggregatedWheelOfFortuneBodyValidator>;

// ------------------------------------------------------------------------------------------

export const getWheelOfFortuneByIdParamsValidator = z
    .object({ wheel_of_fortune_id: objectIdValidator })
    .transform((data) => ({ wheelOfFortuneId: data.wheel_of_fortune_id }));
export type GetWheelOfFortuneByIdDto = z.infer<typeof getWheelOfFortuneByIdParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getWheelOfFortunePlayersParamsValidator = z
    .object({ wheel_of_fortune_id: objectIdValidator, restaurant_id: objectIdValidator })
    .transform((data) => ({ wheelOfFortuneId: data.wheel_of_fortune_id, restaurantId: data.restaurant_id }));
export type GetWheelOfFortunePlayersDto = z.infer<typeof getWheelOfFortunePlayersParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getActiveWheelOfFortuneParamsValidator = z
    .object({ restaurant_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type GetActiveWheelOfFortuneDto = z.infer<typeof getActiveWheelOfFortuneParamsValidator>;

// ------------------------------------------------------------------------------------------

export const updateTotemsForWheelOfFortuneParamsValidator = z
    .object({ wheel_of_fortune_id: objectIdValidator })
    .transform((data) => ({ wheelOfFortuneId: data.wheel_of_fortune_id }));
export type UpdateTotemsForWheelOfFortuneDto = z.infer<typeof updateTotemsForWheelOfFortuneParamsValidator>;

export const updateTotemsForWheelOfFortuneBodyValidator = z.object({
    totemIds: z.array(objectIdValidator),
});
export type UpdateTotemsForWheelOfFortuneBodyDto = z.infer<typeof updateTotemsForWheelOfFortuneBodyValidator>;

// ------------------------------------------------------------------------------------------

export const deleteWheelOfFortuneByIdParamsValidator = z
    .object({ wheel_of_fortune_id: objectIdValidator })
    .transform((data) => ({ wheelOfFortuneId: data.wheel_of_fortune_id }));
export type DeleteWheelOfFortuneByIdDto = z.infer<typeof deleteWheelOfFortuneByIdParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getActiveAndScheduledRestaurantWheelOfFortuneParamsValidator = z
    .object({ restaurant_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type GetActiveAndScheduledRestaurantWheelOfFortuneParamsDto = z.infer<
    typeof getActiveAndScheduledRestaurantWheelOfFortuneParamsValidator
>;

// ------------------------------------------------------------------------------------------

export const getPreviousRestaurantWheelOfFortuneParamsValidator = z
    .object({ restaurant_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type GetPreviousRestaurantWheelOfFortuneParamsDto = z.infer<typeof getPreviousRestaurantWheelOfFortuneParamsValidator>;

// ------------------------------------------------------------------------------------------

export const updateRestaurantWheelOfFortuneParamsValidator = z
    .object({ restaurant_wheel_of_fortune_id: objectIdValidator })
    .transform((data) => ({ restaurantWheelOfFortuneId: data.restaurant_wheel_of_fortune_id }));
export type UpdateRestaurantWheelOfFortuneParamsDto = z.infer<typeof updateRestaurantWheelOfFortuneParamsValidator>;

export const updateRestaurantWheelOfFortuneBodyValidator = wheelOfFortuneWithGiftIdDto;
export type UpdateRestaurantWheelOfFortuneBodyDto = z.infer<typeof updateRestaurantWheelOfFortuneBodyValidator>;

// ------------------------------------------------------------------------------------------

export const upsertRestaurantWheelOfFortuneBodyValidator = wheelOfFortuneDto;
export type UpsertRestaurantWheelOfFortuneBodyDto = z.infer<typeof upsertRestaurantWheelOfFortuneBodyValidator>;

// ------------------------------------------------------------------------------------------

export const haveAtLeastOneWheelOfFortuneBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
});
export type HaveAtLeastOneWheelOfFortuneBodyDto = z.infer<typeof haveAtLeastOneWheelOfFortuneBodyValidator>;

// ------------------------------------------------------------------------------------------

export const subscriptionRequestBodyValidator = z.object({
    restaurants: z.array(
        z.object({
            _id: objectIdValidator,
            name: z.string(),
            address: z.object({
                regionCode: z.nativeEnum(CountryCode),
                country: z.string(),
                postalCode: z.string(),
                locality: z.string(),
                formattedAddress: z.string(),
            }),
        })
    ),
});
export type SubscriptionRequestBodyDto = z.infer<typeof subscriptionRequestBodyValidator>;
