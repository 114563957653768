import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    inject,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { catchError, combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { ReviewResponseDto } from '@malou-io/package-dto';
import { InsightsChart, InsightsTab, isNotNil } from '@malou-io/package-utils';

import { NfcService } from ':core/services/nfc.service';
import { PrivateReviewsService } from ':core/services/private-reviews.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { PrivateReviewStatisticsData, WheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { PrivateReviewCountComponent } from ':modules/statistics/boosters/private-review-count/private-review-count.component';
import { ScanCountComponent } from ':modules/statistics/boosters/scan-count/scan-count.component';
import { TotemsEstimatedReviewCountComponent } from ':modules/statistics/boosters/totems-estimated-review-count/totems-estimated-review-count.component';
import { FiltersComponent } from ':modules/statistics/filters/filters.component';
import { CommunityComponent } from ':modules/statistics/social-networks/community/community.component';
import { EngagementComponent } from ':modules/statistics/social-networks/engagement/engagement.component';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { selectDatesFilter } from ':modules/statistics/store/statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, Nfc } from ':shared/models';
import { ScanForStats } from ':shared/models/scan';
import { ExperimentationPipe } from ':shared/pipes/experimentation.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { BoostersDataFetchingService } from './services/get-boosters-data.service';
import { WheelOfFortuneGiftsDistributionComponent } from './wheel-of-fortune-gifts-distribution/wheel-of-fortune-gifts-distribution.component';
import { WheelOfFortuneGiftsKpisComponent } from './wheel-of-fortune-gifts-kpis/wheel-of-fortune-gifts-kpis.component';

export interface BoostersStatisticsData {
    nfcs: Nfc[];
    scans: ScanForStats[];
    previousScans: ScanForStats[];
    previousStartDate: Date;
    wheelOfFortuneCount: number;
    previousWheelOfFortuneCount: number;
    startDate: Date;
    endDate: Date;
}

@Component({
    selector: 'app-boosters',
    templateUrl: './boosters.component.html',
    styleUrls: ['./boosters.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        LazyLoadImageModule,
        TranslateModule,
        CommunityComponent,
        EngagementComponent,
        FiltersComponent,
        PrivateReviewCountComponent,
        ScanCountComponent,
        SkeletonComponent,
        TotemsEstimatedReviewCountComponent,
        WheelOfFortuneGiftsDistributionComponent,
        WheelOfFortuneGiftsKpisComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        MatButtonModule,
        ExperimentationPipe,
    ],
})
export class BoostersComponent implements AfterViewInit {
    @ViewChild('topOfComponent') topOfComponent: ElementRef<HTMLElement>;
    isLoadingBoosters: WritableSignal<boolean> = signal(true);
    isErrorBoosters: WritableSignal<boolean> = signal(false);
    isLoadingGifts: WritableSignal<boolean> = signal(true);
    isErrorGifts: WritableSignal<boolean> = signal(false);
    isPrivateReviewsLoading: WritableSignal<boolean> = signal(true);
    isPrivateReviewsError: WritableSignal<boolean> = signal(false);

    private readonly _privateReviewsService = inject(PrivateReviewsService);

    isLoading = computed(() => this.isLoadingBoosters() || this.isLoadingGifts());

    readonly totems$: Observable<Nfc[]> = this._store.select(StatisticsSelectors.selectTotemsFilter);
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);
    readonly restaurantTotems: WritableSignal<Nfc[]> = signal([]);
    data$: Observable<BoostersStatisticsData>;
    totemData$: Observable<BoostersStatisticsData>;
    wheelOfFortuneData$: Observable<BoostersStatisticsData>;
    giftsData$: Observable<WheelOfFortuneGiftsStatisticsData>;
    privateReviewData$: Observable<PrivateReviewStatisticsData>;

    readonly boosterPackActivated = toSignal(
        this._restaurantsService.restaurantSelected$.pipe(
            filter(isNotNil),
            map((restaurant) => restaurant.boosterPack?.activated),
            takeUntilDestroyed(this._destroyRef)
        ),
        { initialValue: false }
    );

    readonly nfcIds: WritableSignal<string[]> = signal([]);

    readonly InsightsChart = InsightsChart;
    readonly chartOptions: WritableSignal<ChartOptions> = signal({
        [InsightsChart.BOOSTERS_SCAN_COUNT]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.BOOSTERS_TOTEMS_ESTIMATED_REVIEWS_COUNT]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION]: {
            tableSortOptions: undefined,
        },
        [InsightsChart.BOOSTERS_PRIVATE_REVIEWS_COUNT]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
    });

    readonly privateReviewCount: WritableSignal<number> = signal(0);

    readonly shouldDisplayPrivateReviewsStats: Signal<boolean> = computed(
        () => this.restaurantTotems().length !== 0 && this.privateReviewCount() !== 0
    );

    readonly shouldDisplayWheelOfFortuneStats: WritableSignal<boolean> = signal(false);

    constructor(
        private readonly _store: Store,
        private readonly _destroyRef: DestroyRef,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translateService: TranslateService,
        private readonly _nfcService: NfcService,
        private readonly _toastService: ToastService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _boostersDataFetchingService: BoostersDataFetchingService
    ) {
        this._nfcService
            .search({ page: 1, limit: 999, restaurantId: this._restaurantsService.currentRestaurant._id })
            .subscribe((nfcApiResult) => {
                this.restaurantTotems.set(nfcApiResult.data.map((e) => Nfc.fromNfcDto(e)));
            });

        this.data$ = combineLatest([this.dates$, this.totems$])
            .pipe(
                debounceTime(500),
                tap(() => this.isLoadingBoosters.set(false)),
                filter(([dates]) => isDateSetOrGenericPeriod(dates)),
                tap(() => {
                    this.isErrorBoosters.set(false);
                    this.isLoadingBoosters.set(true);
                }),
                filter(([dates]) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
                switchMap(([dates, nfcs]: [DatesAndPeriod, Nfc[]]) => {
                    const data = this._boostersDataFetchingService.getChartsData(nfcs, dates);
                    this.onNfcChange(nfcs.map((nfc) => nfc.id));
                    this.isLoadingBoosters.set(false);
                    return data;
                }),
                catchError((error) => {
                    console.error('error >>', error);
                    this.isErrorBoosters.set(true);
                    this.isLoadingBoosters.set(false);
                    return EMPTY;
                }),
                tap(() => setTimeout(() => this.isLoadingBoosters.set(false), 1000)),
                shareReplay(1)
            )
            .pipe(takeUntilDestroyed(this._destroyRef));

        this.totemData$ = this.data$.pipe(
            map((data) => {
                this._store.dispatch(StatisticsActions.editBoosterStatsData({ data }));
                return {
                    ...data,
                    scans: data.scans.filter((scan) => !scan.isWheelOfFortuneRelated()),
                    previousScans: data.previousScans.filter((scan) => !scan.isWheelOfFortuneRelated()),
                };
            }),
            takeUntilDestroyed(this._destroyRef)
        );

        this.giftsData$ = this.dates$.pipe(
            filter((dates) => isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isErrorGifts.set(false);
                this.isLoadingGifts.set(true);
            }),
            debounceTime(500),
            filter((dates) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
            switchMap((dates: DatesAndPeriod) => {
                const data = this._boostersDataFetchingService.getGiftsData(dates);
                this.isLoadingGifts.set(false);
                return data;
            }),
            tap((giftData: WheelOfFortuneGiftsStatisticsData) => {
                this.shouldDisplayWheelOfFortuneStats.set(!!giftData.giftsInsightsPerGift?.length);
            }),
            catchError(() => {
                this.isErrorGifts.set(true);
                this.isLoadingGifts.set(false);
                return EMPTY;
            }),
            shareReplay(1),
            takeUntilDestroyed(this._destroyRef)
        );
        this.giftsData$.subscribe();

        this.wheelOfFortuneData$ = this.data$.pipe(
            map((data) => ({
                ...data,
                scans: data.scans.filter((scan) => scan.isWheelOfFortuneRelated()),
                previousScans: data.previousScans.filter((scan) => scan.isWheelOfFortuneRelated()),
            })),
            takeUntilDestroyed(this._destroyRef)
        );

        this.privateReviewData$ = this.data$.pipe(
            tap(() => {
                this.isPrivateReviewsError.set(false);
                this.isPrivateReviewsLoading.set(true);
            }),
            switchMap(({ nfcs, scans, previousScans, startDate }) => {
                const scanIds = [...scans.map((scan) => scan.id), ...previousScans.map((scan) => scan.id)];
                const privateReviewsDtos$ = scanIds.length
                    ? this._privateReviewsService.search({ scanIds }).pipe(map((apiResult) => apiResult.data))
                    : of<ReviewResponseDto[]>([]);
                const previousPrivateReviews$ = privateReviewsDtos$.pipe(
                    map((privateReviewDtos) =>
                        privateReviewDtos.filter((privateReviewDto) => new Date(privateReviewDto.socialCreatedAt) < startDate)
                    )
                );
                const currentPrivateReviews$ = privateReviewsDtos$.pipe(
                    map((privateReviewDtos) =>
                        privateReviewDtos.filter((privateReviewDto) => new Date(privateReviewDto.socialCreatedAt) >= startDate)
                    )
                );
                return forkJoin({
                    nfcs: of(nfcs),
                    scans: of(scans),
                    privateReviewsDto: currentPrivateReviews$,
                    previousPrivateReviewsDto: previousPrivateReviews$,
                });
            }),
            tap(({ privateReviewsDto }) => {
                this.privateReviewCount.set(privateReviewsDto.length);
            }),
            catchError(() => {
                this.isPrivateReviewsError.set(true);
                this.isPrivateReviewsLoading.set(false);
                return EMPTY;
            }),
            tap(() => this.isPrivateReviewsLoading.set(false)),
            takeUntilDestroyed(this._destroyRef)
        );
        this.privateReviewData$.subscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() =>
            this.topOfComponent?.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        );
    }

    openStatisticsDownload(): void {
        this._store
            .select(selectDatesFilter)
            .pipe(
                take(1),
                switchMap(({ startDate, endDate }) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.BOOSTERS,
                                filters: {
                                    dates: { startDate, endDate },
                                    nfcIds: this.nfcIds(),
                                },
                                chartOptions: this.chartOptions(),
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onViewByChange(chart: InsightsChart, value: ViewBy): void {
        this.chartOptions.update((option) => ({
            ...option,
            [chart]: {
                ...option[chart],
                viewBy: value,
            },
        }));
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions.update((option) => ({
            ...option,
            [chart]: {
                ...option[chart],
                hiddenDatasetIndexes: value,
            },
        }));
    }

    onNfcChange(value: string[]): void {
        this.nfcIds.set(value);
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions.update((options) => ({
            ...options,
            [chart]: {
                ...options[chart],
                tableSortOptions: value,
            },
        }));
    }
}
