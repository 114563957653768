import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ExperimentationService } from ':core/services/experimentation.service';
import { InformationsContext } from ':modules/informations/informations.context';
import { RestaurantOverviewComponent } from ':shared/components/restaurant-informations/restaurant-overview/restaurant-overview.component';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { InformationUpdateData, InfosRestaurantModalComponent } from '../infos-restaurant-modal/infos-restaurant-modal.component';

@Component({
    selector: 'app-infos-restaurant',
    templateUrl: './infos-restaurant.component.html',
    styleUrls: ['./infos-restaurant.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        NgTemplateOutlet,
        RestaurantOverviewComponent,
        ImagePathResolverPipe,
        TranslateModule,
        AsyncPipe,
    ],
})
export class InfosRestaurantComponent {
    @Input() restaurant: Restaurant;
    @Output() updated = new EventEmitter<InformationUpdateData>();

    readonly SvgIcon = SvgIcon;
    readonly isFeaturePlatformsUpdatesV2Enabled$ = this._experimentationService.isFeatureEnabled$('release-platforms-updates');

    private readonly _informationsContext = inject(InformationsContext);

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _experimentationService: ExperimentationService
    ) {
        this._informationsContext.openRestaurantInformationsModal$.pipe(takeUntilDestroyed()).subscribe(() => this.openInfoDialog());
    }

    openInfoDialog(): void {
        this._customDialogService
            .open<InfosRestaurantModalComponent, any, InformationUpdateData>(InfosRestaurantModalComponent, {
                panelClass: ['malou-dialog-panel'],
                height: undefined,
                data: { restaurant: this.restaurant },
            })
            .afterClosed()
            .subscribe((data) => {
                if (data && Object.keys(data).length > 0) {
                    this.updated.emit(data);
                }
            });
    }
}
