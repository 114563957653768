<div class="flex w-full flex-col">
    <div class="malou-border-primary border-primary flex h-12 self-stretch rounded-lg bg-white">
        <div class="w-full px-5">
            <input
                class="malou-text-12--medium box-border h-full w-full rounded-lg border-0 outline-none"
                data-testid="social-post-location-input"
                type="text"
                matGoogleMapsAutocomplete
                [placeholder]="'common.search' | translate"
                [formControl]="locationCtrl"
                [matAutocomplete]="autocompletePanel"
                #locationInput />
        </div>

        <div class="flex items-center pr-5">
            @if (!isLoading) {
                <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.LOCALISATION"></mat-icon>
            }
            @if (isLoading) {
                <mat-spinner class="stroke-malou-color-primary" [diameter]="16"></mat-spinner>
            }
        </div>
    </div>
    <mat-autocomplete class="!rounded-[10px]" (optionSelected)="selected($event)" #autocompletePanel="matAutocomplete">
        @for (locationOption of locationOptions$ | async; track locationOption) {
            <mat-option class="!py-1" [value]="locationOption" [disabled]="isDisabled">
                <div class="malou-text-14 leading-5">{{ locationOption.name }}</div>
                <div class="malou-text-12 font-light italic leading-4">{{ displayLocationOptionAddress | applyPure: locationOption }}</div>
            </mat-option>
        }
    </mat-autocomplete>
</div>
