import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { isNotNil, Role } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { NfcService } from ':core/services/nfc.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { Restaurant } from ':shared/models';

import * as StatisticsActions from './store/statistics.actions';

@Component({
    selector: 'app-aggregated-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss'],
    standalone: true,
    imports: [NavigationHeaderComponent, RouterOutlet, MalouSpinnerComponent],
})
@AutoUnsubscribeOnDestroy()
export class StatisticsComponent implements OnInit, KillSubscriptions {
    tabs: NavigationTab[] = [];
    isLoading = true;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    private readonly _SEO_TAB: NavigationTab = { label: this._translate.instant('statistics.navigation_header.seo'), link: './seo' };
    private readonly _E_REPUTATION_TAB: NavigationTab = {
        label: this._translate.instant('statistics.navigation_header.e_reputation'),
        link: './e-reputation',
    };
    private readonly _SOCIAL_NETWORK_TAB: NavigationTab = {
        label: this._translate.instant('statistics.navigation_header.social_network'),
        link: './social-networks',
    };
    private readonly _BOOSTERS_TAB: NavigationTab = {
        label: this._translate.instant('statistics.navigation_header.boosters'),
        link: './boosters',
    };
    private readonly _ROI_TAB: NavigationTab = {
        label: this._translate.instant('statistics.navigation_header.roi'),
        link: './roi',
    };

    constructor(
        private readonly _translate: TranslateService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store,
        private readonly _nfcService: NfcService,
        private readonly _router: Router,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService
    ) {}

    ngOnInit(): void {
        console.log('[LOG-TO-DELETE][Stats] Component initializing');
        this._store.dispatch(StatisticsActions.resetState());
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(isNotNil),
                tap(() => (this.isLoading = true)),
                tap(() => console.log('[LOG-TO-DELETE][Stats] Selected restaurant change triggered')),
                switchMap((restaurant) =>
                    forkJoin([
                        of(restaurant),
                        this._nfcService.search({
                            page: 1,
                            limit: 1,
                            restaurantId: this._restaurantsService.currentRestaurant._id,
                        }),
                        this._wheelsOfFortuneService.haveAtLeastOneWheelOfFortune([this._restaurantsService.currentRestaurant._id]),
                        this._store.select(selectUserInfos).pipe(
                            filter((storageUser) => !!storageUser),
                            take(1)
                        ),
                    ])
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(([restaurant, nfcApiResult, hasAtLeastOneWheelOfFortune, user]) => {
                console.log('[LOG-TO-DELETE][Stats] Received data for tab settings', { restaurant, user });
                const hasTotem = (nfcApiResult.metadata?.pagination.total || 0) > 0;
                this.checkRestaurantTypeForPotentialRedirection(restaurant, hasTotem);
                console.log('[LOG-TO-DELETE][Stats] Has been checked for redirection');
                const boosterTab = hasTotem || hasAtLeastOneWheelOfFortune ? [this._BOOSTERS_TAB] : [];
                const shouldShowRoiTab = user?.role === Role.ADMIN || restaurant.roiActivated;
                console.log('[LOG-TO-DELETE][Stats] Tab settings almost completed', {
                    showingBoosterTab: boosterTab.length,
                    shouldShowRoiTab,
                    roiActivated: restaurant.roiActivated,
                    userRole: user?.role,
                });
                const roiTab = shouldShowRoiTab ? [this._ROI_TAB] : [];
                if (restaurant.isBrandBusiness()) {
                    this.tabs = [this._SOCIAL_NETWORK_TAB, ...boosterTab];
                } else {
                    this.tabs = [...roiTab, this._SEO_TAB, this._E_REPUTATION_TAB, this._SOCIAL_NETWORK_TAB, ...boosterTab];
                }
                console.log('[LOG-TO-DELETE][Stats] Final tabs', this.tabs);
                this.isLoading = false;
            });
    }

    checkRestaurantTypeForPotentialRedirection(restaurant: Restaurant, hasTotem: boolean): void {
        const state = this._router.routerState.snapshot;
        const lastUrlPath = state.url.split('/').pop();
        const parentUrl = state.url.split('/').slice(0, -1).join('/');
        const defaultUrl = [parentUrl, 'social-networks'];

        if (lastUrlPath === 'totems' && !hasTotem) {
            console.log('[LOG-TO-DELETE][Stats] Redirecting to', defaultUrl);
            this._router.navigate(defaultUrl);
            return;
        }

        const isBrand = restaurant.isBrandBusiness();
        if (!isBrand) {
            console.log('[LOG-TO-DELETE][Stats] Not redirecting');
            return;
        }

        if (lastUrlPath === 'social-networks' || lastUrlPath === 'totems') {
            return;
        }

        console.log('[LOG-TO-DELETE][Stats] Redirecting to (default)', defaultUrl);
        this._router.navigate(defaultUrl);
    }
}
