import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { intersectionWith } from 'lodash';
import { catchError, combineLatest, map, of } from 'rxjs';

import { MalouErrorCode, RestaurantAttributeValue } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { AttributesModalComponent, AttributesUpdateData } from ':modules/informations/attributes-modal/attributes-modal.component';
import { InformationsContext } from ':modules/informations/informations.context';
import { RestaurantAttributesComponent } from ':shared/components/restaurant-informations/restaurant-attributes/restaurant-attributes.component';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { Attribute, Restaurant, RestaurantAttribute } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { AttributeService } from './attributes-service';

@Component({
    selector: 'app-attributes',
    templateUrl: './attributes.component.html',
    styleUrls: ['./attributes.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, RestaurantAttributesComponent, TranslateModule, MatTooltipModule, AsyncPipe],
})
export class AttributesComponent implements OnChanges {
    @Input() restaurant: Restaurant;
    @Output() attributesChange: EventEmitter<AttributesUpdateData> = new EventEmitter();
    @Output() prepareAttributesDuplication: EventEmitter<void> = new EventEmitter<void>();

    readonly isFeaturePlatformsUpdatesV2Enabled$ = this._experimentationService.isFeatureEnabled$('release-platforms-updates');
    readonly SvgIcon = SvgIcon;

    categoryAttributesError = '';

    readonly informationsContext = inject(InformationsContext);

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _attributesService: AttributeService,
        private readonly _translate: TranslateService,
        private readonly _experimentationService: ExperimentationService
    ) {
        this.informationsContext.openRestaurantAttributesModal$.pipe(takeUntilDestroyed()).subscribe(() => this.openAttributesDialog());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'restaurant')) {
            this.categoryAttributesError = '';
            this._getAttributes();
        }
    }

    openAttributesDialog(): void {
        this._customDialogService
            .open<AttributesModalComponent, any, AttributesUpdateData>(AttributesModalComponent, {
                panelClass: ['malou-dialog-panel'],
                height: undefined,
                data: {
                    restaurant: this.restaurant,
                    allAttributes: this.informationsContext.allCategoryAttributes(),
                    restaurantAttributes: this.informationsContext.restaurantAttributes(),
                },
            })
            .afterClosed()
            .subscribe({
                next: (data) => {
                    if (data && Object.keys(data).length > 0) {
                        this.attributesChange.emit(data);
                    }
                },
            });
    }

    onPrepareAttributesDuplication(): void {
        this.prepareAttributesDuplication.emit();
    }

    private _getAttributes(): void {
        combineLatest([
            this._attributesService.getCategoryAttributes(this.restaurant._id).pipe(
                catchError((err) => {
                    console.warn('err :>>', err);
                    this.categoryAttributesError = this._clarifyCategoryAttributesError(err);
                    return of({ data: [], err: true });
                })
            ),
            this._attributesService.getRestaurantAttributes(this.restaurant._id),
        ])
            .pipe(map(([resAttr, resRestAttr]) => [resAttr.data, resRestAttr.data]))
            .subscribe({
                next: ([allAttr, restAttr]: [Attribute[], RestaurantAttribute[]]) => {
                    this.informationsContext.allCategoryAttributes.set(allAttr);
                    const restaurantAttributes = allAttr.length
                        ? intersectionWith(
                              restAttr.filter((el) => el.attributeValue !== RestaurantAttributeValue.NOT_CONCERNED),
                              allAttr,
                              (restaurantAttribute, attribute) => restaurantAttribute.attribute?.attributeId === attribute.attributeId
                          )
                        : restAttr.filter((el) => el.attributeValue !== RestaurantAttributeValue.NOT_CONCERNED);
                    this.informationsContext.restaurantAttributes.set(restaurantAttributes);
                },
                error: (err) => console.warn(err),
            });
    }

    private _clarifyCategoryAttributesError(err: HttpErrorResponse): string {
        if (err?.error?.malouErrorCode === MalouErrorCode.GMB_NOT_CONNECTED) {
            return this._translate.instant('information.attributes.error.gmb_not_connected_text');
        }
        return err?.error?.message || err?.message || String(err);
    }
}
