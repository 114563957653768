import { DatePipe, JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { PostType } from '@malou-io/package-utils';

import { IStoryVideoCheck } from ':core/constants';
import { MediaDurationComponent } from ':modules/stories/media-duration/media-duration.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { StoryVideoErrorComponent } from ':shared/components/story-video-error/story-video-error.component';
import { getStoryVideoValidity } from ':shared/helpers/story-video-checker';
import { Post } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

const DEFAULT_PHOTO_DURATION = 5;
@Component({
    selector: 'app-post-summary',
    standalone: true,
    imports: [
        JsonPipe,
        MatIconModule,
        DatePipe,
        SocialPostMediaComponent,
        PluralTranslatePipe,
        EnumTranslatePipe,
        MatButtonModule,
        MediaDurationComponent,
        TranslateModule,
        StoryVideoErrorComponent,
    ],
    templateUrl: './post-summary.component.html',
    styleUrls: ['./post-summary.component.scss'],
})
export class PostSummaryComponent implements OnInit {
    @Input() post: Post;
    @Input() warningText: string;
    @Output() deletedPost = new EventEmitter<string>();

    readonly SvgIcon = SvgIcon;
    mediaDuration: number;
    videoValidity: IStoryVideoCheck;
    videoError = false;

    ngOnInit(): void {
        if (this.post?.postType === PostType.IMAGE) {
            this.mediaDuration = DEFAULT_PHOTO_DURATION;
        }
    }

    deletePost(): void {
        this.deletedPost.emit(this.post._id);
    }

    onLoadedVideoMetadata(event: Event): void {
        const videoElt = event.target as HTMLVideoElement;
        const videoValidity = getStoryVideoValidity(videoElt);
        this.videoValidity = videoValidity;
        this.videoError = videoValidity.error;
        this.mediaDuration = videoValidity.duration;
    }
}
