import { z } from 'zod';

import { booleanAsStringValidator } from '../utils/validators';

export const getRankingsForRestaurantsQueryValidator = z
    .object({
        start_date: z.string().datetime({ offset: true }),
        end_date: z.string().datetime({ offset: true }),
        restaurant_ids: z.array(z.string()).optional(),
        previous_period: booleanAsStringValidator.optional(),
    })
    .transform((data) => ({
        startDate: new Date(data.start_date),
        endDate: new Date(data.end_date),
        previousPeriod: data.previous_period ? data.previous_period === 'true' : undefined,
        restaurant_ids: data.restaurant_ids,
    }));
export type GetRankingsForRestaurantsQueryDto = z.infer<typeof getRankingsForRestaurantsQueryValidator>;
