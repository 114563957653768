import { z } from 'zod';

import { ClientSource, ContactMode, Locale, PlatformKey } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const getClientsForCampaignRangeQueryValidator = z
    .object({
        start_date: z.coerce.date(),
        end_date: z.coerce.date(),
        min_days_from_last_contacted_at: z.string(),
        accepts: z.nativeEnum(ContactMode),
        sources: z.union([z.array(z.string()), z.string()]).optional(),
    })
    .transform((data) => {
        return {
            startDate: data.start_date,
            endDate: data.end_date,
            minDaysFromLastContactedAt: parseInt(data.min_days_from_last_contacted_at),
            accepts: data.accepts,
            sources: Array.isArray(data.sources) ? data.sources : [data.sources],
        };
    });

export type GetClientsForCampaignRangeQueryDto = z.infer<typeof getClientsForCampaignRangeQueryValidator>;

// ------------------------------------------------------------------------------------------

export const clientIdParamsValidator = z
    .object({
        client_id: objectIdValidator,
    })
    .transform((data) => {
        return {
            clientId: data.client_id,
        };
    });

export type ClientIdParamsDto = z.infer<typeof clientIdParamsValidator>;

// ------------------------------------------------------------------------------------------

export const deleteManyClientsByIdsBodyValidator = z.object({
    clientIds: z.array(objectIdValidator),
});

export type DeleteManyClientsByIdsBodyDto = z.infer<typeof deleteManyClientsByIdsBodyValidator>;

const clientAddressDtoValidator = z.object({
    locality: z.string().nullish(),
    regionCode: z.string().nullish(),
    country: z.string().nullish(),
    postalCode: z.string().nullish(),
    formattedAddress: z.string().nullish(),
    streetNumber: z.string().nullish(),
    route: z.string().nullish(),
    administrativeArea: z.string().nullish(),
});

// ------------------------------------------------------------------------------------------

export const createClientRequestBodyValidator = z
    .object({
        restaurantId: objectIdValidator,
        firstName: z.string(),
        lastName: z.string(),
        address: clientAddressDtoValidator.optional(),
        email: z.string().email(),
        source: z.nativeEnum(ClientSource),
        lastVisitedAt: z.coerce.date(),
        visitCount: z.string().optional(),
        accepts: z.array(z.nativeEnum(ContactMode)),
        civility: z.string(),
        phone: z
            .object({
                digits: z.string(),
                prefix: z.number(),
            })
            .optional(),
        language: z.nativeEnum(Locale),
    })
    .transform((data) => ({ ...data, visitCount: data.visitCount ? parseInt(data.visitCount) : 0 }));

export type CreateClientRequestBodyDto = z.infer<typeof createClientRequestBodyValidator>;

// ------------------------------------------------------------------------------------------

export const updateClientHasLeftReviewParamsValidator = z
    .object({
        client_id: objectIdValidator,
        platform_key: z.nativeEnum(PlatformKey),
    })
    .transform((data) => {
        return {
            clientId: data.client_id,
            platformKey: data.platform_key,
        };
    });

export type UpdateClientHasLeftReviewParamsDto = z.infer<typeof updateClientHasLeftReviewParamsValidator>;

// ------------------------------------------------------------------------------------------

export const checkClientFilesParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
        source: z.nativeEnum(ClientSource),
    })
    .transform((data) => {
        return {
            restaurantId: data.restaurant_id,
            source: data.source,
        };
    });

export type CheckClientFilesParamsDto = z.infer<typeof checkClientFilesParamsValidator>;

// ------------------------------------------------------------------------------------------

export const updateClientBodyValidator = z.object({
    filter: z.object({
        restaurantId: objectIdValidator,
        email: z.string().email(),
    }),
    data: z.object({
        restaurantId: objectIdValidator,
        firstName: z.string(),
        email: z.string().email(),
        phone: z
            .object({
                digits: z.string(),
                prefix: z.number(),
            })
            .optional(),
        language: z.nativeEnum(Locale),
        source: z.nativeEnum(ClientSource),
        lastVisitedAt: z.coerce.date(),
        accepts: z.array(z.nativeEnum(ContactMode)),
    }),
});

export type UpdateClientBodyDto = z.infer<typeof updateClientBodyValidator>;
