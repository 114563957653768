import { z } from 'zod';

import { lightRestaurantValidator } from '../restaurant';
import { objectIdValidator } from '../utils/validators';

export const giftWithoutIdDtoValidator = z.object({
    name: z.string(),
    weight: z.number(),
    stocks: z.array(
        z.object({
            quantity: z.number().nullable(),
            restaurant: lightRestaurantValidator,
        })
    ),
});

export type GiftWithoutIdDto = z.infer<typeof giftWithoutIdDtoValidator>;

export const giftWithIdDtoValidator = z.object({
    id: objectIdValidator.optional(),
    name: z.string(),
    weight: z.number(),
    stocks: z.array(
        z.object({
            quantity: z.number().nullable(),
            restaurant: lightRestaurantValidator,
        })
    ),
});

export type GiftWithIdDto = z.infer<typeof giftWithIdDtoValidator>;
