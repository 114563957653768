@let isFeaturePlatformsUpdatesV2Enabled = isFeaturePlatformsUpdatesV2Enabled$ | async;

<div class="malou-card group !m-0 h-full">
    <div class="malou-card__header">
        <div class="malou-text-18--bold">
            {{ 'information.attributes.characteristics' | translate }}
            @if (informationsContext.restaurantAttributes().length && informationsContext.allCategoryAttributes().length) {
                <span class="font-normal italic md:hidden">{{
                    'information.attributes.number_of_attributes'
                        | translate
                            : {
                                  populated: informationsContext.restaurantAttributes().length,
                                  attributes_length: informationsContext.allCategoryAttributes().length,
                              }
                }}</span>
            }
            @if (informationsContext.restaurantAttributes().length && informationsContext.allCategoryAttributes().length) {
                <span class="font-normal italic min-md:hidden">{{
                    'information.attributes.number_of_attributes_short'
                        | translate
                            : {
                                  populated: informationsContext.restaurantAttributes().length,
                                  attributes_length: informationsContext.allCategoryAttributes().length,
                              }
                }}</span>
            }
        </div>
        <div [matTooltip]="categoryAttributesError">
            @if (isFeaturePlatformsUpdatesV2Enabled) {
                <div class="invisible flex gap-2 group-hover:visible md:visible">
                    <button
                        class="malou-btn-icon--secondary btn-xl"
                        data-cy="editAttributesBtn"
                        mat-icon-button
                        [disabled]="categoryAttributesError !== ''"
                        (click)="openAttributesDialog()">
                        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                    </button>
                    <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="onPrepareAttributesDuplication()">
                        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                    </button>
                </div>
            } @else {
                <div class="flex gap-x-2">
                    <button
                        class="malou-btn-icon--secondary btn-xl"
                        data-cy="editAttributesBtn"
                        mat-icon-button
                        [disabled]="categoryAttributesError !== ''"
                        (click)="openAttributesDialog()">
                        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                    </button>
                    <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="onPrepareAttributesDuplication()">
                        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                    </button>
                </div>
            }
        </div>
    </div>
    <div class="malou-card__body">
        <app-restaurant-attributes
            [restaurantAttributes]="informationsContext.restaurantAttributes()"
            (emitOpenAttributesDialog)="openAttributesDialog()">
        </app-restaurant-attributes>
    </div>
</div>
