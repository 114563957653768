import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SpinnerService } from ':core/services/malou-spinner.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

import { AuthService } from '../auth.service';

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        TranslateModule,
        InputTextComponent,
        PlatformLogoPathResolverPipe,
        IllustrationPathResolverPipe,
    ],
    providers: [],
})
export class NewPasswordComponent implements OnInit {
    public readonly MIN_PASSWORD_LENGTH = 8;
    public passwordForm: UntypedFormGroup = this._fb.group(
        {
            password: ['', [Validators.required, Validators.minLength(this.MIN_PASSWORD_LENGTH)]],
            confirmPwd: [''],
        },
        {
            validator: this.mustMatch('password', 'confirmPwd'),
        }
    );

    public userId: string | null = null;
    public token: string | null = null;
    public formSubmitted = false;
    public hasExpiredToken = false;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _auth: AuthService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _router: Router,
        private readonly _spinnerService: SpinnerService
    ) {
        this.userId = this._route.snapshot.paramMap.get('id');
        this.token = this._route.snapshot.paramMap.get('token');
    }

    get form(): Record<string, AbstractControl> {
        return this.passwordForm.controls;
    }

    ngOnInit(): void {
        if (!this.userId || !this.token) {
            this._router.navigate(['./auth/login']);
            return;
        }
        this._auth.isTokenExpired(this.userId, this.token).subscribe({
            error: () => {
                this.setExpiredTokenState();
            },
        });
    }

    reset(): void {
        if (!this.userId || !this.token) {
            return;
        }
        this._spinnerService.show();
        this.formSubmitted = true;
        this._auth.resetPassword(this.userId, this.token, this.passwordForm.value.password).subscribe({
            next: () => {
                this._spinnerService.hide();
            },
            error: (err) => {
                console.warn(err);
                this._spinnerService.hide();
                this.setExpiredTokenState();
            },
        });
    }

    mustMatch(controlName: string, matchingControlName: string) {
        return (passwordForm: UntypedFormGroup): void => {
            const control = passwordForm.controls[controlName];
            const matchingControl = passwordForm.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    goHome(): void {
        this._router.navigate(['/']);
    }

    goToForgotPassword(): void {
        this._router.navigate(['/auth/password/forgot']);
    }

    setExpiredTokenState(): void {
        this.formSubmitted = true;
        this.hasExpiredToken = true;
    }
}
