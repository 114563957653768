<div class="malou-dialog !h-[450px] md:absolute md:bottom-0 md:!h-[80vh]">
    <div class="malou-dialog__header">
        <h2 class="malou-text-18--bold">{{ 'messages.settings.modal.title' | translate }}</h2>
        <button mat-icon-button (click)="close()">
            <mat-icon color="primary">close</mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body !pb-0">
        <div class="flex w-full items-center gap-x-3">
            <img class="rounded-full" height="34" width="34" [src]="'gmb' | platformLogoPathResolver" />
            <p class="text-malou-color-text-1">
                {{ 'messages.settings.modal.google' | translate }}
            </p>
            <app-slide-toggle
                [switchWidth]="100"
                [checked]="gmbMessagingEnabled()"
                [activeLabel]="'common.enabled' | translate"
                [inactiveLabel]="'common.disabled' | translate"
                [disabled]="loading"
                (onToggle)="toggleEnableMessagingForPlatform()">
            </app-slide-toggle>

            @if (loading) {
                <app-malou-spinner size="small"></app-malou-spinner>
            }
        </div>

        <div class="mt-5">
            <div class="malou-text-10--regular text-malou-color-text-2">{{ 'messages.settings.modal.welcome_message' | translate }}</div>
            <div class="malou-text-10 italic text-malou-color-text-2">
                {{ 'messages.settings.modal.welcome_message_description' | translate }}
            </div>

            <app-text-area [placeholder]="'messages.form.placeholder' | translate" [formControl]="welcomeMessageForm"> </app-text-area>
        </div>
    </div>
    <div class="malou-dialog__footer">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button [disabled]="hasErrors()" (click)="validateMessage()">
            {{ 'common.confirm' | translate }}
        </button>
    </div>
</div>
