<div class="w-full border-b border-malou-color-border-primary px-5 py-4">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{
            'notifications.at'
                | translate: { date: notification().createdAt | date: 'dd.MM.yyyy', time: notification().createdAt | date: 'HH:mm' }
        }}
    </div>
    <div class="mt-2 flex items-center gap-x-3">
        <img class="h-3 w-3 rounded-[2px]" [src]="notification().data.platformKey | platformLogoPathResolver" />
        <div class="malou-text-11--semibold text-malou-color-text-1">
            {{ 'notification-center.review-notification-item.negative-review-title' | translate }}
        </div>
    </div>
    <div class="py-2">
        <app-star-gauge [stars]="notification().data.reviewRating"></app-star-gauge>
    </div>

    @if (notification().data.reviewText.length) {
        <div class="flex md:!mt-2 md:!flex-col">
            <div class="malou-card__body-text">
                <div>
                    <span
                        class="font-normal"
                        [innerHTML]="
                            isFolded() ? (notification().data.reviewText | shortText: SHORT_TEXT_LENGTH) : notification().data.reviewText
                        "></span>
                    @if (notification().data.reviewText.length > SHORT_TEXT_LENGTH) {
                        <span class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5" (click)="toggleFolded()">
                            {{ (isFolded() ? 'common.see_more' : 'common.see_less') | translate }}
                        </span>
                    }
                </div>
            </div>
        </div>
    }
    @if (notification().data.suggestedReply) {
        <div
            class="reply-suggestion mt-2 rounded border border-malou-color-background-dark bg-malou-color-background-light p-3 transition-all">
            <div class="malou-text-10 text-malou-color-text-2">
                {{ notification().data.suggestedReply }}
            </div>
        </div>

        <div class="mt-2 flex w-full justify-end gap-x-2">
            <button class="malou-btn-raised--secondary btn-xs" mat-raised-button>
                {{ 'common.edit' | translate }}
            </button>
            <button class="malou-btn-raised--primary btn-xs" mat-raised-button>
                {{ 'common.send' | translate }}
            </button>
        </div>
    }
</div>
