import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

@Component({
    selector: 'app-forgot-password-confirm',
    templateUrl: './forgot-password-confirm.component.html',
    styleUrls: ['./forgot-password-confirm.component.scss'],
    standalone: true,
    imports: [MatButtonModule, TranslateModule, PlatformLogoPathResolverPipe, IllustrationPathResolverPipe],
})
export class ForgotPasswordConfirmComponent {
    email = '';

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute
    ) {
        this.email = this._route.snapshot.queryParams.email;
        if (!this.email) {
            this.goHome();
        }
    }

    goHome(): void {
        this._router.navigate(['/']);
    }

    goToForgotPassword(): void {
        this._router.navigate(['../forgot'], {
            relativeTo: this._route,
            queryParams: { email: this.email },
        });
    }
}
