import { Routes } from '@angular/router';

import { EditUserMiscellaneousComponent } from './edit-user-miscellaneous/edit-user-miscellaneous.component';
import { NotificationsSettingsComponent } from './notifications-settings/notifications-settings.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportsSettingsComponent } from './reports-settings/reports-settings.component';
import { UserResolver } from './user.resolver';

export const USERS_ROUTES: Routes = [
    {
        path: ':user_id',
        component: ProfileComponent,
        resolve: { user: UserResolver },
        providers: [UserResolver],
    },
    {
        path: ':user_id/edit-misc',
        component: EditUserMiscellaneousComponent,
        resolve: { user: UserResolver },
        providers: [UserResolver],
    },
    {
        path: ':user_id/notifications-settings',
        component: NotificationsSettingsComponent,
        resolve: { user: UserResolver },
        providers: [UserResolver],
    },
    {
        path: ':user_id/reports-settings',
        component: ReportsSettingsComponent,
        resolve: { user: UserResolver },
        providers: [UserResolver],
    },
];
