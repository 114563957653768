import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { InsightsChart, InsightsTab } from '@malou-io/package-utils';

import { ToastService } from ':core/services/toast.service';
import { AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT } from ':modules/aggregated-statistics/aggregated-statistics.component';
import { AggregatedPostsInsightsTableComponent } from ':modules/aggregated-statistics/social-networks/insights/aggregated-posts-insights-table.component';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { Restaurant } from ':shared/models';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { FiltersComponent } from '../filters/filters.component';
import { AggregatedStatisticsFiltersContext } from '../filters/filters.context';
import { PlatformFilterPage } from '../store/aggregated-statistics.interface';
import * as StatisticsSelectors from '../store/aggregated-statistics.selectors';
import { GmbActionsComponent } from './gmb-actions/gmb-actions.component';
import { GmbImpressionsComponent } from './gmb-impressions/gmb-impressions.component';
import { KeywordsComponent } from './keywords/keywords.component';

@Component({
    selector: 'app-seo',
    templateUrl: './seo.component.html',
    styleUrls: ['./seo.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        KeywordsComponent,
        GmbActionsComponent,
        GmbImpressionsComponent,
        FiltersComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        MatButtonModule,
        AggregatedPostsInsightsTableComponent,
    ],
})
export class SeoComponent {
    readonly PlatformFilterPage = PlatformFilterPage;

    restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;
    isRestaurantsCountUiLimitExceeded$: Observable<boolean> = this.restaurants$.pipe(
        map((restaurants) => restaurants.length > AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT)
    );
    readonly InsightsChart = InsightsChart;
    chartOptions: ChartOptions = {
        [InsightsChart.AGGREGATED_APPARITIONS]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.AGGREGATED_ACTIONS]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.AGGREGATED_RANKINGS]: {
            tableSortOptions: undefined,
        },
    };

    isKeywordsLoading = signal(true);
    isImpressionsLoading = signal(true);
    isActionsLoading = signal(true);

    isLoading = computed(() => this.isKeywordsLoading() || this.isImpressionsLoading() || this.isActionsLoading());

    constructor(
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {}

    openDownloadStatisticsModal(): void {
        combineLatest([
            this._store.select(StatisticsSelectors.selectDatesFilter),
            this._store.select(StatisticsSelectors.selectRestaurantsIdsFilter),
        ])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, restaurantIds]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.AGGREGATED_SEO,
                                filters: {
                                    dates: { startDate, endDate },
                                    restaurantIds,
                                },
                                chartOptions: this.chartOptions,
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onSortByChange(chart: InsightsChart, value: ChartSortBy): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                chartSortBy: value,
            },
        };
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                hiddenDatasetIndexes: value,
            },
        };
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                tableSortOptions: value,
            },
        };
    }
}
