import { ChangeDetectionStrategy, Component, forwardRef, inject, Injector, input, output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

@Component({
    selector: 'app-select-languages',
    templateUrl: 'select-languages.component.html',
    styleUrls: ['./select-languages.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectLanguagesComponent),
        },
        EnumTranslatePipe,
    ],
    standalone: true,
    imports: [
        SelectBaseComponent,
        FormsModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        TranslateModule,
        ApplyPurePipe,
        FlagPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLanguagesComponent extends ControlValueAccessorConnectorComponent {
    /** Title */
    readonly title = input<string | undefined>();

    /** Subtitle */
    readonly subtitle = input<string | undefined>();

    /** Placeholder */
    readonly placeholder = input<string>('');

    /** Values */
    readonly values = input<ApplicationLanguage[]>([]);

    /** If true, will display an asterisk after the title */
    readonly required = input<boolean>(false);

    /** Error message, will display a colored border and the error message below the input */
    readonly errorMessage = input<string | undefined>();

    readonly selectedValues = input<ApplicationLanguage[]>([]);

    readonly multiSelection = input<boolean>(true);

    readonly displayWith = input<(option: any) => string>((option: any) =>
        option ? this._enumTranslatePipe.transform(option, 'langs') : ''
    );

    // ------------ other ------------//

    readonly testId = input<string>();

    // ------------ Event ------------//

    /** On change event */
    readonly selectLanguagesChange = output<ApplicationLanguage[]>();

    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    constructor(protected readonly injector: Injector) {
        super(injector);
    }
}
