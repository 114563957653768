import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { NegativeReviewReminderNotification } from ':core/components/notification-center/models/negative-review-reminder-notification.model';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-negative-review-reminder-notification-item',
    templateUrl: './negative-review-reminder-notification-item.component.html',
    styleUrl: './negative-review-reminder-notification-item.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, TranslateModule, DatePipe, PlatformLogoPathResolverPipe, StarGaugeComponent, ShortTextPipe, MatButtonModule],
})
export class NegativeReviewReminderNotificationItemComponent extends NotificationItemComponent {
    readonly notification = computed(() => this.initialNotification() as NegativeReviewReminderNotification);

    readonly isFolded = signal(true);

    readonly SHORT_TEXT_LENGTH = 100;

    toggleFolded(): void {
        this.isFolded.update((old) => !old);
    }
}
