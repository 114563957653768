import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, orderBy, sum } from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { RoiAdditionalCustomersByMonthDto } from '@malou-io/package-dto';
import { isNotNil, sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { RoiService } from ':modules/roi/roi.service';
import { StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import * as AggregatedStatisticsSelector from '../../store/aggregated-statistics.selectors';
import { AggregatedEstimatedCustomersData, AggregatedRoiDataChartErrors } from '../roi.interface';
import { AggregatedMonthlyEstimatedCustomersChartComponent } from './aggregated-monthly-estimated-customers-chart/aggregated-monthly-estimated-customers-chart.component';
import { AggregatedMonthlyEstimatedCustomersTableComponent } from './aggregated-monthly-estimated-customers-table/aggregated-monthly-estimated-customers-table.component';

@Component({
    selector: 'app-aggregated-monthly-estimated-customers',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        IllustrationPathResolverPipe,
        TranslateModule,
        SelectComponent,
        SkeletonComponent,
        MatButtonToggleModule,
        MatIconModule,
        MatTooltipModule,
        AggregatedMonthlyEstimatedCustomersChartComponent,
        AggregatedMonthlyEstimatedCustomersTableComponent,
    ],
    templateUrl: './aggregated-monthly-estimated-customers.component.html',
    styleUrl: './aggregated-monthly-estimated-customers.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedMonthlyEstimatedCustomersComponent implements OnInit {
    readonly isParentLoading = input.required<boolean>();

    private readonly _store = inject(Store);
    private readonly _roiService = inject(RoiService);
    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    public readonly screenSizeService = inject(ScreenSizeService);

    readonly SvgIcon = SvgIcon;
    readonly selectedTimeScaleFilter$ = this._store.select(AggregatedStatisticsSelector.selectTimeScaleFilter);
    readonly selectedMonths$ = this.selectedTimeScaleFilter$.pipe(
        map((timeScaleFilter) => getSelectedMonthsNumberFromTimeScaleFilter(timeScaleFilter))
    );
    readonly selectedMonths: Signal<number> = toSignal(this.selectedMonths$, {
        initialValue: getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS),
    });

    readonly Illustration = Illustration;

    readonly isChartLoading: WritableSignal<boolean> = signal(true);
    readonly chartError: WritableSignal<AggregatedRoiDataChartErrors> = signal({
        restaurantsWithoutData: [],
        hasError: false,
        noDataError: false,
        errorMessage: '',
    });

    readonly estimatedCustomersData: WritableSignal<AggregatedEstimatedCustomersData> = signal([]);

    readonly sortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(ChartSortBy.ALPHABETICAL) as FormControl<ChartSortBy>;
    readonly SORT_BY_VALUES = Object.values(ChartSortBy);
    readonly MAX_RESTAURANTS_TO_SHOW_CUSTOM_LABEL = 15;

    sortByChanged$ = new BehaviorSubject(false);

    selectedViewMode = StatisticsDataViewMode.CHART;
    StatisticsDataViewMode = StatisticsDataViewMode;

    killSubscriptions$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this._getEstimatedCustomersData();

        this.sortByChanged$.subscribe(() => {
            if (!this.estimatedCustomersData().length) {
                this.chartError.update((chartError) => cloneDeep({ ...chartError, noDataError: true }));
                return;
            }
            const sortedChartData = this._sortChartData(this.sortByControl.value, this.estimatedCustomersData());
            this.estimatedCustomersData.set(sortedChartData);
        });
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslatePipe.transform(option, 'chart_sort_by');

    onSortByChange(sortByElt: ChartSortBy): void {
        this.sortByControl.setValue(sortByElt);
        this.sortByChanged$.next(true);
    }

    onViewModeChange(value: StatisticsDataViewMode): void {
        this.selectedViewMode = value;
    }

    private _getEstimatedCustomersData(): void {
        combineLatest([this._aggregatedStatisticsFiltersContext.savedRestaurantsWithRoiSettings$, this.selectedTimeScaleFilter$])
            .pipe(
                tap(() => this.isChartLoading.set(true)),
                switchMap(([restaurants, _]: [Restaurant[], MalouTimeScalePeriod | undefined]) =>
                    forkJoin({
                        restaurants: of(restaurants),
                        estimatedCustomersByMonth: this._roiService.getEstimatedCustomersForRestaurantsForNLastMonths(
                            restaurants.map((restaurant) => restaurant._id),
                            this.selectedMonths()
                        ),
                        estimatedCustomersByMonthSimilarLocationsByMonths:
                            this._roiService.getEstimatedCustomersForRestaurantsForNLastMonths(
                                restaurants.map((restaurant) => restaurant._id),
                                this.selectedMonths(),
                                {
                                    similarLocations: true,
                                }
                            ),
                    })
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ({ restaurants, estimatedCustomersByMonth, estimatedCustomersByMonthSimilarLocationsByMonths }) => {
                    this._computeEstimatedCustomersData(
                        restaurants,
                        estimatedCustomersByMonth.data,
                        estimatedCustomersByMonthSimilarLocationsByMonths.data
                    );

                    this.isChartLoading.set(false);
                },
                error: () => {
                    this.estimatedCustomersData.set([]);
                    this.chartError.update((chartError) =>
                        cloneDeep({ ...chartError, hasError: true, errorMessage: this._translateService.instant('common.server_error') })
                    );
                    this.isChartLoading.set(false);
                },
            });
    }

    private _sortChartData(sortByElt: ChartSortBy, data: AggregatedEstimatedCustomersData): AggregatedEstimatedCustomersData {
        if (sortByElt === ChartSortBy.ALPHABETICAL) {
            return cloneDeep(
                data.sort((a, b) =>
                    sortRestaurantsByInternalNameThenName(
                        { name: a.restaurant?.name, internalName: a.restaurant?.internalName },
                        { name: b.restaurant?.name, internalName: b.restaurant?.internalName }
                    )
                )
            );
        }
        return orderBy(
            data.map((singleData) => ({ ...singleData, sumToOrder: sum(singleData.estimatedCustomersByMonth) })),
            'sumToOrder',
            sortByElt
        );
    }

    private _computeEstimatedCustomersData(
        restaurants: Restaurant[],
        estimatedCustomersByMonth: RoiAdditionalCustomersByMonthDto[],
        estimatedCustomersByMonthSimilarLocationsByMonths: RoiAdditionalCustomersByMonthDto[]
    ): void {
        const estimatedCustomersData: AggregatedEstimatedCustomersData = [];
        const restaurantsWithoutData: string[] = [];

        estimatedCustomersByMonth.map(({ additionalCustomersByMonth, restaurantId, isMissingData }) => {
            const concernedRestaurant = restaurants.find((restaurant) => restaurant._id === restaurantId);
            const associatedEstimatedCustomersByMonthSimilarLocationsByMonths = estimatedCustomersByMonthSimilarLocationsByMonths.find(
                (estimatedCustomersSimilarLocations) => estimatedCustomersSimilarLocations.restaurantId === restaurantId
            );
            if (concernedRestaurant) {
                estimatedCustomersData.push({
                    restaurant: concernedRestaurant,
                    estimatedCustomersByMonth: additionalCustomersByMonth.map((estimate) => estimate.additionalCustomers),
                    estimatedCustomersSimilarLocationsByMonth:
                        associatedEstimatedCustomersByMonthSimilarLocationsByMonths?.additionalCustomersByMonth?.map(
                            (estimate) => estimate.additionalCustomers
                        ) ?? [],
                    dates: additionalCustomersByMonth.map((estimatedCustomer) => estimatedCustomer.monthStartDate),
                    isMissingData,
                });
                if (isMissingData) {
                    restaurantsWithoutData.push(concernedRestaurant.internalName ?? concernedRestaurant.name);
                }
            }

            return null;
        });

        this.estimatedCustomersData.set(estimatedCustomersData.filter(isNotNil));

        this.chartError.set({
            restaurantsWithoutData,
            hasError: false,
            noDataError: !!estimatedCustomersByMonth.length,
            errorMessage: '',
        });
    }
}
