import { NgClass } from '@angular/common';
import { Component, effect, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { partition } from 'lodash';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { getReviewsForSemanticAnalysis, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, Restaurant, ReviewWithAnalysis } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { ChartSortBy } from '../../enums/sort.enum';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import { TagsBarChartComponent } from './tags-bar-chart/tags-bar-chart.component';
import { TagsDoughnutChartComponent } from './tags-doughnut-chart/tags-doughnut-chart.component';
import { TagsEvolutionComponent } from './tags-evolution/tags-evolution.component';

@Component({
    selector: 'app-review-analyses',
    templateUrl: './review-analyses.component.html',
    styleUrls: ['./review-analyses.component.scss'],
    standalone: true,
    imports: [
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        TagsBarChartComponent,
        TagsDoughnutChartComponent,
        TagsEvolutionComponent,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        TranslateModule,
        NgClass,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ReviewAnalysesComponent implements OnInit, KillSubscriptions {
    @Input() shouldDetailTagsEvolutionCharts = false;
    @Input() shouldDisplayAnalysesTagCharts = true;
    @Input() shouldDisplayAnalysesTagEvolution = true;
    @Input() tagsEvolutionSortBy: ChartSortBy;
    @Output() tagsEvolutionSortByChange: EventEmitter<ChartSortBy> = new EventEmitter<ChartSortBy>();
    @Output() hasDataChange: EventEmitter<boolean> = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    @Input() dates$: Observable<{ startDate: Date | null; endDate: Date | null }> = this._store
        .select(AggregatedStatisticsSelectors.selectDatesFilter)
        .pipe(map(({ startDate, endDate }) => ({ startDate, endDate })));
    @Input() platformKeys$: Observable<PlatformKey[]> = this._store
        .select(AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
        .pipe(
            map((platforms) => {
                if (platforms?.length) {
                    return platforms;
                }
                return Object.values(PlatformKey);
            })
        );
    @Input() selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;
    @Input() showSplitByRestaurant = true;
    @Input() showSeeMoreButton = false;

    readonly SvgIcon = SvgIcon;
    isLoading = signal(true);
    isAnalysisDisabledOnAllRestaurants = false;
    noData = false;
    reviewsByRestaurantId: Record<string, ReviewWithAnalysis[]>;
    warningTooltip?: string;
    detailHref = environment.BASE_URL + '/groups/statistics/e-reputation';

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    constructor(
        private readonly _store: Store,
        private readonly _reviewsService: ReviewsService,
        private readonly _translate: TranslateService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        combineLatest([this.dates$, this.platformKeys$, this.selectedRestaurants$])
            .pipe(
                filter(
                    ([dates, platforms, _restaurants]) =>
                        !!_restaurants?.length && !!dates.startDate && !!dates.endDate && platforms.length > 0
                ),
                tap(() => this._reset()),
                map(([dates, platforms, restaurants]) => {
                    const [businessRestaurant, nonBusinessRestaurants] = partition(restaurants, (r) => r.isBrandBusiness());
                    this.warningTooltip = this._computeWarningTooltip(businessRestaurant);
                    return [dates, platforms, nonBusinessRestaurants];
                }),
                filter(([_dates, _platforms, restaurants]: [DatesAndPeriod, PlatformKey[], Restaurant[]]) => {
                    if (restaurants.length === 0) {
                        this.isLoading.set(false);
                        this.isAnalysisDisabledOnAllRestaurants = true;
                        this.hasDataChange.emit(false);
                        return false;
                    }
                    return true;
                }),
                switchMap(([dates, platforms, restaurants]) => {
                    const { startDate, endDate } = dates;
                    platforms = platforms.filter((key) => !PlatformDefinitions.getDeliveryPlatformKeys().includes(key as PlatformKey));
                    return forkJoin(
                        restaurants
                            .map((r) => r._id)
                            .reduce(
                                (acc, restaurantId) => ({
                                    ...acc,
                                    [restaurantId]: this._reviewsService.getReviewsWithAnalysis(
                                        startDate,
                                        endDate,
                                        platforms,
                                        restaurantId
                                    ),
                                }),
                                {}
                            )
                    );
                }),
                filter((res: Record<string, ReviewWithAnalysis[]>) => {
                    if (
                        Object.values(res)
                            .map((r) => r.length)
                            .reduce((acc, cur) => acc + cur, 0) === 0
                    ) {
                        this.isLoading.set(false);
                        this.hasDataChange.emit(false);
                        this.noData = true;
                        return false;
                    }
                    return true;
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (res: Record<string, ReviewWithAnalysis[]>) => {
                    this._store.dispatch(AggregatedStatisticsActions.editReviewsWithAnalysisByRestaurantData({ data: res }));
                    this.reviewsByRestaurantId = Object.entries(res).reduce(
                        (acc, cur) => ({
                            ...acc,
                            [cur[0]]: getReviewsForSemanticAnalysis(cur[1]),
                        }),
                        {}
                    );
                    this.isLoading.set(false);
                },
            });
    }

    private _computeWarningTooltip(restaurants: Restaurant[]): string | undefined {
        if (!restaurants.length) {
            return;
        }
        const restaurantsLabel = restaurants.map((e) => e.name).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
            restaurants: restaurantsLabel,
        });
    }

    private _reset(): void {
        this.isLoading.set(true);
        this.isAnalysisDisabledOnAllRestaurants = false;
        this.noData = false;
        this.warningTooltip = undefined;
    }
}
