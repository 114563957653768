import { LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafeHtml } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { APP_DEFAULT_LANGUAGE, MediaType } from '@malou-io/package-utils';

import { MediaPreviewModalComponent } from ':modules/gallery/modals/media-preview-modal/media-preview-modal.component';
import { ComputeBadgeStatusPipe, ReviewBadgeStatus } from ':modules/reviews/pipe/compute-badge-status.pipe';
import { DisplayMenuItemsPipe } from ':modules/reviews/pipe/display-menu-items.pipe';
import { GetEaterTotalOrdersPipe } from ':modules/reviews/pipe/get-eater-total-orders.pipe';
import { GetMenuItemReviewsPipe } from ':modules/reviews/pipe/get-menu-item-reviews.pipe';
import { HasAttachmentsPipe } from ':modules/reviews/pipe/has-attachments.pipe';
import { HasReplyPipe } from ':modules/reviews/pipe/has-reply.pipe';
import { ReviewArchiveTooltipPipe } from ':modules/reviews/pipe/review-archive-tooltip.pipe';
import { ReviewDatePipe } from ':modules/reviews/pipe/review-date.pipe';
import { ReviewShortTextLengthPipe } from ':modules/reviews/pipe/review-short-text-length.pipe';
import { ReviewTextPipe } from ':modules/reviews/pipe/review-text.pipe';
import { ReviewerDisplayNamePipe } from ':modules/reviews/pipe/reviewer-display-name.pipe';
import { RestaurantHeaderForReviewPreviewComponent } from ':modules/reviews/review-preview/restaurant-header-for-review-preview/restaurant-header-for-review-preview.component';
import { FoldType } from ':shared/components/review-preview/review-preview.component';
import { ReviewSemanticAnalysisComponent } from ':shared/components/review-preview/review-semantic-analysis/review-semantic-analysis.component';
import { ReviewTranslationsComponent } from ':shared/components/review-translations/review-translations.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { ReviewAnalysisHighlighter } from ':shared/helpers/analysis-highlight';
import { Media, Restaurant, Review, ReviewSocialAttachment, SegmentAnalyses } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-basic-preview',
    templateUrl: './basic-preview.component.html',
    styleUrls: ['./basic-preview.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        LazyLoadImageModule,
        RestaurantHeaderForReviewPreviewComponent,
        ReviewSemanticAnalysisComponent,
        ReviewTranslationsComponent,
        StarGaugeComponent,
        ApplySelfPurePipe,
        AvatarPipe,
        DisplayMenuItemsPipe,
        GetEaterTotalOrdersPipe,
        GetMenuItemReviewsPipe,
        HasReplyPipe,
        HasAttachmentsPipe,
        LowerCasePipe,
        PlatformLogoPathResolverPipe,
        PluralTranslatePipe,
        ReviewArchiveTooltipPipe,
        ReviewDatePipe,
        ReviewTextPipe,
        ReviewShortTextLengthPipe,
        ReviewerDisplayNamePipe,
        ShortTextPipe,
        ComputeBadgeStatusPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicPreviewComponent {
    readonly withArchiveButton = input<boolean>(true);
    readonly withSeeMoreButton = input<boolean>(true);
    readonly isSelected = input<boolean>(false);
    readonly isSelectable = input<boolean>(false);
    readonly hasBorder = input<boolean>(false);
    readonly displayFullText = input<boolean>(false);
    readonly disabled = input<boolean>(false);
    readonly shouldDisplaySemanticAnalysis = input<boolean>();
    readonly isAggregatedView = input<boolean>(false);
    readonly parentContainerClasses = input<string>('');
    readonly parentListIndex = input<number>();
    readonly restaurant = input.required<Restaurant | null>();
    readonly review = input.required<Review | PrivateReview>();
    readonly canShowTranslation = input.required<boolean>();

    readonly select = output<string>();
    readonly archived = output<number>();

    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _textHighlighter = inject(ReviewAnalysisHighlighter);

    readonly SvgIcon = SvgIcon;
    readonly MAX_MEDIA_ATTACHMENTS_SHOWN = 2;
    readonly MAX_MENU_ITEM_SHOWN = 3;
    readonly ReviewBadgeStatus = ReviewBadgeStatus;

    readonly isFold: { [Property in FoldType]: boolean } = {
        text: true,
        comment: true,
        menuItems: true,
    };
    highlightedReviewTextHtml = signal<SafeHtml | null>(null);

    readonly showTranslation = signal(false);
    readonly shouldShowTranslationTemplate = computed(
        () => this.canShowTranslation() && this.review().lang !== this.currentLang && this.review().hasText() && !!this.review().lang
    );
    readonly showOriginalTextWarning = computed(() => this.showTranslation() && this.shouldShowTranslationTemplate());
    readonly currentLang = localStorage.getItem(LocalStorageKey.LANG) || APP_DEFAULT_LANGUAGE;

    constructor() {
        effect(
            () => {
                this.showTranslation.set(this.review().hasTranslations(this.currentLang));
                this.highlightAllSegments();
            },
            { allowSignalWrites: true }
        );
    }

    onReviewClick(): void {
        this.select.emit(this.review()._id);
    }

    toggleArchived(_review: Review | PrivateReview): void {
        const parentListIndex = this.parentListIndex();
        if (parentListIndex) {
            this.archived.emit(parentListIndex);
        }
    }

    toggleFold(foldKey: FoldType): void {
        this.isFold[foldKey] = !this.isFold[foldKey];
    }

    openCarousel(attachments: ReviewSocialAttachment[], index: number): void {
        this._customDialogService
            .open(MediaPreviewModalComponent, {
                panelClass: 'malou-dialog-no-padding',
                data: {
                    mediaIndex: index,
                    medias: attachments.map(
                        (attachment) =>
                            new Media({
                                urls: attachment.urls,
                                type: ['photo', 'image'].includes(attachment.type) ? MediaType.PHOTO : MediaType.PHOTO,
                            })
                    ),
                    restaurant: this.restaurant(),
                    actions: [],
                },
            })
            .afterClosed()
            .subscribe();
    }

    onHoveredChip(segmentAnalyses: SegmentAnalyses): void {
        this.highlightedReviewTextHtml.set(this._textHighlighter.setReviewTextHtmlSegmentsHighlights(this.review(), segmentAnalyses));
    }

    highlightAllSegments(): void {
        this.highlightedReviewTextHtml.set(this._textHighlighter.getHighlightedReviewTextHtml(this.review()));
    }
}
