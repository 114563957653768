import { Action, createReducer, on } from '@ngrx/store';

import { MalouDateFilters, MalouPeriod, MalouTimeScalePeriod, Nfc, Restaurant } from ':shared/models';

import * as AggregatedStatisticsActions from './aggregated-statistics.actions';
import { AggregatedStatisticsState, PlatformFilterPage } from './aggregated-statistics.interface';

const malouDateFilters = new MalouDateFilters();

export const initialState: AggregatedStatisticsState = {
    filters: {
        dates: malouDateFilters.getFilter({ period: MalouPeriod.DEFAULT }),
        platforms: {
            [PlatformFilterPage.E_REPUTATION]: [],
            [PlatformFilterPage.SOCIAL_NETWORKS]: [],
            [PlatformFilterPage.BOOSTERS]: [],
            [PlatformFilterPage.ROI]: [],
            [PlatformFilterPage.SEO]: [],
        },
        restaurantsIds: [],
        roiRestaurantsIds: [],
        totems: [],
        timeScale: MalouTimeScalePeriod.LAST_SIX_MONTHS,
    },
    data: {
        reviewsWithAnalysisByRestaurant: {},
        platformsRatingsByRestaurant: {},
        reviewCounts: [],
        averageReviewsRatings: [],
        boosterStatsData: undefined,
    },
};

const aggregatedStatisticsReducer = createReducer(
    initialState,
    on(AggregatedStatisticsActions.editDates, (state, { dates }) => ({
        ...state,
        filters: {
            ...state.filters,
            dates: dates,
        },
    })),
    on(AggregatedStatisticsActions.editPlatforms, (state, { page, platforms }) => {
        if (!page) {
            return state;
        }
        return {
            ...state,
            filters: {
                ...state.filters,
                platforms: {
                    ...state.filters.platforms,
                    [page]: platforms,
                },
            },
        };
    }),
    on(AggregatedStatisticsActions.editRestaurants, (state, { restaurants }: { restaurants: Restaurant[] }) => ({
        ...state,
        filters: {
            ...state.filters,
            restaurantsIds: restaurants.map((restaurant) => restaurant._id),
        },
    })),
    on(AggregatedStatisticsActions.editRoiRestaurants, (state, { roiRestaurants }: { roiRestaurants: Restaurant[] }) => ({
        ...state,
        filters: {
            ...state.filters,
            roiRestaurantsIds: roiRestaurants.map((restaurant) => restaurant._id),
        },
    })),
    on(AggregatedStatisticsActions.editTotems, (state, { totems }: { totems: Nfc[] }) => ({
        ...state,
        filters: {
            ...state.filters,
            totems,
        },
    })),
    on(AggregatedStatisticsActions.editReviewsWithAnalysisByRestaurantData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            reviewsWithAnalysisByRestaurant: data,
        },
    })),
    on(AggregatedStatisticsActions.editPlatformsRatingsByRestaurantData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            platformsRatingsByRestaurant: data,
        },
    })),
    on(AggregatedStatisticsActions.editReviewCountsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            reviewCounts: data,
        },
    })),
    on(AggregatedStatisticsActions.editAverageReviewsRatingsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            averageReviewsRatings: data,
        },
    })),
    on(AggregatedStatisticsActions.editBoosterStatsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            boosterStatsData: data,
        },
    })),
    on(AggregatedStatisticsActions.editTimeScale, (state, { data }) => ({
        ...state,
        filters: {
            ...state.filters,
            timeScale: data,
        },
    })),
    on(AggregatedStatisticsActions.resetState, () => initialState)
);

export function reducer(state: AggregatedStatisticsState, action: Action): AggregatedStatisticsState {
    return aggregatedStatisticsReducer(state, action);
}
