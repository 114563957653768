import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';

import { APP_DEFAULT_LANGUAGE, ApplicationLanguage } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { RestaurantAiSettingsService } from ':core/services/restaurant-ai-settings.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { AdvancedAiSettingsComponent } from ':modules/automations/review-reply-automations/restaurant-ai-settings/edit-restaurant-ai-settings-modal/advanced-ai-settings/advanced-ai-settings.component';
import { GeneralAiSettingsComponent } from ':modules/automations/review-reply-automations/restaurant-ai-settings/edit-restaurant-ai-settings-modal/general-ai-settings/general-ai-settings.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Keyword } from ':shared/models';
import { RestaurantAiSettings } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export interface AiSettingsSignatureWithId {
    id: string;
    text: string;
}

export class RestaurantAiSettingsFormData extends RestaurantAiSettings {
    signaturesForForm?: AiSettingsSignatureWithId[];

    constructor(init: RestaurantAiSettings & { signaturesForForm?: AiSettingsSignatureWithId[] }) {
        super(init);
        this.signaturesForForm = init.signaturesForForm;
    }
}

export interface EditRestaurantAiSettingsModalInputData {
    restaurantAiSettings: RestaurantAiSettings;
    selectedKeywords: Keyword[];
}

interface RestaurantAiSettingsTab {
    label: string;
    key: TabKeys;
}

enum TabKeys {
    GENERAL = 'general',
    ADVANCED = 'advanced',
}

@Component({
    selector: 'app-edit-restaurant-ai-settings-modal',
    templateUrl: './edit-restaurant-ai-settings-modal.component.html',
    styleUrls: ['./edit-restaurant-ai-settings-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatTooltipModule,
        TranslateModule,
        AdvancedAiSettingsComponent,
        CloseWithoutSavingModalComponent,
        GeneralAiSettingsComponent,
        MalouSpinnerComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditRestaurantAiSettingsModalComponent {
    private readonly _data: EditRestaurantAiSettingsModalInputData = inject(MAT_DIALOG_DATA);
    private readonly _restaurantAiSettingsService = inject(RestaurantAiSettingsService);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _dialogRef = inject(MatDialogRef<EditRestaurantAiSettingsModalComponent>);

    readonly SvgIcon = SvgIcon;
    readonly restaurantAiSettings = signal<RestaurantAiSettingsFormData | undefined>(undefined);
    readonly selectedKeywords = signal<Keyword[]>([]);

    readonly TabKeys = TabKeys;
    private readonly _GENERAL_TAB: RestaurantAiSettingsTab = {
        key: TabKeys.GENERAL,
        label: this._translateService.instant('restaurant_ai_settings.modals.upsert.tabs.general.title'),
    };
    private readonly _ADVANCED_TAB: RestaurantAiSettingsTab = {
        key: TabKeys.ADVANCED,
        label: this._translateService.instant('restaurant_ai_settings.modals.upsert.tabs.advanced.title'),
    };
    readonly TABS = signal<RestaurantAiSettingsTab[]>([this._GENERAL_TAB, this._ADVANCED_TAB]);
    readonly selectedTabIndex = signal<number>(0);
    readonly selectedTab = computed<RestaurantAiSettingsTab>(() => this.TABS()[this.selectedTabIndex()]);

    readonly areRestaurantAiSettingsValid = computed(() => !!this.restaurantAiSettings()?.restaurantName);

    readonly displayCloseModal = signal(false);
    readonly isSubmitting = signal(false);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });
    isTouched = false;
    isFirstEffectCall = true;

    constructor() {
        this.restaurantAiSettings.set(
            new RestaurantAiSettingsFormData({
                ...this._data.restaurantAiSettings,
                signaturesForForm: this._data.restaurantAiSettings?.signatures?.map((signature) => ({
                    id: uuid(),
                    text: signature,
                })) ?? [this._getNewSignature()],
            })
        );
        this.selectedKeywords.set(this._data.selectedKeywords);

        effect(() => {
            this.restaurantAiSettings(); // only used to trigger the effect
            if (this.isFirstEffectCall) {
                this.isFirstEffectCall = false;
                return;
            }
            this.isTouched = true;
        });
    }

    confirmClose(restaurantAiSettings: RestaurantAiSettings | null = null): void {
        this._dialogRef.close(restaurantAiSettings);
    }

    close(): void {
        if (this.isSubmitting()) {
            return;
        }
        if (this.isTouched) {
            this.displayCloseModal.set(true);
        } else {
            this.confirmClose();
        }
    }

    submit(): void {
        const restaurantAiSettings = this.restaurantAiSettings();

        if (!this.areRestaurantAiSettingsValid() || !restaurantAiSettings) {
            return;
        }

        this.isSubmitting.set(true);

        const filledSignatures = restaurantAiSettings.signaturesForForm?.map(({ text }) => text).filter((signature) => !!signature);

        this._restaurantAiSettingsService
            .updateRestaurantAiSettings({
                ...restaurantAiSettings,
                signatures: filledSignatures,
                shouldTranslateSignature: restaurantAiSettings?.shouldTranslateSignature ?? false,
                shouldTranslateCatchphrase: restaurantAiSettings?.shouldTranslateCatchphrase ?? false,
                sourceLanguage: (localStorage.getItem(LocalStorageKey.LANG) as ApplicationLanguage) ?? APP_DEFAULT_LANGUAGE,
            })
            .subscribe({
                next: (updatedRestaurantAiSettings) => {
                    this.confirmClose(updatedRestaurantAiSettings);
                    this._toastService.openSuccessToast(this._translateService.instant('restaurant_ai_settings.modals.upsert.success'));
                    this.isSubmitting.set(false);
                },
                error: () => {
                    this._toastService.openErrorToast(
                        this._translateService.instant('restaurant_ai_settings.modals.upsert.errors.upsert_error')
                    );
                    this.isSubmitting.set(false);
                },
            });
    }

    handleTabChange(index: number): void {
        this.selectedTabIndex.set(index);
    }

    private _getNewSignature(): AiSettingsSignatureWithId {
        return { id: uuid(), text: '' };
    }
}
