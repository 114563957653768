import { Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { getLocalStorageKey, getSavedState } from ':core/storage.metareducer';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { HeaderComponent } from ':shared/components/header/header.component';
import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';
import { MalouDateFilters, MalouPeriod } from ':shared/models';

import { ReviewsDisplayMode } from '../reviews/store/reviews.reducer';
import { selectUserInfos } from '../user/store/user.selectors';

@Component({
    selector: 'app-aggregated-reputation',
    templateUrl: './aggregated-reputation.component.html',
    styleUrls: ['./aggregated-reputation.component.scss'],
    standalone: true,
    imports: [HeaderComponent, NavigationHeaderComponent, RouterOutlet, ScrollToTopComponent],
})
export class AggregatedReputationComponent implements OnDestroy {
    readonly _REVIEWS_TAB: NavigationTab = {
        label: this._translate.instant('aggregated_reputation.navigation_header.reviews'),
        link: './reviews',
    };
    readonly TABS: NavigationTab[] = [this._REVIEWS_TAB];
    readonly dateFilters = new MalouDateFilters();

    constructor(
        private readonly _translate: TranslateService,
        private readonly _store: Store
    ) {
        this._initAggregatedReviewsState();
    }

    _initAggregatedReviewsState(): void {
        this._store.select(selectUserInfos).subscribe((userInfos) => {
            const userId = userInfos?._id;
            const storageLocalKey = getLocalStorageKey(userId);
            const persistedState = getSavedState(storageLocalKey);
            if (persistedState?.reviews?.filters?.period === MalouPeriod.ALL) {
                this._store.dispatch(
                    ReviewsActions.editReviewsFiltersDates({
                        datesFilters: new MalouDateFilters().getFilter({ period: MalouPeriod.LAST_AND_COMING_THIRTY_DAYS }),
                    })
                );
            }
            this._store.dispatch(ReviewsActions.editReviewsFiltersCurrentView({ currentView: ReviewsDisplayMode.AGGREGATED_RESTAURANTS }));
        });
    }

    ngOnDestroy(): void {
        this._store.dispatch(ReviewsActions.resetReviewsStateExceptRestaurants());
    }
}
