import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { selectOpenedFooterCount } from ':core/components/restaurant/footer-manager/store/footer-manager.reducer';
import { DialogService } from ':core/services/dialog.service';
import { FacebookService } from ':core/services/facebook.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { PostComponent } from ':shared/components/post/post.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { CheckPermissionsDirective } from ':shared/directives/permissions-check.directive';
import { KillSubscriptions } from ':shared/interfaces';
import {
    ApiResult,
    PostFilterOptions,
    PostFilterType,
    PostWithInsights,
    PostWithInsightsAndHoveredPosts,
    Restaurant,
} from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import * as PlatformsActions from '../../platforms/store/platforms.actions';
import { EditInspiringAccountsDialogComponent } from './edit-inspiring-accounts-dialog/edit-inspiring-accounts-dialog.component';

interface AppState {
    platforms: {
        platformsData: any;
    };
}

interface IGAccount {
    business_discovery: {
        followers_count: number;
        media_count: number;
        username: string;
        media: {
            data: PostWithInsights[];
        };
    };
}

@Component({
    selector: 'app-inspirations',
    templateUrl: './inspirations.component.html',
    styleUrls: ['./inspirations.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        TranslateModule,
        PostComponent,
        SkeletonComponent,
        SortByFiltersComponent,
        ScrollToTopComponent,
        CheckPermissionsDirective,
        ApplyPurePipe,
        AsyncPipe,
        IllustrationPathResolverPipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class InspirationsComponent implements OnInit, KillSubscriptions {
    restaurant: Restaurant;
    igMedia$: Observable<any[] | null>;
    restaurant$ = this._restaurantsServices.restaurantSelected$;
    posts$: Subscription;
    filterOptions = PostFilterOptions.get(this._translate, PostFilterType.ENGAGEMENT, [
        PostFilterType.LIKE,
        PostFilterType.COMMENT,
        PostFilterType.CREATED_AT,
        PostFilterType.ENGAGEMENT,
    ]);
    defaultOptionValue: PostFilterType;
    showHover = false;
    allPosts: PostWithInsights[] = [];
    isLoading$ = new BehaviorSubject(false);
    wait: boolean;
    isIgPlatformConnected = true;
    readonly killSubscriptions$: Subject<void> = new Subject();
    footersVisibilityCount = 0;
    sortDirection = -1;
    sortKey = PostFilterType.ENGAGEMENT;
    isSavedPostsView = false;
    bookmarkedPosts: { post: PostWithInsights; hoveredPosts: { url: string }[] }[] = [];

    readonly PlatformKey = PlatformKey;
    readonly SvgIcon = SvgIcon;

    private _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _facebookServices: FacebookService,
        private readonly _restaurantsServices: RestaurantsService,
        private readonly _postsService: PostsService,
        private readonly _spinnerService: SpinnerService,
        private readonly _store: Store<AppState>,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _translate: TranslateService,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _dialogService: DialogService,
        private readonly _toastService: ToastService,
        private readonly _customDialogService: CustomDialogService
    ) {}

    ngOnInit(): void {
        this.defaultOptionValue = this.getSelectedOptionFilterField('key');
        this.igMedia$ = this.restaurant$.pipe(
            filter(Boolean),
            switchMap((restaurant) => this._facebookServices.igMedia(restaurant._id).pipe(map((res) => res.data)))
        );
        this.restaurant$
            .pipe(filter(Boolean), takeUntilDestroyed(this._destroyRef))
            .subscribe((restaurant) => (this.restaurant = restaurant));

        this.posts$ = this.restaurant$
            .pipe(
                switchMap((restaurant) => combineLatest([of(restaurant), this._store.select((state) => state.platforms.platformsData)])),
                filter(([restaurant, data]) => !!restaurant && !!data[restaurant._id]),
                map(([restaurant, data]: [Restaurant, any]) => {
                    this.bookmarkedPosts = this._getBookmarkedPosts(restaurant);
                    const igPlatformData = data[restaurant._id]?.find((platform) => platform.key === PlatformKey.INSTAGRAM);
                    return igPlatformData;
                }),
                switchMap((igPlatformData) => {
                    if (!igPlatformData) {
                        this.isIgPlatformConnected = false;
                        return of({ data: [], msg: '' });
                    }
                    this.isLoading$.next(true);
                    return this._postsService.getCompetitorsPosts(igPlatformData._id);
                }),
                tap((res) => {
                    this._spinnerService.hide();
                    if (res.data && res.data.some((account) => !!(account as unknown as { error: any }).error)) {
                        this._dialogService.open({
                            title: this._translate.instant('inspirations.users_not_found'),
                            message: this._translate.instant('inspirations.users_not_found_html', {
                                users: res.data
                                    .filter((acc) => !!(acc as unknown as { error: any }).error)
                                    .map((acc) => (acc as unknown as { username: any }).username)
                                    .join(', '),
                            }),
                            variant: DialogVariant.INFO,
                            primaryButton: {
                                label: this._translate.instant('common.understood'),
                            },
                        });
                    }
                }),
                map((res) => {
                    this.isLoading$.next(false);
                    return this.processResponse(res);
                }),
                catchError((error) => {
                    this.isLoading$.next(false);
                    if (error?.error?.message) {
                        this._toastService.openErrorToast(this.clarifyError(error));
                    }
                    return of([]);
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe((res) => {
                this.allPosts = res;
            });

        this._store
            .select(selectOpenedFooterCount)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((count) => {
                this.footersVisibilityCount = count;
            });
    }

    isAVideoUrl(url: string): boolean {
        return url?.includes('video-');
    }

    isSaved = (post: PostWithInsights): boolean =>
        !!this.restaurant.bookmarkedPosts?.find((bp) => bp.key === PlatformKey.INSTAGRAM && bp.socialId === post.socialId);

    processResponse(res: ApiResult): PostWithInsights[] {
        if (res && res.data) {
            const posts: PostWithInsights[] = res.data
                .map((account: IGAccount) =>
                    (account?.business_discovery?.media?.data || []).map(
                        (media) =>
                            new PostWithInsights({
                                ...media,
                                key: PlatformKey.INSTAGRAM,
                                nbFollowers: account.business_discovery?.followers_count,
                                username: account.business_discovery?.username,
                                accountEngagement:
                                    account?.business_discovery?.media?.data?.reduce((acc, curr) => acc + (curr.likes || 0), 0) /
                                    (account.business_discovery?.followers_count * account?.business_discovery?.media?.data.length),
                            })
                    )
                )
                .flat();
            const option = this.filterOptions.find((opt) => !!opt.selected);
            if (!option) {
                return posts;
            }
            return posts.sort((a, b) => option.sort(a, b, this.sortDirection));
        }
        return [];
    }

    save({ post, hoveredPosts }: { post: PostWithInsights; hoveredPosts: { url: string }[] }): void {
        if (!this.wait) {
            this.wait = true;
            if (this.isSaved(post)) {
                this._restaurantsServices
                    .update(this.restaurant._id, {
                        bookmarkedPosts: this._pullBookmarkedPost(post),
                    })
                    .subscribe({
                        next: (res) => {
                            this.restaurant = res.data;
                            this.bookmarkedPosts = this._getBookmarkedPosts(res.data);
                            this.wait = false;
                        },
                        error: (err) => {
                            this.wait = false;
                            console.warn('err :>>', err);
                            if (err.status === 403) {
                                return;
                            }
                            this._toastService.openErrorToast(this._translate.instant('inspirations.try_to_fix'));
                        },
                    });
            } else {
                this._restaurantsServices
                    .update(this.restaurant._id, {
                        bookmarkedPosts: this._pushBookmarkedPost(post, hoveredPosts),
                    })
                    .pipe(
                        switchMap((res) => {
                            this.restaurant = res.data;
                            this.bookmarkedPosts = this._getBookmarkedPosts(res.data);
                            return this._restaurantsServices.bookmarkedPostJob(this.restaurant._id, post);
                        })
                    )
                    .subscribe({
                        next: () => {
                            this.wait = false;
                        },
                        error: (err) => {
                            this.wait = false;
                            console.warn('err :>>', err);
                            if (err.status === 403) {
                                return;
                            }
                            this._toastService.openErrorToast(this._translate.instant('inspirations.try_to_fix'));
                        },
                    });
            }
        }
    }

    clarifyError(err: any): string {
        if (err.error?.message?.match(/FB_CREDENTIAL/)) {
            return this._translate.instant('inspirations.instagram_not_connected');
        }
        return this._translate.instant('inspirations.try_to_fix');
    }

    showMetric(metric: { key: string }): boolean {
        return metric.key !== 'timestamp';
    }

    openEditInspiringAccounts(): void {
        const endSub$ = new Subject();
        this._store
            .select((state) => state.platforms.platformsData)
            .pipe(
                map((data) => data[this.restaurant._id]?.find((platform) => platform.key === PlatformKey.INSTAGRAM)),
                switchMap((igPlatformData) =>
                    this._customDialogService
                        .open(EditInspiringAccountsDialogComponent, {
                            panelClass: 'malou-dialog-panel--large',
                            height: undefined,
                            width: '80vw',
                            autoFocus: false,
                            data: {
                                watchedAccounts: igPlatformData?.watchedAccounts || [],
                                igPlatform: igPlatformData,
                                restaurant: this.restaurant,
                            },
                        })
                        .afterClosed()
                        .pipe(
                            tap((result) => {
                                endSub$.next(true);
                                if (result?.dirtyModal) {
                                    this._store.dispatch({
                                        type: PlatformsActions.loadPlatformsData.type,
                                        restaurantId: this.restaurant._id,
                                    });
                                    this._spinnerService.hide();
                                }
                            })
                        )
                ),
                takeUntil(endSub$)
            )
            .subscribe();
    }

    getSelectedOptionFilterField = (key: string): PostFilterType => this.filterOptions.find((fOption) => !!fOption.selected)?.[key];

    applyFilter(key: string): void {
        this.sortKey = key as PostFilterType;
        this.filterOptions.forEach((opt) => (opt.selected = false));
        const option = this.filterOptions.find((opt) => opt.key === key);
        if (!option) {
            return;
        }
        option.selected = true;
        this.allPosts.sort((a, b) => option.sort(a, b, this.sortDirection));
    }

    getHoveredPosts = (username: string): { url: string }[] =>
        this.allPosts
            .filter((post) => post.username === username)
            .slice(0, 9)
            .map((post) => ({ url: post.url }));

    goToPlatforms(): void {
        this._router.navigate(['../../../settings/platforms/connection'], { relativeTo: this._activatedRoute });
    }

    onSortOrderChange(): void {
        this.sortDirection = this.sortDirection * -1;
        this.applyFilter(this.sortKey);
    }

    changePostsView(): void {
        this.isSavedPostsView = !this.isSavedPostsView;
        this.applyFilter(this.sortKey);
    }

    private _getBookmarkedPosts(restaurant: Restaurant): { post: PostWithInsights; hoveredPosts: { url: string }[] }[] {
        return restaurant.bookmarkedPosts.map((bookmarkedPost) => ({
            post: new PostWithInsights(bookmarkedPost),
            hoveredPosts: bookmarkedPost.hoveredPosts || [],
        }));
    }

    private _pullBookmarkedPost(post: PostWithInsights): PostWithInsightsAndHoveredPosts[] {
        return this.restaurant.bookmarkedPosts.filter((bp) => bp.key === PlatformKey.INSTAGRAM && bp.socialId !== post.socialId);
    }

    private _pushBookmarkedPost(post: PostWithInsights, hoveredPosts: { url: string }[]): PostWithInsightsAndHoveredPosts[] {
        const newBookmarkedPost = { ...post, hoveredPosts } as PostWithInsightsAndHoveredPosts;
        return [...this.restaurant.bookmarkedPosts, newBookmarkedPost];
    }
}
