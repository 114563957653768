<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <!-- DESKTOP VIEW -->
        <div class="flex h-full overflow-y-hidden md:hidden">
            <div class="malou-dialog !w-1/2">
                <div class="malou-dialog__header !justify-start">
                    <span>{{ 'inspirations.edit.add_account' | translate }}</span>
                </div>
                <div class="malou-dialog__body suggestions-container">
                    <ng-container [ngTemplateOutlet]="searchAccountsView"></ng-container>
                </div>
            </div>

            <div class="malou-dialog custom-dialog-background !w-1/2">
                <div class="malou-dialog__header">
                    <span
                        >{{ 'inspirations.edit.watched_accounts' | translate }} ({{ watchedAccounts.length }} /
                        {{ MAX_WATCHED_ACCOUNTS }})</span
                    >
                    <mat-icon
                        class="close cursor-pointer"
                        id="tracking_edit_inspiring_accounts_close_modal_icon"
                        [svgIcon]="SvgIcon.CROSS"
                        (click)="cancel()"></mat-icon>
                </div>
                <div class="malou-dialog__body">
                    <ng-container [ngTemplateOutlet]="watchedAccountsView"></ng-container>
                </div>
            </div>
        </div>

        <!-- MOBILE VIEW -->
        <div class="hidden h-full flex-col overflow-y-hidden md:flex">
            <div class="malou-dialog__header">
                <span>{{ 'inspirations.edit.add_new_account' | translate }}</span>
                <mat-icon
                    class="close cursor-pointer"
                    id="tracking_edit_inspiring_accounts_close_modal_icon"
                    [svgIcon]="SvgIcon.CROSS"
                    (click)="cancel()"></mat-icon>
            </div>

            <div class="malou-dialog__body suggestions-mobile-container">
                <mat-tab-group animationDuration="5ms" mat-align-tabs="start">
                    <mat-tab label="{{ 'common.search' | translate }}">
                        <ng-container [ngTemplateOutlet]="searchAccountsView"></ng-container>
                    </mat-tab>

                    <mat-tab
                        label="{{ 'inspirations.edit.my_list' | translate }} ({{ watchedAccounts.length }} / {{ MAX_WATCHED_ACCOUNTS }})">
                        <div class="my-6">
                            <span class="malou-text-18--bold">
                                {{ 'inspirations.edit.watched_accounts' | translate }} ({{ watchedAccounts.length }} /
                                {{ MAX_WATCHED_ACCOUNTS }})
                            </span>
                        </div>
                        <ng-container [ngTemplateOutlet]="watchedAccountsView"></ng-container>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>

        <!-- FOOTER -->
        <div class="malou-dialog__footer md:justify-between">
            <button
                class="malou-btn-raised--secondary !h-11 md:grow"
                id="tracking_edit_inspiring_accounts_cancel_button"
                mat-raised-button
                (click)="confirmClose()">
                {{ 'common.cancel' | translate }}
            </button>
            <button
                class="malou-btn-raised--primary !h-11 md:grow"
                id="tracking_edit_inspiring_accounts_validate_button"
                mat-raised-button
                (click)="save()">
                {{ 'common.confirm' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #searchAccountsView>
    <div class="flex flex-col">
        <div class="malou-text-10--regular malou-color-text-2 mt-3.5 flex italic">
            {{ 'inspirations.edit.search_business_account' | translate }}
        </div>
        <div class="mt-1.5 flex">
            <app-search
                class="grow"
                [placeholder]="'common.search' | translate"
                [debounceTime]="500"
                (searchChange)="typed($event)"></app-search>
        </div>

        @if ((searchText$ | async)?.length) {
            <div class="flex">
                @if (searchResult) {
                    <div class="flex flex-1 flex-col">
                        <div class="mt-4.5 flex">
                            <span class="malou-color-text-1 malou-text-18--bold">{{ 'inspirations.edit.result' | translate }}</span>
                        </div>
                        @if (searching) {
                            <div class="flex justify-center">
                                <mat-spinner diameter="20"></mat-spinner>
                            </div>
                        }
                        @if (searchResult.status === SearchResultStatus.FOUND) {
                            <div class="mt-6.5 flex">
                                <ng-container
                                    [ngTemplateOutlet]="searchedAccount"
                                    [ngTemplateOutletContext]="{
                                        account: searchResult.account,
                                        index: null,
                                        shouldFetchNewAccount: false,
                                    }">
                                </ng-container>
                            </div>
                        }
                        @if (searchResult.status === SearchResultStatus.NOT_FOUND) {
                            <div
                                class="no-search-result-card__border my-4.5 flex h-[130px] flex-col items-center justify-center rounded-[10px] px-4">
                                <p class="malou-text-12--semibold">{{ 'inspirations.edit.accounts_not_found' | translate }}</p>
                                <p class="malou-text-10--regular text-center italic leading-5">
                                    {{ 'inspirations.edit.check_spelling' | translate }}
                                </p>
                            </div>
                        }
                    </div>
                }
            </div>
        }

        <div class="mt-4.5 flex">
            <span class="malou-color-text-1 malou-text-18--bold">{{ 'inspirations.edit.suggestions' | translate }}</span>
            @if (!canFetch) {
                <mat-spinner class="ml-2" diameter="20"></mat-spinner>
            }
        </div>

        @if (recommendedAccounts.length) {
            <div class="mt-6.5 flex flex-col">
                @for (account of recommendedAccounts; track account; let index = $index) {
                    <div class="mb-2 flex">
                        <ng-container
                            [ngTemplateOutlet]="searchedAccount"
                            [ngTemplateOutletContext]="{ account, index, shouldFetchNewAccount: true }">
                        </ng-container>
                    </div>
                }
                <div class="my-2.5 flex justify-center">
                    <button class="malou-btn-flat" mat-button (click)="loadMoreAccounts()">
                        {{ 'inspirations.edit.see_more' | translate }}
                    </button>
                </div>
            </div>
        } @else {
            <div class="inspirations-grid mt-6.5 grid h-full gap-4">
                @for (item of [0, 1, 2]; track item) {
                    <app-skeleton skeletonClass="h-[105px] flex secondary-bg p-3" [animate]="false">
                        <ng-template #nestedSkeletonTemplate>
                            <div class="mr-3 flex">
                                <app-skeleton skeletonClass="h-full !w-[70px] rounded-[10px]"></app-skeleton>
                            </div>
                            <div class="flex grow flex-col gap-2">
                                <app-skeleton skeletonClass="h-[20px] !w-[80px] rounded-[10px]"></app-skeleton>
                                <app-skeleton skeletonClass="h-[20px] !w-[100px] rounded-[10px]"></app-skeleton>
                                <app-skeleton skeletonClass="h-[20px] w-full rounded-[10px]"></app-skeleton>
                            </div>
                            <div class="ml-3 flex">
                                <app-skeleton skeletonClass="h-[30px] !w-[30px] rounded-[10px]"></app-skeleton>
                            </div>
                        </ng-template>
                    </app-skeleton>
                }
            </div>
        }
    </div>
</ng-template>

<ng-template #watchedAccountsView>
    @if (watchedAccounts.length) {
        <div>
            <div class="flex flex-col">
                @for (account of watchedAccounts; track account; let index = $index) {
                    <div class="mb-2 flex">
                        <ng-container [ngTemplateOutlet]="watchedAccount" [ngTemplateOutletContext]="{ account: account }"> </ng-container>
                    </div>
                }
            </div>
        </div>
    } @else {
        <div class="flex h-full flex-col items-center justify-center">
            <img class="h-32" loading="lazy" [src]="'Goggles' | illustrationPathResolver" />
            <p class="malou-text-14--bold malou-color-text-1 mt-10 text-center">{{ 'inspirations.inspire_expire' | translate }}</p>
            <p class="malou-text-10--regular malou-color-text-2 my-4.5 text-center">
                {{ 'inspirations.edit.search_accounts' | translate }}
            </p>
        </div>
    }
</ng-template>

<ng-template let-account="account" #watchedAccount>
    <div class="ig-account-card">
        <div class="mr-2">
            <img
                class="rounded-[10px]"
                alt="account.username"
                width="92"
                height="92"
                [defaultImage]="'default-picture-grey' | imagePathResolver"
                [lazyLoad]="account.profilePicUrl"
                [errorImage]="'default-picture-grey' | imagePathResolver" />
        </div>

        <div class="ml-4 flex w-full flex-col">
            <div class="flex justify-between">
                <div class="flex flex-col justify-around">
                    @if (account.userName) {
                        <p class="malou-text-14--bold malou-color-text-1">{{ '@' + account.userName }}</p>
                    }
                    <p class="malou-color-text-1 malou-text-10--regular">
                        @if (account.followersCount) {
                            <span class="malou-text-10--semibold">
                                {{ account.followersCount | shortNumber }}
                            </span>
                        }
                        <span>{{ 'inspirations.edit.followers' | translate }} - </span>
                        @if (account.mediaCount) {
                            <span class="malou-text-10--semibold">
                                {{ account.mediaCount }}
                            </span>
                        }
                        <span>{{ 'inspirations.edit.posts' | translate }}</span>
                    </p>
                </div>
                <div class="flex">
                    <button
                        class="malou-btn-icon--secondary btn-xl tracking_edit_inspiring_accounts_remove_account_button"
                        mat-icon-button
                        [matTooltip]="'common.remove' | translate"
                        (click)="remove(account.userName)">
                        <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
                    </button>
                </div>
            </div>
            <div class="malou-text-10--regular mt-1 flex" [matTooltip]="account.biography?.length > 150 ? account.biography : ''">
                {{ account.biography || '' | shortText: 150 }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template let-account="account" let-index="index" let-shouldFetchNewAccount="shouldFetchNewAccount" #searchedAccount>
    <div class="ig-account-card">
        <div class="mr-2">
            <img
                class="rounded-[10px]"
                alt="account.username"
                width="92"
                height="92"
                [defaultImage]="'default-picture-grey' | imagePathResolver"
                [lazyLoad]="account.profile_picture_url"
                [errorImage]="'default-picture-grey' | imagePathResolver" />
        </div>

        <div class="ml-4 flex w-full flex-col">
            <div class="flex justify-between">
                <div class="flex flex-col justify-around">
                    @if (account.username) {
                        <p class="malou-text-14--bold malou-color-text-1">{{ '@' + account.username }}</p>
                    }
                    <p class="malou-color-text-1 malou-text-10--regular">
                        @if (account.followers_count) {
                            <span class="malou-text-10--semibold">
                                {{ account.followers_count | shortNumber }}
                            </span>
                        }
                        <span>{{ 'inspirations.edit.followers' | translate }} - </span>
                        @if (account.media_count) {
                            <span class="malou-text-10--semibold">
                                {{ account.media_count }}
                            </span>
                        }
                        <span>{{ 'inspirations.edit.posts' | translate }}</span>
                    </p>
                </div>
                <div
                    class="flex"
                    matTooltip="{{ 'inspirations.edit.limit_reached' | translate }}"
                    [matTooltipDisabled]="watchedAccounts.length < MAX_WATCHED_ACCOUNTS">
                    <button
                        class="malou-btn-icon--secondary btn-xl tracking_edit_inspiring_accounts_add_account_button"
                        mat-icon-button
                        [matTooltip]="watchedAccounts.length < MAX_WATCHED_ACCOUNTS ? ('common.add' | translate) : ''"
                        [disabled]="watchedAccounts.length >= MAX_WATCHED_ACCOUNTS"
                        (click)="choose(account); shouldFetchNewAccount ? fetchNewAccounts(1, index) : null">
                        <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
                    </button>
                </div>
            </div>
            <div class="malou-text-10--regular mt-1 flex" [matTooltip]="account.biography?.length > 150 ? account.biography : ''">
                {{ account.biography || '' | shortText: 150 }}
            </div>
        </div>
    </div>
</ng-template>
