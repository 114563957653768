import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlatformAccessStatus, PlatformAccessType, PlatformKey } from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { PlatformAccess } from ':shared/models';

type AuthorizedPlatformKeys = PlatformKey.ZENCHEF | PlatformKey.DELIVEROO;

export type LoginInfo = {
    login: string;
} & (
    | {
          type: 'password';
          password: string;
      }
    | {
          type: 'apiToken';
          apiToken: string;
      }
);

@Injectable({
    providedIn: 'root',
})
export class CreateCredentialService {
    private readonly _credentialsService = inject(CredentialsService);
    private readonly _restaurantsService = inject(RestaurantsService);

    execute(platformKey: AuthorizedPlatformKeys, loginInfo: LoginInfo): Observable<string> {
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        const platformAccess: PlatformAccess = {
            platformKey: platformKey,
            accessType: PlatformAccessType.CREDENTIALS,
            status: PlatformAccessStatus.INVALID_PAGE,
            lastUpdated: new Date(),
            lastVerified: undefined,
            active: true,
            isAlreadyEncryptedPassword: false,
            apiToken: loginInfo.type === 'apiToken' ? loginInfo.apiToken : undefined,
            data: {
                login: loginInfo.login,
                password: loginInfo.type === 'password' ? loginInfo.password : undefined,
            },
        };

        return this._credentialsService.authenticate(restaurantId, platformAccess).pipe(map((res) => res.data.redirect));
    }
}
