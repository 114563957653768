import { z } from 'zod';

import { Locale, PlatformKey } from '@malou-io/package-utils';

import { BaseEmailProps, concernedRestaurantPropsValidator, ctaValidator, reviewPropsValidator } from '../reports';

export const downloadReviewsRestaurantValidator = z.object({
    name: z.string(),
    address: z.string(),
    image: z.string(),
    alt: z.string().optional(),
    reviewsCount: z.object({
        totalReviewsCount: z.number(),
        nonAnsweredReviewsCount: z.number(),
    }),
});
//--------------------------------------------

export const downloadRestaurantReviewsDataValidator = z.object({
    header: downloadReviewsRestaurantValidator,
    cta: ctaValidator,
    reviews: z.array(reviewPropsValidator),
    bordered: z.boolean(),
});

export type DownloadRestaurantReviewsDataProps = z.infer<typeof downloadRestaurantReviewsDataValidator> & BaseEmailProps;

//--------------------------------------------

export const downloadReviewsStatsValidator = z.object({
    stats: z.object({
        reviewCount: z.number(),
        notAnsweredReviewCount: z.number(),
    }),
    restaurantCount: z.number(),
    aiGlobalAnalysisCta: z.object({
        noticeText: z.string().optional(),
        link: z.string(),
    }),
    noReviewsCta: z.object({
        link: z.string(),
    }),
});

export type DownloadReviewsStatsProps = z.infer<typeof downloadReviewsStatsValidator>;

//--------------------------------------------

export const downloadReviewsTemplateValidator = z.object({
    reviewsStats: downloadReviewsStatsValidator,
    restaurantsReviews: z.array(downloadRestaurantReviewsDataValidator),
    concernedRestaurants: z.array(concernedRestaurantPropsValidator),
    period: z.object({
        startDate: z.date(),
        endDate: z.date(),
        timeZone: z.string(),
    }),
});

export type DownloadReviewsTemplateProps = z.infer<typeof downloadReviewsTemplateValidator> & BaseEmailProps;

//--------------------------------------------

export const basicReviewValidator = z.object({
    reviewerProfilePhoto: z.string(),
    reviewPhotos: z.array(z.string()).optional(),
    reviewerName: z.string(),
    rating: z.number(),
    text: z.string().optional(),
    date: z.date(),
    platformKey: z.nativeEnum(PlatformKey),
    locale: z.nativeEnum(Locale),
});
export type BasicReviewProps = z.infer<typeof basicReviewValidator>;

// --------------------------------------------

export const negativeReviewNotifValidator = z.object({
    link: z.string(),
    restaurantName: z.string(),
    platformFullName: z.string(),
    remainingNegativeReviews: z.array(z.object({ restaurantName: z.string(), reviewsCount: z.number() })),
    review: basicReviewValidator,
});

export type NegativeReviewNotifProps = z.infer<typeof negativeReviewNotifValidator> & BaseEmailProps;
