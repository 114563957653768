<div class="flex items-center justify-between rounded-[10px] bg-malou-color-background-dark p-6">
    <div class="flex gap-4">
        <img class="h-8 md:hidden" [src]="'gmb' | platformLogoPathResolver" />
        <div class="flex flex-col">
            <span class="malou-text-14--bold malou-color-text-1"> {{ 'informations.you_have_suggestions' | translate }}</span>
            <span class="malou-text-10--regular malou-color-text-2">
                {{ 'informations.suggestions_details' | translate }}
            </span>
        </div>
    </div>

    <div class="flex gap-x-2">
        <button class="malou-btn-raised--primary btn-xl !h-12.5 !min-w-fit" mat-raised-button (click)="openModalSuggestions()">
            {{ 'informations.see_suggestions' | translate }}
        </button>
    </div>
</div>
