import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformAccessStatus, PlatformKey } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';

@Component({
    selector: 'app-information-update-state-error',
    standalone: true,
    imports: [TranslateModule, CommonModule, MatIconModule, IncludesPipe, PlatformNamePipe],
    templateUrl: './information-update-state-error.component.html',
    styleUrl: './information-update-state-error.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationUpdateStateErrorComponent {
    readonly PlatformAccessStatus = PlatformAccessStatus;
    readonly SvgIcon = SvgIcon;
    readonly PlatformKey = PlatformKey;
    readonly platformAccessStatus = input.required<PlatformAccessStatus>();
    readonly platformKey = input.required<PlatformKey>();
}
