import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { forkJoin, map, Observable } from 'rxjs';

import {
    Currency,
    getCurrencySymbol,
    isNotNil,
    RevenueOption,
    RoiRevenueOptions,
    SimilarRestaurantCategory,
    sortRestaurantsByInternalNameThenName,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { RestaurantsService } from ':core/services/restaurants.service';
import { SimilarRestaurantsService } from ':core/services/similar-restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { RoiSettingsService } from ':modules/roi/roi-settings.service';
import { RoiContext } from ':modules/roi/roi.context';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { IRoiSettings } from ':shared/models/roi-settings.model';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { displayRevenue } from ':shared/services/csv-services/e-reputation/helper-functions';

import { InputDatePickerComponent } from '../input-date-picker/input-date-picker.component';
import { InputNumberComponent } from '../input-number/input-number.component';
import { SelectComponent } from '../select/select.component';

interface RestaurantSettingsForm {
    averageTicket: FormControl<number | null>;
    revenue: FormControl<RevenueOption | null>;
    category: FormControl<SimilarRestaurantCategory | null>;
    openingDate: FormControl<Date | null>;
}

// Temporary component, to be deleted once we have all restaurants data

@Component({
    selector: 'app-restaurant-setup-legacy-modal',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        TranslateModule,
        AsyncPipe,
        InputDatePickerComponent,
        SelectComponent,
        InputNumberComponent,
        LazyLoadImageModule,
        ApplyPurePipe,
        ApplySelfPurePipe,
        ImagePathResolverPipe,
        MalouSpinnerComponent,
    ],
    templateUrl: './restaurant-setup-legacy-modal.component.html',
    styleUrl: './restaurant-setup-legacy-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantSetupLegacyModalComponent implements OnInit {
    private readonly _store = inject(Store);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _translateService = inject(TranslateService);
    private readonly _dialogRef = inject(MatDialogRef<RestaurantSetupLegacyModalComponent>);
    private readonly _roiContext = inject(RoiContext);
    private readonly _roiSettingsService = inject(RoiSettingsService);
    private readonly _similarRestaurantsService = inject(SimilarRestaurantsService);
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _toastService = inject(ToastService);
    private readonly _fb = inject(FormBuilder);

    readonly trackByIdFn = TrackByFunctionFactory.get('id');
    readonly SvgIcon = SvgIcon;
    readonly restaurants$: Observable<Restaurant[]> = this._store
        .select(selectOwnRestaurants)
        .pipe(
            map(
                (restaurants) =>
                    restaurants.filter((restaurant) => !restaurant.isBrandBusiness()).sort(sortRestaurantsByInternalNameThenName) ?? []
            )
        );
    readonly restaurants: Signal<Restaurant[]> = toSignal(this.restaurants$, { initialValue: [] });
    readonly hasMoreThanOneRestaurant: Signal<boolean> = computed(() => this.restaurants().length > 1);
    readonly singleRestaurantId: Signal<string> = computed(() => (this.restaurants().length === 1 ? this.restaurants()[0]._id : ''));

    readonly currencyOptions = Object.values(Currency);
    readonly revenueOptions: RevenueOption[] = RoiRevenueOptions;
    readonly categoriesOptions: SimilarRestaurantCategory[] = Object.values(SimilarRestaurantCategory).sort(
        (a: SimilarRestaurantCategory, b: SimilarRestaurantCategory) =>
            this._enumTranslatePipe
                .transform(a, 'similar_restaurants_categories')
                .localeCompare(this._enumTranslatePipe.transform(b, 'similar_restaurants_categories'))
    );

    readonly isSavingRestaurantSettings = signal(false);

    readonly currencyForm: WritableSignal<FormControl> = signal(
        new FormControl<Currency>(this._roiContext.globalCurrency() ?? this._roiContext.roiSettings()?.currency ?? Currency.EUR, [
            Validators.required,
        ])
    );
    readonly restaurantSettingsControls: Signal<{ [key: string]: FormGroup<RestaurantSettingsForm> }> = computed(() => {
        const controls: { [key: string]: FormGroup<RestaurantSettingsForm> } = {};
        this.restaurants().forEach((restaurant) => {
            const foundRoiSettings = this._roiContext
                .restaurantsRoiSettings()
                .find((restaurantSettings) => restaurantSettings.restaurantId === restaurant._id.toString());
            const foundCategory =
                this._roiContext.restaurantsCategories().find(({ restaurantId }) => restaurantId === restaurant._id)?.category ?? null;
            const foundAssociatedRevenue =
                foundRoiSettings && isNotNil(foundRoiSettings.minRevenue) && isNotNil(foundRoiSettings.maxRevenue)
                    ? { min: foundRoiSettings.minRevenue, max: foundRoiSettings.maxRevenue }
                    : null;
            controls[restaurant._id] = this._fb.group({
                averageTicket: [(foundRoiSettings?.averageTicket ?? null) as number | null, Validators.required],
                revenue: [foundAssociatedRevenue as RevenueOption | null, Validators.required],
                category: [foundCategory as SimilarRestaurantCategory | null, Validators.required],
                openingDate: [restaurant.openingDate as Date | null, Validators.required],
            });
        });
        return controls;
    });

    readonly showDuplicateModal = signal(false);
    readonly currentDuplicatedRestaurantSettings: WritableSignal<
        (Omit<IRoiSettings, 'createdAt' | 'updatedAt'> & { category: SimilarRestaurantCategory | null; openingDate: Date | null }) | null
    > = signal(null);
    readonly isChecked = computed(
        () =>
            (restaurantId: string): boolean =>
                restaurantId === this.currentDuplicatedRestaurantSettings()?.restaurantId ||
                this._restaurantIdsToDuplicateTo().includes(restaurantId)
    );
    readonly areAllRestaurantsChecked = computed(() => {
        const currentRestaurantId = this.currentDuplicatedRestaurantSettings()?.restaurantId;
        if (!currentRestaurantId) {
            return false;
        }
        const checkableRestaurants = this.restaurants().filter((restaurant) => restaurant.id !== currentRestaurantId);

        return checkableRestaurants.every((checkableRestaurant) => this._restaurantIdsToDuplicateTo().includes(checkableRestaurant.id));
    });
    private readonly _restaurantIdsToDuplicateTo: WritableSignal<string[]> = signal([]);
    private readonly _restaurantSettingsControlsBeforeDuplication: WritableSignal<{ [key: string]: FormGroup<RestaurantSettingsForm> }> =
        signal({});

    ngOnInit(): void {
        this._initCloseModalOnClick();
        this._roiContext.getRoiSettingsForRestaurants(this.restaurants().map(({ _id }) => _id)).subscribe();
    }

    currencyDisplayWith(currency: Currency): string {
        return getCurrencySymbol(currency);
    }

    revenueDisplayWith(revenue: RevenueOption): string {
        return displayRevenue(revenue);
    }

    categoryDisplayWith = (category: SimilarRestaurantCategory): string =>
        this._enumTranslatePipe.transform(category, 'similar_restaurants_categories');

    onAverageTicketChange(newAverageTicket: number | null, restaurantId: string = this.singleRestaurantId()): void {
        this.restaurantSettingsControls()[restaurantId].get('averageTicket')?.setValue(newAverageTicket);
    }

    getRestaurantSubtext = (restaurant: Restaurant): string => restaurant.getFullFormattedAddress();

    openDuplicateDropdown(event: Partial<MouseEvent>, restaurantId: string): void {
        this._restaurantSettingsControlsBeforeDuplication.set(cloneDeep(this.restaurantSettingsControls()));
        this.showDuplicateModal.set(true);
        const associatedRestaurantSettingsControl = this.restaurantSettingsControls()[restaurantId];
        this.currentDuplicatedRestaurantSettings.set({
            restaurantId,
            currency: this.currencyForm().value,
            averageTicket: associatedRestaurantSettingsControl.get('averageTicket')?.value ?? 0,
            minRevenue: associatedRestaurantSettingsControl.get('revenue')?.value?.min ?? 0,
            maxRevenue: associatedRestaurantSettingsControl.get('revenue')?.value?.max ?? 0,
            category: associatedRestaurantSettingsControl.get('category')?.value ?? null,
            openingDate: associatedRestaurantSettingsControl.get('openingDate')?.value ?? null,
        });
        this._setDuplicateModalPosition(event);
    }

    toggleDuplicate(checked: boolean, restaurantId: string): void {
        if (checked) {
            this._restaurantIdsToDuplicateTo.update((currentRestaurantIds) => {
                if (!currentRestaurantIds.includes(restaurantId)) {
                    currentRestaurantIds.push(restaurantId);
                }
                return [...currentRestaurantIds];
            });

            this._pasteRestaurantSettings(restaurantId);
        } else {
            this._restaurantIdsToDuplicateTo.update((currentRestaurantIds) => {
                const index = currentRestaurantIds.findIndex((currentRestaurantId) => currentRestaurantId === restaurantId);
                if (index >= 0) {
                    currentRestaurantIds.splice(index, 1);
                }
                return [...currentRestaurantIds];
            });

            this._resetRestaurantSettings(restaurantId);
        }
    }

    toggleAllDuplicate(event: MatCheckboxChange): void {
        const currentRestaurantId = this.currentDuplicatedRestaurantSettings()?.restaurantId;
        if (!currentRestaurantId) {
            return;
        }
        const checkableRestaurants = this.restaurants().filter((restaurant) => restaurant.id !== currentRestaurantId);

        checkableRestaurants.forEach((restaurant) => this.toggleDuplicate(event.checked, restaurant._id));
    }

    close(): void {
        this._dialogRef.close();
    }

    saveManyRestaurantSettings(): void {
        this.isSavingRestaurantSettings.set(true);

        const currency = this.currencyForm().value;
        const restaurantSettingsData: {
            restaurantId: string;
            currency: Currency | null;
            averageTicket: number | null;
            minRevenue: number | null;
            maxRevenue: number | null;
            category: SimilarRestaurantCategory | null;
            openingDate: Date | null;
        }[] = this.restaurants().map((restaurant) => {
            const restaurantId = restaurant._id;
            const formControlForRestaurant = this.restaurantSettingsControls()[restaurantId];
            const restaurantSetup = formControlForRestaurant.value;

            return {
                currency,
                averageTicket: restaurantSetup.averageTicket ?? null,
                minRevenue: restaurantSetup.revenue?.min ?? null,
                maxRevenue: restaurantSetup.revenue?.max ?? null,
                restaurantId,
                category: restaurantSetup.category ?? null,
                openingDate: restaurantSetup.openingDate ?? null,
            };
        });

        forkJoin([
            ...restaurantSettingsData
                .map((restaurantSettings) => {
                    const actions: Observable<{}>[] = [];
                    if (restaurantSettings.openingDate) {
                        actions.push(
                            this._restaurantService.update(restaurantSettings.restaurantId, { openingDate: restaurantSettings.openingDate })
                        );
                    }
                    if (restaurantSettings.category) {
                        actions.push(this._similarRestaurantsService.update(restaurantSettings.restaurantId, restaurantSettings.category));
                    }
                    if (restaurantSettings.averageTicket || restaurantSettings.minRevenue || restaurantSettings.maxRevenue) {
                        actions.push(this._roiSettingsService.upsert(restaurantSettings.restaurantId, restaurantSettings));
                    }
                    return actions;
                })
                .flat(),
        ]).subscribe({
            next: () => {
                this.isSavingRestaurantSettings.set(false);
                this.close();
            },
            error: (err) => {
                console.warn(err);
                this.isSavingRestaurantSettings.set(false);
                this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
            },
        });
    }

    private _pasteRestaurantSettings(restaurantId: string): void {
        const newRestaurantSettingsToPaste = this.currentDuplicatedRestaurantSettings();
        if (newRestaurantSettingsToPaste) {
            this.restaurantSettingsControls()[restaurantId].get('averageTicket')?.setValue(newRestaurantSettingsToPaste.averageTicket);
            this.restaurantSettingsControls()[restaurantId].get('category')?.setValue(newRestaurantSettingsToPaste.category);
            this.restaurantSettingsControls()[restaurantId].get('openingDate')?.setValue(newRestaurantSettingsToPaste.openingDate);
            this.restaurantSettingsControls()
                [restaurantId].get('revenue')
                ?.setValue({ min: newRestaurantSettingsToPaste.minRevenue, max: newRestaurantSettingsToPaste.maxRevenue });
        }
    }

    private _resetRestaurantSettings(restaurantId: string): void {
        const restaurantSettingsBeforeDuplication = this._restaurantSettingsControlsBeforeDuplication()[restaurantId];
        this.restaurantSettingsControls()
            [restaurantId].get('averageTicket')
            ?.setValue(restaurantSettingsBeforeDuplication.get('averageTicket')?.value ?? null);
        this.restaurantSettingsControls()
            [restaurantId].get('category')
            ?.setValue(restaurantSettingsBeforeDuplication.get('category')?.value ?? null);
        this.restaurantSettingsControls()
            [restaurantId].get('openingDate')
            ?.setValue(restaurantSettingsBeforeDuplication.get('openingDate')?.value ?? null);
        this.restaurantSettingsControls()
            [restaurantId].get('revenue')
            ?.setValue(restaurantSettingsBeforeDuplication.get('revenue')?.value ?? null);
    }

    private _setDuplicateModalPosition(event: Partial<MouseEvent>): void {
        setTimeout(() => {
            const duplicateModal = <HTMLElement>document.querySelector('#duplicateModalTemplate');
            const onTop = (event.clientY || 0) > 500;
            const style = onTop ? 'top: -295px;' : 'top: 36px;';
            duplicateModal.style.cssText = style;
        }, 50);
    }

    private _initCloseModalOnClick(): void {
        document?.addEventListener('click', (event) => this._onClick(event));
    }

    private _onClick(event: Event): void {
        const target = <HTMLElement>event.target;
        if (!target.closest('#duplicateModalTemplate') && !target.closest('#duplicateModalButton')) {
            this._closeDuplicateModal();
        }
    }

    private _closeDuplicateModal(): void {
        this.showDuplicateModal.set(false);
        this._restaurantSettingsControlsBeforeDuplication.set({});
        this._restaurantIdsToDuplicateTo.set([]);
        this.currentDuplicatedRestaurantSettings.set(null);
    }
}
