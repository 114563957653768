@let isFeaturePlatformsUpdatesV2Enabled = isFeaturePlatformsUpdatesV2Enabled$ | async;

<div class="malou-card group !m-0 h-full">
    <div class="malou-card__header">
        <span class="malou-text-18--bold">
            {{ 'information.information.info' | translate }}
        </span>

        @if (isFeaturePlatformsUpdatesV2Enabled) {
            <div class="invisible flex gap-4 group-hover:visible md:visible">
                <button class="malou-btn-icon--secondary btn-xl" data-cy="editInfosBtn" mat-icon-button (click)="openInfoDialog()">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                </button>
            </div>
        } @else {
            <button class="malou-btn-icon--secondary btn-xl" data-cy="editInfosBtn" mat-icon-button (click)="openInfoDialog()">
                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
            </button>
        }
    </div>
    <div class="malou-card__body">
        <ng-container [ngTemplateOutlet]="coverTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="logoTemplate"></ng-container>

        <div class="malou-card__body">
            <app-restaurant-overview [restaurant]="restaurant"></app-restaurant-overview>
        </div>
    </div>
</div>

<ng-template #coverTemplate>
    <div class="relative z-0">
        <div>
            <img
                class="malou-border-primary h-[97px] w-full rounded-xl object-cover"
                id="restaurant-img-cover"
                alt="cover image"
                [src]="restaurant.cover ? restaurant.cover.urls.smallCover : ('default_cover' | imagePathResolver)" />
        </div>
    </div>
</ng-template>

<ng-template #logoTemplate>
    <div class="relative z-[4] flex justify-center">
        <div>
            <img
                class="malou-logo-dimensions logo-border malou-box-shadow rounded-full"
                id="restaurant-img-logo"
                alt="logo image"
                [src]="restaurant.logo?.urls?.original || ('default_logo' | imagePathResolver)" />
        </div>
    </div>
</ng-template>
