import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    standalone: true,
    imports: [NavigationHeaderComponent, RouterOutlet],
})
export class SettingsComponent {
    private readonly _translateService = inject(TranslateService);

    readonly PLATFORMS_TAB: NavigationTab = {
        label: this._translateService.instant('app_sidebar.settings.platforms'),
        link: './platforms',
    };
    readonly ROLES_TAB: NavigationTab = {
        label: this._translateService.instant('app_sidebar.settings.users'),
        link: './roles',
    };
    readonly AUTOMATIONS_TAB: NavigationTab = {
        label: this._translateService.instant('app_sidebar.settings.automations'),
        link: './automations',
    };

    readonly TABS = [this.PLATFORMS_TAB, this.AUTOMATIONS_TAB, this.ROLES_TAB];
}
