import { z } from 'zod';

import { Day } from '@malou-io/package-utils';

// !!! WARNING !!!
// if you make change to these validators, you must also make change to the interfaces
// Because on strict false, zod make all properties optional
// So when strict true, remove interfaces and infer type with zod

export interface OtherHourDto {
    hoursTypeId: string;
    periods: RegularHourDto[];
}

export interface RegularHourDto {
    openDay: Day;
    closeDay: Day;
    isClosed: boolean;
    isPrimaryPeriod: boolean;
    openTime?: string | null;
    closeTime?: string | null;
}

export interface SpecialHourDto {
    startDate: DayYearMonthDto;
    openTime?: string | null;
    endDate?: DayYearMonthDto;
    closeTime?: string | null;
    isClosed: boolean;
}

export interface DayYearMonthDto {
    day: number;
    year: number;
    month: number;
}

export const regularHourValidator = z.object({
    openDay: z.nativeEnum(Day),
    closeDay: z.nativeEnum(Day),
    isClosed: z.boolean(),
    isPrimaryPeriod: z.boolean(),
    openTime: z.string().nullish(),
    closeTime: z.string().nullish(),
});

export const otherHourValidator = z.object({
    hoursTypeId: z.string(),
    periods: z.array(regularHourValidator),
});

export const dayYearMonthValidator = z.object({
    day: z.number(),
    year: z.number(),
    month: z.number(),
});

export const specialHourValidator = z.object({
    startDate: dayYearMonthValidator,
    endDate: dayYearMonthValidator,
    openTime: z.string().nullish(),
    closeTime: z.string().nullish(),
    isClosed: z.boolean(),
});
