<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div class="flex gap-x-2">
            <span>{{ (data.upsertKind === UpsertKind.INSERT ? 'common.edit' : 'common.add') | translate }}</span>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <form class="flex flex-col gap-y-6" [formGroup]="formGroup">
            <div class="flex gap-x-4">
                <div class="grow">
                    <app-input-text
                        formControlName="name"
                        [testId]="'admin-users-firstname-input'"
                        [title]="'admin.users.firstname' | translate"
                        [placeholder]="'admin.users.firstname' | translate"
                        [required]="true">
                    </app-input-text>
                </div>
                <div class="grow">
                    <app-input-text
                        formControlName="lastname"
                        [testId]="'admin-users-input-lastname'"
                        [title]="'admin.users.name' | translate"
                        [placeholder]="'admin.users.name' | translate"
                        [required]="true">
                    </app-input-text>
                </div>
            </div>

            <app-input-text
                formControlName="email"
                [testId]="'admin-users-email-input'"
                [title]="'admin.users.email' | translate"
                [placeholder]="'admin.users.email' | translate"
                [required]="true">
            </app-input-text>

            @if (data.upsertKind === UpsertKind.INSERT) {
                <app-input-text
                    formControlName="password"
                    [testId]="'admin-users-password-input'"
                    [title]="'admin.users.password' | translate"
                    [placeholder]="'admin.users.password' | translate"
                    [required]="true">
                </app-input-text>
            }

            <div class="mb-4 rounded border-l-4 border-red-500 bg-red-100 p-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <mat-icon class="bg-malou-color-state-error" [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-malou-color-state-error">Important</h3>
                        <div class="mt-2 text-sm text-malou-color-state-error">
                            <p>Please handle the role selection with caution.</p>
                            <p><b> Administrator role should only be allowed to Malou users. </b></p>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <app-select
                        formControlName="role"
                        [title]="'admin.users.role' | translate"
                        [placeholder]="'admin.users.role' | translate"
                        [values]="displayedRoles"
                        [displayWith]="roleDisplayWith"
                        [required]="true">
                    </app-select>
                </div>
            </div>

            <app-select-chip-list
                formControlName="organizations"
                [testId]="'admin-users-organization-input'"
                [title]="'admin.users.organizations' | translate"
                [placeholder]="'admin.users.organizations' | translate"
                [values]="allOrganizations"
                [displayWith]="organizationDisplayWith"
                [displayedOptionCount]="20"
                [checkboxOption]="true">
            </app-select-chip-list>

            <app-select
                formControlName="caslRole"
                [title]="'admin.users.casl_role' | translate"
                [placeholder]="'admin.users.casl_role' | translate"
                [values]="OrganizationRole | values"
                [displayWith]="caslRoleDisplayWith"
                [required]="true">
            </app-select>
        </form>
    </div>

    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-10 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button
            class="malou-btn-raised--primary !h-10 md:grow"
            data-testid="admin-users-create-user-btn"
            mat-raised-button
            [disabled]="formGroup.invalid"
            (click)="save()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</div>
