<div class="malou-dialog">
    <div class="malou-dialog__header items-baseline">
        <div class="flex flex-col">
            <span class="malou-text-18--bold">
                {{ 'roi.restaurant_setup_modal.title' | translate }}
            </span>
            <span class="malou-text-12--regular malou-color-text-2 mt-3 italic">{{
                'roi.restaurant_setup_modal.subtitle' | translate
            }}</span>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body mb-6.5 flex flex-col justify-between !overflow-hidden sm:!overflow-y-scroll">
        <div class="m-auto flex w-full flex-col sm:m-0 sm:justify-between" [formGroup]="restaurantSetupForm">
            <div class="mb-5 ml-4 flex items-center">
                <img
                    class="malou-avatar--small"
                    [lazyLoad]="selectedRestaurant()?.logo?.getMediaUrl('small') ?? ''"
                    [defaultImage]="'default-picture-grey' | imagePathResolver" />
                <div class="ml-3 flex min-w-0 grow flex-col leading-4">
                    <span class="malou-text-14--semibold text-malou-color-text-1">{{ selectedRestaurant()?.internalName }}</span>
                    <span class="malou-text-10--regular truncate font-normal italic text-malou-color-text-2">{{
                        getRestaurantSubtext | applyPure: selectedRestaurant()
                    }}</span>
                </div>
            </div>
            <app-select
                class="mb-4 w-full"
                formControlName="category"
                [title]="'roi.restaurant_setup_modal.restaurant_category' | translate"
                [values]="categoriesOptions"
                [inputReadOnly]="true"
                [displayWith]="categoryDisplayWith"></app-select>
            <app-input-date-picker
                class="mb-4"
                formControlName="openingDate"
                [title]="'roi.restaurant_setup_modal.opening_date' | translate"
                [placeholder]="'roi.restaurant_setup_modal.opening_date' | translate">
            </app-input-date-picker>
            <div class="mb-4 mt-5 flex justify-between sm:flex-col">
                <app-select
                    class="w-24 sm:w-full"
                    formControlName="currency"
                    [title]="'roi.restaurant_setup_modal.currency' | translate"
                    [values]="currencyOptions"
                    [displayWith]="currencyDisplayWith">
                </app-select>
                <app-input-number
                    class="ml-5 w-full sm:ml-0 sm:w-full"
                    [value]="averageTicket ?? null"
                    [title]="'roi.restaurant_setup_modal.average_ticket' | translate"
                    [placeholder]="'roi.restaurant_setup_modal.average_ticket_placeholder' | translate"
                    [min]="1"
                    [max]="1000"
                    (inputNumberChange)="onAverageTicketChange($event)">
                </app-input-number>
            </div>
            <app-select
                class="ml-24 mt-3 pl-5 sm:ml-0 sm:w-full sm:pl-0"
                formControlName="revenue"
                [title]="'roi.restaurant_setup_modal.average_revenue_per_month' | translate"
                [placeholder]="'roi.restaurant_setup_modal.average_revenue_per_month_placeholder' | translate"
                [values]="revenueOptions"
                [inputReadOnly]="true"
                [displayWith]="revenueDisplayWith"></app-select>
        </div>
        <div class="!mt-7 flex items-center justify-end sm:flex-col">
            <button
                class="malou-btn-raised--secondary mr-4 !h-11 items-center justify-center sm:mb-5 sm:mr-0 sm:w-full"
                mat-raised-button
                (click)="close()">
                {{ 'common.later' | translate }}
            </button>
            <button
                class="malou-btn-raised--primary flex !h-11 items-center justify-center sm:w-full"
                mat-raised-button
                [disabled]="restaurantSetupForm.invalid"
                [ngClass]="{ 'cursor-not-allowed': restaurantSetupForm.invalid }"
                (click)="saveRoiSettings()">
                {{ 'common.save' | translate }}
            </button>
        </div>
    </div>
</div>
