<div class="flex w-full items-center gap-x-4 pb-5">
    <div class="malou-text-18--bold malou-color-text-1 w-[260px] lg:hidden">
        {{ 'templates.message.actions.template' | translate }}
        <span class="font-light italic">({{ templateCount }})</span>
    </div>
    <div class="grow">
        <app-search [placeholder]="'common.search' | translate" (searchChange)="onSearch($event)"></app-search>
    </div>
    <ng-container *ngTemplateOutlet="duplicateButton"></ng-container>
    <ng-container *ngTemplateOutlet="deleteButton"></ng-container>

    <button class="malou-btn-raised--primary btn-xl !h-12.5 md:!hidden" mat-raised-button (click)="clickOnInput()">
        {{ 'templates.message.actions.create_template' | translate }}
    </button>
    <button class="malou-btn-icon--primary btn-xl min-md:!hidden" mat-icon-button (click)="clickOnInput()">
        <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
    </button>
</div>
@if (screenSizeService.isPhoneScreen) {
    <div [class.show-advanced-filters]="showAdvancedFilters">
        <button class="malou-btn-flat !px-0" mat-button (click)="toggleAdvancedFilters()">
            {{ 'common.advanced_options' | translate }}
            <mat-icon class="chevron-icon !h-[16px] !w-[16px]" iconPositionEnd [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
        </button>
        <div class="search mt-2 flex h-0 grow flex-col gap-y-1 opacity-0">
            <app-sort-by-filters
                [sortOptions]="SORT_OPTIONS"
                [sortBy]="(sortBy$ | async) ?? 'name'"
                [sortOrder]="(sortOrder$ | async) ?? 1"
                (changeSortBy)="onSortByChange($event)"
                (toggleSortOrder)="onSortOrderChange($event)">
            </app-sort-by-filters>
        </div>
    </div>
}

<ng-template #duplicateButton>
    <div
        [matTooltip]="
            !hasSelectedTemplates ? ('templates.message.actions.must_select_template' | translate) : ('common.duplicate' | translate)
        ">
        <button
            class="malou-btn-icon--secondary btn-xl"
            mat-icon-button
            [matMenuTriggerFor]="duplicateMenu"
            [disabled]="!hasSelectedTemplates">
            <mat-icon [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #deleteButton>
    <div
        [matTooltip]="
            !hasSelectedTemplates ? ('templates.message.actions.must_select_template' | translate) : ('common.delete' | translate)
        ">
        <button class="malou-btn-icon--secondary btn-xl" mat-icon-button [disabled]="!hasSelectedTemplates" (click)="delete()">
            <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
        </button>
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow" #duplicateMenu="matMenu">
    <button mat-menu-item (click)="duplicate(DuplicationDestination.HERE)">
        <span class="malou-text-14--regular">
            {{ 'templates.message.actions.duplicate_here' | translate }}
        </span>
    </button>
    <button mat-menu-item (click)="duplicate(DuplicationDestination.OUT)">
        <span class="malou-text-14--regular">
            {{ 'common.to_other_venues' | translate }}
        </span>
    </button>
</mat-menu>
