import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { omit } from 'lodash';
import { EMPTY, forkJoin, interval, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { GetRestaurantCurrentStateResponseDto } from '@malou-io/package-dto';
import { ApiResult, PlatformDataFetchedStatus } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ReviewStrategyType } from ':modules/reviews/reviews/reviews.strategy';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { SynchronizationStatus } from ':modules/reviews/store/reviews.interface';
import {
    selectRestaurantsFilter,
    selectReviews,
    selectReviewsFilters,
    selectReviewsPaginationWithFilters,
} from ':modules/reviews/store/reviews.selectors';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { FetchedState, Pagination, Restaurant, Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Injectable()
@AutoUnsubscribeOnDestroy()
export class ReviewsEffect implements KillSubscriptions {
    readonly killSubscriptions$: Subject<void> = new Subject();

    statusSubscription: Subscription;
    fromAction = false;
    strategyType: ReviewStrategyType;

    readonly synchronizeReviews$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ReviewsActions.synchronizeReviews),
                tap(() => (this.fromAction = true)),
                concatLatestFrom(() => [this._store.select(selectRestaurantsFilter)]),
                tap(([action, restaurants]) => {
                    const forceSynchronize = action.forceSynchronize;

                    if (this.statusSubscription) {
                        this.killSubscriptions$.next();
                    }

                    // only update aggregated reviews when force synchronize
                    // is set to true because it takes too long
                    const multipleRestaurants = restaurants.length > 1;

                    const filteredRestaurants = restaurants.filter(
                        (restaurant) => (!multipleRestaurants && !restaurant?.reviewsLastUpdate) || forceSynchronize
                    );

                    if (filteredRestaurants && filteredRestaurants.length > 0) {
                        this._startUpdateReviews(filteredRestaurants);
                    }
                    this.fromAction = false;
                })
            ),
        { dispatch: false }
    );

    readonly cancelCurrentReviewsSynchronization$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ReviewsActions.cancelCurrentReviewsSynchronization),
            mergeMap(() =>
                this._restaurantsService.restaurantSelected$.pipe(
                    filter(Boolean),
                    tap(() => {
                        if (this.statusSubscription) {
                            this.killSubscriptions$.next();
                        }
                    })
                )
            )
        )
    );

    readonly getOrFetchReviews$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ReviewsActions.getOrFetchReviews),
            withLatestFrom(this._store.select(selectReviews)),
            map(([action, reviews]) => {
                if (this._shouldFetchReviews(reviews, action)) {
                    return ReviewsActions.fetchReviews({ strategyType: action.strategyType });
                }
                return ReviewsActions.setReviews({ reviews, strategyType: action.strategyType });
            })
        )
    );

    readonly addTranslationToReview$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ReviewsActions.addTranslationToReview),
            withLatestFrom(this._store.select(selectReviews)),
            map(([action, reviews]) => {
                const review = reviews.find((r) => r._id === action.reviewId);
                if (review) {
                    const translations = review.addTranslation(action.text, action.language, action.source);
                    const newReview = review.isPrivate()
                        ? new PrivateReview({ ...review, translations })
                        : new Review({ ...review, translations });

                    return ReviewsActions.setReviews({
                        reviews: reviews.map((r) => (r._id === action.reviewId ? newReview : r)),
                        strategyType: ReviewStrategyType.REPLACE,
                    });
                }
                return ReviewsActions.setReviews({ reviews, strategyType: ReviewStrategyType.REPLACE });
            })
        )
    );

    readonly fetchReviewCount$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ReviewsActions.fetchEstimatedReviewCount),
            withLatestFrom(this._store.select(selectReviewsFilters), this._store.select(selectRestaurantsFilter)),
            switchMap(([_, filters, restaurants]) => {
                const restaurantIds = restaurants.map((restaurant) => restaurant._id);

                return restaurantIds.length > 0
                    ? this._reviewsService.getReviewCount(
                          restaurantIds,
                          omit(filters, ['restaurants', 'period', 'aggregatedViewRestaurants'])
                      )
                    : of(0);
            }),
            map((count) => ReviewsActions.setEstimatedReviewCount({ count }))
        )
    );

    readonly fetchReviews$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ReviewsActions.fetchReviews),
            tap((action) => (this.strategyType = action.strategyType)),
            withLatestFrom(this._store.select(selectReviewsPaginationWithFilters), this._store.select(selectRestaurantsFilter)),
            switchMap(([_, { pagination, filters }, restaurants]) =>
                this._reviewsService.getSelectedRestaurantsReviewsPaginated(
                    restaurants.map((r) => r._id),
                    pagination,
                    omit(filters, ['restaurants', 'period', 'aggregatedViewRestaurants'])
                )
            ),
            tap((res) => {
                this._store.dispatch({
                    type: ReviewsActions.setHasLoadedAllReviews.type,
                    hasLoadedAllReviews: res.reviews.length < res.pagination.pageSize,
                });
            }),
            map((res) => res.reviews),
            map((reviews) => ReviewsActions.setReviews({ reviews, strategyType: this.strategyType }))
        )
    );

    readonly fetchUnansweredReviewCount$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ReviewsActions.fetchUnansweredReviewCount),
            withLatestFrom(this._store.select(selectReviewsPaginationWithFilters), this._store.select(selectRestaurantsFilter)),
            switchMap(([action, { filters: reviewsFilters }, restaurants]) => {
                const actionFilters = action.filters;
                const filters = { ...reviewsFilters, ...actionFilters, answered: false };
                const restaurantIds = restaurants.map((restaurant) => restaurant._id);

                const DEFAULT_RESULT: ApiResult<{ count: number }> = { data: { count: 0 } };

                return restaurantIds.length > 0
                    ? this._reviewsService.getRestaurantsUnansweredReviewCount(restaurantIds, omit(filters, ['restaurants', 'period']))
                    : of(DEFAULT_RESULT);
            }),
            map((res) => res.data.count),
            map((count) => ReviewsActions.setUnansweredReviewCount({ count }))
        )
    );

    readonly getPageAndFetchReviews$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ReviewsActions.getPageAndFetchReviews),
            withLatestFrom(
                this._store.select(selectReviewsPaginationWithFilters),
                this._store.select(selectRestaurantsFilter).pipe(filter((restaurants) => !!restaurants?.length))
            ),
            switchMap(([action, { filters, pagination }, restaurants]) => {
                const restaurantIds = restaurants.map((restaurant) => restaurant._id);
                return this._reviewsService
                    .getPageNumberFromReviewId(action.reviewId, restaurantIds, omit(filters, ['restaurants', 'period']))
                    .pipe(
                        map((res) => ({ pageNumber: res.data, strategyType: action.strategyType, previousPagination: pagination })),
                        map(({ pageNumber, previousPagination, strategyType }) => {
                            const newPagination = { ...previousPagination, pageNumber };
                            const paginationToGetPreviousAndAfterPage = this._computePaginationToGetPreviousAndAfterPage(pageNumber);
                            this._store.dispatch(ReviewsActions.setPagination({ pagination: paginationToGetPreviousAndAfterPage }));
                            this._store.dispatch(ReviewsActions.fetchReviews({ strategyType }));
                            return ReviewsActions.setPagination({ pagination: newPagination });
                        }),
                        catchError((err) => {
                            console.warn('err :>>', err);
                            const errorMessage =
                                err.error?.message && err.error?.metadata?.reviewId
                                    ? `${err.error.message}: ${err.error.metadata.reviewId}`
                                    : err.message;
                            this._store.dispatch(ReviewsActions.couldNotGetPageNumberFromReviewId({ errorMessage }));
                            return EMPTY;
                        })
                    );
            })
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _reviewsService: ReviewsService
    ) {}

    private _startUpdateReviews(restaurants: Restaurant[]): void {
        this._store.dispatch({ type: ReviewsActions.setSynchronizationStatus.type, synchronizationStatus: SynchronizationStatus.LOADING });

        const restaurantIds = restaurants.map((restaurant) => restaurant._id);
        const sub = this._reviewsService.synchronizeRestaurantReviews(restaurantIds).subscribe({
            next: () => {
                this._startStatusWatcher(restaurantIds);
                sub.unsubscribe();
            },
            error: () => {
                this._store.dispatch({
                    type: ReviewsActions.setSynchronizationStatus.type,
                    synchronizationStatus: SynchronizationStatus.ERROR,
                });
                sub.unsubscribe();
            },
        });
    }

    private _startStatusWatcher(restaurantIds: string[]): void {
        this.statusSubscription = interval(1000)
            .pipe(
                switchMap(() => {
                    const observables = restaurantIds.map((restaurantId) =>
                        this._restaurantsService.getRestaurantCurrentState(restaurantId).pipe(map((res) => res.data))
                    );
                    return forkJoin(observables);
                }),
                map((restaurantsWithCurrentState) => {
                    const fetchedStateRecordsInit: Record<string, FetchedState<PlatformDataFetchedStatus>> = {};
                    return restaurantsWithCurrentState.reduce(
                        (acc, currentRestaurant) => this._updateFetchedStateRecord(acc, currentRestaurant),
                        fetchedStateRecordsInit
                    );
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (fetchedStateRecord) => {
                    this._store.dispatch({ type: ReviewsActions.setFetchStates.type, fetchStates: fetchedStateRecord });
                    if (this._hasFinished(fetchedStateRecord)) {
                        this.killSubscriptions$.next();
                        this._store.dispatch({
                            type: ReviewsActions.setSynchronizationStatus.type,
                            synchronizationStatus: SynchronizationStatus.LOADED,
                        });
                        this._store.dispatch(ReviewsActions.fetchReviews({ strategyType: ReviewStrategyType.REPLACE }));
                    }
                },
            });
    }

    private _hasFinished(fetchedStateRecord: Record<string, FetchedState<PlatformDataFetchedStatus>>): boolean {
        if (!fetchedStateRecord) {
            return true;
        }
        return Object.values(fetchedStateRecord)
            .map((sts) => sts.status)
            .every((status) => ![PlatformDataFetchedStatus.ASYNC, PlatformDataFetchedStatus.PENDING].includes(status));
    }

    private _shouldFetchReviews(
        reviews: (Review | PrivateReview)[],
        action: { strategyType: ReviewStrategyType; reviewId: string }
    ): boolean {
        if (action.strategyType === ReviewStrategyType.REPLACE) {
            return true;
        }
        const reviewIndex = reviews.findIndex((review) => review._id === action.reviewId);
        if (action.strategyType === ReviewStrategyType.CONCAT) {
            return reviewIndex === reviews.length - 1;
        }
        if (action.strategyType === ReviewStrategyType.CONCAT_BEFORE) {
            return reviewIndex === 0;
        }
        return false;
    }

    private _computePaginationToGetPreviousAndAfterPage(pageNumber: number): Pagination {
        const pageSize = pageNumber === 0 ? 40 : 60;
        const skipPage = pageNumber === 0 ? 0 : pageNumber - 1;
        const skip = skipPage * 20;
        return {
            pageNumber: 0,
            pageSize,
            total: pageSize + skip,
            skip,
        };
    }

    private _updateFetchedStateRecord(
        fetchedStateRecord: Record<string, FetchedState<PlatformDataFetchedStatus>>,
        restaurant: GetRestaurantCurrentStateResponseDto
    ): Record<string, FetchedState<PlatformDataFetchedStatus>> {
        const restaurantFetchedStateRecord = restaurant?.currentState?.reviews?.fetched;

        if (restaurantFetchedStateRecord) {
            Object.keys(restaurantFetchedStateRecord).forEach((platform) => {
                const platformFetchedState = restaurantFetchedStateRecord[platform];
                if (
                    !fetchedStateRecord[platform] ||
                    [PlatformDataFetchedStatus.ASYNC, PlatformDataFetchedStatus.PENDING].includes(platformFetchedState.status)
                ) {
                    fetchedStateRecord[platform] = platformFetchedState;
                }
            });
        }

        return fetchedStateRecord;
    }
}
