import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { BusinessCategory, isNotNil, Role } from '@malou-io/package-utils';

import { selectUserInfos } from ':modules/user/store/user.selectors';

@Injectable()
export class RoiRestaurantGuard {
    private readonly _store = inject(Store);
    private readonly _router = inject(Router);

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const currentRouteRestaurantId = route?.params?.restaurant_id;
        if (!currentRouteRestaurantId) {
            this._router.navigate(['/restaurants']);
            return of(false);
        }
        return this._store.select(selectUserInfos).pipe(
            tap(() => console.log('[LOG-TO-DELETE][RoiGuard] selectUserInfos triggered in roi Guard')),
            filter(isNotNil),
            map((user) => {
                console.log('[LOG-TO-DELETE][RoiGuard] Found user role and restaurants', {
                    role: user?.role,
                    restaurants: user?.restaurants,
                });
                const associatedUserRestaurant = user?.restaurants.find(
                    (userRestaurant) => userRestaurant.restaurant?._id === currentRouteRestaurantId
                );
                console.log('[LOG-TO-DELETE][RoiGuard] associatedUserRestaurant', {
                    associatedUserRestaurant,
                });
                if (user?.role === Role.ADMIN || associatedUserRestaurant?.restaurant?.roiActivated) {
                    return true;
                } else {
                    const redirectTab = associatedUserRestaurant?.type === BusinessCategory.BRAND ? 'social-networks' : 'seo';
                    console.log('[LOG-TO-DELETE][RoiGuard] Conditions not valid, redirecting to ', redirectTab);
                    this._router.navigate(['restaurants', currentRouteRestaurantId, 'statistics', redirectTab]);
                    return false;
                }
            })
        );
    }
}

@Injectable()
export class RoiAggregatedRestaurantsGuard {
    private readonly _store = inject(Store);
    private readonly _router = inject(Router);

    canActivate(): Observable<boolean> {
        return this._store.select(selectUserInfos).pipe(
            tap(() => console.log('[LOG-TO-DELETE][RoiGuardAggregated] selectUserInfos triggered in roi Guard')),
            filter(isNotNil),
            map((user) => {
                console.log('[RoiGuardAggregated] Found user role and restaurants', {
                    role: user?.role,
                    restaurants: user?.restaurants,
                });
                const roiActivatedRestaurantsLength =
                    user?.restaurants.filter((userRestaurant) => userRestaurant.restaurant?.roiActivated)?.length ?? 0;

                console.log('[LOG-TO-DELETE][RoiGuardAggregated] roiActivatedRestaurantsLength', {
                    roiActivatedRestaurantsLength,
                });
                if (user?.role === Role.ADMIN || roiActivatedRestaurantsLength >= 2) {
                    return true;
                } else {
                    console.log('[LOG-TO-DELETE][RoiGuardAggregated] Conditions not valid, redirecting to seo');
                    this._router.navigate(['groups', 'statistics', 'seo']);
                    return false;
                }
            })
        );
    }
}
