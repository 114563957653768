import { NgTemplateOutlet } from '@angular/common';
import {
    Component,
    ContentChild,
    DestroyRef,
    EventEmitter,
    forwardRef,
    inject,
    input,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { isEqual } from 'lodash';
import { distinctUntilChanged, startWith } from 'rxjs';

import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-simple-select',
    templateUrl: 'simple-select.component.html',
    styleUrls: ['./simple-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SimpleSelectComponent),
        },
    ],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatIconModule,
        ApplyPurePipe,
    ],
})
export class SimpleSelectComponent extends ControlValueAccessorConnectorComponent implements OnInit {
    readonly testId = input<string>();

    // ------------ CORE ------------//

    /** Title */
    @Input()
    title?: string;

    /** Subtitle */
    @Input()
    subtitle?: string;

    /** Placeholder */
    @Input()
    placeholder = '';

    /**
     * default value
     *
     * @optional
     */
    @Input()
    defaultValue?: any;

    /** If true, will display an asterisk after the title */
    @Input()
    required = false;

    @Input()
    disabled = false;

    /** Error message, will display a colored border and the error message below the input */
    @Input()
    errorMessage?: string;

    // ------------ Options ------------//

    /**
     * Use this template to customize each options that will be displayed in the select input.
     * Takes 1 parameter:
     *      - option: the value (an element of 'values' array)
     */
    @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;

    // ------------ Event ------------//

    /** On change event */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    @Output()
    simpleSelectChange: EventEmitter<unknown> = new EventEmitter();

    private readonly _destroyRef = inject(DestroyRef);

    @ViewChild('selectTrigger') select: MatSelect | undefined;

    /** Values */
    @Input()
    values: any[] = [];

    @ContentChild('selectedValueTemplate') selectedValueTemplate: TemplateRef<any>;

    /** Map each value from the 'values' array to a string to display (autocomplete works with these strings) */
    @Input()
    displayWith?: (option: any) => string = (option: any) => option;

    onArrowClick(): void {
        if (this.select?.panelOpen) {
            this.select.close();
        } else {
            this.select?.open();
        }
    }

    ngOnInit(): void {
        if (this.defaultValue) {
            this.control.setValue(this.defaultValue);
        }

        this.control.valueChanges
            .pipe(
                startWith(this.control.value),
                distinctUntilChanged((a, b) => isEqual(a, b)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((value) => {
                this.simpleSelectChange.emit(value);
            });
    }
}
