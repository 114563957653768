import { Injectable, signal } from '@angular/core';

import { Notification } from '../models/notification.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationCenterContext {
    readonly isOpen = signal(true);

    readonly notifications = signal<Notification[]>([]);

    open(): void {
        this.isOpen.set(true);
    }

    close(): void {
        this.isOpen.set(false);
    }
}
