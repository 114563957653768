<div class="mb-5 flex w-full items-center justify-between gap-x-2">
    <div class="grow">
        <app-search
            id="conversation-search"
            [placeholder]="'common.search' | translate"
            (searchChange)="searchValueChange($event)"></app-search>
    </div>
    @if (!screenSizeService.isPhoneScreen && isGoogleMessagingFeatureEnabled()) {
        <button
            class="malou-btn-raised--secondary--alt btn-xl !h-12.5 !min-w-max"
            mat-raised-button
            [disabled]="!currentGoogleAgent?.name"
            (click)="openSettingsModal()">
            {{ 'messages.conversations.settings' | translate }}
        </button>
    }

    @if (screenSizeService.isPhoneScreen && isGoogleMessagingFeatureEnabled()) {
        <button
            class="malou-btn-icon--secondary btn-xl"
            mat-icon-button
            matTooltip="{{ 'messages.conversations.settings' | translate }}"
            [disabled]="!currentGoogleAgent?.name"
            (click)="openSettingsModal()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
        </button>
    }

    <button
        class="malou-btn-icon--secondary btn-xl"
        mat-icon-button
        matTooltip="{{ 'messages.filters.tooltip' | translate }}"
        [matMenuTriggerFor]="filterMenu"
        [disabled]="areAllSocialPlatformsDisconnected">
        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
    </button>
    <mat-menu
        class="malou-mat-menu malou-box-shadow !min-w-fit translate-x-[-35%] transform !rounded-[10px] md:w-[100vw] md:!max-w-none md:translate-x-0"
        #filterMenu="matMenu">
        <div class="w-80 p-6 md:w-full" (click)="$event.stopPropagation()">
            <div class="flex w-full justify-between">
                <div class="malou-text-18--bold text-malou-color-text-1">
                    {{ 'messages.filters.tooltip' | translate }}
                </div>

                <button class="malou-btn-flat" mat-button (click)="clearFilters()">
                    {{ 'common.reset' | translate }}
                </button>
            </div>
            <div class="malou-text-10--medium text-malou-color-text-2">
                {{ 'messages.filters.platforms' | translate }}
            </div>
            <div class="mt-4 flex gap-x-4">
                @for (platform of filteredAvailablePlatforms$ | async; track trackByKeyFn($index, platform)) {
                    <button matRipple [disabled]="isPlatformDisabled | applyPure: platform" (click)="changeSelectedPlatforms(platform)">
                        <img
                            class="h-[29px] w-[29px] rounded-full"
                            [class.opacity-50]="!platform.checked"
                            [lazyLoad]="platform.key | platformLogoPathResolver" />
                    </button>
                }
            </div>

            <div class="malou-text-10--medium mt-5 text-malou-color-text-2">
                {{ 'messages.filters.status' | translate }}
            </div>

            <div class="mt-4 flex gap-x-5">
                <button
                    class="malou-btn-raised--primary btn-xl !h-12.5"
                    mat-raised-button
                    [class.opacity-50]="!(conversationStatusFilters | includes: ConversationStatus.READ)"
                    (click)="changeConversationsStatus(ConversationStatus.READ)">
                    {{ 'messages.filters.read' | translate }}
                </button>
                <button
                    class="malou-btn-raised--primary btn-xl !h-12.5"
                    mat-raised-button
                    [class.opacity-50]="!(conversationStatusFilters | includes: ConversationStatus.UNREAD)"
                    (click)="changeConversationsStatus(ConversationStatus.UNREAD)">
                    {{ 'messages.filters.unread' | translate }}
                </button>
            </div>
        </div>
    </mat-menu>

    <button
        class="malou-btn-icon--secondary btn-xl"
        mat-icon-button
        [matMenuTriggerFor]="messageMenu"
        [disabled]="areAllSocialPlatformsDisconnected || !atLeastOneMessageSelected()">
        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
    </button>
    <mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #messageMenu="matMenu">
        @if (allSelectedConversationsAreRead()) {
            <button mat-menu-item (click)="markSelectedConversationsAsUnread()">
                <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EYE_CLOSED"></mat-icon>
                <span>{{
                    readConversationCount() > 1 ? ('messages.mark_as_unread_plural' | translate) : ('messages.mark_as_unread' | translate)
                }}</span>
            </button>
        }
        @if (atLeastOneSelectedConversationIsUnread()) {
            <button mat-menu-item (click)="markSelectedConversationsAsRead()">
                <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EYE"></mat-icon>
                <span>{{
                    unreadConversationCount() > 1 ? ('messages.mark_as_read_plural' | translate) : ('messages.mark_as_read' | translate)
                }}</span>
            </button>
        }
        @if (noSelectedConversationIsInFavorites()) {
            <button mat-menu-item (click)="addSelectedConversationsToFavorites()">
                <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.BOOKMARK_FILLED"></mat-icon>
                <span>{{ 'messages.add_to_favorite' | translate }}</span>
            </button>
        }
        @if (atLeastOneSelectedConversationIsInFavorites()) {
            <button mat-menu-item (click)="removeSelectedConversationsFromFavorites()">
                <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.BOOKMARK"></mat-icon>
                <span>{{ 'messages.remove_from_favorites' | translate }}</span>
            </button>
        }
        @if (allSelectedConversationsAreArchived()) {
            <button mat-menu-item (click)="unarchiveSelectedConversations()">
                <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.BOX"></mat-icon>
                <span>{{ 'messages.unarchive' | translate }}</span>
            </button>
        }
        @if (noSelectedConversationIsArchived()) {
            <button mat-menu-item (click)="archiveSelectedConversations()">
                <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.BOX"></mat-icon>
                <span>{{ 'messages.archive' | translate }}</span>
            </button>
        }
    </mat-menu>

    <button
        class="malou-btn-icon--secondary btn-xl"
        mat-icon-button
        matTooltip="{{ 'messages.sync' | translate }}"
        [disabled]="!(hasUpdateFinished$ | async)"
        [disabled]="areAllSocialPlatformsDisconnected"
        (click)="startSynchronizeMessages()">
        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SYNC" [class.malou-spin]="!(hasUpdateFinished$ | async)"></mat-icon>
    </button>
</div>
