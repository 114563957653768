import { z } from 'zod';

export const userSettingsValidator = z.object({
    receiveFeedbacks: z.boolean(),
    notifications: z.object({
        email: z.object({
            reviewReplyReminder: z.object({
                active: z.boolean(),
            }),
        }),
    }),
    receiveMessagesNotifications: z.object({
        active: z.boolean(),
        restaurantsIds: z.array(z.string()),
    }),
    notificationSettings: z.object({
        userDevicesTokens: z.array(z.string()),
        active: z.boolean(),
        reviews: z.object({
            active: z.boolean(),
            realtime: z.boolean(),
            receivingWeekDays: z.array(z.number()),
            concernedRatings: z.array(z.number()),
            includeAutoRepliedReviews: z.boolean(),
        }),
        messages: z.object({
            active: z.boolean(),
            realtime: z.boolean(),
            receivingWeekDays: z.array(z.number()),
        }),
        posts: z.object({
            noMoreScheduledPosts: z.object({
                active: z.boolean(),
            }),
            publishError: z.object({
                active: z.boolean(),
            }),
        }),
    }),
});
