import { EntityConstructor, PlatformKey } from '@malou-io/package-utils';

import { Notification } from './notification.model';

export interface NegativeReviewReminderData {
    reviewId: string;
    reviewText: string;
    reviewRating: number;
    suggestedReply: string;
    platformKey: PlatformKey;
}

type NegativeReviewReminderNotificationProps = EntityConstructor<NegativeReviewReminderNotification>;

export class NegativeReviewReminderNotification extends Notification {
    data: NegativeReviewReminderData;

    constructor(props: NegativeReviewReminderNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<NegativeReviewReminderNotificationProps>): NegativeReviewReminderNotification {
        return new NegativeReviewReminderNotification({ ...this, ...props });
    }
}
