import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, map, Observable, Subject, takeUntil, tap } from 'rxjs';

import { MalouErrorCode, PlatformDataFetchedStatus, PlatformDefinition, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import * as FooterManagerActions from ':core/components/restaurant/footer-manager/store/footer-manager.actions';
import { AvailableFooterType } from ':core/components/restaurant/footer-manager/store/footer-manager.interface';
import { selectCurrentPlatforms, selectOauthPlatforms } from ':modules/platforms/store/platforms.reducer';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { SynchronizationStatus } from ':modules/reviews/store/reviews.interface';
import {
    selectRestaurantsFilter,
    selectReviewsFetchStates,
    selectReviewsSynchronizationStatus,
} from ':modules/reviews/store/reviews.selectors';
import { KillSubscriptions } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { ReviewStatusTooltipPipe } from ':shared/pipes/review-status-tooltip.pipe';

import { AutoUnsubscribeOnDestroy } from '../../decorators/auto-unsubscribe-on-destroy.decorator';
import { FetchedState, Platform, PlatformComparisonWithStatus, Restaurant } from '../../models';

@Component({
    selector: 'app-review-synchronization-footer',
    templateUrl: './review-synchronization-footer.component.html',
    styleUrls: ['./review-synchronization-footer.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule,
        AsyncPipe,
        PlatformLogoPathResolverPipe,
        ReviewStatusTooltipPipe,
        IncludesPipe,
        ApplySelfPurePipe,
        ApplyPurePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ReviewSynchronizationFooterComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject();

    readonly SynchronizationStatus = SynchronizationStatus;
    readonly PlatformDataFetchedStatus = PlatformDataFetchedStatus;

    readonly restaurants$ = this._store.select(selectRestaurantsFilter);
    readonly platforms$: Observable<Platform[]> = this._store.select(selectCurrentPlatforms).pipe(
        map((platforms) => {
            if (platforms?.length) {
                return platforms.map((platform) => new Platform(platform));
            }
            return platforms;
        })
    );
    readonly isFooterVisible$: Observable<boolean> = this._store.select(selectReviewsSynchronizationStatus).pipe(
        tap((status) => {
            this.status = status ?? SynchronizationStatus.NOT_STARTED;
        }),
        map((status) => status !== SynchronizationStatus.NOT_STARTED),
        tap((isFooterVisible) => {
            setTimeout(() => {
                document.getElementById('footer-review-synchronization')?.classList.remove('close-animation');
            }, 10);
            this._store.dispatch({
                type: FooterManagerActions.setFooterVisibility.type,
                footerType: AvailableFooterType.REVIEW_SYNCHRONIZATION,
                isFooterVisible,
            });
        }),
        takeUntil(this.killSubscriptions$)
    );

    status: SynchronizationStatus;
    fetchStates: Record<string, FetchedState<PlatformDataFetchedStatus>> | null = null;
    platformsWithStatus: PlatformComparisonWithStatus[];
    oauthPlatforms: string[] = [];

    isAggregatedView = false;

    constructor(
        private readonly _store: Store,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this._initPlatformsWithStatus();

        combineLatest([this._store.select(selectReviewsFetchStates)])
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe(([fetchStates]) => {
                const platformsKeysWithReviews = PlatformDefinitions.getPlatformKeysWithReview();
                const isSyncFinished =
                    Object.keys(fetchStates).length > 0 &&
                    Object.entries(fetchStates)
                        .filter(([key, _fetchState]: [PlatformKey, FetchedState<PlatformDataFetchedStatus>]) =>
                            platformsKeysWithReviews.includes(key)
                        )
                        .every(
                            ([_key, fetchState]) =>
                                ![PlatformDataFetchedStatus.ASYNC, PlatformDataFetchedStatus.PENDING].includes(fetchState.status)
                        );
                if (isSyncFinished) {
                    this._store.dispatch({
                        type: ReviewsActions.setSynchronizationStatus.type,
                        synchronizationStatus: SynchronizationStatus.LOADED,
                    });
                }
                this.fetchStates = fetchStates;
            });
    }

    close(): void {
        document.getElementById('footer-review-synchronization')?.classList.add('close-animation');
        setTimeout(() => {
            this._cancelCurrentSynchronization();
            this._store.dispatch({
                type: ReviewsActions.setSynchronizationStatus.type,
                synchronizationStatus: SynchronizationStatus.NOT_STARTED,
            });
        }, 400);
    }

    clarifyError = (errorString: string): string => {
        switch (errorString) {
            case MalouErrorCode.REVIEW_INCORRECT_SOCIAL_LINK:
                return this._translateService.instant('reviews_synchronization.error.social_link_malformed');
            default:
                return errorString;
        }
    };

    private _initPlatformsWithStatus(): void {
        this._store
            .select(selectOauthPlatforms)
            .pipe(map((res) => Object.values(res).map((p) => p.key)))
            .subscribe((oauthPlatformsKeys) => {
                this.oauthPlatforms = oauthPlatformsKeys;
            });

        this._cancelCurrentSynchronization();

        combineLatest([this.restaurants$, this.platforms$])
            .pipe(
                filter(([restaurants, platforms]) => !!(restaurants && restaurants.length && platforms)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([restaurants, platforms]) => {
                    if (restaurants.length === 1) {
                        this.platformsWithStatus = this._buildPlatformsComparisonWithStatus(
                            restaurants[0],
                            platforms,
                            PlatformDefinitions.getNonPrivatePlatforms()
                        ).filter((p) => p.isConnected());
                        this.isAggregatedView = false;
                    } else {
                        this.platformsWithStatus = [];
                        this.isAggregatedView = true;
                    }
                },
                error: (err) => {
                    console.error(err);
                },
            });
    }

    private _buildPlatformsComparisonWithStatus(
        restaurant: Restaurant,
        restaurantPlatforms: Platform[],
        platformList: PlatformDefinition[]
    ): PlatformComparisonWithStatus[] {
        return platformList
            .filter((p) => PlatformDefinitions.getPlatformKeysWithReview().includes(p.key))
            .map((p) => {
                const restaurantPlatform = restaurantPlatforms.find((rp) => rp.key === p.key); // can be null
                return new PlatformComparisonWithStatus(restaurant, restaurantPlatform ?? null, p);
            });
    }

    private _cancelCurrentSynchronization(): void {
        this._store.dispatch({ type: ReviewsActions.cancelCurrentReviewsSynchronization.type });
    }
}
