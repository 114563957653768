<app-modal-structure
    [title]="translations().title | translate"
    [primaryButtonText]="translations().primaryButton | translate"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })">
    <div class="flex flex-col gap-y-8">
        <div
            class="malou-color-text-2 malou-text-12--medium"
            [innerHtml]="translations().description1 | translate: { class: 'malou-text-12--bold' }"></div>
        <div class="malou-color-text-2 malou-text-12--medium">{{ translations().description2 | translate }}</div>
        <div class="malou-color-text-2 malou-text-12--medium italic">{{ translations().description3 | translate }}</div>
        <img
            class="{{ imagesClasses[platformKey()] }} self-center mt-4"
            [src]="imageName() | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
    </div>
</app-modal-structure>
