<div class="h-full">
    <ng-container *ngTemplateOutlet="showCloseModal() ? closeModal : pageWrapper"></ng-container>
</div>

<ng-template #pageWrapper>
    <ng-container *ngTemplateOutlet="isInitialLoading() ? shimmer : page"></ng-container>
</ng-template>

<ng-template #closeModal>
    <div class="h-full w-[100vw]">
        <app-close-without-saving-modal
            (onConfirm)="close(closeModalSettings())"
            (onCancel)="cancelClose()"></app-close-without-saving-modal>
    </div>
</ng-template>

<ng-template #page>
    <div class="malou-dialog malou-dialog-mobile">
        <div class="malou-dialog__header" [ngClass]="{ '!py-5': isMalouAdmin }">
            <div class="flex items-center">
                <span>
                    {{ 'wheel_of_fortune.new_wheel_modal.update' | translate }}
                </span>
                @if (isMalouAdmin) {
                    <div class="malou-color-background-light malou-border-primary ml-6 mr-5 flex items-center rounded p-3">
                        <div class="malou-status--error mr-1.5">
                            <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                        </div>
                        <span class="malou-text-10--medium malou-color-text-2 italic">
                            {{ 'wheel_of_fortune.new_wheel_modal.admin_warning' | translate }}
                        </span>
                    </div>
                }
            </div>

            <mat-icon
                class="close cursor-pointer"
                id="tracking_wof_cancel_upsert_modal"
                [svgIcon]="SvgIcon.CROSS"
                (click)="close({ shouldCheckBeforeClose: true })"></mat-icon>
        </div>
        <hr class="border-malou-color-background-dark" />
        <div class="malou-dialog__body !m-0 flex h-auto overflow-x-hidden !px-0">
            <ng-container *ngTemplateOutlet="editor"></ng-container>
            <ng-container *ngTemplateOutlet="preview"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #editor>
    <div class="settings-container z-20 h-full w-full px-4">
        <mat-tab-group
            class="h-[85%]"
            animationDuration="5ms"
            mat-align-tabs="start"
            [selectedIndex]="selectedTabIndex()"
            (selectedIndexChange)="handleTabChange($event)">
            @for (tab of TABS(); track tab) {
                <mat-tab [labelClass]="'tracking_wof_upsert_modal_' + tab.key + '_tab'">
                    <ng-template mat-tab-label>
                        <div class="malou-text-14--medium flex items-center">
                            <div class="mr-2 flex h-6.5 w-6.5 items-center justify-center rounded-full bg-malou-color-primary">
                                <mat-icon class="malou-color-white !h-4.5 !w-4.5" [svgIcon]="tab.icon"></mat-icon>
                            </div>
                            <span class="tab-title">{{ tab.label }}</span>
                            <div [ngClass]="{ invisible: !saveErrorByTab[tab.key]() }">
                                <div class="malou-status--error ml-1.5" [matTooltip]="saveErrorByTab[tab.key]() ?? ''">
                                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    @if (selectedTab().key === tab.key) {
                        <div class="m-8 mb-4 flex h-[88%] flex-col justify-between">
                            @switch (selectedTab().key) {
                                @case (TabKeys.RESTAURANTS) {
                                    <app-restaurant-selection-wheel-of-fortune
                                        class="h-full"
                                        [selectedRedirectionPlatform]="selectedRedirectionPlatform"
                                        [selectedRestaurants]="selectedRestaurants"
                                        [selectedTotems]="selectedTotems"
                                        [allRestaurants]="allRestaurants"
                                        [nonOwnedRestaurants]="nonOwnedRestaurants"
                                        [userRestaurants]="userRestaurants"
                                        [isUpdate]="!!data.wheelOfFortune"
                                        (onClose)="close($event)"></app-restaurant-selection-wheel-of-fortune>
                                }
                                @case (TabKeys.GIFTS) {
                                    <app-gifts-stocks-settings
                                        [gifts]="gifts"
                                        [restaurants]="selectedRestaurants()"
                                        [giftIdToOpen]="giftIdToOpen()"
                                        [isUpdate]="!!data.wheelOfFortune"
                                        [isDisabled]="nonOwnedRestaurants().length > 0"
                                        (isGiftStockOpened)="giftIdToOpen.set(null)"></app-gifts-stocks-settings>
                                }
                                @case (TabKeys.SETTINGS) {
                                    <app-wheel-of-fortune-global-settings
                                        [isAggregatedView]="data.isAggregatedView"
                                        [selectedTotems]="selectedTotems"
                                        [selectedRestaurants]="selectedRestaurants"
                                        [globalSettings]="globalSettings"
                                        [selectedRestaurants]="selectedRestaurants"
                                        [isDisabled]="nonOwnedRestaurants().length > 0"
                                        (onClose)="close($event)"></app-wheel-of-fortune-global-settings>
                                }
                                @case (TabKeys.APPEARANCE) {
                                    <app-appearance-settings-wheel-of-fortune
                                        [isAggregatedView]="data.isAggregatedView"
                                        [primaryColor]="primaryColor"
                                        [secondaryColor]="secondaryColor"
                                        [media]="media"
                                        [isDisabled]="nonOwnedRestaurants().length > 0"></app-appearance-settings-wheel-of-fortune>
                                }
                            }
                        </div>
                    }
                </mat-tab>
            }
        </mat-tab-group>
        <div class="my-4 flex justify-end">
            <button
                class="malou-btn-raised--secondary mr-4 !h-11"
                id="tracking_wof_cancel_upsert_modal"
                mat-raised-button
                (click)="close({ shouldCheckBeforeClose: true })">
                {{ 'common.cancel' | translate }}
            </button>
            <div [matTooltip]="saveErrorTooltip() ?? ''">
                <button
                    class="malou-btn-raised--primary !h-11"
                    id="tracking_wof_save_upsert_modal"
                    mat-raised-button
                    [disabled]="saveErrorTooltip()"
                    (click)="shouldGoToNextTab() ? changeTab() : save()">
                    {{ (shouldGoToNextTab() ? 'common.next' : 'common.save') | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #preview>
    <div class="preview-container flex flex-col overflow-y-scroll rounded bg-malou-color-background-light px-5 pt-4">
        <div class="malou-text-18--bold malou-color-text-1 mb-2">
            {{ 'wheel_of_fortune.new_wheel_modal.preview' | translate }}
        </div>
        <div class="flex w-full items-center justify-center">
            <div class="malou-box-shadow flex h-[530px] w-[300px] flex-col justify-center rounded-3xl">
                <div class="malou-color-bg-white flex h-7 w-full items-center justify-between rounded-t-3xl">
                    <span class="malou-text-11--semibold malou-color-text-1 ml-6">{{ currentTime }}</span>
                    <div class="mr-3 flex">
                        <img class="mr-1 h-2.5 w-3.5" alt="signal" [src]="'signal' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
                        <img class="mr-1 h-2.5 w-3.5" alt="wifi" [src]="'wifi' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
                        <img
                            class="mr-1 h-2.5 w-4.5"
                            alt="battery"
                            [src]="'battery' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
                    </div>
                </div>
                <app-play-wheel-of-fortune-root
                    class="h-full"
                    [isPreview]="isPreview"
                    [wheelOfFortunePreview]="builtWheelOfFortuneData"></app-play-wheel-of-fortune-root>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #shimmer>
    <div class="malou-dialog malou-dialog-mobile flex h-full !w-[100vw] flex-col">
        <div class="malou-dialog__body !m-0 flex grow gap-x-5 !overflow-hidden !px-0">
            <div class="flex h-full w-full">
                <div class="mr-4 flex h-full w-full flex-col justify-between p-8">
                    <div class="flex flex-col">
                        <app-skeleton skeletonClass="secondary-bg h-[50px] !w-[50%] mb-3 mr-4"></app-skeleton>
                        <div class="flex w-full justify-between gap-4">
                            @for (i of [0, 1, 2]; track i) {
                                <app-skeleton class="w-full flex-1" skeletonClass="secondary-bg h-[40px] mb-4 mr-4"></app-skeleton>
                            }
                        </div>
                        <app-skeleton skeletonClass="secondary-bg h-[22px] !w-[250px] mb-4 mr-4"></app-skeleton>
                        <div class="flex w-full justify-between gap-4">
                            @for (i of [0, 1, 2, 3]; track i) {
                                <app-skeleton class="w-full flex-1" skeletonClass="secondary-bg h-[300px] mb-4 mr-4"></app-skeleton>
                            }
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <app-skeleton skeletonClass="secondary-bg h-[50px] !w-[335px] my-4"></app-skeleton>
                    </div>
                </div>
                <app-skeleton
                    class="w-[350px]"
                    skeletonClass="secondary-bg !w-[320px] h-full w-full secondary-bg p-6 flex flex-col justify-between !rounded-none"
                    [animate]="false">
                    <div class="flex h-full flex-col">
                        <div class="flex items-center justify-end">
                            <mat-icon
                                class="close cursor-pointer"
                                [svgIcon]="SvgIcon.CROSS"
                                (click)="close({ shouldCheckBeforeClose: true })"></mat-icon>
                        </div>
                        <app-skeleton skeletonClass="h-[40px] !w-[70%] mb-4"></app-skeleton>
                        <app-skeleton class="h-full" skeletonClass="w-full h-full"></app-skeleton>
                    </div>
                </app-skeleton>
            </div>
        </div>
    </div>
</ng-template>
