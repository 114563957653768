import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformAccessStatus, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';

@Component({
    selector: 'app-information-update-detail-error',
    standalone: true,
    imports: [TranslateModule, IncludesPipe, PlatformNamePipe, CommonModule],
    templateUrl: './information-update-detail-error.component.html',
    styleUrl: './information-update-detail-error.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationUpdateDetailErrorComponent {
    readonly PlatformAccessStatus = PlatformAccessStatus;
    readonly PlatformKey = PlatformKey;
    readonly platformAccessStatus = input.required<PlatformAccessStatus>();
    readonly platformKey = input.required<PlatformKey>();
    readonly close = output<void>();

    readonly _router = inject(Router);
    readonly _currentRestaurant = inject(RestaurantsService).restaurantSelected$;

    goToPlatforms(): void {
        this.close.emit();
        this._router.navigate([`/restaurants/${this._currentRestaurant.value!._id}/settings/platforms/connection`]);
    }
}
