import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isString } from 'lodash';
import { DateTime } from 'luxon';

import { capitalize } from ':shared/helpers';

type DateFormats = 'full' | 'medium' | 'day' | 'shortDate' | 'mediumDate' | 'shortTime' | 'long';

const FORMATS: Record<DateFormats, any> = {
    full: { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long', hour: '2-digit', minute: '2-digit' },
    medium: { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', second: '2-digit' },
    day: { month: 'short', day: 'numeric', weekday: 'long' },
    shortDate: DateTime.DATE_SHORT,
    mediumDate: DateTime.DATE_MED,
    shortTime: { hour: '2-digit', minute: '2-digit' },
    long: { year: 'numeric', month: 'long', day: 'numeric' },
};

@Pipe({
    name: 'formatDate',
    standalone: true,
})
export class FormatDatePipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(dateInput?: Date | string, format: DateFormats = 'full'): string {
        if (!dateInput) {
            return '';
        }
        const date = isString(dateInput) ? new Date(dateInput) : dateInput;
        const dateTime = DateTime.fromJSDate(date);
        const formatedDateTime = dateTime.setLocale(this._translate.currentLang).toLocaleString(FORMATS[format]);
        return capitalize(formatedDateTime);
    }
}
