import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, combineLatest, debounceTime, filter, map, Observable, switchMap } from 'rxjs';

import {
    Currency,
    getCurrencySymbol,
    getMaxOccurrenceCurrency,
    getTimeDifferenceInHours,
    isNotNil,
    SimilarRestaurantCategory,
    sortRestaurantsByInternalNameThenName,
} from '@malou-io/package-utils';

import { SimilarRestaurantsService } from ':core/services/similar-restaurants.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { Restaurant } from ':shared/models';
import { RoiSettings } from ':shared/models/roi-settings.model';

import { PerformanceScoreByRestaurant } from './interface';
import { RoiSettingsService } from './roi-settings.service';

@Injectable({
    providedIn: 'root',
})
export class RoiContext {
    private readonly _store = inject(Store);
    private readonly _roiSettingsService = inject(RoiSettingsService);
    private readonly _similarRestaurantsService = inject(SimilarRestaurantsService);

    readonly roiSettings: WritableSignal<RoiSettings | null> = signal(null);
    readonly isRoiSettingsComplete: Signal<(roiSettings: RoiSettings | null) => boolean> = computed(
        () => (roiSettings) =>
            !!roiSettings &&
            isNotNil(roiSettings.currency) &&
            isNotNil(roiSettings.averageTicket) &&
            isNotNil(roiSettings.minRevenue) &&
            isNotNil(roiSettings.maxRevenue)
    );
    readonly restaurantsRoiSettings: WritableSignal<RoiSettings[]> = signal([]);
    readonly restaurantIdsWithRoiSettings: Signal<string[]> = computed(() =>
        this.restaurantsRoiSettings()
            .filter((roiSettings) => this.isRoiSettingsComplete()(roiSettings))
            .map((roiSettings) => roiSettings.restaurantId)
    );
    readonly restaurantsIdsWithoutRoiSettings: WritableSignal<string[]> = signal([]);

    readonly globalCurrency: Signal<Currency> = computed(() => {
        const currencies = this.restaurantsRoiSettings().map((roiSettings) => roiSettings.currency);
        return getMaxOccurrenceCurrency(currencies);
    });

    readonly displayedGlobalCurrency: Signal<string> = computed(() => getCurrencySymbol(this.globalCurrency()));
    readonly reloadRestaurantCategories$ = new BehaviorSubject(false);

    readonly restaurantsCategories$: Observable<{ restaurantId: string; category: SimilarRestaurantCategory | undefined }[]> =
        combineLatest([this._store.select(selectOwnRestaurants), this.reloadRestaurantCategories$]).pipe(
            filter(([_, shouldReload]) => shouldReload),
            debounceTime(500),
            map(
                ([restaurants, _]) =>
                    restaurants.filter((restaurant) => !restaurant.isBrandBusiness()).sort(sortRestaurantsByInternalNameThenName) ?? []
            ),
            switchMap((restaurants) =>
                this._similarRestaurantsService.getMany(restaurants.map((restaurant) => restaurant._id)).pipe(map((res) => res.data))
            ),
            catchError((err) => {
                console.warn(err);
                return [];
            }),
            map(
                (similarRestaurants) =>
                    similarRestaurants
                        .filter((res) => !!res && res?.restaurantId)
                        .map((res) => ({ restaurantId: res.restaurantId, category: res.restaurantCategory })) ?? []
            )
        );
    readonly restaurantsCategories: Signal<{ restaurantId: string; category: SimilarRestaurantCategory | undefined }[]> = toSignal(
        this.restaurantsCategories$,
        { initialValue: [] }
    );

    readonly performanceScore: WritableSignal<number> = signal(0);
    readonly performanceScorePerRestaurant: WritableSignal<PerformanceScoreByRestaurant[]> = signal([]);

    getRoiSettings(restaurantId: string): Observable<RoiSettings | null> {
        return this._roiSettingsService.getByRestaurantId(restaurantId).pipe(
            map((res) => {
                this.roiSettings.set(res.data ? new RoiSettings(res.data) : null);
                return this.roiSettings();
            })
        );
    }

    getRoiSettingsForRestaurants(restaurantIds: string[]): Observable<RoiSettings[]> {
        this.reloadRestaurantCategories$.next(true);
        return this._roiSettingsService.getForRestaurantIds(restaurantIds).pipe(
            map((res) => {
                this.restaurantsRoiSettings.set(res.data?.length ? res.data.map((roiSettings) => new RoiSettings(roiSettings)) : []);
                this.restaurantsIdsWithoutRoiSettings.set(
                    restaurantIds.filter((restaurantId) => !this.isRestaurantRoiSettingsComplete(restaurantId))
                );
                return this.restaurantsRoiSettings();
            })
        );
    }

    isRestaurantRoiSettingsComplete(restaurantId: string): boolean {
        const foundRoiSettings = this.restaurantsRoiSettings().find((roiSettings) => roiSettings.restaurantId === restaurantId);
        return !!foundRoiSettings && this.isRoiSettingsComplete()(foundRoiSettings);
    }

    getLatestCreatedRestaurantsWithoutRoiSettings(restaurants: Restaurant[]): Restaurant {
        return restaurants
            .filter((restaurant) => this.restaurantsIdsWithoutRoiSettings().includes(restaurant._id))
            .sort((a, b) => getTimeDifferenceInHours(b.createdAt, a.createdAt))[0];
    }

    getHasSomeInitialSettings(
        restaurantsRoiSettings: RoiSettings[],
        restaurantsCategories: { restaurantId: string; category: SimilarRestaurantCategory | undefined }[]
    ): boolean {
        const fullRoiSettingsAndCategory = restaurantsRoiSettings.filter(
            (roiSettings) =>
                this.isRoiSettingsComplete()(roiSettings) &&
                !!restaurantsCategories.find(({ restaurantId }) => roiSettings.restaurantId === restaurantId)?.category
        );
        return fullRoiSettingsAndCategory.length >= 2;
    }
}
