import { Routes } from '@angular/router';

import { RestaurantResolver } from ':core/resolvers/restaurant.resolver';
import { AGGREGATED_STATISTICS_PDF_ROUTES } from ':modules/aggregated-statistics-pdf/aggregated-statistics-pdf.routing';
import { DOWNLOAD_ROUTES } from ':modules/download-medias-and-folders/download.routing';
import { HEADER_ROUTER_ROUTES } from ':modules/header-router/header-router.routing';
import { SIDENAV_ROUTER_ROUTES } from ':modules/sidenav-router/sidenav-router.routing';
import { STATISTICS_PDF_ROUTES } from ':modules/statistics-pdf/statistics-pdf.routing';

export const LOGGED_IN_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => HEADER_ROUTER_ROUTES,
    },
    {
        path: '',
        loadChildren: () => SIDENAV_ROUTER_ROUTES,
    },
    {
        path: 'download',
        loadChildren: () => DOWNLOAD_ROUTES,
    },
    {
        path: 'statistics-pdf/:restaurant_id',
        providers: [RestaurantResolver],
        resolve: { restaurant: RestaurantResolver },
        loadChildren: () => STATISTICS_PDF_ROUTES,
    },
    {
        path: 'aggregated-statistics-pdf',
        loadChildren: () => AGGREGATED_STATISTICS_PDF_ROUTES,
    },
];
