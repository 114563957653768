@if (getIsPhoneScreen()) {
    <div
        class="!m-0 flex !h-20 !flex-row items-center justify-start !p-2"
        [ngClass]="{
            'malou-card': !comment.hasBeenAnswered,
            'malou-card--secondary': comment.hasBeenAnswered,
        }"
        (click)="openAnswerModal()">
        <a
            class="flex h-16 w-12 overflow-hidden"
            target="_blank"
            [ngClass]="{ 'cursor-pointer': comment.post?.socialLink, 'cursor-default': !comment.post?.socialLink }"
            [href]="comment.post?.socialLink">
            @if (comment.post) {
                <app-social-post-media
                    customMediaClass="!rounded-md h-[4.5rem] w-[5.5rem]"
                    [showVideoControls]="false"
                    [postType]="comment.post.postType"
                    [thumbnailUrl]="comment.post | applySelfPure: 'getSocialMediaThumbnail'"
                    [workingMedia]="
                        (comment.post | applySelfPure: 'getSocialMediaUrl') || (comment.post | applySelfPure: 'getMalouMediaUrl')
                    "
                    [firstAttachmentType]="comment.post | applySelfPure: 'getFirstMediumType'"
                    [tooltipText]="comment.post.text"
                    (refreshMedia)="refreshPost()">
                </app-social-post-media>
            }
        </a>
        <div class="ml-5 flex flex-1 flex-col">
            <div class="flex h-11 flex-row items-center">
                <div class="platform-user-logo relative w-8">
                    <img
                        class="translate-x-[50%] transform rounded-full"
                        height="20"
                        width="20"
                        [lazyLoad]="comment.reviewer.displayName | avatar" />
                    <img class="absolute left-0 top-0" height="20" width="20" [lazyLoad]="comment.platformKey | platformLogoPathResolver" />
                </div>
                <div class="relative ml-3 flex h-full flex-1 gap-x-2">
                    <div class="flex max-w-[70%] flex-col pt-1">
                        <div
                            class="malou-color-text-1 malou-text-12--bold truncate"
                            [innerHTML]="(comment.platformKey === PlatformKey.INSTAGRAM ? '@' : '') + comment.reviewer.displayName"></div>
                        <div class="malou-text-10--regular truncate italic" [innerHTML]="comment.socialCreatedAt | formatDate"></div>
                    </div>
                    <div
                        class="flex h-6 w-6 !cursor-default items-center justify-center rounded-3xl"
                        [ngClass]="getChipColor | applyPure: comment"
                        [matTooltip]="getChipText | applyPure: comment">
                        <span class="malou-text-12--medium mt-[1px]">
                            {{ getChipLabel | applyPure: comment }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="relative h-5 w-full">
                <span class="malou-text-12--regular absolute inset-0 overflow-hidden truncate">
                    <span [innerHTML]="formatTextToHtml | applyPure: (boldenMentions | applyPure: comment.text)"></span>
                </span>
            </div>
        </div>
        <button
            class="malou-btn-icon--secondary btn-xl !ml-1"
            mat-icon-button
            [ngClass]="{ 'btn-active': comment.archived }"
            [matTooltip]="getArchiveTooltipText | applyPure: comment"
            (click)="$event.stopPropagation(); toggleArchived()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.BOX"></mat-icon>
        </button>
    </div>
} @else {
    <div
        class="!m-0 flex min-h-24 !flex-row items-center justify-start !p-2 !pr-6"
        [ngClass]="{
            'malou-card': !comment.hasBeenAnswered,
            'malou-card--secondary': comment.hasBeenAnswered,
            'h-24': !isExpanded,
        }">
        <a
            class="flex h-20 w-16 overflow-hidden"
            target="_blank"
            [ngClass]="{ 'cursor-pointer': comment.post?.socialLink, 'cursor-default': !comment.post?.socialLink }"
            [href]="comment.post?.socialLink">
            @if (comment.post) {
                <app-social-post-media
                    customMediaClass="!rounded-md h-[4.5rem] w-[5.5rem]"
                    [postType]="comment.post.postType"
                    [thumbnailUrl]="comment.post | applySelfPure: 'getSocialMediaThumbnail'"
                    [workingMedia]="
                        (comment.post | applySelfPure: 'getSocialMediaUrl') || (comment.post | applySelfPure: 'getMalouMediaUrl')
                    "
                    [firstAttachmentType]="comment.post | applySelfPure: 'getFirstMediumType'"
                    [tooltipText]="comment.post.text"
                    (refreshMedia)="refreshPost()">
                </app-social-post-media>
            }
        </a>
        <div class="ml-5 flex flex-1 flex-col justify-start gap-y-2 py-1">
            <div class="flex h-8 flex-row justify-center">
                <div class="platform-user-logo relative w-12">
                    <img
                        class="translate-x-[50%] transform rounded-full"
                        height="30"
                        width="30"
                        [lazyLoad]="comment.reviewer.displayName | avatar" />
                    <img class="absolute left-0 top-0" height="30" width="30" [lazyLoad]="comment.platformKey | platformLogoPathResolver" />
                </div>
                <div class="ml-3 flex flex-1 flex-col justify-between truncate">
                    <span class="malou-break-word malou-color-text-1 malou-text-12--bold">
                        {{ (comment.platformKey === PlatformKey.INSTAGRAM ? '@' : '') + comment.reviewer.displayName }}
                    </span>
                    <i class="malou-text-10--regular italic">{{ comment.socialCreatedAt | formatDate }}</i>
                </div>
            </div>
            <span
                class="malou-text-10--regular min-h-8 overflow-hidden leading-snug"
                [ngClass]="{ 'h-8': !isExpanded, 'overflow-hidden': !isExpanded }">
                @if (comment.text) {
                    <span
                        [innerHTML]="
                            isExpanded
                                ? (formatTextToHtml | applyPure: (boldenMentions | applyPure: comment.text))
                                : (formatTextToHtml | applyPure: (boldenMentions | applyPure: (comment.text | shortText: commentTextLimit)))
                        "></span>

                    <!-- One space between the shortened text and the see more button -->
                    {{ ' ' }}
                    @if (comment.text.length > commentTextLimit) {
                        <button class="malou-text-10--semibold malou-color-text-primary" (click)="toggleExpanded()">
                            {{ isExpanded ? ('common.see_less' | translate) : ('common.see_more' | translate) }}
                        </button>
                    }
                }
            </span>
        </div>
        <div class="flex flex-col justify-center pr-3" [ngClass]="{ 'min-w-[14.5rem]': !isSmallChip(), 'min-w-[4rem]': isSmallChip() }">
            <div
                class="malou-chip--rounded-20 !cursor-default"
                [ngClass]="getChipColor | applyPure: comment"
                [matTooltip]="isSmallChip() ? (getChipText | applyPure: comment) : ''">
                {{ getChipLabel | applyPure: comment }}
            </div>
        </div>
        <button class="malou-btn-flat flex w-20 pr-3" (click)="openAnswerModal()">
            {{ comment.hasBeenAnswered ? ('common.see' | translate) : ('common.reply' | translate) }}
        </button>
        <button
            class="malou-btn-icon--secondary btn-xl"
            mat-icon-button
            [matTooltip]="!comment.archived ? ('common.archive' | translate) : ('common.unarchive' | translate)"
            [ngClass]="{ 'btn-active': comment.archived }"
            (click)="$event.stopPropagation(); toggleArchived()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.BOX"></mat-icon>
        </button>
    </div>
}
