<div class="flex w-full items-center gap-4 md:flex-col md:items-stretch md:gap-2">
    <div class="sm:w-full">
        <ng-container [ngTemplateOutlet]="page() === PlatformFilterPage.ROI ? appTimeScaleFilterTemplate : appGroupedDateFiltersTemplate">
        </ng-container>
    </div>

    <div class="flex min-w-0 grow items-center gap-4 sm:w-full md:flex-col md:items-stretch md:gap-2">
        <div
            class="min-w-0 flex-1 sm:max-w-sm"
            [class.flex-2]="page() === PlatformFilterPage.SOCIAL_NETWORKS || page() === PlatformFilterPage.BOOSTERS">
            <app-select-restaurants
                [values]="(restaurants$ | async) ?? []"
                [formControl]="restaurantsFilterControl"
                [multiSelectionElementWrap]="false"
                [shouldSwitchToWrapModeOnClick]="false"
                [defaultEmptyValueMessage]="'app_select_restaurants.no_business_found' | translate"
                [shouldUpdateValuesToDisplayAfterSelection]="false"
                [computeObjectHash]="compareByRestaurantId"
                [valuesCanBeDisabled]="page() === PlatformFilterPage.ROI"
                (selectRestaurantsChange)="onRestaurantsChange($event)">
            </app-select-restaurants>
        </div>

        @if (showPlatformsFilter()) {
            <div class="min-w-0 flex-1">
                <app-select-platforms
                    [values]="connectedPlatforms"
                    [formControl]="platformsFilterControl"
                    (selectPlatformsChange)="onPlatformsChange($event)">
                </app-select-platforms>
            </div>
        }

        @if (showTotemsFilter()) {
            <div class="h-[50px] min-w-0 flex-2 sm:max-w-sm">
                <app-select-base
                    class="mr-5 mt-4 max-h-[50px] max-w-[98%] overflow-x-hidden"
                    [values]="selectableTotems()"
                    [selectedValues]="selectableTotems()"
                    [displayWith]="totemDisplayWith"
                    [sortBy]="totemsSortBy"
                    [formControl]="totemsFilterControl"
                    [defaultEmptyValueMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_totem' | translate"
                    [selectAllCheckboxMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.all_totems' | translate"
                    [multiSelection]="true"
                    [checkboxOption]="true"
                    [showSelectAllCheckbox]="true"
                    [showValuesSelectedCount]="true"
                    [groupSelectedValuesAtTop]="true"
                    [multiSelectionElementWrap]="false"
                    [computeObjectHash]="compareByTotemId"
                    [shouldSwitchToWrapModeOnClick]="false"
                    [shouldUpdateValuesToDisplayAfterSelection]="true"
                    [valuesSelectedCountMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.totems' | translate"
                    (selectBaseChange)="onTotemsChange($event)">
                    <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
                        <div class="malou-chip--rounded-4 malou-chip--primary">
                            <span>{{ totemDisplayWith | applyPure: value }}</span>
                            <mat-icon
                                class="malou-chip-icon--right malou-color-primary"
                                [svgIcon]="SvgIcon.REMOVE"
                                (click)="deleteValueAt(index)"></mat-icon>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" let-isValueSelected="isValueSelected" #optionTemplate>
                        <div class="malou-text-12--semibold" [class.malou-color-text-1]="isValueSelected">
                            {{ totemDisplayWith | applyPure: value }}
                        </div>
                    </ng-template>
                </app-select-base>
            </div>
        }
    </div>
</div>

<ng-template #appGroupedDateFiltersTemplate>
    <app-grouped-date-filters
        [startDate]="startDate$ | async"
        [endDate]="endDate$ | async"
        [defaultPeriod]="(period$ | async) ?? MalouPeriod.DEFAULT"
        [periodOptions]="DEFAULT_PERIODS"
        [withLabel]="!screenSizeService.isPhoneScreen"
        [blockFutureDates]="true"
        [isLeftMaxDateToday]="true"
        (chooseBoundaryDate)="chooseBoundaryDate($event)">
    </app-grouped-date-filters>
</ng-template>

<ng-template #appTimeScaleFilterTemplate>
    <app-select-time-scale-filter
        [formControl]="timeScaleFilterControl"
        [minAcceptedDate]="timeScaleMinAcceptedDate()"
        (selectTimeScaleChange)="onTimeScaleChange($event)" />
</ng-template>
