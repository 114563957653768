<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [selectedValues]="selectedValues()"
    [displayWith]="displayWith()"
    [multiSelection]="multiSelection()"
    [checkboxOption]="multiSelection()"
    [groupSelectedValuesAtTop]="false"
    (selectBaseChange)="selectPlatformsChange.emit($event)">
    <ng-template let-value="value" #selectedValueTemplate>
        <img class="h-9 w-9 rounded-full" [src]="value | platformLogoPathResolver: { folder: 'platforms' }" [alt]="value" />
    </ng-template>
    <ng-template let-value="value" #optionTemplate>
        <div class="flex items-center gap-x-2">
            <img class="h-8 w-8 rounded-full" [lazyLoad]="value | platformLogoPathResolver: { folder: 'platforms' }" [alt]="value" />
            <span>{{ displayWith() | applyPure: value }}</span>
        </div>
    </ng-template>
</app-select-base>
