import { MalouErrorCode } from './constants';

export type RemoveMethodsFromClass<T> = {
    [K in keyof T as T[K] extends Function ? never : K]: T[K];
};

export type ApiResultSuccess<T> = {
    data: T;
    message?: string;
    msg?: string;
};

export type ApiResultFailure = {
    err?: any;
    error?: any;
    msg?: string;
    errorData?: any;
};

export type ApiResult<T = undefined> = ApiResultSuccess<T> | ApiResultFailure;

export function isApiResultSuccess<T>(apiResult: ApiResult<T>): apiResult is ApiResultSuccess<T> {
    return (apiResult as ApiResultSuccess<T>).data !== undefined;
}

// We use '[T] extends [never]' instead of 'T extends never' because of distributive conditional types
// https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
// type Generic<T = never> = T extends never ? 0 : 1;
// type A = Generic<number | string> => number extends never ? 0 : 1 | string extends never ? 0 : 1;
// type B = Generic<number>          => number extends never ? 0 : 1;
// type C = Generic                  => never; !!! nor 0 or 1
// TODO IMPROVE TYPE SO WE DON'T HAVE metadata? BUT CAN DO SOMETHING LIKE ApiResultV2<T, U | undefined> IN CONTROLLERS TO MAKE IT OPTIONAL
export type ApiResultV2<T = never, U = never> = ([T] extends [never] ? {} : { data: T }) & ([U] extends [never] ? {} : { metadata?: U });

export interface ApiResultError {
    errorMessage?: string;
    errorCode?: MalouErrorCode;
}

export interface ApiResultMetadataPagination {
    pagination: {
        total: number;
    };
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

// https://stackoverflow.com/a/49725198
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
    {
        [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
    }[Keys];

export interface Timestampeable {
    createdAt: Date;
    updatedAt: Date;
}

export type DefaultEntityProperties = {
    id: string;
    createdAt: Date;
    updatedAt: Date;
};

export type EntityConstructor<T> = Omit<RemoveMethodsFromClass<T>, keyof Timestampeable> & Partial<Timestampeable>;

export type MakeUndefinedKeysOptional<T extends object> = {
    [K in keyof T as undefined extends T[K] ? never : K]: T[K];
} & {
    [K in keyof T as undefined extends T[K] ? K : never]+?: T[K];
};

export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;
