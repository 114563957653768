import { ChangeDetectionStrategy, Component, computed, inject, input, model, output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { Keyword } from ':shared/models/keyword';
import { RestaurantAiSettings } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

import { RestaurantAiSettingsFormData } from '../edit-restaurant-ai-settings-modal.component';

@Component({
    selector: 'app-advanced-ai-settings',
    templateUrl: './advanced-ai-settings.component.html',
    styleUrls: ['./advanced-ai-settings.component.scss'],
    standalone: true,
    imports: [
        MatCheckboxModule,
        MatIconModule,
        TranslateModule,
        SelectChipListComponent,
        TextAreaComponent,
        ApplyPurePipe,
        SimpleSelectComponent,
        EnumTranslatePipe,
        FlagPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedAiSettingsComponent {
    private readonly _translateService = inject(TranslateService);
    private readonly _router = inject(Router);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly restaurantAiSettings = model.required<RestaurantAiSettings | undefined>();
    readonly selectedKeywords = input<Keyword[]>([]);
    readonly disabled = input<boolean>(false);
    readonly close = output();

    readonly DEFAULT_LANGUAGE_RESPONSE_OPTIONS = Object.values(ApplicationLanguage);
    readonly SvgIcon = SvgIcon;
    readonly isKeywordSelected = computed(
        () =>
            (keyword: Keyword): boolean =>
                !!this.restaurantAiSettings()?.restaurantKeywordIds.some(
                    (restaurantKeywordId) => restaurantKeywordId === keyword.restaurantKeywordId
                )
    );

    readonly MAX_FORBIDDEN_WORDS = 10;

    readonly forbiddenWordsError = computed(() => {
        const forbiddenWords = this.restaurantAiSettings()?.forbiddenWords ?? [];

        if (forbiddenWords.length > this.MAX_FORBIDDEN_WORDS) {
            return this._translateService.instant('restaurant_ai_settings.modals.upsert.errors.max_forbidden_words', {
                max: this.MAX_FORBIDDEN_WORDS,
            });
        }

        return undefined;
    });

    readonly defaultLanguageResponseError = computed(() => {
        const defaultLanguageResponse = this.restaurantAiSettings()?.defaultLanguageResponse;

        if (!defaultLanguageResponse) {
            return this._translateService.instant('restaurant_ai_settings.modals.upsert.errors.missing_default_language');
        }

        return undefined;
    });

    displayForbiddenWordWith = (forbiddenWord: string): string => forbiddenWord;

    buildForbiddenWordFromText = (text: string): string => text.trim();

    toggleKeywordSelected(keyword: Keyword): void {
        const restaurantKeywordId = keyword.restaurantKeywordId;
        this.restaurantAiSettings.update((restaurantAiSettings) => {
            if (!restaurantAiSettings) {
                return undefined;
            }
            const restaurantKeywordIds = restaurantAiSettings.restaurantKeywordIds;
            if (restaurantKeywordIds.includes(restaurantKeywordId)) {
                return new RestaurantAiSettings({
                    ...restaurantAiSettings,
                    restaurantKeywordIds: restaurantKeywordIds.filter((id) => id !== restaurantKeywordId),
                });
            }
            return new RestaurantAiSettings({
                ...restaurantAiSettings,
                restaurantKeywordIds: [...restaurantKeywordIds, restaurantKeywordId],
            });
        });
    }

    updateForbiddenWords(newForbiddenWords: string[]): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings ? new RestaurantAiSettings({ ...restaurantAiSettings, forbiddenWords: newForbiddenWords }) : undefined
        );
    }

    onKeywordsCtaClick(): void {
        this.close.emit();
        void this._router.navigate([`/restaurants/${this._restaurantsService.currentRestaurant._id}/resources/keywords`]);
    }

    updateDefaultLanguageResponse(newDefaultLanguageResponses: unknown): void {
        this.restaurantAiSettings.update((restaurantAiSettings) =>
            restaurantAiSettings
                ? new RestaurantAiSettingsFormData({
                      ...restaurantAiSettings,
                      defaultLanguageResponse: newDefaultLanguageResponses as ApplicationLanguage,
                  })
                : undefined
        );
    }
}
