import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { NotificationChannel, NotificationType, PlatformKey } from '@malou-io/package-utils';

import { environment } from ':environments/environment';

import { NegativeReviewReminderData } from '../models/negative-review-reminder-notification.model';
import { NotificationFactory } from '../models/notification.factory';
import { Notification } from '../models/notification.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/notifications`;

    private readonly _http: HttpClient;

    getNotifications(): Observable<Notification[]> {
        // return this.http.get<Notification[]>(`${this.baseUrl}/notifications`);
        return of([
            NotificationFactory.create({
                id: '1',
                userIds: ['1'],
                restaurantId: '1',
                data: {
                    reviewId: '1',
                    platformKey: PlatformKey.GMB,
                    reviewRating: 1,
                    reviewText:
                        'lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet ',
                    suggestedReply:
                        'lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet ',
                } as NegativeReviewReminderData,
                readAt: null,
                type: NotificationType.REVIEW_REPLY_REMINDER,
                error: null,
                channel: NotificationChannel.EMAIL,
                completedAt: null,
                createdAt: new Date(),
                updatedAt: new Date(),
            }),
            NotificationFactory.create({
                id: '432',
                userIds: ['1'],
                restaurantId: '1',
                data: {
                    restaurantIds: ['6516db5e6d6bef2863493391'],
                    reviewIds: ['5f7b1b3b6d6bef2863493391', '5f7b1b3b6d6bef2863493392'],
                },
                readAt: null,
                type: NotificationType.REVIEW,
                error: null,
                channel: NotificationChannel.EMAIL,
                completedAt: null,
                createdAt: new Date(),
                updatedAt: new Date(),
            }),
            NotificationFactory.create({
                id: '4333',
                userIds: ['1'],
                restaurantId: '1',
                data: {
                    restaurantIds: ['6516db5e6d6bef2863493391'],
                    reviewIds: ['5f7b1b3b6d6bef2863493391'],
                },
                readAt: new Date(),
                type: NotificationType.REVIEW,
                error: null,
                channel: NotificationChannel.EMAIL,
                completedAt: null,
                createdAt: new Date(),
                updatedAt: new Date(),
            }),

            NotificationFactory.create({
                id: '22121',
                userIds: ['1'],
                restaurantId: '1',
                data: {
                    eventName: 'Fête du travail France',
                    restaurantIds: ['6516db5e6d6bef2863493391', 'dazeazezae'],
                    eventDate: new Date('2024-05-01'),
                },
                readAt: null,
                type: NotificationType.SPECIAL_HOUR,
                error: null,
                channel: NotificationChannel.EMAIL,
                completedAt: null,
                createdAt: new Date(),
                updatedAt: new Date(),
            }),
            NotificationFactory.create({
                id: '221212',
                userIds: ['1'],
                restaurantId: '1',
                data: {
                    eventName: 'Fête du travail Italie',
                    restaurantIds: ['6516db5e6d6bef2863493391'],
                    eventDate: new Date('2024-05-01'),
                },
                readAt: null,
                type: NotificationType.SPECIAL_HOUR,
                error: null,
                channel: NotificationChannel.EMAIL,
                completedAt: null,
                createdAt: new Date(),
                updatedAt: new Date(),
            }),
        ]);
    }
}
