import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
    SpecialHourInterval,
    SpecialHoursService,
} from ':modules/informations/hours-modal/special-hours-form/special-hours-interval.service';
import { Restaurant, SpecialTimePeriod } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { MalouDatePipe } from ':shared/pipes/malou-date.pipe';
import { SpecialTimePeriodPipe } from ':shared/pipes/special-time-period.pipe';

@Component({
    selector: 'app-restaurant-special-hours',
    templateUrl: './restaurant-special-hours.component.html',
    styleUrls: ['./restaurant-special-hours.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, MatButtonModule, TranslateModule, ApplyPurePipe, FormatDatePipe, MalouDatePipe, SpecialTimePeriodPipe],
    providers: [FormatTimePipe],
})
export class RestaurantSpecialHoursComponent implements OnChanges {
    @Input() restaurant: Restaurant;
    public displayedColumns = ['date', 'time'];
    public displayedHours: SpecialTimePeriod[];
    public showAnteriorHours = false;
    public restaurantSpecialHoursIntervals: SpecialHourInterval[] = [];
    specialHours = this.translate.instant('information.special_hours');

    constructor(
        private readonly _specialHoursService: SpecialHoursService,
        public readonly translate: TranslateService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.restaurant && changes.restaurant.currentValue.specialHours) {
            this.displayedHours = this._sortHours(changes.restaurant.currentValue.specialHours);
            this.onShowAnteriorDates();
        }
    }

    onShowAnteriorDates(): void {
        this.displayedHours = this.showAnteriorHours
            ? this._sortHours(this.restaurant.specialHours)
            : this._sortHours(this.restaurant.specialHours).filter(
                  (hour) => hour.startDate.getDate().setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
              );
        this.restaurantSpecialHoursIntervals = this._specialHoursService.createIntervalsFromDates(this.displayedHours);
    }

    isIntervalDates = (interval: SpecialHourInterval): boolean => interval.start.getDate().getTime() !== interval.end.getDate().getTime();

    private _sortHours(hours: SpecialTimePeriod[]): SpecialTimePeriod[] {
        if (!hours || hours.length === 0) {
            return [];
        }
        return [...hours].sort((h1, h2) => {
            if (h1.startDate.getDate() < h2.startDate.getDate()) {
                return -1;
            }
            if (h1.startDate.getDate() > h2.startDate.getDate()) {
                return 1;
            }
            if (!h1.openTime || !h2.openTime) {
                return 0;
            }
            if (h1.openTime < h2.openTime) {
                return -1;
            }
            if (h1.openTime > h2.openTime) {
                return 1;
            }
            return 0;
        });
    }
}
