import { Action, createReducer, on } from '@ngrx/store';

import * as SideBarActions from './sidenav.actions';

export interface SidenavState {
    isOpened: boolean;
}

export const initialState: SidenavState = {
    isOpened: true,
};

const _sidenavReducer = createReducer(
    initialState,
    on(SideBarActions.open, (state) => ({ ...state, isOpened: true })),
    on(SideBarActions.close, (state) => ({ ...state, isOpened: false })),
    on(SideBarActions.toggle, (state) => ({ ...state, isOpened: !state.isOpened }))
);

export function reducer(state: SidenavState | undefined, action: Action): SidenavState {
    return _sidenavReducer(state, action);
}
