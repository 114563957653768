import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    effect,
    EventEmitter,
    inject,
    Injector,
    Input,
    OnInit,
    Output,
    runInInjectionContext,
    Signal,
    ViewChild,
} from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Observable } from 'rxjs';

import { InsightsChart, PlatformKey, PostType } from '@malou-io/package-utils';

import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { DatesAndPeriod, PostWithInsights } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-social-networks-posts',
    templateUrl: './posts.component.html',
    styleUrls: ['./posts.component.scss'],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' },
        },
        EnumTranslatePipe,
    ],
    standalone: true,
    imports: [
        NgClass,
        PaginatorComponent,
        SkeletonComponent,
        SocialPostMediaComponent,
        SortByFiltersComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        LazyLoadImageModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        FormatDatePipe,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
        PlatformLogoPathResolverPipe,
        ShortNumberPipe,
        StatisticsHttpErrorPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostsComponent implements OnInit {
    @Input() posts: Signal<PostWithInsights[]>;
    @Input() areAllPlatformsInError = false;
    @Input() platformsErrorTooltip: string | undefined;
    @Input() httpError?: any;
    @Input() isLoading = true;
    @Input() isReelTable = false;
    @Input() shouldDisplayElementsPaginated = true;
    @Input() shouldLazyLoadMedia = true;

    @Input() tableSortOptions: Sort | undefined = undefined;
    @Output() tableSortOptionsChange = new EventEmitter<{ chart: InsightsChart; value: Sort }>();

    readonly SvgIcon = SvgIcon;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);

    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'platform', label: this.translate.instant('statistics.social_networks.posts.platform') },
        { key: 'createdAt', label: this.translate.instant('statistics.social_networks.posts.created_at') },
        { key: 'likes', label: this.translate.instant('statistics.social_networks.posts.likes') },
        { key: 'comments', label: this.translate.instant('statistics.social_networks.posts.comments') },
        { key: 'engagementRate', label: this.translate.instant('statistics.social_networks.posts.engagement') },
        { key: 'impressions', label: this.translate.instant('statistics.social_networks.posts.impressions') },
        { key: 'plays', label: this.translate.instant('statistics.social_networks.posts.plays') },
    ];
    readonly DISPLAYED_COLUMNS: string[] = ['picture', 'platform', 'createdAt', 'likes', 'comments', 'shares', 'saved', 'engagementRate'];

    dataSource = new MatTableDataSource<PostWithInsights>();
    defaultSort: Sort = {
        active: 'createdAt',
        direction: 'desc',
    };

    private readonly _injector = inject(Injector);

    constructor(
        private readonly _store: Store,
        public readonly translate: TranslateService
    ) {}

    @ViewChild(PaginatorComponent) set paginator(paginatorComponent: PaginatorComponent) {
        if (paginatorComponent) {
            this.dataSource.paginator = paginatorComponent.matPaginator;
        }
    }

    @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string => {
                const { active, direction } = sort;
                this._emitSortOptions({ active, direction }, this.isReelTable);
                switch (property) {
                    case 'platform':
                        return item.key;
                    default:
                        return item[property];
                }
            };
            this.dataSource.sort = sort;
        }
    }

    ngOnInit(): void {
        runInInjectionContext(this._injector, () => {
            effect(() => {
                this.dataSource.data = this.posts();
            });
        });

        if (this.tableSortOptions) {
            this.defaultSort = this.tableSortOptions;
        }

        if (this.isReelTable) {
            this.DISPLAYED_COLUMNS.splice(1, 1);
            this.DISPLAYED_COLUMNS.push('plays', 'reach');
        } else {
            this.DISPLAYED_COLUMNS.push('impressions');
        }
    }

    isInstagramCarrousel(postWithInsights: PostWithInsights): boolean {
        return postWithInsights.key === PlatformKey.INSTAGRAM && postWithInsights.postType === PostType.CAROUSEL;
    }

    isFromFacebook(postWithInsights: PostWithInsights): boolean {
        return postWithInsights.key === PlatformKey.FACEBOOK;
    }

    onSortByChange(sortBy: string): void {
        this.dataSource.sort?.sort({ id: sortBy, start: this.dataSource.sort.direction || 'asc', disableClear: true });
    }

    onSortOrderChange(): void {
        const direction = this.dataSource.sort?.direction === 'asc' ? 'desc' : 'asc';
        this.dataSource.sort?.sort({ id: this.dataSource.sort.active, start: direction, disableClear: true });
    }

    private _emitSortOptions(sort: Sort, isReelTable: boolean): void {
        const chart = isReelTable ? InsightsChart.REEL_INSIGHTS : InsightsChart.POST_INSIGHTS;
        this.tableSortOptionsChange.emit({ chart, value: sort });
    }
}
