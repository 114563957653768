import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { ToastService } from ':core/services/toast.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import * as UserActions from '../store/user.actions';
import { UserState } from '../store/user.reducer';
import { User } from '../user';
import { UsersService } from '../users.services';

interface MiscForm {
    name: string;
    lastname: string;
}

@Component({
    selector: 'app-edit-user-miscellaneous',
    templateUrl: './edit-user-miscellaneous.component.html',
    styleUrls: ['./edit-user-miscellaneous.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        InputTextComponent,
        MatButtonModule,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
        TranslateModule,
    ],
})
export class EditUserMiscellaneousComponent implements OnInit {
    public miscForm: INullableFormGroup<MiscForm>;
    public user: User;

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _usersService: UsersService,
        private readonly _router: Router,
        private readonly _fb: FormBuilder,
        private readonly _store: Store<{ user: UserState }>,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {}

    ngOnInit(): void {
        this._activatedRoute.data.subscribe((val) => {
            this.user = val.user;
            this.miscForm = this._fb.group({
                name: [this.user.name, Validators.required],
                lastname: [this.user.lastname, Validators.required],
            });
        });
    }

    cancel(): void {
        this._router.navigate(['..'], { relativeTo: this._activatedRoute });
    }

    updateMisc(): void {
        this._usersService
            .update(this.user._id, {
                name: this.miscForm.value.name ?? undefined,
                lastname: this.miscForm.value.lastname ?? undefined,
            })
            .subscribe({
                next: (res: any) => {
                    this._store.dispatch(UserActions.editUserInfos({ infos: new User(res.data) }));
                    this._router.navigate(['.']);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err?.error?.message || err?.message || err));
                },
            });
    }
}
