import { NgClass } from '@angular/common';
import { Component, computed, EventEmitter, Input, Output, Signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ConversationStatus } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { ConversationWithMessages } from ':shared/models/conversation';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

import * as MessagesActions from '../messages.actions';
import { MessagesService } from '../messages.service';

@Component({
    selector: 'app-conversation-card',
    templateUrl: './conversation-card.component.html',
    styleUrls: ['./conversation-card.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTooltipModule,
        TranslateModule,
        PlatformLogoPathResolverPipe,
        ApplySelfPurePipe,
        LazyLoadImageModule,
        ApplyPurePipe,
        AvatarPipe,
    ],
})
export class ConversationCardComponent {
    @Input() conversationWithMessages: ConversationWithMessages;
    @Input() selectedConversation: ConversationWithMessages | null;
    @Input() checkedConversations: Signal<ConversationWithMessages[]>;
    @Output() onSelectConversation = new EventEmitter<ConversationWithMessages>();
    @Output() onCheckConversation = new EventEmitter<ConversationWithMessages>();
    @Output() onChangeArchivedConversation = new EventEmitter<string>();
    @Output() onRemoveConversationFromFavorite = new EventEmitter<string>();

    readonly SvgIcon = SvgIcon;

    menuOpened = false;

    readonly isConversationChecked = computed(() =>
        this.checkedConversations()?.some(
            (conversationWithMessages) => conversationWithMessages.conversation._id === this.conversationWithMessages.conversation._id
        )
    );

    constructor(
        private readonly _messagesService: MessagesService,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _avatarPipe: AvatarPipe,
        private readonly _toastService: ToastService,
        public screenSizeService: ScreenSizeService
    ) {}

    getConversationLastMessageHtml = (conversationWithMessages: ConversationWithMessages): string | SafeHtml => {
        const isLastMessageSentByMalouUser = conversationWithMessages.getLastMessage().isFromRestaurant;
        return `${
            isLastMessageSentByMalouUser ? this._translate.instant('messages.conversation_card.you') : ''
        } ${conversationWithMessages.getLastMessageHtml(this._translate)}`;
    };

    selectConversation(): void {
        if (this.conversationWithMessages.conversation.status === ConversationStatus.UNREAD) {
            this._changeConversationStatus(ConversationStatus.READ);
        }
        this.onSelectConversation.emit(this.conversationWithMessages);
    }

    isSelected(): boolean {
        return this.selectedConversation?.conversation._id === this.conversationWithMessages?.conversation._id;
    }

    saveConversationAsFavorite(): void {
        const favorite = !this.conversationWithMessages.conversation.favorite;

        this._messagesService.updateConversation(this.conversationWithMessages.conversation, { favorite }).subscribe({
            next: () => {
                this._store.dispatch({
                    type: MessagesActions.editConversationFavorite.type,
                    conversation: this.conversationWithMessages.conversation,
                    favorite,
                });
            },
            error: (err) => {
                console.error('An error occured while saving this conversation', err);
                this._openErrorToast(err.message);
            },
        });

        if (!favorite) {
            this.onRemoveConversationFromFavorite.emit(this.conversationWithMessages.conversation._id);
        }
    }

    isConversationUnread = (): boolean => this.conversationWithMessages.conversation.status === ConversationStatus.UNREAD;

    isConversationArchived = (): boolean => this.conversationWithMessages.conversation.archived;

    closeMenu(): void {
        this.menuOpened = false;
    }

    changeArchivedConversation(): void {
        const archived = !this.isConversationArchived();

        this._messagesService.updateConversation(this.conversationWithMessages.conversation, { archived }).subscribe({
            next: () => {
                this._store.dispatch({
                    type: MessagesActions.editConversationArchived.type,
                    conversation: this.conversationWithMessages.conversation,
                    archived,
                });
            },
            error: (err) => {
                console.error('An error occured while saving this conversation', err);
                this._openErrorToast(err.message);
                this._store.dispatch({
                    type: MessagesActions.editConversationArchived.type,
                    conversation: this.conversationWithMessages.conversation,
                    archived,
                });
            },
        });

        this.onChangeArchivedConversation.emit(this.conversationWithMessages.conversation._id);
    }

    openMenu(event: MouseEvent): void {
        event.stopPropagation();
        this.menuOpened = true;
    }

    getAvatarUrl = (): string =>
        this.conversationWithMessages?.getConversationSenderAvatar() ||
        this._avatarPipe.transform(this.conversationWithMessages?.getConversationSenderName());

    toogleMessageChecked(): void {
        this.onCheckConversation.emit(this.conversationWithMessages);
    }

    toggleConversationStatus(): void {
        const conversationStatus =
            this.conversationWithMessages.conversation.status === ConversationStatus.UNREAD
                ? ConversationStatus.READ
                : ConversationStatus.UNREAD;

        this._changeConversationStatus(conversationStatus);
    }

    private _changeConversationStatus(status: ConversationStatus): void {
        this._messagesService.updateConversation(this.conversationWithMessages.conversation, { status }).subscribe({
            next: () => {
                this._store.dispatch({
                    type: MessagesActions.editConversationStatus.type,
                    conversation: this.conversationWithMessages.conversation,
                    status,
                });
            },
            error: (err) => {
                console.error('An error occured while saving this conversation', err);
                this._openErrorToast(err.message);
            },
        });
    }

    private _openErrorToast(errMessage: string): void {
        this._toastService.openErrorToast(this._clarifyError(errMessage));
    }

    private _clarifyError(message: string): string {
        // TODO: clarify error messages eg

        return this._translate.instant('messages.errors.unknown_error') + ' : ' + message;
    }
}
