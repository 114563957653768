<ng-container [ngTemplateOutlet]="isTipsLoading() || isParentLoading() ? loadingTemplate : tipsTemplate"></ng-container>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="secondary-bg px-4 py-6 flex gap-4 min-h-[335px]">
        <div class="flex items-center justify-center">
            <app-skeleton skeletonClass="h-[35px] w-[25px]"></app-skeleton>
        </div>
        <div class="flex h-full w-full flex-col gap-2">
            <div class="flex flex-col">
                <app-skeleton class="h-[30px] w-[200px]"></app-skeleton>
                <app-skeleton class="h-[15px] w-[150px]"></app-skeleton>
            </div>
            <div class="flex flex-col gap-1">
                <app-skeleton class="flex-1" skeletonClass="h-[45px]">
                    <app-skeleton skeletonClass="h-[30px] !w-[30px] secondary-bg my-auto ml-2"></app-skeleton>
                </app-skeleton>
                <app-skeleton class="flex-1" skeletonClass="h-[45px]">
                    <app-skeleton skeletonClass="h-[30px] !w-[30px] secondary-bg my-auto ml-2"></app-skeleton>
                </app-skeleton>
                <app-skeleton class="flex-1" skeletonClass="h-[45px]">
                    <app-skeleton skeletonClass="h-[30px] !w-[30px] secondary-bg my-auto ml-2"></app-skeleton>
                </app-skeleton>
                <app-skeleton class="flex-1" skeletonClass="h-[45px]">
                    <app-skeleton skeletonClass="h-[30px] !w-[30px] secondary-bg my-auto ml-2"></app-skeleton>
                </app-skeleton>
                <app-skeleton class="flex-1" skeletonClass="h-[45px]">
                    <app-skeleton skeletonClass="h-[30px] !w-[30px] secondary-bg my-auto ml-2"></app-skeleton>
                </app-skeleton>
            </div>
        </div>
    </app-skeleton>
</ng-template>

<ng-template #tipsTemplate>
    <div class="malou-simple-card flex h-full">
        @if (!screenSizeService.isPhoneScreen) {
            <div class="flex w-[70px] items-center justify-center rounded-s bg-malou-color-background-dark">
                <img class="h-7 w-auto sm:mb-5" [src]="'tips-idea' | roiTipsPathResolver" />
            </div>
        }
        @if (roiTips()) {
            <div class="flex w-full flex-col px-6 py-4 sm:px-4">
                <div class="flex flex-col gap-1">
                    <span class="malou-text-22--bold malou-color-text-1 mr-1">{{ 'roi.tips.title' | translate }}</span>
                    <span class="malou-text-10--semibold malou-color-text-2">{{ 'roi.tips.subtitle' | translate }}</span>
                </div>
                <div class="mt-1 flex w-full flex-col gap-1 sm:justify-center">
                    @for (tip of roiTips(); track $index) {
                        <app-tip-item
                            [roiTip]="tip.id"
                            [title]="tip.titleTranslationKey | translate"
                            [details]="tip.descriptionTranslationKey | translate: { value: tip.value }"
                            [emoji]="tip.emojiPath"
                            [buttonLink]="tip.actionRedirectionLink"
                            [buttonText]="tip.actionTranslationKey | translate">
                        </app-tip-item>
                    }
                </div>
                @if (
                    roiTips().length <= MAX_TIPS_TO_SHOW_CONGRATS &&
                    !isError() &&
                    roiContext.performanceScore() >= MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS
                ) {
                    <div class="mt-9.5 flex h-24 items-center gap-5 rounded-md bg-malou-color-background-dark p-7">
                        <img class="!h-12 !w-14" [src]="'clap' | roiTipsPathResolver" />
                        <div class="flex flex-col gap-1">
                            <span class="malou-text-14--bold text-malou-color-text-1">{{ 'roi.tips.congrats' | translate }}</span>
                            <span class="malou-text-10 text-malou-color-text-2">{{ 'roi.tips.congrats_subtitle' | translate }}</span>
                        </div>
                    </div>
                }
                @if (isError()) {
                    <div class="flex h-full flex-col items-center justify-center">
                        <img class="mb-3 !h-9 !w-9" [src]="'sad' | roiTipsPathResolver" />
                        <span class="malou-text-14--bold malou-color-text-1">{{ 'roi.tips.error' | translate }}</span>
                        <span class="malou-text-10 malou-color-text-2">{{ 'roi.tips.try_again' | translate }}</span>
                    </div>
                }
            </div>
        }
    </div>
</ng-template>
